/* Feature - lading page / home page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React from "react";
// import "../home/home.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Commonheader from "../../layouts/common/commonHeader";
import StatusChart from './statusChart';
import PaiChart from "./paiChart";
import { Outlet } from 'react-router-dom';
import Dashboard from "../../layouts/dashboard/dashboard";
import StatusChartFA from "../FA/StatusChartFA";
import Verification from "../Verification/VerificationDashboard";

// import Demo from '../home/demo'


// function of home page
const Home = () => {

  // Zongo cyan color code = #64FFE2
  // Zongo blue color code = #0063B9
  // Zongo dark blue  color code = #021D37

  const dispatch = useDispatch();

  //////////////// fetch user role from redux/////////////////
  const role = useSelector((state) => state.auth.role);
  const access = useSelector((state) => state.access.accessData);
  const accessFA = useSelector((state) => state.access.dashboard_accounting);
  const accessAMM = useSelector((state) => state.access.dashboard);
  const accessPlant = useSelector((state) => state.access.dashboard_plant);
  const dashboard_criticality = useSelector((state) => state.access.dashboard_criticality);


  // console.log('VERIFICATION', verification)
  // console.log("accessFA?.", accessFA)

  /////////// dispatch for role get/////////////
  useEffect(() => {
    // console.log('role', role)
    const roleCode = role[0];
    // console.log(roleCode);
    if (roleCode !== null) {
      // console.log(roleCode);
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role]);

  useEffect(() => {
    // console.log(access);
  }, [access]);


  return (
    <div
      style={{
        backgroundColor: '#f5f5f5'
      }}
    >

      <div className="overflowX">
        {/* Header of home page */}



        <Dashboard selectedTab={'dashboard'} />
        {
          (accessPlant !== null || accessFA !== null) && (accessPlant?.r || accessFA?.r) &&
          <div
            style={{
              marginTop: '5rem',
            }}
          >

            <Verification />
          </div>
        }


        {/* First & Second component of landing page - Inspection & Maintnance status  */}

        {accessAMM !== null && accessAMM?.r && <div>
          <StatusChart />
          <br />

          {/* Third component of landing page - asset / device & facility count based on criticality in paichart */}
          {dashboard_criticality !== null && dashboard_criticality?.r &&
            <div>
              <div style={{
                marginTop: '4rem',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                marginLeft: '5px',
                marginRight: '5px',
                borderRadius: '5px',
                backgroundColor: 'rgba(105, 250, 225, 1)'
              }}>
                Total Asset by Criticality
              </div>
              <PaiChart />
              <br />
            </div>}

          {/* <Demo /> */}
          {/* <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginLeft: '5px',
            marginRight: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(105, 250, 225, 1)'
          }}>
            Ticket
          </div> */}
          {/* <iframe title="DashboardV1.1" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=f338c76b-e7c5-4869-9012-a483fd7ef819&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSection51a7870d60cb56d0dc23" frameborder="0" allowFullScreen="true"></iframe>
          <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginLeft: '5px',
            marginRight: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(105, 250, 225, 1)'
          }}>
            Uptime & OEE
          </div>
          <iframe title="DashboardV1.1" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=f338c76b-e7c5-4869-9012-a483fd7ef819&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSection3910420cd4ee0ea58001" frameborder="0" allowFullScreen="true"></iframe>
          <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginLeft: '5px',
            marginRight: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(105, 250, 225, 1)'
          }}>
            MTTR & MTBF
          </div>
          <iframe title="DashboardV1.1" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=f338c76b-e7c5-4869-9012-a483fd7ef819&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSection004699258e27a601c01b" frameborder="0" allowFullScreen="true"></iframe>
          <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginLeft: '5px',
            marginRight: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(105, 250, 225, 1)'
          }}>
            Workforce by Category
          </div>
          <iframe title="Workforce_by_category" width="1500" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=c13fe80f-cdef-4772-93da-d43e767969c6&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSectiona1ec1aed5c3135dc05c3" frameborder="0" allowFullScreen="true"></iframe>
          <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginLeft: '5px',
            marginRight: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(105, 250, 225, 1)'
          }}>
            SLA Deviation
          </div>
          <iframe title="DashboardV1.1" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=f338c76b-e7c5-4869-9012-a483fd7ef819&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSectionb4c87375b23c16bcb4ed" frameborder="0" allowFullScreen="true"></iframe>
          <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginLeft: '5px',
            marginRight: '5px',
            borderRadius: '5px',
            backgroundColor: 'rgba(105, 250, 225, 1)'
          }}>
            Total Maintnance Cost Based on Criticality
          </div> */}
        </div>
        }

        {/* ........... Dashboard for Verification ............. */}



        {accessFA !== null && accessFA?.r &&
          <StatusChartFA />
        }

        {/* <iframe title="DashboardV1.1" width="1500" height="441.25" src="https://app.powerbi.com/reportEmbed?reportId=f338c76b-e7c5-4869-9012-a483fd7ef819&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSection33f1730d0d403ee4c289" frameborder="0" allowFullScreen="true"></iframe> */}
        {/* <iframe title="Fin_App_Test" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=22cea237-20b9-4a90-bd64-d351ccb9d90f&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSection374ee8b8ce9d151ca80c" frameborder="0" allowFullScreen="true"></iframe> */}
        {/* <iframe title="Fin_App_Test" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=22cea237-20b9-4a90-bd64-d351ccb9d90f&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSectione1d8fedc3eda61ac2106" frameborder="0" allowFullScreen="true"></iframe> */}
        {/* <iframe title="Fin_App_Test" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=22cea237-20b9-4a90-bd64-d351ccb9d90f&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSection5aec5efca1f573455ea3" frameborder="0" allowFullScreen="true"></iframe> */}
      </div>

      {/* {accessFA?.r &&
        <div className="FA">
          <div style={{ paddingTop: "3rem" }}>
            <iframe title="Fin_App_Test" width="1500" height="841.25" src="https://app.powerbi.com/reportEmbed?reportId=22cea237-20b9-4a90-bd64-d351ccb9d90f&autoAuth=true&ctid=55a799d5-4aae-4d77-b8bc-6dea7587cbb1&navContentPaneEnabled=false&filterPaneEnabled=false&pageName=ReportSectione1d8fedc3eda61ac2106" frameborder="0" allowFullScreen="true"></iframe>
          </div>
        </div>
      } */}
    </div>

  );
};

export default Home;
