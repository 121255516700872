import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import Commonheader from "../../layouts/common/commonHeader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCalendarDays,
    faAngleRight,
    faAngleLeft,
    faClose,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faEye,
    faBook,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import BackButton from "../../components/BackButton/BackButton";

const Expensed = () => {
    const [list, setList] = useState();
    const dispatch = useDispatch()
    const list1 = useSelector((state) => state.FA.ExpensedData)
    const isLoading = useSelector((state) => state.FA.isLoading);
    const getData = async () => {
        dispatch({
            type: 'EXPENSE_GET_LIST',
            payload: [currentPage, itemsPerPage],

        })
        // const { data } = await axios.get(`http://localhost:4010/expense`);
        // setList(data)
    };
    function generateExcelFile(data) {
        // const worksheet = XLSX.utils.json_to_sheet(data);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // const downloadLink = document.createElement('a');
        // const url = URL.createObjectURL(blob);
        // downloadLink.href = url;
        // downloadLink.download = 'ZV_Expensed_Report.xlsx';
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);


        data.map((date) => { date.doi = moment(date?.doi).format('DD-MM-YYYY') })

        data.map((date) => { date.dop = moment(date?.dop).format('DD-MM-YYYY') })

        data.map((date) => { date.invoice_date = moment(date?.invoice_date).format('DD-MM-YYYY') })

        data.map((date) => { date.doe = moment(date?.doe).format('DD-MM-YYYY') })


        let customHeader = [[
            "BU Code",
            "GL Code",
            "Asset ID",
            "Asset Class Code",
            "Asset Class Name",
            "Asset Name",
            "Asset Tag",
            "Location Code",
            "Asset Location",
            "Cost Center Code",
            "Cost Center Name",
            "Date of Installation (dd-mm-yyyy)",
            "Date of Purchase (dd-mm-yyyy)",
            "Date of Expensed (dd-mm-yyyy)",
            "Invoice Date (dd-mm-yyyy)",
            "Invoice Number",
            "Invoice Value (INR)",
            "Other Cost (INR)",
            "Description",
            "Useful Life in Months",
            "Nature of Asset",
            "Salvage Value (INR)",
            "Supplier Name",
            "Supplier Location",
            "Supplier Code",
            "Capital Value (INR)",
            "Quantity",
            "User",
        ]]

        // const modifiedData = [customHeader, ...data];

        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();
        // console.log("worksheet", modifiedData);
        XLSX.utils.sheet_add_aoa(worksheet, customHeader);
        XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `ZV_Expensed_List_${new Date().toDateString()}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }



    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    // Calculate the total number of pages
    let totalPages = 0;
    var data1List = [];
    if (list1?.result !== undefined) {
        data1List = list1?.result;
    }
    if (list1?.total_count !== undefined) {
        totalPages = Math.ceil(list1?.total_count / itemsPerPage);
    }
    // console.log('data1List', data1List);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    useEffect(() => {
        if ((currentPage && currentPage > 0)) {
            getData()
        }
    }, [currentPage, itemsPerPage])


    return (
        <>
            {isLoading && <Loader />}
            <Commonheader />

            <div
                className="col-md-12">
                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        justifyContent: "center",
                    }}
                >
                    Expensed Asset List
                    <div style={{ float: "right" }}>
                        {/* ................. SEARCH ................. */}
                        {/* <form
                            className="mb-4"
                        >
                            <input
                                type="text"
                                placeholder="Search"

                                style={{
                                    border: "1px solid steelblue",
                                    fontSize: "15px",
                                    padding: "5px",
                                }}
                            />
                           
                            <span
                                type="reset"

                                style={{ paddingLeft: "8px", marginRight: "25px" }}
                            >
                                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                            </span>
                        </form> */}
                    </div>
                    <div>Expensed Asset View </div>
                </div>
                <BackButton />
                <table className="table-bordered">
                    <thead >
                        <tr style={{
                            textAlign: "center",
                            backgroundColor: "steelblue",
                            padding: "8px",
                            color: "#fff",
                        }}>
                            <th style={{ padding: "8px", width: "5%" }}>S. No</th>
                            <th scope="col"
                                style={{ wordBreak: "break-word", width: "10%" }}>Asset ID</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "left", width: "auto" }}>Asset Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Class Code</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Class Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost Center Code</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost Center Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Location Code</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Location Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Supplier Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Quantity</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Invoice Number</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Date of Purchase</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost (INR)</th>
                            {/* <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Date Of Expensed</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data1List?.map((el, index) =>
                        (<tr class="fs-6" style={{ textAlign: "center" }}>
                            <td style={{ padding: "8px" }}>{(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                            <td >{el.asset_id}</td>
                            <td style={{ textAlign: "left", paddingLeft: "5px" }} >{el.asset_name}</td>
                            <td >{el.asset_class}</td>
                            <td >{el.asset_class_name}</td>
                            <td >{el.cost_center}</td>
                            <td >{el.cost_center_name}</td>
                            <td >{el.location}</td>
                            <td >{el.location_name}</td>
                            <td >{el.supplier_name}</td>
                            <td >{el.quantity}</td>
                            <td >{el.invoice_no}</td>
                            <td >{el.acquisition_date?.slice(0, 10)}</td>
                            <td >{el.cost}</td>
                            {/* <td >{el.doe.slice(0, 10)}</td> */}
                        </tr>)
                        )}


                    </tbody>
                </table>
                <div>
                    {/* pagination */}
                    {data1List !== null && data1List.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                padding: "10px",
                            }}
                        >
                            <div className="mb-6">
                                <label>Items per page:</label> &nbsp;
                                <select
                                    onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                    style={{ borderRadius: "8px", cursor: "pointer" }}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div style={{ paddingRight: "25px" }} className="pagination">
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handleFirstPage}
                                >
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handlePrev}
                                >
                                    {/* Prev */}
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                </button>
                                <span style={{ padding: "10px" }}>
                                    Showing {currentPage} of {totalPages} pages
                                </span>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        border: "none",
                                    }}
                                    disabled={currentPage === totalPages}
                                    onClick={handleNext}
                                >
                                    {currentPage === totalPages ? (
                                        <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        border: "none",
                                    }}
                                    disabled={currentPage === totalPages}
                                    onClick={handleLastpage}
                                >
                                    {currentPage === totalPages ? (
                                        <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <Button
                type="submit"
                style={{
                    margin: "2rem",
                    backgroundColor: "#69fae1",
                    color: "#000",
                    fontWeight: "bold"
                }}
                onClick={() => generateExcelFile(list1.result)
                }>
                Download
            </Button>
        </>
    );
};

export default Expensed;
