import {
    CWIP_GET_LIST,
    CWIP_GET_SUCCESS,
    CWIP_GET_FAILED,
    EXPENSE_GET_LIST,
    EXPENSE_GET_SUCCESS,
    EXPENSE_GET_FAILED,
    CAPITAL_GET_LIST,
    CAPITAL_GET_SUCCESS,
    CAPITAL_GET_FAILED,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAILED,
    GET_LOCATION_LIST,
    GET_CLASS_LIST,
    GET_CLASS_SUCCESS,
    GET_CLASS_FAILED,
    GET_CALCULATION,
    GET_CALCULATION_SUCCESS,
    GET_CALCULATION_FAILED,
    GET_COST_CENTER_LIST,
    GET_COST_CENTER_SUCCESS,
    GET_COST_CENTER_FAILED,
    GET_BU_CODE_LIST,
    GET_BU_CODE_SUCCESS,
    GET_BU_CODE_FAILED,
    GET_ADDITION_DELETION,
    GET_ADDITION_DELETION_SUCCESS,
    GET_ADDITION_DELETION_FAILED,
    GET_VERIFIED_LIST,
    GET_VERIFIED_LIST_SUCCESS,
    GET_VERIFIED_LIST_FAILED,
    GET_REPORT_DATA,
    GET_REPORT_DATA_SUCCESS,
    GET_REPORT_DATA_FAILED,
    CREATE_FAASSET_SUCCESS,
    ADD_BULK_FAASSET_SUCCESS,
} from '../actions/faAction';

const inistialFAState = {
    isLoading: false,
    isSubmitted: false,
    CWIPData: null,
    ExpensedData: null,
    CapitalData: null,
    location: null,
    assetClass: null,
    calculation: null,
    costCenter: null,
    buCode: null,
    addDeleteAsset: null,
    verifiedData: null,
    getReportData: null,
}

export default function FAReducer(state = inistialFAState, action) {
    // console.log(action.payload);
    switch (action.type) {
        case CWIP_GET_LIST:
            return {
                ...state,
                isLoading: true,
                isSubmitted: false,
            };
        case CREATE_FAASSET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSubmitted: true,
            };
        case ADD_BULK_FAASSET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSubmitted: true,
            }
        case CWIP_GET_SUCCESS:
            return {
                ...state,
                CWIPData: action.payload,
                isLoading: false,
            };
        case CWIP_GET_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case EXPENSE_GET_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case EXPENSE_GET_SUCCESS:
            return {
                ...state,
                ExpensedData: action.payload,
                isLoading: false,
            };
        case EXPENSE_GET_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case CAPITAL_GET_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case CAPITAL_GET_SUCCESS:
            return {
                ...state,
                CapitalData: action.payload,
                isLoading: false,
                isSubmitted: false,
            };
        case CAPITAL_GET_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_LOCATION_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                location: action.payload,
                isLoading: false,
            };
        case GET_LOCATION_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_CLASS_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_CLASS_SUCCESS:
            return {
                ...state,
                assetClass: action.payload,
                isLoading: false,
            };
        case GET_CLASS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_CALCULATION:
            return {
                ...state,
                isLoading: true,
            };
        case GET_CALCULATION_SUCCESS:
            return {
                ...state,
                calculation: action.payload,
                isLoading: false,
            };
        case GET_CALCULATION_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_COST_CENTER_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_COST_CENTER_SUCCESS:
            return {
                ...state,
                costCenter: action.payload,
                isLoading: false,
            };
        case GET_COST_CENTER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_BU_CODE_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_BU_CODE_SUCCESS:
            return {
                ...state,
                buCode: action.payload,
                isLoading: false,
            };
        case GET_BU_CODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_ADDITION_DELETION:
            return {
                ...state,
                isLoading: true,
            };
        case GET_ADDITION_DELETION_SUCCESS:
            return {
                ...state,
                addDeleteAsset: action.payload,
                isLoading: false,
            };
        case GET_ADDITION_DELETION_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_VERIFIED_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_VERIFIED_LIST_SUCCESS:
            return {
                ...state,
                verifiedData: action.payload,
                isLoading: false,
            };
        case GET_VERIFIED_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_REPORT_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case GET_REPORT_DATA_SUCCESS:
            return {
                ...state,
                getReportData: action.payload,
                isLoading: false,
            };
        case GET_REPORT_DATA_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case "CLEAR_FA_REDUCER":
            return {
                ...state,
                CWIPData: null,
                ExpensedData: null,
                CapitalData: null,
                location: null,
                assetClass: null,
                calculation: null,
                costCenter: null,
                buCode: null,
                addDeleteAsset: null,
                verifiedData: null,
                getReportData: null,
            };
        default:
            return state;
    }
}