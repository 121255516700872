// Upload Maintenance
// Frequency greater than a day
// 6 types of activities

/* Author - Janaki */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import CheckPoints from "./checkPoints";
import { MAINT_ADD } from "../../../redux/actions/maintAction";
import Approvals from "./approvals";
import PdfDocView from './pdfDocView';

const UploadMaintenance = (props) => {
  const [queryParams] = useSearchParams();
  const isLoading = useSelector((state) => state.maintenance.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const isSubmitted = useSelector((state) => state.maintenance.isSubmitted);
  const sopData = useSelector((state) => state.maintenance.sop);

  const [disabled, setDisabled] = useState(false);
  const handleForm = () => setShowForm(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  //useState
  const [showDocModel, setShowDocModel] = useState(false);
  const [viewPdf, setViewPdf] = useState("");
  const [showhide, setShowhide] = useState("");
  const [showForm, setShowForm] = useState(true);
  const today = new Date().toISOString().slice(0, 10);
  //client-side validation
  const [form, setForm] = useState({
    name: "",
    maintype: "",
    priority: "",
    basedOn: "",
    managedBy: "",
    frequency: "",
    assigneeType: "",
    maintname: "",
    std_req: "",
    ans_typ: ""
  });

  const [nameErr, setNameErr] = useState("");
  const [maintypeErr, setMaintypeErr] = useState("");
  const [priorityErr, setPriorityErr] = useState("");
  const [basedonErr, setBasedonErr] = useState("");
  const [managedByErr, setManagedbyErr] = useState("");
  const [freqErr, setFreqErr] = useState("");
  const [maintNameErr, setMaintNameErr] = useState("");
  const [stdReqErr, setStdReqErr] = useState("");
  const [ansTypeErr, setAnsTypErr] = useState("");


  let isValid = true;
  const handleName = (val) => {
    setForm({
      ...form,
      name: val,
    });
  };
  const handleMaintType = (val) => {
    setForm({
      ...form,
      maintype: val,
    });
  };
  const handleProity = (val) => {
    setForm({
      ...form,
      priority: val,
    });
  };
  const handleBased = (val) => {
    setForm({
      ...form,
      basedOn: val,
    });
  };
  const handleManagedby = (val) => {
    setForm({
      ...form,
      managedBy: val,
    });
  };
  const handleFrecy = (val) => {
    setForm({
      ...form,
      frequency: val,
    });
  };
  const handleAssignee = (val) => {
    setForm({
      ...form,
      assigneeType: val,
    });
  };
  const handleMaintName = (val) => {
    setForm({
      ...form,
      maintname: val,
    });
  };
  const formValidation = () => {
    const nameErr = {};
    const maintypeErr = {};
    const priorityErr = {};
    const basedonErr = {};
    const managedByErr = {};
    const freqErr = {};
    const maintNameErr = {};
    const stdReqErr = {};
    const ansTypeErr = {};

    if (form.name.trim().length < 1) {
      nameErr.inspShort = "Please Enter Maintenance Name";
      isValid = false;
    }
    setNameErr(nameErr);
    if (form.maintype === "") {
      maintypeErr.inspShort = "Please Choose Maintenance Type";
      isValid = false;
    }
    setMaintypeErr(maintypeErr);
    if (form.priority === "") {
      priorityErr.inspShort = "Please Choose Priority";
      isValid = false;
    }
    setPriorityErr(priorityErr);
    // if (form.basedOn === "") {
    //   basedonErr.inspShort = "Please Choose Based on";
    //   isValid = false;
    // }
    // setBasedonErr(basedonErr);
    if (form.managedBy === "") {
      managedByErr.inspShort = "Please Choose Managed by";
      isValid = false;
    }
    setManagedbyErr(managedByErr);
    if (form.frequency === "") {
      freqErr.inspShort = "Please Choose Frequency";
      isValid = false;
    }
    setFreqErr(freqErr);
    if (form.maintname.trim().length < 1) {
      maintNameErr.inspShort = "Please Enter Activity";
      isValid = false;
    }
    setMaintNameErr(maintNameErr);
    if (form.std_req.trim().length < 1) {
      setStdReqErr.inspShort = "Please Enter Standard Requirement";
      isValid = false;
    }
    setStdReqErr(stdReqErr);
    if (form.ans_typ.trim().length < 1) {
      setAnsTypErr.inspShort = "Please Enter Answer Type";
      isValid = false;
    }
    setAnsTypErr(ansTypeErr);
    return isValid;
  };
  //Radio button function
  const handleShow = (e) => {
    const getshow = e.target.value;
    setShowhide(getshow);
    setMaintenance({ ...maintenace, priority: e.target.value });
  };
  //Handle Frequency
  const handleFrequency = (e) => {
    setMaintenance((prevState) => {
      prevState.frequency.type = e;
      return { ...prevState };
    });
  };
  //Handle Inspection Frequency
  const handleMaintFreqcy = (e) => {
    setMaintenance((prevState) => {
      prevState.frequency.value = e;
      return { ...prevState };
    });
  };
  //Schedule End Radio Button
  const handleScheduleEnd = (e) => {
    const getshow = e.target.value;
    setShowhide(getshow);
    setMaintenance((prevState) => {
      prevState.frequency.end_type = getshow;
      return { ...prevState };
    });
  };
  const initialCalenderFrequency = {
    type: "", //"months",
    value: "",
    start: "", //["00","01","02","03",....]
    end_type: "", //'no_end'
    end_after_occurence: "",
    date_value: "",
  };
  const [calendarFreqncy, setCalendarFreqncy] = useState(
    initialCalenderFrequency
  );
  function sortByAcendingOrder(objectArray) {
    return objectArray.sort(
      (prev, curr) => Number(prev.ma_sequence) - Number(curr.ma_sequence)
    );
  }
  const addMaintChekpnts = (taskType, taskSeq) => {
    let newMaObj = {
      ma_type: taskType,
      ma_name: "",
      ma_sequence: taskSeq,
      ma_sla: "",
      ma_sla_interval: "",
      ma_sop: "",
      ma_assignee_type: "",
      ma_assignee: [],
      ma_status: "",
      ma_attachment: "",
      created_by: "",
      modified_by: "",
      checkpoints: [],
      approvals: {
        approval_category: "",
        sequence_approval_type: "",
        parallel_approval_type: "",
        parallel_type: "",
        approval_set: [],
      },
    };
    let updatedMaList = [...maintenace.maintenance_activities, newMaObj];
    const uniqueValues = new Set(updatedMaList.map((v) => v.ma_type));
    if (uniqueValues.size < updatedMaList.length) {
      return { ...maintenace };
    }
    const groupedTasks = sortByAcendingOrder(updatedMaList);
    setMaintenance({ ...maintenace, maintenance_activities: groupedTasks });
  };
  const deleteTask = (index) => {
    alert("Are you sure you wish to delete ?");
    let afterDelTask = maintenace.maintenance_activities;
    afterDelTask.splice(index, 1);
    setMaintenance({ ...maintenace, maintenance_activities: afterDelTask });
  };
  const addCheckPoints = (maIndex) => {
    let newcpObj = {
      checkpoint: "",
      approval_sequence: "",
      uom: "",
      standard_requirement: "",
      answer_type: "",
    };
    let taskList = maintenace.maintenance_activities;
    taskList.forEach((mactvty, j) => {
      if (j === maIndex) {
        mactvty.checkpoints.push(newcpObj);
      }
    });
    let updatedTaskList = [...taskList];
    setMaintenance({ ...maintenace, maintenance_activities: updatedTaskList });
  };
  const deleteCheckPoints = (taskIndex, itemIndex) => {
    alert("Are you sure you wish to delete ?");
    let taskItemList = maintenace.maintenance_activities[taskIndex].checkpoints;
    taskItemList.splice(itemIndex, 1);
    setMaintenance({ ...maintenace, checkpoints: taskItemList });
  };
  const initialMaintenanceState = {
    parent_mid: "",
    maintenance_name: "",
    maintenance_type: "",
    priority: "",
    based_on: "calendar",
    managed_by: "",
    assignee_type: "",
    assignee: [],
    maintenance_duration: "",
    shutdown_duration: "",
    man_power_required: "",
    man_hour_required: "",
    frequency: calendarFreqncy,
    created_by: userid,
    maintenance_activities: [],
  };
  const [maintenace, setMaintenance] = useState(initialMaintenanceState);

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };
  const submitMaintenance = (e) => {
    e.preventDefault();
    if (
      validation()
    ) {
      alert("Please add atleast one Activity", 2.5);
    }
    else if (validation() ||
      maintenace.maintenance_activities.length === 0) {
      alert("Please fill Mandatory field's & Please add atleast one Activity", 2.5);
    }
    else {
      let validate_Cp = true
      maintenace.maintenance_activities.forEach((obj) => {
        // console.log('obj', obj);
        if (obj.ma_name === "") {
          validate_Cp = false
        }
        // (obj.checkpoints.forEach((cp) => {
        //   if (cp.standard_requirement === "" || cp.answer_type) {
        //     validate_Cp = false
        //   }
        // }))
      })
      if (validate_Cp) {
        let maintenanceData = {
          parent_mid: id,
          maintenance_name: maintenace.maintenance_name,
          maintenance_type: maintenace.maintenance_type,
          priority: maintenace.priority,
          based_on: maintenace.based_on,
          managed_by: maintenace.managed_by,
          // assignee_type: maintenace.assignee_type,
          assignee: maintenace.assignee,
          maintenance_duration: maintenace.maintenance_duration,
          shutdown_duration: maintenace.shutdown_duration,
          man_power_required: maintenace.man_power_required,
          man_hour_required: maintenace.man_hour_required,
          frequency: {
            frequency_type: maintenace.frequency.type,
            frequency_type_value: maintenace.frequency.value,
            frequency_start: maintenace.frequency.start,
            frequency_end_type: maintenace.frequency.end_type,
            frequency_end_occurence_value: maintenace.frequency.occur_value,
            frequency_end_date_value: maintenace.frequency.date_value,
          },
          created_by: userid,
          maintenance_activities: maintenace.maintenance_activities,
        };
        // console.log('maintenanceData', maintenanceData);
        dispatch({
          type: MAINT_ADD,
          payload: [queryParams.get("type"), maintenanceData],
        });
      }
    }
  };

  // console.log("maintenace", maintenace);

  //functions for validation
  function nameChars(str) {
    const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialChars.test(str);
  }
  function timeChars(str) {
    const integers = /^[1-9][0-9]{0,9}$/;
    return integers.test(str);
  }

  function slaChars(str) {
    const zipCodeChars = /^([0-9])*[0-9]+$/;
    return zipCodeChars.test(str);
  }
  // useEffect
  useEffect(() => {
    if (isSubmitted === true) {
      navigate("/maintenancelist");
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (id !== null) {
      dispatch({
        type: 'SOP_GET',
        payload: id
      })
    }
  }, [id])

  // console.log("maintenace data", maintenace);

  return (
    <div>
      <div className="container">
        {/* {isLoading && <Loader />} */}
        <div className="row">
          <div className="d-flex">
            <div className="col-md-6">
              <form className="mt-5 border p-4 bg-light shadow rounded">
                {/* Add Schedule form starts */}
                <h3 style={{ textAlign: "center" }}>
                  Create Planned Maintenance
                </h3>
                <div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Planned Maintenance Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="schedulename"
                      className="form-control"
                      placeholder="Maintenance Name"
                      value={maintenace.maintenance_name}
                      onChange={(e) => {
                        setMaintenance({
                          ...maintenace,
                          maintenance_name: e.target.value,
                        });
                        handleName(e.target.value);
                      }}
                    />

                    {Object.keys(nameErr).map((key) => {
                      if (!maintenace.maintenance_name) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {nameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {nameChars(maintenace.maintenance_name) ||
                      maintenace.maintenance_name === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Maintenance Name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Planned Maintenance Type
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="type"
                      className="form-select"
                      value={maintenace.maintenance_type}
                      onChange={(e) => {
                        setMaintenance({
                          ...maintenace,
                          maintenance_type: e.target.value,
                        });
                        handleMaintType(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="autonomous">Autonomous</option>
                      <option value="preventive">Preventive</option>
                      <option value="predictive">Predictive</option>
                    </select>
                    {Object.keys(maintypeErr).map((key) => {
                      if (!maintenace.maintenance_type) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {maintypeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Priority
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="assets"
                      value="low"
                      defaultChecked={showhide === "low"}
                      onClick={handleShow}
                      onChange={(e) => handleProity(e)}
                    />
                    &nbsp;Low
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="assets"
                      value="medium"
                      onClick={handleShow}
                      onChange={(e) => handleProity(e)}
                    />
                    &nbsp; Medium
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="assets"
                      value="high"
                      onClick={handleShow}
                      onChange={(e) => handleProity(e)}
                    />
                    &nbsp; High
                    {Object.keys(priorityErr).map((key) => {
                      if (!maintenace.priority) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {priorityErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Based on
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="based_on"
                      className="form-select"
                      value={maintenace.based_on}
                      onChange={(e) => {
                        setMaintenance({
                          ...maintenace,
                          based_on: e.target.value,
                        });
                        handleBased(e.target.value);
                      }}
                    >
                      {/* <option value="" disabled>
                        Select
                      </option> */}
                      <option value="calendar">Calendar</option>
                      {/* <option value="monitoring">Monitoring</option>
                    <option value="calendar_monitoring">
                      Calendar / Monitoring
                    </option>
                    <option value="adhoc">Adhoc</option> */}
                    </select>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Managed by
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="managed_by"
                      className="form-select"
                      value={maintenace.managed_by}
                      onChange={(e) => {
                        setMaintenance({
                          ...maintenace,
                          managed_by: e.target.value,
                        });
                        handleManagedby(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="inhouse">In house</option>
                      <option value="external">External</option>
                    </select>
                    {Object.keys(managedByErr).map((key) => {
                      if (!maintenace.managed_by) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {managedByErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>

                  <div className="mb-2 col-md-12">
                    <label>Maintenance Duration(Mins)</label>
                    <input
                      name="duration"
                      className="form-control"
                      placeholder="Minutes"
                      value={maintenace.maintenance_duration}
                      onChange={(e) =>
                        setMaintenance({
                          ...maintenace,
                          maintenance_duration: e.target.value,
                        })
                      }
                    />
                    {timeChars(maintenace.maintenance_duration) ||
                      maintenace.maintenance_duration === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Time
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Shutdown Duration(Mins)</label>
                    <input
                      name="duration"
                      className="form-control"
                      placeholder="Minutes"
                      value={maintenace.shutdown_duration}
                      onChange={(e) =>
                        setMaintenance({
                          ...maintenace,
                          shutdown_duration: e.target.value,
                        })
                      }
                    />
                    {timeChars(maintenace.shutdown_duration) ||
                      maintenace.shutdown_duration === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Time
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man power required</label>
                    <input
                      min="1"
                      name="man_power"
                      className="form-control"
                      placeholder="Total man power"
                      value={maintenace.man_power_required}
                      onChange={(e) =>
                        setMaintenance({
                          ...maintenace,
                          man_power_required: e.target.value,
                        })
                      }
                    />
                    {timeChars(maintenace.man_power_required) ||
                      maintenace.man_power_required === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid man power
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man hour required</label>
                    <input
                      min="1"
                      name="man_hour"
                      className="form-control"
                      placeholder="Total man hour"
                      value={maintenace.man_hour_required}
                      onChange={(e) =>
                        setMaintenance({
                          ...maintenace,
                          man_hour_required: e.target.value,
                        })
                      }
                    />
                    {timeChars(maintenace.man_hour_required) ||
                      maintenace.man_hour_required === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid man hour
                          </div>
                        ))}
                  </div>
                  {/* <div className="mb-2 col-md-12">
                    <label>Attachments</label>
                    <br></br>
                    <input
                      className="display-none"
                      label="attachments"
                      type="file"
                    ></input>
                  </div> */}
                  <button
                    className="backButton m-2"
                    type="button"
                    onClick={() => {
                      navigate("/assetlist");
                    }}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
            {showForm && (
              <div className="mx-2 col-md-12">
                <form className="mt-5 border p-4 bg-light shadow rounded col-md-6">
                  <div className="col-md-12 mb-2">
                    <h5>Calendar based on</h5>
                    {/* <button className="float-end delIcons" type="button">
                      <FontAwesomeIcon
                        size="xl"
                        icon={faClose}
                        onClick={handleForm}
                      />
                    </button> */}
                  </div>

                  <label>
                    Frequency
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    name="frequency"
                    className="form-select"
                    value={maintenace.frequency.type}
                    onChange={(e) => {
                      handleFrequency(e.target.value);
                      handleFrecy(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="half_yearly">Half yearly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                  {Object.keys(freqErr).map((key) => {
                    if (!maintenace.frequency.type) {
                      return (
                        <div
                          key={key}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {freqErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {/* Frequency type === Yearly */}
                  {maintenace.frequency.type === "yearly" && (
                    <div className="mt-3">
                      <label>Frequency value</label>
                      <input
                        style={{
                          marginTop: 5,
                          height: 35,
                          paddingLeft: "10px",
                          borderRadius: 5,
                          border: "1px solid #ccc",
                        }}
                        min="1"
                        className="col-md-12"
                        placeholder="Number of years"
                        value={maintenace.frequency.value}
                        onChange={(e) => handleMaintFreqcy(e.target.value)}
                      />
                      {timeChars(maintenace.frequency.value) ||
                        maintenace.frequency.value === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Value
                            </div>
                          ))}
                    </div>
                  )}
                  {/* Frequency type === Weekly */}
                  {maintenace.frequency.type === "weekly" && (
                    <div className="mt-3">
                      <label>Frequency value</label>
                      <input
                        style={{
                          marginTop: 5,
                          height: 35,
                          paddingLeft: "10px",
                          borderRadius: 5,
                          border: "1px solid #ccc",
                        }}
                        min="1"
                        className="col-md-12"
                        placeholder="Number of weeks"
                        value={maintenace.frequency.value}
                        onChange={(e) => handleMaintFreqcy(e.target.value)}
                      />
                      {timeChars(maintenace.frequency.value) ||
                        maintenace.frequency.value === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Value
                            </div>
                          ))}
                    </div>
                  )}
                  {/* Frequency type === Monthly */}
                  {maintenace.frequency.type === "monthly" && (
                    <div className="mt-3">
                      <label>Frequency value</label>
                      <input
                        style={{
                          marginTop: 5,
                          height: 35,
                          paddingLeft: "10px",
                          borderRadius: 5,
                          border: "1px solid #ccc",
                        }}
                        min="1"
                        className="col-md-12"
                        placeholder="Number of months"
                        value={maintenace.frequency.value}
                        onChange={(e) => handleMaintFreqcy(e.target.value)}
                      />
                      {timeChars(maintenace.frequency.value) ||
                        maintenace.frequency.value === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Value
                            </div>
                          ))}
                    </div>
                  )}
                  {/* Frequency type === Quarterly -------------->change backend */}
                  {maintenace.frequency.type === "quarterly" && (
                    <div className="mt-3">
                      <label>Frequency value</label>
                      <input
                        style={{
                          marginTop: 5,
                          height: 35,
                          paddingLeft: "10px",
                          borderRadius: 5,
                          border: "1px solid #ccc",
                        }}
                        min="1"
                        className="col-md-12"
                        placeholder="Number of months"
                        value={maintenace.frequency.value}
                        onChange={(e) => handleMaintFreqcy(e.target.value)}
                      />
                      {timeChars(maintenace.frequency.value) ||
                        maintenace.frequency.value === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Value
                            </div>
                          ))}
                    </div>
                  )}
                  {/* Frequency type === half_yearly -------------->change backend*/}
                  {maintenace.frequency.type === "half_yearly" && (
                    <div className="mt-3">
                      <label>Frequency value</label>
                      <input
                        style={{
                          marginTop: 5,
                          height: 35,
                          paddingLeft: "10px",
                          borderRadius: 5,
                          border: "1px solid #ccc",
                        }}
                        min="1"
                        className="col-md-12"
                        placeholder="Number of months"
                        value={maintenace.frequency.value}
                        onChange={(e) => handleMaintFreqcy(e.target.value)}
                      />
                      {timeChars(maintenace.frequency.value) ||
                        maintenace.frequency.value === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Value
                            </div>
                          ))}
                    </div>
                  )}
                  {/* Schedule End types */}
                  <div className="mt-4 md-6">
                    <label>Maintenance End Types</label>
                    <div>
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px", margin: "8px" }}
                        type="radio"
                        value="no_end"
                        name="endType"
                        defaultChecked={showhide === "no_end"}
                        onClick={handleScheduleEnd}
                      />
                      No End<br></br>
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px", margin: "8px" }}
                        type="radio"
                        value="end_after_occurence"
                        name="endType"
                        onClick={handleScheduleEnd}
                      />
                      End After Occurences
                      {maintenace.frequency.end_type ===
                        "end_after_occurence" && (
                          <input
                            style={{
                              marginLeft: 10,
                              border: "1px solid #CCC",
                              borderRadius: 5,
                              paddingLeft: "8px",
                            }}
                            min="1"
                            placeholder="Occurences"
                            onChange={(e) =>
                              setMaintenance((prevState) => {
                                prevState.frequency.occur_value = e.target.value;
                                return { ...prevState };
                              })
                            }
                          />
                        )}
                      <br></br>
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px", margin: "8px" }}
                        type="radio"
                        value="end_date"
                        name="endType"
                        onClick={handleScheduleEnd}
                      />
                      End Date
                      {maintenace.frequency.end_type === "end_date" && (
                        <input
                          style={{
                            marginLeft: 35,
                            width: 190,
                            border: "1px solid #CCC",
                            borderRadius: 5,
                            height: 30,
                          }}
                          type="date"
                          placeholder="Date"
                          onChange={(e) =>
                            setMaintenance((prevState) => {
                              prevState.frequency.date_value = e.target.value;
                              return { ...prevState };
                            })
                          }
                          min={today}
                        />
                      )}
                    </div>
                  </div>
                  {/* Schedule type */}
                  {/* <div className="mt-4 md-6">
                    <label> Interval Types</label>
                    <div>
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px", margin: "8px" }}
                        type="radio"
                        value="no_end"
                        name="endType"
                        defaultChecked={true}
                        onClick={handleScheduleEnd}
                      />
                      Date Based<br></br>
                      <input
                        className="radioButtons"
                        style={{ marginLeft: "25px", margin: "8px" }}
                        type="radio"
                        value="end_date"
                        name="endType"
                        onClick={handleScheduleEnd}
                      />
                      Week Based
                      {maintenace.frequency.end_type === "end_date" && (
                        <input
                          style={{
                            marginLeft: 35,
                            width: 190,
                            border: "1px solid #CCC",
                            borderRadius: 5,
                            height: 30,
                          }}
                          type="date"
                          placeholder="Date"
                          onChange={(e) =>
                            setMaintenance((prevState) => {
                              prevState.frequency.date_value = e.target.value;
                              return { ...prevState };
                            })
                          }
                          min={today}
                        />
                      )}
                    </div>
                  </div> */}
                </form>
              </div>
            )}
          </div>

          {/* Tasks */}
          {maintenace.maintenance_activities &&
            maintenace.maintenance_activities.map((task, i) => (
              // console.log("index", i) &&
              <div key={i} className="col-md-10">
                <div className="mt-4 p-5 bg-light shadow rounded">
                  <div
                    className="col-md-12"
                    style={{
                      // width: "600px",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {task.ma_type === "pre_maintenance_approval" && (
                      <h4>Pre Maintenance Approval</h4>
                    )}
                    {task.ma_type === "pre_maintenance_check" && (
                      <h4>Pre Maintenance Check</h4>
                    )}
                    {task.ma_type === "maintenance_checkpoint" && (
                      <h4>Maintenance checkpoint</h4>
                    )}
                    {task.ma_type === "spare_change" && <h4>Spare Change</h4>}
                    {task.ma_type === "post_maintenance_check" && (
                      <h4>Post Maintenance Check</h4>
                    )}
                    {task.ma_type === "post_maintenance_approval" && (
                      <h4>Post Maintenance Approval</h4>
                    )}
                    <h6>Task number : {i + 1}</h6>
                    <h6>Sequence #{task.ma_sequence}</h6>
                    {/* delete icon */}
                    <div className="mb-2 col-md-2">
                      <button
                        className="delIcons float-end"
                        type="button"
                        onClick={() => deleteTask(i)}
                      >
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 col-md-12">
                      <label>
                        Maintenance Activity
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="taskname"
                        className="form-control"
                        placeholder="Activity Name"
                        value={task.ma_name}
                        onChange={(e) => {
                          setMaintenance((prevState) => {
                            prevState.maintenance_activities[i].ma_name =
                              e.target.value;
                            return { ...prevState };
                          });
                          // handleAssignee(e.target.value);
                        }}
                      />
                      {Object.keys(maintNameErr).map((key) => {
                        if (!maintenace.maintenance_activities[i].ma_name) {
                          return (
                            <div
                              key={key}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {maintNameErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {nameChars(
                        maintenace.maintenance_activities[i].ma_name
                      ) || maintenace.maintenance_activities[i].ma_name === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid activity name
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-12">
                      <label>SLA</label>
                      <input
                        min="1"
                        name="sla"
                        className="form-control"
                        placeholder="SLA"
                        value={task.ma_sla}
                        onChange={(e) =>
                          setMaintenance((prevState) => {
                            prevState.maintenance_activities[i].ma_sla =
                              e.target.value;
                            return { ...prevState };
                          })
                        }
                      />
                      {slaChars(task.ma_sla) || task.ma_sla === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid SLA
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-12">
                      <label>SLA Interval</label>
                      <select
                        name="interval"
                        className="form-select"
                        value={task.ma_sla_interval}
                        onChange={(e) =>
                          setMaintenance((prevState) => {
                            prevState.maintenance_activities[
                              i
                            ].ma_sla_interval = e.target.value;
                            return { ...prevState };
                          })
                        }
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="hours">Hours</option>
                        <option value="minutes">Minutes</option>
                      </select>
                    </div>
                    {/* {(task.ma_type !== "pre_maintenance_approval" &&
                      task.ma_type !== "post_maintenance_approval") && (
                        <div className="mb-2 col-md-12">
                          <label>SOP</label>
                          <select
                            name="interval"
                            className="form-select"
                            value={task.ma_sop}
                            onChange={(e) =>
                              setMaintenance((prevState) => {
                                prevState.maintenance_activities[
                                  i
                                ].ma_sop = e.target.value;
                                return { ...prevState };
                              })
                            }
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {sopData?.sop?.map((eachData, index) => (
                              <option key={index} value={[eachData.sop, eachData.name]}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "10px", colour: 'red' }}>
                                  <span>{eachData.name}</span>
                                </div>
                              </option>
                            ))}
                          </select>
                          <br />
                          {console.log('SOP array', maintenace.maintenance_activities[i].ma_sop)}
                          {maintenace.maintenance_activities[i].ma_sop && (
                            <button
                              className="addBtn"
                              onClick={() => {
                                setShowDocModel(true);
                                setViewPdf(task.ma_sop);
                              }
                              }>
                              Assign / View Selected SOP
                            </button>
                          )}
                        </div>
                      )} */}
                    {/* <div className="mb-2 col-md-12">
                      <label>Attachments</label>
                      <br></br>
                      <input
                        className="display-none"
                        label="attachments"
                        type="file"
                      ></input>
                    </div> */}
                    {(task.ma_type === "pre_maintenance_approval" ||
                      task.ma_type === "post_maintenance_approval") && (
                        <div>
                          {/* {task.approvals.approval_set.map((appvl, index) => (
                            <div> */}
                          <Approvals
                            maintenanceState={maintenace}
                            setMaintenenceState={setMaintenance}
                            maState={task}
                            maIndex={i}
                          />
                          {/* </div>
                          ))} */}
                        </div>
                      )}

                    {(task.ma_type === "pre_maintenance_check" ||
                      task.ma_type === "maintenance_checkpoint" ||
                      task.ma_type === "post_maintenance_check" ||
                      task.ma_type === "inspection_checkpoint") && (
                        <div>
                          <CheckPoints
                            maintenanceState={maintenace}
                            setMaintenenceState={setMaintenance}
                            maState={task}
                            maIndex={i}
                            addCpItem={(maIndex) => addCheckPoints(maIndex)}
                            deleteCpItem={(maIndex, cpIndex) =>
                              deleteCheckPoints(maIndex, cpIndex)
                            }
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}

          {/* Submit Schedule Button */}
          <div className="mt-10 col-md-12 p-2">
            {/* {maintenace.based_on === "calendar" && ( */}
            <div className="dropdown">
              <button
                style={{ fontSize: "14px" }}
                className="float-end addBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add Activites
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="pre_maintenance_approval"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "1");
                      // setDisabled(true);
                    }}
                  >
                    Pre Maintenance Approval
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="pre_maintenance_check"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "2");
                      // setDisabled(true);
                    }}
                  >
                    Pre Maintenance Check
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="maintenance_checkpoint"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "3");
                      // setDisabled(true);
                    }}
                  >
                    Maintenance Checkpoint
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="post_maintenance_check"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "5");
                      // setDisabled(true);
                    }}
                  >
                    Post Maintenance Check
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="post_maintenance_approval"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "6");
                      // setDisabled(true);
                    }}
                  >
                    Post Maintenance Approval
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="spare_change"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "4");
                      // setDisabled(true);
                    }}
                  >
                    Spare Change
                  </button>
                </li>
              </ul>
            </div>
            {/* )} */}
          </div>
          <div className="mt-10 col-md-12 p-2">
            <button
              className="saveButton float-end"
              onClick={(e) => submitMaintenance(e)}
            >
              Submit
            </button>
            {/* <button
              className="backButton mt-4"
              type="button"
              onClick={() => {
                navigate("/assetlist");
              }}
            >
              Back
            </button> */}
          </div>
        </div>
      </div>
      {showDocModel && <PdfDocView
        show={showDocModel}
        onHide={() => setShowDocModel(false)}
        viewlink={viewPdf}
      />}
    </div>
  );
};

export default UploadMaintenance;
