import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
// import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function SRS({ responseData, filterData, chklname, docNumber, depname ,data6,data4, logo ,instrumentCode,malur,medopharm,header2,headingName2}) {

    const [resData, setResData] = useState([])
    console.log("responseData",responseData)

    const data1 = {
        data: depname,
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }
    const data3 = {
        data: `${chklname} FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
        colSpan: '7'
    }

    let frequency = [{ data: `Frequency -  ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'left', rowSpan: '1', colSpan: '1' }]
    let frequency2 =[]

    let tableResData = responseData?.checklist?.map((item) => {
        return {
            data0: {
                value: item?.start_date  && item?.start_data!==''? moment(item.start_date).local().format("DD/MM/YY") : '-',
                style: { textAlign: 'center' },
            },
            data3: {
                value: item?.checkpoints[0]?.answer !== '' ? item?.checkpoints[0]?.answer : '-',
                style: { textAlign: 'center', colSpan: '1' },
            },
            data5: {
                value:  item?.checkpoints[1]?.answer !== '' && item?.checkpoints?.length==5 ? item?.checkpoints[1]?.answer : '-',
                style: { textAlign: 'center' },
            },
            data6: {
                value:  item?.checkpoints[2]?.answer !== '' && item?.checkpoints?.length==5 ? item?.checkpoints[2]?.answer : '-',
                style: { textAlign: 'center' },
            },
            data7: {
                value: item?.checkpoints?.length==5 && item?.checkpoints[3]?.answer!==''?item?.checkpoints[3]?.answer:(item?.checkpoints?.length==3 && item?.checkpoints[1]?.answer!==''?item?.checkpoints[1]?.answer:'-'), 
                style: { textAlign: 'center' },
            },
            data8: {
                value: item?.checkpoints?.length==5 && item?.checkpoints[4]?.answer!==''?item?.checkpoints[4]?.answer:(item?.checkpoints?.length==3 && item?.checkpoints[2]?.answer!==''?item?.checkpoints[2]?.answer:'-'), 
                style: { textAlign: 'center' },
            },
            data9: {
                value: item?.performed_by?.length > 0 ? item.performed_by : '-',
                style: { textAlign: 'center' },
            },
            data10: {
                value: item?.ins_approval_id?.length > 0 ? item.ins_approval_id : '-',
                style: { textAlign: 'center' },
            },
        };
    });

    if(docNumber=='MAINT/006:F8-01'){
        tableResData = responseData?.checklist?.map((item ,i) => {
            return {
                data0: {
                    value: i+1,
                    style: { textAlign: 'center' },
                },
                data1: {
                    value: item?.start_date  && item?.start_data!==''? moment(item.start_date).local().format("DD/MM/YY") : '-',
                    style: { textAlign: 'center', colSpan: '1' },
                },
                data2: {
                    value:  item?.checkpoints[0]?.answer !== '' ? item?.checkpoints[0]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data3: {
                    value: item?.performed_by?.length > 0 ? item.performed_by : '-',
                    style: { textAlign: 'center' },
                },
                data4: {
                    value: item?.ins_approval_id?.length > 0 ? item.ins_approval_id : '-',
                    style: { textAlign: 'center' },
                },
            };
        });
    }else if (docNumber=='MAINT/006:F10-02'){
        frequency = [];
        var heading1 = [
            { data: `Frequency -  ${responseData?.maint_checklist && responseData?.maint_checklist[0]?.frequency?.frequency_type !== undefined ? responseData?.maint_checklist[0]?.frequency?.frequency_type : ''}`, textAlign: 'left', rowSpan: '2', colSpan: '1' },
            { data: `SANITATION TEMPERATURE : 80°C and above`, textAlign: 'left', rowSpan: '1', colSpan: '1' }
        ]
        var heading2 = [
            { data: `VENT FILTER TEMPERATURE : For monitoring purpose`, textAlign: 'left', rowSpan: '1', colSpan: '1' },
        ]
        tableResData = responseData?.checklist?.map((item ,i) => {
            return {
                data1: {
                    value: item?.start_date  && item?.start_data!==''? moment(item.start_date).local().format("DD/MM/YY") : '-',
                    style: { textAlign: 'center', colSpan: '1' },
                },
                data2: {
                    value:  item?.checkpoints[0]?.answer !== '' ? item?.checkpoints[0]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data3: {
                    value:  item?.checkpoints[1]?.answer !== '' ? item?.checkpoints[1]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data4: {
                    value:  item?.checkpoints[2]?.answer !== '' ? item?.checkpoints[2]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data5: {
                    value:  item?.checkpoints[3]?.answer !== '' ? item?.checkpoints[3]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data6: {
                    value:  item?.checkpoints[4]?.answer !== '' ? item?.checkpoints[4]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data7: {
                    value:  item?.checkpoints[5]?.answer !== '' ? item?.checkpoints[5]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'4' },
                },
                data8: {
                    value: item?.performed_by?.length > 0 ? item.performed_by : '-',
                    style: { textAlign: 'center' },
                },
                data9: {
                    value: item?.ins_approval_id?.length > 0 ? item.ins_approval_id : '-',
                    style: { textAlign: 'center' },
                },
            };
        });
    }else if (docNumber=="MAINT/004:F5-01"){
        frequency = [];
        tableResData = responseData?.checklist?.map((item ,i) => {
            return {
                data1: {
                    value: item?.start_date  && item?.start_data!==''? moment(item.start_date).local().format("DD/MM/YY") : '-',
                    style: { textAlign: 'center', colSpan: '1' },
                },
                data2: {
                    value:  item?.checkpoints[0]?.answer !== '' ? item?.checkpoints[0]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'1' },
                },
                data3: {
                    value:  item?.checkpoints[1]?.answer !== '' ? item?.checkpoints[1]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'1' },
                },
                data4: {
                    value:  item?.checkpoints[2]?.answer !== '' ? item?.checkpoints[2]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'1' },
                },
                data5: {
                    value:  item?.checkpoints[3]?.answer !== '' ? item?.checkpoints[3]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'1' },
                },
                data6: {
                    value:  item?.checkpoints[4]?.answer !== '' ? item?.checkpoints[4]?.answer : '-',
                    style: { textAlign: 'center',colSpan:'1' },
                },
                data8: {
                    value: item?.performed_by?.length > 0 ? item.performed_by : '-',
                    style: { textAlign: 'center' },
                },
                data9: {
                    value: item?.ins_approval_id?.length > 0 ? item.ins_approval_id : '-',
                    style: { textAlign: 'center' },
                },
            };
        });
    }

    console.log("heading1",heading1,"heading2",heading2)

    useEffect(() => {
        setResData(responseData)
    }, [responseData])


    return (
        <div>
           <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={logo}
                malur={malur}
                medopharm={medopharm}
            />
            {instrumentCode!==true && <ReportTable
                headerData={data4}
            
            />}
             {headingName2 !== '' && headingName2 !== undefined ? <ReportTable
                headerData={frequency}
            /> : ''}

            {docNumber=='MAINT/006:F10-02'?<ReportTable
                headerData={heading1}
                headerData2={heading2}
            />:''}
            <ReportTable
                headerData={data6}
                headerData2={header2}
            />
            <ReportTable
                // headerData2={data6}
                verticalBodyData={tableResData}
            />
        </div>
    )
}


export default SRS
