import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCalendarDays,
    faAngleRight,
    faAngleLeft,
    faCancel,
    faClose,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import BackButton from "../../components/BackButton/BackButton";

//Function to fetch all asset
function AssetClass() {
    //For Search and filter by murthi new
    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.user.userData);
    const access = useSelector((state) => state.access.user);
    const role = useSelector((state) => state.auth.role);
    const assetclass = useSelector((state) => state.FA.assetClass)
    // console.log(assetclass, "assetclass")

    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    // Calculate the total number of pages
    let totalPages = 0;
    var data1List = [];
    if (assetclass?.result !== undefined) {
        data1List = assetclass?.result;
    }
    if (assetclass?.total_count !== undefined) {
        totalPages = Math.ceil(assetclass?.total_count / itemsPerPage);
    }
    // console.log('data1List', data1List);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    //useEffect
    useEffect(() => {


        dispatch({
            type: "GET_CLASS_LIST",
            payload: [currentPage, itemsPerPage],
        })

    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        const roleCode = role[0];
        // console.log(roleCode);
        if (access === null && roleCode !== null) {
            dispatch({
                type: "ACCESS_GET_LIST",
                payload: roleCode,
            });
        }
    }, [role]);

    useEffect(() => {
        // console.log(access);
        // console.log("userData", userData);
    }, [access]);

    return (
        <div>
            {/* Table to view user list */}
            <div className="col-md-12">
                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        paddingLeft: "100px",
                    }}
                >
                    Asset Class List
                    <div style={{ float: "right" }}>

                        {/* ................. SEARCH ................. */}
                        {/* <form
                            className="mb-4"
                        >
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchInput}

                                style={{
                                    border: "1px solid steelblue",
                                    fontSize: "15px",
                                    padding: "5px",
                                }}
                            />

                            
                            <span
                                type="reset"

                                style={{ paddingLeft: "8px", marginRight: "25px" }}
                            >
                                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                            </span>
                        </form> */}
                    </div>
                    <div>Create & View</div>
                </div>
                <BackButton />
                {/* Table to view asset list */}
                <div className="mb-6">
                    <table className="table-bordered" width="100%">
                        <thead>
                            {/* Table heading to display list of Site */}
                            <tr
                                style={{
                                    textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                }}
                            >
                                <th style={{ padding: "8px", width: "70px" }}>S. No</th>
                                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                                   Class Code
                                </th>
                                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                                    Class Name
                                </th>
                                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                                    Useful Life in Months
                                </th>
                                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                                    Rate (%)
                                </th>
                            </tr>
                        </thead>
                        {/* {console.log(currentItems)} */}
                        {data1List !== null && data1List?.length > 0 ? (
                            data1List.map((eachData, index) => {
                                return (
                                    <tbody key={eachData._id} style={{ padding: "15px" }}>
                                        <tr style={{ padding: "20px" }}>
                                            {/* function Double click on row to view all asset atribute other than availbe on table.  */}
                                            <td style={{ textAlign: "center", padding: "4px" }}>
                                                {(currentPage - 1) * itemsPerPage + (index + 1)}
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                                                {eachData.code}
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                                                {eachData.class}
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                                                {eachData.life}
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                                                {eachData.rate}
                                            </td>
                                        </tr>
                                    </tbody>
                                );
                            })
                        ) : (
                            <tbody>
                                <tr style={{ textAlign: "center", height: "75px" }}>
                                    <td colSpan="6">No Data Available</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                    <div>
                        {/* pagination */}
                        {data1List !== null && data1List.length > 0 ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                    padding: "10px",
                                }}
                            >
                                <div className="mb-6">
                                    <label>Items per page:</label> &nbsp;
                                    <select
                                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                        style={{ borderRadius: "8px", cursor: "pointer" }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div style={{ paddingRight: "25px" }} className="pagination">
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            border: "none",
                                            backgroundColor: "#fff",
                                        }}
                                        disabled={currentPage === 1}
                                        onClick={handleFirstPage}
                                    >
                                        {currentPage === 1 ? (
                                            <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                                    </button>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            border: "none",
                                            backgroundColor: "#fff",
                                        }}
                                        disabled={currentPage === 1}
                                        onClick={handlePrev}
                                    >
                                        {/* Prev */}
                                        {currentPage === 1 ? (
                                            <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                    </button>
                                    <span style={{ padding: "10px" }}>
                                        Showing {currentPage} of {totalPages} pages
                                    </span>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            border: "none",
                                        }}
                                        disabled={currentPage === totalPages}
                                        onClick={handleNext}
                                    >
                                        {currentPage === totalPages ? (
                                            <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                                    </button>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            border: "none",
                                        }}
                                        disabled={currentPage === totalPages}
                                        onClick={handleLastpage}
                                    >
                                        {currentPage === totalPages ? (
                                            <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AssetClass;