import {
  CUST_GET_LIST,
  CUST_GET_SUCCESS,
  CUST_GET_FAILED,

  CUST_DELETE_LIST,
  CUST_DELETE_SUCCESS,
  CUST_DELETE_FAILED,

  CUST_ADD,
  CUST_ADD_SUCCESS,
  CUST_ADD_FAILED,

  CUST_CONTACT_DETAILS,
  CUST_CONTACT_DETAILS_SUCCESS,
  CUST_CONTACT_DETAILS_FAILED,

  CUST_CONTACT_GET,
  CUST_CONTACT_GET_SUCCESS,
  CUST_CONTACT_GET_FAILED,

  CUST_UPDATE,
  CUST_UPDATE_SUCCESS,
  CUST_UPDATE_FAILED,

  CUST_GET_SINGLE,
  CUST_GET_SINGLE_SUCCESS,
  CUST_GET_SINGLE_FAILED,
} from "../actions/custAction";

const inistialCustState = {
  custData: [],
  custAddData: {},
  custUpdateData: {},
  id: "",
  contactId: "",
  customerContactGet: [],
  customerContactDetails: null,
  custGetSingle: {},
  isLoading: false,
  isSubmitted: false,
};

export default function CustReducer(state = inistialCustState, action) {
  switch (action.type) {
    case CUST_GET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case CUST_GET_SUCCESS:
      return {
        ...state,
        custData: action.payload.data,
        isLoading: false,
      };
    case CUST_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CUST_DELETE_LIST:
      return {
        ...state,
      };
    case CUST_DELETE_SUCCESS:
      return {
        ...state,
      };
    case CUST_DELETE_FAILED:
      return {
        ...state,
      };
    case CUST_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case CUST_ADD_SUCCESS:
      return {
        ...state,
        custAddData: action.payload.subscriberData,
        contactId: action.payload.customerId,
        isLoading: false,
      };
    case CUST_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CUST_CONTACT_GET:
      return {
        ...state,
        isLoading: true,
      };
    case CUST_CONTACT_GET_SUCCESS:
      return {
        ...state,
        customerContactGet: action.payload,
        contactId: null,
        isLoading: false,
      };
    case CUST_CONTACT_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CUST_CONTACT_DETAILS:
      return {
        ...state,
        isLoading: true,
        isSubmitted: true,
      };

    case CUST_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        customerContactDetails: action.payload.contactdetails,
        isLoading: false,
      };

    case CUST_CONTACT_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CUST_GET_SINGLE:
      return {
        ...state,
        isLoading: true,
      };
    case CUST_GET_SINGLE_SUCCESS:
      return {
        ...state,
        custGetSingle: action.payload,
        isLoading: false,
      };
    case CUST_GET_SINGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CUST_UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case CUST_UPDATE_SUCCESS:
      return {
        ...state,
        custUpdateData: action.payload,
      };
    case CUST_UPDATE_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case "CLEAR_CUST_REDUCER":
      return {
        ...state,
        custData: [],
        custAddData: {},
        custUpdateData: {},
        id: "",
        contactId: "",
        customerContactGet: [],
        customerContactDetails: null,
        custGetSingle: {},
      }
    default:
      return state;
  }
}
