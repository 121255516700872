//Feature - Form to update/modify Customer details

//Created by - Janaki J
//Updated by - Janaki J

//importing dependencies and packages
import { useEffect, useState, React } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CUST_UPDATE } from "../../redux/actions/custAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";

//Function to update customer
function CustomerUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // To fetch details from redux
  const custGetSingle = useSelector((state) => state.customer.custGetSingle);
  const userId = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.customer.isLoading);
  const access = useSelector((state) => state.access.customerAccess);

  // console.log("access", access);

  //client-side validation
  const [clegalnameErr, setClegalnameErr] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [regionErr, setRegionErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [zcodeErr, setZcodeErr] = useState("");

  const formValidation = () => {
    const clegalnameErr = {};
    const websiteErr = {};
    const addressErr = {};
    const regionErr = {};
    const cityErr = {};
    const countryErr = {};
    const stateErr = {};
    const zcodeErr = {};

    if (data.clegalname.trim().length < 1) {
      clegalnameErr.clegalnameShort = "Please Enter Legal Name";
      isValid = false;
    }
    setClegalnameErr(clegalnameErr);

    if (data.cwebsite.trim().length < 1) {
      websiteErr.cwebsiteShort = "Please Enter website";
      isValid = false;
    }
    setWebsiteErr(websiteErr);

    if (data.caddress.trim().length < 1) {
      addressErr.caddressShort = "Please Enter Address";
      isValid = false;
    }
    setAddressErr(addressErr);

    if (data.cregion.trim().length < 1) {
      regionErr.cregionShort = "Please Enter Region";
      isValid = false;
    }
    setRegionErr(regionErr);

    if (data.ccity.trim().length < 1) {
      cityErr.ccityShort = "Please Enter City";
      isValid = false;
    }
    setCityErr(cityErr);

    if (data.ccountry.trim().length < 1) {
      countryErr.countryShort = "Please Enter Country";
      isValid = false;
    }
    setCountryErr(countryErr);

    if (data.cstate.trim().length < 1) {
      stateErr.stateShort = "Please Enter State";
      isValid = false;
    }
    setStateErr(stateErr);
    if (data.czcode.toString().trim().length !== 6) {
      zcodeErr.zcodeShort = "Please Enter Valid Zipcode";
      isValid = false;
    }
    setZcodeErr(zcodeErr);

    return isValid;
  };
  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };
  const [data, setData] = useState({
    cust_id: "",
    clegalname: "",
    cwebsite: "",
    caddress: "",
    cregion: "",
    ccity: "",
    ccountry: "",
    cstate: "",
    czcode: "",
    cgeo: "",
    ccriticality: "",
  });

  let isValid = true;

  //Functions to handle form inputs
  const handleLegalName = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        clegalname: val,
      });
    } else {
      setData({
        ...data,
        clegalname: val,
      });
    }
  };
  const handleWebsite = (val) => {
    setData({
      ...data,
      cwebsite: val,
    });
  };
  const handleAddress = (val) => {
    setData({
      ...data,
      caddress: val,
    });
  };
  const handleRegion = (val) => {
    setData({
      ...data,
      cregion: val,
    });
  };
  const handleCity = (val) => {
    setData({
      ...data,
      ccity: val,
    });
  };
  const handleCountry = (val) => {
    setData({
      ...data,
      ccountry: val,
    });
  };
  const handleState = (val) => {
    setData({
      ...data,
      cstate: val,
    });
  };
  const handleZipCode = (val) => {
    setData({
      ...data,
      czcode: val,
    });
  };
  const handleGeo = (val) => {
    setData({
      ...data,
      cgeo: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      ccriticality: val,
    });
  };

  //Function to update customer details
  const updateCustomer = (e) => {
    e.preventDefault();
    let customerData = {
      subscriber_id: custGetSingle._id,
      legal_name: custGetSingle.legal_name,
      website: data.cwebsite,
      address: data.caddress,
      region: data.cregion,
      city: data.ccity,
      country: data.ccountry,
      state: data.cstate,
      zip_code: data.czcode,
      geo_address: data.cgeo,
      criticality_rating: data.ccriticality,
      modified_by: userId,
    };
    validation();
    // console.log(customerData);
    // console.log(clegalnameErr, websiteErr, addressErr, regionErr, cityErr, countryErr, stateErr, zcodeErr);
    // console.log(isValid);
    if (isValid) {
      dispatch({
        type: CUST_UPDATE,
        payload: customerData,
      });
      navigate("/customerlist");
    }
  };

  //fetch customer ID
  const sendID = () => {
    if (id !== null) {
      dispatch({
        type: "CUST_GET_SINGLE",
        payload: id,
      });
    }
  };

  //useEffects
  useEffect(() => {
    sendID();
  }, [id]);

  //useEffects
  useEffect(() => { }, [data]);

  useEffect(() => {
    setData({
      cust_id: custGetSingle._id,
      clegalname: custGetSingle.legal_name,
      cwebsite: custGetSingle.website,
      caddress: custGetSingle.address,
      cregion: custGetSingle.region,
      ccity: custGetSingle.city,
      ccountry: custGetSingle.country,
      cstate: custGetSingle.state,
      czcode: custGetSingle.zip_code,
      cgeo: custGetSingle.geo_address,
      ccriticality: custGetSingle.criticality_rating,
    });
  }, [custGetSingle]);

  // Regex website tester: https://www.regextester.com/ - Blue color shows it pass. If it turns to white color then its invalid regex
  // Regex website generation: Refer internet

  function containSpeclCustName(str) {
    const specialChars = /^([a-zA-Z0-9-@']+\s)*[a-zA-Z0-9-@']+$/;
    return specialChars.test(str);
  }
  function containSpeclChars(str) {
    const specialChars =
      /^([a-zA-Z-]+\s)*[a-zA-Z-]+$/;
    return specialChars.test(str);
    //^[a-zA-z]+([\s][a-zA-Z]+)*$
    // ^[a-zA-z]+([\s][a-zA-Z]+)*$
  }
  function zcodeChars(str) {
    const zipChars = /^[1-9][0-9]{5}$/;
    return zipChars.test(str);
  }
  function websiteChars(str) {
    const webChars =
      /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=-]*)$/;
    // /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-]+\.[a-z.]+(\/[a-zA-Z0-9-#:=?]+\/?)*$/;
    // /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return webChars.test(str);
  }
  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to show Loading effect */}
      <BackButton />
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div style={{ paddingTop: "55px" }}>
              {access !== null && access.u ? (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                >
                  <div className="formHeadings mb-2">Edit Customer</div>
                  <div className="row">
                    <div className="mb-2 col-md-6">
                      <label>
                        Customer Legal Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="clegalname"
                        className="form-control"
                        onChange={(e) => handleLegalName(e.target.value)}
                        defaultValue={data.clegalname}
                      />
                      {Object.keys(clegalnameErr).map((key) => {
                        if (!data.clegalname) {
                          return (
                            <div
                              key={key}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {clegalnameErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {containSpeclCustName(data.clegalname) ||
                        data.clegalname === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Customer name
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Website
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="cwebsite"
                        className="form-control"
                        onChange={(e) => handleWebsite(e.target.value)}
                        defaultValue={data.cwebsite}
                      />
                      {Object.keys(websiteErr).map((key) => {
                        if (!data.cwebsite) {
                          return (
                            <div
                              key={key}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {websiteErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {websiteChars(data.cwebsite) || data.cwebsite === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                padding: "2px",
                              }}
                            >
                              Invalid Website
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Address
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="caddress"
                        className="form-control"
                        onChange={(e) => handleAddress(e.target.value)}
                        defaultValue={data.caddress}
                      />
                      {Object.keys(addressErr).map((key) => {
                        if (!data.caddress) {
                          return (
                            <div
                              key={key}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {addressErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>Region</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="cregion"
                        className="form-control"
                        onChange={(e) => handleRegion(e.target.value)}
                        defaultValue={data.cregion}
                      />
                      {Object.keys(regionErr).map((key) => {
                        if (!data.cregion) {
                          return (
                            <div
                              key={key}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {regionErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {containSpeclChars(data.cregion) || data.cregion === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Region name
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        City
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="ccity"
                        className="form-control"
                        onChange={(e) => handleCity(e.target.value)}
                        defaultValue={data.ccity}
                      />
                      {containSpeclChars(data.ccity) || data.ccity === "" ? (
                        ""
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            padding: "2px",
                          }}
                        >
                          Invalid City
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Country
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="ccountry"
                        className="form-control"
                        onChange={(e) => handleCountry(e.target.value)}
                        defaultValue={data.ccountry}
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        State
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="cstate"
                        className="form-control"
                        onChange={(e) => handleState(e.target.value)}
                        defaultValue={data.cstate}
                      />
                      {containSpeclChars(data.cstate) || data.cstate === "" ? (
                        ""
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            padding: "2px",
                          }}
                        >
                          Invalid state
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Zipcode
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        maxLength={6}
                        minLength={6}
                        pattern="[0-9]{6,}"
                        name="czcode"
                        className="form-control"
                        onChange={(e) => handleZipCode(e.target.value)}
                        placeholder="Eg. 5600XX"
                        defaultValue={data.czcode}
                      />
                      {Object.keys(zcodeErr).map((key) => {
                        if (!data.czcode) {
                          return (
                            <div
                              key={key}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {zcodeErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {zcodeChars(data.czcode) || data.czcode === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Zipcode
                            </div>
                          ))}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="ccriticality">Criticality Rating</label>
                      <select
                        name="ccriticality"
                        id="ccriticality"
                        className="form-select"
                        onChange={(e) => handleCriticality(e.target.value)}
                        placeholder="Choose any one"
                        value={data.ccriticality}
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-12">
                      <button
                        onClick={() => navigate("/customerlist")}
                        className="backButton"
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="float-end saveButton"
                        onClick={(e) => updateCustomer(e)}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                >
                  <div className="formHeadings mb-2">View Customer</div>
                  <div className="row">
                    <div className="mb-2 col-md-6">
                      <label>
                        Customer Legal Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="clegalname"
                        className="form-control"
                        onChange={(e) => handleLegalName(e.target.value)}
                        defaultValue={data.clegalname}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Website
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="cwebsite"
                        className="form-control"
                        onChange={(e) => handleWebsite(e.target.value)}
                        defaultValue={data.cwebsite}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Address
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="caddress"
                        className="form-control"
                        onChange={(e) => handleAddress(e.target.value)}
                        defaultValue={data.caddress}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>Region</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="cregion"
                        className="form-control"
                        onChange={(e) => handleRegion(e.target.value)}
                        defaultValue={data.cregion}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        City
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="ccity"
                        className="form-control"
                        onChange={(e) => handleCity(e.target.value)}
                        defaultValue={data.ccity}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Country
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="ccountry"
                        className="form-control"
                        onChange={(e) => handleCountry(e.target.value)}
                        defaultValue={data.ccountry}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        State
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="cstate"
                        className="form-control"
                        onChange={(e) => handleState(e.target.value)}
                        defaultValue={data.cstate}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Zipcode
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        pattern="[0-9]{6,}"
                        onChange={(e) => handleZipCode(e.target.value)}
                        placeholder="Eg. 5600XX"
                        defaultValue={data.czcode}
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="ccriticality">Criticality Rating</label>
                      <select
                        name="ccriticality"
                        id="ccriticality"
                        className="form-select"
                        onChange={(e) => handleCriticality(e.target.value)}
                        placeholder="Choose any one"
                        value={data.ccriticality}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-12">
                      <button
                        onClick={() => navigate("/customerlist")}
                        className="backButton"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//exporting component
export default CustomerUpdate;
