import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Commonheader from "../../layouts/common/commonHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import InspectionHistory from "../history/inspHistory";
import MaintHistory from "../history/maintHistory";

function AssetHistory() {
  const [key, setKey] = useState("inspection");
  const isLoading = useSelector((state) => state.asset.isLoading);

  return (
    <div>
      <Commonheader />
      {isLoading && (
        <div className="">
          <Loader />
        </div>
      )}
      {
        <div className="pt-5">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="m-3 headings"
          >
            <Tab eventKey="inspection" title="Inspection">
              <InspectionHistory eventKey={key} />
            </Tab>
            <Tab eventKey="maintenance" title="Maintenance">
              <MaintHistory eventKey={key} />
            </Tab>
          </Tabs>
        </div>
      }
    </div>
  );
}

export default AssetHistory;
