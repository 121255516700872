// get status of report based on user
const REP_BASEDON_USER_STATUS = 'REP_BASEDON_USER_STATUS';
const REP_BASEDON_USER_STATUS_SUCCESS = 'REP_BASEDON_USER_STATUS_SUCCESS';
const REP_BASEDON_USER_STATUS_FAILED = 'REP_BASEDON_USER_STATUS_FAILED';


// Custom report builder API action
const GET_COLLECTIONS = 'GET_COLLECTIONS';
const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS';
const GET_COLLECTIONS_FAILED = 'GET_COLLECTIONS_FAILED';


const GET_QUERY = 'GET_QUERY';
const GET_QUERY_SUCCESS = 'GET_QUERY_SUCCESS';
const GET_QUERY_FAILED = 'GET_QUERY_FAILED';


const GET_ATTRIBUTES = 'GET_ATTRIBUTES';
const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS';
const GET_ATTRIBUTES_FAILED = 'GET_ATTRIBUTES_FAILED';

const GET_FIELD_NAME = 'GET_FIELD_NAME';
const GET_FIELD_NAME_SUCCESS = 'GET_FIELD_NAME_SUCCESS';
const GET_FIELD_NAME_FAILED = 'GET_FIELD_NAME_FAILED';


const GET_REPORT = 'GET_REPORT';
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
const GET_REPORT_FAILED = 'GET_REPORT_FAILED';

const SAVE_QUERY = 'SAVE_QUERY';
const SAVE_QUERY_SUCCESS = 'SAVE_QUERY_SUCCESS';
const SAVE_QUERY_FAILED = 'SAVE_QUERY_FAILED';

const UPDATE_QUERY = 'UPDATE_QUERY';
const UPDATE_QUERY_SUCCESS = 'UPDATE_QUERY_SUCCESS';
const UPDATE_QUERY_FAILED = 'UPDATE_QUERY_FAILED';

const DELETE_QUERY = 'DELETE_QUERY';
const DELETE_QUERY_SUCCESS = 'DELETE_QUERY_SUCCESS';
const DELETE_QUERY_FAILED = 'DELETE_QUERY_FAILED';

const GET_QUERY_LIST = 'GET_QUERY_LIST';
const GET_QUERY_LIST_SUCCESS = 'GET_QUERY_LIST_SUCCESS';
const GET_QUERY_LIST_FAILED = 'GET_QUERY_LIST_FAILED';

const SAVE_REPORT = 'SAVE_REPORT';
const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
const SAVE_REPORT_FAILED = 'SAVE_REPORT_FAILED';



export {
    REP_BASEDON_USER_STATUS,
    REP_BASEDON_USER_STATUS_SUCCESS,
    REP_BASEDON_USER_STATUS_FAILED,
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAILED,
    GET_QUERY,
    GET_QUERY_SUCCESS,
    GET_QUERY_FAILED,
    GET_ATTRIBUTES,
    GET_ATTRIBUTES_SUCCESS,
    GET_ATTRIBUTES_FAILED,
    GET_FIELD_NAME,
    GET_FIELD_NAME_SUCCESS,
    GET_FIELD_NAME_FAILED,
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_FAILED,
    SAVE_QUERY,
    SAVE_QUERY_SUCCESS,
    SAVE_QUERY_FAILED,
    GET_QUERY_LIST,
    GET_QUERY_LIST_SUCCESS,
    GET_QUERY_LIST_FAILED,
    UPDATE_QUERY,
    UPDATE_QUERY_SUCCESS,
    UPDATE_QUERY_FAILED,
    DELETE_QUERY,
    DELETE_QUERY_SUCCESS,
    DELETE_QUERY_FAILED,
    SAVE_REPORT,
    SAVE_REPORT_FAILED,
    SAVE_REPORT_SUCCESS,
}