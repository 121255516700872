/* Feature - service / api call for login

Created by - Mona R
Updated by - Mona R */

import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from '../../constants/URLs/urlConstants';

function logIn(userData) {
    // console.log("login data",userData);
    return serviceBase.post(CONSTANTS.AUTH_PATH, userData);
};
function logOut(data) {
    // console.log("logout string",data);
    return serviceBase.get(CONSTANTS.LOGOUT_PATH, data);
};

export const authentication = {
    logIn,
    logOut
}