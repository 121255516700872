import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";

const S3BucketImage = ({ imageUrl, widthSize, paddingSize }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Fetch image from S3 bucket using the provided URL
    /////////////////////////AWS Operations starts/////////////////////////
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      apiVersion: "latest",
      credentials: {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      },
    });
    const s3 = new AWS.S3(); // Initialize AWS SDK with your credentials
    let bucketURL = process.env.REACT_APP_BUCKET_URL;
    let ImageKey = imageUrl?.replace(bucketURL, "")
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: ImageKey,
    };
    s3.getObject(params, (err, data) => {
      if (err) {
        // console.error(err);
        return;
      }
      const imageBlob = new Blob([data.Body], { type: data.ContentType });
      setImageSrc(URL.createObjectURL(imageBlob));
    });
    return () => {
      // Clean up any resources if needed
      URL.revokeObjectURL(imageSrc);
    };
  }, [imageUrl]);

  return (
    <div>
      {imageSrc !== null &&
        <img
          src={imageSrc}
          alt="logo"
          style={{
            width: widthSize,
            maxHeight: "70px",
            maxWidth: "100px",
            verticalAlign: "middle",
            padding: paddingSize
          }}
        />
      }
    </div>
  );
};

export default S3BucketImage;
