//importing dependencies and packages
import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import Axios from "axios";
import Loader from '../../components/loader/loader'


// function to set new password
function SetNewPass() {
  const navigate = useNavigate();

  // hide the content after invalid code state.

  const [showForm, setshowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // validate the token once.

  const { id } = useParams();

  const fetchId = useCallback(() => {
    return id;
  }, [id]);

  useEffect(() => {
    // token validation API
    const activationUrl =
    process.env.REACT_APP_BASE_URL + "reset/validateActivationCode";
    var data1 = JSON.stringify({
      activation_code: id,
    });

    var config = {
      method: "post",
      url: activationUrl,
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };

    Axios(config).then(function (response) {
        // console.log(JSON.stringify(response.data));
        // console.log(response.data);
        const valid_code = response.data === "valid activation code";
        // console.log(valid_code);
        if (valid_code) {
          setshowForm(true);
        } else {
          alert("Invalid code");
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
    fetchId();
  }, [id, fetchId]);

  // post API for password update

  const passwordUrl = process.env.REACT_APP_BASE_URL + "reset/updateUserPass";

  // handle eye off icon for password
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  // handle eye off icon for confirm password
  const [type1, setType1] = useState("password");
  const [icon1, setIcon1] = useState(eyeOff);
  const handleToggle1 = () => {
    if (type1 === "password") {
      setIcon1(eye);
      setType1("text");
    } else {
      setIcon1(eyeOff);
      setType1("password");
    }
  };

  // validation client side

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  var data = JSON.stringify({
    activation_code: id,
    pass: password,
  });

  // handle submit & update the password.
  const handleSubmit = async (e) => {
    // if (e.includes(" ")) {
    //   e = e.replace(/\s/g, "");
    // }
    e.preventDefault();
    const isValid = formValidation();
    if (isValid === true) {
      setIsLoading(true);
      try {
        var config = {
          method: "post",
          url: passwordUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        Axios(config)
          .then(function (response) {
            if (isValid) {
              alert("Password reset Successfully");
            }
            // console.log(JSON.stringify(response.data));
            // console.log(response.data._id);
            navigate("/");
          })
          .catch(function (error) {
            // console.log(error);
          });
      } catch (error) {
        // console.log(error.response);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // function to prevent space as an input.
  const handleSpace = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  let isValid = true;

  // validation cliend side
  const formValidation = () => {
    const passwordErr = {};
    const confirmPasswordErr = {};
    // const passwordregexErr = {};

    if (password.trim().length < 1) {
      passwordErr.passwordShort = "Password can not be either empty or space";
      isValid = false;
    }    
    if (confirmPassword !== password) {
      confirmPasswordErr.confirmPasswordShort = "Confirm password is not matching with given password";
      isValid = false;
    }

    setPasswordErr(passwordErr);
    setConfirmPasswordErr(confirmPasswordErr);
    return isValid;
  };

  function passregex(str) {
    const specialChars = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/;
    return specialChars.test(str);
  }

  return (
    <div>
      {isLoading ? <Loader /> : null}
      {/* Hide dive */}
      {/* {showForm ? <div> : null} */}
      {showForm ? (
        <div className="container">
          <div className="row">
            <div className="col-md-4 offset-md-4 pt-4">
              <div className="forgetemailbody">
                <form
                  className="mt-5 border p-4 bg-light shadow rounded"
                  onSubmit={handleSubmit}
                >
                  <div className="formHeadings mb-2">Reset Password</div>
                  <div className="row">
                    <div className="mb-2 col-md-12">
                      <label>New Password</label>
                      <input
                        type={type}
                        maxLength={15}
                        minLength={8}
                        name="password"
                        className="form-control"
                        placeholder="Minimum 8 Character"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        onKeyDown={handleSpace}
                      />
                      <span onClick={handleToggle}>
                        <Icon className="eyeIcon" icon={icon} />
                      </span>
                      {Object.keys(passwordErr).map((key) => {
                        return (
                          <div style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}>{passwordErr[key]}</div>
                        );
                      })}
                      {passregex(password) || password === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Password.
                              Minimum eight and maximum 10 characters,
                              at least one uppercase letter,
                              one lowercase letter,
                              one number and one special character.
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-12">
                      <label>Confirm Password</label>
                      <input
                        type={type1}
                        maxLength={15}
                        minLength={8}
                        name="confirmpassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        onKeyDown={handleSpace}
                      />
                      <span onClick={handleToggle1}>
                        <Icon className="eyeIcon" icon={icon1} />
                      </span>
                      {Object.keys(confirmPasswordErr).map((key) => {
                        return (
                          <div style={{ color: "red" }}>
                            {confirmPasswordErr[key]}
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12">
                      <button type="button" className="backButton"
                        onClick={() => navigate('/')}>
                        Cancel
                      </button>
                      <button type="submit" className="saveButton float-end">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SetNewPass;
