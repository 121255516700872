import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";

const CheckPoints = (props) => {
  const {
    maintenanceState,
    setMaintenenceState,
    maState,
    maIndex,
    addCpItem,
    deleteCpItem,
    action
  } = props;

  //functions for validation
  function nameChars(str) {
    const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialChars.test(str);
  }
  //useEffects
  useEffect(() => {}, [maintenanceState]);

  return (
    <div>
      {maState.length !== 0 &&
        maState.checkpoints !== undefined &&
        maState.checkpoints.map((taskItem, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card className="mt-2 border p-4 bg-light shadow rounded col-md-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5>Checkpoint number : {i + 1}</h5>
                <button
                  className="delIcons mb-2"
                  type="button"
                  onClick={() => deleteCpItem(maIndex, i)}
                  disabled={action}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
              <div
                style={{
                  backgroundColor: i % 2 === 0 ? "#ACDDF5" : "#CAF1FE",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {(maState.ma_type === "pre_maintenance_check" ||
                  maState.ma_type === "maintenance_checkpoint" ||
                  maState.ma_type === "post_maintenance_check" ||
                  maState.ma_type === "inspection_checkpoint") && (
                  <div>
                    {maState.checkpoints.length !== 0 && (
                      <div key={i}>
                        <div className="mb-2 col-md-12 p-2">
                          <label>Checkpoint</label>
                          <input
                            type="text"
                            name="taskdescription"
                            className="form-control"
                            placeholder="Checkpoint"
                            value={taskItem.checkpoint}
                            onChange={(e) =>
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].checkpoints[i].checkpoint = e.target.value;
                                return { ...prevState };
                              })
                            }
                            disabled={action}
                          />
                        </div>
                        <div className="mb-2 col-md-12 p-2">
                          <label>Standard Requirement
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="standardrequirement"
                            className="form-control"
                            placeholder="Standard Requirement"
                            value={taskItem.standard_requirement}
                            onChange={(e) =>
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].checkpoints[i].standard_requirement =
                                  e.target.value;
                                return { ...prevState };
                              })
                            }
                            disabled={action}
                          />
                        </div>
                        <div className="mb-2 col-md-12 p-2">
                          <label>UOM</label>
                          <input
                            type="text"
                            name="uom"
                            className="form-control"
                            placeholder="UOM"
                            value={taskItem.uom}
                            onChange={(e) =>
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].checkpoints[i].uom = e.target.value;
                                return { ...prevState };
                              })
                            }
                            disabled={action}
                          />
                        </div>
                        <div className="mb-2 col-md-12 p-2">
                          <label>Answer Type
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            name="answertype"
                            className="form-select"
                            placeholder="Answer Type"
                            value={taskItem.answer_type}
                            onChange={(e) =>
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].checkpoints[i].answer_type = e.target.value;
                                return { ...prevState };
                              })
                            }
                            disabled={action}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="yes_no_na">Yes / No / Not Applicable</option>
                            <option value="value">Value</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* {maState.ma_type === "spare_change" && (
                  <div>
                    {maState.checkpoints !== [] && (
                      <div key={i}>
                        <div className="mb-2 col-md-12 p-2">
                          <label>
                            Spare ID
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="spareid"
                            className="form-control"
                            placeholder="Spare ID"
                            value={taskItem.spare_id}
                            onChange={(e) =>
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].checkpoints[i].spare_id = e.target.value;
                                return { ...prevState };
                              })
                            }
                          />
                        </div>
                        <div className="mb-2 col-md-12 p-2">
                          <label>
                            Spare Quantity
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="spareqty"
                            className="form-control"
                            placeholder="Spare Quantity"
                            value={taskItem.spare_quantity}
                            onChange={(e) =>
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].checkpoints[i].spare_quantity =
                                  e.target.value;
                                return { ...prevState };
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )} */}
              </div>
            </Card>
          </div>
        ))}
      <div className="mt-4 col-md-12">
        <button
          type="button"
          className="btn btn-info mb-2 float-end"
          onClick={() => addCpItem(maIndex)}
          style={{ borderRadius: "8px", padding: "8px", fontWeight: "bold" }}
          disabled={action}
        >
          Add CheckPoints
        </button>
      </div>
    </div>
  );
};
export default CheckPoints;
