import React from 'react'
import MenuItem from './MenuItem';
import './RestaurantV1_0.css';
import BaslFooter from './baslFooter';
import BaslHeader from './baslHeader';
const RestaurantV1_0 = () => {
    const Imagename1 = {
        QILA1: require("./Images/Fist_Image.jpg"),
        QILA2: require("./Images/third_img.png")
    }

    const redirectToExternalSite = () => {
        window.location.href = 'https://www.bengaluruairport.com/travellers/contact-us';
    };
    return (
        <div className="mainContainer" >
            <BaslHeader />
            <header className="app-header"  >
                {/* <h1>Welcome to Our Lounge</h1> */}
                <div className="menu"  >
                    <MenuItem
                        title="Menus"
                        // logo={Imagename1.QILA1}
                        to='menus'
                    />
                    <MenuItem
                        title="Events & Promotions"
                        // logo={Imagename1.QILA1}
                        to="promotions"
                    />
                    <MenuItem
                        title="House Rules"
                        // logo={Imagename1.QILA2}
                        to="house-rule"
                    />

                    {/* <MenuItem
                        title="Lounge Design Story"
                    // logo={Imagename1.QILA1}
                    /> */}
                    {/* <button style={{ background: 'none', border: 'none', padding: "0", margin: "0" }} onClick={redirectToExternalSite}> */}
                    <MenuItem
                        title="Feedback"
                        to="feedback"
                    // logo={Imagename1.QILA1}
                    />
                    {/* </button> */}

                </div>
            </header>
            <BaslFooter />
            {/* <footer>
                <h6>
                    <span>  © 2024 Powered by </span>
                    <a style={{ textDecoration: "none", color: 'black', cursor: 'pointer' }} href="https://www.zongovita.com" target="_blank" rel="noopener noreferrer">
                        ZongoVita Tech Pvt. Ltd.
                    </a>
                    <span> v0.10.35</span>
                </h6>
            </footer> */}

        </div>
    )
}

export default RestaurantV1_0