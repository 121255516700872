import React, { useEffect } from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import moment from "moment";

function GenexEleMechMainReport({
  responseData,
  filterData,
  reportName,
  pageWidth,
  pagenumber,
}) {
  // header details
  const data1 = {
    data: `Genex Utility Management Pvt Ltd 
        No:04, Sahyadri Layout
        Shettihalli, Jalahalli West, Bangalore-560015
        Phone: 08040945095/08029910304
        E-mail: services@genexutility.com
        Website:www.genexutility.com`,
    colSpan: "5",
    textAlign: "center",
  };

  // report name details
  const data2 = [
    {
      data: reportName,
      colSpan: "3",
      textAlign: "center",
      backgroundColor: "#d3e2fd",
    },
  ];

  // REF and Date details
  const data3 = [
    {
      data: `REF: ${
        responseData.length > 0 || responseData.length === undefined
          ? responseData?.checklist?.[0]?.checkpoints?.find(
              (cp) => cp.checkpoint === "Report No"
            )?.answer
          : ""
      }`,
      colSpan: "2",
    },
    {
      data: `DATE: ${
        responseData.length > 0 || responseData.length === undefined
          ? moment(responseData?.checklist?.[0]?.start_date)
              .local()
              .format("Do MMMM YYYY")
          : ""
      }`,
      colSpan: "1",
    },
  ];

  // CUSTOMER NAME & ADDRESS, ENGINEER/TECHNICIAN NAME details
  const data4 = [
    {
      data: `CUSTOMER NAME & ADDRESS: ${
        responseData.length > 0 || responseData.length === undefined
          ? `\n${responseData?.parent?.site_name},\n${responseData?.parent?.location}`
          : ""
      }`,
      colSpan: "1",
    },
    {
      data: `ENGINEER/TECHNICIAN NAME: ${
        responseData.length > 0 || responseData.length === undefined
          ? responseData?.checklist[0]?.performed_by
          : ""
      }`,
      colSpan: "1",
      verticalAlign: "top",
    },
  ];

  // SITE DETAILS
  const data5 = [
    {
      data: `SITE DETAILS: 
        Name: ${
          responseData.length > 0 || responseData.length === undefined
            ? responseData?.parent?.parent_name
            : ""
        }
        Capacity: ${
          responseData.length > 0 || responseData.length === undefined
            ? `${responseData?.parent?.rating_capacity} ${responseData?.parent?.uom}`
            : ""
        }
        Model: ${
          responseData.length > 0 || responseData.length === undefined
            ? responseData?.parent?.model
            : ""
        }
        Permit Number: ${
          responseData.length > 0 || responseData.length === undefined
            ? responseData?.checklist?.[0]?.checkpoints?.find(
                (cp) => cp.checkpoint === "Permit Number"
              )?.answer
            : ""
        }
        LOTO Number: ${
          responseData.length > 0 || responseData.length === undefined
            ? responseData?.checklist?.[0]?.checkpoints?.find(
                (cp) => cp.checkpoint === "LOTO Number"
              )?.answer
            : ""
        }
        `,
      colSpan: "1",
    },
    {
      data: `DESCRIPTION: ${
        responseData.length > 0 || responseData.length === undefined
          ? responseData?.checklist?.[0]?.checkpoints?.find(
              (cp) =>
                cp.checkpoint === "Description" ||
                cp.checkpoint === "STP Details - Description"
            )?.answer
          : ""
      }`,
      colSpan: "1",
      textAlign: "left",
      verticalAlign: "top",
    },
  ];

  // DETAILS OF SERVICE DONE details
  const data6 = [
    {
      data: `DETAILS OF SERVICE DONE :`,
      colSpan: "1",
      backgroundColor: "#d3e2fd",
    },
  ];

  // header given before asset checklist details
  const data7 = [
    {
      data: `${reportName === "SERVICE REPORT" ? "DETAILS" : "ASSET DETAILS"}`,
      textAlign: "left",
      colSpan: "2",
    },
    {
      data: `${
        reportName === "SERVICE REPORT" ? "RESPONSE" : "ASSET CONDITION"
      }`,
      textAlign: "left",
    },
    {
      data: `COMMENTS`,
      textAlign: "left",
    },
  ];

  // function to map the checkpoints along with answer & comments
  const generateData = (checklist, checkpointIndex) => {
    return checklist?.map((item) => ({
      data1: {
        value:
          item?.checkpoints[checkpointIndex]?.checkpoint !== ""
            ? item?.checkpoints[checkpointIndex]?.checkpoint
            : "-",
        style: { textAlign: "left", colSpan: "2" },
      },
      data2: {
        value:
          item?.checkpoints[checkpointIndex]?.answer !== ""
            ? item?.checkpoints[checkpointIndex]?.answer
            : "-",
        style: { textAlign: "left" },
      },
      data3: {
        value:
          item?.checkpoints[checkpointIndex]?.cp_comment !== ""
            ? item?.checkpoints[checkpointIndex]?.cp_comment
            : "-",
        style: { textAlign: "left" },
      },
    }));
  };
  const data11 = generateData(responseData?.checklist, 4);
  const data13 = generateData(responseData?.checklist, 5);
  const data14 = generateData(responseData?.checklist, 6);
  const data15 = generateData(responseData?.checklist, 7);
  const data16 = generateData(responseData?.checklist, 8);
  const data17 = generateData(responseData?.checklist, 9);
  const data18 = generateData(responseData?.checklist, 10);
  const data19 = generateData(responseData?.checklist, 11);
  const data20 = generateData(responseData?.checklist, 12);
  const data21 = generateData(responseData?.checklist, 13);
  const data22 = generateData(responseData?.checklist, 14);
  const data23 = generateData(responseData?.checklist, 15);
  const data24 = generateData(responseData?.checklist, 16);
  const data25 = generateData(responseData?.checklist, 17);
  const data26 = generateData(responseData?.checklist, 18);
  const data27 = generateData(responseData?.checklist, 19);

  // SUGGETIONS FROM ENGINEER/TECHNICIAN & SIGNATURE
  const data8 = [
    {
      data: `SUGGETIONS FROM ENGINEER/TECHNICIAN & SIGNATURE :`,
      colSpan: "1",
      backgroundColor: "#d3e2fd",
    },
  ];

  // common comment mapped of checklist - below SUGGETIONS FROM ENGINEER/TECHNICIAN & SIGNATURE
  const data9 = {
    data: `${
      responseData?.checklist?.[0]?.common_comment
        ? responseData?.checklist?.[0]?.common_comment
        : "-"
    }`,
    colSpan: "5",
    verticalAlign: "top",
  };

  // details of SERVICE DONE for Service Report
  const data29 = [
    {
      data: `SERVICE DONE : ${
        responseData?.checklist?.[0]?.checkpoints?.find(
          (cp) => cp.checkpoint === "Details of service done"
        )?.answer
      }\n\n\n`,
      colSpan: "1",
    },
  ];

  // Customer signature & comments included
  const data12 = {
    data: "-",
    colSpan: "5",
    textAlign: "left",
    verticalAlign: "top",
  };
  const data10 = [
    {
      data: `CUSTOMER SIGNATURE :`,
      colSpan: "1",
      backgroundColor: "#d3e2fd",
    },
  ];

  useEffect(() => {
    // console.log(responseData);
  }, [responseData]);

  return (
    <div>
      <ReportHearder
        custLogo={responseData?.customer?.cust_logo}
        genexMain={true}
        data1={data1}
        pageWidth={pageWidth}
      />
      <ReportTable headerData2={data2} bodyData={data3} pageWidth={pageWidth} />
      <ReportTable bodyData={data4} pageWidth={pageWidth} />
      <ReportTable bodyData={data5} pageWidth={pageWidth} />
      <ReportTable bodyData={data6} pageWidth={pageWidth} />
      <ReportTable
        headerData={data7}
        verticalBodyData={data11}
        pageWidth={pageWidth}
      />
      <ReportTable verticalBodyData={data13} pageWidth={pageWidth} />
      <ReportTable verticalBodyData={data14} pageWidth={pageWidth} />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data15 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        bodyData={reportName === "SERVICE REPORT" ? data29 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data16 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data17 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data18 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data19 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data20 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data21 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data22 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data23 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data24 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data25 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data26 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable
        verticalBodyData={reportName !== "SERVICE REPORT" ? data27 : ""}
        pageWidth={pageWidth}
      />
      <ReportTable bodyData={data8} pageWidth={pageWidth} />
      <ReportHearder
        custLogo={
          responseData.length > 0 || responseData.length === undefined
            ? responseData?.checklist?.[0]?.signature
            : ""
        }
        genexMain={true}
        data1={data9}
        pageWidth={pageWidth}
      />
      {/* <ReportTable
                bodyData={data10}
                pageWidth={pageWidth}
            />
            <ReportHearder
                custLogo={(responseData.length > 0 || responseData.length === undefined) ? responseData?.checklist?.[0]?.approver_signature : ''}
                genexMain={true}
                data1={data12}
                pageWidth={pageWidth}
            /> */}
    </div>
  );
}

export default GenexEleMechMainReport;
