/* Feature - action list for asset

Created by - Mona R
Updated by - Mona R */

// action to get all asset details from API
const ASSET_GET_LIST = 'ASSET_GET_LIST';
const ASSET_GET_SUCCESS = 'ASSET_GET_SUCCESS';
const ASSET_GET_FAILED = 'ASSET_GET_FAILED';

// action to delete asset details.
const ASSET_DELETE_LIST = 'ASSET_DELETE_LIST';
const ASSET_DELETE_SUCCESS = 'ASSET_DELETE_SUCCESS';
const ASSET_DELETE_FAILED = 'ASSET_DELETE_FAILED';

// action to create new asset.
const ASSET_ADD = 'ASSET_ADD';
const ASSET_ADD_SUCCESS = 'ASSET_ADD_SUCCESS';
const ASSET_ADD_FAILED = 'ASSET_ADD_FAILED';

// action to create bulk asset.
const ASSET_BULK_ADD = 'ASSET_BULK_ADD';
const ASSET_BULK_ADD_SUCCESS = 'ASSET_BULK_ADD_SUCCESS';
const ASSET_BULK_ADD_FAILED = 'ASSET_BULK_ADD_FAILED';

// action to update individual asset (to make changes in updateded datails)
const ASSET_UPDATE = 'ASSET_UPDATE';
const ASSET_UPDATE_SUCCESS = 'ASSET_UPDATE_SUCCESS';
const ASSET_UPDATE_FAILED = 'ASSET_UPDATE_FAILED';

// action to get single asset by ID to view individual asset details.
const ASSET_GET_SINGLE = 'ASSET_GET_SINGLE';
const ASSET_GET_SINGLE_SUCCESS = 'ASSET_GET_SINGLE_SUCCESS';
const ASSET_GET_SINGLE_FAILED = 'ASSET_GET_SINGLE_FAILED';

// action to upload SOP under individual asset/device/facility.
const SOP_UPLOAD = 'SOP_UPLOAD';
const SOP_UPLOAD_SUCCESS = 'SOP_UPLOAD_SUCCESS';
const SOP_UPLOAD_FAILED = 'SOP_UPLOAD_FAILED';


export {
    ASSET_GET_LIST,
    ASSET_GET_SUCCESS,
    ASSET_GET_FAILED,
    
    ASSET_DELETE_LIST,
    ASSET_DELETE_SUCCESS,
    ASSET_DELETE_FAILED,

    ASSET_ADD,
    ASSET_ADD_SUCCESS,
    ASSET_ADD_FAILED,  
    
    ASSET_BULK_ADD,
    ASSET_BULK_ADD_SUCCESS,
    ASSET_BULK_ADD_FAILED,
    
    ASSET_UPDATE,
    ASSET_UPDATE_SUCCESS,
    ASSET_UPDATE_FAILED,

    ASSET_GET_SINGLE,
    ASSET_GET_SINGLE_SUCCESS,
    ASSET_GET_SINGLE_FAILED,

    SOP_UPLOAD,
    SOP_UPLOAD_SUCCESS,
    SOP_UPLOAD_FAILED
}