/* Feature - to create RequireAuth

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../utilities/hooks/useAuth";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// function for RequireAuth
const RequireAuth = (props) => {
  const { auth, setAuth } = useAuth();
  const location = useLocation();

  const reducerToken = useSelector(state => state.auth.userToken)

  // useEffect
  useEffect(() => {
  }, [reducerToken])


  return (
    (auth !== null) ? <Outlet /> : <Navigate to={location} state={{ from: location }} replace />
  );
}

export default RequireAuth;