import React from 'react'
import { ResponsivePie } from '@nivo/pie';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { dashboardService } from '../../services/service.index';

function PaiChart() {

  const [data, setData] = useState({})

  const getCriticalityData = async () => {
    await dashboardService.criticalityData().then(function (res) {
        // console.log(res);
        setData(res.data);
      })
      .catch(function (error) {
      });
  };

  // console.log('data after asset', data.asset_criticality);
  // console.log('data after device', data.device_criticality);
  // console.log('data after facility', data.facility_criticality);

  const color = [
    "rgba(100, 255, 226, 1)", // criticality 1
    "rgba(0, 99, 185, 1)", // criticality 2
    "rgba(133, 250, 231, 0.8)" // criticality 3
  ];
 
  if (Object.keys(data).length > 0 && data.asset_criticality !== undefined) {
    var dataAsset = data.asset_criticality.map((d) => ({
      id: d.criticality,
      label: `Criticality ${d.criticality}`,
      value: d.count
    }));     
  }
  if (data.device_criticality !== undefined) {
    var dataDevice = data.device_criticality.map((d) => ({
      id: d.criticality,
      label: `Criticality ${d.criticality}`,
      value: d.count
    }));    
  }
  if (data.facility_criticality !== undefined) {
    var dataFacility = data.facility_criticality.map((d) => ({
      id: d.criticality,
      label: `Criticality ${d.criticality}`,
      value: d.count
    }));    
  } 

  useEffect(() => {
    // console.log(data);
    // console.log(Object.keys(data).length);
    if (Object.keys(data).length === 0) {
      getCriticalityData();
    }
  }, [data]);

  
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          paddingTop: '-50px',
          marginTop:'40px',
        }}
      >
        {/* Asset data */}
        {(Object.keys(data).length > 0 && data.asset_criticality !== undefined && data.asset_criticality.length > 0) &&
          <div style={{
            height: "380px",
            width: "480px",
            margin: '10px',
            textAlign: 'center',
            border: '1px solid rgba(105, 250, 225, 1)',
            borderRadius: '1rem'
          }}>
            <br />
            <span style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginTop: '15px'
            }}>Asset Based on Criticality</span>
            <br />
            <br />
            <ResponsivePie
              data={dataAsset}
              colors={color}
              margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
              innerRadius={0.6}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            />
          </div>
        }
        {/* Device data */}
        {(Object.keys(data).length  > 0 && data.device_criticality !== undefined && data.device_criticality.length > 0) &&
          <div style={{
            height: "380px",
            width: "480px",
            margin: '10px',
            textAlign: 'center',
            border: '1px solid rgba(105, 250, 225, 1)',
            borderRadius: '1rem'
          }}>
            <br />
            <span style={{
              fontSize: '1.5rem',
              fontWeight: 'bold'
            }}>Device Based on Criticality</span>
            <br />
            <br />
            <ResponsivePie
              data={dataDevice}
              colors={color}
              margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
              innerRadius={0.6}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            />
          </div>
          }
        {/* Facility data */}
        {(Object.keys(data).length  > 0 && data.facility_criticality !== undefined && data.facility_criticality.length > 0) && 
        <div style={{ 
          height: "380px",
            width: "480px",
            margin: '10px',
            textAlign: 'center',
            border: '1px solid rgba(105, 250, 225, 1)',
            borderRadius: '1rem'
          }}>
            <br />
             <span style={{
              fontSize: '1.5rem',
              fontWeight: 'bold'
            }}>Facility Based on Criticality</span>
            <br />
            <br />
          <ResponsivePie
            data={dataFacility}
            colors={color}
            margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
            innerRadius={0.6}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          />
        </div>}

      </div>

    </div>
  )
}

export default PaiChart
