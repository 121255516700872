import React, { useEffect, useState } from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import ReportFooter from "./reportFooter";
import moment from "moment";

function PmcGenMonthly({
  responseData,
  filterData,
  frequency,
  headingName,
  headingName2,
  headingName3,
  data4,
  docNumber,
  heading,
  footer,
  medopharm,
  malur,
}) {
  const data1 = {
    data: "MAINTENANCE",
    colSpan: "6",
  };
  const data2 = {
    data: docNumber,
  };

  let resData = [];
  let dataR1 = [];
  let dataR2 = [];
  let dataR3 = [];
  let headingData = [];

  const data3 = {
    data: `${headingName !== undefined ? headingName : ""}`,
    colSpan: "7",
  };

  const data5 = [
    {
      data: {
        value: `Done By : -`,
        style: { textAlign: "left" },
      },
      data1: {
        value: `Checked By : -`,
        style: { textAlign: "left" },
      },
    },
  ];

  const dataFooter1 = {
    data: `Next Due Date: -`,
    colSpan: "6",
  };

  const dataFooter = {
    data: `Done on : -`,
    colSpan: "6",
  };

  let tableHeaders = [
    { data: "Sl. No", textAlign: "center", rowSpan: "1", colSpan: "1" },
    { data: "Description", textAlign: "center", rowSpan: "1", colSpan: "3" },
    { data: "Observation", textAlign: "center", rowSpan: "1", colSpan: "3" },
    { data: "Remarks", textAlign: "center", rowSpan: "1", colSpan: "3" },
  ];

  let tableResData = [];
  const formatDate = (date) =>
    date ? moment(date).local().format("DD/MM/YY") : "-";

  // Utility function to get performer information
  const getPerformerInfo = (activity) =>
    activity?.performer_info?.[0]?.performed_by || "-";

  // Utility function to get maintenance approval ID
  const getMaintApprovalId = (checklist) => checklist?.maint_approval_id || "-";

  if (docNumber == "MAINT/006:F11-00") {
    tableHeaders = [
      { data: "Date", textAlign: "center", rowSpan: "1", colSpan: "2" },
      {
        data: "ACCEPTANCE CRITERIA",
        textAlign: "center",
        rowSpan: "1",
        colSpan: "4",
      },
      { data: "OBSERVATION", textAlign: "center", rowSpan: "1", colSpan: "4" },
      { data: "DONE BY", textAlign: "center", rowSpan: "1", colSpan: "2" },
      { data: "CHECKED BY", textAlign: "center", rowSpan: "1", colSpan: "2" },
    ];
    responseData?.maint_checklist?.forEach((el) => {
        const activity = el?.maintenance_activities[0];
    
        const row1 =  [
            {
              data: {
                value: `Done By : ${getPerformerInfo(activity)}`,
                style: { textAlign: "left" },
              },
              data1: {
                value: `Checked By : ${getMaintApprovalId(el)}`,
                style: { textAlign: "left" },
              },
            },
        ];
    
        const row2 = {
            data: `Done on : ${formatDate(activity?.performer_info?.[0]?.performed_date)} `,
            colSpan: "6",
        };
    
        const row3 = {
            data: `Next Due Date :  ${formatDate(el?.next_run)}  `,
            colSpan: "6",
        }
    
        headingData.push({
            data: `${headingName} FOR THE MONTH OF ${moment(el?.maint_sch_date)
              .local()
              .format("MMMM")
              .toUpperCase()}`,
            colSpan: "7",
        });
        dataR1.push(row1);
        dataR2.push(row2);
        dataR3.push(row3);
    
        // Process checkpoint data
        const checkpointData =
          activity?.checkpoints?.map((item, index) => ({
            data: {
                value:activity?.start_date && activity?.start_date !== undefined
                    ? moment(activity?.start_date).local().format("DD/MM/YY")
                    : "-", // received email on 31st jan to maintain this date format.
                style: { textAlign: "center", colSpan: "2" },
            },
            data1: {
                value:
                  "Set the conductivity as 1.0μS/cm and observe. When water reaches the conductivity 1.0μS/cm, the dump valve should open and water should be drained off. All water should go to the waste water line.",
                style: { textAlign: "center", colSpan: "4" },
            },
            data2: {
              value: getPerformerInfo(activity) || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
            data3: {
              value: item?.cp_answer || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
            data4: {
              value: getMaintApprovalId(el) || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
          })) || [];
    
        resData.push(checkpointData);
    });
  } else if (docNumber == "MAINT/011:F2-01") {
    tableHeaders = [
      { data: "Date", textAlign: "center", rowSpan: "1", colSpan: "2" },
      {
        data: "AHU/VAU Number",
        textAlign: "center",
        rowSpan: "1",
        colSpan: "3",
      },
      {
        data: "Reason for replacement of HEPA filter",
        textAlign: "center",
        rowSpan: "1",
        colSpan: "3",
      },
      { data: "Replaced by", textAlign: "center", rowSpan: "1", colSpan: "3" },
      { data: "Checked by", textAlign: "center", rowSpan: "1", colSpan: "3" },
    ];

    responseData?.maint_checklist?.forEach((el) => {
        const activity = el?.maintenance_activities[0];
  
        headingData.push({
          data: `${headingName} FOR THE MONTH OF ${moment(el?.maint_sch_date)
            .local()
            .format("MMMM")
            .toUpperCase()}`,
          colSpan: "7",
        });
  
        // Process checkpoint data
        const checkpointData =
          activity?.checkpoints?.map((item, index) => ({
            data: {
              value: item?.start_date && item?.start_date!==''?moment(item?.start_date).local().format("DD/MM/YY"):'-',
              style: { textAlign: "center", colSpan: "1", rowSpan: "1" },
            },
            data1: {
              value: el?.asset_name || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
            data2: {
              value: item?.cp_answer || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
            data3: {
              value: getPerformerInfo(item) || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
            data4: {
              value: getMaintApprovalId(el) || "-",
              style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
            },
          })) || [];
  
        resData.push(checkpointData);
      });
  } else {
    responseData?.maint_checklist?.forEach((el) => {
      const activity = el?.maintenance_activities[0];

      const row1 = [
        {
          data: {
            value: `Done By : ${getPerformerInfo(activity)}`,
            style: { textAlign: "left" },
          },
          data1: {
            value: `Checked By : ${getMaintApprovalId(el)}`,
            style: { textAlign: "left" },
          },
        },
      ];

      const row2 = {
        data: `Done on : ${formatDate(
          activity?.performer_info?.[0]?.performed_date
        )} `,
        colSpan: "6",
      };

      const row3 = {
        data: `Next Due Date :  ${formatDate(el?.next_run)}  `,
        colSpan: "6",
      };

      headingData.push({
        data: `${headingName} FOR THE MONTH OF ${moment(el?.maint_sch_date)
          .local()
          .format("MMMM")
          .toUpperCase()}`,
        colSpan: "7",
      });
      dataR1.push(row1);
      dataR2.push(row2);
      dataR3.push(row3);

      // Process checkpoint data
      const checkpointData =
        activity?.checkpoints?.map((item, index) => ({
          data: {
            value: index + 1,
            style: { textAlign: "center", colSpan: "1", rowSpan: "1" },
          },
          data1: {
            value: item?.checkpoint || "-",
            style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
          },
          data2: {
            value: item?.cp_answer || "-",
            style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
          },
          data3: {
            value: item?.cp_comments || "-",
            style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
          },
        })) || [];

      resData.push(checkpointData);
    });
  }
  console.log("responseData?.customer?.cust_logo",responseData?.customer?.cust_logo)
  return (
    <div>
      <div>
        {resData.length > 0 ? (
          resData.map((el, index) => (
            <div
              key={index}
              style={{
                pageBreakBefore: index !== 0 ? "always" : "auto",
                paddingTop: index !== 0 ? "20px" : "0px",
              }}
            >
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={headingData[index]}
                malur={malur}
                medopharm={medopharm}
                custLogo={responseData?.customer?.cust_logo}
              />
              <ReportTable headerData={data4} />
              {headingName2 !== "" && headingName2 !== undefined ? (
                <ReportTable headerData={headingName2} />
              ) : (
                ""
              )}
              {headingName3 !== "" && headingName3 !== undefined ? (
                <ReportTable headerData={headingName3} />
              ) : (
                ""
              )}
              <ReportTable headerData={tableHeaders} />

              <ReportTable verticalBodyData={el} />
              {footer == false ? (
                ""
              ) : (
                <ReportTable verticalBodyData={dataR1[index]} />
              )}

              {footer == false ? (
                ""
              ) : (
                <ReportFooter
                  dataRow1={dataR2[index]}
                  dataRow2={dataR3[index]}
                />
              )}
            </div>
          ))
        ) : (
          <div>
            <ReportHearder
              data1={data1}
              data2={data2}
              data3={data3}
              malur={malur}
              medopharm={medopharm}
              custLogo={responseData?.customer?.cust_logo}
            />
            <ReportTable headerData={data4} />
            {headingName2 !== "" && headingName2 !== undefined ? (
              <ReportTable headerData={headingName2} />
            ) : (
              ""
            )}
            {headingName3 !== "" && headingName3 !== undefined ? (
              <ReportTable headerData={headingName3} />
            ) : (
              ""
            )}
            <ReportTable headerData={tableHeaders} />
            <ReportTable verticalBodyData={[]} />
            {footer == false ? "" : <ReportTable verticalBodyData={data5} />}

            {footer == false ? (
              ""
            ) : (
              <ReportFooter dataRow1={dataFooter} dataRow2={dataFooter1} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PmcGenMonthly;
