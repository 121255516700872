/* Feature - action list for asset

Created by - Mona R
Updated by - Janaki */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../modules/login/styleSheet.css";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import IMAGENAME from "../../medias/media.index";
import { useSelector, useDispatch } from "react-redux";
import { LOGIN } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSignIn } from "@fortawesome/free-solid-svg-icons";

// Regex list
const emailRegexCheck =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordRegexCheck =
  /^(?=(?:\S*\d){1})(?=(?:\S*[A-Z]){1})(?=(?:\S*[a-z]){1})(?=\S*[^A-Za-z0-9])\S{8,}$/;
// function to handle login
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // handle state
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  // function to handle email input
  const handleEmail = (val) => {
    if (emailRegexCheck.test(val)) {
      setData({
        ...data,
        email: val,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        email: val,
        isValidUser: false,
      });
    }
  };

  // function to handle password input
  const handlePassword = (val) => {
    if (val.includes(" ") && passwordRegexCheck.test(val)) {
      val = val.replace(/\s/g, "");
      setData({
        password: val,
      });
    } else {
      setData({
        ...data,
        password: val,
      });
    }
  };

  // function to handle timeout
  const helloHandeler = () => {
    setTimeout(() => {
      setLoading(!loading);
    }, 5000);
    setShow(!show);
  };

  // handle eye off icon for password
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  // function to prevent space as an input.
  const handleSpace = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  // function to submit login data with dispatch
  const login = (e) => {
    e.preventDefault();
    let loginData = {
      email: data.email,
      pass: data.password,
    };
    dispatch({
      type: LOGIN,
      payload: loginData,
    });
  };

  // codition to check authentication from redux
  const reducerToken = useSelector((state) => state.auth.userToken);
  const isLoading = useSelector((state) => state.auth.isLoading);

  /////////// dispatch for role get/////////////
  const role = useSelector((state) => state.auth.role);
  const webAccess = useSelector((state) => state.access.webAccess);

  useEffect(() => {
    // console.log('role', role)
    const roleCode = role[0];
    // console.log(roleCode);
    if (roleCode !== null && reducerToken) {
      // console.log(roleCode);
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
    if (webAccess?.r && reducerToken) {
      navigate("/home");
    }
    if (webAccess?.r === false) {
      alert(
        "Regrettably, web access is unavailable. Your access is limited to mobile devices."
      );
      dispatch({
        type: "LOGOUT",
      });
    }
  }, [role, reducerToken, webAccess]);

  // useEffect(() => {
  //   // console.log(webAccess?.r, 'webAccess?.r');
  //   if (webAccess?.r) {
  //     navigate("/home");
  //   }
  //   if (webAccess?.r === false) {
  //     alert(
  //       "Regrettably, web access is unavailable. Your access is limited to mobile devices."
  //     );
  //     dispatch({
  //       type: "LOGOUT",
  //     });
  //   }
  //   // if (webAccess === 'not_availabe') {
  //   //   navigate("/home");
  //   // }
  // }, [reducerToken, webAccess]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className="container-fluid bg">
        <form
          className="form-container"
          // onSubmit={(e) => {
          //   login(e);
          //   return false;
          // }}
        >
          {/* Backgroud Image */}
          <img className="icon-V1-sites zongoLogo" src={IMAGENAME.logoZV} />
          <div className="mb-4">
            <label htmlFor="Email1" className="form-label">
              Sign in to access your account
            </label>
            <input
              type="email"
              className="loginInput"
              id="Email1"
              aria-describedby="emailHelp"
              required
              placeholder="Username/Email ID"
              onChange={(e) => handleEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <input
              type={type}
              maxLength={15}
              className="loginInput"
              id="Password1"
              required
              placeholder="Password"
              onChange={(e) => handlePassword(e.target.value)}
              onKeyDown={handleSpace}
            />
            <span onClick={handleToggle}>
              <Icon className="eyeIcon" icon={icon} />
            </span>
          </div>

          <div className="float-end">
            <button
              type="button"
              className="forgtPass"
              onClick={() => navigate("/forget")}
              disabled={isLoading}
            >
              Forgot Password ?
            </button>
          </div>

          <button
            type="submit"
            className="SignInButton"
            // onClick={helloHandeler}
            onClick={login}
            disabled={isLoading}
          >
            {" "}
            Log In &nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </form>
      </div>

      <div>
        <footer className="footer">
          <p style={{ color: "#fff" }}>
            {" "}
            &#169; 2023 Powered by ZongoVita Tech Pvt. Ltd. v0.10.35
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Login;
