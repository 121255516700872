//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function WeighingBalence({ responseData, tableHeaders, tableHeaders2, data, filterData, chklname, docNumber, depname, checkedby, instrumentCode, instCodeData, objheading, malur, medopharm, check1, check2,checked }) {

  const [resData, setResData] = useState([])

  // header details
  const data1 = {
    data: depname,
    colSpan: '6'
  }
  const data2 = {
    data: docNumber
  }

  const data3 = {
    data: `${chklname} FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
    colSpan: '7'

  }

  useEffect(() => {
    setResData(responseData)
  }, [responseData])

  return (
    <div>
      <ReportHearder
        data1={data1}
        data2={data2}
        data3={data3}
        custLogo={resData?.customer?.cust_logo}
        malur={malur}
        medopharm={medopharm}
      />
      {instrumentCode == true ? '' : <ReportTable
        headerData2={instCodeData}
      />}
      {objheading?.length > 0 && objheading?.map((el, i) => {
        return <ReportTable headerData2={el} />
      })}
      <ReportTable
        headerData={tableHeaders}
        headerData2={tableHeaders2}
      />
      <ReportTable
        verticalBodyData={data}
      />

      {checked==true?'':check1 !== undefined ? <ReportTable verticalBodyData={check1} /> : <ReportFooter
        dataRow1={check2}
      />}
    </div>
  )
}

export default WeighingBalence
