/* Feature - Get all Onboarded asset list provided with pagination, search, filter functionalities
Created by - Mona R
Updated by - Janaki, Mona R, Guru Murthi M
*/
//importing dependencies and packages
//importing dependencies and packages
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faCalendarDays,
  faAngleRight,
  faAngleLeft,
  faClose,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import CreateBulkSchedule from "../schedules/bulkUpload";
import Loader from "../../components/loader/loader";
import ReactTooltip from "react-tooltip";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";


//Function to fetch all asset
function BasedOnUser() {
  //For Search and filter by murthi new
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [key, setKey] = useState("inspection");
  const [showDetails, setShowDetails] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //to fetch data from redux
  // const assetData = useSelector((state) => state.asset.assetData);
  const userBasedData = useSelector((state) => state.report.userBasedData) 
  const accessOnDemand = useSelector((state) => state.access.onDemandInspection);
  // console.log(userBasedData);
  const isLoading = useSelector((state) => state.report.isLoading);


  // Add state variables for current page and items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  //add toggle Button

  // Calculate the total number of pages
  let totalPages = 0;
  var reportList = [];
  if (userBasedData?.result !== undefined) {
    reportList = userBasedData?.result
  } 
  if (userBasedData?.total_count !== undefined) {
    totalPages = Math.ceil(userBasedData?.total_count / itemsPerPage)
  }
  // console.log('assetList', assetList);
  // Function to handle next button
  const handleNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  // Function to handle search input change
  // let id ;
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handleStartDate = (e) => {
    // console.log(e.target.value);
    e.preventDefault();
    setStartDate(e.target.value)
  }
  const handleEndDate = (e) => {
    // console.log(e.target.value);
    e.preventDefault();
    setEndDate(e.target.value)
  }


  //Changes for Pagination and Search
  const handleClearButton = () => {
    setSearchInput("");
  };

  //  Handle Tab change key
  const handleKey = (k) => {
    setKey(k)
    setCurrentPage(1);
  }

  const handleViewMore = (index) => {
    setShowDetails((prevShowDetails) => {
      const updatedShowDetails = [...prevShowDetails];
      updatedShowDetails[index] = !updatedShowDetails[index];
      return updatedShowDetails;
    });
  };

  useEffect(() => {
    // console.log(endDate);
    if ((currentPage && currentPage > 0 || searchInput.length > 0)) {
      dispatch({
        type: "REP_BASEDON_USER_STATUS",
        payload: [key, startDate, endDate,currentPage, itemsPerPage, searchInput],
      });
    }
  }, [key, startDate, endDate,currentPage, itemsPerPage, searchInput]);



  ////////////////////////////////////////HTML////////////////////////////////////////////////
  return (
    <div>
      {isLoading && <Loader />} {/* to show Loading effect */}

      <div className="col-md-12">
        <div
          className="listHeadings"
          style={{
            fontSize: "20px",
            justifyContent: "center",
          }}
        >
          Summary Report - Based on User
          <div style={{ float: "right" }}>
            <form
              className="mb-4"
            >
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "5px",
                }}
              />
              {/* {console.log(searchInput)} */}
              <span
                type="reset"
                onClick={handleClearButton}
                style={{ paddingLeft: "8px", marginRight: "25px" }}
              >
                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
              </span>
            </form>
          </div>
        </div>
        <br />
        <div style={{
          border: "1px solid steelblue",
          fontSize: "15px",
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Tabs
            // id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleKey(k)}
          // className="m-3 headings"
          >
            <Tab eventKey="inspection" title="Inspection"></Tab> &nbsp;
            <Tab eventKey="maintenance" title="Maintenance"></Tab> &nbsp;
            {(accessOnDemand?.c || accessOnDemand?.r || accessOnDemand?.u || accessOnDemand?.d) &&
            <Tab eventKey="on_demand" title="On Demand"></Tab>}
          </Tabs>
          <div style={{
            float: "right"
          }}>
            <input
              type="date"
              placeholder="Start Date"
              value={startDate}
              onChange={handleStartDate}
              style={{
                border: "1px solid steelblue",
                fontSize: "15px",
                padding: "5px",
                margin: '5px'
              }}
            />
            <input
              type="date"
              placeholder="End Date"
              value={endDate}
              onChange={handleEndDate}
              style={{
                border: "1px solid steelblue",
                fontSize: "15px",
                padding: "5px",
                margin: '5px'
              }}
            />
            {/* <button
              className="saveButton my-0 mx-2">
              Submit
            </button> */}
          </div>
        </div>
        {/* Table to view asset list */}
        <div className="mb-6">
          <table className="table-bordered" width="100%">
            <thead>
              {/* Table heading to display list of Site */}
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}
              >
                <th style={{ padding: "8px" }}>S. No</th>
                <th
                  scope="col"
                  style={{ wordBreak: "break-word",padding: "8px", textAlign: "left" }}
                >
                  User Name
                </th>
                <th
                  scope="col"
                  style={{ padding: "8px", textAlign: "left" }}
                >
                  User ID
                </th>
                <th scope="col" style={{ padding: "8px", textAlign: "left" }} >
                  Department
                </th>
                {/* <th
                  scope="col"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Planned Date
                </th> */}
                <th scope="col" >
                  {key !== "on_demand" ? 'Total Planned' : 'In Progress'}
                </th>
                <th
                  scope="col"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Waiting for approval
                </th>
                <th
                  scope="col"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Completed
                </th>
                <th
                  scope="col"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  {key !== "on_demand" ? 'Not performed' : 'Save as Draft'}
                  
                </th>
                {/* <th
                  scope="col"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Save as Draft
                </th> */}
              </tr>
            </thead>
            {reportList !== null && reportList?.length > 0 ? (
              (reportList).map((eachData, index) => {
                return (
                  <tbody key={index} style={{ padding: "15px" }}>
                    <tr
                      style={{ padding: "20px" }}
                    // onDoubleClick={() =>
                    //   navigate(`/asset/view/${eachData._id}`)
                    // }
                    >
                      {/* function Double click on row to view all asset atribute other than availbe on table.  */}
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {(currentPage - 1) * itemsPerPage + (index + 1)}
                      </td>
                      <td style={{ textAlign: "left", padding: "8px"  }}>
                        {eachData.name}
                      </td>
                      <td style={{ textAlign: "left", padding: "8px"  }}>
                        {eachData.userId}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "8px" }}>
                        {showDetails[index]
                          ? eachData.department.join(', ')
                          : eachData.department.slice(0, 1).join(', ')}
                        {eachData?.department?.length > 2 && (
                          <button style={{
                            // display:'flex',
                            alignItems:'end',
                            float:'right',
                            // border:'black',
                            backgroundColor:'white',
                            margin:'5px',
                            padding:'2px'
                          }} onClick={() => handleViewMore(index)}>
                            {showDetails[index] ? "View Less" : "View More"}
                          </button>
                        )}
                        {showDetails[index] && eachData?.department?.length > 2 && (
                          eachData.department.slice(2).join(', ')
                        )}
                      </td>
                      {/* <td style={{ textAlign: "center" }}>{moment(startDate).format("MMMM Do YYYY")}</td> */}
                      <td style={{ textAlign: "center" }}>
                        {key !== 'on_demand'?
                        (eachData.assigned_count) + (eachData.waiting_for_approval_count) + (eachData.completed_count) :
                        (eachData.inprogress_count)}
                        </td>
                      <td style={{ textAlign: "center" }}>{eachData.waiting_for_approval_count}</td>
                      <td style={{ textAlign: "center" }}>{eachData.completed_count}</td>
                      <td style={{ textAlign: "center" }}>{((eachData.assigned_count))}</td>
                      {/* <td style={{ textAlign: "center" }}>{eachData.model}</td> */}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr style={{ textAlign: "center", height: "75px" }}>
                  <td colSpan="10">No Data Available</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        {/* pagination */}
        {reportList !== null && reportList?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "10px",
            }}
          >
            <div className="mb-6">
              <label>Items per page:</label> &nbsp;
              <select
                onChange={(e) => handleItemsPerPageChange(e.target.value)}
                style={{ borderRadius: "8px", cursor: "pointer" }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                {/* <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option> */}
              </select>
            </div>
            <div style={{ paddingRight: "25px" }} className="pagination">
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

              </button>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handlePrev}
              >
                {/* Prev */}
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
              </button>
              <span style={{ padding: "10px" }}>
                Showing {currentPage} of {totalPages} pages
              </span>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  border: "none",
                }}
                disabled={currentPage === totalPages}
                onClick={handleNext}
              >
                {currentPage === totalPages ? (
                  <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
              </button>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  border: "none",
                }}
                disabled={currentPage === totalPages}
                onClick={handleLastpage}
              >
                {currentPage === totalPages ? (
                  <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BasedOnUser;
