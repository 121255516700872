//Customer Services
//Feature - Functions to define Customer's API

//importing dependencies
import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from '../../constants/URLs/urlConstants';

function getCustData() {
    return serviceBase.get(CONSTANTS.CUST_GET_ALL);    
};

function deleteCustData(id) {
    return serviceBase.delete(CONSTANTS.CUST_DELETE + id);    
};

function addCustData(data){
    // console.log(data);
    return serviceBase.post( CONSTANTS.CUST_ADD, data);
}

function addCustContact(data){
    // console.log(data);
    let requestObj = JSON.stringify(data.newObj);
    return serviceBase.post(CONSTANTS.CUST_CONTACT_ADD + data.id, requestObj);
}

function getCustContact(id){  
    return serviceBase.get(CONSTANTS.CUST_CONTACT_GET + id);
}

function updateCust(data) {
    // console.log(data);
    return serviceBase.patch(CONSTANTS.CUS_UPDATE + data.subscriber_id, data);
}

function getSingleCustomer(id) {
    return serviceBase.get(CONSTANTS.CUST_GET_SINGLE + id);
}

function addBulkCustomer(data) {
    // console.log(data);
    return serviceBase.post(CONSTANTS.CUST_BULK_ADD + data[0], data[1]);   
};

function deleteCustContactData(data) {
    return serviceBase.delete(CONSTANTS.CUST_CONTACT_DELETE + data._id);    
};
//exporting functions for the global usage
export const custDetails = {
    getCustData,
    deleteCustData,
    addCustData,
    updateCust,
    getSingleCustomer,
    addCustContact,
    getCustContact,
    addBulkCustomer,
    deleteCustContactData,
}