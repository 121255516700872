import { React, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


function TrendChart({ chartType, handleChartTypeChange, data }) {

    // Generate categories for the x-axis (dates from June 1 to June 30, 2023)
    const startDate = new Date('2023-06-01').getTime();
    const categories = Array.from({ length: 30 }, (_, index) => {
        const date = new Date(startDate + index * 24 * 60 * 60 * 1000);
        return date.toISOString().split('T')[0];
    });

    const series = data.map((answers, questionIndex) => ({
        name: `Question ${questionIndex + 1}`,
        data: answers
    }));

    const options = {
        chart: {
            type: chartType.value
        },
        title: {
            text: 'Total Checkpoints for Each Answer'
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            title: {
                text: 'Total Checkpoints'
            }
        },
        series: series
    };
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export default TrendChart
