//DAILY BALANCE CALIBRATION LOGSHEET
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
// import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function DbcLog({ data, responseData, filterData, data1, data2, data6, data7, data8, data9, data10, data12, tableHeaders, department1, department2, make, medopharm, std,LeastCount }) {
    const [resData, setResData] = useState([])
    
    const data3 = {
        data: `DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')} ${make?.length > 0 ? make : ''}`,
        colSpan: '7'
    }

    const data4 = [
        { data: `Department`, colSpan: '2' },
        { data: `:`, colSpan: '1', textAlign: 'center' },
        { data: `${resData?.parent?.department !== undefined ? resData?.parent?.department : ""}`, colSpan: '2' },
        { data: `Area`, colSpan: '2', textAlign: 'left' },
        { data: `:`, colSpan: '1', textAlign: 'center' },
        { data: `${resData?.parent?.area !== undefined ? resData?.parent?.area : ''}`, colSpan: '6', textAlign: 'left' },
    ]
    const data5 = [
        { data: `Weighing capacity`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: `:`, colSpan: '1', rowSpan: "5", textAlign: 'center' },
        { data: `${resData?.parent?.rating_capacity !== undefined ? resData?.parent?.rating_capacity : ''} ${resData?.parent?.uom !== undefined ? resData?.parent?.uom : ''}`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: 'Least count', colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: ':', colSpan: '1', rowSpan: "5", textAlign: 'center' },
        { data: `${LeastCount}`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: `Balance code No.`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: ` ${resData?.parent?.parent_id !== undefined ? resData?.parent?.parent_id : ''}`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
    ]
    
    const distinctCheckpoints0 = [...new Set(resData?.checklist
        ?.map(item => item?.checkpoints[0]?.answer)
        .filter(answer => answer !== '' && answer !== undefined && answer !== null)
    )];

    // Extract distinct non-empty answers for checkpoint 1
    const distinctCheckpoints1 = [...new Set(resData?.checklist
        ?.map(item => item?.checkpoints[1]?.answer)
        .filter(answer => answer !== '' && answer !== undefined && answer !== null)
    )];

    const data11 = [
        { data: `Std. Weight Certificate No. ${distinctCheckpoints0} & Calibration due:  ${distinctCheckpoints1}`, colSpan: '15', textAlign: 'left' },
    ]

    const data13 = [
        { data: `Std Weight Certificate no. ${distinctCheckpoints0} & Calibration due:  ${distinctCheckpoints1}`, colSpan: '15', textAlign: 'left' },
    ]

    const data14 = [
        { data: `Std Weight Certificate no. ${distinctCheckpoints0} & calibration due:  ${distinctCheckpoints1}`, colSpan: '15', textAlign: 'left' },
    ]

    useEffect(() => {
        setResData(responseData)
    }, [responseData])


    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
                medopharm={medopharm}
            />
            <ReportTable
                headerData={data4}
                headerData2={data5}
            />

            <ReportTable
                headerData={data6}
                headerData2={data7}
            />
            <ReportTable
                headerData={data8}
                headerData2={data9}
            />
            {std?.length > 0 ? <ReportTable
                headerData={data10}
                headerData2={data14}
            /> : <ReportTable
                headerData={data10}
                headerData2={make?.length > 0 ? data13 : data11}
            />}

            <ReportTable
                headerData={tableHeaders}
                headerData2={data12}
            />
            <ReportTable
                verticalBodyData={data}
            />
        </div>
    )
}

export default DbcLog
