import React from 'react'
import IMAGENAME from '../../medias/media.index'

const PlantLink = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div style={{ paddingTop: "25px" }}>
                        <form
                            action=""
                            className="mt-5 border p-4 bg-light shadow rounded"
                            style={{
                                maxWidth: "400px",
                                margin: "0 auto",
                                padding: "20px",
                                // border: "1px solid #000",
                            }}
                        >
                            <div
                                style={{
                                    // border: "1px solid #000",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                <img
                                    style={{
                                        maxWidth: "10rem",
                                        margin: "auto",
                                        padding: "20px",

                                    }}

                                    src={IMAGENAME.qrAirportLogo} />

                            </div>
                            <div
                            // style={{
                            //     border: "1px solid"
                            // }}
                            >

                                <div
                                    style={{
                                        // border: "1px solid",
                                        display: "flex",
                                        // justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            // border: "1px solid",
                                            width: "40%"
                                        }}
                                    >
                                        <h5
                                            style={{
                                                display: "inline",
                                                fontWeight: "bolder"
                                            }}
                                        >Plant Name</h5>
                                    </div>
                                    <h5
                                        style={{
                                            display: "inline"
                                        }}
                                    >:</h5>
                                    <h5
                                        style={{
                                            display: "inline",
                                            marginLeft: "10px"
                                        }}
                                    >Dracaena Draco</h5>
                                </div>

                                <div
                                    style={{
                                        // border: "1px solid",
                                        display: "flex",
                                        // justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            // border: "1px solid",
                                            width: "40%"
                                        }}
                                    >
                                        <h5
                                            style={{
                                                display: "inline",
                                                fontWeight: "bolder"
                                            }}
                                        >Plant Location</h5>
                                    </div>
                                    <h5
                                        style={{
                                            display: "inline"
                                        }}
                                    >:</h5>
                                    <h5
                                        style={{
                                            display: "inline",
                                            marginLeft: "10px"
                                        }}
                                    >Terminal 2</h5>
                                </div>












                                <div
                                    style={{
                                        // border: "1px solid",
                                        display: "flex",
                                        // justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            // border: "1px solid",
                                            width: "40%"
                                        }}
                                    >
                                        <h5
                                            style={{
                                                display: "inline",
                                                fontWeight: "bolder"
                                            }}
                                        >Plant Details</h5>
                                    </div>
                                    <h5
                                        style={{
                                            display: "inline"
                                        }}
                                    >:</h5>
                                    <h5
                                        style={{
                                            display: "inline",
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <a
                                            style={{
                                                textDecoration: "none",
                                                textAlign: "center",
                                                // marginLeft: "10px",
                                                // marginTop: "2px",
                                                fontSize: "14px",
                                            }}
                                            href="https://en.wikipedia.org/wiki/Dracaena_draco">Click here</a>
                                    </h5>
                                </div>
                                {/* < div style={{
                                    display: "flex",
                                }}>
                                    <h5>Details :</h5>
                                    <a
                                        style={{
                                            textDecoration: "none",
                                            textAlign: "center",
                                            marginLeft: "10px",
                                            // marginTop: "2px",
                                            fontSize: "14px",
                                        }}
                                        href="https://en.wikipedia.org/wiki/Dracaena_draco">Click here</a>
                                </div > */}
                            </div>
                        </form>
                        <p
                            style={{
                                marginTop: "1rem",
                                marginLeft: "20px",
                            }}
                        >Thank you for scanning, have a good time !</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PlantLink





{/* <h2>
                                    Plant Name : Dracaena Draco
                                </h2>
                                <h2>Location : T2 </h2>
                                <h2>Details :</h2>
                                <a href="https://en.wikipedia.org/wiki/Dracaena_draco">Click here</a> */}