import React from 'react'
import Commonheader from '../../layouts/common/commonHeader'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../components/BackButton/BackButton';
const TransferDispose = () => {
    const navigate = useNavigate()
    const list1 = useSelector((state) => state.FA.CapitalData)
    const location = useSelector((state) => state.FA.location)
    const [transdisp, setTransDisp] = useState("transfer")
    const [reason, setReasons] = useState();
    const [selectedOptions, setSelectedOptions] = useState([]);
    // console.log(list1)
    const [isLocation, setIsLocation] = useState(false)
    const dispatch = useDispatch()
    const handleChange = (obj) => {
        // console.log('obj', obj);
        setSelectedOptions(obj);
    };
    const handleAction = (e) => {
        setSelectedOptions([])
        setTransDisp(e.target.value)
    }

    const handleChangeTransfer = (e) => {
        // console.log(e.target.value);
        selectedOptions.map((el) => el.location = e.target.value)
        // FAASSET_UPDATE
        setIsLocation(true)
        // console.log(selectedOptions)


    };
    const handleTransfer = () => {
        // console.log(isLocation)
        if (selectedOptions.length == 0 || !isLocation) {
            alert("Please select asset and location")
        } else {
            dispatch({
                type: 'FAASSET_UPDATE',
                payload: selectedOptions
            })
        }
    }
    const handleChangeReason = (e) => {
        setReasons(e.target.value);
    };
    const handleDispose = () => {
        selectedOptions.map((el) => el.reason = reason)
        // console.log(selectedOptions)
        dispatch({
            type: 'ADD_EXPENSE',
            payload: selectedOptions
        })
        navigate("/expensed")
    };


    useEffect(() => {
        if (list1 == null) {
            dispatch({
                type: 'CAPITAL_GET_LIST',
                payload: [1, 50],
            })
        }
        if (location == null) dispatch({ type: "GET_LOCATION_LIST", payload: [1, 50], })
    }, [])

    return (
        <div>
            <Commonheader />
            <BackButton />
            <div className='row'>
                <div className="col-md-4 offset-md-4 mt-4">
                    <div
                        //prevent default page loading on pressing "Enter" key
                        onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                        }}
                        name="myForm"
                        id="Form"
                        className="mt-5 rounded p-4 bg-light shadow"
                        style={{ minHeight: "400px" }}
                    >
                        <div className="formHeadings mb-2">Transfer & Dispose</div>

                        <div className="ml-mb-2 col-md-12">
                            {/* <label>Choose Asset/Device/Facility
                                <span className="text-danger">*</span>
                            </label> */}
                            <br></br>
                            <input
                                style={{ marginTop: "10px" }}
                                className="radioButtons"
                                type="radio"
                                name="type"
                                value="transfer"
                                defaultChecked
                                onChange={(e) => handleAction(e)}
                            />
                            &nbsp;Transfer
                            <input

                                className="radioButtons"
                                style={{ marginLeft: "25px" }}
                                type="radio"
                                name="type"
                                value="dispose"
                                onChange={(e) => handleAction(e)}
                            />
                            &nbsp; Disposal

                        </div>


                        {transdisp == "transfer" ? <>
                            <div className="mb-2 col-md-10" style={{ marginTop: "2rem" }}>
                                <label>Select Assets </label>
                                <br />
                                <Select
                                    className="dropdown"
                                    placeholder="Select"
                                    getOptionLabel={(option) => `${ option.asset_id} - ${option.asset_name}`}
                                    getOptionValue={(option) => option._id}
                                    options={list1?.result}
                                    value={selectedOptions}
                                    onChange={handleChange}
                                    isSearchable={true}
                                    isMulti
                                    isClearable
                                />
                            </div>
                            <div>
                                <div style={{ marginTop: "2rem" }}>
                                    Number of Asset Selected : {selectedOptions.length}
                                </div>
                                <div className="mb-2 col-md-10"
                                    style={{ marginTop: "2rem" }}
                                >
                                    <label>
                                        Transfer To<span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="location"
                                        onChange={(e) => handleChangeTransfer(e)}
                                        className="form-control"
                                    >
                                        <option>Select Location</option>
                                        {location?.result?.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            <button

                                className="saveButton float-end"
                                onClick={handleTransfer} >
                                Transfer
                            </button>
                        </>
                            :
                            <>
                                <div className="mb-2 col-md-10" style={{ marginTop: "2rem" }}>
                                    <label>Select Assets </label>
                                    <br />
                                    <Select
                                        className="dropdown"
                                        placeholder="Select"
                                        getOptionLabel={(option) => `${ option.asset_id} - ${option.asset_name}`}
                                        getOptionValue={(option) => option._id}
                                        options={list1?.result}      
                                        value={selectedOptions}
                                        onChange={handleChange}
                                        isSearchable={true}
                                        isMulti
                                        isClearable
                                    />
                                </div>
                                <div>
                                    <div style={{ marginTop: "2rem" }}>
                                        Number of Asset Selected : {selectedOptions.length}
                                    </div>
                                    <div className="mb-2 col-md-10"
                                        style={{ marginTop: "2rem" }}
                                    >
                                        <label>
                                            Reason
                                        </label>
                                        <input
                                            type="text"
                                            name="reason"
                                            onChange={handleChangeReason}
                                            className="form-control"

                                        />

                                    </div>
                                </div>
                                <button
                                    className="saveButton float-end"
                                    onClick={handleDispose}
                                >
                                    Dispose
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TransferDispose