
const IMAGENAMEMenu = {
    menuQILA1: require("./Images/QILA_page-0001.jpg"),
    menuQILA2: require("./Images/QILA_page-0002.jpg"),
    menuQILA3: require("./Images/QILA_page-0003.jpg"),
    menuQILA4: require("./Images/QILA_page-0004.jpg"),
    menuQILA5: require("./Images/QILA_page-0005.jpg"),
    menuQILA6: require("./Images/QILA_page-0006.jpg"),
    menuQILA7: require("./Images/QILA_page-0007.jpg"),
    menuQILA8: require("./Images/QILA_page-0008.jpg"),
    menuQILA9: require("./Images/QILA_page-0009.jpg"),
    menuQILA10: require("./Images/QILA_page-0010.jpg"),
    menuQILA11: require("./Images/QILA_page-0011.jpg"),
    menuRADIO1: require("./Images/RADIO_page-0001.jpg"),
    menuRADIO2: require("./Images/RADIO_page-0002.jpg"),
    menuRADIO3: require("./Images/RADIO_page-0003.jpg"),
    menuRADIO4: require("./Images/RADIO_page-0004.jpg"),
    menuRADIO5: require("./Images/RADIO_page-0005.jpg"),
    menuRADIO6: require("./Images/RADIO_page-0006.jpg"),
    menuRADIO7: require("./Images/RADIO_page-0007.jpg"),
    menuRADIO8: require("./Images/RADIO_page-0008.jpg"),
    menuRADIO9: require("./Images/RADIO_page-0009.jpg"),
    menuRADIO10: require("./Images/RADIO_page-0010.jpg"),
    menuRADIO11: require("./Images/RADIO_page-0011.jpg"),
    officeLayout1: require("./Images/Office_Layout_page-0001.jpg"),
    clientLogo: require("./Images/080LogoFull.jpeg"),
    loaderLogo: require("./Images/080Logo(1).jpg")
};

export default IMAGENAMEMenu;