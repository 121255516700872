// get list of all customer for view screen
const CUST_GET_LIST = 'CUST_GET_LIST';
const CUST_GET_SUCCESS = 'CUST_GET_SUCCESS';
const CUST_GET_FAILED = 'CUST_GET_FAILED';

// delete individual cutomer by id
const CUST_DELETE_LIST = 'CUST_DELETE_LIST';
const CUST_DELETE_SUCCESS = 'CUST_DELETE_SUCCESS';
const CUST_DELETE_FAILED = 'CUST_DELETE_FAILED';

// add / upload individual customer using UI
const CUST_ADD = 'CUST_ADD';
const CUST_ADD_SUCCESS = 'CUST_ADD_SUCCESS';
const CUST_ADD_FAILED = 'CUST_ADD_FAILED';

// update individual customer (changes in updateded datails)
const CUST_UPDATE = 'CUST_UPDATE';
const CUST_UPDATE_SUCCESS = 'CUST_UPDATE_SUCCESS';
const CUST_UPDATE_FAILED = 'CUST_UPDATE_FAILED';

// get single customer by ID to view individual customer details.
const CUST_GET_SINGLE = 'CUST_GET_SINGLE';
const CUST_GET_SINGLE_SUCCESS = 'CUST_GET_SINGLE_SUCCESS';
const CUST_GET_SINGLE_FAILED = 'CUST_GET_FAILED';

// add customer contact details individually
const CUST_CONTACT_DETAILS = 'CUST_CONTACT_DETAILS';
const CUST_CONTACT_DETAILS_SUCCESS = 'CUST_CONTACT_DETAILS_SUCCESS';
const CUST_CONTACT_DETAILS_FAILED = 'CUST_CONTACT_DETAILS_FAILED';


//  get customer contact list to view the updated details.
const CUST_CONTACT_GET = 'CUST_CONTACT_GET';
const CUST_CONTACT_GET_SUCCESS = 'CUST_CONTACT_GET_SUCCESS';
const CUST_CONTACT_GET_FAILED = 'CUST_CONTACT_GET_FAILED';

// customer Bulk upload
const CUST_BULK_ADD = 'CUST_BULK_ADD';
const CUST_BULK_ADD_SUCCESS = 'CUST_BULK_ADD_SUCCESS';
const CUST_BULK_ADD_FAILED = 'CUST_BULK_ADD_FAILED';

// customer contact delete

const CUST_CONTACT_DELETE = "CUST_CONTACT_DELETE";
const CUST_CONTACT_DELETE_SUCCESS = "CUST_CONTACT_DELETE_SUCCESS";
const CUST_CONTACT_DELETE_FAILED = "CUST_CONTACT_DELETE_FAILED";

export {
    CUST_GET_LIST,
    CUST_GET_SUCCESS,
    CUST_GET_FAILED,
    
    CUST_DELETE_LIST,
    CUST_DELETE_SUCCESS,
    CUST_DELETE_FAILED,

    CUST_ADD,
    CUST_ADD_SUCCESS,
    CUST_ADD_FAILED,

    CUST_UPDATE,
    CUST_UPDATE_SUCCESS,
    CUST_UPDATE_FAILED,

    CUST_GET_SINGLE,
    CUST_GET_SINGLE_SUCCESS,
    CUST_GET_SINGLE_FAILED,    

    CUST_CONTACT_DETAILS,
    CUST_CONTACT_DETAILS_SUCCESS,
    CUST_CONTACT_DETAILS_FAILED,

    CUST_CONTACT_GET,
    CUST_CONTACT_GET_SUCCESS,
    CUST_CONTACT_GET_FAILED,

    CUST_BULK_ADD,
    CUST_BULK_ADD_SUCCESS,
    CUST_BULK_ADD_FAILED,

    CUST_CONTACT_DELETE,
    CUST_CONTACT_DELETE_SUCCESS,
    CUST_CONTACT_DELETE_FAILED, 
}