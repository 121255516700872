import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import S3BucketImage from '../report/pdfPreview/s3BucketImages';


function HistoryModel(props) {
    const [data, setData] = useState([])
    const [actualQuantity, setActualQuantity] = useState(0)

    useEffect(() => {
        // console.log("props", props);
        setData(props.historydata)
        props?.basicdata?.map((eachData, index) => {
            return (
                setActualQuantity(eachData?.actual_quantity))
        }
        )
    }
        , [props])

        // console.log(data);

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        History of Asset Verification
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: "calc(100vh - 210px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
                >
                    <div>
                        <div>
                            <table>
                                {props?.basicdata?.map((eachData, index) => {
                                    return (
                                        <tbody key={index + 1}>
                                            <tr>
                                                <td style={{ padding: "8px", width: "5%" }}>Asset ID</td>
                                                <td style={{ padding: "8px", width: "5%" }}>{eachData?.plant_id}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "8px", width: "5%" }}>Asset Name</td>
                                                <td style={{ padding: "8px", width: "5%" }}>{eachData?.plant_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "8px", width: "5%" }}>Number of Times Verified</td>
                                                <td style={{ padding: "8px", width: "5%" }}>{data?.length}</td>
                                            </tr>
                                            {/* <tr>
                                                <td style={{ padding: "8px", width: "5%" }}>Origin</td>
                                                <td style={{ padding: "8px", width: "5%" }}>{eachData?.origin}</td>
                                            </tr> */}
                                        </tbody>
                                    )
                                }
                                )}
                            </table>
                        </div>
                        <table className="table-bordered" width="100%">
                            <thead>
                                <tr style={{
                                    // textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                }}>
                                    <th scope="col" style={{ padding: "8px", width: "5%" }}>Verification Date & Time</th>
                                    <th scope="col" style={{ padding: "8px", width: "5%" }}>Verified By</th>
                                    {/* <th scope="col" style={{ padding: "8px", width: "5%" }}>Actual Quantity</th> */}
                                    {/* <th scope="col" style={{ padding: "8px", width: "5%" }}>Verified Quantity</th> */}
                                    {/* <th scope="col" style={{ padding: "8px", width: "5%" }}>
                                        Discrepancy
                                        <br />
                                        <span style={{
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                        }}>
                                            If you observe negative values, please update the actual quantity using Plant Create Screen / Bulk Upload.
                                        </span>
                                    </th> */}
                                    <th scope="col" style={{
                                        padding: "8px",
                                        width: "5%",


                                    }}>Comment</th>
                                    <th scope="col" style={{ padding: "8px", width: "5%" }}>Attachments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((item, index) => {
                                        return (
                                            <tr key={index + 1}>
                                                <td style={{ padding: "8px" }}>
                                                    {moment(item?.verification_date).local().format("Do MMM YYYY | hh:mm:ss A")}
                                                    {/* {moment(item?.verification_record?.verify_date).local().format("Do MMM YYYY | hh:mm:ss A")} */}
                                                </td>
                                                {/* <td style={{ padding: "8px" }}>{item?.verification_record?.performerDetails?.name}</td> */}
                                                <td style={{ padding: "8px" }}>{item?.performerDetails?.name}</td>
                                                {/* <td style={{ padding: "8px" }}>
                                                    {actualQuantity}
                                                </td>
                                                <td style={{ padding: "8px" }}>{item?.verification_record?.quantity}</td>
                                                <td style={{ padding: "8px" }}>
                                                    {actualQuantity - (item?.verification_record?.quantity || 0)}
                                                </td> */}
                                                <td style={{
                                                    padding: "8px",
                                                    maxWidth: "200px",
                                                    wordWrap: "break-word",
                                                }}>
                                                    {/* {item?.verification_record?.comments ? item?.verification_record?.comments : "-"} */}
                                                    {item?.comments ? item?.comments : "-"}

                                                </td>
                                                <td style={{ padding: "8px" }}>
                                                    {/* {item?.verification_record?.attachments?.length > 0 ?
                                                        (item?.verification_record?.attachments).map((each, index) => { */}
                                                    {item?.attachments.length > 0 ?
                                                        (item?.attachments).map((each, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <S3BucketImage
                                                                        imageUrl={each}
                                                                        widthSize={'auto'}
                                                                        paddingSize={'5px'}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                        : " - "}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default HistoryModel
