import React, { useState, useEffect } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  faDotCircle,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MaintHistory(props) {
  const [queryParams] = useSearchParams();

  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const [serialNumber, setSerialNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState("button1");

  //to fetch data from redux
  const historyData = useSelector((state) => state.history.historyData);
  // const isLoading = useSelector((state) => state.history.historyData);

  var historyArr = [];
  if (historyArr !== undefined) {
    historyArr = historyData.result;
  }
  let totalPages = 0;
  if (searchInput === "" && historyData.total_count !== undefined) {
    totalPages = Math.ceil(historyData.total_count / itemsPerPage);
  } else {
    totalPages = Math.ceil(filteredData.length / itemsPerPage);
  }
  // Function to handle next button
  const handleNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    dispatch({
      type: "HISTRY_GET_LIST",
      payload: [id,
        queryParams.get("type"),
        props.eventKey,
        "weekly",
        currentPage,
        itemsPerPage,
       "calendar"],
    });
  };
  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    dispatch({
      type: "HISTRY_GET_LIST",
      payload: [id,
        queryParams.get("type"),
        props.eventKey,
        "weekly",
        currentPage,
        itemsPerPage,
       "calendar"],
    });
  };
  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(1);
    dispatch({
      type: "HISTRY_GET_LIST",
      payload: [id,
        queryParams.get("type"),
        props.eventKey,
        "weekly",
        currentPage,
        itemsPerPage,
       "calendar"],
    });
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
    dispatch({
      type: "HISTRY_GET_LIST",
      payload: [id,
        queryParams.get("type"),
        props.eventKey,
        "weekly",
        currentPage,
        itemsPerPage,
       "calendar"],
    });
  };
  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  const handleClick = (value) => {
    if (selected === value) {
      setSelected(null);
    } else {
      setSelected(value);
      setCurrentPage(1);
    }
  };

  function handleDispatch() {
    if (selected === "button1" && props.eventKey === "maintenance") {
      dispatch({
        type: "HISTRY_GET_LIST",
        payload: [
          id,
          queryParams.get("type"),
          props.eventKey,
          "weekly",
          currentPage,
          itemsPerPage,
          "calendar"
        ],
      });
    } else if (selected === "button2" && props.eventKey === "maintenance") {
      dispatch({
        type: "HISTRY_GET_LIST",
        payload: [
          id,
          queryParams.get("type"),
          props.eventKey,
          "monthly",
          currentPage,
          itemsPerPage,
          "calendar"
        ],
      });
    } else if (selected === "button3" && props.eventKey === "maintenance") {
      dispatch({
        type: "HISTRY_GET_LIST",
        payload: [
          id,
          queryParams.get("type"),
          props.eventKey,
          "yearly",
          currentPage,
          itemsPerPage,
          "calendar"
        ],
      });
    } else if (selected === "button4" && props.eventKey === "maintenance") {
      dispatch({
        type: "HISTRY_GET_LIST",
        payload: [
          id,
          queryParams.get("type"),
          props.eventKey,
          "weekly",
          currentPage,
          itemsPerPage,
          "on_demand"
        ],
      });
    }
  }
  useEffect(() => {
    if (currentPage && currentPage > 0) {
      handleDispatch();
    }
  }, [currentPage, itemsPerPage, props.eventKey, selected]);

  return (
    <div>
      <div className="container">
        <div className="col-md-12">
          <div
            className="formHeadings"
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {queryParams.get("type")} - Maintenance History
          </div>

          {/* Toggle button to choose frequency */}
          <ButtonGroup>
            <Button
              variant={selected === "button1" ? "primary" : "outline-primary"}
              onClick={() => handleClick("button1")}
            >
              Weekly
            </Button>
            <Button
              variant={
                selected === "button2" ? "secondary" : "outline-secondary"
              }
              onClick={() => handleClick("button2")}
            >
              Monthly
            </Button>
            <Button
              variant={selected === "button3" ? "primary" : "outline-primary"}
              onClick={() => handleClick("button3")}
            >
              Yearly
            </Button>
              <Button
              variant={
                selected === "button4" ? "secondary" : "outline-secondary"
              }
              onClick={() => handleClick("button4")}
            >
              On Demand
            </Button>
          </ButtonGroup>

          {/* history data - Maintenance */}
          {(historyArr &&
            historyArr !== null && historyArr?.length > 0) &&
            historyArr.map((item, i) => (
              <div className="list-wrapper" key={i}>
                {item.schedule_type === "maintenance" ? (
                  <div className="d-flex">
                    <div className="list-bullet">
                      <button
                        type="button"
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                        }}
                        data-tip
                        data-for={`tooltip-${item.id}`}
                        onMouseEnter={() => setSerialNumber(i + 1)}
                      >
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          style={{ color: "#1963D1", width: "12px" }}
                        />
                        <ReactTooltip
                          place="left"
                          effect="solid"
                          id={`tooltip-${item.id}`}
                          className="toolTip"
                        >
                          {serialNumber}
                        </ReactTooltip>
                      </button>
                    </div>
                    <div className="red-line"></div>
                    <div className="list-item-wrapper">
                      <div className="list-item"
                        onClick={() => navigate(`/maintenance/report/${item.schedule_mid}`)}>
                        <div className="list-title">
                          {item.completed_date !== null
                            ? moment(item.completed_date)
                              .format("MMMM Do YYYY | hh:mm A") : "-"}
                        </div>
                        <div
                          className="list-text"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* {item.frequency.frequency_type} */}
                          {item.schedule_name}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : <p></p>}
              </div>
            ))}

          {(historyArr && historyArr.length === 0) && (
            <span style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '18px', marginTop: "15px" }}>No Data Found</span>
          )}
        </div>
      </div>

      {/* pagination */}
      {(historyArr !== null && historyArr?.length > 0) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
            padding: "10px",
          }}
        >
          <div className="mb-6">
            <label>Items per page:</label> &nbsp;
            <select
              onChange={(e) => handleItemsPerPageChange(e.target.value)}
              style={{ borderRadius: "8px", cursor: "pointer" }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              {/* <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option> */}
            </select>
          </div>

          {/* //pagination */}
          <div style={{ paddingRight: "25px" }} className="pagination">
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={currentPage === 1}
              onClick={handleFirstPage}
            >
              {currentPage === 1 ? (
                <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}
            </button>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              {/* Prev */}
              {currentPage === 1 ? (
                <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
            </button>
            <span style={{ padding: "10px" }}>
              Showing {currentPage} of {totalPages} pages
            </span>
            {(totalPages === 0 || currentPage === totalPages) ? (
              <>
                <button
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  disabled={true}
                >
                  <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                </button>
                <button
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  disabled={true}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                </button>
              </>
            ) : <>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  border: "none",
                }}
                disabled={currentPage === totalPages}
                onClick={handleNext}
              >
                <FontAwesomeIcon icon={faAngleRight} color="steelblue" />
              </button>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  border: "none",
                }}
                disabled={currentPage === totalPages}
                onClick={handleLastpage}
              >
                <FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />
              </button>
            </>}

          </div>
        </div>
      ) : null}
      {/* //Back button */}
      <div className="m-2">
        <button className="backButton" onClick={() => navigate("/assetList")}>
          Back
        </button>
      </div>
    </div>
  );
}

export default MaintHistory;
