import { React, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo";

function ScheduleUploadheader() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("inspectionlist");

  const currentURL = window.location.href;

  useEffect(() => {
    if (currentURL?.includes('inspection')) {
      setSelectedTab("inspectionlist")
    } if (currentURL?.includes('maintenance')) {
      setSelectedTab("maintenancelist")
    }
  }, [selectedTab])


  return (
    <div>
      <div>
        <Navbar bg="light" variant={"light"} expand="lg">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="headershadow">
            <Nav
              className="mr-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
            >
              <Sidebar />
              <Logo onClick={() => navigate("/home")} />
              <Nav.Link
                onClick={() => setSelectedTab("inspectionlist")}
                as={Link}
                to="/inspectionlist"
                className="position-absolute top-25 end-0 header"
                style={{
                  color: "#000", marginRight: "380px",
                  borderRadius: '10px',
                  backgroundColor: selectedTab === "inspectionlist" ? "#64FFE2" : "",
                }}
              >
                Inspection
              </Nav.Link>
              <Nav.Link
                onClick={() => setSelectedTab("maintenancelist")}
                as={Link}
                to="/maintenancelist"
                className="position-absolute top-25 end-0 header"
                style={{
                  color: "#000",
                  marginRight: "250px",
                  borderRadius: '10px',
                  backgroundColor: selectedTab === "maintenancelist" ? "#64FFE2" : "",
                }}
              >
                Maintenance
              </Nav.Link>
              <LogoutButton />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
    </div>
  );
}

export default ScheduleUploadheader;
