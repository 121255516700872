import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const SingleCreation = () => {
    const initialStateData = {
        asset_id: "",
        asset_name: "",
        nature_of_asset: "",
        bu_code: "",
        gl_code: "",
        asset_tag: "",
        asset_class: "",
        supplier_name: "",
        supplier_code: "",
        supplier_location: "",
        cost_center: "",
        invoice_no: "",
        invoice_date: "",
        quantity: "",
        invoice_value: "",
        other_value: "",
        cost: "",
        acquisition_date: "",
        installation_date: "",
        salvage_value: "",
        useful_life: "",
        user: "",
        remarks: "",
        asset_image: "",
        invoice: "",
    }
    const [data, setData] = useState(initialStateData)
    const [supplier, setSupplier] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userid = useSelector((state) => state.auth.userid);
    const assetclass = useSelector((state) => state.FA.assetClass)
    const location = useSelector((state) => state.FA.location)
    const center = useSelector((state) => state.FA.costCenter)
    const [showButton, setShowButton] = useState(true)
    const [masterChecked, setMasterChecked] = useState(false);
    const isSubmitted = useSelector((state) => state.FA.isSubmitted)

    // console.log("center", center)
    // console.log(assetclass)
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (data?.invoice_value != null && data?.other_value !== null) {
            data.cost = Number(data.invoice_value) + Number(data.other_value)
        }
        // let newData = {
        //     ...data,
        //     [name]: value,
        // };
        // console.log(data)
        // setData(newData)
        setData((prevState) => {
            let newData = {
                ...prevState,
                [name]: value,
            };
            return newData;
        })
    }
    const onMasterCheck = (e) => {
        setMasterChecked(e.target.checked)
        setData((prevState) => {
            let newData = {
                ...prevState,
                asset_id: "",
            };
            return newData;
        })

    };


    const AttachmentDetails = () => {

        return (
            <>
                <p style={{
                    fontSize: '12px',
                    color: 'grey',
                    margin: '0',
                    padding: '0'
                }}>Supported file type : .pdf, .jpeg, .jpg, .png</p>
                <p style={{
                    fontSize: '12px',
                    color: 'grey',
                    margin: '0',
                    padding: '0'
                }}>Max file size : 5MB</p>
            </>
        )
    }

    const handleAssetImg = (input) => {
        // console.log(input);
        let file = input.target.files[0];
        // console.log(file);
        // console.log('fileread', file instanceof Blob);
        if (file && !file.type.match(/(jpeg|png|jpg)/)) {
            alert('Only JPEG, PNG, JPG files are allowed');
            input.target.value = '';
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64String = reader.result;
            // console.log('Base64 String - ', base64String);
            // Simply Print the Base64 Encoded String,
            // without additional data: Attributes.
            // console.log('Base64 String without Tags- ',
            //   base64String.substr(base64String.indexOf(', ') + 1));
            // console.log('base64String', base64String);
            setData({
                ...data,
                asset_image: base64String,
            });
        };
    };


    const handleInvoice = (input) => {
        // console.log(input);
        let file = input.target.files[0];
        // console.log(file);
        // console.log('fileread', file instanceof Blob);
        // if (file && !file.type.match(/(jpeg|png|jpg)/)) {
        //   alert('Only JPEG, PNG, JPG files are allowed');
        //   input.target.value = '';
        //   return;
        // }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64String = reader.result;
            // console.log('Base64 String - ', base64String);
            // Simply Print the Base64 Encoded String,
            // without additional data: Attributes.
            // console.log('Base64 String without Tags- ',
            //   base64String.substr(base64String.indexOf(', ') + 1));
            // console.log('base64String', base64String);
            setData({
                ...data,
                invoice: base64String,
            });
        };
    };
    // console.log(isSubmitted, "SUBMIT")
    useEffect(() => {
        if (isSubmitted) navigate("/cwip")
    }, [isSubmitted]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            // console.log("handleSubmit", data)
            data.created_by = userid
            if (!data.asset_name || !data.bu_code || !data.nature_of_asset || !data?.asset_class || !data.location || !data.cost_center || !data.quantity || !data.invoice_value || !data.useful_life || !data.salvage_value || !data.installation_date) {
                alert("Please fill mandatory fields")
            } else {
                if (new Date(data.installation_date) < new Date(data.invoice_date)) {
                    alert("Date of Installation can not be earlier than Date of Purchase")
                } else {


                    if ((data.salvage_value / data.invoice_value) > 0.5) {
                        alert("Please enter correct salvage value")
                    } else {
                        data.acquisition_date = data.invoice_date
                        dispatch({
                            type: "CREATE_FAASSET",
                            payload: [data]
                        });
                        // navigate("/cwip")
                    }

                }
            }

            // await axios.post("https://fa-airport.onrender.com/create", data)
            // alert("Asset created successfully")
        } catch (error) {
            // console.log(error)
        }
        // console.log(data)
    }
    // const getData = async () => {
    //     try {
    //         const { data } = await axios.get(`https://fa-airport.onrender.com/supplier`);
    //         // console.log(data)
    //         setSupplier(data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // };

    useEffect(() => {

        dispatch({
            type: "GET_CLASS_LIST",
            payload: [1, 50, '']
        })

        dispatch({
            type: "GET_LOCATION_LIST",
            payload: [1, 50, '']
        })
        dispatch({
            type: "GET_COST_CENTER_LIST",
            payload: [1, 50, '']
        })
        // getData()
    }, [])


    return (

        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div style={{ paddingTop: "1px" }}>
                        <form
                            action=""
                            className="mt-2 border p-4 bg-light shadow rounded"
                        >
                            <div className="formHeadings mb-3">Asset Registration</div>
                            <div className="row">
                                {/* <div className="mb-2 col-md-6">
                                    <label>
                                        Asset Id :<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="asset_id"
                                        className="form-control"

                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Asset ID<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="asset_id"
                                        onChange={handleChange}
                                        className="form-control"
                                        disabled={masterChecked}
                                        value={data?.asset_id}
                                    />
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="mastercheck"
                                        checked={masterChecked}
                                        onChange={(e) => onMasterCheck(e)}
                                    />  Auto Generate
                                </div>

                                <div className="mb-2 col-md-6">
                                    <label>
                                        Asset Name<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="asset_name"
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Nature of Asset<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="nature_of_asset"
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        BU Code<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="bu_code"
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        GL Code
                                    </label>
                                    <input
                                        type="text"
                                        name="gl_code"
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Asset Tag
                                    </label>
                                    <input
                                        type="text"
                                        name="asset_tag"
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Asset Class Code<span className="text-danger">*</span>
                                    </label>
                                    <select
                                        // type="text"
                                        name="asset_class"
                                        onChange={handleChange}
                                        className="form-select"
                                    >
                                        <option value="" >Select Asset Class Code</option>
                                        {(assetclass?.result && assetclass?.result.length > 0) && assetclass?.result.map((el) => <option value={el.code}>{el.code} {el.class}</option>)}
                                    </select>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Cost Center Code<span className="text-danger">*</span>
                                    </label>
                                    <select
                                        // type="text"
                                        name="cost_center"
                                        onChange={handleChange}
                                        className="form-select"
                                    >
                                        <option value="">Select Cost Center Code</option>
                                        {center?.result && center?.result.length > 0 && center?.result.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                                    </select>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Asset Location Code <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        // type="text"
                                        name="location"
                                        onChange={handleChange}
                                        className="form-select"
                                    >
                                        <option value="" >Select Location Code</option>
                                        {location?.result && location?.result.length && location?.result.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                                    </select>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Supplier Code
                                    </label>
                                    <input
                                        type="text"
                                        // placeholder='Select Supplier'
                                        onChange={handleChange}
                                        className="form-control"
                                        name="supplier_code">
                                    </input>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Supplier Name
                                    </label>
                                    <input
                                        type="text"
                                        // placeholder='Select Supplier'
                                        onChange={handleChange}
                                        className="form-control"
                                        name="supplier_name">
                                        {/* <option value="all">Select Supplier</option>
                                        {supplier && supplier.map((el) =>
                                            <>
                                                <option value={el}>{el}</option>
                                            </>
                                        )
                                        } */}
                                    </input>
                                    {/* <input
                                        type="text"
                                        name="supplier_name"
                                        onChange={handleChange}
                                        placeholder='Select Supplier'
                                        className="form-control"

                                    /> */}
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Supplier Location
                                    </label>
                                    <input
                                        type="text"
                                        // placeholder='Select Supplier'
                                        onChange={handleChange}
                                        className="form-control"
                                        name="supplier_location">
                                    </input>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>Date of Purchase</label>
                                    <input
                                        className="form-control"
                                        name="acquisition_date"
                                        onChange={handleChange}
                                        type="date"
                                        id="dop"
                                        value={data?.invoice_date}
                                        max={new Date().toISOString().split('T')[0]}
                                        disabled
                                    ></input>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>Date of Installation<span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        name="installation_date"
                                        onChange={handleChange}
                                        type="date"
                                        id="dop"
                                        max={new Date().toISOString().split('T')[0]}
                                    ></input>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>
                                        Invoice Date<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="invoice_date"
                                        className="form-control"
                                        onChange={handleChange}
                                        max={new Date().toISOString().split('T')[0]}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>
                                        Invoice Number
                                    </label>
                                    <input
                                        type="number"
                                        name="invoice_no"
                                        className="form-control"
                                        onChange={handleChange}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label >Invoice Value (INR)<span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        type='number'
                                        name='invoice_value'
                                        onChange={handleChange}
                                        min={0}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Attach Invoice
                                    </label>
                                    <input
                                        className="form-control"
                                        type='file'
                                        onChange={handleInvoice}
                                    />
                                    <AttachmentDetails />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>Salvage Value (INR)<span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        name="salvage_value"
                                        onChange={handleChange}
                                        type="number"
                                        min={0}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    ></input>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>Other Cost (INR)</label>
                                    <input
                                        minLength={0}
                                        maxLength={10}
                                        className="form-control"
                                        name="other_value"
                                        onChange={handleChange}
                                        type="number"
                                        min={0}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    // onKeyPress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                                    ></input>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>Capitalised Value (INR)<span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        name="cost"
                                        value={(Number(data?.invoice_value) + Number(data?.other_value || 0)) || 0}
                                        onChange={handleChange}
                                        type="number"
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                        disabled
                                    ></input>
                                </div>

                                <div className="mb-3 col-md-6">
                                    <label>Useful Life in Months<span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        name="useful_life"
                                        onChange={handleChange}
                                        type="number"
                                        max={999}
                                        min={0}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    ></input>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>Quantity<span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        name="quantity"
                                        type="number"
                                        onChange={handleChange}
                                        min={0}
                                        maxLength={3}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    ></input>
                                </div>

                                {/* <div className="mb-3 col-md-6">
                                    <label>Invoice No :</label>
                                    <input
                                        className="form-control"
                                        name="invoice_no"
                                        type="text"
                                        onChange={handleChange}
                                    ></input>
                                </div> */}
                                <div className="mb-3 col-md-6">
                                    <label>Description</label>
                                    <textarea
                                        className="form-control"
                                        name="remarks"
                                        onChange={handleChange}
                                        type="text"
                                    ></textarea>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label>User</label>
                                    <input
                                        className="form-control"
                                        name="user"
                                        onChange={handleChange}
                                        type="text"
                                    ></input>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>Asset Image</label>
                                    <input
                                        className="form-control"
                                        name="assetimg"
                                        type="file"
                                        id="formFile"
                                        onChange={handleAssetImg}
                                        accept=".jpeg,.jpg,.png"
                                    ></input>
                                    <AttachmentDetails />
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="mb-5 col-md-12">
                                    {/* <button
                                        onClick={() => navigate("/")}
                                        className="backButton"
                                    >
                                        Back
                                    </button> */}
                                    {(!data.asset_name || !data.bu_code || !data.nature_of_asset || !data?.asset_class || !data.location || !data.cost_center || !data.quantity || !data.invoice_value || !data.useful_life || !data.salvage_value || !data.installation_date || (!data.asset_id && !masterChecked))
                                        ? <button
                                            type="submit"
                                            className="float-end"
                                            disabled={true}
                                            style={{
                                                backgroundColor: "lightgrey",
                                                color: "black",
                                                padding: "8px",
                                                borderRadius: "10px",
                                                cursor: "not-allowed",
                                                width: "85px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Save
                                        </button> :
                                        <button
                                            type="submit"
                                            className="float-end saveButton" //TODO: add css for disabled
                                            onClick={(e) => handleSubmit(e)}
                                        // disabled={(!data.asset_name && !data.nature_of_asset && !data?.asset_class && !data.location && !data.cost_center && !data.invoice_value && !data.life && !data.salvage_value && !data.doi)}
                                        >
                                            Save
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >


    )
}

export default SingleCreation
// <div class="container bg-white" style={{ backgroundColor: "white", width: "85%", borderRadius: "0.5rem", padding: "1rem", boxShadow: "rgba(0.24, 0.24, 0.24, 0.24) 3px 3px 3px 3px" }}>
//     <p class="h5 text-center" style={{ marginBottom: "3rem", fontWeight: "bolder" }}>Asset Registration</p>
//     <form class="row g-3 bg-white fs-6">
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "2rem", }}>
//                 <label for="assetId" class="form-label bg-white">Asset Id :</label>
//                 <div style={{ width: "50%", }}>
//                     <input type="text" placeholder='Auto generate' name="asset_id" onChange={handleChange} disabled class="form-outline" id="asset_id" style={{ width: "100%", borderRadius: "0.4rem", height: "36px" }} />
//                 </div>
//             </div>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
//                 <label for="assetId" class="form-label bg-white">Asset Name :*</label>
//                 <input type="text" onChange={handleChange} class="form-outline" id="asset_name" name="asset_name" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", alignItems: "center", height: "36px" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center", }}>
//                 <label for="assetId" class="form-label bg-white">Nature Of Asset :*</label>
//                 <input type="text" onChange={handleChange} class="form-outline" id="nature" name="nature_of_asset" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", height: "36px", }} />
//             </div>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Cost Center :*</label>
//                 <input type="text" onChange={handleChange} class="form-outline" id="cost-center" name="cost_center" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", height: "36px" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Supplier Name :</label>
//                 <select type="text" placeholder='Select Supplier' onChange={handleChange} class="form-outline" id="nature" name="supplier_name" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", height: "36px" }} >
//                     <option value="all">Select Supplier</option>

//                     {supplier && supplier.map((el) =>
//                         <>
//                             <option value={el}>{el}</option>
//                         </>
//                     )
//                     }
//                 </select>
//             </div>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Location :*</label>
//                 <input type="text" onChange={handleChange} class="form-outline" id="location" name="location" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", height: "36px" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Invoice No :</label>
//                 <input type="text" onChange={handleChange} class="form-outline" id="invoice" name="invoice_no" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", height: "36px" }} />
//             </div>
//             {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem" }}>
//                     <label for="assetId" class="form-label bg-white">Attach Invoice :</label>
//                     <input class="form-outline" type="file" id="formFile" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid" }} />
//                 </div> */}
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Invoice Value (INR):*</label>
//                 <input class="form-outline" onChange={handleChange} name="invoice_value" type="number" id="formFile" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid", height: "36px" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem" }}>
//                     <label for="assetId" class="form-label bg-white">Invoice No :</label>
//                     <input type="text" onChange={handleChange} class="form-outline" id="invoice" name="invoice_no" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid" }} />
//                 </div> */}
//             {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem" }}>
//                     <label for="assetId" class="form-label bg-white">Attach Invoice :</label>
//                     <input class="form-outline" type="file" id="formFile" style={{ width: "50%", borderRadius: "0.4rem", border: "0.5px solid" }} />
//                 </div> */}
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Other Cost (INR):</label>
//                 <input class="form-outline" onChange={handleChange} name="other_value" type="number" id="formFile" style={{ width: "50%", height: "36px", borderRadius: "0.4rem", border: "0.5px solid" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Date Of Purchase:</label>
//                 <input class="form-outline" onChange={handleChange} type="date" id="dop" name="dop" style={{ width: "50%", height: "36px", borderRadius: "0.4rem", border: "0.5px solid", paddingLeft: "0.5rem" }} />
//             </div>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem" }}>
//                 <label for="assetId" class="form-label bg-white">Date Of Installation :*</label>
//                 <input type="date" onChange={handleChange} class="form-outline" id="doi" name="doi" style={{ width: "50%", height: "36px", borderRadius: "0.4rem", border: "0.5px solid" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center" }}>
//                 <label for="assetId" class="form-label bg-white">Cost Of Asset (INR):*</label>
//                 <input type="text" value={data?.cost ? data.cost : 0} disabled class="form-outline" id="cost" name="cost" style={{ width: "50%", borderRadius: "0.4rem", height: "36px", border: "0.5px solid" }} />
//             </div>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center" }}>
//                 <label for="assetId" class="form-label bg-white">Salvage Value (INR):*</label>
//                 <input class="form-outline" onChange={handleChange} type="number" id="salvage_value" name="salvage_value" style={{ width: "50%", height: "36px", borderRadius: "0.4rem", border: "0.5px solid", paddingLeft: "0.5rem" }} />
//             </div>
//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center" }}>
//                 <label for="assetId" class="form-label bg-white">Useful life In Months :*</label>
//                 <input type="number" onChange={handleChange} class="form-outline" id="life" name="life" style={{ width: "50%", height: "36px", borderRadius: "0.4rem", border: "0.5px solid" }} />
//             </div>

//             {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center" }}>
//                     <label for="assetId" class="form-label bg-white">User :</label>
//                     <input class="form-outline" onChange={handleChange} type="text" id="user" name="user" style={{ width: "50%", height: "36px", borderRadius: "0.4rem", border: "0.5px solid", paddingLeft: "0.5rem" }} />
//                 </div> */}

//         </div>
//         <div style={{ display: "flex" }}>
//             {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", alignItems: "center" }}>
//                     <label for="assetId" class="form-label bg-white">Rate Of Depreciation :</label>
//                     <input type="number" class="form-outline" id="rate" onChange={handleChange} name="rate" style={{ width: "50%", borderRadius: "0.4rem", height: "36px", border: "0.5px solid" }} />
//                 </div> */}

//         </div>
//         <div style={{ display: "flex" }}>
//             <div class="col-md-12 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem" }}>
//                 {/* <label for="assetId" class="form-label bg-white">Description :</label>
//                         <input type="text" class="form-outline" id="nature" style={{ width: "76%", borderRadius: "0.4rem", border: "0.5px solid" }} /> */}
//                 <label class="form-label" for="textAreaExample">Description :</label>
//                 <textarea class="form-outline" onChange={handleChange} id="textAreaExample" name="description" rows="4" style={{ width: "76%" }}></textarea>
//             </div>

//         </div>
//         <div style={{ marginTop: "3rem", paddingBottom: "3rem", paddingRight: "2rem" }}>
//             <button type="button" class="btn btn-primary" onClick={handleSubmit} style={{ width: "30%", float: "right" }}>Submit</button>
//         </div>
//     </form>
// </div>