import React from 'react'
import "../home/home.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCircleInfo, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import ReactCardFlip from 'react-card-flip';
import { info } from 'react-icons-kit/icomoon/info'
import ReactTooltip from "react-tooltip";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Tooltip from '../../components/Tooltip/Tooltip';
import Icon from 'react-icons-kit';
import { color } from 'highcharts';
import { useSelector, useDispatch } from "react-redux";




function StatusComponent(props) {

    const { startDate, endDate, handleDateChange } = props;
    const [status, setStatus] = useState(null)
    const [cardOpen, setCardOpen] = useState(false); // to open filter of inspection card
    const [isFlipped, setIsFlipped] = useState(false); // to flip the card of inspection
    const sites_id = useSelector((state) => state.auth?.sites_id && state.auth?.sites_id[0]);

    const today = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone: "UTC",
    }).format(new Date());
    // console.log(today)  

    //open or close the filter based on Onclick  ///
    const openInsOption = () => {
        // console.log('Dropdown button clicked!');
        // console.log(cardOpen);
        setCardOpen(!cardOpen);
    };

    /// handle flip the card - inspection /////
    const handleFlip = () => {
        // console.log('clicked card');
        setIsFlipped(!isFlipped);
    }

    const handleClear = () => {
        window.location.reload(true)
    }

    useEffect(() => {
        // console.log(props.data);
        if (props.data !== undefined) {
            setStatus(props.data)
        }
    }, [props.data, status])

    function StatusCard({ parent, title, count, handlefunction, tooltip_data, percentage }) {

        // Set the maximum font size and the threshold for reducing font size
        const maxFontSize = 14; // in pixels
        const thresholdLength = 24; // characters

        // Calculate font size based on data length
        let fontSize;
        if (title.length > thresholdLength) {
            // const reducedFontSize = maxFontSize * (thresholdLength / title.length);
            // fontSize = `${reducedFontSize}px`;
            fontSize = 14
        } else {
            // fontSize = `${maxFontSize}px`;
            fontSize = 16
        }
        return (
            <div
                // className="p-4 bg-white text-center"
                style={{
                    width: "16rem",
                    // width: "12rem",
                    height: "7rem",
                    // height: "6rem",
                    // borderRadius: "1.5rem",
                    borderRadius: "0.6rem",
                    // marginLeft: '-12px',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    // paddingTop: '0.3rem',
                    paddingRight: '0.2rem',
                    // border: '0.1px solid',
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: 'space-between',
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    // boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                    // boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"
                }}
                onClick={handlefunction}
            >
                <div>
                    <div
                        style={{
                            float: 'right',
                            width: '22px',
                        }}
                    >
                        {/* <Tooltip text={tooltip_data}>
                            <div
                                style={{
                                    color: '#C6C6C6'
                                }}
                            >
                                <Icon size={16} icon={info} />
                            </div>
                        </Tooltip> */}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        // alignItems: 'space-between',
                        // alignContent: 'space-between',
                        // border: '1px solid',
                        // height: '100%',
                        // border: '1px solid'
                    }}>

                        <div style={{
                            display: 'flex',
                            paddingRight: "0.5rem",
                            alignItems: "baseline",

                        }}>
                            {percentage != undefined ?
                                <>
                                    <p
                                        style={{
                                            margin: '0',
                                            fontSize: count == null ? "13px" : '36px',
                                            padding: '0.3rem 0.5rem 0 0.5rem',
                                            color: '#454545'
                                        }}
                                    >
                                        {percentage} %
                                    </p>
                                    <p
                                        style={{
                                            margin: '0',
                                            fontSize: "14px",
                                            padding: '0 0 0 0.5rem',
                                            color: 'gray',
                                            float: 'bottom'
                                        }}
                                    >
                                        {count == null ? "Loading..." : count} <span>{parent == 'ticket' ? "Ticket" : "Activity"}</span>
                                    </p>
                                </>
                                :
                                <>
                                    <p
                                        style={{
                                            margin: '0',
                                            fontSize: count == null ? "13px" : '36px',
                                            padding: '0.3rem 0.5rem 0 0.5rem',
                                            color: '#454545'
                                        }}
                                    >
                                        {count == null ? "Loading..." : count}
                                    </p>
                                    <p
                                        style={{
                                            margin: '0',
                                            fontSize: "14px",
                                            padding: '0 0 0 0.5rem',
                                            color: 'gray',
                                            float: 'bottom'
                                        }}
                                    >
                                        {/* {count == null ? "Loading..." : count} Task */}
                                    </p>
                                </>
                            }
                            {/* <p
                                style={{
                                    margin: '0',
                                    fontSize: count == null ? "13px" : '36px',
                                    padding: '0.3rem 0.5rem 0 0.5rem',
                                    color: '#454545'
                                }}
                            >
                                {percentage !== undefined ? `${percentage} %` : count == null ? "Loading..." : count}
                            </p>
                            {percentage && <p
                                style={{
                                    margin: '0',
                                    fontSize: "14px",
                                    padding: '0 0 0 0.5rem',
                                    color: 'gray',
                                    float: 'bottom'
                                }}
                            >
                                {count == null ? "Loading..." : count} Task
                            </p>} */}
                        </div>
                    </div>
                </div>
                <div style={{
                    // border: '1px solid',
                    width: '100%',
                    margin: '0',
                    padding: '0'
                    // backgroundColor: 'gray'
                }}>
                    <p
                        style={{
                            margin: '0',
                            padding: '0 0 0.2rem 0',
                            color: 'gray',
                            fontSize: fontSize
                            // fontSize: `calc(16px + (20px * (100% / ${300})))`
                            // calc(10px + (20px * (100% / 300)))
                        }}
                    >{title}</p>
                </div>
            </div>
        )
    }
    return (
        <div style={{ margin: 'auto' }}>
            <div className="row" style={{ width: '95%', padding: "0.7rem 0", margin: 'auto' }}>
                {/* Inspection Card */}
                <div style={{ display: "flex", width: '100%' }}>
                    <div
                        style={{
                            fontSize: "18px",
                            flex: 1,
                        }}
                    >
                        {props.name}
                        <div>
                            {startDate !== null && endDate !== null && (props.name !== "Maintenance Status") ?
                                // moment(eachData.frequency_start).local().format("MMMM Do YYYY | hh:mm A")
                                <p style={{ color: 'gray' }}>
                                    {moment(startDate).local().format("Do MMMM YYYY")} To {moment(endDate).local().format("Do MMMM YYYY")}
                                </p>
                                : null}
                            {startDate !== null && endDate !== null && (props.name === "Maintenance Status") ?
                                // moment(eachData.frequency_start).local().format("MMMM Do YYYY | hh:mm A")
                                <p style={{ color: 'gray' }}>
                                    {moment(startDate).startOf('week').format("Do MMMM YYYY")} To {moment(endDate).endOf('week').format("Do MMMM YYYY")} (Week - {moment(startDate).week()})
                                </p>
                                : null}
                            {((props.name === "Maintenance Status") && (startDate === null && endDate === null)) ?
                                <p style={{ color: 'gray' }}>{moment().startOf('week').format("Do MMMM YYYY")} To {moment().endOf('week').format("Do MMMM YYYY")} (Week - {moment().week()})</p>
                                :
                                ((props.name === "Inspection Status") && (startDate === null && endDate === null)) ?
                                    <p style={{ color: 'gray' }}>{moment(today).local().format("Do MMMM YYYY")}</p>
                                    :
                                    ((props.name === "On-Demand Status") && (startDate === null && endDate === null)) ?
                                        <p style={{ color: 'gray' }}>{moment(today).local().format("Do MMMM YYYY")}</p>
                                        :
                                        ((props.name === "Ticket Status") && (startDate === null && endDate === null)) ? <p style={{ color: 'gray' }}>{moment(today).local().format("Do MMMM YYYY")}</p>
                                            :
                                            null
                            }
                        </div>
                    </div>
                    <div
                        // className="p-4"
                        style={{
                            fontSize: "18px",
                            // padding: "0px",
                            // border: '1px solid'
                        }}
                    >
                        {!cardOpen
                            ?
                            (
                                <button
                                    // className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    style={{
                                        border: "black",
                                        background: "none",
                                        color: "white",
                                        // fontSize: "15px",
                                        // borderRadius: "5px",
                                    }}
                                    onClick={openInsOption}
                                    data-tip
                                    data-for="openCloseCal"
                                >
                                    <FontAwesomeIcon icon={faCalendar} size="lg" style={{ color: 'gray' }} />
                                    <ReactTooltip
                                        id="openCloseCal"
                                        place="bottom"
                                        effect="solid"
                                    >
                                        Custom Date
                                        <br />
                                        Open Calendar
                                    </ReactTooltip>
                                </button>
                            )
                            :
                            (
                                <button
                                    // className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    style={{
                                        border: "black",
                                        background: "none",
                                        color: "white",
                                        // fontSize: "15px",
                                        // borderRadius: "5px",
                                        float: 'right'
                                    }}
                                    onClick={openInsOption}
                                    data-tip
                                    data-for="CloseCal"
                                >
                                    <div
                                        style={{
                                            // border: '1px solid black',
                                            // 
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSquareXmark} style={{ color: 'black' }} size="lg" />
                                    </div>
                                    <ReactTooltip
                                        id="CloseCal"
                                        place="bottom"
                                        effect="solid"
                                    >
                                        Custom Date
                                        <br />
                                        Close Calendar
                                    </ReactTooltip>
                                </button>
                            )}
                        {cardOpen && (
                            <div
                                className="d-flex p-2 justify-content-between"
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: "0.5rem",
                                }}
                            >
                                {props.name == "Maintenance Status" ? <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    minDate={sites_id === '6422f56e26037ff46450359d' ? new Date("2024-06-21") : null}
                                    dateFormat="I/R"
                                    locale="en-GB"
                                    withPortal
                                    showWeekNumbers
                                    showWeekPicker
                                /> :
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={new Date()}
                                        minDate={sites_id === '6422f56e26037ff46450359d' ? new Date("2024-06-21") : null}
                                        selectsRange
                                        withPortal
                                        placeholderText="Click to select date"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                }
                                &nbsp;
                                <div>
                                    <button
                                        style={{
                                            height: "2rem",
                                            border: "none",
                                            borderRadius: '0.5rem',
                                            backgroundColor: "lightblue",
                                            padding: "5px",
                                        }}
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Maintenance status bar */}

                {props.name !== "On-Demand Status" && props.name !== "Ticket Status" &&
                    <div className="justify-content-evenly row" style={{ gap: '10px' }}>
                        {/* Total status */}
                        <StatusCard title={"Total Planned"} handlefunction={props.handleTotal} count={(status === null || status.totalPlanned === '') ? null : status.totalPlanned} tooltip_data={props.info.total_planned} />
                        {/* Executed : waiting_for_approval */}
                        <StatusCard title={"Waiting For Approval"} handlefunction={props.handlewaiting} count={(status === null || status.waitingApproval === '') ? null : status.waitingApproval} tooltip_data={props.info.waiting_for_approval} percentage={(status === null || status.waitingApproval === '' && status.totalPlanned === '') ? null : Math.round((status.waitingApproval / status.totalPlanned) * 100 || 0)} />
                        {/* Completed */}
                        <StatusCard title={"Completed"} handlefunction={props.handleCompleted} count={(status === null || status.completed === '') ? null : status.completed} tooltip_data={props.info.completed} percentage={(status === null || status.completed === '' && status.totalPlanned === '') ? null : Math.round((status.completed / status.totalPlanned) * 100 || 0)} />
                        {/* Reopened */}
                        <StatusCard title={"Reopened"} handlefunction={props.handleReopen} count={(status === null || status.reopened === '') ? null : status.reopened} tooltip_data={props.info.reopened} percentage={(status === null || status.reopened === '' && status.totalPlanned === '') ? null : Math.round((status.reopened / status.totalPlanned) * 100 || 0)} />
                        {/* Skipped */}
                        <StatusCard title={"Skipped"} handlefunction={props.handleskipped} count={(status === null || status.skipped === '') ? null : status.skipped} tooltip_data={props.info.skipped} percentage={(status === null || status.skipped === '' && status.totalPlanned === '') ? null : Math.round((status.skipped / status.totalPlanned) * 100 || 0)} />
                    </div>
                }

                {/* On demand status bar */}
                {props.name === "On-Demand Status" &&
                    <div className="justify-content-evenly row" style={{ gap: '10px' }}>
                        {/* Total status */}
                        <StatusCard title={"In Progress"} handlefunction={props.handleInprogress} count={(status === null || status.ondemandIndraft === '') ? null : status.ondemandIndraft} tooltip_data={props.info.in_progress} />
                        {/* Waiting for Approval status */}
                        <StatusCard title={"Waiting for Approval"} handlefunction={props.handlewaiting} count={(status === null || status.ondemandWaitingForApproval === '') ? null : status.ondemandWaitingForApproval} tooltip_data={props.info.waiting_for_approval} />
                        {/* Completed status */}
                        <StatusCard title={"Completed"} handlefunction={props.handleCompleted} count={(status === null || status.ondemandApproved === '') ? null : status.ondemandApproved} tooltip_data={props.info.completed} />
                        {/* ondemandReopened status */}
                        <StatusCard title={"Reopened"} handlefunction={props.handleReopen} count={(status === null || status.ondemandReopened === '') ? null : status.ondemandReopened} tooltip_data={props.info.re_opened} />
                        {/* Save as Draft status */}
                        <StatusCard title={"Save as Draft"} handlefunction={props.handleSaveasDraft} count={(status === null || status.ondemandSaveAsDraft === '') ? null : status.ondemandSaveAsDraft} tooltip_data={props.info.save_as_draft} />
                    </div>}

                {/* Ticket status bar */}
                {props.name === "Ticket Status" &&
                    <div className="justify-content-evenly row" style={{ gap: '10px' }}>
                        {/* Total status */}
                        <StatusCard parent={'ticket'} title={"Total Raised"} count={(status === null || status.totalPlanned === '') ? null : status.totalPlanned} tooltip_data={props.info.total_planned} />
                        {/* Open status */}
                        {/* <StatusCard title={"Open"} count={(status === null || status.assigned === '') ? null : status.assigned} tooltip_data={props.info.assigned} /> */}
                        {/* Waiting for Approval status */}
                        <StatusCard parent={'ticket'} title={"Waiting for Approval"} count={(status === null || status.waitingApproval === '') ? null : status.waitingApproval} tooltip_data={props.info.waiting_for_approval} percentage={(status === null || status.waitingApproval === '' && status.totalPlanned === '') ? null : Math.round((status.waitingApproval / status.totalPlanned) * 100 || 0)} />
                        {/* Completed status */}
                        <StatusCard parent={'ticket'} title={"Completed"} count={(status === null || status.completed === '') ? null : status.completed} tooltip_data={props.info.completed} percentage={(status === null || status.completed === '' && status.totalPlanned === '') ? null : Math.round((status.completed / status.totalPlanned) * 100 || 0)} />
                        {/* Reopened status */}
                        <StatusCard parent={'ticket'} title={"Reopened & Reassigned"} count={(status === null || status.reopened === '') ? null : (status.reopened + status.reassigned)} tooltip_data={props.info.re_opened} percentage={(status === null || status.reopened === '' && status.totalPlanned === '') ? null : Math.round(((status.reopened + status.reassigned) / status.totalPlanned) * 100 || 0)} />
                        {/* Yet To Assign status */}
                        <StatusCard parent={'ticket'} title={"Yet To Be Assigned"} count={(status === null || status.yetToBeAssigned === '') ? null : status.yetToBeAssigned} tooltip_data={props.info.yet_to_assign} percentage={(status === null || status.yetToBeAssigned === '' && status.totalPlanned === '') ? null : Math.round((status.yetToBeAssigned / status.totalPlanned) * 100 || 0)} />
                    </div>}
            </div >
        </div >
    )
}

export default StatusComponent
