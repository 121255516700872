/* Feature - Reducer for asset

Created by - Mona R
Updated by - Mona R */

import {
  ASSET_GET_LIST,
  ASSET_GET_SUCCESS,
  ASSET_GET_FAILED,
  ASSET_DELETE_LIST,
  ASSET_DELETE_SUCCESS,
  ASSET_DELETE_FAILED,
  ASSET_ADD,
  ASSET_ADD_SUCCESS,
  ASSET_ADD_FAILED,
  ASSET_UPDATE,
  ASSET_UPDATE_SUCCESS,
  ASSET_UPDATE_FAILED,
  ASSET_GET_SINGLE,
  ASSET_GET_SINGLE_SUCCESS,
  ASSET_GET_SINGLE_FAILED,
} from "../actions/assetAction";

const inistialAssetState = {
  assetData: { result: [] },
  assetAddData: [],
  isSubmitted: false,
  isLoading: false,
};

export default function AssetReducer(state = inistialAssetState, action) {
  switch (action.type) {
    case ASSET_GET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case ASSET_GET_SUCCESS:
      return {
        ...state,
        assetData: action.payload.data,
        isSubmitted: false,
        isLoading: false,
      };
    case ASSET_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ASSET_DELETE_LIST:
      return {
        ...state,
      };
    case ASSET_DELETE_SUCCESS:
      return {
        ...state,
      };
    case ASSET_DELETE_FAILED:
      return {
        ...state,
      };
    case ASSET_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case ASSET_ADD_SUCCESS:
      return {
        ...state,
        assetAddData: action.payload,
        isSubmitted: true,
        isLoading: false,
      };
    case ASSET_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ASSET_GET_SINGLE:
      return {
        ...state,
        isLoading: true,
      };
    case ASSET_GET_SINGLE_SUCCESS:
      return {
        ...state,
        assetGetSingle: action.payload,
        isLoading: false,
      };
    case ASSET_GET_SINGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ASSET_UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case ASSET_UPDATE_SUCCESS:
      return {
        ...state,
        assetUpdateData: action.payload.data,
        isLoading: false,
      };
    case ASSET_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_ASSET_REDUCER":
      return {
        ...state,
        assetData: {},
        assetAddData: [],
      };
    default:
      return state;
  }
}
