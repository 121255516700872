/* Feature - Get all Onboarded user list

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faCalendarDays,
  faAngleRight,
  faAngleLeft,
  faCancel,
  faClose,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

//Function to fetch all asset
function UserList() {
  //For Search and filter by murthi new
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Add state variables for current page and items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const userData = useSelector((state) => state.user.userData);
  const access = useSelector((state) => state.access.user);
  const role = useSelector((state) => state.auth.role);

  // console.log(userData);

  // Function to handle next button
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(currentPage - currentPage + 1);
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  //Changes for Pagination and Search
  const handleClearButton = () => {
    setSearchInput("");
    //added new
  };
  // console.log(searchInput)

  // Function to handle search submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let keyWord = e.target.value;
    // Check if siteData is defined
    if (userData) {
      const filteredData = userData.filter((user) => {
        return (
          (user &&
            user.first_name &&
            user.first_name.toLowerCase().includes(keyWord.toLowerCase())) ||
          (user &&
            user.last_name &&
            user.last_name.toLowerCase().includes(keyWord.toLowerCase())) ||
          (user &&
            user.email &&
            user.email.toLowerCase().includes(keyWord.toLowerCase())) ||
          (user &&
            user.mobile_number &&
            user.mobile_number.toString().includes(keyWord))
        );
      });
      setFilteredData(filteredData);
      setCurrentPage(1);
      // console.log(filteredData)
      // }
    }
  };

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Slice the filteredData array to only show the appropriate items
  let currentItems = [];
  if (searchInput === "") {
    currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);
  } else {
    currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }
  // Calculate the total number of pages
  let totalPages = 0;
  if (searchInput === "") {
    totalPages = Math.ceil(userData.length / itemsPerPage);
  } else {
    totalPages = Math.ceil(filteredData.length / itemsPerPage);
  }

  // console.log("userData", userData);
  //dispatch to get all user list
  const getUserDetails = () => {
    dispatch({
      type: "USER_GET_LIST",
    });
  };

  //function to delete user from list
  const handleDelete = (_id) => {
    dispatch({
      type: "USER_DELETE_LIST",
      payload: _id,
    });
  };

  //useEffect
  useEffect(() => {
    if (userData.length === 0) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    const roleCode = role[0];
    // console.log(roleCode);
    if (access === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role]);

  useEffect(() => {
    // console.log(access);
    // console.log("userData", userData);
  }, [access]);

  return (
    <div>
      {/* Table to view user list */}
      <div className="col-md-12">
        <div
          className="listHeadings"
          style={{
            fontSize: "20px",
            paddingLeft: "100px",
          }}
        >
          User List
          <div style={{ float: "right" }}>
            <form
              className="mb-4"
              onChange={handleSearchSubmit}
              onSubmit={handleSearchInputChange}
            >
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "5px",
                }}
              />

              {/* {console.log(searchInput)} */}
              <span
                type="reset"
                onClick={handleClearButton}
                style={{ paddingLeft: "8px", marginRight: "25px" }}
              >
                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
              </span>
            </form>
          </div>
          <div>Create & Delete</div>
        </div>
        {/* Table to view asset list */}
        <div className="mb-6">
          <table className="table-bordered" width="100%">
            <thead>
              {/* Table heading to display list of Site */}
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}
              >
                <th style={{ padding: "8px", width: "70px" }}>S. No</th>
                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                  First Name
                </th>
                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                  Last Name
                </th>
                <th scope="col" style={{ padding: "4px", textAlign: "left" }}>
                  Email ID
                </th>
                <th scope="col">Mobile Number</th>
                {access !== null && access.c ? (
                  <th
                    scope="col"
                    colSpan="3"
                    className="actionColumn"
                    style={{ textAlign: "center" }}
                  >
                    Action
                  </th>
                ) : null}
              </tr>
            </thead>
            {/* {console.log(currentItems)} */}
            {currentItems !== null && currentItems.length > 0 ? (
              currentItems.map((eachData, index) => {
                return (
                  <tbody key={eachData._id} style={{ padding: "15px" }}>
                    <tr style={{ padding: "20px" }}>
                      {/* function Double click on row to view all asset atribute other than availbe on table.  */}
                      <td style={{ textAlign: "center", padding: "4px" }}>
                        {(currentPage - 1) * itemsPerPage + (index + 1)}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                        {eachData.first_name}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                        {eachData.last_name}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                        {eachData.email}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.country_code} {eachData.mobile_number}
                      </td>
                      {access !== null && access.d ? (
                        <td style={{ textAlign: "center" }}>
                          {/* Delete button */}
                          <button
                            onClick={() => {
                              window.confirm(
                                "Are you sure you want to delete this item?"
                              ) && handleDelete(eachData._id);
                            }}
                            style={{
                              border: "none",
                              color: "#2D7DC1",
                              background: "none",
                            }}
                            data-tip
                            data-for="deleteTip"
                          >
                            <FontAwesomeIcon icon={faTrash} size="lg" />
                            <ReactTooltip
                              id="deleteTip"
                              place="bottom"
                              effect="solid"
                            >
                              Delete
                            </ReactTooltip>
                          </button>
                          {/* )} */}
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr style={{ textAlign: "center", height: "75px" }}>
                  <td colSpan="6">No Data Available</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "30px",
            padding: "10px",
          }}
        >
          <div className="mb-6">
            <label>Items per page:</label> &nbsp;
            <select
              onChange={(e) => handleItemsPerPageChange(e.target.value)}
              style={{ borderRadius: "8px", cursor: "pointer" }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={userData.length}>All</option>
            </select>
          </div>

          <div style={{ paddingRight: "25px" }} className="pagination">
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={currentPage === 1}
              onClick={handleFirstPage}
            >
              {currentPage === 1 ? (
                <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}
            </button>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              {/* Prev */}
              {currentPage === 1 ? (
                <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
            </button>
            <span style={{ padding: "10px" }}>
              Showing {currentPage} of {totalPages} pages
            </span>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "none",
              }}
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              {currentPage === totalPages ? (
                <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
            </button>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "none",
              }}
              disabled={currentPage === totalPages}
              onClick={handleLastpage}
            >
              {currentPage === totalPages ? (
                <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserList;
