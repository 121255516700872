import React from 'react'
import BasedOnUser from './basedOnUserSelection'

function Report() {
  return (
    <div>
      <BasedOnUser />
    </div>
  )
}

export default Report
