import React from 'react'
import { useEffect, useState } from 'react';
import UserroleModel from './userRoleModel';
import { accessService } from "../../services/service.index";
import { useSelector, useDispatch } from "react-redux";


function CustomRole(props) {

    const [data, setData] = useState([]); // to set Role list
    const [featureAccess, setFeatureAccess] = useState([]); // to set feature access
    const [loading, setLoading] = useState(false);
    const userid = useSelector((state) => state.auth.userid);
    const dispatch = useDispatch();


    //fetch all Role details
    const getRoleList = async () => {
        await accessService
            .getAccessRole()
            .then(function (res) {
                setData(res.data);
                // console.log(res.data);
            })
            .catch(function (error) {
                // console.log(error.response);
            });
    };

    const handlRole = (rolecode) => {
        getFeatureAccess(rolecode)
    }


    const getFeatureAccess = async (rolecode) => {
        // console.log(rolecode);
        setLoading(true);
        await accessService
            .getAllAccessByRoleCode(rolecode)
            .then(function (res) {
                // console.log(res.data);
                setFeatureAccess(res.data[0].feature_control);
                setLoading(false);
            })
            .catch(function (error) {
                // console.log(error.response);
                setLoading(false);
            });
    };



    // getting updated data from child and pass it services.

    const getupdateddata = (updatedData, roleName, rolecode) => {
        let newRole = {
            "role_name": roleName,
            "role_code": rolecode,
            "created_by": userid,
            "feature_control": updatedData
        }
        // console.log('updatedData send to the API', newRole);
        customrole(newRole)
    }
    const customrole = async (newRole) => {
        await accessService.createCustomRole(newRole).then(function (res) {
            // console.log(res);
            if (res.status === 201 || res.status === 200) {
                dispatch ({
                    type: 'ROLE_GET_LIST'
                  })
                alert('"The new role has been created successfully')
                props.onHide();
            }
        }).catch(function (err) {
            // console.log(err);
        })
    }


    useEffect(() => {
        if (data.length === 0) {
            getRoleList();
        }
    }, [data]);

    useEffect(() => {

    }, [props])

    return (
        <div>
            <UserroleModel
                show={props.show}
                onHide={props.onHide}
                name={'Custom Feature Access'}
                rolelist={data}
                sendrole={handlRole}
                featureaccess={featureAccess}
                getupdateddata={getupdateddata}
                // getRoleList={getRoleList}
            // loading={loading}
            />
           
        </div>
    )
}

export default CustomRole
