/* Feature - Create password of user

Created by - Mona R
Updated by - Janaki */

import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";

function CreatePassword() {
  const navigate = useNavigate();
  const [showForm, setshowForm] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const isSubmitted = useSelector((state) => state.user.isSubmitted);

  const fetchId = useCallback(() => {
    return id;
  }, [id]);

  // function to prevent space as an input.
  const handleSpace = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  //Functions to handle form inputs
  const handlePassword = (val) => {
    setData({
      ...data,
      password: val,
    });
  };
  const handleComfirmPassword = (val) => {
    setData({
      ...data,
      confirmPassword: val,
    });
  };

  // handle eye button
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [type1, setType1] = useState("password");
  const [icon1, setIcon1] = useState(eyeOff);
  
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const handleToggle1 = () => {
    if (type1 === "password") {
      setIcon1(eye);
      setType1("text");
    } else {
      setIcon1(eyeOff);
      setType1("password");
    }
  };

  // validation

  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let userData = {
      activation_code: id,
      pass: data.password,
    };
    // console.log(userData);
    dispatch({
      type: "USER_SET_PASSWORD",
      payload: userData,
    });
  };

  if (isSubmitted) {
    // console.log(isSubmitted);
    navigate("/");
  }
  useEffect(() => {
    // token validation API
    const activationUrl = process.env.REACT_APP_BASE_URL + "/user/verifycode";
    var data1 = JSON.stringify({
      activation_code: id,
    });

    var config = {
      method: "post",
      url: activationUrl,
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };

    Axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        const valid_code = response.data === "valid activation code";
        if (valid_code) {
          setshowForm(true);
        } else {
          alert("Invalid code");
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
    fetchId();
  }, [id, fetchId]);

  return (
    <div>
      {showForm ? (
        <div className="container">
          <div className="row">
            <div className="col-md-4 offset-md-4 pt-4">
              <form
                className="mt-5 border p-4 bg-light shadow rounded"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="formHeadings mb-2">Create Password</div>
                <div className="row">
                  <div className="mb-2 col-md-12">
                    <label>New Password</label>
                    <input
                      type={type}
                      maxLength={15}
                      name="password"
                      className="form-control"
                      minLength={8}
                      placeholder="Minimum 8 Character"
                      onChange={(e) => {
                        handlePassword(e.target.value);
                      }}
                      onKeyDown={handleSpace}
                    />
                    <span onClick={handleToggle}>
                      <Icon className="eyeIcon" icon={icon} />
                    </span>
                    {Object.keys(passwordErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>{passwordErr[key]}</div>
                      );
                    })}
                  </div>
                  <div className="mb-3 col-md-12">
                    <label>Confirm Password</label>
                    <input
                      type={type1}
                      maxLength={15}
                      name="confirmpassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        handleComfirmPassword(e.target.value);
                      }}
                      onKeyDown={handleSpace}
                    />
                    <span onClick={handleToggle1}>
                      <Icon className="eyeIcon" icon={icon1} />
                    </span>
                    {Object.keys(confirmPasswordErr).map((key) => {
                      return (
                        <div style={{ color: "red" }}>
                          {confirmPasswordErr[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-12">
                    <button type="button" className="backButton mt-3">
                      Cancel
                    </button>
                    <button type="submit" className="saveButton float-end">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CreatePassword;
