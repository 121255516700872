/* Feature - Reducer for history details

Created by - Janaki J
Updated by - Janaki J */

import {
  HISTRY_GET_LIST,
  HISTRY_GET_SUCCESS,
  HISTRY_GET_FAILED,
} from "../actions/historyAction";

const inistialHistoryState = {
  historyData: {},
  isLoading: false,
};

export default function HistoryReducer(state = inistialHistoryState, action) {
  switch (action.type) {
    case HISTRY_GET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case HISTRY_GET_SUCCESS:
      return {
        ...state,
        historyData: action.payload.data,
        isLoading: false,
      };
    case HISTRY_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_HISTORY_REDUCER":
      return {
        ...state,
        historyData: {},
      };
    default:
      return state;
  }
}
