import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Dashboard from '../../layouts/dashboard/dashboard';
import Select from "react-select";
import TrendChart from './trendChart';
import { assetService } from "../../services/service.index";


function Trend() {
    const dispatch = useDispatch();
    const [chartType, setChartType] = useState({ value: "column", label: "Column" });

    //to fetch data from redux
    const assetData = useSelector((state) => state.asset.assetData);

    // console.log(assetData);

    const handleChartTypeChange = (event) => {
        // console.log(event);
        if (event === null) {
            setChartType({ value: "column", label: "Column" });
        } else {
            setChartType({ value: event.value, label: event.label });
        }
    };
    const data = [
        [2, 1, 3, 4, 2, 3, 1, 4, 3, 2, 1, 4, 3, 2, 1, 2, 3, 4, 1, 2, 3, 4, 3, 2, 1, 4, 3, 2, 1],
        [3, 2, 4, 1, 3, 2, 4, 1, 2, 3, 4, 1, 3, 2, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2],
        [1, 4, 2, 3, 1, 4, 2, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2],
        [4, 3, 1, 2, 4, 3, 1, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1],
        [2, 1, 3, 4, 2, 3, 1, 4, 3, 2, 1, 4, 3, 2, 1, 2, 3, 4, 1, 2, 3, 4, 3, 2, 1, 4, 3, 2, 1],
        [3, 2, 4, 1, 3, 2, 4, 1, 2, 3, 4, 1, 3, 2, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2],
        [1, 4, 2, 3, 1, 4, 2, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2],
        [4, 3, 1, 2, 4, 3, 1, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1, 4, 3, 2, 1],
        [2, 1, 3, 4, 2, 3, 1, 4, 3, 2, 1, 4, 3, 2, 1, 2, 3, 4, 1, 2, 3, 4, 3, 2, 1, 4, 3, 2, 1],
        [3, 2, 4, 1, 3, 2, 4, 1, 2, 3, 4, 1, 3, 2, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2]
    ];

    useEffect(() => {
        // assetService
        //     .getAssetById(siteId, type)
        //     .then(function (res) {
        //         console.log(res.data);
        //         // setAllasset(res.data);
        //     })
        //     .catch(function (error) {
        //         console.log(error.response);
        //     });
    }, []);


    return (
        <div>
            <Dashboard selectedTab={'trend'} />
            <br />
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="mb-2">
                            <Select
                                className="dropdown"
                                placeholder="Select Asset ID"
                                // getOptionLabel={(option) => option.asset_id || option.device_id || option.facility_id}
                                // getOptionValue={(option) => option._id}
                                // options={allasset}
                                // value={assetData}
                                // onChange={handleChange}
                                isSearchable={true}
                                // isMulti
                                isClearable
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="mb-2">
                            <Select
                                className="dropdown"
                                placeholder="Select Inspection Activity"
                                // getOptionLabel={(option) => option.asset_id || option.device_id || option.facility_id}
                                // getOptionValue={(option) => option._id}
                                // options={allasset}
                                // value={selectedOptions}
                                // onChange={handleChange}
                                isSearchable={true}
                                // isMulti
                                isClearable
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="mb-2">
                            <Select
                                className="dropdown"
                                placeholder="Select Maintenance Activity"
                                // getOptionLabel={(option) => option.asset_id || option.device_id || option.facility_id}
                                // getOptionValue={(option) => option._id}
                                // options={allasset}
                                // value={selectedOptions}
                                // onChange={handleChange}
                                isSearchable={true}
                                // isMulti
                                isClearable
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="mb-2">
                            <Select
                                className="dropdown"
                                placeholder="Select Checkpoint"
                                // getOptionLabel={(option) => option.asset_id || option.device_id || option.facility_id}
                                // getOptionValue={(option) => option._id}
                                // options={allasset}
                                // value={selectedOptions}
                                // onChange={handleChange}
                                isSearchable={true}
                                // isMulti
                                isClearable
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="mb-2 col-md-3" >
                    <Select
                        className="chartType"
                        placeholder="Select Chart Type"
                        // getOptionLabel={(option) => option.asset_id || option.device_id || option.facility_id}
                        // getOptionValue={(option) => option._id}
                        options={[
                            { value: 'column', label: 'Column' },
                            { value: 'bar', label: 'Bar' },
                            { value: 'line', label: 'Line' },
                            { value: 'area', label: 'Area' }
                        ]}
                        value={{ value: chartType.value, label: chartType.label }}
                        onChange={handleChartTypeChange}
                        isSearchable={true}
                        // isMulti
                        isClearable
                    />
                    <br />
                    <br />
                </div>
            </div>
            <TrendChart
                chartType={chartType}
                handleChartTypeChange={handleChartTypeChange}
                data={data}
            />
        </div>
    )
}

export default Trend
