//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'

function AirCompressorMonitor({ responseData, filterData }) {

    const [resData, setResData] = useState([])

    // header details
    const data1 = {
        data: "MAINTENANCE",
        colSpan: '6'
    }
    const data2 = {
        data: "MAINT/035:F1-02"
    }
    const data3 = {
        data: `AIR COMPRESSOR MONITORING RECORD FOR THE MONTH OF ${filterData?.selectedStartDate !==''? filterData?.selectedStartDate :''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
        colSpan: '7'
    }
    const data4 = [
        {
            data: 'FREQUENCY : Shift I - 07:00±30 min, 10:00±30 min, 13:00±30 min \nShift II - 16:00±30 min, 19:00±30 min, 22:00±30 min \nShift III - 01:00±30 min, 04:00±30 min, 07:00±30 min',
            textAlign: 'right',
            colSpan: '7'
        },
    ]
    const data5 = [
        {
            data: `Asset ID : ${resData?.parent?.parent_id!==undefined ? resData?.parent?.parent_id:''}`,
            textAlign: 'center',
            colSpan: '7'
        },
    ]

    const tableHeaders = [
        { data: 'Date', textAlign: 'center' },
        { data: 'Starting time', textAlign: 'center' },
        { data: 'Recording time', textAlign: 'center' },
        { data: 'Loading pump Pressure (NLT 6 kg/cm²)', textAlign: 'center' },
        { data: 'Un Loading pump Pressure (NMT 7 bars)', textAlign: 'center' },
        { data: 'Cabin Temperature (NMT 90° C)', textAlign: 'center' },
        { data: 'Stopping Time', textAlign: 'center' },
        { data: 'Done by', textAlign: 'center' },
        { data: 'Checked by', textAlign: 'center' },
    ]

    const tableResData = resData?.checklist?.map(item => ({
        data: {
            value: item?.start_date && item?.start_date!==undefined? moment(item?.start_date).local().format("DD/MM/YY"):'-',  // received email on 31st jan to maintain this date format.
            style: { textAlign: 'center' },
        },
        data1: {
            value: item?.checkpoints[0]?.answer!==''?item?.checkpoints[0]?.answer:'-',
            style: { textAlign: 'center' }, // Define inline style here
        },
        data2: {
            value: item?.checkpoints[1]?.answer!==''?item?.checkpoints[1]?.answer:'-',
            style: { textAlign: 'center' }, // Define inline style here
        },
        data3: {
            value: item?.checkpoints[2]?.answer!==''?item?.checkpoints[2]?.answer:'-',
            style: { textAlign: 'center' },
        },
        data4: {
            value: item?.checkpoints[3]?.answer!==''?item?.checkpoints[3]?.answer:'-',
            style: { textAlign: 'center' },
        },
        data5: {
            value: item?.checkpoints[4]?.answer!==''?item?.checkpoints[4]?.answer:'-',
            style: { textAlign: 'center' },
        },
        data6: {
            value: item?.checkpoints[5]?.answer!==''?item?.checkpoints[5]?.answer:'-',
            style: { textAlign: 'center' },
        },
        data7: {
            value: item?.performed_by?.length>0?item?.performed_by:'-',
            style: { textAlign: 'center' },
        },
        data8: {
            value: item?.ins_approval_id?.length>0?item?.ins_approval_id:'-',
            style: { textAlign: 'center' },
        },
    }));

    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}

            />
            <ReportTable
                headerData={data4}
            />
            <ReportTable
                headerData={data5}
            />
            <ReportTable
                headerData={tableHeaders}
            />
            <ReportTable
                verticalBodyData={tableResData}
            />
        </div>
    )
}

export default AirCompressorMonitor
