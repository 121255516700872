const SITE_GET_LIST = 'SITE_GET_LIST';
const SITE_GET_SUCCESS = 'SITE_GET_SUCCESS';
const SITE_GET_FAILED = 'SITE_GET_FAILED';

const SITE_DELETE_LIST = 'SITE_DELETE_LIST';
const SITE_DELETE_SUCCESS = 'SITE_DELETE_SUCCESS';
const SITE_DELETE_FAILED = 'SITE_DELETE_FAILED';

const SITE_ADD = 'SITE_ADD';
const SITE_ADD_SUCCESS = 'SITE_ADD_SUCCESS';
const SITE_ADD_FAILED = 'SITE_ADD_FAILED';

const SITE_CONTACT_DETAILS = 'SITE_CONTACT_DETAILS';
const SITE_CONTACT_DETAILS_SUCCESS = 'SITE_CONTACT_DETAILS_SUCCESS';
const SITE_CONTACT_DETAILS_FAILED = 'SITE_CONTACT_DETAILS_FAILED';

const SITE_CONTACT_GET = 'SITE_CONTACT_GET';
const SITE_CONTACT_GET_SUCCESS = 'SITE_CONTACT_GET_SUCCESS';
const SITE_CONTACT_GET_FAILED = 'SITE_CONTACT_GET_FAILED';

// site Bulk upload
const SITE_BULK_ADD = 'SITE_BULK_ADD';
const SITE_BULK_ADD_SUCCESS = 'SITE_BULK_ADD_SUCCESS';
const SITE_BULK_ADD_FAILED = 'SITE_BULK_ADD_FAILED';

const SITE_GET_SINGLE = 'SITE_GET_SINGLE';
const SITE_GET_SINGLE_SUCCESS = 'SITE_GET_SINGLE_SUCCESS'
const SITE_GET_SINGLE_FAILED = 'SITE_GET_SINGLE_FAILED';

// update individual site (changes in updated details)
const SITE_UPDATE = 'SITE_UPDATE';
const SITE_UPDATE_SUCCESS = 'SITE_UPDATE_SUCCESS';
const SITE_UPDATE_FAILED = 'SITE_UPDATE_FAILED';

const SITE_CONTACT_DELETE = "SITE_CONTACT_DELETE";
const SITE_CONTACT_DELETE_SUCCESS = "SITE_CONTACT_DELETE_SUCCESS";
const SITE_CONTACT_DELETE_FAILED = 'SITE_CONTACT_DELETE_FAILED';

export {
    SITE_GET_LIST,
    SITE_GET_SUCCESS,
    SITE_GET_FAILED,
    
    SITE_DELETE_LIST,
    SITE_DELETE_SUCCESS,
    SITE_DELETE_FAILED,

    SITE_ADD,
    SITE_ADD_SUCCESS,
    SITE_ADD_FAILED,

    SITE_CONTACT_DETAILS,
    SITE_CONTACT_DETAILS_SUCCESS,
    SITE_CONTACT_DETAILS_FAILED,

    SITE_CONTACT_GET,
    SITE_CONTACT_GET_SUCCESS,
    SITE_CONTACT_GET_FAILED,

    SITE_BULK_ADD,
    SITE_BULK_ADD_SUCCESS,
    SITE_BULK_ADD_FAILED,
    
    SITE_GET_SINGLE,
    SITE_GET_SINGLE_SUCCESS,
    SITE_GET_SINGLE_FAILED,

    SITE_UPDATE,
    SITE_UPDATE_SUCCESS,
    SITE_UPDATE_FAILED,

    SITE_CONTACT_DELETE_FAILED,
    SITE_CONTACT_DELETE_SUCCESS,
    SITE_CONTACT_DELETE,
}