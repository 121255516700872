import {
  MAINT_ADD,
  MAINT_ADD_SUCCESS,
  MAINT_ADD_FAILED,
  MAINT_GET_LIST,
  MAINT_GET_SUCCESS,
  MAINT_GET_FAILED,
  MAINT_DELETE_LIST,
  MAINT_DELETE_SUCCESS,
  MAINT_DELETE_FAILED,
  MAINTENANCE_REPORT,
  MAINTENANCE_REPORT_SUCCESS,
  MAINTENANCE_REPORT_FAILED,
  MAINT_GET_SINGLE,
  MAINT_GET_SINGLE_SUCCESS,
  MAINT_GET_SINGLE_FAILED,
  MAINTENANCE_UPDATE,
  MAINT_UPDATE_SUCCESS,
  MAINT_UPDATE_FAILED,
  MAINTENANCE_STATUS,
  MAINTENANCE_STATUS_SUCCESS,
  MAINTENANCE_STATUS_FAILED,
  SOP_GET,
  SOP_GET_SUCCESS,
  SOP_GET_FAILED
} from "../actions/maintAction";

const initialMaintenanceState = {
  maintenanceData: { result: [] },
  addMaintenanceData: [],
  isLoading: false,
  isSubmitted: false,
  maintReportData: null,
  maintGetSingle: null,
  maintUpdateData: {},
  maintenanceStatus: [],
  sop:null,
};

export default function MaintenanceReducer(
  state = initialMaintenanceState,
  action
) {
  switch (action.type) {
    case MAINT_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case MAINT_ADD_SUCCESS:
      return {
        ...state,
        addMaintenanceData: action.payload,
        isLoading: false,
        isSubmitted: true,
      };
    case MAINT_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case MAINT_GET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case MAINT_GET_SUCCESS:
      return {
        ...state,
        maintenanceData: action.payload.data,
        isLoading: false,
        isSubmitted: false,
      };
    case MAINT_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case MAINT_DELETE_LIST:
      return {
        ...state,
      };
    case MAINT_DELETE_SUCCESS:
      return {
        ...state,
      };
    case MAINT_DELETE_FAILED:
      return {
        ...state,
      };
    case MAINT_GET_SINGLE:
      return {
        ...state,
        isLoading: true,
      };
    case MAINT_GET_SINGLE_SUCCESS:
      return {
        ...state,
        maintGetSingle: action.payload.data,
        isLoading: false,
      };
    case MAINT_GET_SINGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case MAINTENANCE_UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case MAINT_UPDATE_SUCCESS:
      return {
        ...state,
        maintUpdateData: action.payload,
      };
    case MAINT_UPDATE_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case MAINTENANCE_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case MAINTENANCE_REPORT_SUCCESS:
      return {
        ...state,
        maintReportData: action.payload.data,
        isLoading: false,
      };
    case MAINTENANCE_REPORT_FAILED:
      return {
        ...state,
        maintReportData: null,
        isLoading: false,
      };
    case MAINTENANCE_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case MAINTENANCE_STATUS_SUCCESS:
      // console.log("insp Status",action.payload);
      return {
        ...state,
        maintenanceStatus: action.payload.data,
        isLoading: false,
        isSubmitted: false,
      };
    case MAINTENANCE_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SOP_GET:
      return {
        ...state,
      };
    case SOP_GET_SUCCESS:
      // console.log("SOP_GET_SUCCESS",action.payload);
      return {
        ...state,
        sop: action.payload.data,
      };
    case SOP_GET_FAILED:
      return {
        ...state,
      };
    case "CLEAR_MAINTENANCE_REDUCER":
      return {
        ...state,
        maintenanceData: {},
        addMaintenanceData: [],
        maintReportData: null,
        maintGetSingle: null,
        maintUpdateData: {},
        maintenanceStatus: [],
        sop:null,
      };
    default:
      return state;
  }
}
