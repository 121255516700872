import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'

const BackButton = () => {

    const handleBackMoment = () => {
        window.history.back();
    }

    return (
        <div
            onClick={handleBackMoment}
            style={{
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                backgroundColor: "aqua",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid grey",
                cursor: "pointer",
                position: "fixed",
                // position:"absolute",
                top: "60px",
                left: "20px",
                zIndex: "100",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"
                //box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
                // box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

            }}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
            {/* <span> Back</span> */}
        </div >
    )
}

export default BackButton