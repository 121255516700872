// action to create new user.
const USER_ADD = "USER_ADD";
const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
const USER_ADD_FAILED = "USER_ADD_FAILED";

// action to get all user details from API
const USER_GET_LIST = "USER_GET_LIST";
const USER_GET_SUCCESS = "USER_GET_SUCCESS";
const USER_GET_FAILED = "USER_GET_FAILED";

// action to get all user details from API
const USER_SET_PASSWORD = "USER_SET_PASSWORD";
const USER_SET_PASSWORD_SUCCESS = "USER_SET_PASSWORD_SUCCESS";
const USER_SET_PASSWORD_FAILED = "USER_SET_PASSWORD_FAILED";

// action to get all role code / role details from API
const ROLE_GET_LIST = "ROLE_GET_LIST";
const ROLE_GET_SUCCESS = "ROLE_GET_SUCCESS";
const ROLE_GET_FAILED = "ROLE_GET_FAILED";

// action to create new user through Bulk Upload.
const USER_BULK_ADD = "USER_BULK_ADD";
const USER_BULK_ADD_SUCCESS = "USER_BULK_ADD_SUCCESS";
const USER_BULK_ADD_FAILED = "USER_BULK_ADD_FAILED";

// action to delete user details.
const USER_DELETE_LIST = 'USER_DELETE_LIST';
const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
const USER_DELETE_FAILED = 'USER_DELETE_FAILED';

// action to delete user details.
const USER_GET_SINGLE = "USER_GET_SINGLE";
const USER_GET_SINGLE_SUCCESS = "USER_GET_SINGLE_SUCCESS";
const USER_GET_SINGLE_FAILED = "USER_GET_SINGLE_FAILED";

const GET_APPROV_USERS = "GET_APPROV_USERS";
const GET_APPROV_USERS_SUCCESS = "GET_APPROV_USERS_SUCCESS";
const GET_APPROV_USERS_FAILED = "GET_APPROV_USERS_FAILED";

const USER_GET_TECH = "USER_GET_TECH";
const USER_GET_TECH_SUCESS = "USER_GET_TECH_SUCESS";
const USER_GET_TECH_FAILED = "USER_GET_TECH_FAILED";

const USER_GET_APPROVER = "USER_GET_APPROVER";
const USER_GET_APPROVER_SUCESS = "USER_GET_APPROVER_SUCESS";
const USER_GET_APPROVER_FAILED = "USER_GET_APPROVER_FAILED";



export {
  USER_ADD,
  USER_ADD_SUCCESS,
  USER_ADD_FAILED,
  USER_GET_LIST,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_SET_PASSWORD,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD_FAILED,
  ROLE_GET_LIST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILED,
  USER_BULK_ADD,
  USER_BULK_ADD_SUCCESS,
  USER_BULK_ADD_FAILED,
  USER_DELETE_LIST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_GET_SINGLE,
  USER_GET_SINGLE_SUCCESS,
  USER_GET_SINGLE_FAILED,
  GET_APPROV_USERS,
  GET_APPROV_USERS_SUCCESS,
  GET_APPROV_USERS_FAILED,
  USER_GET_TECH,
  USER_GET_TECH_SUCESS,
  USER_GET_TECH_FAILED,
  USER_GET_APPROVER,
  USER_GET_APPROVER_SUCESS,
  USER_GET_APPROVER_FAILED
};
