import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

// adding user through UI
function getCWIPData(data) {
    // console.log('call start for CWIP');
    return serviceBase.get(CONSTANTS.GET_CWIP + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);
}

function addCapitalise(data) {
    // console.log('data of capitalaise', data);
    return serviceBase.post(CONSTANTS.ADD_CAPITALISE, data);
}

function addExpense(data) {
    // console.log('data of expense', data);
    return serviceBase.post(CONSTANTS.ADD_EXPENSE, data);
}
function getExpenseData(data) {
    // console.log('call start for Expense');
    return serviceBase.get(CONSTANTS.GET_EXPENSE + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);
}
function getCapitalData(data) {
    // console.log('call start for Capital');
    return serviceBase.get(CONSTANTS.GET_CAPITAL + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);
}

function addLocation(data) {
    // console.log('data of Location', data);
    return serviceBase.post(CONSTANTS.ADD_LOCATION, data);
}
function getLocationData(data) {
    // console.log('data of Location', data);
    return serviceBase.get(CONSTANTS.GET_LOCATION + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);
}
function addClass(data) {
    // console.log('data of Center', data);
    return serviceBase.post(CONSTANTS.ADD_CLASS, data);
}

// function getCenterData(data) {
function getClassData(data) {
    // console.log('data of center', data);
    return serviceBase.get(CONSTANTS.GET_CLASS + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);
}

function getCalculationData(data) {
    // console.log('data of Calculation', data);
    if (data[1] == "SLM") {
        return serviceBase.get(CONSTANTS.GET_CALCULATION + `?fy=${data[0]}` + `&location=${data[2]}` + `&buCode=${data[3]}` + `&costCenter=${data[4]}`);
    } else {
        return serviceBase.get(CONSTANTS.GET_CALCULATION_WDV + `?fy=${data[0]}` + `&location=${data[2]}` + `&buCode=${data[3]}` + `&costCenter=${data[4]}`);
    }
}

function addFAAsset(data) {
    // console.log('data of Center', data);
    return serviceBase.post(CONSTANTS.ADD_ASSET, data);
}
function addBulkFAAsset(data) {
    // console.log('data of Center', data);
    if (data[1] == "new") {
        return serviceBase.post(CONSTANTS.ADD_BULK_ASSET, data[0]);
    } else {
        return serviceBase.post(CONSTANTS.ADD_EXISTING_ASSET, data[0]);
    }
}

function addDispose(data) {
    // console.log('data of expense', data);
    return serviceBase.post(CONSTANTS.ADD_EXPENSE, data);
}

function updateAsset(data) {
    // console.log('Data of transfer of asset', data);
    return serviceBase.patch(CONSTANTS.FAASSET_UPDATE, data);
}

function addCostCenter(data) {
    // console.log('data of Center', data);
    return serviceBase.post(CONSTANTS.ADD_COST_CENTER, data);
}
function getCostCenterData(data) {
    // console.log('data of center', data);
    return serviceBase.get(CONSTANTS.GET_COST_CENTER + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);
}

function getBuCodeData() {
    return serviceBase.get(CONSTANTS.GET_BU_CODE);
}

function getDashboardData() {
    return serviceBase.get(CONSTANTS.GET_FA_DASHBOARD);
}

function getAddDeleteAssetData() {
    return serviceBase.get(CONSTANTS.GET_ADDITION_DELETION);
}

function getDepByCategoryData() {
    return serviceBase.get(CONSTANTS.GET_DEP_BY_CATEGORY);
}

function saleAsset(data) {
    // console.log("1")
    return serviceBase.post(CONSTANTS.SALE_ASSET, data);
}

function getSampleData(data, currentPage, itemsPerPage) {
    return serviceBase.post(CONSTANTS.GET_SAMPLE_DATA + `?page=${currentPage}&limit=${itemsPerPage}`, data);
}

function getVerifiedData(data) {
    // console.log(data);
    return serviceBase.get(CONSTANTS.GET_VERIFIED_LIST + `?page=${data[0]}&limit=${data[1]}&type=${data[2]}`);
}

function getReportData(id) {
    // console.log(id);
    return serviceBase.get(CONSTANTS.GET_REPORT_DATA + id);
}

function sampleList(data) {
    // console.log("1")
    return serviceBase.post(CONSTANTS.ADD_SAMPLE_LIST, data);
}

function getSampleRunData(data) {
    // console.log(data);
    return serviceBase.post(CONSTANTS.GET_SAMPLE_RUN_DETAILS, data);
}

function getSampleListData(id) {
    // console.log(id);
    return serviceBase.get(CONSTANTS.GET_SAMPLES + id);
}

function getVerificationCount() {
    return serviceBase.get(CONSTANTS.GET_VERIFICATION_COUNT);
}

function getVerificationData(data) {
    return serviceBase.get(CONSTANTS.GET_VERIFICATION_DATA);
}

export const faDetails = {
    getCWIPData,
    addCapitalise,
    addExpense,
    getExpenseData,
    getCapitalData,
    addLocation,
    getLocationData,
    addClass,
    getClassData,
    getCalculationData,
    addFAAsset,
    addBulkFAAsset,
    addDispose,
    updateAsset,
    addCostCenter,
    getCostCenterData,
    getBuCodeData,
    getDashboardData,
    getAddDeleteAssetData,
    getDepByCategoryData,
    saleAsset,
    getSampleData,
    getVerifiedData,
    getReportData,
    sampleList,
    getSampleRunData,
    getSampleListData,
    getVerificationCount,
    getVerificationData
};