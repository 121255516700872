import React from 'react'

function ReportFooter({dataRow1, dataRow2, pageWidth}) {
  return (
    <div>
      <table className="table" style={{ margin: "auto", width: pageWidth || 1200, border: '1px solid black', tableLayout: 'fixed' }}>
        <thead style={{ width: '100%'}}>
        {dataRow1 && 
          <tr>
            <td>
            {dataRow1?.data}  
            </td>
          </tr>
          }
          {dataRow2 && 
          <tr>
            <td>
            {dataRow2?.data}  
            </td>
          </tr>
          }
        </thead>
      </table>
    </div>
  )
}

export default ReportFooter
