/* action to get all asset/device/facility - history data for Inspection and Maintenance
 Created by - Janaki J
 Updated by - Janaki J */

const HISTRY_GET_LIST = "HISTRY_GET_LIST";
const HISTRY_GET_SUCCESS = "HISTRY_GET_SUCCESS";
const HISTRY_GET_FAILED = "HISTRY_GET_FAILED";

export {
    HISTRY_GET_LIST,
    HISTRY_GET_SUCCESS,
    HISTRY_GET_FAILED
};
