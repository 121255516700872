//Feature - Form to upload/onboard Site

//Created by - Mona R
//Updated by - Mona R

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SITE_ADD } from "../../redux/actions/siteAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";
import ContactUpload from "./contactUpload";
import BackButton from "../../components/BackButton/BackButton";

//Function to upload single site
function SiteUpload() {
  const navigate = useNavigate();

  // to fetch the redux data
  const Siteid = useSelector((state) => state.site.contactId);
  const userid = useSelector((state) => state.auth.userid);
  const custData = useSelector((state) => state.customer.custData);
  const isLoading = useSelector((state) => state.site.isLoading);
  const [modalShow, setModalShow] = useState(false);

  // console.log("Siteid", Siteid);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    sitename: "",
    swebsite: "",
    saddress: "",
    sregion: "",
    scity: "",
    scountry: "",
    sstate: "",
    szcode: "",
    sgeo: "",
    scriticality: "",
    id: "",
  });
  //Functions to handle form inputs
  const handleSitename = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        sitename: val,
      });
    } else {
      setData({
        ...data,
        sitename: val,
      });
    }
  };
  const handleWebsite = (val) => {
    setData({
      ...data,
      swebsite: val,
    });
  };
  const handleAddress = (val) => {
    setData({
      ...data,
      saddress: val,
    });
  };
  const handleRegion = (val) => {
    setData({
      ...data,
      sregion: val,
    });
  };
  const handleCity = (val) => {
    setData({
      ...data,
      scity: val,
    });
  };
  const handleCountry = (val) => {
    setData({
      ...data,
      scountry: val,
    });
  };
  const handleState = (val) => {
    setData({
      ...data,
      sstate: val,
    });
  };
  const handleZipCode = (val) => {
    setData({
      ...data,
      szcode: val,
    });
  };

  const handleGeo = (val) => {
    setData({
      ...data,
      sgeo: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      scriticality: val,
    });
  };
  const handleCustomerData = (string) => {
    let array = string.split(",");
    data.id = array[0];
    if (string.length < 1) {
      setData({
        ...data,
        isValidCustomername: true,
      });
    } else {
      setData({
        ...data,
        isValidCustomername: false,
      });
    }
  };
  const getCustDetails = () => {
    dispatch({
      type: "CUST_GET_LIST",
    });
  };

  //functions for validation
  function containSpeclChars(str) {
    const specialChars = /^([a-zA-Z0-9-#@']+\s)*[a-zA-Z0-9-#@']+$/;
    return specialChars.test(str);
  }

  function websiteChars(str) {
    const webChars =
      /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=-]*)$/;
    return webChars.test(str);
  }
  function regionChars(str) {
    const placeChars = /^([a-zA-Z0-9-@']+\s)*[a-zA-Z0-9-@']+$/;
    return placeChars.test(str);
  }

  function zcodeChars(str) {
    const zipChars = /^[1-9][0-9]{5}$/;
    return zipChars.test(str);
  }

  //client-side validation
  const [sitenameErr, setSiteNameErr] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [regionErr, setRegionErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [zcodeErr, setZcodeErr] = useState("");
  const [custnameErr, setCustNameErr] = useState("");

  let isValid = true;
  const formValidation = () => {
    const sitenameErr = {};
    const websiteErr = {};
    const addressErr = {};
    const regionErr = {};
    const cityErr = {};
    const countryErr = {};
    const stateErr = {};
    const zcodeErr = {};
    const custnameErr = {};

    if (data.sitename.trim().length < 1) {
      sitenameErr.sitenameShort = "Please Enter Site Name";
      isValid = false;
    }
    setSiteNameErr(sitenameErr);

    if (data.swebsite.trim().length < 1) {
      websiteErr.swebsiteShort = "Please Enter website";
      isValid = false;
    } else {
    }
    setWebsiteErr(websiteErr);

    if (data.saddress.trim().length < 1) {
      addressErr.saddressShort = "Please Enter Address";
      isValid = false;
    }
    setAddressErr(addressErr);

    if (data.sregion.trim().length < 1) {
      regionErr.sregionShort = "Please Enter Region";
      isValid = false;
    }
    setRegionErr(regionErr);

    if (data.scity.trim().length < 1) {
      cityErr.scityShort = "Please Enter City";
      isValid = false;
    }
    setCityErr(cityErr);

    if (data.scountry.trim().length < 1) {
      countryErr.scountryShort = "Please Enter Country";
      isValid = false;
    }
    setCountryErr(countryErr);

    if (data.sstate.trim().length < 1) {
      stateErr.stateShort = "Please Enter State";
      isValid = false;
    }
    setStateErr(stateErr);

    if (data.szcode.trim().length != 6) {
      zcodeErr.zcodeShort = "Please Enter Valid Zipcode";
      isValid = false;
    }
    setZcodeErr(zcodeErr);

    if (data.id.trim().length < 1) {
      custnameErr.custNameShort = "Please Select Customer Name";
      isValid = false;
    }
    setCustNameErr(custnameErr);
    return isValid;
  };
  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  //Function to submit site details
  const submitSite = (e) => {
    e.preventDefault();
    let siteData = {
      cust_id: data.id,
      site_name: data.sitename,
      website: data.swebsite,
      address: data.saddress,
      region: data.sregion,
      city: data.scity,
      country: data.scountry,
      state: data.sstate,
      zip_code: data.szcode,
      geo_address: data.sgeo,
      site_criticality_rating: data.scriticality,
      created_by: userid,
    };
    validation();
    // console.log("isValid", isValid);
    if (isValid) {
      // console.log(siteData);
      dispatch({
        type: SITE_ADD,
        payload: siteData,
      });
    }
  };
  //useEffects
  useEffect(() => {
    getCustDetails();
  }, [data]);
  useEffect(() => {
    if (data.sitename !== "" &&
      data.swebsite !== "" &&
      data.saddress !== "" &&
      data.sregion !== "" &&
      data.scity !== "" &&
      data.scountry !== "" &&
      data.sstate !== "" &&
      data.szcode !== "" &&
      Siteid != null && Siteid !== undefined) {
      // navigate("/sitecontact/" + Siteid);
      setModalShow(true);
    }
  }, [Siteid]);

  useEffect(() => { }, [Siteid]);
  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to show the Loading data */}
      <BackButton />
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div style={{ paddingTop: "45px" }}>
              <form
                action=""
                className="mt-5 border p-4 bg-light shadow rounded"
              >
                <div className="formHeadings mb-2">Create Site</div>
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <label>
                      Site Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="sitename"
                      name="sitename"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleSitename(e.target.value)}
                    />
                    {Object.keys(sitenameErr).map((key) => {
                      if (!data.sitename) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {sitenameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {containSpeclChars(data.sitename) || data.sitename === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Site name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Customer Name<span className="text-danger">*</span>
                    </label>
                    <select
                      id="custname"
                      className="form-select"
                      onChange={(e) => {
                        handleCustomerData(e.target.value);
                        // getCustDetails();
                      }}
                    >
                      <option value=" " id="">
                        Select
                      </option>
                      {custData?.map((eachData) => {
                        return (
                          <option
                            value={[eachData._id, eachData.legal_name]}
                            key={eachData._id}
                          >
                            {eachData.legal_name}
                          </option>
                        );
                      })}
                    </select>
                    {Object.keys(custnameErr).map((key) => {
                      if (!data.id) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {custnameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Website<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="swebsite"
                      className="form-control"
                      onChange={(e) => handleWebsite(e.target.value)}
                      placeholder="www.abc.com"
                    />
                    {Object.keys(websiteErr).map((key) => {
                      if (!data.swebsite) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {websiteErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {websiteChars(data.swebsite) || data.swebsite === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >Invalid Website</div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="saddress"
                      className="form-control"
                      onChange={(e) => handleAddress(e.target.value)}
                    />
                    {Object.keys(addressErr).map((key) => {
                      if (!data.saddress) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {addressErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Region<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="sregion"
                      className="form-control"
                      onChange={(e) => handleRegion(e.target.value)}
                    />
                    {Object.keys(regionErr).map((key) => {
                      if (!data.sregion) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {regionErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.sregion) || data.sregion === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Region name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      City<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="scity"
                      className="form-control"
                      onChange={(e) => handleCity(e.target.value)}
                    />
                    {Object.keys(cityErr).map((key) => {
                      if (!data.scity) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {cityErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.scity) || data.scity === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid City name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Country<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="scountry"
                      className="form-control"
                      onChange={(e) => handleCountry(e.target.value)}
                    />
                    {Object.keys(countryErr).map((key) => {
                      if (!data.scountry) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {countryErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.scountry) || data.scountry === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Country name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      State<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="sstate"
                      className="form-control"
                      onChange={(e) => handleState(e.target.value)}
                    />
                    {Object.keys(stateErr).map((key) => {
                      if (!data.sstate) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {stateErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.sstate) || data.sstate === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid State name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Zipcode<span className="text-danger">*</span>
                    </label>
                    <input
                      // type="number"
                      // min="0"
                      // name="szcode"
                      className="form-control"
                      maxLength={6}
                      minLength={6}
                      pattern="[0-9]{6,}"
                      placeholder="Eg. 5600XX"
                      onChange={(e) => handleZipCode(e.target.value)}
                    />
                    {Object.keys(zcodeErr).map((key) => {
                      if (!data.szcode) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {zcodeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {zcodeChars(data.szcode) || data.szcode === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Zipcode
                          </div>
                        ))}
                  </div>
                  {/* <div className="mb-3 col-md-6">
                    <label>Geo Address</label>
                    <input
                      type="text"
                      name="sgeo"
                      className="form-control"
                      onChange={(e) => handleGeo(e.target.value)}
                    />
                  </div> */}
                  <div className="mb-3 col-md-6">
                    <label htmlFor="scriticality">Criticality Rating</label>
                    <select
                      name="scriticality"
                      id="scriticality"
                      className="form-select"
                      onChange={(e) => handleCriticality(e.target.value)}
                      placeholder="Choose any one"
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>

                  <div className="mb-mt-2 col-md-12">
                    <button
                      type="submit"
                      className="float-end saveButton"
                      onClick={(e) => submitSite(e)}
                    >
                      Save
                    </button>
                    <button
                      onClick={() => navigate("/sitelist")}
                      className="backButton"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {modalShow && (
            <ContactUpload
              show={modalShow}
              onHide={() => setModalShow(false)}
              id={Siteid}
            />
          )}
        </div>
      </div>
    </div>
  );
}

//exporting component
export default SiteUpload;
