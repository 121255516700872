import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { Button, Form } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { DataDep } from '../../components/FA-Components/DataExcel';
import Commonheader from '../../layouts/common/commonHeader';
import Loader from '../../components/loader/loader';
import BackButton from '../../components/BackButton/BackButton';

const DepReport = () => {

    const [excelData, setExcelData] = useState(null);
    const [excelData1, setExcelData1] = useState(null);
    const [list, setList] = useState();
    const [method, setMethod] = useState()
    const [isNew, setIsNew] = useState("all")
    const [fy, setFy] = useState()
    const [locationselect, setLocationSelect] = useState("all")
    const [buCode, setBuCode] = useState("all")
    const [costCenter, setCostCenter] = useState("all")



    // const [list1, setList1] = useState();
    const dispatch = useDispatch()
    const list1 = useSelector((state) => state.FA.calculation)
    const isLoading = useSelector((state) => state.FA.isLoading)
    const buCode1 = useSelector((state) => state.FA.buCode)
    const center = useSelector((state) => state.FA.costCenter)
    const location = useSelector((state) => state.FA.location)
    const [showTables, setShowTables] = useState(false)


    // console.log("list1", list1);


    // all filter code

    const handleMethod = (e) => {
        // console.log("mathod", e.target.value)
        setMethod(e.target.value)
    }

    let numberOfAssets = list1?.length || 0
    let total_cost = 0;
    let totalNetBlock = 0
    for (let i = 0; i < list1?.length; i++) {
        total_cost += list1[i]?.total_cost
        totalNetBlock += list1[i]?.net_block
    }
    // console.log(total_cost)

    const handleYear = (e) => {
        // console.log(e.target.value)
        setIsNew(e.target.value)
        if (e.target.value == "prev") {
            setFy(new Date().getFullYear() - 1)
        } else {
            setFy(new Date().getFullYear())
        }
    }

    const yearOptions = [];
    for (let year = 2010; year <= 2050; year++) {
        yearOptions.push({ value: year - 1, lable: `${year - 1}-${year.toString().slice(2, 4)}` });
    }

    const getData = async () => {

        if (window.confirm(`Depreciation Method: ${method} 
        Financial year: ${Number(fy) + 1}
                `)) {

            if (!fy || !method) {
                alert("Please select year and Method")
            } else {
                if (method == 'SLM') {
                    // var { data } = await axios.get(`http://localhost:8000/calculation?fy=${fy}`);

                    dispatch({
                        type: "GET_CALCULATION",
                        payload: [fy, "SLM", locationselect, buCode, costCenter]
                    })

                } else {
                    // var { data } = await axios.get(`http://localhost:8000/calculation-wdv?fy=${fy}`);
                    dispatch({
                        type: "GET_CALCULATION",
                        payload: [fy, "WDV", locationselect, buCode, costCenter]
                    })
                }
                // setList(data)
                // setExcelData(data)
                // console.log(data)
                setShowTables(true)

            }
        }

    };

    const getData1 = async () => {
        // const { data } = await axios.get(`http://localhost:4010/calculation-wdv`);
        dispatch({
            type: "GET_CALCULATION",
            payload: [2023, "WDV"]
        })
        // setList1(data)
        setExcelData1(list1)
        // console.log(data)
    };


    function generateExcelFile(data) {



        data.map((date) => { date.doi = moment(date?.doi).format('DD-MM-YYYY') })

        data.map((date) => { date.dop = moment(date?.dop).format('DD-MM-YYYY') })

        // data.map((date) => { date.invoice_date = moment(date?.invoice_date).format('DD-MM-YYYY') })


        // console.log(data);
        let customHeader = [[
            "BU Code",
            "GL Code",
            "Asset ID",
            "Asset Name",
            "Class Code",
            "Location Name",
            "Class Name",
            "Cost Center Name",
            "Nature of Asset",
            "Cost Center Code",
            "Supplier Name",
            "Location",
            "Invoice Number",
            "Invoice Value (INR)",
            "Other Cost (INR)",
            "Date of Purchase (dd-mm-yyyy)",
            "Date of Installation (dd-mm-yyyy)",
            // "Cost",
            "Salvage Value (INR)",
            "Useful Life in Months",
            "Quantity",
            "User",
            "Description",
            "Opening Gross Block (INR)",
            "Addition During Year (INR)",
            "Deletion During Year (INR)",
            "Gross Block (INR)",
            "Opening Depreciation (INR)",
            "Current Depreciation (INR)",
            "Accumulated Depreciation (INR)",
            "Net Block (INR)",
        ]]

        // const modifiedData = [customHeader, ...data];

        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();
        // console.log("worksheet", modifiedData);
        XLSX.utils.sheet_add_aoa(worksheet, customHeader);
        XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `ZV_Depreciation_${new Date().toDateString()}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    // useEffect(() => {
    //     getData()
    //     // getData1()
    // }, [])

    useEffect(() => {
        dispatch({
            type: "GET_CLASS_LIST",
            payload: [1, 50, ''],
        })
        dispatch({
            type: "GET_LOCATION_LIST",
            payload: [1, 50, ''],
        })
        dispatch({
            type: "GET_COST_CENTER_LIST",
            payload: [1, 50, '']
        })
        dispatch({
            type: "GET_BU_CODE_LIST",
        })
    }, [])



    return (
        <>
            {/* <Sidebar /> */}
            {isLoading && <Loader />}
            <Commonheader />
            <BackButton />

            <div className="col-md-12">
                {/* <div class="container" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}> */}
                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        // paddingLeft: "100px",
                    }}>
                    Report
                </div>

                <div class="container bg-white" >

                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Depreciation Method :</label>
                        <Form.Select onChange={handleMethod} style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm">
                            <option>Select method</option>
                            <option>SLM</option>
                            {/* <option>WDV</option> */}
                        </Form.Select>
                    </div>

                    <div class="col-md-6 bg-white" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "2rem" }}>
                        <div>
                            <label for="assetId" class="form-label bg-white">Select Financial Year :</label>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ display: "flex" }}>
                                <div class="form-check" >
                                    <input onChange={handleYear} class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="prev" />
                                    <label class="form-check-label" for="inlineRadio1">Prev Year({new Date().getFullYear() - 1}-{(new Date().getFullYear()).toString().slice(2, 4)})</label>
                                </div>

                                <div class="form-check">
                                    <input onChange={handleYear} class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="current" />
                                    <label class="form-check-label" for="inlineRadio2">Cur Year({new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(2, 4)})</label>
                                </div>
                            </div>


                            <div >
                                {isNew == "all" && <select style={{ width: "100%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} type='number' placeholder='Ex: 2023' onChange={(e) => setFy(e.target.value)} >
                                    <option value="-">Select Year</option>
                                    {yearOptions?.map((el) => <option value={el.value}>{el.lable}</option>)}
                                </select>}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select Location :</label>
                        <select style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} onChange={(e) => setLocationSelect(e.target.value)} >
                            <option value="all"> All</option>
                            {location?.result && location?.result.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                        </select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select BU Code :</label>
                        <select style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} onChange={(e) => setBuCode(e.target.value)} >
                            <option value="all"> All</option>
                            {buCode1 && buCode1.map((el) => <option value={el}>{el}</option>)}
                        </select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select Cost Center :</label>
                        <select style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} onChange={(e) => setCostCenter(e.target.value)} >
                            <option value="all"> All</option>
                            {center?.result && center?.result.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                        </select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", paddingRight: "2rem", marginBottom: "0.5rem", justifyContent: "right", }}>
                        <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => getData()}>Generate Report</Button>
                    </div>
                </div>

                {/* </div> */}
                {showTables &&
                    <Button
                        type="submit"

                        style={{
                            margin: "2rem",
                            backgroundColor: "#69fae1",
                            color: "#000",
                            fontWeight: "bold"
                        }}
                        onClick={() => generateExcelFile(list1)
                        }>
                        Download Report
                    </Button>
                }

                {showTables &&
                    <div className="mb-6" style={{ overflow: "scroll", minHeight: "400px", width: "100%", margin: "auto" }}>
                        {/* <p style={{ fontSize: "16px" }}>Straight Line Depreciation (company act)</p> */}
                        <table className="table-bordered" >
                            <thead >
                                <tr style={{
                                    textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                }}>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>S. No</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>BU Code</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}> GL Code</th>
                                    <th scope="col" style={{ padding: "8px", textAlign: "left", width: "auto" }}>Asset ID</th>
                                    <th scope="col" style={{ padding: "0px 10px", textAlign: "left", width: "auto" }}>Asset Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}> Nature of Assset</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Class Code</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}> Class Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Cost Center</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Cost Center Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Location</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Location Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Supplier Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Invoice Number</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Date of Purchase</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Date of Installation</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Invoice Value (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Other Cost (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Salvage Value (INR)</th>
                                    {method && method !== "SLM" && < th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Rate</th>}
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Life in Months</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Quantity</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>User</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Description</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Opening Gross Block (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Addition During Year (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Deletion During Year (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Gross Block (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Opening Depreciation (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Current Depreciation (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Accumulated Depreciation (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Net Block (INR)</th>
                                </tr>
                            </thead>
                            <tbody >
                                {list1 && <DataDep excelData={list1} />}
                            </tbody>
                        </table>
                    </div>
                }

                <br />
                {/* <p style={{ fontSize: "16px", marginTop: "2rem", fontWeight: "bolder" }}>WDV Depreciation (IT act)</p>
                    <div className='table-responsive' style={{ border: "1px solid", overflow: "scroll", minHeight: "400px" }}>
                        <table className='table table-bordered' style={{ textAlign: "center", fontSize: "13px", border: "1px solid", }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Asset ID</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Asset Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}> Nature of asset</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Cost Center</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Supplier Name</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Location</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Invoice No</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Date Of Purchase</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Date Of Installation</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Cost (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Salvage Value (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Life in Months</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Quantity</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>User</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Description</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Total Cost (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Deletion During Year (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Gross Block (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Opening Depreciation (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Total Depreciation (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Accumulated Depreciation (INR)</th>
                                    <th scope="col" style={{ padding: "4px", textAlign: "left" }}>Net Block (INR)</th>
                                </tr>
                            </thead>
                            <tbody >
                                {excelData1 && <DataDep excelData={excelData1} />}
                            </tbody>
                        </table>
                    </div>
                    <Button style={{ marginTop: "2rem" }} onClick={() => generateExcelFile(excelData)}>Download Report</Button> */}
            </div>

        </>
    )
}

export default DepReport