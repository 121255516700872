/* Feature - Bulkupload of user

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

function Bulkupload(props) {
  // To fetch details from redux
  const dispatch = useDispatch();

  // to disable button based on file input //
  const [disabled, setDisabled] = useState(true);

  // to disable button based on empty file input //
  const [disEmptyArray, setDisEmptyArray] = useState(false);

  // to disable button based on selection of wrong worksheet name //
  const [wsdisabled, setWSDisabled] = useState(false);

  const userid = useSelector((state) => state.auth.userid);

  const subscriber_id = useSelector((state) => state.auth.subscriber_id);

  
  const [items, setItems] = useState({
    user: '',
    created_by: userid,
  });


  //    console.log(props.show);

  // Read the excel sheet data and convert it to JSON file using following code

  const readExcel = (file) => {
    // console.log(file);

    // to handle button
    if (file.length === 0) {
      setDisabled(true);
    }

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" }); // wb - workbook

        const wsname = wb.SheetNames; // wsname - worksheet name

        const ws_user_upload = wb.Sheets["UserCreation"]; // "UserCreation" name is the name directly taken from excel workbook sheet name.

        const user_header = [
          [
            "SrNo",
            "first_name",
            "middle_name",
            "last_name",
            "email",
            'country_code',
            "mobile_number",
            "department",
            "designation",
            "role",
            "created_by",
          ],
        ]; // map header from backend

        const user_upload_data = XLSX.utils.sheet_to_json(ws_user_upload, {
          defval: null,
        });

        const CreateWStoMearge = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMearge, user_header);

        const MeargehederDataUser = XLSX.utils.sheet_add_json(
          CreateWStoMearge,
          user_upload_data,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        const MeagedDataUser = XLSX.utils.sheet_to_json(MeargehederDataUser, {
          defval: null,
        });

        //////////////////////////// client side validation - sheet name ///////////////////////////////////////

        if (wsname.includes("UserCreation")) {
        } else {
          setWSDisabled(true);
          result = "error";
          alert(
            `Please verify the uploaded sheet. \n It doesn't includes workbook: \n 1. UserCreation - work book with the name - "UserCreation"  \n \n\n Note: Either you may have changed the work book name or you are not uploading correct format`
          );
        }

        //////////////////////////// add role code & delete the sr.no  ///////////////////////////////////////

        MeagedDataUser.forEach(function (obj) {
          delete obj.SrNo;
          // console.log(obj);
        });

        MeagedDataUser.map((eachdata) => {
          if (eachdata.role === 'Admin') {
            eachdata.role = 1001;
          }
          if (eachdata.role === 'Senior Executive') {
            eachdata.role = 1002;
          }
          if (eachdata.role === 'Head of Department') {
            eachdata.role = 1003
          }
          if (eachdata.role === 'Manager') {
            eachdata.role = 1004
          }
          if (eachdata.role === 'Engineer') {
            eachdata.role = 1005
          }
          if (eachdata.role === 'Supervisor') {
            eachdata.role = 1006
          }
          if (eachdata.role === 'Technician') {
            eachdata.role = 1007
          }
        });

        //////////////////////////// client side validation -  ///////////////////////////////////////
        const regex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        var result = "";

        MeagedDataUser.map((eachdata) => {
          // console.log(eachdata);
          if (eachdata.first_name == null) {
            var first_name_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.last_name == null) {
            var last_name_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.email == null) {
            var email_index = MeagedDataUser.indexOf(eachdata);
          }
          if ((!(eachdata.email) || regex.test(eachdata.email) === false)) {
            var valid_email_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.country_code === null) {
            var country_code_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.mobile_number === null) {
            var null_mobile_number_index = MeagedDataUser.indexOf(eachdata);
          }
          if ((eachdata.mobile_number != null) && (eachdata.mobile_number).length !== 10) {
            var mobile_number_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.department == null) {
            var department_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.designation == null) {
            var designation_index = MeagedDataUser.indexOf(eachdata);
          }
          if (eachdata.role == null) {
            var role_index = MeagedDataUser.indexOf(eachdata);
          }

          result += validateFirstName();
          result += validateLastName();
          result += validateemail();
          result += validateCountryCode();
          result += validateMobileNumber();
          result += validateDepartment();
          result += validateDesignation();
          result += validateRole();

          function validateFirstName() {
            if (eachdata.first_name == null)
              return `Sl.No: ${first_name_index + 1} - First name is mandetory field.\n`;
            return "";
          }
          function validateLastName() {
            if (eachdata.last_name == null)
              return `Sl.No: ${last_name_index + 1} - Last name is mandetory field.\n`;
            return "";
          }
          function validateemail() {
            if (eachdata.email == null)
              return `Sl.No: ${email_index + 1} - Email ID is mandetory field.\n`;
            if ((!(eachdata.email) || regex.test(eachdata.email) === false))
              return `Sl.No: ${valid_email_index + 1} - Please Enter Valid Email ID. \n`;
            return "";
          }
          function validateCountryCode() {
            if (eachdata.country_code === null)
              return `Sl.No: ${
                country_code_index + 1
              } - Country Code is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateMobileNumber() {
            if ((eachdata.mobile_number != null) && ((eachdata.mobile_number).toString().length !== 10))
              return `Sl.No: ${mobile_number_index + 1} - Enter valid mobile number.\n`;
            if (eachdata.mobile_number === null)
              return `Sl.No: ${null_mobile_number_index + 1} - Mobile number is mandetory field.\n`;
            return "";
          }
          function validateDepartment() {
            if (eachdata.department == null)
              return `Sl.No: ${department_index + 1} - Department is mandetory field.\n`;
            return "";
          }
          function validateDesignation() {
            if (eachdata.designation == null)
              return `Sl.No: ${designation_index + 1} - Designation is mandetory field.\n`;
            return "";
          }
          function validateRole() {
            if (eachdata.role == null)
              return `Sl.No: ${role_index + 1} - Role is mandetory field.\n`;
            return "";
          }
        });
        resolve(MeagedDataUser);

        setItems({
          ...items,
          user: MeagedDataUser,
        });


        // console.log('MeagedDataUser', MeagedDataUser);
        if (MeagedDataUser.length === 0) {
          alert("Please enter atleast one User")
          setDisEmptyArray(true)
        }

        if (result == "") return setDisabled(false);
        // if (result == "") return true;
        alert(
          "Please resolve the following error in the attachment:\n\n" + result
        );
        return false;
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => { });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitUserData = [items.user];
    // console.log(submitUserData);
    dispatch({
      type: "USER_BULK_ADD",
      payload: [subscriber_id, submitUserData],
    });
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Upload of User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            />
          </div>
          <br />
          <br />
          <span>Download User Config Bulk Upload Template-
            <a href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_User_Config_Bulk_Upload_V1_0.xlsx`}>
              Click here
            </a>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} disabled={disabled || wsdisabled || disEmptyArray}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Bulkupload;
