//Feature - Form to upload/onboard Customer

//Created by - Mona R
//Updated by - Mona R

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_ADD } from "../../redux/actions/deviceAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";

//function to upload device
function DeviceUpload() {
  const { id } = useParams();

  //to fetch the data from redux
  const dispatch = useDispatch();
  const isSubmitted = useSelector((state) => state.device.isSubmitted);
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.device.isLoading);

  // page navigation
  const navigate = useNavigate();

  // cleint side form validation
  const [data, setData] = useState({
    site_id: id,
    deviceID: "",
    devicename: "",
    devicetype: "",
    devicemake: "",
    devicemodel: "",
    deviceserial: "",
    devicerange: "",
    deviceresolution: "",
    deviceaccuracy: "",
    devicerating: "",
    deviceuom: "",
    devicecriticality: "",
    devicelocation: "",
    devicemakeyear: "",
    deviceinstyear: "",
    devicelife: "",
    devicedepartment: "",
    deviceaccesbility: "",
    devicephoto: "",
    deviceremark: "",
  });

  //Functions to handle form inputs
  const handleDeviceID = (val) => {
    setData({
      ...data,
      deviceID: val,
    });
  };
  const handleDeviceName = (val) => {
    setData({
      ...data,
      devicename: val,
    });
  };
  const handleDeviceType = (val) => {
    setData({
      ...data,
      devicetype: val,
    });
  };
  const handleDeviceMake = (val) => {
    setData({
      ...data,
      devicemake: val,
    });
  };
  const handleDeviceModel = (val) => {
    setData({
      ...data,
      devicemodel: val,
    });
  };
  const handleDeviceSerial = (val) => {
    setData({
      ...data,
      deviceserial: val,
    });
  };
  const handleDevicerange = (val) => {
    setData({
      ...data,
      devicerange: val,
    });
  };
  const handleDeviceResolution = (val) => {
    setData({
      ...data,
      deviceresolution: val,
    });
  };
  const handleDeviceAccuracy = (val) => {
    setData({
      ...data,
      deviceaccuracy: val,
    });
  };
  const handleDeviceRating = (val) => {
    setData({
      ...data,
      devicerating: val,
    });
  };
  const handleDeviceUom = (val) => {
    setData({
      ...data,
      deviceuom: val,
    });
  };
  const handleDeviceCriticality = (val) => {
    setData({
      ...data,
      devicecriticality: val,
    });
  };
  const handleDeviceMakeYear = (val) => {
    setData({
      ...data,
      devicemakeyear: val,
    });
  };
  const handleDevicelocation = (val) => {
    setData({
      ...data,
      devicelocation: val,
    });
  };
  const handleDepartment = (val) => {
    setData({
      ...data,
      devicedepartment: val,
    });
  };
  const handleDeviceInstYear = (val) => {
    setData({
      ...data,
      deviceinstyear: val,
    });
  };
  const handleDeviceLife = (val) => {
    setData({
      ...data,
      devicelife: val,
    });
  };
  const handleDeviceAccesbility = (val) => {
    setData({
      ...data,
      deviceaccesbility: val,
    });
  };
  const handleDeviceImg = (input) => {
    let file = input.target.files[0];
    if (file && !file.type.match(/(jpeg|png|jpg)/)) {
      alert("Only JPEG, PNG, JPG files are allowed");
      input.target.value = "";
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64String = reader.result;

      setData({
        ...data,
        devicephoto: base64String,
      });
    };
  };
  const handleDeviceremark = (val) => {
    setData({
      ...data,
      deviceremark: val,
    });
  };

  //client-side validation
  const [deviceIDErr, setDeviceIDErr] = useState("");
  const [devicenameErr, setDeviceNameErr] = useState("");
  const [devicetypeErr, setDeviceTypeErr] = useState("");
  const [devicemakeErr, setDeviceMakeErr] = useState("");
  const [devicemodelErr, setDeviceModelErr] = useState("");
  const [devicerangeErr, setDevicerangeErr] = useState("");
  const [deviceresolutionErr, setDeviceResolutionErr] = useState("");
  const [deviceaccuracyErr, setDeviceAccuracyErr] = useState("");
  const [devicecriticalityErr, setDeviceCriticalityErr] = useState("");

  let isValid = true;
  const formValidation = () => {
    const deviceIDErr = {};
    const devicenameErr = {};
    const devicetypeErr = {};
    const devicemakeErr = {};
    const devicemodelErr = {};
    const devicerangeErr = {};
    const deviceresolutionErr = {};
    const deviceaccuracyErr = {};
    const devicecriticalityErr = {};

    if (data.deviceID.trim().length < 1) {
      deviceIDErr.deviceIDShort = "Please enter Device ID";
      isValid = false;
    }
    setDeviceIDErr(deviceIDErr);
    if (data.devicename.trim().length < 1) {
      devicenameErr.devicenameShort = "Please enter Device name";
      isValid = false;
    }
    setDeviceNameErr(devicenameErr);
    if (data.devicetype.trim().length < 1) {
      devicetypeErr.devicetypeShort = "Choose Device Type";
      isValid = false;
    }
    setDeviceTypeErr(devicetypeErr);
    if (data.devicemake.trim().length < 1) {
      devicemakeErr.devicemakeShort = "Please enter make";
      isValid = false;
    }
    setDeviceMakeErr(devicemakeErr);
    if (data.devicemodel.trim().length < 1) {
      devicemodelErr.devicemodelShort = "Please enter model";
      isValid = false;
    }
    setDeviceModelErr(devicemodelErr);

    if (data.devicerange.trim().length < 1) {
      devicerangeErr.devicerangeShort = "Please enter Range";
      isValid = false;
    }
    setDevicerangeErr(devicerangeErr);
    if (data.deviceresolution.trim().length < 1) {
      deviceresolutionErr.deviceresolutionShort = "Please enter Resolution ";
      isValid = false;
    }
    setDeviceResolutionErr(deviceresolutionErr);
    if (data.deviceaccuracy.trim().length < 1) {
      deviceaccuracyErr.deviceaccuracyShort = "Please enter Accuracy";
      isValid = false;
    }
    setDeviceAccuracyErr(deviceaccuracyErr);
    if (data.devicecriticality.trim().length < 1) {
      devicecriticalityErr.devicecriticalityShort = "Choose the Criticality";
      isValid = false;
    }
    setDeviceCriticalityErr(devicecriticalityErr);

    return isValid;
  };
  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };
  useEffect(() => {
    if (isSubmitted) {
      navigate("/devicelist");
    }
  }, [isSubmitted]);

  //Function to upload device details
  const submitDevice = (e) => {
    e.preventDefault();
    let deviceData = {
      site_id: data.site_id,
      device_id: data.deviceID,
      device_name: data.devicename,
      type: data.devicetype,
      make: data.devicemake,
      model: data.devicemodel,
      serial_number: data.deviceserial,
      range: data.devicerange,
      resolution: data.deviceresolution,
      accuracy: data.deviceaccuracy,
      rating_capacity: data.devicerating,
      uom: data.deviceuom,
      criticality: data.devicecriticality,
      location: data.devicelocation,
      year_of_manufacture: data.devicemakeyear,
      year_of_installation: data.deviceinstyear,
      expected_life_in_month: data.devicelife,
      department: data.devicedepartment,
      accessibility: data.deviceaccesbility,
      device_image: data.devicephoto,
      remarks: data.deviceremark,
      created_by: userid,
    };
    validation();
    if (isValid) {
      dispatch({
        type: DEVICE_ADD,
        payload: deviceData,
      });
    }
  };

  //functions forinvalid data

  function idChars(str) {
    const specialChars = /^([a-zA-Z0-9-/@*]+\s)*[a-zA-Z0-9-/@*]+$/;
    return specialChars.test(str);
  }
  function snumChars(str) {
    const categoryChars = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;
    return categoryChars.test(str);
  }
  function resolutionChar(str) {
    const specialResoltion = /^([a-zA-Z0-9.]+\s)*[a-zA-Z0-9.]+$/;
    return specialResoltion.test(str);
  }
  function accureccyChar(str) {
    const specialAcc = /^([a-zA-Z0-9-.]+\s)*[a-zA-Z0-9-.]+$/;
    return specialAcc.test(str);
  }
  function yearChars(str) {
    const categoryChars = /^[0-9]*[0-9]+$/;
    return categoryChars.test(str);
  }
  function rncChars(str) {
    const specialAcc = /^([a-zA-Z0-9-/&]+\s)*[a-zA-Z0-9-/&]+$/;
    return specialAcc.test(str);
  }
  function uomChars(str) {
    const specialAcc = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialAcc.test(str);
  }

  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to Show Loading data */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3" style={{ paddingTop: "45px" }}>
            <div className="m-2">
              <form
                action=""
                method="post"
                name="myForm"
                id="Form"
                className=" border p-4 bg-light shadow rounded"
                target="#"
              >
                <div className="formHeadings mb-2">Create Device</div>
                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceid">
                      Device ID<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="deviceid"
                      id="deviceid"
                      maxLength="200"
                      className="form-control form-rounded"
                      onChange={(e) => {
                        handleDeviceID(e.target.value);
                      }}
                    />
                    {Object.keys(deviceIDErr).map((key) => {
                      if (!data.deviceID) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {deviceIDErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.deviceID) || data.deviceID === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Device Id
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="devicename">
                      Device Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="devicename"
                      id="devicename"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => {
                        handleDeviceName(e.target.value);
                      }}
                    />
                    {Object.keys(devicenameErr).map((key) => {
                      if (!data.devicename) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {devicenameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.devicename) || data.devicename === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Device Name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="type">
                      Type<span className="text-danger">*</span>
                    </label>
                    <select
                      name="devicetype"
                      id="devicetype"
                      className="form-select"
                      onChange={(e) => {
                        handleDeviceType(e.target.value);
                      }}
                    >
                      <option value="select">Select</option>
                      <option value="measuring_device">Measuring Device</option>
                      <option value="iot">IoT</option>
                    </select>
                    {Object.keys(devicetypeErr).map((key) => {
                      if (!data.devicetype) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {devicetypeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="devicemake">
                      Make<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="devicemake"
                      id="devicemake"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => {
                        handleDeviceMake(e.target.value);
                      }}
                    />
                    {Object.keys(devicemakeErr).map((key) => {
                      if (!data.devicemake) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {devicemakeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.devicemake) || data.devicemake === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Device Mark
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="model">
                      Model<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="devicemodel"
                      id="devicemodel"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => {
                        handleDeviceModel(e.target.value);
                      }}
                    />
                    {Object.keys(devicemodelErr).map((key) => {
                      if (!data.devicemodel) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {devicemodelErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.devicemodel) || data.devicemodel === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Device Model
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="serial">Serial Number</label>
                    <input
                      // type="number"
                      name="deviceserial"
                      id="deviceserial"
                      min="0"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => {
                        handleDeviceSerial(e.target.value);
                      }}
                    />
                    {snumChars(data.deviceserial) || data.deviceserial === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Serial Number
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="devicerange">
                      Range<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="devicerange"
                      id="devicerange"
                      className="form-control"
                      onChange={(e) => {
                        handleDevicerange(e.target.value);
                      }}
                    />
                    {Object.keys(devicerangeErr).map((key) => {
                      if (!data.devicerange) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {devicerangeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceresolution">
                      Resolution<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="deviceresolution"
                      id="deviceresolution"
                      className="form-control"
                      onChange={(e) => {
                        handleDeviceResolution(e.target.value);
                      }}
                    />
                    {Object.keys(deviceresolutionErr).map((key) => {
                      if (!data.deviceresolution) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {deviceresolutionErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {resolutionChar(data.deviceresolution) ||
                    data.deviceresolution === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Resolution
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceaccuracy">
                      Accuracy<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="deviceaccuracy"
                      id="deviceaccuracy"
                      className="form-control"
                      onChange={(e) => {
                        handleDeviceAccuracy(e.target.value);
                      }}
                    />
                    {Object.keys(deviceaccuracyErr).map((key) => {
                      if (!data.deviceaccuracy) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {deviceaccuracyErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {accureccyChar(data.deviceaccuracy) ||
                    data.deviceaccuracy === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Accuracy
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="rating">Rating/Capacity</label>
                    <input
                      type="text"
                      name="devicerating"
                      id="devicerating"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => {
                        handleDeviceRating(e.target.value);
                      }}
                    />
                    {rncChars(data.devicerating) || data.devicerating === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Rating/Capacity
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceuom">UoM of Rating/Capacity</label>
                    <input
                      type="text"
                      name="deviceuom"
                      id="deviceuom"
                      maxLength="100"
                      className="form-control"
                      onChange={(e) => {
                        handleDeviceUom(e.target.value);
                      }}
                    />
                    {uomChars(data.deviceuom) || data.deviceuom === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid UOM
                          </div>
                        ))}
                  </div>{" "}
                  <div className="mb-2 col-md-4">
                    <label htmlFor="devicecriticality">
                      Criticality<span className="text-danger">*</span>
                    </label>
                    <select
                      name="devicecriticality"
                      id="devicecriticality"
                      className="form-select"
                      onChange={(e) => {
                        handleDeviceCriticality(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    {Object.keys(devicecriticalityErr).map((key) => {
                      if (!data.devicecriticality) {
                        return (
                          <div
                            key={key}
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {devicecriticalityErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  {/* location */}
                  <div className="mb-2 col-md-4">
                    <label htmlFor="devicelocation">Location</label>
                    <input
                      name="devicelocation"
                      id="datepicker"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => {
                        handleDevicelocation(e.target.value);
                      }}
                    />

                    {/* {locationChars(data.devicelocation) ||
                    data.devicelocation === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Location
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="devicemakeyear">Year of Manufacture</label>
                    <input
                      name="devicemakeyear"
                      id="datepicker"
                      className="form-control"
                      maxLength="4"
                      onChange={(e) => {
                        handleDeviceMakeYear(e.target.value);
                      }}
                    />

                    {yearChars(data.devicemakeyear) ||
                    data.devicemakeyear === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid year
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceinstyear">Year of Installation</label>
                    <input
                      name="deviceinstyear"
                      id="datepicker"
                      className="form-control"
                      maxLength="4"
                      onChange={(e) => {
                        handleDeviceInstYear(e.target.value);
                      }}
                    />

                    {yearChars(data.deviceinstyear) ||
                    data.deviceinstyear === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Device Installation Year
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Expected Life in Years</label>
                    <input
                      name="devicelife"
                      id="devicelife"
                      className="form-control"
                      maxLength="4"
                      onChange={(e) => {
                        handleDeviceLife(e.target.value);
                      }}
                    />
                    {yearChars(data.devicelife) || data.devicelife === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Device Life
                          </div>
                        ))}
                  </div>
                  {/* Department */}
                  <div className="mb-2 col-md-4">
                    <label htmlFor="department">Department</label>
                    <input
                      name="department"
                      id="datepicker"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => {
                        handleDepartment(e.target.value);
                      }}
                    />

                    {/* {locationChars(data.devicelocation) ||
                    data.devicelocation === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Location
                          </div>
                        ))} */}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceaccesbility">
                      Network Accessibility
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      name="deviceaccesbility"
                      id="deviceaccesbility"
                      className="form-select"
                      onChange={(e) => {
                        handleDeviceAccesbility(e.target.value);
                      }}
                    >
                      <option value="select">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-8">
                    <label htmlFor="photo">Device Image</label>
                    <input
                      type="file"
                      id="devicephoto"
                      name="devicephoto"
                      className="form-control"
                      onChange={handleDeviceImg}
                      accept=".jpeg,.jpg,.png"
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <label htmlFor="deviceremark">Remarks</label>
                    <input
                      type="text"
                      name="deviceremark"
                      id="deviceremark"
                      className="form-control"
                      onChange={(e) => {
                        handleDeviceremark(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="mb-2 col-md-12">
                    <button
                      type="submit"
                      className="float-end saveButton"
                      onClick={(e) => submitDevice(e)}
                    >
                      Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      onClick={() => navigate("/select/asset")}
                      className="backButton"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//exporting component
export default DeviceUpload;
