/* Feature - Select customer & site for create new asset / device / facility

Created by - Janaki J
Updated by - Mona R */

//importing dependencies and packages
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../constants/URLs/urlConstants";
import { custService } from "../../services/service.index";
import { siteService } from "../../services/service.index";
import BulkUpload from "../../modules/asset/bulkUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";

function Selectasset() {
  // To fetch details from redux
  const isLoading = useSelector((state) => state.asset.isLoading);

  // useState to handle state
  const [custname, setCustname] = useState("");
  const [sitename, setSitename] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState(""); // customer ID
  const [siteId, setSiteID] = useState(""); // site ID
  const [custdata, setCustData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [assetselect, setAssetselect] = useState("");

  const navigate = useNavigate();

  // Bulk upload button with modal feature
  const [modalShow, setModalShow] = useState(false);

  let data = {
    cust_id: id,
    site_id: siteId,
    legal_name: name,
    site_name: sitename,
  };

  // client side validation
  const [sitenameErr, setSiteNameErr] = useState("");
  const [custnameErr, setCustnameErr] = useState("");
  const [assetselectErr, setAssetSelectErr] = useState("");

  const formValidation = () => {
    const sitenameErr = {};
    const custnameErr = {};
    const assetselectErr = {};

    let isValid = true;

    if (custname.trim().length < 1 || custname === "select") {
      custnameErr.custnameShort = "Choose Customer Name";
      isValid = false;
    }
    if (sitename.trim().length < 1 || sitename === "select") {
      sitenameErr.sitenameShort = "Choose Site Name";
      isValid = false;
    }
    if (assetselect.trim().length < 1 || assetselect === "select") {
      assetselectErr.assetselectShort = "Choose Any One";
      isValid = false;
    }
    setSiteNameErr(sitenameErr);
    setCustnameErr(custnameErr);
    setAssetSelectErr(assetselectErr);

    return isValid;
  };

  // function to get customer data
  const getData = async () => {
    await custService
      .getCustData(CONSTANTS.CUST_GET_ALL)
      .then(function (response) {
        setCustData(response.data);
      })
      .catch(function (error) {});
  };

  // function to handle customer details. To fetch the customer ID from API.
  const handleCustomer = (string) => {
    let array = string.split(",");
    setId(array[0]);
    setName(array[1]);
  };

  // function to get site based on selected customer.
  const getSiteByCustId = async (id) => {
    // console.log(id);
    await siteService
      .getSiteById(id)
      .then(function (res) {
        setSiteData(res.data);
      })
      .catch(function (error) {});
  };

  const uploadAssetbySiteId = (data) => {
    // console.log(data);
    setSiteID(data);
  };

  // function to navigate respective page based on selected data (asset/device/facility)
  const handleSubmit = (e) => {
    const isValid = formValidation();
    if (isValid && assetselect === "asset") {
      navigate("/asset/upload/" + siteId);
    }
    if (isValid && assetselect === "device") {
      navigate("/deviceupload/" + siteId);
    }
    if (isValid && assetselect === "facility") {
      navigate("/facility/upload/" + siteId);
    }
    return isValid;
  };

  // useEffect
  useEffect(() => {
    getData();
    getSiteByCustId(id);
  }, []);

  return (
    <div>
      <Navbar bg="light" variant={"light"} expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="headershadow">
          <Nav style={{ maxHeight: "100px" }}>
            <Sidebar />
            <Logo />
            <Nav.Link
              className="position-absolute top-25 end-0 header"
              style={{ color: "#000", paddingRight: "250px" }}
              onClick={() => setModalShow(true)}
            >
              Bulk Upload
            </Nav.Link>
            <LogoutButton />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {isLoading && <Loader />} {/* to show loading effect */}
      <div className="container">
        <div className="row h-100">
          <div className="col-md-6 offset-md-3" style={{ paddingTop: "120px" }}>
            <br />
            {/* Bulkupload modal feature */}
            {modalShow && (
              <BulkUpload
                show={modalShow}
                onHide={() => setModalShow(false)}
              ></BulkUpload>
            )}

            {/* Form to select customer & site */}
            <form
              name="myForm"
              id="Form"
              className="mt-5 rounded p-4 bg-light shadow"
              target="#"
            >
              <div className="formHeadings mb-2">
                Create Asset / Device / Facility
              </div>
              <div className="ml-mb-2 col-md-12">
                <label>
                  Customer Name
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="custname"
                  className="form-select"
                  value={custname}
                  onChange={(e) => {
                    setCustname(e.target.value);
                    handleCustomer(e.target.value);
                    getSiteByCustId(e.target.value.split(",")[0]);
                  }}
                >
                  <option value="select">Select</option>
                  {custdata?.map((eachData) => {
                    return (
                      <option
                        value={[eachData._id, eachData.legal_name]}
                        key={eachData._id}
                      >
                        {eachData.legal_name}
                      </option>
                    );
                  })}
                </select>
                {Object.keys(custnameErr).map((key) => {
                  return (
                    <div style={{ color: "red", fontSize: 15 }}>
                      {custnameErr[key]}
                    </div>
                  );
                })}
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className="ml-mb-2 col-md-12">
                <label>
                  Site Name
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="sitename"
                  className="form-select"
                  value={sitename}
                  onChange={(e) => {
                    setSitename(e.target.value);
                    uploadAssetbySiteId(e.target.value.split(",")[0]);
                  }}
                >
                  <option value="">Select Customer Name First</option>
                  {siteData?.map((eachSite, index) => {
                    return (
                      <option
                        value={[eachSite._id, eachSite.site_name]}
                        key={eachSite._id}
                      >
                        {eachSite.site_name}
                      </option>
                    );
                  })}
                </select>
                {Object.keys(sitenameErr).map((key) => {
                  return (
                    <div style={{ color: "red", fontSize: 15 }}>
                      {sitenameErr[key]}
                    </div>
                  );
                })}
              </div>
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              <div className="ml-mb-2 col-md-12">
                <label>
                  Select Asset/Device/Facility
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="assetselect"
                  name="assetselect"
                  className="form-select"
                  value={assetselect}
                  onChange={(e) => {
                    setAssetselect(e.target.value);
                  }}
                >
                  <option value="" >Select</option>
                  <option value="asset">Asset</option>
                  <option value="device">Device</option>
                  <option value="facility">Facility</option>
                </select>
                {Object.keys(assetselectErr).map((key) => {
                  return (
                    <div style={{ color: "red", fontSize: 15 }}>
                      {assetselectErr[key]}
                    </div>
                  );
                })}
              </div>
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              <div className="mt-mb-4 col-md-12 p-2">
                <button
                  type="button"
                  className="float-end saveButton"
                  onClick={(e) => handleSubmit(e)}
                >
                  Next
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/home")}
                  className="backButton"
                >
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selectasset;
