import React, { useState } from 'react';
import Commonheader from '../../../layouts/common/commonHeader';
import Loader from '../../../components/loader/loader';
import { useNavigate } from "react-router-dom";


function TatUpload() {
    const [cat, setCat] = useState("");
    const navigate = useNavigate();

    return (
        <>
            <Commonheader />
            {/* {isLoading && <Loader />}  */}
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3" style={{ paddingTop: "45px" }}>
                        <div className="m-2">
                            <form
                                action=""
                                className="mt-5 border p-4 bg-light shadow rounded"
                            >
                                <div className="formHeadings mb-2">Create TAT</div>
                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                        <label>
                                            Criticality
                                            {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <select
                                            name="criticality"
                                            className="form-select"
                                            value={cat}
                                            onChange={(e) => {
                                                setCat(e.target.value);
                                            }}
                                        >
                                            <option value="" disabled>
                                                Select
                                            </option>
                                            <option value="1">1</option>
                                            <option value="device">b</option>
                                            <option value="facility">c</option>
                                            <option value="custom">custom</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-md-12">
                                        <label>
                                            Category
                                            {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <select
                                            name="cat"
                                            className="form-select"
                                            value={cat}
                                            onChange={(e) => {
                                                setCat(e.target.value);
                                            }}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="asset">a</option>
                                            <option value="device">b</option>
                                            <option value="facility">c</option>
                                            <option value="custom">custom</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-md-12">
                                        <label>
                                            Sub-Category
                                            {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <select
                                            name="sub-cat"
                                            className="form-select"
                                            value={cat}
                                            onChange={(e) => {
                                                setCat(e.target.value);
                                            }}
                                        >
                                            <option value="" disabled>
                                                Select
                                            </option>
                                            <option value="asset">a</option>
                                            <option value="device">b</option>
                                            <option value="facility">c</option>
                                            <option value="custom">custom</option>
                                        </select>
                                    </div>

                                    <div className="mb-3 col-md-6">
                                        <label htmlFor="appt">
                                            TAT Response Time
                                            <br></br>
                                            <input type="time" id="appt" name="appt" className="validity" placeholder="HH:MM"></input>
                                        </label>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label>
                                            Resolution Time
                                            <br></br>
                                            <input type="time" id="appt" name="appt" className="validity" placeholder="HH:MM"></input>
                                        </label>
                                    </div>
                                    <div className="mb-3 col-md-12">
                                        <label>
                                            Description
                                        </label>
                                        <br></br>
                                        {/* <input type='text' size="50" className='descriptn col-md-12' style={{ overflowWrap: 'break-word'  }} /> */}
                                        <textarea id="myText" rows="4" cols="8" className='descriptn p-2' ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button
                                        style={{ width: "100px" }}
                                        type="button"
                                        // onClick={(e) => multiDownload(e.target.value)}
                                        className="float-end saveButton"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => navigate("/tat-ticket/list")}
                                        className="backButton"
                                    >
                                        Back
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TatUpload