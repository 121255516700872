
const IMAGENAME = {
  logoZV: require("./images/ZV_new_logo.jpg"),
  loader: require("./images/zv_loading.gif"),
  logo: require("./images/zv_logo_transparent.png"),
  loaderLogo: require("./images/zv_O_transparent.png"),
  loginBgPic: require("./images/login_bg.png"),
  // qrLogo: require("./images/zv_O_black.png"),
  // qrLogo: require("./images/zv_logo_white.png"),
  qrCompanyLogo: require("./images/zv_logo_transparent.svg").default,
  qrIndigoLogo: require("./images/indigo-vector-logo-2022.png"),
  qrAirportLogo: require("./images/Airport1.png"),
  BASLLogo: require("./images/080Logov1.jpg")
  // qrIndigoLogo: require("./images/indigo-vector-logo-2022.svg").default
};
export default IMAGENAME;