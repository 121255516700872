/* Feature - to maintain header for userrole page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import Logo from "../../components/logo";
import { useSelector, useDispatch } from "react-redux";
import CustomRole from '../../modules/userRole/customRole';

//Function for site header
function UserRoleheader() {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const access = useSelector((state) => state.access.user);
  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    const roleCode = role[0];
    // console.log(roleCode);
    if (access === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role]);

  useEffect(() => {
    // console.log(access);
  }, [access]);

  return (
    <div>
      <div>
        <Navbar bg="light" variant={"light"} expand="lg">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="headershadow">
            <Nav
              className="mr-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
            >
              <Sidebar />
              <Logo />
              {/* {access !== null && access.c ? ( */}
                <div>
                  <div>
                    <Nav.Link                      
                      onClick={() => setModalShow(true)}
                      className="position-absolute end-0 header"
                      style={{
                        marginRight: "260px",
                      }}
                    >
                      Create User Role
                    </Nav.Link>
                  </div>                 
                </div>
              {/* ) : null}               */}
              {modalShow && (
                <CustomRole
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                ></CustomRole>
              )}
              <LogoutButton />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
    </div>
  );
}

export default UserRoleheader;
