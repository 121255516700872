import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCalendarDays,
    faAngleRight,
    faAngleLeft,
    faClose,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faEye,
    faBook,
} from "@fortawesome/free-solid-svg-icons";
import Commonheader from "../../layouts/common/commonHeader";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import BackButton from "../../components/BackButton/BackButton";
// const CapitalisedAsset = [
//     {
//         asset_id: "GMP/0021",
//         asset_name: "Server",
//         cost_center: "IT",
//         vendor_name: "Lenovo",
//         quantity: "10",
//         invoice: "321",
//         dop: "2023-06-12",
//         doi: ""
//     },
//     {
//         asset_id: "GMP/032",
//         asset_name: "Computer",
//         cost_center: "IT",
//         vendor_name: "Lenovo",
//         quantity: "10",
//         invoice: "321",
//         dop: "2023-06-12",
//         doi: ""
//     }
// ]
export const Cwip = () => {
    const [cwip, setCWIP] = useState()
    const [list, setList] = useState();
    const [masterChecked, setMasterChecked] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const isLoading = useSelector((state) => state.FA.isLoading);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const userid = useSelector((state) => state.auth.userid);
    let data1 = useSelector((state) => state.FA.CWIPData)
    const [isMasterChecked, setIsMasterChecked] = useState(false);
    // setList(data1)
    // data1 = data1?.map((el) => {});
    // console.log(list);
    let AllData = []
    // useEffect(() => {
    //     for (let i = 0; i < data1?.length; i++) {
    //         let newData = {
    //             ...data1[i],
    //             selected: false
    //         }
    //         AllData.push(newData)
    //     }
    //     setList(AllData)

    // }, [data1])

    const onMasterCheck = (e) => {
        const tempList = data1?.result?.map((user) => ({
            ...user,
            selected: e.target.checked,
        }));
        // console.log(tempList, "AASS")
        setIsMasterChecked(e.target.checked);
        setMasterChecked(e.target.checked);
        setList(tempList);
        setSelectedList(tempList.filter((e) => e.selected));
    };

    const onItemCheck = (e, item) => {

        const tempList = list.map((user) =>
            user._id === item._id ? { ...user, selected: e.target.checked } : user
        );

        const totalItems = data1?.result?.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        setMasterChecked(totalItems === totalCheckedItems);
        setList(tempList);
        setSelectedList(tempList.filter((e) => e.selected));
    };
    // console.log(selectedList)
    const getSelectedRows = () => {
        setSelectedList(list.filter((e) => e.selected));
    };

    const getData = async () => {

        dispatch({
            type: 'CWIP_GET_LIST',
            payload: [currentPage, itemsPerPage],
        })
        // setList(data1)
        // const { data } = await axios.get(`http://localhost:4010/list`);
        // // console.log(data)
        // let AllData = []
        // for (let i = 0; i < data.length; i++) {
        //     let newData = {
        //         ...data[i],
        //         selected: false
        //     }
        //     AllData.push(newData)
        // }
        // setList(AllData)
    };

    const handleCapitalise = async () => {

        if (selectedList.length == 0) {
            alert("Please select at least one Asset")
        } else {
            let selectedListN = selectedList.map((el) => ({
                ...el, created_by: userid
            }))
            // console.log(selectedListN)
            dispatch({
                type: 'ADD_CAPITALISE',
                payload: selectedListN
            })
            navigate("/capitalised")
        }

        // await axios.post("http://localhost:4010/capitalise", selectedList)
        // getData()
    }
    const handleExpense = async () => {
        // console.log(selectedList)
        if (selectedList.length == 0) {
            alert("Please select at least one Asset")
        } else {

            let selectedListN = selectedList.map((el) => ({
                ...el, created_by: userid
            }))
            dispatch({
                type: 'ADD_EXPENSE',
                payload: selectedListN
            })
            navigate("/expensed")
        }
        // await axios.post("http://localhost:4010/expense", selectedList)

        // alert("Asset Expensed successfully")

    }


    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    // Calculate the total number of pages
    let totalPages = 0;
    var data1List = [];
    if (data1?.result !== undefined) {
        data1List = data1?.result;
    }
    if (data1?.total_count !== undefined) {
        totalPages = Math.ceil(data1?.total_count / itemsPerPage);
    }
    // console.log('data1List', data1List);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    useEffect(() => {
        if ((currentPage && currentPage > 0)) {
            getData()
        }
    }, [currentPage, itemsPerPage]);


    useEffect(() => {
        setList(data1?.result);
    }, [data1?.result])


    // console.log('data1List', data1List);
    return (
        <>
            {isLoading && <Loader />}
            <Commonheader />

            <div className="col-md-12">

                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        justifyContent: "center",
                    }}
                >
                    CWIP Asset List
                    {/* <div style={{ float: "right" }}>
                        <form
                            className="mb-4"
                        >
                            <input
                                type="text"
                                placeholder="Search"

                                style={{
                                    border: "1px solid steelblue",
                                    fontSize: "15px",
                                    padding: "5px",
                                }}
                            />
                            <span
                                type="reset"

                                style={{ paddingLeft: "8px", marginRight: "25px" }}
                            >
                                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                            </span>
                        </form>
                    </div> */}
                    <div>Asset View </div>
                    <BackButton />
                </div>
                {/* <div class="container" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div><p class="fs-4 fw-semibold">CWIP Asset List</p></div>
                    <div class="input-group-2" >
                        <input type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="basic-addon2" />
                    </div>
                </div> */}
                <table className="table-bordered">
                    <thead >
                        <tr style={{
                            textAlign: "center",
                            backgroundColor: "steelblue",
                            padding: "8px",
                            color: "#fff",
                        }}>
                            <th style={{ padding: "8px", width: "5%" }} scope="col">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={masterChecked}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheck(e)}
                                />
                            </th>
                            <th style={{ padding: "8px", width: "5%" }}>S. No</th>
                            <th scope="col"
                                style={{ wordBreak: "break-word", width: "10%" }}>Temporary ID</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "left", width: "auto" }}>Asset Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Class Code</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Class Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost Center Code</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost Center Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Location Code</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Location Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Supplier Name</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Quantity</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Invoice Number</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Date of Purchase</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Date of Installation</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Salvage Value</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Invoice Value (INR)</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Other Cost (INR)</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Capital Value (INR)</th>
                            <th scope="col"
                                style={{ padding: "8px", textAlign: "center", width: "auto" }}>Description</th>
                            {/* <th scope="col">Salvage Value (INR)</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {isMasterChecked ? (
                            list?.map((el, index) => (
                                <tr key={el.id} style={{ textAlign: "center" }}>
                                    <th scope="row">
                                        <input
                                            type="checkbox"
                                            checked={el.selected}
                                            className="form-check-input"
                                            id={`rowcheck${el._id}`}
                                            onChange={(e) => onItemCheck(e, el)}
                                        />
                                    </th>
                                    <td style={{ padding: "8px" }}>{(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                                    <td>{el.asset_id}</td>
                                    <td style={{ textAlign: "left", paddingLeft: "5px" }}>{el.asset_name}</td>
                                    <td>{el.asset_class}</td>
                                    <td>{el.asset_class_name}</td>
                                    <td>{el.cost_center}</td>
                                    <td>{el.cost_center_name}</td>
                                    <td>{el.location}</td>
                                    <td>{el.location_name}</td>
                                    <td>{el.supplier_name}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.invoice_no}</td>
                                    <td>{el.acquisition_date?.slice(0, 10)}</td>
                                    <td>{el.installation_date?.slice(0, 10)}</td>
                                    <td>{el.salvage_value}</td>
                                    <td>{el.invoice_value}</td>
                                    <td>{el.other_value}</td>
                                    <td>{el.cost}</td>
                                    <td>{el.remarks}</td>
                                </tr>
                            ))
                        ) : (
                            data1List?.map((el, index) => (
                                <tr key={el.id} style={{ textAlign: "center" }}>
                                    <th scope="row">
                                        <input
                                            type="checkbox"
                                            checked={el.selected}
                                            className="form-check-input"
                                            id={`rowcheck${el._id}`}
                                            onChange={(e) => onItemCheck(e, el)}
                                        />
                                    </th>
                                    <td style={{ padding: "8px" }}>{(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                                    <td>{el.asset_id}</td>
                                    <td style={{ textAlign: "left", paddingLeft: "5px" }}>{el.asset_name}</td>
                                    <td>{el.asset_class}</td>
                                    <td>{el.asset_class_name}</td>
                                    <td>{el.cost_center}</td>
                                    <td>{el.cost_center_name}</td>
                                    <td>{el.location}</td>
                                    <td>{el.location_name}</td>
                                    <td>{el.supplier_name}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.invoice_no}</td>
                                    <td>{el.acquisition_date?.slice(0, 10)}</td>
                                    <td>{el.installation_date?.slice(0, 10)}</td>
                                    <td>{el.salvage_value}</td>
                                    <td>{el.invoice_value}</td>
                                    <td>{el.other_value}</td>
                                    <td>{el.cost}</td>
                                    <td>{el.remarks}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <div>
                    {/* pagination */}
                    {data1List !== null && data1List.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                padding: "10px",
                            }}
                        >
                            <div className="mb-6">
                                <label>Items per page:</label> &nbsp;
                                <select
                                    onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                    style={{ borderRadius: "8px", cursor: "pointer" }}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div style={{ paddingRight: "25px" }} className="pagination">
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handleFirstPage}
                                >
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handlePrev}
                                >
                                    {/* Prev */}
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                </button>
                                <span style={{ padding: "10px" }}>
                                    Showing {currentPage} of {totalPages} pages
                                </span>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        border: "none",
                                    }}
                                    disabled={currentPage === totalPages}
                                    onClick={handleNext}
                                >
                                    {currentPage === totalPages ? (
                                        <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        border: "none",
                                    }}
                                    disabled={currentPage === totalPages}
                                    onClick={handleLastpage}
                                >
                                    {currentPage === totalPages ? (
                                        <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div style={{ float: "right", }}>
                    <Button variant="outline-primary" style={{ margin: "1rem" }} onClick={handleCapitalise}>Capitalise</Button>
                    <Button variant="outline-danger" style={{ margin: "1rem" }} onClick={handleExpense}>Expense</Button>
                </div>
                <button
                    className="btn btn-primary"
                    onClick={() => getSelectedRows()}
                >
                    Get Selected Items {selectedList.length}
                </button>
                {/* <div className="row">
                    <b>All Row Items:</b>
                    <code>{JSON.stringify(list)}</code>
                </div>
                <div className="row">
                    <b>Selected Row Items(Click Button To Get):</b>
                    <code>{JSON.stringify(selectedList)}</code>
                </div> */}
            </div >



        </>
    );
};

