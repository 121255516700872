import React from 'react';
// import CardComponent from './CardComponent';
import styles from './Restaurant.module.css'
import IMAGENAME from '../../medias/media.index';
import styles_for_logo from '../PrivacyPolicy/PrivacyPolicy.module.css'
import { Link } from 'react-router-dom';
const Restaurant = () => {
    const Imagename1 = { 
        QILA1: require("./Images/Fist_Image.jpg"), 
        QILA2: require("./Images/third_img.png") 
    }
    return (
        <div className={styles.container}>

            <div className={styles_for_logo.logo} style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }} >
                <img
                    className={styles.logoImage}
                    style={{ cursor: "pointer", }}
                    src={IMAGENAME.logo}
                    width="auto"
                    alt="logo"
                    height="25"

                />
            </div>
            <div
                className={styles.container}
            >
                <div className={styles.box} style={{
                    position: 'relative'
                }}>
                    <img
                        src={Imagename1.QILA1} 
                        style={{
                            width: "50%",
                            height: '50%',
                            borderRadius: '50%',
                            marginBottom: '1rem',
                            marginTop: '2rem'

                        }}
                        />
                    <h4>
                        QILA BEVERAGE MENU
                    </h4>
                    <Link to='QILA' style={{ color: 'black' }}>View Menu</Link>
                </div>
                <div className={styles.box} style={{
                    position: 'relative'
                }}>
                    <img
                        style={{
                            width: "50%",
                            height: '50%',
                            borderRadius: '50%',
                            marginBottom: '1rem',
                            marginTop: '2rem'
                        }}
                        src={Imagename1.QILA1} />
                    <h4>
                        RADIO HOUSE BEVERAGE MENU
                    </h4>
                    <Link to='RADIO' style={{ color: 'black' }}>View Menu</Link>
                </div>
                <div className={styles.box} style={{
                    position: 'relative'
                }}>
                    <img
                        style={{
                            width: "50%",
                            height: '50%',
                            borderRadius: '50%',
                            marginBottom: '1rem'
                        }}
                        src={Imagename1.QILA2} />
                    <h4>
                        VIEW LAYOUT
                    </h4>
                    <Link to='Office-Layout' style={{ color: 'black' }}>View Layout</Link>
                </div>
            </div>
        </div>
    );
};

export default Restaurant;