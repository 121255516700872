import React from 'react'
import IMAGENAME from '../../medias/media.index'

const PlantDetails = () => {
    return (

        // <div className="container">
        //     <div className="row">
        //         <div className="col-md-6 offset-md-3">
        //             <div style={{ paddingTop: "25px" }}>
        //                 <form
        //                     action=""
        //                     className="mt-5 border p-4 bg-light shadow rounded"
        //                 >
        //                     <div style={{
        //                         padding: "2rem"
        //                     }}>
        //                         <h2>
        //                             Plant Name : Dracaena Draco
        //                         </h2>
        //                         <h2>Location : T2 </h2>
        //                         <h2>Details :</h2>
        //                         <div>
        //                             <div >
        //                                 <img style={{ width: '50%' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Ajgal_P2210782.jpg/440px-Ajgal_P2210782.jpg" alt="Dracaena Draco" />
        //                             </div>
        //                             <div
        //                                 style={{
        //                                     padding: "1rem"
        //                                 }}
        //                             >

        //                                 <p>
        //                                     Dracaena draco is an evergreen long lived tree with up to 15 m (49 ft) or more in height and a trunk 5 m (16 ft) or more in circumference, starting with a smooth bark that evolves to a more rough texture as it ages.[8] The "dragon tree" is a Monocot, with a branching growth pattern currently placed in the asparagus family (Asparagaceae, subfamily Nolinoidae).[9] When young it has a single stem. At about 10–15 years of age the stem stops growing and produces a flower spike with white, lily-like perfumed flowers, followed by coral berries. Soon a crown of terminal buds appears and the plant starts branching. Each branch grows for about 10–15 years and re-branches, so a mature plant has an umbrella-like habit. It grows slowly, requiring about 10 years to reach 1.2 metres (4 ft) in height, but can grow much faster.[clarification needed][10]

        //                                     Despite being a monocotyledon, it still has annual or growth rings. There is considerable genetic variation within the Canary Island dragon trees. The form found on Gran Canaria is now treated as a separate species, Dracaena tamaranae, based on differences in flower structure. The form endemic to La Palma initially branches very low with numerous, nearly vertical branches arranged fastigiately. There is a forest of such trees at Las Tricias, Garafia district, La Palma.
        //                                 </p>
        //                             </div>

        //                         </div>
        //                     </div>

        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        // ....................................

        <div
            style={{
                padding: "2rem"
            }}
        >
            <div
                style={{
                    // border: "1px solid #000",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                <img
                    style={{
                        maxWidth: "12rem",
                        margin: "auto",
                        padding: "20px",

                    }}

                    src={IMAGENAME.qrAirportLogo} />

            </div>
            {/* <div
                style={{
                    marginTop: "2rem",
                    marginBottom: "2rem"
                }}
            >
                <h3>
                    Welcome to Bengaluru Airport - Terminal 2
                </h3>
            </div> */}
            <div style={{
                padding: "1rem"
            }}>
                <div
                    style={{
                        // border: "1px solid",
                        display: "flex",
                        // justifyContent: "space-between"
                    }}
                >
                    <div
                        style={{
                            // border: "1px solid",
                            width: "40%"
                        }}
                    >
                        <h5
                            style={{
                                display: "inline",
                                fontWeight: "bold"
                            }}
                        >Plant Name</h5>
                    </div>
                    <h5
                        style={{
                            display: "inline",
                            fontWeight: "lighter"
                        }}
                    >:</h5>
                    <h5
                        style={{
                            display: "inline",
                            marginLeft: "10px",
                            fontWeight: "lighter"
                        }}
                    >Dracaena Draco</h5>
                </div>

                <div
                    style={{
                        // border: "1px solid",
                        display: "flex",
                        // justifyContent: "space-between"
                    }}
                >
                    <div
                        style={{
                            // border: "1px solid",
                            width: "40%"
                        }}
                    >
                        <h5
                            style={{
                                display: "inline",
                                fontWeight: "bolder"
                            }}
                        >Plant Location</h5>
                    </div>
                    <h5
                        style={{
                            display: "inline"
                        }}
                    >:</h5>
                    <h5
                        style={{
                            display: "inline",
                            marginLeft: "10px"
                        }}
                    >Terminal 2</h5>
                </div>

                <div
                    style={{
                        // border: "1px solid",
                        display: "flex",
                        // justifyContent: "space-between"
                    }}
                >
                    <div
                        style={{
                            // border: "1px solid",
                            width: "40%"
                        }}
                    >
                        <h5
                            style={{
                                display: "inline",
                                fontWeight: "bolder"
                            }}
                        >Plant Details</h5>
                    </div>
                    <h5
                        style={{
                            display: "inline"
                        }}
                    >:</h5>
                    <h5
                        style={{
                            display: "inline",
                            marginLeft: "10px"
                        }}
                    ></h5>
                </div>
                <div>
                    <div >
                        <img style={{ width: '90%', }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Dracaena_draco.jpg/440px-Dracaena_draco.jpg" alt="Dracaena Draco" />
                    </div>
                    <div
                        style={{
                            textAlign: "justify",
                            marginTop: "1rem"
                        }}
                    >

                        <p>

                            <strong
                                style={{
                                    fontWeight: "bold"
                                }}
                            >Dracaena draco </strong>
                            is an evergreen long lived tree with up to 15 m (49 ft) or more in height and a trunk 5 m (16 ft) or more in circumference, starting with a smooth bark that evolves to a more rough texture as it ages. The "dragon tree" is a Monocot, with a branching growth pattern currently placed in the asparagus family (Asparagaceae, subfamily Nolinoidae). When young it has a single stem. At about 10–15 years of age the stem stops growing and produces a flower spike with white, lily-like perfumed flowers, followed by coral berries. Soon a crown of terminal buds appears and the plant starts branching. Each branch grows for about 10–15 years and re-branches, so a mature plant has an umbrella-like habit. It grows slowly, requiring about 10 years to reach 1.2 metres (4 ft) in height, but can grow much faster.

                        </p>
                        <p>
                            Despite being a monocotyledon, it still has annual or growth rings. There is considerable genetic variation within the Canary Island dragon trees. The form found on Gran Canaria is now treated as a separate species, Dracaena tamaranae, based on differences in flower structure. The form endemic to La Palma initially branches very low with numerous, nearly vertical branches arranged fastigiately. There is a forest of such trees at Las Tricias, Garafia district, La Palma.

                        </p>
                    </div>

                </div>
            </div>
            <div
                style={{
                    // border: "1px solid",
                    width: "100%",
                }}
            >
                <p
                    style={{
                        marginTop: "3rem"
                    }}
                >Thank you for scanning, have a good time !</p>
            </div>
        </div>



    )
}

export default PlantDetails