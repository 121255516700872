import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notifySuccess = () => {
  toast.success("Customer uploaded successfully!", {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const notifyContactSuccess = () => {
  toast.success("Customer contact uploaded successfully!", {
    position: toast.POSITION.TOP_CENTER,
  });
};

const ToastNotifications = () => {
  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default ToastNotifications;
