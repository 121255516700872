import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from '../../constants/URLs/urlConstants';


function getDeviceData(data) {
    // console.log(data);
    return serviceBase.get(CONSTANTS.DEVICE_GET_ALL + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`);    
};

function deleteDeviceData (id) {
    return serviceBase.delete(CONSTANTS.DEVICE_DELETE + id[3]);    
};

function addDeviceData(data) {
    // console.log('data from componet to service', data);
    return serviceBase.post(CONSTANTS.DEVICE_ADD, data);
}
function updateDevice(data) {
    return serviceBase.patch(CONSTANTS.DEVICE_UPDATE + data[0], data[1]);
}

function getSingleDevice(id) {
    return serviceBase.get(CONSTANTS.DEVICE_GET_SINGLE + id);
}


export const deviceDetails = {    
    getDeviceData,   
    deleteDeviceData, 
    addDeviceData,
    updateDevice,
    getSingleDevice
}