/* Feature - Show the Audit Trail
Created by - Mona R
*/

//importing dependencies and packages
import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faAngleLeft,
    faClose,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import moment from "moment";
import { auditAttr } from './attributes'


function TableComponent({ bodydata, isloading, currentpage, searchinput, itemsperpage }) {

    //For Search and filter 
    const [searchInput, setSearchInput] = useState("");
    const dispatch = useDispatch();

    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    // Calculate the total number of pages
    let totalPages = 0;
    var bodyList = [];
    if (bodydata.result !== undefined) {
        bodyList = bodydata.result;
    }
    if (bodydata?.total_count !== undefined) {
        totalPages = Math.ceil(bodydata.total_count / itemsPerPage);
    }
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
        currentpage(currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
        currentpage(currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
        currentpage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
        currentpage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
        currentpage(1);
        itemsperpage(items)
    };

    // Function to handle search input change
    const handleSearchInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        searchinput(e.target.value)
    };


    //Changes for Pagination and Search
    const handleClearButton = () => {
        setSearchInput("");
        searchinput("")
    };


    ////////////////////////////////////////HTML////////////////////////////////////////////////
    return (
        <div>
            {isloading && <Loader />} {/* to show Loading effect */}
            <div className="col-md-12">
                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        justifyContent: "center",
                    }}
                >
                    Audit Trail
                    <div style={{ float: "right" }}>
                        <form
                            className="mb-4"
                        >
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                                style={{
                                    border: "1px solid steelblue",
                                    fontSize: "15px",
                                    padding: "5px",
                                }}
                            />
                            {/* {console.log(searchInput)} */}
                            <span
                                type="reset"
                                onClick={handleClearButton}
                                style={{ paddingLeft: "8px", marginRight: "25px" }}
                            >
                                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                            </span>
                        </form>
                    </div>
                </div>
                {/* Table to view data list */}
                <div className="mb-6">
                    <table className="table-bordered" width="100%">
                        <thead>
                            {/* Table heading to display list of Site */}
                            <tr
                                style={{
                                    textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                }}
                            >
                                {auditAttr.auditAMM.map((eachData, index) => {
                                    return (
                                        <th style={{ textAlign: eachData.alignment, height: '50px', padding: "8px" }}>{eachData.heading}</th>
                                    )
                                }
                                )
                                }
                            </tr>
                        </thead>
                        {bodyList !== null && bodyList.length > 0 ? (
                            bodyList.map((eachData, index) => {
                                return (
                                    <tbody key={eachData._id} style={{ padding: "15px" }}>
                                        <tr>
                                            <td style={{ textAlign: "center", padding: "8px" }}>
                                                {(currentPage - 1) * itemsPerPage + (index + 1)}
                                            </td>
                                            <td style={{ textAlign: "left", padding: "8px" }}>
                                                {eachData.event_name.replace(/_/g, ' ')}
                                            </td>
                                            <td style={{ textAlign: "left", paddingLeft: "8px" }}>
                                                {eachData.remarks}
                                            </td>
                                            <td style={{ textAlign: "center", padding: "8px" }}>
                                                {eachData.executed_date !== null
                                                    ? moment(eachData.executed_date)
                                                        .format("MMMM Do YYYY | hh:mm A") : "-"
                                                }
                                            </td>
                                            <td style={{ textAlign: "center", padding: "8px" }}>{eachData.executed_by}</td>
                                        </tr>
                                    </tbody>
                                );
                            })
                        ) : (
                            <tbody>
                                <tr style={{ textAlign: "center", height: "75px" }}>
                                    <td colSpan="9">No Data Available</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>

                {/* pagination */}
                {bodyList !== null && bodyList.length > 0 ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            padding: "10px",
                        }}
                    >
                        <div className="mb-6">
                            <label>Items per page:</label> &nbsp;
                            <select
                                onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                style={{ borderRadius: "8px", cursor: "pointer" }}
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div style={{ paddingRight: "25px" }} className="pagination">
                            <button
                                style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "#fff",
                                }}
                                disabled={currentPage === 1}
                                onClick={handleFirstPage}
                            >
                                {currentPage === 1 ? (
                                    <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                            </button>
                            <button
                                style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "#fff",
                                }}
                                disabled={currentPage === 1}
                                onClick={handlePrev}
                            >
                                {/* Prev */}
                                {currentPage === 1 ? (
                                    <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                            </button>
                            <span style={{ padding: "10px" }}>
                                Showing {currentPage} of {totalPages} pages
                            </span>
                            <button
                                style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    border: "none",
                                }}
                                disabled={currentPage === totalPages}
                                onClick={handleNext}
                            >
                                {currentPage === totalPages ? (
                                    <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                            </button>
                            <button
                                style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    border: "none",
                                }}
                                disabled={currentPage === totalPages}
                                onClick={handleLastpage}
                            >
                                {currentPage === totalPages ? (
                                    <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default TableComponent;
