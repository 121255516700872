import React, { useState } from 'react';
import styles from './Tooltip.module.css'

function Tooltip({ text, children }) {
    const [isVisible, setIsVisible] = useState(false);

    const showTooltip = () => {
        setIsVisible(true);
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    return (
        <div className={styles.tooltipContainer} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {children}
            {isVisible && <div className={styles.tooltip}>{text}</div>}
        </div>
    );
}

export default Tooltip;
