/* Feature - service / api call for asset

Created by - Mona R
Updated by - Janaki J */

import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

function getAssetData(data) {
  // console.log(data[2]);
  return serviceBase.get(
    CONSTANTS.ASSET_GET_ALL + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`
  );
}

function getAssetById(id, data) {
  // console.log(id, data);
  return serviceBase.get(CONSTANTS.ASSET_BYSITEID + id + `?type=${data}`)
}

function deleteAssetData(id) {
  // console.log("id", id);
  return serviceBase.delete(CONSTANTS.ASSET_DELETE + id[3]);
}

function addBulkAsset(data) {
  // console.log('bulk upload service data', data[0]);
  // console.log('bulk upload service data', data[1]);
  return serviceBase.post(CONSTANTS.ASSET_BULK_ADD + data[0], data[1]);
}

function addAssetData(data) {
  // console.log(data);
  return serviceBase.post(CONSTANTS.ASSET_ADD, data);
}

function updateAsset(data) {
  return serviceBase.patch(CONSTANTS.ASSET_UPDATE + data[0], data[1]);
}

function getSingleAsset(id) {
  return serviceBase.get(CONSTANTS.ASSET_GET_SINGLE + id);
}

function SOPattachment(data) {
  // console.log(data);
  // console.log(CONSTANTS.SOP_ATTACHMENT + data[0] + `?type=${data[1]}`);
  return serviceBase.patch(CONSTANTS.SOP_ATTACHMENT + data[0] + `?type=${data[1]}`, data[2]);
}

export const assetDetails = {
  getAssetData,
  getAssetById,
  deleteAssetData,
  addAssetData,
  addBulkAsset,
  updateAsset,
  getSingleAsset,
  SOPattachment,
};
