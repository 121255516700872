import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'

function BoilerDaily({ responseData,filterData,malur,logo }) {
  const [resData, setResData] = useState([])


  const data1 = {
    data: "MAINTENANCE",
    colSpan: '6'
  }
  const data2 = {
    data: "MAINT/036:F4-01"
  }
  const data3 = {
    data: `BOILER LOG 850 KGS. FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
    colSpan: '7'
  }
  const data4 = [
    { data: 'Steam', colSpan: '2', rowSpan: '1', textAlign: 'center' },
    { data: 'Diesel', colSpan: '2', rowSpan: '1', textAlign: 'center' },

  ]
  const data5 = [
    { data: 'Date', colSpan: '2', rowSpan: '2', textAlign: 'center' },
    { data: 'ON Time', colSpan: '2', rowSpan: '2', textAlign: 'center' },
    { data: 'OFF Time', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Reading at', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Steam Temp (°C)', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Pressure (Kgs.)', textAlign: 'center', colSpan: '4', rowSpan: '1', },
    { data: 'Water Quality (NMT 5 ppm)', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Diesel Level (Ltrs)', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Water Level', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Blow down Time NMT 30secs.', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Softner regeneration done on', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'Checked By', textAlign: 'center', colSpan: '2', rowSpan: '2', }
  ]

  const tableResData = resData?.checklist?.map(item => ({
    data: {
      value: item?.start_date && item?.start_date!==undefined? moment(item?.start_date).local().format("DD/MM/YY"):'-',  // received email on 31st jan to maintain this date format.
      style: { textAlign: 'left', width: '150px',colSpan:'2'},
    },
    data1: {
      value: item?.checkpoints[0]?.answer!==''?item?.checkpoints[0]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' }, // Define inline style here
    },
    data2: {
      value: item?.checkpoints[1]?.answer!==''?item?.checkpoints[1]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' }, // Define inline style here
    },
    data3: {
      value: item?.checkpoints[2]?.answer!==''?item?.checkpoints[2]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data4: {
      value: item?.checkpoints[3]?.answer!==''?item?.checkpoints[3]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data5: {
      value: item?.checkpoints[4]?.answer!==''?item?.checkpoints[4]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data6: {
      value: item?.checkpoints[5]?.answer!==''?item?.checkpoints[5]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data7: {
      value: item?.checkpoints[6]?.answer!==''?item?.checkpoints[6]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data8: {
      value: item?.checkpoints[7]?.answer!==''?item?.checkpoints[7]?.answer:'-',
      style: { textAlign: 'center' ,colSpan:'2'},
    },
    data9: {
      value: item?.checkpoints[8]?.answer!==''?item?.checkpoints[8]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data10: {
      value: item?.checkpoints[9]?.answer!==''?item?.checkpoints[9]?.answer:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data11: {
      value: item?.checkpoints[10]?.answer!==''?item?.checkpoints[10]?.answer:'-',
      style: { textAlign: 'center',colSpan:'3' },
    },
    data12: {
      value: item?.performed_by?.length>0?item?.performed_by:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
    data13: {
      value: item?.ins_approval_id?.length>0?item?.ins_approval_id:'-',
      style: { textAlign: 'center',colSpan:'2' },
    },
  }));


  useEffect(() => {
    setResData(responseData)
  }, [responseData])

  return (
    <div>
      <ReportHearder
        data1={data1}
        data2={data2}
        data3={data3}
        custLogo={logo}
        malur={malur}
      />
      <ReportTable
        headerData={data5}
        headerData2={data4}
      />
      <ReportTable
        verticalBodyData={tableResData}
      />
    </div>
  )
}

export default BoilerDaily