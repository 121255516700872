/* Feature - saga for history details

Created by - Janaki J
Updated by - Janaki J */

import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  HISTRY_GET_LIST,
  HISTRY_GET_SUCCESS,
  HISTRY_GET_FAILED,
} from "../actions/historyAction";
import { historyService } from "../../services/service.index";

// export function for saga activity
export function* watchGetHistory() {
  yield takeLatest(HISTRY_GET_LIST, getHistorySaga);
}

export function* getHistorySaga(action) {
  // console.log('action', action.payload);
  try {
    const response = yield call(historyService.getHistoryData, action.payload);
    // console.log('response', response.data);
    if (response.status === 200) {
      yield put({
        type: HISTRY_GET_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: HISTRY_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log('err', e);
    yield put({
      type: HISTRY_GET_FAILED,
    });
  }
}
