/* Feature - common component for loader

Created by - Mona R
Updated by - Janaki */

//importing dependencies and packages
import React from "react";
import "../loader/loader.css";
import IMAGENAME from "../../medias/media.index";

function Loader() {
  return (
    <div
      className="loader-container"
      id="preloader"
      style={{ background: "rgba(0,0,0,0.2)", width: "100%", height: "100%" }}
    >
      <div>
        <div className="loader">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img src={IMAGENAME.loaderLogo} />
          <div className="" style={{ display: "flex" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your gateway
            to <br></br>a smart and intuitive space
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
