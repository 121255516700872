/* Feature - service / api call for feature access

Created by - Mona R
Updated by - Mona R */

import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

function getAccessByRoleCode(rolecode) {
  return serviceBase.get(CONSTANTS.ACCESS_GET + rolecode);
}

function getAccessRole() {
  return serviceBase.get(CONSTANTS.ACCESS_ROLE_LIST);
}

function getAllAccessByRoleCode(rolecode) {
  // console.log(CONSTANTS.ACCESS_GET_ALL + rolecode);
  return serviceBase.get(CONSTANTS.ACCESS_GET_ALL + rolecode);
}

function createCustomRole(data) {
  // console.log(data);
  return serviceBase.post(CONSTANTS.CREATE_CUSTOM_ROLE, data);
}

export const accessDetails = {
  getAccessByRoleCode,
  getAccessRole,
  getAllAccessByRoleCode,
  createCustomRole,
};