/* Feature - Reducer for access

Created by - Mona R
Updated by - Mona R */

import { call, put, takeLatest } from "@redux-saga/core/effects";
import { accessService } from "../../services/service.index";

import {
  ACCESS_GET_LIST,
  ACCESS_GET_SUCCESS,
  ACCESS_GET_FAILED,

  ROLE_GET_LIST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILED,

} from '../actions/accessAction';


export function* watchGetAccess() {
  yield takeLatest(ACCESS_GET_LIST, getAccessSaga);
}
export function* watchGetRoleList() {
  yield takeLatest(ROLE_GET_LIST, getRoleListSaga);
}

export function* getAccessSaga(action) {
  try {
    //   console.log("getAccessSaga action", action)
    const response = yield call(accessService.getAccessByRoleCode, action.payload);
    if (response.status === 200) {
      // console.log("getAccessData response", response.data)
      yield put({
        type: ACCESS_GET_SUCCESS,
        payload: response.data,
      });
    }
    else {
      //   console.log("getAccessData Error response", response)
      yield put({
        type: ACCESS_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log("getAccessData Error response", e)
    yield put({
      type: ACCESS_GET_FAILED,
    });
  }
}
export function* getRoleListSaga(action) {
  try {
    //   console.log("getAccessSaga action", action)
    const response = yield call(accessService.getAccessRole, action.payload);
    if (response.status === 200 || response.status === 201) {
      // console.log("getAccessData response", response.data)    
      yield put({
        type: ROLE_GET_SUCCESS,
        payload: response.data,
      });
    }
    else {
      //   console.log(response)
      yield put({
        type: ROLE_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log(e)
    yield put({
      type: ROLE_GET_FAILED,
    });
  }
}
