// View Maintenance

/* Author - Janaki */
import React, { useState, useEffect } from "react";
import UpdateMaintenance from "../updateMaintainance";

const MaintenanceView = (props) => {

  return (
    <div>
      <UpdateMaintenance
        action={'ins_view'}
      />
    </div>
  );
};

export default MaintenanceView;
