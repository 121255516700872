//Feature - Asset header for asset, device & facility

//Created by - Mona R
//Updated by - Mona R

//importing dependencies and packages
import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, Navigate, useParams } from "react-router-dom";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import Logo from "../../components/logo";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


function AssetHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState("asset");

  const role = useSelector((state) => state.auth.role);
  const accessData = useSelector((state) => state.access.accessData);
  const accessAsset = useSelector((state) => state.access.asset);
  const accessDevice = useSelector((state) => state.access.device);
  const accessFacility = useSelector((state) => state.access.facility);
  const accessplant = useSelector((state) => state.access.plant);
  // console.log(accessplant);
  const currentURL = window.location.href;
  // console.log("currentURL", currentURL);

  useEffect(() => {
    if (currentURL?.includes('asset')) {
      setSelectedTab("asset")
    }
    if (currentURL?.includes('device')) {
      setSelectedTab("device")
    } if (currentURL?.includes('facility')) {
      setSelectedTab("facility")
    } if (currentURL?.includes('plant')) {
      setSelectedTab("plant")
    }
  }, [selectedTab, currentURL])

  // to ger user access for each feature


  useEffect(() => {
    const roleCode = role[0];

    if (accessData === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [accessData]);

  return (
    <div>
      <Navbar bg="light" variant={"light"} expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="headershadow">
          <Nav style={{ maxHeight: "100px" }} id="controlled-tab-example">
            {/* to show sidebar with header */}
            <Sidebar />
            <Logo />
            {accessAsset !== null && accessAsset?.u ? (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/assetlist"
                  onClick={() => setSelectedTab("asset")}
                  className="position-absolute top-25 end-0 header"
                  style={{
                    color: "#000", marginRight: "600px",
                    borderRadius: '10px',
                    backgroundColor: selectedTab === "asset" ? "#64FFE2" : "",
                  }}
                >
                  Asset
                </Nav.Link>
              </Nav.Item>
            ) : (
              <div></div>
            )}
            {accessDevice !== null && accessDevice?.u ? (
              <Nav.Item>
                <Nav.Link
                  onClick={() => setSelectedTab("device")}
                  as={Link}
                  to="/devicelist"
                  className="position-absolute top-25 end-0 header"
                  style={{
                    color: "#000", marginRight: "500px",
                    borderRadius: '10px',
                    backgroundColor: selectedTab === "device" ? "#64FFE2" : "",
                  }}
                >
                  Device
                </Nav.Link>
              </Nav.Item>
            ) : (
              <div></div>
            )}
            {accessFacility !== null && accessFacility?.u ? (
              <Nav.Item>
                <Nav.Link
                  onClick={() => setSelectedTab("facility")}
                  as={Link}
                  to="/facilitylist"
                  className="position-absolute top-25 end-0 header"
                  style={{
                    color: "#000", marginRight: "400px",
                    borderRadius: '10px',
                    backgroundColor: selectedTab === "facility" ? "#64FFE2" : ""
                  }}
                >
                  Facility
                </Nav.Link>
              </Nav.Item>
            ) : (
              <div></div>
            )}
            {accessplant !== null && accessplant?.r ? (
              <Nav.Item>
                <Nav.Link
                  onClick={() => setSelectedTab("plant")}
                  as={Link}
                  to="/plantlist"
                  className="position-absolute top-25 end-0 header"
                  style={{
                    color: "#000", marginRight: "300px",
                    borderRadius: '10px',
                    backgroundColor: selectedTab === "plant" ? "#64FFE2" : "",
                  }}
                >
                  Plant
                </Nav.Link>
              </Nav.Item>
            ) : (
              <div></div>
            )}
            {/* Logout button */}
            <LogoutButton />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default AssetHeader;
