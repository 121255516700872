/* Feature - Create of user

Created by - Mona R
Updated by - Janaki J */

import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { USER_ADD } from "../../redux/actions/userAction";
import Loader from "../../components/loader/loader";
import Userheader from "../../layouts/userRole/userHeader";

const UserCreation = () => {
  const [selected, setSelected] = useState("");

  // To fetch details from redux
  const isLoading = useSelector((state) => state.user.isLoading);
  const roleData = useSelector((state) => state.user.roleData);
  // console.log(roleData);
  const subscriber_id = useSelector((state) => state.auth.subscriber_id);
  const navigate = useNavigate();

  // Bulk upload button with modal feature
  const inputRef = useRef();

  // To fetch details from redux
  const dispatch = useDispatch();
  const isSubmitted = useSelector((state) => state.user.isSubmitted);
  const userid = useSelector((state) => state.auth.userid);

  // state to update the form data
  const [data, setData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    countyCode: "",
    mobNumber: "",
    role: "",
    designation: "",
    dept: "",
  });
  const [valid, setValid] = useState({
    firstName: true,
    middleName: true,
    lastName: true,
    email: true,
    country_code: true,
    mobNumber: true,
    role: true,
    designation: true,
    dept: true,
  });
  //Functions to handle form inputs
  const handleFname = (val) => {
    setData({
      ...data,
      firstName: val,
    });
  };

  const handleMname = (val) => {
    setData({
      ...data,
      middleName: val,
    });
  };
  const handleLname = (val) => {
    setData({
      ...data,
      lastName: val,
    });
  };
  const handleMobnum = (val) => {
    setData({
      ...data,
      mobNumber: val,
    });
  };
  const handleCode = (val) => {
    setData({
      ...data,
      countyCode: val,
    });
  };
  const handleDept = (val) => {
    setData({
      ...data,
      dept: val,
    });
  };
  const handleDesigntion = (val) => {
    setData({
      ...data,
      designation: val,
    });
  };
  const handleRole = (val) => {
    // console.log(val);
    setData({
      ...data,
      role: val,
    });
  };
  const handleEmail = (val) => {
    setData({
      ...data,
      email: val,
    });
  };

  function containSpeclChars(str) {
    const specialChars = /^([a-zA-Z]+\s)*[a-zA-Z&]+$/;
    return specialChars.test(str);
  }

  function emailSpeclChars(str) {
    // const emailspecialChars = /^[a-z0-9._]+@[a-z]+(?:\.[a-zA-Z]+)*$/;
    // const emailspecialChars =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const emailspecialChars = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$/;
    return emailspecialChars.test(str);
  }

  function mobileChars(str) {
    const mobilespecialChars = /^\+?[1-9][0-9]{9}$/;
    return mobilespecialChars.test(str);
  }

  function designationChars(str) {
    const designationspecialChars = /^([a-zA-Z0-9-]+\s)*[a-zA-Z0-9-]+$/;
    return designationspecialChars.test(str);
  }

  // console.log(containsSpecialChars(data.firstName));
  const array = [
    "admin",
    "senior_executive",
    "head_of_department",
    "manager",
    "engineer",
    "supervisor",
    "technician",
  ];

  const containAll = roleData?.every((item) => {
    return array.includes(item);
  });

  // console.log(containAll)

  if (containAll) {
    roleData?.map((eachdata) => {
      eachdata.role_name = eachdata.role_name;
    });
  }

  roleData?.map((eachdata) => {
    if (eachdata.role_name === "admin") {
      eachdata.role_name = "Admin";
    }
    if (eachdata.role_name === "senior_executive") {
      eachdata.role_name = "Senior Executive";
    }
    if (eachdata.role_name === "head_of_department") {
      eachdata.role_name = "Head of Department";
    }
    if (eachdata.role_name === "manager") {
      eachdata.role_name = "Manager";
    }
    if (eachdata.role_name === "engineer") {
      eachdata.role_name = "Engineer";
    }
    if (eachdata.role_name === "supervisor") {
      eachdata.role_name = "Supervisor";
    }
    if (eachdata.role_name === "technician") {
      eachdata.role_name = "Technician";
    }
  });

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //client-side validation
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [mnumberErr, setmnumberErr] = useState("");
  const [deptErr, setdeptErr] = useState("");
  const [designationErr, setdesignationErr] = useState("");
  const [roleErr, setRoleErr] = useState("");

  let isValid = true;
  const formValidation = () => {
    const firstNameErr = {};
    const lastNameErr = {};
    const emailErr = {};
    const mnumberErr = {};
    const deptErr = {};
    const designationErr = {};
    const roleErr = {};

    if (data.firstName.trim().length < 1) {
      firstNameErr.firstNameShort = "Please Enter First Name";
      isValid = false;
    }
    setFirstNameErr(firstNameErr);

    if (data.lastName.trim().length < 1) {
      lastNameErr.lastNameShort = "Please Enter Last Name";
      isValid = false;
    }
    setLastNameErr(lastNameErr);

    if (!data.email || regex.test(data.email) === false) {
      emailErr.emailShort = "Please Enter Valid Email ID";
      isValid = false;
    }
    setEmailErr(emailErr);

    if (data.mobNumber.trim().length !== 10) {
      mnumberErr.mobShort = "Please Enter Valid Mobile number";
      isValid = false;
    }
    setmnumberErr(mnumberErr);

    if (data.dept.trim().length < 1) {
      deptErr.deptShort = "Please Enter Department";
      isValid = false;
    }
    setdeptErr(deptErr);

    if (data.designation.trim().length < 1) {
      designationErr.deptShort = "Please Enter Designation";
      isValid = false;
    }
    setdesignationErr(designationErr);

    if (data.role.trim().length < 1) {
      // console.log(data.role);
      roleErr.roleShort = "Please Select Role";
      isValid = false;
    }
    setRoleErr(roleErr);

    return isValid;
  };

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };
  // Function to upload user details
  const submitUserdetails = (e) => {
    e.preventDefault();
    let userData = {
      subscriber_id: subscriber_id,
      first_name: data.firstName,
      middle_name: data.middleName,
      last_name: data.lastName,
      email: data.email,
      country_code: data.countyCode,
      mobile_number: data.mobNumber,
      department: data.dept,
      designation: data.designation,
      role: data.role,
      created_by: userid,
    };
    validation();
    if (emailSpeclChars && !isValid) {
      alert(`Please enter valid data`);
    }
    if (isValid) {
      dispatch({
        type: USER_ADD,
        payload: userData,
      });
    }
  };

  useEffect(() => {
    if (roleData?.length === 0) {
      dispatch({
        type: "ROLE_GET_LIST",
      });
    }
    if (isSubmitted) {
      navigate("/user/list");
    }
  }, [roleData, isSubmitted]);

  return (
    <div>
      {isLoading && <Loader />} {/* to show loading effect */}
      <Userheader />
      <div className="container">
        <div className="row h-100">
          <div className="col-md-6 offset-md-3">
            <br />
            <form
              action=""
              method="post"
              className="mt-5 border p-4 bg-light shadow rounded"
              target="#"
            >
              {" "}
              <div className="formHeadings mb-2"> Create User</div>
              <div className="row">
                <div className="mb-2 col-md-6">
                  <label>
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="fname"
                    className="form-control"
                    placeholder="Enter First Name"
                    value={data.firstName}
                    onChange={(e) => handleFname(e.target.value)}
                    // onKeyDown={(e) => containSpeclChars(e.target.value)}
                    required
                  />
                  {Object.keys(firstNameErr).map((key, i) => {
                    if (!data.firstName) {
                      return (
                        <div key={i} style={{ color: "red", fontSize: "12px" }}>
                          {firstNameErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {containSpeclChars(data.firstName) ||
                  data.firstName === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid first name
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    name="mname"
                    value={data.middleName}
                    className="form-control"
                    placeholder="Enter Middle Name"
                    onChange={(e) => handleMname(e.target.value)}
                  />
                  {containSpeclChars(data.middleName) ||
                  data.middleName === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid middle name
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label>
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="Lname"
                    value={data.lastName}
                    className="form-control"
                    placeholder="Enter Last Name"
                    onChange={(e) => handleLname(e.target.value)}
                    required
                  />
                  {Object.keys(lastNameErr).map((key, i) => {
                    if (!data.lastName) {
                      return (
                        <div key={i} style={{ color: "red", fontSize: "12px" }}>
                          {lastNameErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {containSpeclChars(data.lastName) || data.lastName === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid last Name
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label>
                    Email/User ID<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="userID"
                    value={data.email}
                    className="form-control"
                    placeholder="Enter Email ID"
                    onChange={(e) => handleEmail(e.target.value)}
                    ref={inputRef}
                    required
                  />
                  {Object.keys(emailErr).map((key, i) => {
                    if (!data.email) {
                      return (
                        <div key={i} style={{ color: "red", fontSize: "12px" }}>
                          {emailErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {emailSpeclChars(data.email) || data.email === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid email
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label htmlFor="Mnumber">
                    Mobile Number
                    <span className="text-danger">*</span>
                  </label>

                  <div className="input-group">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={data.countyCode}
                        onChange={(e) => handleCode(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                      </select>
                      <input
                        style={{
                          borderRadius: "4px",
                          height: "32px",
                          width: "auto",
                        }}
                        name="Mnumber"
                        value={data.mobNumber}
                        className="form-control"
                        id="Mnumber"
                        minLength={0}
                        maxLength={10}
                        placeholder="Enter Mobile"
                        onChange={(e) => handleMobnum(e.target.value)}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        required
                      />
                    </div>
                  </div>
                  {Object.keys(mnumberErr).map((key, i) => {
                    if (!data.mobNumber) {
                      return (
                        <div key={i} style={{ color: "red" }}>
                          {mnumberErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {mobileChars(data.mobNumber) || data.mobNumber === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid Mobile number
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label>
                    Department<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="department"
                    value={data.dept}
                    className="form-control"
                    placeholder="Enter Deparment"
                    onChange={(e) => handleDept(e.target.value)}
                    required
                  />
                  {Object.keys(deptErr).map((key, i) => {
                    if (!data.dept) {
                      return (
                        <div key={i} style={{ color: "red", fontSize: "12px" }}>
                          {deptErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {containSpeclChars(data.dept) || data.dept === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid Deparment name
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label>
                    Designation<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="designation"
                    value={data.designation}
                    className="form-control"
                    placeholder="Enter Designation"
                    onChange={(e) => handleDesigntion(e.target.value)}
                    required
                  />
                  {Object.keys(designationErr).map((key, i) => {
                    if (!data.designation) {
                      return (
                        <div key={i} style={{ color: "red", fontSize: "12px" }}>
                          {designationErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {designationChars(data.designation) ||
                  data.designation === "" ? (
                    " "
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        paddingTop: "2px",
                      }}
                    >
                      Invalid Designation name
                    </div>
                  )}
                </div>
                <div className="mb-2 col-md-6">
                  <label>
                    Role
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    placeholder="Select"
                    name="role"
                    className="form-select"
                    value={data.role}
                    onChange={(e) => handleRole(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {roleData?.length !== 0 &&
                      roleData?.map((eachData, index) => {
                        return (
                          <option value={eachData.role_code} key={eachData._id}>
                            {eachData.role_name}
                          </option>
                        );
                      })}
                  </select>
                  {Object.keys(roleErr).map((key, i) => {
                    if (!data.role) {
                      return (
                        <div key={i} style={{ color: "red", fontSize: "12px" }}>
                          {roleErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                </div>
                <div className="mt-4 col-md-12">
                  <button
                    type="button"
                    className="backButton"
                    onClick={() => {
                      navigate("/user/list");
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="float-end saveButton"
                    onClick={(e) => submitUserdetails(e)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreation;
