import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../components/loader/loader";

import LocationHeader from "../../layouts/FA-layouts/LocationHeader";
import axios from "axios";

const CreateCostCenter = () => {
    const [selected, setSelected] = useState("");

    // To fetch details from redux
    const isLoading = useSelector((state) => state.user.isLoading);
    const roleData = useSelector((state) => state.user.roleData);
    const userid = useSelector((state) => state.auth.userid);
    // console.log(roleData);
    const subscriber_id = useSelector((state) => state.auth.subscriber_id);
    const navigate = useNavigate();

    // Bulk upload button with modal feature
    const inputRef = useRef();

    // To fetch details from redux
    const dispatch = useDispatch();
    const isSubmitted = useSelector((state) => state.user.isSubmitted);
    const [code, setCode] = useState()
    const [costcenter, setCostCenter] = useState()
    // const [costcenter, setcostcenter] = useState()
    const [description, setDescription] = useState()
    // const [life, setLife] = useState()
    const [rate, setRate] = useState()
    const submitLocationdetails = (e) => {
        let payload = {
            code,
            name: costcenter,
            description,
            created_by: userid
        }

        if (!code || !costcenter) {
            alert("Please enter mandatory fields")
        } else {
            dispatch({
                type: "ADD_COST_CENTER",
                payload: payload,
            })
            navigate("/costcenter")
        }
        // try {
        //     axios.post("http://localhost:8000/fa/location", payload)
        // } catch (error) {

        // }
    }

    useEffect(() => {

    }, []);

    return (
        <div>
            {isLoading && <Loader />} {/* to show loading effect */}

            <div className="container">
                <div className="row h-100">
                    <div className="col-md-6 offset-md-3">
                        <br />
                        {/* <form
                            action=""
                            method="post"
                           
                            target="#"
                        > */}
                        {" "}
                        <div className="mt-5 border p-4 bg-light shadow rounded">
                            <div className="formHeadings mb-2"> Add Cost Center</div>
                            <div className="row">
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Cost Center Code<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="lcode"
                                        className="form-control"
                                        onChange={(e) => setCode(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Cost Center Name<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="lcode"
                                        className="form-control"
                                        onChange={(e) => setCostCenter(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>Description</label>
                                    <input
                                        type="text"
                                        name="lname"
                                        className="form-control"
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                                {/* <div className="mb-2 col-md-6">
                                    <label>
                                        Rate<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        name="laddress"
                                        className="form-control"
                                        onChange={(e) => setRate(e.target.value)}
                                        required
                                        onKeyPress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                                        maxLength={3}
                                    />
                                </div> */}
                                <div className="mt-4 col-md-12">
                                    <button
                                        type="button"
                                        className="backButton"
                                        onClick={() => {
                                            navigate("/costcenter");
                                        }}
                                    >
                                        Back
                                    </button>
                                    <button
                                        type="submit"
                                        className="float-end saveButton"
                                        onClick={(e) => submitLocationdetails(e)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCostCenter;
