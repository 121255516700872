const CWIP_GET_LIST = 'CWIP_GET_LIST';
const CWIP_GET_SUCCESS = 'CWIP_GET_SUCCESS';
const CWIP_GET_FAILED = 'CWIP_GET_FAILED';

const ADD_CAPITALISE = 'ADD_CAPITALISE';
const ADD_CAPITALISE_SUCCESS = "ADD_CAPITALISE_SUCCESS";
const ADD_CAPITALISE_FAILED = "ADD_CAPITALISE_FAILED";

const ADD_EXPENSE = 'ADD_EXPENSE';
const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
const ADD_EXPENSE_FAILED = "ADD_EXPENSE_FAILED";

const EXPENSE_GET_LIST = 'EXPENSE_GET_LIST';
const EXPENSE_GET_SUCCESS = 'EXPENSE_GET_SUCCESS';
const EXPENSE_GET_FAILED = 'EXPENSE_GET_FAILED';

const CAPITAL_GET_LIST = 'CAPITAL_GET_LIST';
const CAPITAL_GET_SUCCESS = 'CAPITAL_GET_SUCCESS';
const CAPITAL_GET_FAILED = 'CAPITAL_GET_FAILED';

const ADD_LOCATION = 'ADD_LOCATION';
const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
const ADD_LOCATION_FAILED = "ADD_LOCATION_FAILED";

const GET_LOCATION_LIST = 'GET_LOCATION_LIST';
const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
const GET_LOCATION_FAILED = "GET_LOCATION_FAILED";

const ADD_CLASS = 'ADD_CLASS';
const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
const ADD_CLASS_FAILED = "ADD_CLASS_FAILED";

const GET_CLASS_LIST = 'GET_CLASS_LIST';
const GET_CLASS_SUCCESS = "GET_CLASS_SUCCESS";
const GET_CLASS_FAILED = "GET_CLASS_FAILED";

const GET_CALCULATION = 'GET_CALCULATION';
const GET_CALCULATION_SUCCESS = "GET_CALCULATION_SUCCESS";
const GET_CALCULATION_FAILED = "GET_CALCULATION_FAILED";

const CREATE_FAASSET = 'CREATE_FAASSET';
const CREATE_FAASSET_SUCCESS = "CREATE_FAASSET_SUCCESS";
const CREATE_FAASSET_FAILED = "CREATE_FAASSET_FAILED";

const ADD_BULK_FAASSET = 'ADD_BULK_FAASSET';
const ADD_BULK_FAASSET_SUCCESS = "ADD_BULK_FAASSET_SUCCESS";
const ADD_BULK_FAASSET_FAILED = "ADD_BULK_FAASSET_FAILED";

const ADD_DISPOSE = 'ADD_DISPOSE';
const ADD_DISPOSE_SUCCESS = "ADD_DISPOSE_SUCCESS";
const ADD_DISPOSE_FAILED = "ADD_DISPOSE_FAILED";

const FAASSET_UPDATE = "FAASSET_UPDATE";
const FAASSET_UPDATE_SUCCESS = "FAASSET_UPDATE_SUCCESS";
const FAASSET_UPDATE_FAILED = "FAASSET_UPDATE_FAILED";

const ADD_COST_CENTER = 'ADD_COST_CENTER';
const ADD_COST_CENTER_SUCCESS = "ADD_COST_CENTER_SUCCESS";
const ADD_COST_CENTER_FAILED = "ADD_COST_CENTER_FAILED";

const GET_COST_CENTER_LIST = 'GET_COST_CENTER_LIST';
const GET_COST_CENTER_SUCCESS = "GET_COST_CENTER_SUCCESS";
const GET_COST_CENTER_FAILED = "GET_COST_CENTER_FAILED";

const GET_BU_CODE_LIST = 'GET_BU_CODE_LIST';
const GET_BU_CODE_SUCCESS = "GET_BU_CODE_SUCCESS";
const GET_BU_CODE_FAILED = "GET_BU_CODE_FAILED";

const GET_ADDITION_DELETION = 'GET_ADDITION_DELETION';
const GET_ADDITION_DELETION_SUCCESS = "GET_ADDITION_DELETION_SUCCESS";
const GET_ADDITION_DELETION_FAILED = "GET_ADDITION_DELETION_FAILED";

const SALE_ASSET = "SALE_ASSET";
const SALE_ASSET_SUCCESS = "SALE_ASSET_SUCCESS";
const SALE_ASSET_FAILED = "SALE_ASSET_FAILED";

const GET_VERIFIED_LIST = "GET_VERIFIED_LIST";
const GET_VERIFIED_LIST_SUCCESS = "GET_VERIFIED_LIST_SUCCESS";
const GET_VERIFIED_LIST_FAILED = "GET_VERIFIED_LIST_FAILED";

const GET_REPORT_DATA = "GET_REPORT_DATA";
const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
const GET_REPORT_DATA_FAILED = "GET_REPORT_DATA_FAILED";

const ADD_SAMPLE_LIST = "ADD_SAMPLE_LIST";
const ADD_SAMPLE_LIST_SUCCESS = "ADD_SAMPLE_LIST_SUCCESS";
const ADD_SAMPLE_LIST_FAILED = "ADD_SAMPLE_LIST_FAILED"

const GET_SAMPLE_LIST = "GET_SAMPLE_LIST";
const GET_SAMPLE_LIST_SUCCESS = "GET_SAMPLE_LIST_SUCCESS";
const GET_SAMPLE_LIST_FAILED = "GET_SAMPLE_LIST_FAILED"

export {
    CWIP_GET_LIST,
    CWIP_GET_SUCCESS,
    CWIP_GET_FAILED,
    ADD_CAPITALISE,
    ADD_CAPITALISE_SUCCESS,
    ADD_CAPITALISE_FAILED,
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAILED,
    EXPENSE_GET_LIST,
    EXPENSE_GET_SUCCESS,
    EXPENSE_GET_FAILED,
    CAPITAL_GET_LIST,
    CAPITAL_GET_SUCCESS,
    CAPITAL_GET_FAILED,
    ADD_LOCATION,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAILED,
    GET_LOCATION_LIST,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAILED,
    ADD_CLASS,
    ADD_CLASS_SUCCESS,
    ADD_CLASS_FAILED,
    GET_CLASS_LIST,
    GET_CLASS_SUCCESS,
    GET_CLASS_FAILED,
    GET_CALCULATION,
    GET_CALCULATION_SUCCESS,
    GET_CALCULATION_FAILED,
    CREATE_FAASSET,
    CREATE_FAASSET_SUCCESS,
    CREATE_FAASSET_FAILED,
    ADD_BULK_FAASSET,
    ADD_BULK_FAASSET_SUCCESS,
    ADD_BULK_FAASSET_FAILED,
    ADD_DISPOSE,
    ADD_DISPOSE_SUCCESS,
    ADD_DISPOSE_FAILED,
    FAASSET_UPDATE,
    FAASSET_UPDATE_SUCCESS,
    FAASSET_UPDATE_FAILED,
    ADD_COST_CENTER,
    ADD_COST_CENTER_SUCCESS,
    ADD_COST_CENTER_FAILED,
    GET_COST_CENTER_LIST,
    GET_COST_CENTER_SUCCESS,
    GET_COST_CENTER_FAILED,
    GET_BU_CODE_LIST,
    GET_BU_CODE_SUCCESS,
    GET_BU_CODE_FAILED,
    GET_ADDITION_DELETION,
    GET_ADDITION_DELETION_SUCCESS,
    GET_ADDITION_DELETION_FAILED,
    SALE_ASSET,
    SALE_ASSET_SUCCESS,
    SALE_ASSET_FAILED,
    GET_VERIFIED_LIST,
    GET_VERIFIED_LIST_SUCCESS,
    GET_VERIFIED_LIST_FAILED,
    GET_REPORT_DATA,
    GET_REPORT_DATA_SUCCESS,
    GET_REPORT_DATA_FAILED,
    ADD_SAMPLE_LIST,
    ADD_SAMPLE_LIST_SUCCESS,
    ADD_SAMPLE_LIST_FAILED,
    GET_SAMPLE_LIST,
    GET_SAMPLE_LIST_SUCCESS,
    GET_SAMPLE_LIST_FAILED
}