/* Feature - action list for inspection

Created by - Mona R
Updated by - Mona R */

// action to create single Inspection.
const INSPECTION_ADD = "INSPECTION_ADD";
const INSPECTION_ADD_SUCCESS = "INSPECTION_ADD_SUCCESS";
const INSPECTION_ADD_FAILED = "INSPECTION_ADD_FAILED";

// action to get all inspection details from API
const INSPECTION_GET_LIST = 'INSPECTION_GET_LIST';
const INSPECTION_GET_SUCCESS = 'INSPECTION_GET_SUCCESS';
const INSPECTION_GET_FAILED = 'INSPECTION_GET_FAILED';

// action to update inspection details from API
const INSPECTION_UPDATE = "INSPECTION_UPDATE";
const INSPECTION_UPDATE_SUCCESS = "INSPECTION_UPDATE_SUCCESS";
const INSPECTION_UPDATE_FAILED = "INSPECTION_UPDATE_FAILED";

// action to get single inspection details from API
const INSPECTION_GET_SINGLE = "INSPECTION_GET_SINGLE";
const INSPECTION_GET_SINGLE_SUCCESS = "INSPECTION_GET_SINGLE_SUCCESS";
const INSPECTION_GET_SINGLE_FAILED = "INSPECTION_GET_SINGLE_FAILED";

// action to assign inspection 
const INSPECTION_ASSIGN_LIST = 'INSPECTION_ASSIGN_LIST';
const INSPECTION_ASSIGN_SUCCESS = 'INSPECTION_ASSIGN_SUCCESS';
const INSPECTION_ASSIGN_FAILED = 'INSPECTION_ASSIGN_FAILED';

// action to delete Inspection details.
const INSPECTION_DELETE_LIST = 'INSPECTION_DELETE_LIST';
const INSPECTION_DELETE_SUCCESS = 'INSPECTION_DELETE_SUCCESS';
const INSPECTION_DELETE_FAILED = 'INSPECTION_DELETE_FAILED';

// action to create bulk Inspection.
const INSPECTION_BULK_ADD = 'INSPECTION_BULK_ADD';
const INSPECTION_BULK_ADD_SUCCESS = 'INSPECTION_BULK_ADD_SUCCESS';
const INSPECTION_BULK_ADD_FAILED = 'INSPECTION_BULK_ADD_FAILED';

// action for Inspection report.
const INSPECTION_REPORT = "INSPECTION_REPORT";
const INSPECTION_REPORT_SUCCESS = "INSPECTION_REPORT_SUCCESS";
const INSPECTION_REPORT_FAILED = "INSPECTION_REPORT_FAILED";

// action for Inspection status.
const INSPECTION_STATUS = "INSPECTION_STATUS";
const INSPECTION_GET_STATUS_SUCCESS = "INSPECTION_GET_STATUS_SUCCESS";
const INSPECTION_GET_STATUS_FAILED = "INSPECTION_GET_STATUS_FAILED";

// action for Inspection status.
const INSPECTION_STOP = "INSPECTION_STOP";
const INSPECTION_STOP_SUCCESS = "INSPECTION_STOP_SUCCESS";
const INSPECTION_STOP_FAILED = "INSPECTION_STOP_FAILED";

export {
  INSPECTION_ADD,
  INSPECTION_ADD_SUCCESS,
  INSPECTION_ADD_FAILED,

  INSPECTION_GET_LIST,
  INSPECTION_GET_SUCCESS,
  INSPECTION_GET_FAILED,

  INSPECTION_GET_SINGLE,
  INSPECTION_GET_SINGLE_SUCCESS,
  INSPECTION_GET_SINGLE_FAILED,

  INSPECTION_UPDATE,
  INSPECTION_UPDATE_SUCCESS,
  INSPECTION_UPDATE_FAILED,

  INSPECTION_ASSIGN_LIST,
  INSPECTION_ASSIGN_SUCCESS,
  INSPECTION_ASSIGN_FAILED,

  INSPECTION_DELETE_LIST,
  INSPECTION_DELETE_SUCCESS,
  INSPECTION_DELETE_FAILED,

  INSPECTION_BULK_ADD,
  INSPECTION_BULK_ADD_SUCCESS,
  INSPECTION_BULK_ADD_FAILED,

  INSPECTION_REPORT,
  INSPECTION_REPORT_SUCCESS,
  INSPECTION_REPORT_FAILED,

  INSPECTION_STATUS,
  INSPECTION_GET_STATUS_SUCCESS,
  INSPECTION_GET_STATUS_FAILED,

  INSPECTION_STOP,
  INSPECTION_STOP_SUCCESS,
  INSPECTION_STOP_FAILED,
};