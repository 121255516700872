import React from 'react'
import styles from '../PrivacyPolicy/PrivacyPolicy.module.css'
import IMAGENAME from "../../medias/media.index";
const BaslFooter = () => {
    return (
        <>
            {/* <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, display: 'flex', justifyContent: 'center', background: 'linear-gradient(359deg, rgb(255, 239, 190) 0.59%, rgb(255, 255, 255) 99.58%)', color: 'white' }} > */}
            {/* <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, display: 'flex', justifyContent: 'center', background: '#a37f4b', color: 'white' }} > */}
            {/* <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, display: 'flex', justifyContent: 'center', background: 'linear-gradient(180deg, rgba(224,173,80,1) 0%, rgba(165,128,55,1) 95%)', color: 'white' }} > */}
            <footer style={{ textAlign: 'center', width: "100%", bottom: 0, }}>
                {/* <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", margin: '0.3rem' }}
                        src={IMAGENAME.logo}
                        width="auto"
                        alt="logo"
                        height="18"

                    /> */}
                <p style={{ padding: "0", color: 'black', fontSize: "6px", fontFamily: "Arial", }}>
                    <span style={{ fontFamily: "Arial", fontSize: "10px" }}>  © 2024 Powered by </span>
                    <a style={{ textDecoration: "none", color: 'black', cursor: 'pointer', fontSize: "10px", fontFamily: "Arial" }} href="https://www.zongovita.com" target="_blank" rel="noopener noreferrer">
                        ZongoVita
                    </a>
                    {/* <span> v0.10.35</span> */}
                </p>
            </footer>
            {/* </div> */}
        </>

    )
}

export default BaslFooter