import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const Approvals = (props) => {
  const approvalUserData = useSelector((state) => state.user.approvalUserData);

  const { maintenanceState, setMaintenenceState, maState, maIndex, action } = props;

  // const [postApprval, setPostAppvl] = useState("");
  const [radioValue, setRadiovalue] = useState("");
  const [showhide, setShowhide] = useState("");

  const [pValue, setPvalue] = useState("");
  const [showprll, setShowprll] = useState("");

  const [sValue, setSvalue] = useState("");
  const [showSeq, setShowSeq] = useState("");

  const [allMandatValue, setAllMandatValue] = useState("");

  const [randomVal, setRandomVal] = useState("");

  const [selectedSeq, setSelectedSeq] = useState([]);
  const [selectedSeqMail, setSelectedSeqMail] = useState("");
  const [selectedParMail, setSelectedParMail] = useState([]);
  // const [prllTypeEmails, setPrllTypeEmails] = useState([]);

  // const [seqNum, setSeqNum] = useState(0)
  //useState
  const dispatch = useDispatch();

  const displayOptions = selectedSeq.map((item, index) => ({
    ...item,
    label: `${index + 1} - ${item?.label?.split(' - ').pop()}`
  }));

  function handleSeqApproval(val) {
    setSelectedSeq(val);
    let seqEmails = []
    val.forEach((item, index) => {
      seqEmails.push({
        approval_sequence: index + 1,
        approval_id: item.value,
        sequence_approval_email: item.label.split(' - ').pop(),
        user_id: item.value,
        approval_status: "waiting_for_approval"
      });
    });

    setMaintenenceState((prevState) => {
      prevState.maintenance_activities[maIndex].approvals.approval_set =
        seqEmails;
      return { ...prevState };
    });
  }

  const addParallelUser = (val) => {
    // setPrllTypeEmails(val);

    //change const keyword to let seqEmails to push more

    let allMandatEmails = [];

    val.forEach(item => {
      allMandatEmails.push({
        approval_id: item.value,
        parallel_approval_email: item.label,
        user_id: item.value,
        approval_status: "waiting_for_approval"
      });
    });

    setMaintenenceState((prevState) => {
      prevState.maintenance_activities[maIndex].approvals.approval_set =
        allMandatEmails;
      return { ...prevState };
    });
  };

  const getApprvUserDetails = () => {
    dispatch({
      type: "GET_APPROV_USERS",
    });
  };

  const checkEHS = () => {
    alert(`Please choose Operational category`);
  };

  useEffect(() => {
    if (approvalUserData.length === 0) {
      getApprvUserDetails();
    }
  }, [approvalUserData]);

  useEffect(() => {
    if (
      maintenanceState.maintenance_activities.length !== 0 &&
      maintenanceState.maintenance_activities[maIndex].approvals
        .approval_category !== undefined
    ) {
      const getRadioValues =
        maintenanceState.maintenance_activities[maIndex].approvals
          .approval_category;
      setRadiovalue(getRadioValues);
      setShowhide("operational");

      const getAllMandatValues =
        maintenanceState.maintenance_activities[maIndex].approvals
          .parallel_type;
      if (getAllMandatValues === "all_mandatory") {
        setAllMandatValue(getAllMandatValues);
      }
      if (getAllMandatValues === "any_one") {
        setRandomVal(getAllMandatValues);
      }
    }

    let seqUserMail =
      maintenanceState.maintenance_activities[maIndex].approvals.approval_set;
    // console.log(seqUserMail, 'seqUserMail');
    // Check if seqUserMail is an object and convert it to an array if necessary
    // if (!Array.isArray(seqUserMail)) {
    //   seqUserMail = [seqUserMail];
    // }
    if (seqUserMail.length > 0) {
      // console.log(seqUserMail.filter(obj => obj.sequence_approval_email !== ""));
      let getSeqOnly = seqUserMail
        .filter(obj => obj.sequence_approval_email !== "")
        .map(obj => ({ label: obj.sequence_approval_email, value: obj.approval_id }));
      // console.log(getSeqOnly, 'getSeqOnly');
      // console.log(getSeqOnly !== undefined, 'getSeqOnlycondition');

      if (getSeqOnly !== undefined) {
        // setSelectedSeqMail(getSeqOnly);
        // console.log(getSeqOnly, 'setSelectedSeq');
        setSelectedSeq(getSeqOnly)
      }
    }
    // Extract all non-empty parallel_approval_email
    let parallelEmails = seqUserMail
      .filter(obj => obj.parallel_approval_email !== "")
      .map(obj => ({ label: obj.parallel_approval_email, value: obj.approval_id }));

    if (parallelEmails.length > 0) {
      // console.log(parallelEmails, 'parallelEmailsparallelEmails');
      setSelectedParMail(parallelEmails);
    }
    // console.log(maintenanceState, 'maintenanceStateapproval');
  }, [maintenanceState]);

  // console.log(selectedSeq, 'selectedSeq11');

  useEffect(() => {
    const getSValues =
      maintenanceState.maintenance_activities[maIndex].approvals
        .sequence_approval_type;
    setSvalue(getSValues);
    setShowSeq("sequence");

    const getPValues =
      maintenanceState.maintenance_activities[maIndex].approvals
        .parallel_approval_type;
    setPvalue(getPValues);
    setShowprll("parallel");

  }, [maintenanceState]);

  // console.log(maintenanceState);
  // console.log(setMaintenenceState);

  return (
    <div>
      {maState.ma_type === "pre_maintenance_approval" && (
        <div>
          {maintenanceState &&
            maintenanceState.maintenance_activities.length !== 0 && (
              <div key={maIndex}>
                <div className="col-md-12 pt-2">
                  <label>Approval Category</label>

                  {radioValue === "operational" && radioValue !== "" ? (
                    <div>
                      <input
                        className="radioButtons"
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          margin: "8px",
                        }}
                        type="radio"
                        name="approval"
                        id="approval"
                        value="operational"
                        // defaultChecked={"operational"}
                        checked={radioValue === "operational"}
                        onChange={(e) => {
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.approval_category = e.target.value;
                            return { ...prevState };
                          });
                          setShowhide(e.target.value);
                        }}
                        disabled={action}
                      />
                      <label>Operational</label>
                    </div>
                  )
                    :
                    (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="approval"
                          id="approval"
                          value="operational"
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.approval_category = e.target.value;
                              return { ...prevState };
                            });
                            setShowhide(e.target.value);
                          }}
                          disabled={action}
                        />
                        <label>Operational</label>
                      </div>
                    )}
                  {radioValue === "ehs" && radioValue !== "" ? (
                    <div>
                      <input
                        className="radioButtons"
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          margin: "8px",
                        }}
                        type="radio"
                        name="approval"
                        id="approval"
                        value="ehs"
                        // defaultChecked={"ehs"}
                        checked={radioValue === "ehs"}
                        onChange={(e) => {
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.approval_category = e.target.value;
                            return { ...prevState };
                          });
                          setShowhide(e.target.value);
                          checkEHS();
                        }}
                        disabled={action}
                      />
                      <label>EHS</label>
                    </div>
                  )
                    :
                    (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="approval"
                          id="approval"
                          value="ehs"
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.approval_category = e.target.value;
                              return { ...prevState };
                            });
                            setShowhide(e.target.value);
                            checkEHS();
                          }}
                          disabled={action}
                        />
                        <label>EHS</label>
                      </div>
                    )}
                </div>

                {radioValue === "operational" && (
                  <div className="col-md-12 pt-2">
                    <label>Operational Category</label>
                    {pValue === "parallel" && pValue !== "" ?
                      (
                        <div>
                          <input
                            className="radioButtons"
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                              margin: "8px",
                            }}
                            type="radio"
                            name="poprAppvl1"
                            id="poprAppvl1"
                            value="parallel"
                            checked={pValue === "parallel"}
                            onChange={(e) => {
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.sequence_approval_type = "";
                                return { ...prevState };
                              });
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.parallel_approval_type =
                                  e.target.value;
                                return { ...prevState };
                              });
                              setPvalue(e.target.value);
                              setSvalue("")
                            }}
                            disabled={action}
                          />
                          <label>Parallel</label>
                        </div>
                      )
                      :
                      (
                        <div>
                          <input
                            className="radioButtons"
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                              margin: "8px",
                            }}
                            type="radio"
                            name="poprAppvl3"
                            id="poprAppvl3"
                            value="parallel"
                            onChange={(e) => {
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.sequence_approval_type = "";
                                return { ...prevState };
                              });
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.parallel_approval_type =
                                  e.target.value;
                                return { ...prevState };
                              });
                              setPvalue(e.target.value);
                              setSvalue("")
                            }}
                            disabled={action}
                          />
                          <label>Parallel</label>
                        </div>
                      )}

                    {sValue === "sequence" && sValue !== "" ? (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="sqAppvl1"
                          id="sqAppvl1"
                          value="sequence"
                          // defaultChecked={"sequence"}
                          checked={sValue === "sequence"}
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.sequence_approval_type =
                                e.target.value;
                              return { ...prevState };
                            });
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_approval_type = "";
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_type = "";
                              return { ...prevState };
                            });
                            setSvalue(e.target.value);
                            setPvalue("");
                          }}
                          disabled={action}
                        />
                        <label>Sequence</label>
                      </div>
                    ) : (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="sqrAppvl3"
                          id="sqrAppvl3"
                          value="sequence"
                          checked={sValue === "sequence"}
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.sequence_approval_type =
                                e.target.value;
                              return { ...prevState };
                            });
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_approval_type = "";
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_type = "";
                              return { ...prevState };
                            });
                            setSvalue(e.target.value);
                            setPvalue("");
                          }}
                          disabled={action}
                        />
                        <label>Sequence</label>
                      </div>
                    )}
                  </div>
                )}
                {radioValue === "operational" &&
                  pValue === "parallel"
                  // &&
                  // sValue !== "sequence" 
                  ? (
                    <div className="col-md-12 pt-2">
                      <label>Parallel Category</label>

                      {allMandatValue === "all_mandatory" &&
                        allMandatValue !== "" ? (
                        <div>
                          <input
                            className="radioButtons"
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                              margin: "8px",
                            }}
                            type="radio"
                            name="allAppvl1"
                            id="allAppvl"
                            value="all_mandatory"
                            // defaultChecked={"all_mandatory"}
                            checked={allMandatValue === "all_mandatory"}
                            onChange={(e) => {
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.parallel_type = e.target.value;
                                return { ...prevState };
                              });
                              setAllMandatValue(e.target.value);
                              setRandomVal('')
                            }}
                            disabled={action}
                          />
                          <label>All Mandatory</label>
                        </div>
                      ) : (
                        <div>
                          <input
                            className="radioButtons"
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                              margin: "8px",
                            }}
                            type="radio"
                            name="allAppvl3"
                            id="prlAppvl"
                            value="all_mandatory"
                            onChange={(e) => {
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.parallel_type = e.target.value;
                                return { ...prevState };
                              });
                              setAllMandatValue(e.target.value);
                              setRandomVal('')
                            }}
                            disabled={action}
                          />
                          <label>All Mandatory</label>
                        </div>
                      )}
                      {randomVal === "any_one" && randomVal !== "" ? (
                        <div>
                          <input
                            className="radioButtons"
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                              margin: "8px",
                            }}
                            type="radio"
                            name="any_one1"
                            id="any_one"
                            value="any_one"
                            // defaultChecked={"any_one"}
                            checked={randomVal === "any_one"}
                            onChange={(e) => {
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.parallel_type = e.target.value;
                                return { ...prevState };
                              });
                              setRandomVal(e.target.value);
                              setAllMandatValue('');
                            }}
                            disabled={action}
                          />
                          <label>Any One</label>
                        </div>
                      ) : (
                        <div>
                          <input
                            className="radioButtons"
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                              margin: "8px",
                            }}
                            type="radio"
                            name="any_one3"
                            id="prlAppvl"
                            value="any_one"
                            onChange={(e) => {
                              setMaintenenceState((prevState) => {
                                prevState.maintenance_activities[
                                  maIndex
                                ].approvals.parallel_type = e.target.value;
                                return { ...prevState };
                              });
                              setRandomVal(e.target.value);
                              setAllMandatValue('');
                            }}
                            disabled={action}
                          />
                          <label>Any One</label>
                        </div>
                      )}
                    </div>
                  ) : null}
                {radioValue === "operational" && sValue === "sequence" ? (
                  <div className="col-md-4 p-2">
                    <Select
                      className="dropdown"
                      placeholder="Select User"
                      options={approvalUserData.map((user, index) => ({ label: user.email, value: user.user_id }))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      // defaultValue={displayOptions}
                      value={displayOptions}
                      isSearchable={true}
                      isMulti={true}
                      onChange={(e) => handleSeqApproval(e)}
                      isDisabled={action}
                    />
                  </div>
                ) : null}
                {radioValue === "operational" &&
                  pValue === "parallel" &&
                  (allMandatValue === "all_mandatory" ||
                    randomVal === "any_one") ? (
                  <div className="col-md-4 p-2">
                    {/* {console.log(selectedParMail)} */}
                    {/* {console.log(approvalUserData, 'approvalUserData')} */}
                    {/* {console.log(selectedParMail.map(email => ({ label: email, value: email })))} */}
                    <Select
                      className="dropdown"
                      placeholder="Select User"
                      options={approvalUserData.map(user => ({ label: user.email, value: user.user_id }))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      defaultValue={selectedParMail}
                      isSearchable={true}
                      isMulti={true}
                      isClearable={true}
                      onChange={(e) => addParallelUser(e)}
                      isDisabled={action}
                    />
                  </div>
                ) : null}
              </div>
            )}
        </div>
      )}

      {maState.ma_type === "post_maintenance_approval" && (
        <div>
          {maintenanceState &&
            maintenanceState.maintenance_activities.length !== 0 && (
              <div key={maIndex}>
                <div className="col-md-12 pt-2">
                  <label>Operational Category</label>

                  {pValue === "parallel" && pValue !== "" ? (
                    <div>
                      <input
                        className="radioButtons"
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          margin: "8px",
                        }}
                        type="radio"
                        name="parAppvl2"
                        id="parAppvl2"
                        value="parallel"
                        // defaultChecked={"parallel"}
                        checked={pValue === "parallel"}
                        onChange={(e) => {
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.sequence_approval_type = "";
                            return { ...prevState };
                          });
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.parallel_approval_type = e.target.value;
                            return { ...prevState };
                          });
                          setPvalue(e.target.value);
                          setSvalue("")
                        }}
                        disabled={action}
                      />
                      <label>Parallel</label>
                    </div>
                  ) : (
                    <div>
                      <input
                        className="radioButtons"
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          margin: "8px",
                        }}
                        type="radio"
                        name="poprAppvl4"
                        id="poprAppvl4"
                        value="parallel"
                        onChange={(e) => {
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.sequence_approval_type = "";
                            return { ...prevState };
                          });
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.parallel_approval_type = e.target.value;
                            return { ...prevState };
                          });
                          setPvalue(e.target.value);
                          setSvalue("")
                        }}
                        disabled={action}
                      />
                      <label>Parallel</label>
                    </div>
                  )}

                  {sValue === "sequence" && sValue !== "" ? (
                    <div>
                      <input
                        className="radioButtons"
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          margin: "8px",
                        }}
                        type="radio"
                        name="sqAppvl2"
                        id="sqAppvl2"
                        value="sequence"
                        // defaultChecked={"sequence"}
                        checked={sValue === "sequence"}
                        onChange={(e) => {
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.sequence_approval_type = e.target.value;
                            return { ...prevState };
                          });
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.parallel_approval_type = "";
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.parallel_type = "";
                            return { ...prevState };
                          });
                          setSvalue(e.target.value);
                          setPvalue("");
                        }}
                        disabled={action}
                      />
                      <label>Sequence</label>
                    </div>
                  ) : (
                    <div>
                      <input
                        className="radioButtons"
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          margin: "8px",
                        }}
                        type="radio"
                        name="sqAppvl4"
                        id="sqAppvl4"
                        value="sequence"
                        onChange={(e) => {
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.sequence_approval_type = e.target.value;
                            return { ...prevState };
                          });
                          setMaintenenceState((prevState) => {
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.parallel_approval_type = "";
                            prevState.maintenance_activities[
                              maIndex
                            ].approvals.parallel_type = "";
                            return { ...prevState };
                          });
                          setSvalue(e.target.value);
                          setPvalue("");
                        }}
                        disabled={action}
                      />
                      <label>Sequence</label>
                    </div>
                  )}
                </div>
                {pValue === "parallel" && sValue !== "sequence" ? (
                  <div className="col-md-12 pt-2">
                    <label>Parallel Category</label>

                    {allMandatValue === "all_mandatory" &&
                      allMandatValue !== "" ? (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="allAppvl2"
                          id="prlAppvl"
                          value="all_mandatory"
                          // defaultChecked={"all_mandatory"}
                          checked={allMandatValue === "all_mandatory"}
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_type = e.target.value;
                              return { ...prevState };
                            });
                            setAllMandatValue(e.target.value);
                            setRandomVal('')
                          }}
                          disabled={action}
                        />
                        <label>All Mandatory</label>
                      </div>
                    ) : (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="allAppvl4"
                          id="prlAppvl"
                          value="all_mandatory"
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_type = e.target.value;
                              return { ...prevState };
                            });
                            setAllMandatValue(e.target.value);
                            setRandomVal('')
                          }}
                          disabled={action}
                        />
                        <label>All Mandatory</label>
                      </div>
                    )}

                    {randomVal === "any_one" && randomVal !== "" ? (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="any_one2"
                          id="prlAppvl"
                          value="any_one"
                          // defaultChecked={"any_one"}
                          checked={randomVal === "any_one"}
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_type = e.target.value;
                              return { ...prevState };
                            });
                            setRandomVal(e.target.value);
                            setAllMandatValue('');
                          }}
                          disabled={action}
                        />
                        <label>Any One</label>
                      </div>
                    ) : (
                      <div>
                        <input
                          className="radioButtons"
                          style={{
                            marginLeft: "10px",
                            height: "15px",
                            width: "15px",
                            margin: "8px",
                          }}
                          type="radio"
                          name="any_one4"
                          id="prlAppvl"
                          value="any_one"
                          onChange={(e) => {
                            setMaintenenceState((prevState) => {
                              prevState.maintenance_activities[
                                maIndex
                              ].approvals.parallel_type = e.target.value;
                              return { ...prevState };
                            });
                            setRandomVal(e.target.value);
                            setAllMandatValue('');
                          }}
                          disabled={action}
                        />
                        <label>Any One</label>
                      </div>
                    )}
                  </div>
                ) : null}
                {sValue === "sequence" ? (
                  <div className="col-md-4 p-2">
                    {/* {console.log(selectedSeqMail, 'selectedSeqMail')} */}
                    <Select
                      className="dropdown"
                      placeholder="Select User"
                      options={approvalUserData.map(user => ({ label: user.email, value: user.user_id }))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      // defaultValue={displayOptions}
                      value={displayOptions}
                      isSearchable={true}
                      isMulti={true}
                      onChange={(e) => handleSeqApproval(e)}
                      isDisabled={action}
                    />
                  </div>
                ) : null}
                {pValue === "parallel" &&
                  (allMandatValue === "all_mandatory" ||
                    randomVal === "any_one") ? (
                  <div className="col-md-4 p-2">
                    {/* {console.log(selectedParMail)} */}
                    <Select
                      className="dropdown"
                      placeholder="Select User"
                      options={approvalUserData.map(user => ({ label: user.email, value: user.user_id }))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      defaultValue={selectedParMail}
                      isSearchable={true}
                      isMulti={true}
                      isClearable={true}
                      onChange={(e) => addParallelUser(e)}
                      isDisabled={action}
                    />
                  </div>
                ) : null}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default Approvals;
