// Feature -
//     Form to upload Customer contact details
//     Read contact details
//     Delete contact details

//Created by - Janaki J
//Updated by - Janaki J

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CUST_CONTACT_DETAILS } from "../../redux/actions/custAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/loader/loader";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";

//Function to upload customer contact details
function CustomerContact() {
  const navigate = useNavigate();
  // To fetch user role code & access based on role
  const role = useSelector((state) => state.auth.role);
  const access = useSelector((state) => state.access.customerContact);

  var create;
  var read;
  var update;
  var remove;
  // if (access != null) {
  //   const crudAccess = access;
  //   create = crudAccess.customer_contact.c;
  // }
  // console.log(create);

  // To hide nav items
  const [hideNavItems, setHideNavItems] = useState(false);

  // condition to handle access of items based on role
  if (hideNavItems === false && create === true) {
    setHideNavItems(true);
  }

  const { id } = useParams();

  // To fetch details from redux
  const userid = useSelector((state) => state.auth.userid);
  const customerContactGet = useSelector(
    (state) => state.customer.customerContactGet
  );
  const isLoading = useSelector((state) => state.asset.isLoading);

  const dispatch = useDispatch();
  const dispatchid = useDispatch();

  //fetch contactdetails to read
  const getCustContactDetails = () => {
    dispatchid({
      type: "CUST_CONTACT_GET",
      payload: id,
    });
  };
  //delete contact details from list
  const handleDelete = (_id) => {
    dispatch({
      type: "CUST_CONTACT_DELETE",
      payload: { _id, id },
    });
  };
  //useEffects
  useEffect(() => {
    getCustContactDetails(id);
  }, [id]);
  useEffect(() => {
    const roleCode = role[0];
    // console.log(access);
    // console.log(customerContactGet);
    if (access === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [access]);

  //-------------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to show Loading effect */}
      <div
        className="listHeadings mb-4"
        style={{
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        <div
          style={{ float: "inline-start", marginLeft: "25px" }}
          className="d-flex"
        >
          {/* <button
            type="button"
            className="backButton"
            onClick={() => navigate("/customerlist")}
          >
            Back
          </button> */}
          <BackButton />
        </div>
        Customer Contact List
        <div>Customer Contact (List)</div>
      </div>
      <div className="col-md-12">
        <table className="table-bordered">
          <thead className="table-bordered">
            <tr
              style={{
                textAlign: "center",
                backgroundColor: "steelblue",
                color: "#fff",
                width: "100%",
              }}
            >
              <th
                scope="col"
                style={{
                  padding: "8px",
                }}
              >
                S. No
              </th>
              <th scope="col">First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Email ID</th>
              <th>Mobile Number</th>
              <th>Designation</th>
              <th>LinkedIn Profile</th>
              <th>Type</th>
              {access !== null && access.d ? (
                <th
                  scope="col"
                  style={{ textAlign: "center" }}
                  className="actionColumn"
                >
                  Action
                </th>
              ) : null}
            </tr>
          </thead>
          {customerContactGet !== null && customerContactGet.length > 0 ? (
            customerContactGet.map((eachData, index) => {
              return (
                <tbody key={eachData._id}>
                  <tr>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ padding: "8px" }}>{eachData.first_name}</td>
                    <td style={{ padding: "8px" }}>{eachData.middle_name}</td>
                    <td style={{ padding: "8px" }}>{eachData.last_name}</td>
                    <td style={{ padding: "8px" }}>{eachData.email_id}</td>
                    <td style={{ padding: "8px" }}>
                      {eachData.country_code} {eachData.mobile_number}
                    </td>
                    <td style={{ padding: "8px", textTransform: "capitalize" }}>
                      {eachData.designation}
                    </td>
                    <td style={{ padding: "8px" }}>
                      {eachData.linkedin_profile}
                    </td>
                    <td style={{ padding: "8px", textTransform: "capitalize" }}>
                      {eachData.type}
                    </td>
                    {access !== null && access.d ? (
                      <td style={{ textAlign: "center" }}>
                        <button
                          onClick={() => {
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            ) && handleDelete(eachData._id);
                          }}
                          style={{
                            border: "none",
                            color: "#2D7DC1",
                            background: "none",
                          }}
                          data-tip
                          data-for="deleteTip"
                        >
                          <FontAwesomeIcon icon={faTrash} size="lg" />
                          <ReactTooltip
                            id="deleteTip"
                            place="bottom"
                            effect="solid"
                          >
                            Delete
                          </ReactTooltip>
                        </button>
                      </td>
                    ) : null}
                  </tr>
                </tbody>
              );
            })
          ) : (
            <tbody>
              <tr style={{ textAlign: "center", height: "75px" }}>
                <td colSpan="10">No Data Available</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

//exporting component
export default CustomerContact;
