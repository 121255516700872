import { takeEvery, call, put, all, takeLatest } from "@redux-saga/core/effects";
import {
    CWIP_GET_LIST,
    CWIP_GET_SUCCESS,
    CWIP_GET_FAILED,
    ADD_CAPITALISE,
    ADD_CAPITALISE_SUCCESS,
    ADD_CAPITALISE_FAILED,
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAILED,
    EXPENSE_GET_LIST,
    EXPENSE_GET_SUCCESS,
    EXPENSE_GET_FAILED,
    CAPITAL_GET_SUCCESS,
    CAPITAL_GET_FAILED,
    CAPITAL_GET_LIST,
    ADD_LOCATION,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAILED,
    GET_LOCATION_LIST,
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAILED,
    ADD_CLASS,
    ADD_CLASS_SUCCESS,
    ADD_CLASS_FAILED,
    GET_CLASS_LIST,
    GET_CLASS_FAILED,
    GET_CLASS_SUCCESS,
    GET_CALCULATION,
    GET_CALCULATION_SUCCESS,
    GET_CALCULATION_FAILED,
    CREATE_FAASSET,
    CREATE_FAASSET_SUCCESS,
    CREATE_FAASSET_FAILED,
    ADD_BULK_FAASSET_SUCCESS,
    ADD_BULK_FAASSET_FAILED,
    ADD_BULK_FAASSET,
    ADD_DISPOSE,
    ADD_DISPOSE_SUCCESS,
    ADD_DISPOSE_FAILED,
    FAASSET_UPDATE,
    FAASSET_UPDATE_SUCCESS,
    FAASSET_UPDATE_FAILED,
    ADD_COST_CENTER,
    ADD_COST_CENTER_FAILED,
    ADD_COST_CENTER_SUCCESS,
    GET_COST_CENTER_LIST,
    GET_COST_CENTER_SUCCESS,
    GET_COST_CENTER_FAILED,
    GET_BU_CODE_LIST,
    GET_BU_CODE_SUCCESS,
    GET_BU_CODE_FAILED,
    GET_ADDITION_DELETION,
    GET_ADDITION_DELETION_SUCCESS,
    GET_ADDITION_DELETION_FAILED,
    SALE_ASSET,
    SALE_ASSET_SUCCESS,
    SALE_ASSET_FAILED,
    GET_VERIFIED_LIST,
    GET_VERIFIED_LIST_SUCCESS,
    GET_VERIFIED_LIST_FAILED,
    GET_REPORT_DATA,
    GET_REPORT_DATA_SUCCESS,
    GET_REPORT_DATA_FAILED,
    ADD_SAMPLE_LIST,
    ADD_SAMPLE_LIST_SUCCESS,
    ADD_SAMPLE_LIST_FAILED,
    GET_SAMPLE_LIST,
    GET_SAMPLE_LIST_FAILED,
    GET_SAMPLE_LIST_SUCCESS,
} from '../actions/faAction';
import { faServices } from "../../services/service.index";

export function* watchGetCWIP() {
    yield takeLatest(CWIP_GET_LIST, getCWIPSaga);
}
export function* watchAddCapitalise() {
    yield takeLatest(ADD_CAPITALISE, addCapitaliseDataSaga);
}
export function* watchAddExpense() {
    yield takeLatest(ADD_EXPENSE, addExpensedDataSaga);
}
export function* watchGetExpense() {
    yield takeLatest(EXPENSE_GET_LIST, getExpenseSaga);
}

export function* watchGetCapital() {
    yield takeLatest(CAPITAL_GET_LIST, getCapitalSaga);
}
export function* watchAddLocation() {
    yield takeLatest(ADD_LOCATION, addLocationDataSaga);
}
export function* watchAddClass() {
    yield takeLatest(ADD_CLASS, addClassDataSaga);
}
export function* watchAddCostCenter() {
    yield takeLatest(ADD_COST_CENTER, addCostDataSaga);
}

export function* watchGetLocation() {
    yield takeLatest(GET_LOCATION_LIST, getLocationSaga);
}
// export function* watchGetCenter() {
export function* watchGetClass() {
    yield takeLatest(GET_CLASS_LIST, getClassSaga);
}

export function* watchGetCostCenter() {
    yield takeLatest(GET_COST_CENTER_LIST, getCostCenterSaga);
}

export function* watchGetCalculation() {
    yield takeLatest(GET_CALCULATION, getCalculationSaga);
}
export function* watchAddFAAsset() {
    yield takeLatest(CREATE_FAASSET, addFAAssetDataSaga);
}
export function* watchAddBulkFAAsset() {
    yield takeLatest(ADD_BULK_FAASSET, addBulkFAAssetDataSaga);
}

export function* watchAddDispose() {
    yield takeLatest(ADD_DISPOSE, addDisposeDataSaga);
}

export function* watchUpdateAsset() {
    yield takeLatest(FAASSET_UPDATE, updateAssetLocation);
}
export function* watchBuCode() {
    yield takeLatest(GET_BU_CODE_LIST, getBuCode);
}

export function* watchAddDeleteAsset() {
    yield takeLatest(GET_ADDITION_DELETION, getAddDeleteAsset);
}

export function* watchAddSale() {
    yield takeLatest(SALE_ASSET, addSaleDataSaga);
}
export function* watchGetVerfiedList() {
    yield takeLatest(GET_VERIFIED_LIST, addVerifiedDataSaga);
}
export function* watchGetReportData() {
    yield takeLatest(GET_REPORT_DATA, getReportDataSaga);
}

export function* watchAddSampleList() {
    yield takeLatest(ADD_SAMPLE_LIST, addSampleDataSaga);
}

export function* watchGetSampleData() {
    yield takeLatest(GET_SAMPLE_LIST, getSampleDataSaga);
}

export function* getCWIPSaga(action) {
    try {
        const response = yield call(faServices.getCWIPData, action.payload);
        // // console.log(response);
        if (response.status === 200) {
            yield put({
                type: CWIP_GET_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: CWIP_GET_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: CWIP_GET_FAILED,
        });
    }
}
export function* addCapitaliseDataSaga(action) {
    try {
        // // console.log(action.payload);
        const response = yield call(faServices.addCapitalise, action.payload);
        // // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_CAPITALISE_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: CAPITAL_GET_LIST,
                payload: [1, 10]
            });
            alert("Asset Capitalised successfully")
        }
        else {
            yield put({
                type: ADD_CAPITALISE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ADD_CAPITALISE_FAILED,
        });
    }
}

export function* addExpensedDataSaga(action) {
    try {
        // // console.log(action.payload);
        const response = yield call(faServices.addExpense, action.payload);
        // // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_EXPENSE_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: EXPENSE_GET_LIST,
                payload: [1, 10]
            });
            alert("Asset Expensed successfully")
        }
        else {
            yield put({
                type: ADD_EXPENSE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ADD_EXPENSE_FAILED,
        });
    }
}
export function* getExpenseSaga(action) {
    try {
        const response = yield call(faServices.getExpenseData, action.payload);
        // // console.log(response);
        if (response.status === 200) {
            yield put({
                type: EXPENSE_GET_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: EXPENSE_GET_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: EXPENSE_GET_FAILED,
        });
    }
}

export function* getCapitalSaga(action) {
    try {
        const response = yield call(faServices.getCapitalData, action.payload);
        // // console.log(response);
        if (response.status === 200) {
            yield put({
                type: CAPITAL_GET_SUCCESS,
                payload: response.data,
            });

        }
        else {
            yield put({
                type: CAPITAL_GET_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: CAPITAL_GET_FAILED,
        });
    }
}
export function* addLocationDataSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.addLocation, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_LOCATION_SUCCESS,
                payload: response.data,
            });
            alert("Location added successfully")
            yield put({
                type: GET_LOCATION_LIST,
                payload: [1, 10],
            });
        }
        else {
            yield put({
                type: ADD_LOCATION_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ADD_CAPITALISE_FAILED,
        });
    }
}
export function* getLocationSaga(action) {
    try {
        const response = yield call(faServices.getLocationData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_LOCATION_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_LOCATION_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_LOCATION_FAILED,
        });
    }
}
export function* addClassDataSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.addClass, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_CLASS_SUCCESS,
                payload: response.data,
            });
            alert("Asset Class added successfully")
            yield put({
                type: GET_CLASS_LIST,
                payload: [1, 10],
            });
        }
        else {
            yield put({
                type: ADD_CLASS_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ADD_CLASS_FAILED,
        });
    }
}
export function* getCostCenterSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.getCostCenterData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_COST_CENTER_SUCCESS,
                payload: response.data,
            });

        }
        else {
            yield put({
                type: GET_COST_CENTER_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_COST_CENTER_FAILED,
        });
    }
}
export function* addCostDataSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.addCostCenter, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_COST_CENTER_SUCCESS,
                payload: response.data,
            });
            alert("Cost Center added successfully")
            yield put({
                type: GET_COST_CENTER_LIST,
                payload: [1, 10]
                // payload: response.data,
            });
        }
        else {
            yield put({
                type: ADD_COST_CENTER_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ADD_COST_CENTER_FAILED,
        });
    }
}
// export function* getCenterSaga(action) {
export function* getClassSaga(action) {
    try {
        const response = yield call(faServices.getClassData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_CLASS_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_CLASS_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_CLASS_FAILED,
        });
    }
}
export function* getCalculationSaga(action) {
    try {
        const response = yield call(faServices.getCalculationData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_CALCULATION_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_CALCULATION_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_CALCULATION_FAILED,
        });
    }
}
export function* addFAAssetDataSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.addFAAsset, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: CREATE_FAASSET_SUCCESS,
                payload: response.data,
            });
            // yield put({
            //     type: CWIP_GET_LIST,
            //     payload: [1, 10]
            // });
            alert("Asset added successfully")

        }
        else {

            yield put({
                type: CREATE_FAASSET_FAILED,
            });
            // alert()
        }
    } catch (e) {
        // console.log(e.response, "AAAA")
        if (e.response.data === 'Duplicate Asset ID found.') {
            alert('Entered Asset ID already exists')
        }
        yield put({
            type: CREATE_FAASSET_FAILED,
        });
    }
}
export function* addBulkFAAssetDataSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.addBulkFAAsset, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_BULK_FAASSET_SUCCESS,
                payload: response.data,
            });
            alert("Asset's added successfully")
            // yield put({
            //     type: CWIP_GET_LIST,
            //     payload: [1, 10]
            // });
        }
        else {
            yield put({
                type: ADD_BULK_FAASSET_FAILED,
            });
        }
    } catch (e) {
        if (e.response.data === 'Duplicate Asset ID found.') {
            alert('Entered Asset ID already exists')
        }
        yield put({
            type: ADD_BULK_FAASSET_FAILED,
        });
    }
}
export function* addDisposeDataSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.addDispose, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_DISPOSE_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: EXPENSE_GET_LIST,
                payload: [1, 10]
            });
            alert("Asset Disposed successfully")
        }
        else {
            yield put({
                type: ADD_DISPOSE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ADD_DISPOSE_FAILED,
        });
    }
}

export function* addSaleDataSaga(action) {
    try {
        const response = yield call(faServices.saleAsset, action.payload);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: SALE_ASSET_SUCCESS,
                payload: response.data,
            });
            // yield put({
            //     type: EXPENSE_GET_LIST,
            //     payload: [1, 10]
            // });
            alert("Asset Disposed successfully")
        }
        else {
            yield put({
                type: SALE_ASSET_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SALE_ASSET_FAILED,
        });
    }
}

export function* updateAssetLocation(action) {
    try {
        // console.log(action.payload);
        const response = yield call(faServices.updateAsset, action.payload);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: FAASSET_UPDATE_SUCCESS,
                payload: response.data,
            });
            alert("Asset Transfered successfully")
            yield put({
                type: CAPITAL_GET_LIST,
                payload: [1, 10]
            });
        }
        else {
            yield put({
                type: FAASSET_UPDATE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: FAASSET_UPDATE_FAILED,
        });
    }
}

export function* addSampleDataSaga(action) {
    try {
        const response = yield call(faServices.sampleList, action.payload);
        if (response.status === 200 || response.status === 201) {
            yield put({
                type: ADD_SAMPLE_LIST_SUCCESS,
                payload: response.data,
            });
            // yield put({
            //     type: EXPENSE_GET_LIST,
            //     payload: [1, 10]
            // });
            alert("Sample Published successfully")
        }
        else {
            yield put({
                type: ADD_SAMPLE_LIST_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SALE_ASSET_FAILED,
        });
    }
}


export function* getBuCode(action) {
    try {
        const response = yield call(faServices.getBuCodeData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_BU_CODE_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_BU_CODE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_BU_CODE_FAILED,
        });
    }
}

export function* getAddDeleteAsset(action) {
    try {
        const response = yield call(faServices.getAddDeleteAssetData, action.payload);
        if (response.status === 200) {
            yield put({
                type: GET_ADDITION_DELETION_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_ADDITION_DELETION_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_ADDITION_DELETION_FAILED,
        });
    }
}
export function* addVerifiedDataSaga(action) {
    try {
        const response = yield call(faServices.getVerifiedData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_VERIFIED_LIST_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_VERIFIED_LIST_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_VERIFIED_LIST_FAILED,
        });
    }
}
export function* getReportDataSaga(action) {
    try {
        const response = yield call(faServices.getReportData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_REPORT_DATA_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_REPORT_DATA_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_REPORT_DATA_FAILED,
        });
    }
}
// Get generated sample list
export function* getSampleDataSaga(action) {
    try {
        const response = yield call(faServices.getSampleListData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: GET_SAMPLE_LIST_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: GET_SAMPLE_LIST_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_SAMPLE_LIST_FAILED,
        });
    }
}
