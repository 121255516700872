import React, { useState, useEffect } from "react";
import UpdateInspection from "./updateInspection";


const InspectionView = () => {

  return (
    <div style={{ overflowX: "hidden" }}>
      <UpdateInspection
        action={'ins_view'}
      />
    </div>
  );
};

export default InspectionView;
