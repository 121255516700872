import React from "react";
import IMAGENAME from "../medias/media.index";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <div>
      <img
        style={{cursor:"pointer"}}
        className="mt-2 mx-2"
        src={IMAGENAME.logo}
        width="auto"
        alt="logo"
        height="25"
        onClick={() => {
          navigate("/home");
        }}
      />
    </div>
  );
};
export default Logo