const FACILITY_GET_LIST = 'FACILITY_GET_LIST';
const FACILITY_GET_SUCCESS = 'FACILITY_GET_SUCCESS';
const FACILITY_GET_FAILED = 'FACILITY_GET_FAILED';

const FACILITY_DELETE_LIST = 'FACILITY_DELETE_LIST';
const FACILITY_DELETE_SUCCESS = 'FACILITY_DELETE_SUCCESS';
const FACILITY_DELETE_FAILED = 'FACILITY_DELETE_FAILED';

const FACILITY_ADD = 'FACILITY_ADD';
const FACILITY_ADD_SUCCESS = 'FACILITY_ADD_SUCCESS';
const FACILITY_ADD_FAILED = 'FACILITY_ADD_FAILED';

// update individual customer (changes in updateded datails)
const FACILITY_UPDATE = 'FACILITY_UPDATE';
const FACILITY_UPDATE_SUCCESS = 'FACILITY_UPDATE_SUCCESS';
const FACILITY_UPDATE_FAILED = 'FACILITY_UPDATE_FAILED';


// get single customer by ID to view individual customer details.
const FACILITY_GET_SINGLE = 'FACILITY_GET_SINGLE';
const FACILITY_GET_SINGLE_SUCCESS = 'FACILITY_GET_SINGLE_SUCCESS';
const FACILITY_GET_SINGLE_FAILED = 'FACILITY_GET_SINGLE_FAILED';


export {
    FACILITY_GET_LIST,
    FACILITY_GET_SUCCESS,
    FACILITY_GET_FAILED,
    
    FACILITY_DELETE_LIST,
    FACILITY_DELETE_SUCCESS,
    FACILITY_DELETE_FAILED,

    FACILITY_ADD,
    FACILITY_ADD_SUCCESS,
    FACILITY_ADD_FAILED,   
    
    FACILITY_UPDATE,
    FACILITY_UPDATE_SUCCESS,
    FACILITY_UPDATE_FAILED,


    FACILITY_GET_SINGLE,
    FACILITY_GET_SINGLE_SUCCESS,
    FACILITY_GET_SINGLE_FAILED,

}