// action to create single Maintenace.
const MAINT_ADD = "MAINT_ADD";
const MAINT_ADD_SUCCESS = "MAINT_ADD_SUCCESS";
const MAINT_ADD_FAILED = "MAINT_ADD_FAILED";

const MAINT_GET_LIST = "MAINT_GET_LIST";
const MAINT_GET_SUCCESS = "MAINT_GET_SUCCESS";
const MAINT_GET_FAILED = "MAINT_GET_FAILED";

const MAINT_DELETE_LIST = "MAINT_DELETE_LIST";
const MAINT_DELETE_SUCCESS = "MAINT_DELETE_SUCCESS";
const MAINT_DELETE_FAILED = "MAINT_DELETE_FAILED";

// action to create bulk Maintenace.
const MAINTENANCE_BULK_ADD = "MAINTENANCE_BULK_ADD";
const MAINTENANCE_BULK_ADD_SUCCESS = "MAINTENANCE_BULK_ADD_SUCCESS";
const MAINTENANCE_BULK_ADD_FAILED = "MAINTENANCE_BULK_ADD_FAILED";

// action for Maintenance report.
const MAINTENANCE_REPORT = "MAINTENANCE_REPORT";
const MAINTENANCE_REPORT_SUCCESS = "MAINTENANCE_REPORT_SUCCESS";
const MAINTENANCE_REPORT_FAILED = "MAINTENANCE_REPORT_FAILED";
// action to assign Maintenace.
const MAINTENANCE_ASSIGN_LIST = "MAINTENANCE_ASSIGN_LIST";
const MAINTENANCE_ASSIGN_SUCCESS = "MAINTENANCE_ASSIGN_SUCCESS";
const MAINTENANCE_ASSIGN_FAILED = "MAINTENANCE_ASSIGN_FAILED";

const MAINT_GET_SINGLE = "MAINT_GET_SINGLE";
const MAINT_GET_SINGLE_SUCCESS = "MAINT_GET_SINGLE_SUCCESS";
const MAINT_GET_SINGLE_FAILED = "MAINT_GET_SINGLE_FAILED";

const MAINTENANCE_UPDATE = "MAINTENANCE_UPDATE";
const MAINT_UPDATE_SUCCESS = "MAINT_UPDATE_SUCCESS";
const MAINT_UPDATE_FAILED = "MAINT_UPDATE_FAILED";

const MAINTENANCE_STATUS = "MAINTENANCE_STATUS";
const MAINTENANCE_STATUS_SUCCESS = "MAINTENANCE_STATUS_SUCCESS";
const MAINTENANCE_STATUS_FAILED = "MAINTENANCE_STATUS_FAILED";

// action for Maintenance status.
const MAINTENANCE_STOP = "MAINTENANCE_STOP";
const MAINTENANCE_STOP_SUCCESS = "MAINTENANCE_STOP_SUCCESS";
const MAINTENANCE_STOP_FAILED = "MAINTENANCE_STOP_FAILED";

// action to get SOP list based on asset ID
const SOP_GET = "SOP_GET";
const SOP_GET_SUCCESS = "SOP_GET_SUCCESS";
const SOP_GET_FAILED = "SOP_GET_FAILED";

// action to map SOP to the maintenance activity
const ASSIGN_SOP_MAIN = "ASSIGN_SOP_MAIN";
const ASSIGN_SOP_MAIN_SUCCESS = "ASSIGN_SOP_MAIN_SUCCESS";
const ASSIGN_SOP_MAIN_FAILED = "ASSIGN_SOP_MAIN_FAILED";

export {
  MAINT_ADD,
  MAINT_ADD_SUCCESS,
  MAINT_ADD_FAILED,

  MAINT_GET_LIST,
  MAINT_GET_SUCCESS,
  MAINT_GET_FAILED,

  MAINT_DELETE_LIST,
  MAINT_DELETE_SUCCESS,
  MAINT_DELETE_FAILED,

  MAINTENANCE_BULK_ADD,
  MAINTENANCE_BULK_ADD_SUCCESS,
  MAINTENANCE_BULK_ADD_FAILED,

  MAINTENANCE_REPORT,
  MAINTENANCE_REPORT_SUCCESS,
  MAINTENANCE_REPORT_FAILED,

  MAINTENANCE_ASSIGN_LIST,
  MAINTENANCE_ASSIGN_SUCCESS,
  MAINTENANCE_ASSIGN_FAILED,

  MAINT_GET_SINGLE,
  MAINT_GET_SINGLE_SUCCESS,
  MAINT_GET_SINGLE_FAILED,

  MAINTENANCE_UPDATE,
  MAINT_UPDATE_SUCCESS,
  MAINT_UPDATE_FAILED,

  MAINTENANCE_STATUS,
  MAINTENANCE_STATUS_SUCCESS,
  MAINTENANCE_STATUS_FAILED,
  
  MAINTENANCE_STOP,
  MAINTENANCE_STOP_SUCCESS,
  MAINTENANCE_STOP_FAILED,

  SOP_GET,
  SOP_GET_SUCCESS,
  SOP_GET_FAILED,

  ASSIGN_SOP_MAIN,
  ASSIGN_SOP_MAIN_SUCCESS,
  ASSIGN_SOP_MAIN_FAILED
};
