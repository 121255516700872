/* Feature - Bulkupload of asset, device & facility

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Selectasset from "../asset/selectAsset";
import { CONSTANTS } from "../../constants/URLs/urlConstants";
import { custService } from "../../services/service.index";
import { siteService } from "../../services/service.index";
import { Link } from "react-router-dom";
import { faC } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";

function BulkUpload(props) {
  // To fetch details from redux
  // const siteData = useSelector(state => state.site.siteData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.asset.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const [items, setItems] = useState({
    site_id: {},
    asset: [],
    device: [],
    facility: [],
  });

  // console.log(items.site_id);

  // disable upload button for empty site details
  const [isDisabled, setIsDisabled] = useState(true);

  // to disable button base on file input //
  const [disabled, setDisabled] = useState(true);

  // to disable button based on selection of wrong worksheet name //
  const [wsdisabled, setWSDisabled] = useState(false);

  // to disable button based on empty file input //
  const [disEmptyArray, setDisEmptyArray] = useState(false);

  // to handle site & customer selection based on selectAsset.js without redux.

  // useState to handle state
  const [custname, setCustname] = useState("");
  const [sitename, setSitename] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState(""); // customer ID
  const [siteId, setSiteID] = useState(""); // site ID
  const [custdata, setCustData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [assetselect, setAssetselect] = useState("");

  // function to get customer data
  const getData = async () => {
    await custService
      .getCustData(CONSTANTS.CUST_GET_ALL)
      .then(function (response) {
        setCustData(response.data);
      })
      .catch(function (error) { });
  };


  // useEffect
  useEffect(() => {
    getData();
    getSiteByCustId(id);
  }, []);

  // function to handle customer details. To fetch the customer ID from API.
  const handleCustomer = (string) => {
    let array = string.split(",");
    setId(array[0]);
    setName(array[1]);
  };

  // function to get site based on selected customer.
  const getSiteByCustId = async (id) => {
    await siteService
      .getSiteById(id)
      .then(function (res) {
        setSiteData(res.data);
      })
      .catch(function (error) { });
  };

  const uploadAssetbySiteId = (data) => {
    setSiteID(data);
  };

  // Read the excel sheet data and convert it to JSON file using following code
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" }); // wb - workbook

        const wsname = wb.SheetNames; // wsname - worksheet name

        const wsAsset = wb.Sheets["Asset"]; // "Asset" name is the name directly taken from excel workbook sheet name.

        const wsDevice = wb.Sheets["Device"]; // "Device" name is the name directly taken from excel workbook sheet name.

        const wsFacility = wb.Sheets["Facility"]; // "Facility" name is the name directly taken from excel workbook sheet name.

        /////////////////////////  Asset Bulk Upload  ///////////////////////////////////////////////////

        const Assetheader = [
          [
            "SrNo",
            "asset_id",
            "asset_name",
            "make",
            "model",
            "serial_number",
            "rating_capacity",
            "uom",
            "year_of_manufacture",
            "year_of_installation",
            "expected_life_in_month",
            "criticality",
            "area",
            "location",
            "building",
            "block_tower",
            "floor",
            "wing",
            "department",
            "accessibility",
            "remarks",
            "created_by",
          ],
        ];

        const Assetdata = XLSX.utils.sheet_to_json(wsAsset, { defval: null });

        const CreateWStoMeargeAsset = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMeargeAsset, Assetheader);

        const MeargehederDataAsset = XLSX.utils.sheet_add_json(
          CreateWStoMeargeAsset,
          Assetdata,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        const MeagedDataAsset = XLSX.utils.sheet_to_json(MeargehederDataAsset, {
          defval: null,
        });

        MeagedDataAsset.forEach(function (obj) {
          obj.created_by = null
      }, MeagedDataAsset);

      MeagedDataAsset.map((eachdata) => {

        if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
        }        

        const createdby = MeagedDataAsset

        const index = createdby.findIndex(object => {
            return object.created_by === null;
        });

        // console.log(index);

        if (index !== -1) {
          createdby[index].created_by = enteredCreatedBy
        }

    })

        // console.log(MeagedDataAsset);


        // const data = MeagedDataAsset.filter(obj =>obj.asset_id === )

        ////////////////////////////////////// Device Bulk Upload /////////////////////////////////////////////////////////////

        const Deviceheader = [
          [
            "SrNo",
            "device_id",
            "device_name",
            "type",
            "make",
            "model",
            "serial_number",
            "range",
            "resolution",
            "accuracy",
            "rating_capacity",
            "uom",
            "criticality",
            "location",
            "year_of_manufacture",
            "year_of_installation",
            "expected_life_in_month",
            "department",
            "accessibility",
            "remarks",
            "created_by",
          ],
        ];

        const Devicedata = XLSX.utils.sheet_to_json(wsDevice, { defval: null });

        const CreateWStoMeargeDevice = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMeargeDevice, Deviceheader);

        const MeargehederDataDevice = XLSX.utils.sheet_add_json(
          CreateWStoMeargeDevice,
          Devicedata,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        const MeagedDataDevice = XLSX.utils.sheet_to_json(
          MeargehederDataDevice,
          { defval: null }
        );

        MeagedDataDevice.forEach(function (obj) {
          obj.created_by = null
      }, MeagedDataDevice);

      MeagedDataDevice.map((eachdata) => {

        if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
        }        

        const createdby = MeagedDataDevice

        const index = createdby.findIndex(object => {
            return object.created_by === null;
        });

        // console.log(index);

        if (index !== -1) {
          createdby[index].created_by = enteredCreatedBy
        }

    })

    // console.log(MeagedDataDevice);

        ////////////////////////////////////////  Facility Bulk upload /////////////////////////////////////////////////////////

        const Facilityheader = [
          [
            "SrNo",
            "facility_id",
            "category",
            "facility_name",
            "criticality",
            "area",
            "location",
            "building",
            "block",
            "floor",
            "wing",
            "department",
            "accessibility",
            "remarks",
            "created_by",
          ],
        ];

        const Facilitydata = XLSX.utils.sheet_to_json(wsFacility, {
          defval: null,
        });

        const CreateWStoMeargeFacility = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMeargeFacility, Facilityheader);

        const MeargehederDataFacility = XLSX.utils.sheet_add_json(
          CreateWStoMeargeFacility,
          Facilitydata,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        const MeagedDataFacility = XLSX.utils.sheet_to_json(
          MeargehederDataFacility,
          { defval: null }
        );

        MeagedDataFacility.forEach(function (obj) {
          obj.created_by = null
      }, MeagedDataFacility);

      MeagedDataFacility.map((eachdata) => {

        if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
        }        

        const createdby = MeagedDataFacility

        const index = createdby.findIndex(object => {
            return object.created_by === null;
        });

        // console.log(index);

        if (index !== -1) {
          createdby[index].created_by = enteredCreatedBy
        }

    })
    // console.log(MeagedDataFacility);


        //////////////////////////// client side validation - sheet name ///////////////////////////////////////

        if (
          wsname.includes("Asset") &&
          wsname.includes("Device") &&
          wsname.includes("Facility")
        ) {
        } else {
          setWSDisabled(true);
          result = "error";
          alert(
            `Please verify the uploaded sheet. \n It doesn't includes following workbook: \n 1. Asset - work book with the name - "Asset" \n 2. Device - work book with the name of - "Device" \n 3. Facility - work book with the name of - "Facility" \n \n\n Note: Either you may have changed the work book name or you are not uploading correct format`
          );
        }

        /////////////////////////////////////////// Client side Validation - Asset ////////////////////////////////////////////////////////////////

        MeagedDataAsset.forEach(function (obj) {
          delete obj.SrNo;
        });

        var result = "";

        MeagedDataAsset.map((eachdata) => {
          // console.log(eachdata.asset_id);

          if (eachdata.asset_id == null) {
            var asset_id_index = MeagedDataAsset.indexOf(eachdata);
          }

          if (eachdata.asset_name == null) {
            var asset_name_index = MeagedDataAsset.indexOf(eachdata);
          }

          if (eachdata.make == null) {
            var make_index = MeagedDataAsset.indexOf(eachdata);
          }

          if (eachdata.model == null) {
            var model_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.rating_capacity == null) {
            var rating_capacity_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.uom == null) {
            var uom_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.criticality == null) {
            var criticality_index = MeagedDataAsset.indexOf(eachdata);
          }
          // console.log(eachdata.criticality);
          if ((eachdata.criticality !== null) && ((eachdata.criticality !== 1) && (eachdata.criticality !== 2) && (eachdata.criticality !== 3))
          ) {
            var criticality_type_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.area == null) {
            var area_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.location == null) {
            var location_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.department == null) {
            var department_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.year_of_manufacture !== null &&
            (typeof (eachdata.year_of_manufacture) !== 'number')) {
            var yom_index = MeagedDataAsset.indexOf(eachdata);
          }
          // console.log((typeof(eachdata.year_of_manufacture) !== 'number'));
          // console.log(eachdata.year_of_manufacture);

          if (eachdata.year_of_installation !== null &&
            (typeof (eachdata.year_of_installation) !== 'number')) {
            var yoi_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.expected_life_in_month !== null &&
            (typeof (eachdata.expected_life_in_month) !== 'number')) {
            var elim_index = MeagedDataAsset.indexOf(eachdata);
          }
          if ((eachdata.accessibility !== null) && ((eachdata.accessibility !== "Yes") && (eachdata.accessibility !== "No"))
          ) {
            var accessibility_index = MeagedDataAsset.indexOf(eachdata);
          }

          result += validateassetID();
          result += validateassetname();
          result += validatemake();
          result += validatemodel();
          result += validaterating();
          result += validateuom();
          result += validatecriticality();
          result += validatecriticalityType();
          result += validatearea();
          result += validatelocation();
          result += validatedepartment();
          result += validateyearofManu();
          result += validateyearofInst();
          result += validateexpectedlife();
          result += validateaccessibility();

          function validateassetID() {
            if (eachdata.asset_id == null)
              return `Sl.No: ${asset_id_index + 1
                } - Asset ID field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validateassetname() {
            if (eachdata.asset_name === null)
              return `Sl.No: ${asset_name_index + 1
                } - Asset Name field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validatemake() {
            if (eachdata.make === null)
              return `Sl.No: ${make_index + 1
                } - Make field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validatemodel() {
            if (eachdata.model === null)
              return `Sl.No: ${model_index + 1
                } - Model field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validaterating() {
            if (eachdata.rating_capacity === null)
              return `Sl.No: ${rating_capacity_index + 1
                } - Rating / Capacity field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validateuom() {
            if (eachdata.uom === null)
              return `Sl.No: ${uom_index + 1
                } - UOM of rating capacity field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validatecriticality() {
            if (eachdata.criticality === null)
              return `Sl.No: ${criticality_index + 1
                } - Criticality field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validatecriticalityType() {
            if ((eachdata.criticality !== null) && ((eachdata.criticality !== 1) && (eachdata.criticality !== 2) && (eachdata.criticality !== 3)))
              return `Sl.No: ${criticality_type_index + 1
                } - Please select appropriate criticality either 1, 2 or 3 - Asset Sheet \n`;
            return "";
          }
          function validatearea() {
            if (eachdata.area === null)
              return `Sl.No: ${area_index + 1
                } - Area field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validatelocation() {
            if (eachdata.location === null)
              return `Sl.No: ${location_index + 1
                } - Location field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validatedepartment() {
            if (eachdata.department === null)
              return `Sl.No: ${department_index + 1
                } - Department field is mandetory field - Asset Sheet \n`;
            return "";
          }
          function validateyearofManu() {
            if (eachdata.year_of_manufacture !== null &&
              (typeof (eachdata.year_of_manufacture) !== 'number'))
              return `Sl.No: ${yom_index + 1
                } - 'Number' is only allowed for Year of Manufacturer field (Ex. 2022) - Asset Sheet \n`;
            return "";
          }
          function validateyearofInst() {
            if (eachdata.year_of_installation !== null &&
              (typeof (eachdata.year_of_installation) !== 'number'))
              return `Sl.No: ${yoi_index + 1
                } - 'Number' is only allowed for Year of Installation field (Ex. 2022) - Asset Sheet \n`;
            return "";
          }
          function validateexpectedlife() {
            if (eachdata.expected_life_in_month !== null &&
              (typeof (eachdata.expected_life_in_month) !== 'number'))
              return `Sl.No: ${elim_index + 1
                } - 'Number' is only allowed for Expected Life in Month field (Ex. 12) - Asset Sheet \n`;
            return "";
          }
          function validateaccessibility() {
            if ((eachdata.accessibility !== null) && ((eachdata.accessibility !== "Yes") && (eachdata.accessibility !== "No"))
            )
              return `Sl.No: ${accessibility_index + 1
                } - Please select appropriate Accessibility either Yes or No - Asset Sheet \n`;
            return "";
          }
        });

        const assetData = MeagedDataAsset.map((x) =>
          x.accessibility === null ? { ...x, accessibility: "" } : x
        );



        // console.log(assetData);
        resolve(MeagedDataAsset);

        //////////////////////////////////// Client side Validation - Device ////////////////////////////////////////////////////////

        MeagedDataDevice.forEach(function (obj) {
          delete obj.SrNo;
        });

        MeagedDataDevice.map((eachdata) => {
          if (eachdata.device_id == null) {
            var device_id_index = MeagedDataDevice.indexOf(eachdata);
          }

          if (eachdata.device_name == null) {
            var device_name_index = MeagedDataDevice.indexOf(eachdata);
          }
          if (eachdata.type == null) {
            var type_index = MeagedDataDevice.indexOf(eachdata);
          }
          if ((eachdata.type !== null) && ((eachdata.type !== "Measuring Device") && (eachdata.type !== "IoT"))
          ) {
            var type_selected_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.make == null) {
            var make_index = MeagedDataDevice.indexOf(eachdata);
          }
          if (eachdata.model == null) {
            var model_index = MeagedDataDevice.indexOf(eachdata);
          }
          if (eachdata.range == null) {
            var range_index = MeagedDataDevice.indexOf(eachdata);
          }
          if (eachdata.resolution == null) {
            var resolution_index = MeagedDataDevice.indexOf(eachdata);
          }
          if (eachdata.accuracy == null) {
            var accuracy_index = MeagedDataDevice.indexOf(eachdata);
          }
          if (eachdata.criticality == null) {
            var criticality_index = MeagedDataDevice.indexOf(eachdata);
          }
          if ((eachdata.criticality !== null) && ((eachdata.criticality !== 1) && (eachdata.criticality !== 2) && (eachdata.criticality !== 3))
          ) {
            var criticality_type_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.year_of_manufacture !== null &&
            (typeof (eachdata.year_of_manufacture) !== 'number')) {
            var yom_index = MeagedDataAsset.indexOf(eachdata);
          }
          // console.log((typeof(eachdata.year_of_manufacture) !== 'number'));
          // console.log(eachdata.year_of_manufacture);

          if (eachdata.year_of_installation !== null &&
            (typeof (eachdata.year_of_installation) !== 'number')) {
            var yoi_index = MeagedDataAsset.indexOf(eachdata);
          }
          if (eachdata.expected_life_in_month !== null &&
            (typeof (eachdata.expected_life_in_month) !== 'number')) {
            var elim_index = MeagedDataAsset.indexOf(eachdata);
          }
          if ((eachdata.accessibility !== null) && ((eachdata.accessibility !== "Yes") && (eachdata.accessibility !== "No"))
          ) {
            var accessibility_index = MeagedDataAsset.indexOf(eachdata);
          }

          result += validatedeviceID();
          result += validatedevicename();
          result += validatetype();
          result += validateselectedtype();
          result += validatemake();
          result += validatemodel();
          result += validaterange();
          result += validateresolution();
          result += validateaccuracy();
          result += validatecriticality();
          result += validatecriticalityType();
          result += validateyearofManu();
          result += validateyearofInst();
          result += validateexpectedlife();
          result += validateaccessibility();


          function validatedeviceID() {
            if (eachdata.device_id == null)
              return `Sl.No: ${device_id_index + 1
                } - Device ID field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validatedevicename() {
            if (eachdata.device_name === null)
              return `Sl.No: ${device_name_index + 1
                } - Device Name field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validatetype() {
            if (eachdata.type === null)
              return `Sl.No: ${type_index + 1
                } - Type field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validateselectedtype() {
            if ((eachdata.type !== null) && ((eachdata.type !== "Measuring Device") && (eachdata.type !== "IoT")))
              return `Sl.No: ${type_selected_index + 1
                } - Please select appropriate Type either Measuring Device or IoT - Device Sheet \n`;
            return "";
          }
          function validatemake() {
            if (eachdata.make === null)
              return `Sl.No: ${make_index + 1
                } - Make field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validatemodel() {
            if (eachdata.model === null)
              return `Sl.No: ${model_index + 1
                } - Model field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validaterange() {
            if (eachdata.range === null)
              return `Sl.No: ${range_index + 1
                } - Range field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validateresolution() {
            if (eachdata.resolution === null)
              return `Sl.No: ${resolution_index + 1
                } - Resolution field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validateaccuracy() {
            if (eachdata.accuracy === null)
              return `Sl.No: ${accuracy_index + 1
                } - Accuracy field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validatecriticality() {
            if (eachdata.criticality === null)
              return `Sl.No: ${criticality_index + 1
                } - Criticality field is mandetory field - Device Sheet \n`;
            return "";
          }
          function validatecriticalityType() {
            if ((eachdata.criticality !== null) && ((eachdata.criticality !== 1) && (eachdata.criticality !== 2) && (eachdata.criticality !== 3)))
              return `Sl.No: ${criticality_type_index + 1
                } - Please select appropriate criticality either 1, 2 or 3 - Device Sheet \n`;
            return "";
          }
          function validateyearofManu() {
            if (eachdata.year_of_manufacture !== null &&
              (typeof (eachdata.year_of_manufacture) !== 'number'))
              return `Sl.No: ${yom_index + 1} - 'Number' is only allowed for Year of Manufacturer field (Ex. 2022) - Device Sheet \n`;
            return "";
          }
          function validateyearofInst() {
            if (eachdata.year_of_installation !== null &&
              (typeof (eachdata.year_of_installation) !== 'number'))
              return `Sl.No: ${yoi_index + 1
                } - 'Number' is only allowed for Year of Installation field (Ex. 2022) - Device Sheet \n`;
            return "";
          }
          function validateexpectedlife() {
            if (eachdata.expected_life_in_month !== null &&
              (typeof (eachdata.expected_life_in_month) !== 'number'))
              return `Sl.No: ${elim_index + 1
                } - 'Number' is only allowed for Expected Life in Month field (Ex. 12) - Device Sheet \n`;
            return "";
          }
          function validateaccessibility() {
            if ((eachdata.accessibility !== null) && ((eachdata.accessibility !== "Yes") && (eachdata.accessibility !== "No"))
            )
              return `Sl.No: ${accessibility_index + 1
                } - Please select appropriate Accessibility either Yes or No - Device Sheet \n`;
            return "";
          }
        });

        const deviceType = MeagedDataDevice.map(eachdata => {
          if (eachdata.type === 'Measuring Device') {
            return { ...eachdata, type: 'measuring_device' };
          }
          return eachdata;
        });

        const deviceData = deviceType.map((x) =>
          x.accessibility === null ? { ...x, accessibility: "" } : x
        );

        resolve(MeagedDataDevice);

        /////////////////////////////////////// Client side Validation - Facility //////////////////////////////////////////////////////

        MeagedDataFacility.forEach(function (obj) {
          delete obj.SrNo;
        });

        // console.log(MeagedDataFacility);

        MeagedDataFacility.map((eachdata) => {
          if (eachdata.facility_id == null) {
            var facility_id_index = MeagedDataFacility.indexOf(eachdata);
          }

          if (eachdata.category == null) {
            var category_index = MeagedDataFacility.indexOf(eachdata);
          }

          if (eachdata.facility_name == null) {
            var facility_name_index = MeagedDataFacility.indexOf(eachdata);
          }

          if ((eachdata.criticality !== null) && ((eachdata.criticality !== 1) && (eachdata.criticality !== 2) && (eachdata.criticality !== 3))
          ) {
            var criticality_type_index = MeagedDataAsset.indexOf(eachdata);
          }

          if (eachdata.location == null) {
            var location_index = MeagedDataFacility.indexOf(eachdata);
          }

          if (eachdata.department == null) {
            var department_index = MeagedDataFacility.indexOf(eachdata);
          }

          if (eachdata.accessibility == null) {
            var accessibility_index = MeagedDataFacility.indexOf(eachdata);
          }

          if ((eachdata.accessibility !== null) && ((eachdata.accessibility !== "Yes") && (eachdata.accessibility !== "No"))
          ) {
            var accessibility_index = MeagedDataAsset.indexOf(eachdata);
          }

          result += validatefacilityID();
          result += validateCategory();
          result += validatefacilityname();
          result += validatecriticalityType();
          result += validateLocation();
          result += validateDepartment();
          result += validateAccessibility();
          result += validateAccessibilityType();


          function validatefacilityID() {
            if (eachdata.facility_id == null)
              return `Sl.No: ${facility_id_index + 1
                } - Facility ID field is mandetory field - Facility Sheet \n`;
            return "";
          }
          function validateCategory() {
            if (eachdata.category == null)
              return `Sl.No: ${category_index + 1
                } - Category field is mandetory field - Facility Sheet \n`;
            return "";
          }
          function validatefacilityname() {
            if (eachdata.facility_name === null)
              return `Sl.No: ${facility_name_index + 1
                } - Facility Name field is mandetory field - Facility Sheet \n`;
            return "";
          }
          function validatecriticalityType() {
            if ((eachdata.criticality !== null) && ((eachdata.criticality !== 1) && (eachdata.criticality !== 2) && (eachdata.criticality !== 3)))
              return `Sl.No: ${criticality_type_index + 1
                } - Please select appropriate criticality either 1, 2 or 3 - Facility Sheet \n`;
            return "";
          }
          function validateLocation() {
            if (eachdata.location === null)
              return `Sl.No: ${location_index + 1
                } - Location field is mandetory field - Facility Sheet \n`;
            return "";
          }
          function validateDepartment() {
            if (eachdata.department === null)
              return `Sl.No: ${department_index + 1
                } - Department field is mandetory field - Facility Sheet \n`;
            return "";
          }
          function validateAccessibility() {
            if (eachdata.accessibility === null)
              return `Sl.No: ${accessibility_index + 1
                } - Accessibility field is mandetory field - Facility Sheet \n`;
            return "";
          }
          function validateAccessibilityType() {
            if ((eachdata.accessibility !== null) && ((eachdata.accessibility !== "Yes") && (eachdata.accessibility !== "No"))
            )
              return `Sl.No: ${accessibility_index + 1
                } - Please select appropriate Accessibility either Yes or No - Facility Sheet \n`;
            return "";
          }
        });

        const facilityData = MeagedDataFacility.map((x) =>
          x.criticality === null ? { ...x, criticality: "" } : x
        );
        // console.log(facilityData);

        resolve(MeagedDataFacility);

        items.asset = assetData;
        items.device = deviceData;
        items.facility = facilityData;

        setItems({
          ...items,
          asset: assetData,
          device: deviceData,
          facility: facilityData,
        });

        if (
          MeagedDataAsset.length === 0 &&
          MeagedDataDevice.length === 0 &&
          MeagedDataFacility.length === 0
        ) {
          alert("Please enter atleast one Asset / Device / Facility");
          setDisEmptyArray(true);
        }

        if (result == "") return setDisabled(false);
        alert(
          "Please resolve the following error in the attachment:\n\n" + result
        );
        return false;
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => { });
  };

  // dispatch action to redux for fetching site details
  const getSiteDetails = () => {
    dispatch({
      type: "SITE_GET_LIST",
    });
  };

  // useeffet
  useEffect(() => {
    if (siteData.length === 0) {
      getSiteDetails();
    }
  }, [siteData]);

  // function to handle site data
  const handleSiteData = (string) => {
    if (string.length !== null) {
      setIsDisabled(false);
    }
    let array = string.split(",");
    items.site_id = array[0];
  };
  // handle function to submit bulk upload details
  const handleSubmit = (e) => {
    e.preventDefault();
    const submitAssetData = [items.asset, items.device, items.facility];
    // console.log(submitAssetData);
    dispatch({
      type: "ASSET_BULK_ADD",
      payload: [items.site_id, submitAssetData],
    });
    props.onHide();
    navigate("/assetlist");
  };

  return (
    <div>
      {isLoading && <Loader />}
      {/* Modal for showing bulkupload feature */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Upload of Asset / Device / Facility
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <div className="ml-mb-2 col-md-12">
                <label>
                  Customer Name
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="custname"
                  className="form-select"
                  value={custname}
                  onChange={(e) => {
                    setCustname(e.target.value);
                    handleCustomer(e.target.value);
                    getSiteByCustId(e.target.value.split(",")[0]);
                  }}
                >
                  <option value="select">Select</option>
                  {custdata?.map((eachData) => {
                    return (
                      <option
                        value={[eachData._id, eachData.legal_name]}
                        key={eachData._id}
                      >
                        {eachData.legal_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className="ml-mb-2 col-md-12">
                <label>
                  Site Name
                  <span className="text-danger">*</span>
                </label>
                <select
                  id="sitename"
                  className="form-select"
                  value={sitename}
                  onChange={(e) => {
                    setSitename(e.target.value);
                    uploadAssetbySiteId(e.target.value.split(",")[0]);
                    handleSiteData(e.target.value);
                  }}
                >
                  <option value="">Select Customer Name First</option>
                  {siteData?.map((eachSite, index) => {
                    return (
                      <option
                        value={[eachSite._id, eachSite.site_name]}
                        key={eachSite._id}
                      >
                        {eachSite.site_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* fetch site data and show it in dropdown */}
            {/* <div className="form-group w-50">
                        <select id="sitename" className="form-control" label="Disabled Dropdown" onChange={(e) => {
                            handleSiteData(e.target.value);
                        }}>
                            <option>Select Site Name</option>
                            {
                                siteData?.map((eachSite) => {
                                    return (
                                        <option value={[eachSite._id, eachSite.site_name]} key={eachSite._id}>{eachSite.site_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div> */}
            <br />
            <div>
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
            </div>
          </div>
          <br />
          <br />
          <span>
            Download Asset, Device & Facility Bulk Upload Template -
            <a
              href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Asset_Bulk_Upload_V1_1.xlsx`}
            >
              Click here
            </a>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={isDisabled || disabled || wsdisabled || disEmptyArray}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BulkUpload;
