/* Feature - Reducer for Dashboard

Created by - Mona R
Updated by - Mona R */

import {
    INS_MAIN_GET_STATUS,
    INS_MAIN_GET_STATUS_SUCCESS,
    INS_MAIN_GET_STATUS_FAILED,

    FILTERED_INS_DATA,
    FILTERED_INS_DATA_SUCCESS,
    FILTERED_INS_DATA_FAILED,

    FILTERED_MAIN_DATA,
    FILTERED_MAIN_DATA_SUCCESS,
    FILTERED_MAIN_DATA_FAILED,

    INS_MAIN_GET_STATUS_OFFLINE,
    INS_MAIN_GET_STATUS_OFFLINE_SUCCESS,
    INS_MAIN_GET_STATUS_OFFLINE_FAILED,

} from '../actions/dashAction';

const inistialDashboardState = {
    InsMainStatusData: [],
    isSubmitted: false,
    isLoading: false,
};

export default function DashboardReducer(state = inistialDashboardState, action) {

    switch (action.type) {
        case INS_MAIN_GET_STATUS:
            return {
                ...state,
                isLoading: true,
            };
        case INS_MAIN_GET_STATUS_SUCCESS:
            // console.log(action.payload);
            return {
                ...state,
                InsMainStatusData: action.payload,
                isSubmitted: false,
                isLoading: false,
            };
        case INS_MAIN_GET_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case INS_MAIN_GET_STATUS_OFFLINE:
            return {
                ...state,
                isLoading: true,
            };
        case INS_MAIN_GET_STATUS_OFFLINE_SUCCESS:
            // console.log(action.payload);
            return {
                ...state,
                InsMainStatusData: action.payload,
                isSubmitted: false,
                isLoading: false,
            };
        case INS_MAIN_GET_STATUS_OFFLINE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case "CLEAR_DASH_REDUCER":
            return {
                ...state,
                InsMainStatusData: [],
                // FilteredInsData: [],
                // FilteredMainData: [],
            };
        default:
            return state;
    }
};