// Update Maintenance
// Frequency greater than a day
// 6 types of activities

/* Author - Janaki */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import CheckPoints from "./checkPoints";
import Approvals from "./approvals";
import Commonheader from "../../../layouts/common/commonHeader";
import { MAINTENANCE_UPDATE } from "../../../redux/actions/maintAction";
import PdfDocView from './pdfDocView';


const UpdateMaintenance = ({ action }) => {
  const isLoading = useSelector((state) => state.maintenance.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const maintGetSingle = useSelector(
    (state) => state.maintenance.maintGetSingle
  );
  const sopData = useSelector((state) => state.maintenance.sop);
  // console.log('sopData', sopData);


  //useState
  const [showDocModel, setShowDocModel] = useState(false);
  const [viewPdf, setViewPdf] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sopSelectedActivity, setsopSelectedActivity] = useState("");
  const [viewAction, setViewAction] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // console.log("maintGetSingle", maintGetSingl-+9*e);

  //useState
  const [showhide, setShowhide] = useState("");
  const [radioValue, setRadiovalue] = useState("");
  const [endValue, setEndValue] = useState("");

  const today = new Date().toISOString().slice(0, 10);

  const [data, setData] = useState({
    parent_mid: "",
    maintenance_name: "",
    maintenance_type: "",
    priority: "",
    based_on: "",
    managed_by: "",
    maintenance_duration: "",
    shutdown_duration: "",
    man_power_required: "",
    man_hour_required: "",
    frequency: {
      frequency_type: "", //"months",
      frequency_type_value: "",
      frequency_end_type: "",
      frequency_end_occurence_value: "",
      frequency_end_date_value: "",
    },
    maintenance_activities: [
      {
        ma_type: "",
        ma_name: "",
        ma_sequence: "",
        ma_sla: "",
        ma_sla_interval: "",
        sop: [],
        ma_assignee_type: "",
        ma_assignee: "",
        ma_status: "",
        ma_attachment: "",
        created_by: "",
        modified_by: "",
        checkpoints: [],
        approvals: {
          approval_category: "",
          sequence_approval_type: "",
          parallel_approval_type: "",
          parallel_type: "",
          approval_set: [],
        },
      },
    ],
  });

  // console.log(data);

  const [nameErr, setNameErr] = useState("");
  const [maintypeErr, setMaintypeErr] = useState("");
  const [priorityErr, setPriorityErr] = useState("");
  const [basedonErr, setBasedonErr] = useState("");
  const [managedByErr, setManagedbyErr] = useState("");
  const [freqErr, setFreqErr] = useState("");
  const [maintNameErr, setMaintNameErr] = useState("");
  const [assigneeTypeErr, setAssigneeType] = useState("");

  let isValid = true;

  const handleName = (val) => {
    setData({
      ...data,
      maintenance_name: val,
    });
  };
  const handleType = (val) => {
    setData({
      ...data,
      maintenance_type: val,
    });
  };
  const handleProity = (val) => {
    setRadiovalue(val);
    setData({
      ...data,
      priority: val,
    });
  };
  const handleBased = (val) => {
    setData({
      ...data,
      based_on: val,
    });
  };
  const handleManagedby = (val) => {
    setData({
      ...data,
      managed_by: val,
    });
  };
  const handleMaintDuration = (val) => {
    setData({
      ...data,
      maintenance_duration: val,
    });
  };
  const handleShutdwnDton = (val) => {
    setData({
      ...data,
      shutdown_duration: val,
    });
  };
  const handleManPower = (val) => {
    setData({
      ...data,
      man_power_required: val,
    });
  };
  const handleManHour = (val) => {
    setData({
      ...data,
      man_hour_required: val,
    });
  };
  const handleFreqType = (val) => {
    setData((prevState) => {
      prevState.frequency.frequency_type = val;
      return { ...prevState };
    });
  };
  const handleFreqEnd = (val) => {
    setEndValue(val);
    setData((prevState) => {
      prevState.frequency.frequency_end_type = val;
      return { ...prevState };
    });
  };

  //Radio button function
  const handleShow = (e) => {
    const getshow = e.target.value;
    setShowhide(getshow);
    setMaintenance({ ...maintenace, priority: e.target.value });
  };

  //Handle Inspection Frequency
  const handleMaintFreqcy = (e) => {
    setMaintenance((prevState) => {
      prevState.frequency.frequency_type_value = e;
      return { ...prevState };
    });
    setData((prevState) => {
      prevState.frequency.frequency_type_value = e;
      return { ...prevState };
    });
  };
  //Schedule End Radio Button
  const handleScheduleEnd = (e) => {
    const getshow = e.target.value;
    setShowhide(getshow);
    setMaintenance((prevState) => {
      prevState.frequency.end_type = getshow;
      return { ...prevState };
    });
  };
  const initialCalenderFrequency = {
    frequency_type: "", //"months",
    frequency_type_value: "",
    frequency_start: "", //["00","01","02","03",....]
    frequency_end_type: "", //'no_end'
    frequency_end_occurence_value: "",
    frequency_end_date_value: "",
  };
  const [calendarFreqncy, setCalendarFreqncy] = useState(
    initialCalenderFrequency
  );
  function sortByAcendingOrder(objectArray) {
    return objectArray.sort(
      (prev, curr) => Number(prev.ma_sequence) - Number(curr.ma_sequence)
    );
  }
  const addMaintChekpnts = (taskType, taskSeq) => {
    // console.log(taskType, taskSeq);
    let newMaObj = {
      ma_type: taskType,
      ma_name: "",
      ma_sequence: taskSeq,
      ma_sla: "",
      ma_sla_interval: "",
      sop: [],
      ma_sop: '',
      ma_assignee_type: "",
      ma_assignee: "",
      ma_status: "",
      ma_attachment: "",
      created_by: "",
      modified_by: "",
      checkpoints: [],
      approvals: {
        approval_category: "",
        sequence_approval_type: "",
        parallel_approval_type: "",
        parallel_type: "",
        approval_set: [],
      },
    };

    let updatedMaList = [...data.maintenance_activities, newMaObj];
    // variable to choose type only once
    const uniqueValues = new Set(updatedMaList.map((v) => v.ma_type));
    if (uniqueValues.size < updatedMaList.length) {
      return { ...data };
    }
    const groupedTasks = sortByAcendingOrder(updatedMaList);
    setData({ ...data, maintenance_activities: groupedTasks });
  };
  const deleteTask = (index) => {
    alert("Are you sure you wish to delete ?");
    let afterDelTask = data.maintenance_activities;
    afterDelTask.splice(index, 1);
    setData({ ...data, maintenance_activities: afterDelTask });
  };
  const addCheckPoints = (maIndex) => {
    let newcpObj = {
      checkpoint: "",
      approval_sequence: "",
      uom: "",
      standard_requirement: "",
      answer_type: "",
    };
    let taskList = data.maintenance_activities;
    taskList.forEach((mactvty, j) => {
      if (j === maIndex) {
        mactvty.checkpoints.push(newcpObj);
      }
    });
    let updatedTaskList = [...taskList];
    setData({ ...data, maintenance_activities: updatedTaskList });
  };
  const deleteCheckPoints = (taskIndex, itemIndex) => {
    let taskItemList = data.maintenance_activities[taskIndex].checkpoints;
    taskItemList.splice(itemIndex, 1);
    setData({ ...data, checkpoints: taskItemList });
  };
  const initialMaintenanceState = {
    parent_mid: "",
    maintenance_name: "",
    maintenance_type: "",
    priority: "",
    based_on: "",
    managed_by: "",
    assignee_type: "",
    assignee: "",
    // maintenance_duration: "",
    shutdown_duration: "",
    man_power_required: "",
    man_hour_required: "",
    frequency: calendarFreqncy,
    created_by: userid,
    maintenance_activities: [],
  };
  const [maintenace, setMaintenance] = useState(initialMaintenanceState);

  //functions for validation
  function nameChars(str) {
    const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialChars.test(str);
  }
  function timeChars(str) {
    const integers = /^[1-9][0-9]{9}$/;
    // const integers = /^[1-9][0-9]{9}$/;
    return integers.test(str);
  }

  function slaChars(str) {
    const zipCodeChars = /^[1-9][0-9]{1}$/;
    return zipCodeChars.test(str);
  }

  //fetch ID
  const sendID = () => {
    if (id !== null) {
      // console.log("id" , id);
      dispatch({
        type: "MAINT_GET_SINGLE",
        payload: id,
      });
    }
  };



  // console.log('data',data);

  const updateMaintenance = (e) => {
    e.preventDefault();
    if (data.maintenance_activities.length === 0) {
      alert("Please add atleast one activity", 2.5);
    } else {
      let updateMaintData = {
        parent_mid: maintGetSingle.parent_mid,
        maintenance_name: data.maintenance_name,
        maintenance_type: data.maintenance_type,
        priority: data.priority,
        based_on: data.based_on,
        managed_by: data.managed_by,
        assignee_type: data.assignee_type,
        assignee: data.assignee,
        maintenance_duration: data.maintenance_duration,
        shutdown_duration: data.shutdown_duration,
        man_power_required: data.man_power_required,
        man_hour_required: data.man_hour_required,
        frequency: {
          frequency_type: data.frequency.frequency_type,
          frequency_type_value: data.frequency.frequency_type_value,
          // frequency_start: data.frequency.frequency_start,
          frequency_end_type: data.frequency.frequency_end_type,
          frequency_end_occurence_value:
            data.frequency.frequency_end_occurence_value,
          frequency_end_date_value: data.frequency.frequency_end_date_value,
        },
        created_by: userid,
        modified_by: userid,
        maintenance_activities: data.maintenance_activities,
      };
      // console.log("updateMaintData", updateMaintData);
      dispatch({
        type: MAINTENANCE_UPDATE,
        payload: [updateMaintData, id],
      });
      navigate("/maintenancelist");
    }
  };

  const assigned = (selectedActivity) => {
    const arrayFromString = viewPdf?.split(',');
    let SOPdata = {
      maintenance_activities: {
        ma_type: selectedActivity
      },
      is_sop: true,
      name: arrayFromString[1],
      url: arrayFromString[0],
      // ma_type: data.maintenance_activities.map((el, i) => el.ma_type),
      assigned_by: userid,
    }
    // console.log('SOPdata', SOPdata);
    dispatch({
      type: "ASSIGN_SOP_MAIN",
      payload: [SOPdata, id],
    });
    let updateMaintData = {
      parent_mid: maintGetSingle.parent_mid,
      maintenance_name: data.maintenance_name,
      maintenance_type: data.maintenance_type,
      priority: data.priority,
      based_on: data.based_on,
      managed_by: data.managed_by,
      assignee_type: data.assignee_type,
      assignee: data.assignee,
      maintenance_duration: data.maintenance_duration,
      shutdown_duration: data.shutdown_duration,
      man_power_required: data.man_power_required,
      man_hour_required: data.man_hour_required,
      frequency: {
        frequency_type: data.frequency.frequency_type,
        frequency_type_value: data.frequency.frequency_type_value,
        // frequency_start: data.frequency.frequency_start,
        frequency_end_type: data.frequency.frequency_end_type,
        frequency_end_occurence_value:
          data.frequency.frequency_end_occurence_value,
        frequency_end_date_value: data.frequency.frequency_end_date_value,
      },
      created_by: userid,
      modified_by: userid,
      maintenance_activities: data.maintenance_activities,
    };
    dispatch({
      type: MAINTENANCE_UPDATE,
      payload: [updateMaintData, id],
    });
  }

  //useEffects
  useEffect(() => {
    sendID();
  }, [id]);

  useEffect(() => {
    if (maintGetSingle !== null) {
      const getRadioValues = maintGetSingle.priority;
      setRadiovalue(getRadioValues);
      // setRadiovalue(getRadioValues);
    }
  }, [maintGetSingle]);

  useEffect(() => {
    if (maintGetSingle !== null) {
      const getValues = maintGetSingle.frequency.frequency_end_type;
      setEndValue(getValues);
    }
  }, [maintGetSingle]);

  useEffect(() => {
    if (maintGetSingle !== null) {
      setData({
        parent_mid: maintGetSingle.parent_mid,
        maintenance_name: maintGetSingle.maintenance_name,
        maintenance_type: maintGetSingle.maintenance_type,
        priority: maintGetSingle.priority,
        based_on: maintGetSingle.based_on,
        managed_by: maintGetSingle.managed_by,
        maintenance_duration: maintGetSingle.maintenance_duration,
        shutdown_duration: maintGetSingle.shutdown_duration,
        man_power_required: maintGetSingle.man_power_required,
        man_hour_required: maintGetSingle.man_hour_required,
        frequency: {
          frequency_type: maintGetSingle.frequency.frequency_type,
          frequency_type_value: maintGetSingle.frequency.frequency_type_value,
          frequency_start: maintGetSingle.frequency.frequency_start,
          frequency_end_type: maintGetSingle.frequency.frequency_end_type,
          frequency_end_occurence_value:
            maintGetSingle.frequency.frequency_end_occurence_value,
          frequency_end_date_value:
            maintGetSingle.frequency.frequency_end_date_value,
        },
        maintenance_activities: maintGetSingle.maintenance_activities,
      });
    }
    // console.log("maintGetSingle", maintGetSingle);
  }, [maintGetSingle]);

  useEffect(() => {
    if (maintGetSingle?.parent_mid) {
      dispatch({
        type: 'SOP_GET',
        payload: maintGetSingle?.parent_mid
      })
    }
  }, [maintGetSingle?.parent_mid])


  useEffect(() => {
    // console.log('action', action);
    if (action !== undefined && action === 'ins_view') {
      setViewAction(true)
    }
  }, [action])

  return (
    <div>
      <Commonheader />
      {isLoading && <Loader />}
      <div className="container">
        <div className="row">
          <div className="d-flex">
            <div className="col-md-6">
              <form className="mt-5 border p-4 bg-light shadow rounded">
                {/* Add Schedule form starts */}
                <h3 style={{ textAlign: "center" }}>
                  {(action === 'ins_view' && action !== undefined) ? 'View Planned Maintenance' : 'Edit Planned Maintenance'}
                </h3>
                <div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Planned maintenance name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="schedulename"
                      className="form-control"
                      placeholder="Maintenance name"
                      defaultValue={data.maintenance_name}
                      onChange={(e) => handleName(e.target.value)}
                      disabled={viewAction}
                    />
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Planned maintenance type
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="type"
                      className="form-select"
                      placeholder="Select"
                      value={data.maintenance_type}
                      onChange={(e) => {
                        handleType(e.target.value);
                      }}
                      disabled={viewAction}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="autonomous">Autonomous</option>
                      <option value="preventive">Preventive</option>
                      <option value="predictive">Predictive</option>
                    </select>
                    {Object.keys(maintypeErr).map((key) => {
                      if (!maintenace.maintenance_type) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {maintypeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Priority
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="priority"
                      id="priority"
                      value={radioValue}
                      onChange={(e) => handleProity(e.target.value)}
                      checked={radioValue === "low"}
                      disabled
                    />
                    &nbsp;Low
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="priority"
                      id="priority"
                      value={radioValue}
                      onChange={(e) => handleProity(e.target.value)}
                      checked={radioValue === "medium"}
                      disabled
                    />
                    &nbsp; Medium
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="priority"
                      id="priority"
                      value={radioValue}
                      onChange={(e) => handleProity(e.target.value)}
                      checked={radioValue === "high"}
                      disabled
                    />
                    &nbsp; High
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Schedule based on
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="based_on"
                      className="form-select"
                      value={data.based_on}
                      onChange={(e) => {
                        handleBased(e.target.value);
                      }}
                      disabled={viewAction}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="calendar">Calendar</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Managed by
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="managed_by"
                      className="form-select"
                      value={data.managed_by}
                      onChange={(e) => {
                        handleManagedby(e.target.value);
                      }}
                      disabled={viewAction}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="inhouse">In house</option>
                      <option value="external">External</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Maintenance Duration(Mins)</label>
                    <input
                      name="duration"
                      className="form-control"
                      placeholder="Minutes"
                      defaultValue={data.maintenance_duration}
                      onChange={(e) => handleMaintDuration(e.target.value)}
                      disabled={viewAction}
                    />
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Shutdown Duration(Mins)</label>
                    <input
                      name="duration"
                      className="form-control"
                      placeholder="Minutes"
                      value={data.shutdown_duration}
                      onChange={(e) => handleShutdwnDton(e.target.value)}
                      disabled={viewAction}
                    />
                    {timeChars(maintenace.shutdown_duration) ||
                      maintenace.shutdown_duration === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Time
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man power required</label>
                    <input
                      min="1"
                      name="man_power"
                      className="form-control"
                      placeholder="Total man power"
                      value={data.man_power_required}
                      onChange={(e) => handleManPower(e.target.value)}
                      disabled={viewAction}
                    />
                    {timeChars(maintenace.man_power_required) ||
                      maintenace.man_power_required === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid man power
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man hour required</label>
                    <input
                      min="1"
                      name="man_hour"
                      className="form-control"
                      placeholder="Total man hour"
                      value={data.man_hour_required}
                      onChange={(e) => handleManHour(e.target.value)}
                      disabled={viewAction}
                    />
                    {timeChars(maintenace.man_hour_required) ||
                      maintenace.man_hour_required === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid man hour
                          </div>
                        ))}
                  </div>
                  <button
                    className="backButton m-2"
                    type="button"
                    onClick={() => {
                      navigate("/maintenancelist");
                    }}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
            <div className="mx-2 col-md-12">
              <form className="mt-5 border p-4 bg-light shadow rounded col-md-6">
                <h4>Calendar based on </h4>
                <label>
                  Frequency
                  <span className="text-danger">*</span>
                </label>
                <select
                  name="frequency"
                  className="form-select"
                  placeholder="Select"
                  value={data.frequency.frequency_type}
                  onChange={(e) => handleFreqType(e.target.value)}
                  disabled={viewAction}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="half_yearly">Half yearly</option>
                  <option value="yearly">Yearly</option>
                </select>
                {Object.keys(freqErr).map((key) => {
                  if (!data.frequency.frequency_type) {
                    return (
                      <div key={key} style={{ color: "red", fontSize: "12px" }}>
                        {freqErr[key]}
                      </div>
                    );
                  } else {
                    <p></p>;
                  }
                })}
                {/* Frequency type === Yearly */}
                {data.frequency.frequency_type === "yearly" && (
                  <div className="mt-3">
                    <label>Frequency value</label>
                    <input
                      style={{
                        marginTop: 5,
                        height: 35,
                        paddingLeft: "10px",
                        borderRadius: 5,
                        border: "1px solid #ccc",
                      }}
                      min="1"
                      className="col-md-12"
                      placeholder="Number of years"
                      value={data.frequency.frequency_type_value}
                      onChange={(e) => handleMaintFreqcy(e.target.value)}
                      disabled={viewAction}
                    />
                  </div>
                )}
                {/* Frequency type === Weekly */}
                {data.frequency.frequency_type === "weekly" && (
                  <div className="mt-3">
                    <label>Frequency value</label>
                    <input
                      style={{
                        marginTop: 5,
                        height: 35,
                        paddingLeft: "10px",
                        borderRadius: 5,
                        border: "1px solid #ccc",
                      }}
                      min="1"
                      className="col-md-12"
                      placeholder="Number of weeks"
                      value={data.frequency.frequency_type_value || ""}
                      onChange={(e) => handleMaintFreqcy(e.target.value)}
                      disabled={viewAction}
                    />
                    {/* {timeChars(maintenace.frequency.value) ||
                    maintenace.frequency.value === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Value
                          </div>
                        ))} */}
                  </div>
                )}
                {/* Frequency type === Monthly */}
                {data.frequency.frequency_type === "monthly" && (
                  <div className="mt-3">
                    <label>Frequency value</label>
                    <input
                      style={{
                        marginTop: 5,
                        height: 35,
                        paddingLeft: "10px",
                        borderRadius: 5,
                        border: "1px solid #ccc",
                      }}
                      min="1"
                      className="col-md-12"
                      placeholder="Number of months"
                      value={data.frequency.frequency_type_value}
                      onChange={(e) => handleMaintFreqcy(e.target.value)}
                      disabled={viewAction}
                    />
                  </div>
                )}
                {/* Frequency type === Quarterly -------------->change backend */}
                {data.frequency.frequency_type === "quarterly" && (
                  <div className="mt-3">
                    <label>Frequency value</label>
                    <input
                      style={{
                        marginTop: 5,
                        height: 35,
                        paddingLeft: "10px",
                        borderRadius: 5,
                        border: "1px solid #ccc",
                      }}
                      min="1"
                      className="col-md-12"
                      placeholder="Number of months"
                      value={data.frequency.frequency_type_value}
                      onChange={(e) => handleMaintFreqcy(e.target.value)}
                      disabled={viewAction}
                    />
                  </div>
                )}
                {/* Frequency type === half_yearly -------------->change backend*/}
                {data.frequency.frequency_type === "half_yearly" && (
                  <div className="mt-3">
                    <label>Frequency value</label>
                    <input
                      style={{
                        marginTop: 5,
                        height: 35,
                        paddingLeft: "10px",
                        borderRadius: 5,
                        border: "1px solid #ccc",
                      }}
                      min="1"
                      className="col-md-12"
                      placeholder="Number of months"
                      value={data.frequency.frequency_type_value}
                      onChange={(e) => handleMaintFreqcy(e.target.value)}
                      disabled={viewAction}
                    />
                  </div>
                )}
                {/* Schedule End types */}
                <div className="mt-4 md-6">
                  <label>Maintenance End Types</label>
                  <div>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px", margin: "8px" }}
                      type="radio"
                      name="endType"
                      id="endType"
                      value={endValue}
                      onChange={(e) => handleFreqEnd(e.target.value)}
                      checked={endValue === "no_end"}
                      disabled
                    />
                    No End<br></br>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px", margin: "8px" }}
                      type="radio"
                      name="endType"
                      id="endType"
                      value={endValue}
                      onChange={(e) => handleFreqEnd(e.target.value)}
                      checked={endValue === "end_after_occurence"}
                      disabled
                    />
                    End After Occurences
                    {maintenace.frequency.end_type ===
                      "end_after_occurence" && (
                        <input
                          style={{
                            marginLeft: 10,
                            border: "1px solid #CCC",
                            borderRadius: 5,
                            paddingLeft: "8px",
                          }}
                          min="1"
                          placeholder="Occurences"
                          onChange={(e) =>
                            setMaintenance((prevState) => {
                              prevState.frequency.occur_value = e.target.value;
                              return { ...prevState };
                            })
                          }
                          disabled={viewAction}
                        />
                      )}
                    <br></br>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px", margin: "8px" }}
                      type="radio"
                      name="endType"
                      id="endType"
                      value={endValue}
                      onChange={(e) => handleFreqEnd(e.target.value)}
                      checked={endValue === "end_date"}
                      disabled
                    />
                    End Date
                    {maintenace.frequency.end_type === "end_date" && (
                      <input
                        style={{
                          marginLeft: 35,
                          width: 190,
                          border: "1px solid #CCC",
                          borderRadius: 5,
                          height: 30,
                        }}
                        type="date"
                        placeholder="Date"
                        onChange={(e) =>
                          setMaintenance((prevState) => {
                            prevState.frequency.date_value = e.target.value;
                            return { ...prevState };
                          })
                        }
                        min={today}
                        disabled={viewAction}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* {console.log(data.maintenance_activities)} */}
          {/* Tasks */}
          {data.maintenance_activities &&
            data.maintenance_activities.map(
              (task, i) =>
                <div key={i} className="col-md-10">
                  <div className="mt-4 p-5 bg-light shadow rounded">
                    <div
                      className="col-md-12"
                      style={{
                        // width: "600px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      {task.ma_type === "pre_maintenance_approval" && (
                        <h4>Pre Maintenance Approval</h4>
                      )}
                      {task.ma_type === "pre_maintenance_check" && (
                        <h4>Pre Maintenance Check</h4>
                      )}
                      {task.ma_type === "maintenance_checkpoint" && (
                        <h4>Maintenance checkpoint</h4>
                      )}
                      {task.ma_type === "spare_change" && (
                        <h4>Spare Change</h4>
                      )}
                      {task.ma_type === "post_maintenance_check" && (
                        <h4>Post Maintenance Check</h4>
                      )}
                      {task.ma_type === "post_maintenance_approval" && (
                        <h4>Post Maintenance Approval</h4>
                      )}
                      <h6>Task number : {i + 1}</h6>
                      <h6>Sequence #{task.ma_sequence}</h6>
                      {/* delete icon */}
                      <div className="mb-2 col-md-2">
                        <button
                          className="delIcons float-end"
                          type="button"
                          onClick={() => deleteTask(i)}
                          disabled={viewAction}
                        >
                          <FontAwesomeIcon icon={faTrash} size="lg" />
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="mb-2 col-md-12">
                        <label>
                          Maintenance activity name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="taskname"
                          className="form-control"
                          placeholder="Activity name"
                          value={task.ma_name}
                          onChange={(e) => {
                            setData((prevState) => {
                              prevState.maintenance_activities[i].ma_name =
                                e.target.value;
                              return { ...prevState };
                            });
                            // handleAssignee(e.target.value);
                          }}
                          disabled={viewAction}
                        />
                        {Object.keys(maintNameErr).map((key) => {
                          if (!data.maintenance_activities[i].ma_name) {
                            return (
                              <div
                                key={key}
                                style={{ color: "red", fontSize: "12px" }}
                              >
                                {maintNameErr[key]}
                              </div>
                            );
                          } else {
                            <p></p>;
                          }
                        })}
                        {nameChars(data.maintenance_activities[i].ma_name) ||
                          data.maintenance_activities[i].ma_name === ""
                          ? " "
                          : ((isValid = false),
                            (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  paddingTop: "2px",
                                }}
                              >
                                Invalid activity name
                              </div>
                            ))}
                      </div>
                      <div className="mb-2 col-md-12">
                        <label>SLA</label>
                        <input
                          maxLength={5}
                          minLength={2}
                          name="sla"
                          className="form-control"
                          placeholder="SLA"
                          value={task.ma_sla}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) =>
                            setData((prevState) => {
                              prevState.maintenance_activities[i].ma_sla =
                                e.target.value;
                              return { ...prevState };
                            })
                          }
                          disabled={viewAction}
                        />
                        {slaChars(task.ma_sla) ? null : (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            {/* Invalid SLA */}
                          </div>
                        )}
                      </div>
                      <div className="mb-2 col-md-12">
                        <label>SLA Interval</label>
                        <select
                          name="interval"
                          className="form-select"
                          value={task.ma_sla_interval}
                          onChange={(e) =>
                            setData((prevState) => {
                              prevState.maintenance_activities[
                                i
                              ].ma_sla_interval = e.target.value;
                              return { ...prevState };
                            })
                          }
                          disabled={viewAction}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="hours">Hours</option>
                          <option value="minutes">Minutes</option>
                        </select>
                      </div>
                      {(task.ma_type !== "pre_maintenance_approval" &&
                        task.ma_type !== "post_maintenance_approval") && (
                          <div className="mb-2 col-md-12">
                            <label>SOP</label>
                            <select
                              name="interval"
                              className="form-select"
                              value={task?.ma_sop}
                              onChange={(e) =>
                                setViewPdf(e.target.value) &&
                                setData((prevState) => {
                                  prevState.maintenance_activities[
                                    i
                                  ].ma_sop = e.target.value;
                                  return { ...prevState };
                                })
                              }
                              disabled={viewAction}
                            >
                              {sopData?.sop?.map((eachData, index) => (
                                <>
                                  <option>Select</option>
                                  <option key={index} value={[eachData.sop, eachData.name]}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "10px", colour: 'red' }}>
                                      <span>{eachData.name}</span>
                                      {/* <span onClick={() => setShowDocModel(true)}>View</span> */}
                                    </div>
                                  </option>
                                </>
                              ))}
                            </select>
                            <br />
                            {viewPdf && (
                              <button
                                className="addBtn"
                                onClick={() => {
                                  setShowDocModel(true);
                                  // setViewPdf(data.maintenance_activities[i].sop)
                                  setsopSelectedActivity(task?.ma_type);
                                }
                                }
                                disabled={viewAction}
                              >
                                Assign & View Selected SOP
                              </button>
                            )}
                          </div>
                        )}
                      <h5>List of Mapped SOP with this Activity</h5>
                      {data?.maintenance_activities[i]?.sop?.map((el, index) =>
                        <ul className="sopList">
                          <li>
                            {el.name}
                          </li>
                        </ul>

                      )}
                      {/* <div className="mb-2 col-md-12">
                      <label>Attachments</label>
                      <br></br>
                      <input
                        className="display-none"
                        label="attachments"
                        type="file"
                      ></input>
                    </div> */}
                      {(task.ma_type === "pre_maintenance_approval" ||
                        task.ma_type === "post_maintenance_approval") && (
                          <div>
                            <Approvals
                              maintenanceState={data}
                              setMaintenenceState={setData}
                              maState={task}
                              maIndex={i}
                              action={viewAction}
                            />
                          </div>
                        )}

                      {(task.ma_type === "pre_maintenance_check" ||
                        task.ma_type === "maintenance_checkpoint" ||
                        task.ma_type === "post_maintenance_check" ||
                        task.ma_type === "inspection_checkpoint") && (
                          <div>
                            <CheckPoints
                              maintenanceState={data}
                              setMaintenenceState={setData}
                              maState={task}
                              maIndex={i}
                              addCpItem={(maIndex) => addCheckPoints(maIndex)}
                              deleteCpItem={(maIndex, cpIndex) =>
                                deleteCheckPoints(maIndex, cpIndex)
                              }
                              action={viewAction}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
            )}

          {/* Submit Schedule Button */}
          <div className="mt-10 col-md-12 p-2">
            {/* {data.based_on === "calendar" && ( */}
            <div className="dropdown">
              <button
                style={{ fontSize: "14px" }}
                className="float-end addBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={viewAction}
              >
                Add Activites
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="pre_maintenance_approval"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "1");
                      // setDisabled(true);
                    }}
                  >
                    Pre Maintenance Approval
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="pre_maintenance_check"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "2");
                      // setDisabled(true);
                    }}
                  >
                    Pre Maintenance Check
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="maintenance_checkpoint"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "3");
                      // setDisabled(true);
                    }}
                  >
                    Maintenance Checkpoint
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="post_maintenance_check"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "5");
                      // setDisabled(true);
                    }}
                  >
                    Post Maintenance Check
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="post_maintenance_approval"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "6");
                      // setDisabled(true);
                    }}
                  >
                    Post Maintenance Approval
                  </button>
                </li>
                <li>
                  <button
                    disabled={disabled}
                    className="dropdown-item"
                    type="button"
                    value="spare_change"
                    onClick={(e) => {
                      addMaintChekpnts(e.target.value, "4");
                      // setDisabled(true);
                    }}
                  >
                    Spare Change
                  </button>
                </li>
              </ul>
            </div>
            {/* )} */}
          </div>
          {viewAction !== true &&
            <div className="mt-10 col-md-12 p-2">
              <button
                className="saveButton float-end"
                onClick={(e) => updateMaintenance(e)}
                disabled={viewAction}
              >
                Save
              </button>
            </div>
          }
        </div>
      </div>
      {showDocModel && <PdfDocView
        show={showDocModel}
        onHide={() => setShowDocModel(false)}
        viewlink={viewPdf}
        assigned={assigned}
        selectedactivity={sopSelectedActivity}
      />}
    </div>
  );
};

export default UpdateMaintenance;
