/* Feature - saga for login

Created by - Mona R
Updated by - Mona R */

import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
} from "../actions/authActions";
import { authService } from "../../services/service.index";
import { Persistor } from "../store.index";

// export function for saga activity
export function* watchLogin() {
  yield takeLatest(LOGIN, logInSaga);
}
export function* watchLogout() {
  yield takeLatest(LOGOUT, logOutSaga);
}

export function* logInSaga(action) {
  try {
    const response = yield call(authService.logIn, action.payload);
    // console.log(response);
    if (response.status === 200 && response.data !== "Not Allowed") {
      // alert("loggedIn!")
      yield put({
        type: LOGIN_SUCCESS,
        payload: response,
      });

      window.localStorage.setItem("userToken", response.data.accessToken);
    } else {
      alert(response.data.message);
      // console.log("logInSaga Error response", response)
      yield put({
        type: LOGIN_FAILED,
      });
    }
  } catch (e) {
    // console.log("logInSaga Error response", e)
    yield put({
      type: LOGIN_FAILED,
    });
    alert("Invalid Login ID or Password");
  }
}
export function* logOutSaga(action) {
  try {
    // NOTE: Loggin out user whether success or failed logout (Now commented. see forceLogout() in interceptor)
    // console.log("logOutSaga action", action);
    const response = yield call(authService.logOut, action.payload);
    // console.log("logOutSaga Success", response);
    if (response.status === 200 || response.status === 204) {
      // console.log("logOutSaga Success", response);
      yield put({
        type: LOGOUT_SUCCESS,
        payload: response,
      });
      yield put({
        type: "CLEAR_ACCESS_REDUCER",
      });
      yield put({
        type: "CLEAR_ASSET_REDUCER",
      });
      yield put({
        type: "CLEAR_CUST_REDUCER",
      });
      yield put({
        type: "CLEAR_DASH_REDUCER",
      });
      yield put({
        type: "CLEAR_DEVICE_REDUCER",
      });
      yield put({
        type: "CLEAR_FACILITY_REDUCER",
      });
      yield put({
        type: "CLEAR_INSPECTION_REDUCER",
      });
      yield put({
        type: "CLEAR_MAINTENANCE_REDUCER",
      });
      yield put({
        type: "CLEAR_SITE_REDUCER",
      });
      yield put({
        type: "CLEAR_USER_REDUCER",
      });
      yield put({
        type: "CLEAR_HISTORY_REDUCER",
      });
      yield put({
        type: "CLEAR_REPORT_REDUCER",
      });
      localStorage.clear();
      Persistor.purge();
      sessionStorage.removeItem("persist:root");
    } else {
      // alert(response.data.message);
      // console.log("logOutSaga Error response", response)
      yield put({
        type: LOGOUT_FAILED,
      });
    }
  } catch (e) {
    // console.log("logInSaga Error response", e)
    yield put({
      type: LOGOUT_FAILED,
    });
  }
}
