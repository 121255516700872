import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreatableSelect from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Toggle from "react-toggle";
import "react-toggle/style.css";
import moment from "moment";

function InsAssign(props) {
  const dispatch = useDispatch();

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const today = new Date().toISOString().slice(0, 16);
  // console.log(today);

  const [items, setItems] = useState({
    date: "",
    userID: [],
    approverID: []
  });

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" ref={ref} onClick={onClick} style={{
      width: '320px',
      height: '38px',
      borderRadius: '5px',
      textAlign: 'left',
      border: "1px solid #ccc",
      paddingLeft: '10px'
    }}
    >
      {value ? value : "Select Date"}
    </button>
  ));

  if (items.date !== "") {
    // const date = new Date(items.date);
    // date.setHours(date.getHours() + 5);
    // date.setMinutes(date.getMinutes() + 30);
    // var formattedDate = date.toISOString().slice(0, 16)
    var formattedDate = moment(items.date).utc().format()
  }
  // console.log('formattedDate', formattedDate);

  const [assign, setAssign] = useState("");
  const [autoApproval, setAutoApproval] = useState(false);

  const userData = useSelector((state) => state.user.userTechData);
  const userid = useSelector((state) => state.auth.userid);
  const approverData = useSelector((state) => state.user.approverData);

  // console.log(approverData);
  // console.log(userData);

  const ID = props.id;
  // console.log(ID);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPastDates = (date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    return date.getTime() >= currentDate.getTime();
  };

  const handleDate = (val) => {
    // console.log(val);
    // date = val
    setItems({
      ...items,
      date: val,
    });
  };
  const handleUserID = (val) => {
    // console.log(val);
    if (val !== null) {
      const key = 'value';
      const data = val.map((obj) => obj[key]);
      // console.log(data);
      setItems({
        ...items,
        userID: data,
      });
    }
  };
  const handleapprovalID = (val) => {
    // console.log(val);
    if (val !== null) {
      const key = 'value';
      const data = val.map((obj) => obj[key]);
      // console.log(data);
      setItems({
        ...items,
        approverID: data,
      });
    }
  };

  // console.log(items.userID);

  const options = userData.map((eachData) => ({
    value: eachData.user_id,
    label: eachData.email,
  }));
  const optionsApprover = approverData.map((eachData) => ({
    value: eachData.user_id,
    label: eachData.email,
  }));

  // dispatch to get all available user list
  const getUserDetails = () => {
    dispatch({
      type: "USER_GET_TECH",
    });
  };

  // dispatch to get all available approver list
  const getApproverDetails = () => {
    dispatch({
      type: "USER_GET_APPROVER",
    });
  };

  const handleSubmit = () => {
    // console.log('date', date);
    // console.log('userID', userID);
    // console.log(ID);
    let inspectionAssignData = {
      frequency: {
        frequency_start: formattedDate,
      },
      assignee: items.userID,
      approver: items.approverID,
      is_auto_approve: autoApproval,
      inspection_status: "assigned",
      modified_by: userid,
    };
    // console.log(inspectionAssignData);
    // console.log((inspectionAssignData.frequency.frequency_start === '') || (inspectionAssignData.assignee === ''));
    // console.log(typeof (inspectionAssignData.frequency.frequency_start));
    // console.log(typeof (inspectionAssignData.assignee));
    if ((inspectionAssignData.frequency.frequency_start === undefined) && (inspectionAssignData.assignee === '') && (props.basedon === 'calendar')) {
      alert("Please Select Date & UserID")
    }
    if (
      (inspectionAssignData.frequency.frequency_start === undefined) && (props.basedon === 'calendar')
    ) {
      alert("Please Select Date");
    }
    if (
      (inspectionAssignData.assignee === "") && (props.basedon === 'calendar')
    ) {
      alert("Please Select UserID");
    }
    // console.log((inspectionAssignData.frequency.frequency_start !== '') && (inspectionAssignData.assignee !== ''));
    if (
      (inspectionAssignData.frequency.frequency_start !== "") &&
      (inspectionAssignData.assignee !== "") && (props.basedon === 'calendar')
    ) {
      dispatch({
        type: "INSPECTION_ASSIGN_LIST",
        payload: [inspectionAssignData, ID, props.pagenodata],
      });
      props.onHide();
    }
    if (props.basedon === 'on_demand') {
      dispatch({
        type: "INSPECTION_ASSIGN_LIST",
        payload: [inspectionAssignData, ID, props.pagenodata],
      });
      props.onHide();
    }
  };
  useEffect(() => {
    // console.log(userData);
    if (userData.length === 0) {
      getUserDetails();
      getApproverDetails();
    }
  }, [userData]);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Inspection Assign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-10 offset-md-1" >
            {props.basedon === "calendar" &&
              <div>
                <div style={{ width: "100%" }}>
                  <select
                    name="type"
                    className="form-select mb-4"
                    value={assign}
                    onChange={(e) => {
                      setAssign(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="auto_assign">Auto Assign</option>
                    <option value="manual_assign">Manual Assign</option>
                  </select>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="mb-4" style={{ width: '50%', marginRight: '10px' }}>
                    <DatePicker
                      showIcon
                      selected={items.date}
                      onChange={(date) => handleDate(date)}
                      filterDate={filterPastDates}
                      filterTime={filterPassedTime}
                      showTimeSelect
                      timeIntervals={1}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      customInput={<CustomInput />}
                    />
                    <br />
                    <br />
                    {assign === "auto_assign" && (
                      <div style={{ width: "100%" }}>
                        <label>Assign user - Perform Task</label>
                        <CreatableSelect
                          isMulti
                          isClearable
                          formatCreateLabel={() => undefined}
                          options={options}
                          onChange={(option) => {
                            handleUserID(option);
                          }}
                          placeholder="Select User"
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%", borderLeft: "1px solid #ccc", paddingLeft: "10px" }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '38px' }}>
                      <label style={{ marginRight: "10px", }}>Auto Approval</label>
                      <Toggle
                        checked={autoApproval === true}
                        icons={false}
                        onChange={(e) => {
                          setAutoApproval(e.target.checked ? true : false);
                        }}
                      />
                    </div>
                    <br />
                    {autoApproval === false &&
                      <div style={{ width: "100%", }}>
                        <label>Assign user - Approval</label>
                        <CreatableSelect
                          isMulti
                          isClearable
                          formatCreateLabel={() => undefined}
                          options={optionsApprover}
                          onChange={(option) => {
                            handleapprovalID(option);
                          }}
                          placeholder="Select Approval ID"
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            {props.basedon === "on_demand" &&
              <div style={{ width: "100%", paddingLeft: "10px" }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '38px' }}>
                  <label style={{ marginRight: "10px", }}>Auto Approval</label>
                  <Toggle
                    checked={autoApproval === true}
                    icons={false}
                    onChange={(e) => {
                      setAutoApproval(e.target.checked ? true : false);
                    }}
                  />
                </div>
                <br />
                {autoApproval === false &&
                  <div style={{ width: "100%", }}>
                    <label>Assign user - Approval</label>
                    <CreatableSelect
                      isMulti
                      isClearable
                      formatCreateLabel={() => undefined}
                      options={optionsApprover}
                      onChange={(option) => {
                        handleapprovalID(option);
                      }}
                      placeholder="Select Approval ID"
                    />
                  </div>
                }
              </div>
            }
            <button
              onClick={() => {
                window.confirm(
                  "Are you sure you wish to initiate this inspection?"
                ) && handleSubmit();
              }}
              className="float-end saveButton mt-5"
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default InsAssign;
