import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  MAINT_ADD,
  MAINT_ADD_SUCCESS,
  MAINT_ADD_FAILED,
  MAINT_GET_LIST,
  MAINT_GET_SUCCESS,
  MAINT_GET_FAILED,
  MAINT_DELETE_LIST,
  MAINT_DELETE_SUCCESS,
  MAINT_DELETE_FAILED,
  MAINTENANCE_BULK_ADD,
  MAINTENANCE_BULK_ADD_SUCCESS,
  MAINTENANCE_BULK_ADD_FAILED,
  MAINTENANCE_REPORT,
  MAINTENANCE_REPORT_SUCCESS,
  MAINTENANCE_REPORT_FAILED,
  MAINTENANCE_ASSIGN_LIST,
  MAINTENANCE_ASSIGN_SUCCESS,
  MAINTENANCE_ASSIGN_FAILED,
  MAINT_GET_SINGLE,
  MAINT_GET_SINGLE_SUCCESS,
  MAINT_GET_SINGLE_FAILED,
  MAINTENANCE_UPDATE,
  MAINT_UPDATE_SUCCESS,
  MAINT_UPDATE_FAILED,
  MAINTENANCE_STATUS,
  MAINTENANCE_STATUS_SUCCESS,
  MAINTENANCE_STATUS_FAILED,
  MAINTENANCE_STOP,
  MAINTENANCE_STOP_SUCCESS,
  MAINTENANCE_STOP_FAILED,
  SOP_GET,
  SOP_GET_SUCCESS,
  SOP_GET_FAILED,
  ASSIGN_SOP_MAIN,
  ASSIGN_SOP_MAIN_SUCCESS,
  ASSIGN_SOP_MAIN_FAILED
} from "../actions/maintAction";

import { maintService } from "../../services/service.index";

export function* watchAddMaint() {
  yield takeLatest(MAINT_ADD, addMaintSaga);
}
export function* watchGetMaintenance() {
  yield takeLatest(MAINT_GET_LIST, getMaintSaga);
}
export function* watchBulkAddMaint() {
  yield takeLatest(MAINTENANCE_BULK_ADD, bulkAddMaintenanceSaga);
}
export function* watchMaintReportGet() {
  yield takeLatest(MAINTENANCE_REPORT, getMaintenanceReportSaga);
}
export function* watchAssignMaintenance() {
  yield takeLatest(MAINTENANCE_ASSIGN_LIST, assignMaintenanceSaga);
}
export function* watchDeleteMaintenance() {
  yield takeLatest(MAINT_DELETE_LIST, deleteMaintenanceSaga);
}
export function* watchGetSingleMaint() {
  yield takeLatest(MAINT_GET_SINGLE, getSingleMaintSaga);
}
export function* watchMaintUpdate() {
  yield takeLatest(MAINTENANCE_UPDATE, updateMaintSaga);
}
export function* watchGetMaintStatus() {
  yield takeLatest(MAINTENANCE_STATUS, getMaintStatus);
}
export function* watchStopMaintenanceStatus() {
  yield takeLatest(MAINTENANCE_STOP, stopMaintenanceSaga);
}
export function* watchSOPGet() {
  yield takeLatest(SOP_GET, sopGetSaga);
}
export function* watchSOPAssign() {
  yield takeLatest(ASSIGN_SOP_MAIN, sopAssignSaga);
}
//upload inspection details
export function* addMaintSaga(action) {
  try {
    const response = yield call(maintService.addMaintData, action.payload);
    if (response.status === 201) {
      yield put({
        type: MAINT_ADD_SUCCESS,
        payload: response,
      });
      yield put({
        type: MAINT_GET_LIST,
      });
      alert("Maintenance Uploaded Successfully");
    } else {
      yield put({
        type: MAINT_ADD_FAILED,
      });
    }
  } catch (e) {
    // console.log("error", e);
    yield put({
      type: MAINT_ADD_FAILED,
    });
  }
}
export function* getMaintSaga(action) {
  try {
    const response = yield call(maintService.getMaintData, action.payload);
    // console.log('response', response);
    if (response.status === 200) {
      yield put({
        type: MAINT_GET_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: MAINT_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e);
    yield put({
      type: MAINT_GET_FAILED,
    });
  }
}
export function* bulkAddMaintenanceSaga(action) {
  try {
    const response = yield call(maintService.addBulkMainData, action.payload);
    // console.log("addBulkMaintenance action.payload", action.payload);
    if (response.status === 201) {
      // console.log("addBulkMaintenance response", response);
      const data = action.payload.response
      // yield put({
      //   type: MAINT_GET_LIST,
      //   payload: [data[2], 1, 10, '']
      // });
      alert("Maintenance Data uploaded Successfully");
    } else {
      // console.log("MAINTENANCE_BULK_ADD_FAILED Error response", response);
      yield put({
        type: MAINTENANCE_BULK_ADD_FAILED,
      });
    }
  } catch (e) {
    // console.log("addBulkMaintenance Error response", e);
    if (e.response.data.message === "Duplicate maintenance name entered") {
      alert("Duplicate maintenance name entered");
    }
    yield put({
      type: MAINTENANCE_BULK_ADD_FAILED,
    });
    alert("Error!", e.response.data.message, [{ text: "Okay" }]);
  }
}
export function* assignMaintenanceSaga(action) {
  try {
    const response = yield call(maintService.assignMaintData, action.payload);
    // console.log(action.payload);
    if (response.status === 200) {
      const data = action.payload;
      // console.log(response);
      yield put({
        type: MAINTENANCE_ASSIGN_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: MAINT_GET_LIST,
        payload: data[2]
      });
    } else {
      yield put({
        type: MAINTENANCE_ASSIGN_FAILED,
      });
      alert("Maintenance Assigned Successfully")
    }
  } catch (e) {
    // console.log(e);
    yield put({
      type: MAINTENANCE_ASSIGN_FAILED,
    });
  }
}
export function* deleteMaintenanceSaga(action) {
  try {
    // console.log("viewUserSaga action", action)
    const response = yield call(
      maintService.deleteMaintenanceData,
      action.payload
    );
    if (response.status === 200) {
      // console.log("delete Maintenance Data response", response)
      const data = action.payload;
      yield put({
        type: MAINT_GET_LIST,
        payload: [data[0], data[1], data[2], data[3]]
      });
      alert("Maintenance Data Deleted Successfully");
    } else {
      // console.log("MAINT_DELETE_FAILED Error response", response)
      yield put({
        type: MAINT_DELETE_FAILED,
      });
    }
  } catch (e) {
    // console.log(e)
    yield put({
      type: MAINT_DELETE_FAILED,
    });
  }
}

// Report
export function* getMaintenanceReportSaga(action) {
  try {
    const response = yield call(maintService.maintenanceReport, action.payload);
    // console.log("getMaintenanceReportSaga response", response);
    if (response.status === 200) {
      yield put({
        type: MAINTENANCE_REPORT_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getInspectionSaga Error response", response)
      yield put({
        type: MAINTENANCE_REPORT_FAILED,
      });
    }
  } catch (e) {
    // console.log("getInspectionSaga Error response", e)
    yield put({
      type: MAINTENANCE_REPORT_FAILED,
    });
  }
}

export function* getSingleMaintSaga(action) {
  // console.log("action", action.payload);
  try {
    const response = yield call(maintService.getMaintSingle, action.payload);
    // console.log("response", response);
    if (response.status === 200) {
      yield put({
        type: MAINT_GET_SINGLE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: MAINT_GET_SINGLE_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e);
    yield put({
      type: MAINT_GET_SINGLE_FAILED,
    });
  }
}
//update inspection details
export function* updateMaintSaga(action) {
  // console.log("action", action.payload);
  try {
    const response = yield call(maintService.updateMaintData, action.payload);
    if (response.status === 200) {
      yield put({
        type: MAINT_UPDATE_SUCCESS,
        payload: action.payload,
      });
      alert("Maintenance Updated Successfully");
      yield put({
        type: MAINT_GET_LIST,
      });
    } else {
      yield put({
        type: MAINT_UPDATE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: MAINT_UPDATE_FAILED,
    });
  }
}
export function* getMaintStatus(action) {
  // console.log("action", action);
  try {
    const response = yield call(
      maintService.maintenanceGetallStatus,
      action.payload
    );
    // console.log("response", response);
    if (response.status === 200) {
      yield put({
        type: MAINTENANCE_STATUS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: MAINTENANCE_STATUS_FAILED,
      });
    }
  } catch (e) {
    // console.log("error", e);
    yield put({
      type: MAINTENANCE_STATUS_FAILED,
    });
  }
}
export function* stopMaintenanceSaga(action) {
  try {
    const response = yield call(maintService.stopMaintenanceData, action.payload);
    // console.log(action.payload);
    // console.log(response);
    if ((response.status === 200) || (response.status === 204)) {
      const data = action.payload
      yield put({
        type: MAINTENANCE_STOP_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: MAINT_GET_LIST,
        payload: data[2]
      });
      alert("Maintenance stopped successfully")
    } else {
      yield put({
        type: MAINTENANCE_STOP_FAILED,
      });
    }
  } catch (e) {
    // console.log(e);
    yield put({
      type: MAINTENANCE_STOP_FAILED,
    });
  }
}
export function* sopGetSaga(action) {
  try {
    const response = yield call(maintService.SOPByAssetID, action.payload);
    // console.log(action.payload);
    // console.log(response);
    if ((response.status === 200) || (response.status === 204)) {
      yield put({
        type: SOP_GET_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: SOP_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log(e);
    yield put({
      type: SOP_GET_FAILED,
    });
  }
}
export function* sopAssignSaga(action) {
  try {
    const response = yield call(maintService.assignSOPData, action.payload);
    // console.log(action.payload);
    // console.log(response);
    if ((response.status === 200) || (response.status === 204)) {
      yield put({
        type: ASSIGN_SOP_MAIN_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: ASSIGN_SOP_MAIN_FAILED,
      });
    }
  } catch (e) {
    // console.log(e);
    yield put({
      type: ASSIGN_SOP_MAIN_FAILED,
    });
  }
}