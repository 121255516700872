import React from 'react'
import MenuItem from './MenuItem';
import './RestaurantV1_0.css';
import BaslFooter from './baslFooter';
import BaslHeader from './baslHeader';

const Menu = () => {
    const Imagename1 = {
        QILA1: require("./Images/Fist_Image.jpg"),
        QILA2: require("./Images/third_img.png")
    }
    return (
        <div className="mainContainer">
            <BaslHeader />
            <header className="app-header" >
                <div className="menu">
                    <MenuItem
                        title="QILA Beverage"
                        // logo={Imagename1.QILA1}
                        to="QILA"
                    />
                    <MenuItem
                        title="Radio House"
                        // logo={Imagename1.QILA1}
                        to="RADIO"
                    />
                    <MenuItem
                        title="Shalimar Beverage"
                        // logo={Imagename1.QILA1}
                        to="shalimar-beverage"
                    />
                </div>
            </header>
            {/* <footer>
                <h6>
                    <span>  © 2024 Powered by </span>
                    <a style={{ textDecoration: "none", color: 'black', cursor: 'pointer' }} href="https://www.zongovita.com" target="_blank" rel="noopener noreferrer">
                        ZongoVita Tech Pvt. Ltd.
                    </a>
                    <span> v0.10.35</span>
                </h6>
            </footer> */}
            <BaslFooter />
        </div>
    )
}

export default Menu