/* Feature - Reducer for user

Created by - Mona R
Updated by - Mona R */

import {
  USER_ADD,
  USER_ADD_SUCCESS,
  USER_ADD_FAILED,
  USER_GET_LIST,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  ROLE_GET_LIST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILED,
  USER_SET_PASSWORD,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD_FAILED,
  USER_GET_SINGLE,
  USER_GET_SINGLE_SUCCESS,
  USER_GET_SINGLE_FAILED,
  GET_APPROV_USERS,
  GET_APPROV_USERS_SUCCESS,
  GET_APPROV_USERS_FAILED,
  USER_GET_TECH,
  USER_GET_TECH_SUCESS,
  USER_GET_TECH_FAILED,
  USER_GET_APPROVER,
  USER_GET_APPROVER_SUCESS,
  USER_GET_APPROVER_FAILED
} from "../actions/userAction";

const initialUserState = {
  approvalUserData: [],
  userData: [],
  userSingleData: {},
  userAddData: {},
  isSubmitted: false,
  isLoading: false,
  roleData: [],
  userTechData: [],
  approverData: [],
};

export default function UserReducer(state = initialUserState, action) {
  // console.log("reducer-action.payload", action.payload);
  switch (action.type) {
    case USER_GET_LIST:
      return {
        ...state,
        isLoading: true,
        isSubmitted: true
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        userData: action.payload.data,
        isLoading: false,
        isSubmitted: false
      };
    case USER_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_APPROV_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_APPROV_USERS_SUCCESS:
      return {
        ...state,
        approvalUserData: action.payload.data,
        isLoading: false,
      };
    case GET_APPROV_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case USER_GET_SINGLE:
      return {
        ...state,
        isLoading: true,
      };
    case USER_GET_SINGLE_SUCCESS:
      return {
        ...state,
        userSingleData: action.payload.data,
        isLoading: false,
      };
    case USER_GET_SINGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case USER_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ADD_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        userAddData: action.payload,
        isSubmitted: true,
      };
    case USER_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ROLE_GET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case ROLE_GET_SUCCESS:
      return {
        ...state,
        roleData: action.payload.data,
        isLoading: false,
      };
    case ROLE_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case USER_SET_PASSWORD:
      return {
        ...state,
      };
    case USER_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
      };
    case USER_SET_PASSWORD_FAILED:
      return {
        ...state,
      };
    case USER_GET_TECH:
      return {
        ...state,
        isLoading: true,
      };
    case USER_GET_TECH_SUCESS:
      // console.log(action.payload.data);
      return {
        ...state,
        userTechData: action.payload.data,
        isLoading: false,
      };
    case USER_GET_TECH_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case USER_GET_APPROVER:
      return {
        ...state,
        isLoading: true,
      };
    case USER_GET_APPROVER_SUCESS:
      // console.log(action.payload.data);
      return {
        ...state,
        approverData: action.payload.data,
        isLoading: false,
      };
    case USER_GET_APPROVER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case 'CLEAR_USER_REDUCER':
      return {
        ...state,
        approvalUserData: [],
        userData: [],
        userSingleData: {},
        userAddData: {},
        roleData: [],
        userTechData: [],
        approverData:[],
      };
    default:
      return state;
  }
}
