import React, { useState, useEffect, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import './RestaurantV1_0.css';
import styles from '../PrivacyPolicy/PrivacyPolicy.module.css'
import IMAGENAME from "../../medias/media.index";
import IMAGENAMEMenu from './image.index'
import { useParams, useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import BaslFooter from "./baslFooter";
import BaslHeader from "./baslHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faLink } from "@fortawesome/free-solid-svg-icons";

// Set workerSrc to the URL of the PDF worker file
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function BIALMenuLink() {


    const [key, setKey] = useState("menu");

    const [numPages, setNumPages] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const containerRef = useRef(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        const handleResize = () => {
            setWidth(containerRef.current ? containerRef.current.offsetWidth : window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const { id } = useParams();

    return (
        <div className="mainContainer" >
            <BaslHeader />
            {/* <div className={styles.logo} style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }} >
                <img
                    className={styles.logoImage}
                    style={{ cursor: "pointer", }}
                    src={IMAGENAME.logo}
                    width="auto"
                    alt="logo"
                    height="25"

                />
            </div>
            <br />
            <br />
            <br /> */}
            {/* <div > */}
            {/* <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="m-3 headings"
                    style={{ gap: '0.5%', margin: '2%' }}
                > */}
            {/* <Tab eventKey="menu" title="QILA BEVERAGE MENU"> */}
            {id === 'QILA' &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: 'scroll',
                    marginTop: '8rem'
                }}>
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer" }}
                        src={IMAGENAMEMenu.menuQILA1}
                        width="100%"
                        alt="logo"
                        height='auto'
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA2}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA3}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA4}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA5}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA6}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA7}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA8}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA9}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA10}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuQILA11}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                </div>}
            {/* </Tab> */}
            {/* <Tab eventKey="menu1" title="RADIO HOUSE BEVERAGE MENU"> */}
            {id === 'RADIO' &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: '8rem'
                }}>
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO1}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO2}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO3}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO4}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO5}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO6}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO7}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO8}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO9}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO10}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.menuRADIO11}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                </div>}
            {/* </Tab> */}
            {/* <Tab eventKey="map" title="Office Layout"> */}
            {/* <MaintHistory eventKey={key} /> */}
            {id === 'Office-Layout' &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: '8rem'
                }}>
                    <img
                        className={styles.logoImage}
                        style={{ cursor: "pointer", }}
                        src={IMAGENAMEMenu.officeLayout1}
                        width="100%"
                        alt="logo"
                        height="auto"
                    />
                </div>}
            {/* </Tab> */}
            {/* </Tabs> */}
            {
                id == "shalimar-beverage" &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // width: "80%",
                    // border: '1px solid'
                    marginTop: '8rem'

                }}>
                    <Document ref={containerRef} style={{ width: '100%', overflowX: 'hidden' }}
                        // style={{ width: '100%' }}
                        file="/ShalimarMenu_compressed.pdf"
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
                        ))}
                    </Document>
                </div>
            }
            {id === 'promotions' &&
                <div style={{
                    display: "flex",
                    // flexDirection: "column",
                    // alignItems: "center",
                    // alignContent: 'center',
                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: '8rem',
                    height: "100%",
                    color: 'gray',
                    // border: "1px solid black"
                }}>
                    <div>
                        <FontAwesomeIcon icon={faFileLines} size="2xl" color="gray" style={{ margin: '1rem' }} />
                        <h5>Content to be updated</h5>
                    </div>
                </div>}
            {id === 'feedback' &&
                <div style={{
                    display: "flex",
                    // flexDirection: "column",
                    // alignItems: "center",
                    // alignContent: 'center',
                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: '8rem',
                    height: "100%",
                    color: 'gray',
                    // border: "1px solid black"
                }}>
                    <div>
                        <FontAwesomeIcon icon={faLink} size="2xl" color="gray" style={{ margin: '1rem' }} />
                        <h5>Link to be embedded</h5>
                    </div>
                </div>}
            {/* </div> */}
            <BaslFooter />

        </div>
    )
}

export default BIALMenuLink
