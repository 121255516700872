import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  CUST_GET_LIST,
  CUST_GET_FAILED,
  CUST_GET_SUCCESS,
  CUST_DELETE_LIST,
  CUST_DELETE_FAILED,
  CUST_ADD,
  CUST_ADD_SUCCESS,
  CUST_ADD_FAILED,
  CUST_UPDATE,
  CUST_UPDATE_SUCCESS,
  CUST_UPDATE_FAILED,
  CUST_GET_SINGLE,
  CUST_GET_SINGLE_SUCCESS,
  CUST_GET_SINGLE_FAILED,

  // customer contact details ADD
  CUST_CONTACT_DETAILS,
  CUST_CONTACT_DETAILS_SUCCESS,
  CUST_CONTACT_DETAILS_FAILED,

  // get customer contact list
  CUST_CONTACT_GET,
  CUST_CONTACT_GET_SUCCESS,
  CUST_CONTACT_GET_FAILED,

  // delete customer contact list
  CUST_CONTACT_DELETE,
  CUST_CONTACT_DELETE_FAILED,

  // custmer bulk upload
  CUST_BULK_ADD,
} from "../actions/custAction";

import { custService } from "../../services/service.index";

//Watcher functions
export function* watchGetCust() {
  yield takeLatest(CUST_GET_LIST, getCustSaga);
}

export function* watchDeleteCust() {
  yield takeLatest(CUST_DELETE_LIST, deleteCustSaga);
}

export function* watchAddCust() {
  yield takeLatest(CUST_ADD, addCustSaga);
}

export function* watchCutomerContact() {
  yield takeLatest(CUST_CONTACT_DETAILS, addCustContactsaga);
}

export function* watchCutomerGetContact() {
  yield takeLatest(CUST_CONTACT_GET, getCustContactsaga);
}

export function* watchCutomerUpdate() {
  yield takeLatest(CUST_UPDATE, CustUpdatesaga);
}

export function* watchSingleCustomerget() {
  yield takeLatest(CUST_GET_SINGLE, CustGetSinglesaga);
}

export function* watchBulkAddCust() {
  yield takeLatest(CUST_BULK_ADD, bulkAddCustSaga);
}

export function* watchCustContactdelete() {
  yield takeLatest(CUST_CONTACT_DELETE, deleteCustContactSaga);
}

//fetch customer details to read
export function* getCustSaga(action) {
  try {
    const response = yield call(custService.getCustData, action.payload);
    if (response.status === 200) {
      yield put({
        type: CUST_GET_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: CUST_GET_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: CUST_GET_FAILED,
    });
  }
}
//delete customer details
export function* deleteCustSaga(action) {
  try {
    const response = yield call(custService.deleteCustData, action.payload);
    if (response.status === 200) {
      yield put({
        type: CUST_GET_LIST,
      });
      alert("Customer Deleted Successfully");
    } else {
      yield put({
        type: CUST_DELETE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: CUST_DELETE_FAILED,
    });
  }
}
//upload customer details
export function* addCustSaga(action) {
  try {
    const response = yield call(custService.addCustData, action.payload);
    // console.log(response, action.payload);
    if (response.status === 201) {
      let obj = {
        subscriberData: action.payload,
        customerId: response.data._id,
      };
      yield put({
        type: CUST_ADD_SUCCESS,
        payload: obj,
      });
      yield put({
        type: CUST_GET_LIST,
      });
      // alert("Customer Uploaded Successfully");
    } else {
      yield put({
        type: CUST_ADD_FAILED,
      });
    }
  } catch (e) {
    // console.log("error", e);
    if (e.response.data.message === "Duplicate legal_name entered") {
      alert("Duplicate Legal Name Entered");
    }
    if (e.response.data.message === "Duplicate website entered") {
      alert("Duplicate Website Entered");
    }
    if (e.response.data.message === "invalid subscriber") {
      alert("Please contact your Admin for Subscriber ID");
    }
    yield put({
      type: CUST_ADD_FAILED,
    });
  }
}
//upload customer contact details
export function* addCustContactsaga(action) {
  try {
    const response = yield call(custService.addCustContact, action.payload);
    // console.log(response);
    if (response.status === 201) {
      let obj = {
        contactdetails: action.payload.newObj,
        customerId: action.payload.id,
      };
      yield put({
        type: CUST_CONTACT_DETAILS_SUCCESS,
        payload: obj,
      });
      alert("Customer Contact Uploaded Successfully");
      yield put({
        type: CUST_CONTACT_GET,
        payload: obj.customerId,
      });
    } else {
      yield put({
        type: CUST_CONTACT_DETAILS_FAILED,
      });
    }
  } catch (e) {
    // console.log(e)
    yield put({
      type: CUST_CONTACT_DETAILS_FAILED,
    });
  }
}
//update customer details
export function* CustUpdatesaga(action) {
  try {
    // console.log(action.payload);
    const response = yield call(custService.updateCust, action.payload);
    // console.log(response);
    if (response.status === 200) {
      yield put({
        type: CUST_UPDATE_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: CUST_GET_LIST,
      });
      alert("Customer Updated Successfully");
    } else {
      yield put({
        type: CUST_UPDATE_FAILED,
      });
    }
  } catch (e) {
    if (e.response.data.message === "invalid subscriber") {
      alert("Please contact your Admin for Subscriber ID");
    }
    yield put({
      type: CUST_UPDATE_FAILED,
    });
  }
}
//fetch single customer details to update
export function* CustGetSinglesaga(action) {
  try {
    const response = yield call(custService.getSingleCustomer, action.payload);
    if (response.status === 200) {
      yield put({
        type: CUST_GET_SINGLE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: CUST_GET_SINGLE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: CUST_GET_SINGLE_FAILED,
    });
  }
}
//fetch customer contact details to read
export function* getCustContactsaga(action) {
  try {
    const response = yield call(custService.getCustContact, action.payload);
    if (response.status === 200) {
      yield put({
        type: CUST_CONTACT_GET_SUCCESS,
        payload: response.data.contacts,
      });
    } else {
      yield put({
        type: CUST_CONTACT_GET_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: CUST_GET_FAILED,
    });
  }
}
//customer bulk upload
export function* bulkAddCustSaga(action) {
  try {
    const response = yield call(custService.addBulkCustomer, action.payload);
    // console.log(response);
    if (response.status === 201) {
      alert("Data Uploaded Successfully");
      yield put({
        type: CUST_GET_LIST,
      });
    } else {
    }
  } catch (e) {
    // console.log(e);
    if (e.response.data.message === "Duplicate legal_name entered") {
      alert("Duplicate Legal Name Entered");
    }
    if (e.response.data.message === "Duplicate website entered") {
      alert("Duplicate Website Entered");
    }
    if (e.response.data.message === "invalid subscriber") {
      alert("Please contact your Admin for Subscriber ID");
    }
    // alert("Error!", e.response.data.message, [{ text: "Okay" }]);
  }
}
//delete customer contact details
export function* deleteCustContactSaga(action) {
  try {
    const response = yield call(
      custService.deleteCustContactData,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: CUST_CONTACT_GET,
        payload: action.payload.id,
      });
    } else {
      yield put({
        type: CUST_CONTACT_DELETE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: CUST_CONTACT_DELETE_FAILED,
    });
  }
}
