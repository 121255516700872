/* Feature - to maintain header for dashboard page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Bulkupload from "../../modules/customer/bulkUpload";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../components/logo";

//Function for customer header
function Dashboard(props) {
    const [selectedTab, setSelectedTab] = useState(props.selectedTab);
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);

    // To fetch user role code & access based on role
    const role = useSelector((state) => state.auth.role);
    const access = useSelector((state) => state.access.customerAccess);

    useEffect(() => {
        const roleCode = role[0];
        // console.log(access);
        // console.log(roleCode);
        if (access === null && roleCode !== null) {
            dispatch({
                type: "ACCESS_GET_LIST",
                payload: roleCode,
            });
        }
    }, [access]);

    return (
        <div>
            <div>
                <Navbar bg="light" variant={"light"} expand="lg">
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" className="headershadow">
                        <Nav
                            className="mr-auto my-2 my-lg-0"
                            style={{ maxHeight: "100px" }}
                        >
                            <Sidebar />
                            <Logo />
                            {/* <div>
                                <Nav.Link
                                    as={Link}
                                    to="/home"
                                    onClick={() => setSelectedTab("dashboard")}
                                    className="position-absolute top-25 end-0 header"
                                    style={{
                                        color: "#000",
                                        marginRight: "320px",
                                        borderRadius: '10px',
                                        backgroundColor: selectedTab === "dashboard" ? "#64FFE2" : "",
                                    }}
                                >
                                    Dashboard
                                </Nav.Link>
                            </div> */}
                            {/* <div>
                                <Nav.Link
                                    onClick={() => setSelectedTab("trend")}
                                    as={Link}
                                    to="/trend"
                                    className="position-absolute top-25 end-0 header"
                                    style={{
                                        color: "#000", marginRight: "250px",
                                        borderRadius: '10px',
                                        backgroundColor: selectedTab === "trend" ? "#64FFE2" : ""
                                    }}
                                >
                                    Trend
                                </Nav.Link>
                            </div> */}
                            <LogoutButton />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Outlet />
            </div>
        </div>
    );
}

export default Dashboard;
