import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'


function PMC7({ responseData, filterData, chklname, docNumber, frequency,data4,headingName }) {

    const [resData, setResData] = useState([])

    const data1 = {
        data: "MAINTENANCE",
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }
    const data3 = {
        data: `${headingName} FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')} `,
        colSpan: '7'
    }

    const data5 = [
        { data: 'SI. No', colSpan: '2', rowSpan: '1', textAlign: 'center' },
        { data: 'Date', colSpan: '2', rowSpan: '1', textAlign: 'center' },
        { data: 'Check For Oil / Fuel Level (Ok / Not Ok)', textAlign: 'center', colSpan: '3', rowSpan: '1', },
        { data: 'Check and rectify the Unusual Sound (Ok / Not Ok)', textAlign: 'center', colSpan: '3', rowSpan: '1', },
        { data: 'Check and rectify / replace the leaks(Ok / Not Ok)', textAlign: 'center', colSpan: '3', rowSpan: '1', },
        { data: 'Clean Air Filters and Air Duct (Cleaned / Not Cleaned)', textAlign: 'center', colSpan: '3', rowSpan: '1', },
        { data: 'Clean Oil Sludge and dust from the engine and accessories(Cleaned / Not Cleaned)', textAlign: 'center', colSpan: '4', rowSpan: '1', },
        { data: 'Engine running hours', textAlign: 'center', colSpan: '2', rowSpan: '1', },
        { data: 'KWH reading', textAlign: 'center', colSpan: '2', rowSpan: '1', },
        { data: 'Done by', textAlign: 'center', colSpan: '2', rowSpan: '1', },
        { data: 'Checked By', textAlign: 'center', colSpan: '2', rowSpan: '1', }
    ]

    const tableResData = resData?.checklist?.map((item, index) => ({
        data0: {
            value: index + 1,
            style: { textAlign: 'center',colSpan: '2' }, // Define inline style here
        },
        data1: {
            value: item?.start_date && item?.start_date!==undefined? moment(item?.start_date).local().format("DD/MM/YY"):'-',  // received email on 31st jan to maintain this date format.
            style: { textAlign: 'center',colSpan: '2' },
        },
        data2: {
            value: item?.checkpoints[0]?.answer!==''?item?.checkpoints[0]?.answer:'-',
            style: { textAlign: 'center',colSpan: '3' }, // Define inline style here
        },
        data3: {
            value: item?.checkpoints[1]?.answer!==''?item?.checkpoints[1]?.answer:'-',
            style: { textAlign: 'center',colSpan: '3' }, // Define inline style here
        },
        data4: {
            value: item?.checkpoints[2]?.answer!==''?item?.checkpoints[2]?.answer:'-',
            style: { textAlign: 'center',colSpan: '3' },
        },
        data5: {
            value: item?.checkpoints[3]?.answer!==''?item?.checkpoints[3]?.answer:'-',
            style: { textAlign: 'center' ,colSpan: '3'},
        },
        data6: {
            value: item?.checkpoints[4]?.answer!==''?item?.checkpoints[4]?.answer:'-',
            style: { textAlign: 'center',colSpan:'4' },
        },
        data7: {
            value: item?.checkpoints[5]?.answer!==''?item?.checkpoints[5]?.answer:'-',
            style: { textAlign: 'center',colSpan: '2' },
        },
        data8: {
            value: item?.checkpoints[6]?.answer!==''?item?.checkpoints[6]?.answer:'-',
            style: { textAlign: 'center',colSpan: '2' },
        },
        data9: {
            value: item?.performed_by?.length>0?item?.performed_by:'-',
            style: { textAlign: 'center',colSpan: '2' },
        },
        data10: {
            value: item?.ins_approval_id?.length>0?item?.ins_approval_id:'-',
            style: { textAlign: 'center' ,colSpan: '2'},
        },
    }));

    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
            />

            <ReportTable
                headerData={data4}

            />
            <ReportTable
                headerData={data5}
            />
            <ReportTable
                verticalBodyData={tableResData}
            />
        </div>
    )
}

export default PMC7