/* Feature - Reducer for Report

Created by - Mona R
Updated by - Mona R */

import {
    REP_BASEDON_USER_STATUS,
    REP_BASEDON_USER_STATUS_SUCCESS,
    REP_BASEDON_USER_STATUS_FAILED,
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAILED,
    GET_QUERY,
    GET_QUERY_SUCCESS,
    GET_QUERY_FAILED,
    GET_ATTRIBUTES,
    GET_ATTRIBUTES_SUCCESS,
    GET_ATTRIBUTES_FAILED,
    GET_FIELD_NAME,
    GET_FIELD_NAME_SUCCESS,
    GET_FIELD_NAME_FAILED,
    SAVE_QUERY,
    SAVE_QUERY_SUCCESS,
    SAVE_QUERY_FAILED,
    GET_QUERY_LIST,
    GET_QUERY_LIST_SUCCESS,
    GET_QUERY_LIST_FAILED,
    UPDATE_QUERY,
    UPDATE_QUERY_SUCCESS,
    UPDATE_QUERY_FAILED,
    DELETE_QUERY,
    DELETE_QUERY_SUCCESS,
    DELETE_QUERY_FAILED,
    SAVE_REPORT,
    SAVE_REPORT_FAILED,
    SAVE_REPORT_SUCCESS,


} from '../actions/reportAction'

const inistialReportState = {
    userBasedData: [],
    isSubmitted: false,
    isLoading: false,
    collection: [],
    queryData: [],
    attributeData: [],
    fieldData: [],
    queryList: {},
    isUpdated: false,
    isCreated: false,

};

export default function ReportReducer(state = inistialReportState, action) {
    // console.log(action.payload, "action.payload")

    switch (action.type) {
        case REP_BASEDON_USER_STATUS:
            return {
                ...state,
                isLoading: true,
            };
        case REP_BASEDON_USER_STATUS_SUCCESS:
            // console.log(action.payload);
            return {
                ...state,
                userBasedData: action?.payload,
                isSubmitted: false,
                isLoading: false,
            };
        case REP_BASEDON_USER_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case "CLEAR_REPORT_REDUCER":
            return {
                ...state,
                userBasedData: [],
            };
        case GET_COLLECTIONS:
            return {
                ...state,
                isLoading: true,
            };
        case GET_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collection: action?.payload,
                isLoading: false,
            };
        case GET_COLLECTIONS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_QUERY:
            return {
                ...state,
                isLoading: true,
            };
        case GET_QUERY_SUCCESS:
            return {
                ...state,
                queryData: action?.payload,
                isLoading: false,
            };
        case GET_QUERY_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_ATTRIBUTES:
            return {
                ...state,
                isLoading: true,
            };
        case GET_ATTRIBUTES_SUCCESS:
            // console.log("GET_ATTRIBUTES_SUCCESS", action?.payload)
            return {
                ...state,
                attributeData: [...state.attributeData, action?.payload],
                isLoading: false,
            };
        case GET_ATTRIBUTES_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_FIELD_NAME:
            return {
                ...state,
                isLoading: true,
            };
        case GET_FIELD_NAME_SUCCESS:
            return {
                ...state,
                fieldData: action?.payload?.data,
                isLoading: false,
            };
        case GET_FIELD_NAME_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case SAVE_QUERY:
            return {
                ...state,
                isLoading: true,
                isCreated: false,
            };
        case SAVE_QUERY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isCreated: true,
            };
        case SAVE_QUERY_FAILED:
            return {
                ...state,
                isLoading: false,
                isCreated: false,
            };
        case GET_QUERY_LIST:
            return {
                ...state,
                isLoading: true,
                isCreated: false,
            };
        case GET_QUERY_LIST_SUCCESS:
            return {
                ...state,
                queryList: action?.payload,
                isLoading: false,
                isCreated: false,

            };
        case GET_QUERY_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isCreated: false,
            };
        case UPDATE_QUERY:
            return {
                ...state,
                isLoading: true,
                isUpdated: false,

            };
        case UPDATE_QUERY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isUpdated: true,

            };
        case UPDATE_QUERY_FAILED:
            return {
                ...state,
                isLoading: false,
                isUpdated: false,
            };
        case DELETE_QUERY:
            return {
                ...state,
                isLoading: true,
            };
        case DELETE_QUERY_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_QUERY_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case SAVE_REPORT:
            return {
                ...state,
                isLoading : false,
            };
        case SAVE_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: true,
            };
        case SAVE_REPORT_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
};

/* state?.attributeData.some(
    (attributeItem) => attributeItem?.collectionName === action?.payload?.collectionName
)
    ? [...state?.attributeData]
    : [...state?.attributeData, action?.payload], */