/* Description
+----------------------------------------------------------------------
 * 
   Author: [Mona Raychura & Ajit Shelake]
   Purpose: [
            - All API calls enter from this file (i.e. Response)
            - All API calls finally leave from this file (i.e. Request)
            - Implementing axios interceptor function to set headers and other information before making request
            - Response error handling is done here
            - Token refresh is managed here
            ]envir
+----------------------------------------------------------------------
 */

/* Package Imports */
import axios from 'axios';
import store from '../../redux/store.index';
import { CONSTANTS } from '../../constants/URLs/urlConstants';

/* Token handling Methods */
/*---------------------------------------------------------------------
     |  Purpose:  [Global methods for handling actions on token: 
                    1) getting Stored token from local storage
                    2) storing new token in local storage and reducer
                  ]
     *-------------------------------------------------------------------*/

let requestArray = [];
let isRefreshing = false;

const getToken = async () => {
  let token = localStorage.getItem("userToken")
  // console.log('getToken', token);
  return token;
}
const redirectToLogin = async () => {
  requestArray = [];
  isRefreshing = false;
  let token = getToken();
  store.dispatch({
    type: "LOGOUT",
    payload: JSON.stringify(token)
  });
  localStorage.removeItem("userToken")
  alert(
    'Session has expired, please re-login.',
  )
}
/* Logout function */
/*---------------------------------------------------------------------
     |  Method [forceLogout]
     |
     |  Purpose:  [Function for logging out user if token is invalid ]
     |
     |  Parameters: [reason -- reason/message for logging out user]
     *-------------------------------------------------------------------*/

const forceLogout = async () => {
  requestArray = [];
  isRefreshing = false;
  localStorage.removeItem("userToken")
  store.dispatch({
    type: "LOGOUT_SUCCESS"
  });
}

const storeToken = async (resData) => {
  const data = {
    accessToken: resData.accessToken,
  };
  store.dispatch({ // updating state with new token
    type: "REFRESH_TOKEN_SUCCESS",
    payload: data,
  });
  localStorage.setItem('userToken', resData.accessToken);
}

/* Configuration for axios Interceptor */
/*---------------------------------------------------------------------
     |  Purpose: - For setting global constants to API request.
                ( eg: adding necessary headers, url, etc. )
                 - Globally accessible for making all HTTP requests 
     *-------------------------------------------------------------------*/

let serviceBase = axios.create({
  //add necessary headers
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

/* Intercepting all requests */
serviceBase.interceptors.request.use(
  async config => {
    getToken().then((token) => {
      config.withCredentials = true;
      if (token != null) {
        config.headers = {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      }
    });
    return config;
  },
  error => {
    Promise.reject(error)
  });

/* Intercepting all responses */
serviceBase.interceptors.response.use(
  response => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const { status, data, config } = error.response;
    // console.log("error int", error);
    if (status === 401 && originalRequest.url.includes('/refresh')) { // add correct url for refresh 
      return forceLogout();
    }
    if (status === 403) {
      isRefreshing = true;
      // console.log("refresh starts")
      return await resetTokenAndReattemptRequest(error);
      // console.log("refRes", refRes)
      // return refRes;
    }
    if (status === 500) {
      alert("500 - Internal Server Error");
      return Promise.reject(error);
    }
    if (status === 204) {
      alert("204 - no content redirect to login");
      // console.log("redirectToLogin", status)
      return forceLogout();
    }
    if (status === 401) {
      // console.log("redirectToLogin", status)
      return forceLogout();
    }
    return Promise.reject(error);
  }
);
export default serviceBase;


// This is the list of waiting requests that will retry after the JWT refresh complete
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

/* Parent Function for refreshing token and then re-executing pending requests */
async function resetTokenAndReattemptRequest(error) {
  try {
    // console.log("1 - Request failed, starting token refresh logic");
    const { response: errorResponse } = error;
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const resetToken = await getToken();
      if (!resetToken) {
        // console.log("2 - No reset token available, forcing logout");
        forceLogout();
        return Promise.reject(error);
      }
      // console.log("3 - Reset token acquired, refreshing token");
      const response = await refreshToken(resetToken);
      if (!response.data || response.status === 401 || response.status === 403) {
        // console.log("4 - Token refresh failed, forcing logout");
        forceLogout();
        return Promise.reject(error);
      }
      const newToken = response.data.accessToken;
      // console.log("5 - New token acquired, notifying subscribers");
      onAccessTokenFetched(newToken);
      isAlreadyFetchingAccessToken = false;
    }
    const retryOriginalRequest = new Promise((resolve, reject) => {
      // console.log("12 - Adding request to subscriber queue");
      addSubscriber(async access_token => {
        // console.log("6 - Retrying original request with new token");
        // if (typeof errorResponse.config.headers !== 'object') {
        errorResponse.config.headers = {};
        // }
        // Set the new Authorization header
        serviceBase.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
        errorResponse.config.headers["Authorization"] = `Bearer ${access_token}`;
        errorResponse.config.headers["Content-Type"] = 'application/json';
        // console.log("11 - Retrying request", errorResponse.config);
        try {
          const response = await axios(errorResponse.config);
          resolve(response);
        } catch (err) {
          // console.log("Error during retrying original request:", err);
          reject(err);
        }
      });
    });
    return retryOriginalRequest;
  } catch (err) {
    // console.log("7 - Error during token refresh process", err);
    isAlreadyFetchingAccessToken = false;
    return Promise.reject(err);
  }
}

/* Function for executing pending requests */
function onAccessTokenFetched(access_token) {
  // console.log("8 - Notifying subscribers with new token:", access_token);
  subscribers.forEach(callback => {
    // console.log("8.1 - Executing subscriber callback");
    callback(access_token);
  });
  subscribers = [];
}

/* Function for queuing pending requests */
function addSubscriber(callback) {
  // console.log("9 - Adding subscriber to retry queue");
  subscribers.push(callback);
}

/* Function for making refresh token API call */
async function refreshToken(token) {
  // if (token != null) {
  const response = await serviceBase.get(CONSTANTS.REFRESH_TOKEN);
  if (response.status === 200) {
    storeToken(response.data);
    // console.log("refresh ended 200")
    isRefreshing = false;
    return response;
  }
  else {
    // console.log("refresh ended error", response)
  }
  // }
  isRefreshing = false;
  return response;
}