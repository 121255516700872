/* Feature - Reducer for access

Created by 
Updated by - Mona R */

import {
  ACCESS_GET_LIST,
  ACCESS_GET_SUCCESS,
  ACCESS_GET_FAILED,

  ROLE_GET_LIST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILED,
} from "../actions/accessAction";

const initialUserState = {
  webAccess: null,
  mobileAccess: null,
  accessData: null,
  customerAccess: null,
  customerContact: null,
  site: null,
  siteContact: null,
  asset: null,
  assetMaintenance: null,
  assetMaintenanceAssign: null,
  assetInspection: null,
  assetInspectionAssign: null,
  device: null,
  deviceMaintenance: null,
  deviceMaintenanceAssign: null,
  deviceInspection: null,
  deviceInspectionAssign: null,
  facility: null,
  facilityMaintenance: null,
  facilityMaintenanceAssign: null,
  facilityInspection: null,
  facilityInspectionAssign: null,
  qrGenerator: null,
  dashboard: null,
  user: null,
  workOrder: null,
  onDemandInspection: null,
  roleListData: null,
  accounting: null,
  plant_verification: null,
  asset_registration: null,
  fixed_asset_master: null,
  depreciation_calculation: null,
  slm_method: null,
  wdv_method: null,
  transfer_dispose: null,
  depreciation_report: null,
  asset_verification: null,
  report_mortality: null,
  verification_report: null,
  dashboard_plant: null,
  dashboard_accounting: null,
  audit_trial: null,
  cwip_list: null,
  capitalised_list: null,
  breakdown: null,
  custom_report: null,
  offline_access: null
};

export default function AccessReducer(state = initialUserState, action) {
  // console.log("reducer-action.payload", action.payload);
  switch (action.type) {
    case ACCESS_GET_LIST:
      return {
        ...state,
      };
    case ACCESS_GET_SUCCESS:
      // console.log("action.payload", action.payload);
      // console.log(action.payload.find((obj) => obj["web_login"]), 'action.payload[web_login]');
      // console.log(action.payload.find(obj => obj['asset_maintenance']))
      // var customerAccess = payload.find(obj => obj['customer'])
      return {
        ...state,

        // mobileAccess: null,

        accessData: action.payload,

        webAccess:
          action.payload.find((obj) => obj["web_login"]) ? action.payload.find((obj) => obj["web_login"]).web_login : 'not_availabe',

        customerAccess: action.payload.find((obj) => obj["customer"]) ? action.payload.find((obj) => obj["customer"]).customer : null,

        customerContact:
          action.payload.find((obj) => obj["customer_contact"]) ? action.payload.find((obj) => obj["customer_contact"]).customer_contact : null,

        site: action.payload.find((obj) => obj["site"]) ? action.payload.find((obj) => obj["site"]).site : null,

        siteContact:
          action.payload.find((obj) => obj["site_contact"]) ? action.payload.find((obj) => obj["site_contact"]).site_contact : null,

        asset:
          action.payload.find((obj) => obj["asset"]) ? action.payload.find((obj) => obj["asset"]).asset : null,

        assetMaintenance:
          action.payload.find((obj) => obj["asset_maintenance"]) ? action.payload.find((obj) => obj["asset_maintenance"]).asset_maintenance : null,

        assetMaintenanceAssign:
          action.payload.find((obj) => obj["asset_maintenance_assign"]) ? action.payload.find((obj) => obj["asset_maintenance_assign"]).asset_maintenance_assign : null,

        assetInspection:
          action.payload.find((obj) => obj["asset_inspection"]) ? action.payload.find((obj) => obj["asset_inspection"]).asset_inspection : null,

        assetInspectionAssign:
          action.payload.find((obj) => obj["asset_inspection_assign"]) ? action.payload.find((obj) => obj["asset_inspection_assign"]).asset_inspection_assign : null,

        device:
          action.payload.find((obj) => obj['device']) ? action.payload.find((obj) => obj['device']).device : null,

        deviceMaintenance:
          action.payload.find((obj) => obj["device_maintenance"]) ? action.payload.find((obj) => obj["device_maintenance"]).device_maintenance : null,

        deviceMaintenanceAssign:
          action.payload.find((obj) => obj["device_maintenance_assign"]) ? action.payload.find((obj) => obj["device_maintenance_assign"]).device_maintenance_assign : null,

        deviceInspection:
          action.payload.find((obj) => obj["device_inspection"]) ? action.payload.find((obj) => obj["device_inspection"]).device_inspection : null,

        deviceInspectionAssign:
          action.payload.find((obj) => obj["device_inspection_assign"]) ? action.payload.find((obj) => obj["device_inspection_assign"]).device_inspection_assign : null,

        facility:
          action.payload.find((obj) => obj["facility"]) ? action.payload.find((obj) => obj["facility"]).facility : null,

        facilityMaintenance:
          action.payload.find((obj) => obj["facility_maintenance"]) ? action.payload.find((obj) => obj["facility_maintenance"]).facility_maintenance : null,

        facilityMaintenanceAssign:
          action.payload.find((obj) => obj["facility_maintenance_assign"]) ? action.payload.find((obj) => obj["facility_maintenance_assign"]).facility_maintenance_assign : null,

        facilityInspection:
          action.payload.find((obj) => obj["facility_inspection"]) ? action.payload.find((obj) => obj["facility_inspection"]).facility_inspection : null,

        facilityInspectionAssign:
          action.payload.find((obj) => obj["facility_inspection_assign"]) ? action.payload.find((obj) => obj["facility_inspection_assign"]).facility_inspection_assign : null,

        qrGenerator:
          action.payload.find((obj) => obj["qr_generator"]) ? action.payload.find((obj) => obj["qr_generator"]).qr_generator : null,

        dashboard:
          action.payload.find((obj) => obj["dashboard"]) ? action.payload.find((obj) => obj["dashboard"]).dashboard : null,

        user:
          action.payload.find((obj) => obj["user"]) ? action.payload.find((obj) => obj["user"]).user : null,

        workOrder:
          action.payload.find((obj) => obj["work_order"]) ? action.payload.find((obj) => obj["work_order"]).work_order : null,

        onDemandInspection:
          action.payload.find((obj) => obj["on_demand_inspection"]) ? action.payload.find((obj) => obj["on_demand_inspection"]).on_demand_inspection : null,

        accounting:
          action.payload.find((obj) => obj["accounting"]) ? action.payload.find((obj) => obj["accounting"]).accounting : null,

        plant_verification:
          action.payload.find((obj) => obj["plant_verification"]) ? action.payload.find((obj) => obj["plant_verification"]).plant_verification : null,

        asset_registration:
          action.payload.find((obj) => obj["asset_registration"]) ? action.payload.find((obj) => obj["asset_registration"]).asset_registration : null,

        fixed_asset_master:
          action.payload.find((obj) => obj["fixed_asset_master"]) ? action.payload.find((obj) => obj["fixed_asset_master"]).fixed_asset_master : null,

        depreciation_calculation:
          action.payload.find((obj) => obj["depreciation_calculation"]) ? action.payload.find((obj) => obj["depreciation_calculation"]).depreciation_calculation : null,

        slm_method:
          action.payload.find((obj) => obj["slm_method"]) ? action.payload.find((obj) => obj["slm_method"]).slm_method : null,

        wdv_method:
          action.payload.find((obj) => obj["wdv_method"]) ? action.payload.find((obj) => obj["wdv_method"]).wdv_method : null,

        transfer_dispose:
          action.payload.find((obj) => obj["transfer_dispose"]) ? action.payload.find((obj) => obj["transfer_dispose"]).transfer_dispose : null,

        depreciation_report:
          action.payload.find((obj) => obj["depreciation_report"]) ? action.payload.find((obj) => obj["depreciation_report"]).depreciation_report : null,

        asset_verification:
          action.payload.find((obj) => obj["asset_verification"]) ? action.payload.find((obj) => obj["asset_verification"]).asset_verification : null,

        report_mortality:
          action.payload.find((obj) => obj["report_mortality"]) ? action.payload.find((obj) => obj["report_mortality"]).report_mortality : null,

        verification_report:
          action.payload.find((obj) => obj["verification_report"]) ? action.payload.find((obj) => obj["verification_report"]).verification_report : null,

        audit_trial:
          action.payload.find((obj) => obj["audit_trial"]) ? action.payload.find((obj) => obj["audit_trial"]).audit_trial : null,

        dashboard_plant:
          action.payload.find((obj) => obj["dashboard_plant"]) ? action.payload.find((obj) => obj["dashboard_plant"]).dashboard_plant : null,

        dashboard_accounting:
          action.payload.find((obj) => obj["dashboard_accounting"]) ? action.payload.find((obj) => obj["dashboard_accounting"]).dashboard_accounting : null,

        cwip_list:
          action.payload.find((obj) => obj["cwip_list"]) ? action.payload.find((obj) => obj["cwip_list"]).cwip_list : null,

        capitalised_list:
          action.payload.find((obj) => obj["capitalised_list"]) ? action.payload.find((obj) => obj["capitalised_list"]).capitalised_list : null,

        dashboard_criticality:
          action.payload.find((obj) => obj["dashboard_criticality"]) ? action.payload.find((obj) => obj["dashboard_criticality"]).dashboard_criticality : null,

        breakdown:
          action.payload.find((obj) => obj["breakdown"]) ? action.payload.find((obj) => obj["breakdown"]).breakdown : null,

        custom_report:
          action.payload.find((obj) => obj["custom_report"]) ? action.payload.find((obj) => obj["custom_report"]).custom_report : null,
        
        offline_access:
          action.payload.find((obj) => obj["offline"]) ? action.payload.find((obj) => obj["offline"]).offline : null,

      };
    case ACCESS_GET_FAILED:
      return {
        ...state,
      };
    case ROLE_GET_LIST:
      return {
        ...state,
      };
    case ROLE_GET_SUCCESS:
      return {
        ...state,
        roleListData: action.payload.data,
      };
    case ROLE_GET_FAILED:
      return {
        ...state,
      };
    case "CLEAR_ACCESS_REDUCER":
      return {
        ...state,
        webAccess: null,
        mobileAccess: null,
        accessData: null,
        customerAccess: null,
        customerContact: null,
        site: null,
        siteContact: null,
        asset: null,
        assetMaintenance: null,
        assetMaintenanceAssign: null,
        assetInspection: null,
        assetInspectionAssign: null,
        device: null,
        deviceMaintenance: null,
        deviceMaintenanceAssign: null,
        deviceInspection: null,
        deviceInspectionAssign: null,
        facility: null,
        facilityMaintenance: null,
        facilityMaintenanceAssign: null,
        facilityInspection: null,
        facilityInspectionAssign: null,
        qrGenerator: null,
        dashboard: null,
        user: null,
        workOrder: null,
        onDemandInspection: null,
        roleListData: null,
        accounting: null,
        plant_verification: null,
        asset_registration: null,
        fixed_asset_master: null,
        depreciation_calculation: null,
        slm_method: null,
        wdv_method: null,
        transfer_dispose: null,
        depreciation_report: null,
        asset_verification: null,
        report_mortality: null,
        verification_report: null,
        dashboard_plant: null,
        dashboard_accounting: null,
        audit_trial: null,
        cwip_list: null,
        capitalised_list: null,
        breakdown: null,
        custom_report: null,
        offline_access:null
      };
    default:
      return state;
  }
}
