import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'

function PmcGenDaily({ responseData, filterData,heading,headingName,tableHeaders,docNumber }) {
    const [resData, setResData] = useState([])

    // header details
    const data1 = {
        data: "MAINTENANCE",
        colSpan: '6'
    }
    const data2 = {
        data: `${docNumber}`
    }
    const data3 = {
        data: `${headingName} ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
        colSpan: '7'
    }
    const data4 = [
        { data: `${heading}`, colSpan: '9', textAlign: 'center' },
        {
            data: `FREQUENCY - DAILY`,
            colSpan: '5',
            textAlign: 'center'
        },
    ]
    let tableResData = []

    if(docNumber=='MAINT/063:F1-00'){
        tableResData = resData?.checklist?.map(item => ({
            data: {
                value: item?.start_date && item?.start_date!==undefined? moment(item?.start_date).local().format("DD/MM/YY"):'-',  // received email on 31st jan to maintain this date format.
                style: { textAlign: 'center' },
            },
            data1: {
                value: item?.checkpoints[0]?.answer!==''?item?.checkpoints[0]?.answer:'-',
                style: { textAlign: 'center' }, // Define inline style here
            },
            data2: {
                value: item?.checkpoints[1]?.answer!==''?item?.checkpoints[1]?.answer:'-',
                style: { textAlign: 'center' }, // Define inline style here
            },
            data3: {
                value: item?.checkpoints[2]?.answer!==''?item?.checkpoints[2]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data4: {
                value: item?.checkpoints[3]?.answer!==''?item?.checkpoints[3]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data5: {
                value: item?.checkpoints[4]?.answer!==''?item?.checkpoints[4]?.answer:'-',
                style: { textAlign: 'center',colSpan:'3' },
            },
            data6: {
                value: item?.checkpoints[5]?.answer!==''?item?.checkpoints[5]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data7: {
                value: item?.checkpoints[6]?.answer!==''?item?.checkpoints[6]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data9: {
                value: item?.checkpoints[7]?.answer!==''?item?.checkpoints[7]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data10: {
                value: item?.checkpoints[8]?.answer!==''?item?.checkpoints[8]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data11: {
                value: item?.performed_by?.length>0?item?.performed_by:'-',
                style: { textAlign: 'center' },
            },
            data12: {
                value: item?.ins_approval_id?.length>0?item?.ins_approval_id:'-',
                style: { textAlign: 'center' },
            },
        }));
    }else if(docNumber=="MAINT/037:F1-02"){
        tableResData = resData?.checklist?.map((item,index) => ({
            dataa: {
                value: index+1,
                style: { textAlign: 'center' },
            },
            data: {
                value: item?.start_date && item?.start_date!==undefined? moment(item?.start_date).local().format("DD/MM/YY"):'-',  // received email on 31st jan to maintain this date format.
                style: { textAlign: 'center' },
            },
            data1: {
                value: item?.checkpoints[0]?.answer!==''?item?.checkpoints[0]?.answer:'-',
                style: { textAlign: 'center' }, // Define inline style here
            },
            data2: {
                value: item?.checkpoints[1]?.answer!==''?item?.checkpoints[1]?.answer:'-',
                style: { textAlign: 'center' }, // Define inline style here
            },
            data3: {
                value: item?.checkpoints[2]?.answer!==''?item?.checkpoints[2]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data4: {
                value: item?.checkpoints[3]?.answer!==''?item?.checkpoints[3]?.answer:'-',
                style: { textAlign: 'center' ,colSpan:'2'},
            },
            data5: {
                value: item?.checkpoints[4]?.answer!==''?item?.checkpoints[4]?.answer:'-',
                style: { textAlign: 'center',colSpan:'3' },
            },
            data6: {
                value: item?.checkpoints[5]?.answer!==''?item?.checkpoints[5]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data7: {
                value: item?.checkpoints[6]?.answer!==''?item?.checkpoints[6]?.answer:'-',
                style: { textAlign: 'center' },
            },
            data11: {
                value: item?.performed_by?.length>0?item?.performed_by:'-',
                style: { textAlign: 'center' },
            },
            data12: {
                value: item?.ins_approval_id?.length>0?item?.ins_approval_id:'-',
                style: { textAlign: 'center' },
            },
        }));
    }


    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
            />
            <ReportTable
                headerData={data4}
                headerData2={tableHeaders}
            />
            <ReportTable
                verticalBodyData={tableResData}
            />
        </div>
    )
}

export default PmcGenDaily
