const DEVICE_GET_LIST = 'DEVICE_GET_LIST';
const DEVICE_GET_SUCCESS = 'DEVICE_GET_SUCCESS';
const DEVICE_GET_FAILED = 'DEVICE_GET_FAILED';

const DEVICE_DELETE_LIST = 'DEVICE_DELETE_LIST';
const DEVICE_DELETE_SUCCESS = 'DEVICE_DELETE_SUCCESS';
const DEVICE_DELETE_FAILED = 'DEVICE_DELETE_FAILED';

const DEVICE_ADD = 'DEVICE_ADD';
const DEVICE_ADD_SUCCESS = 'DEVICE_ADD_SUCCESS';
const DEVICE_ADD_FAILED = 'DEVICE_ADD_FAILED';


const DEVICE_UPDATE = "DEVICE_UPDATE";
const DEVICE_UPDATE_SUCCESS = "DEVICE_UPDATE_SUCCESS";
const DEVICE_UPDATE_FAILED = "DEVICE_UPDATE_FAILED";

const DEVICE_GET_SINGLE = "DEVICE_GET_SINGLE";
const DEVICE_GET_SINGLE_SUCCESS = "DEVICE_GET_SINGLE_SUCCESS";
const DEVICE_GET_SINGLE_FAILED = "DEVICE_GET_SINGLE_FAILED";

export {
    DEVICE_GET_LIST,
    DEVICE_GET_SUCCESS,
    DEVICE_GET_FAILED,

    DEVICE_DELETE_LIST,
    DEVICE_DELETE_SUCCESS,
    DEVICE_DELETE_FAILED,

    DEVICE_ADD,
    DEVICE_ADD_SUCCESS,
    DEVICE_ADD_FAILED,

    DEVICE_UPDATE,
    DEVICE_UPDATE_SUCCESS,
    DEVICE_UPDATE_FAILED,

    DEVICE_GET_SINGLE,
    DEVICE_GET_SINGLE_SUCCESS,
    DEVICE_GET_SINGLE_FAILED
}