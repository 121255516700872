import React from 'react';
import AssetHeader from './assetHeader';
import { Outlet } from 'react-router-dom';

function LayoutContainer() {
    return (
        <div>
            <div>                
                <AssetHeader />                               
                <Outlet />
                {/* <footer /> */}
            </div>
        </div>
    )
}

export default LayoutContainer
