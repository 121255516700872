import { React, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { SITE_CONTACT_DETAILS } from "../../redux/actions/siteAction";
import { useNavigate } from "react-router-dom";

function ContactUpload(props) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const userid = useSelector((state) => state.auth.userid);

  const dispatch = useDispatch();
  const dispatchid = useDispatch();
  const id = props.id;

  const [formData, setFormData] = useState({
    cfname: "",
    cmname: "",
    clname: "",
    cemail: "",
    ccode: "",
    mnumber: "",
    desgnt: "",
    lprofile: "",
    ctype: "",
  });
  // console.log(formData);

  const handleFirstname = (val) => {
    setFormData({
      ...formData,
      cfname: val,
    });
  };

  const handleMiddletname = (val) => {
    setFormData({
      ...formData,
      cmname: val,
    });
  };

  const handleLastname = (val) => {
    setFormData({
      ...formData,
      clname: val,
    });
  };

  const handleemail = (val) => {
    setFormData({
      ...formData,
      cemail: val,
    });
  };
  const handleCode = (val) => {
    setFormData({
      ...formData,
      ccode: val,
    });
  };
  const handleMobileNumber = (val) => {
    setFormData({
      ...formData,
      mnumber: val,
    });
  };
  const handleDesgnt = (val) => {
    setFormData({
      ...formData,
      desgnt: val,
    });
  };
  const handleProfile = (val) => {
    setFormData({
      ...formData,
      lprofile: val,
    });
  };
  const handleType = (val) => {
    setFormData({
      ...formData,
      ctype: val,
    });
  };

  //useEffects
  useEffect(() => { }, [formData]);

  //client-side validation
  const [cfnameErr, setcfnameErr] = useState("");
  const [clnameErr, setclnameErr] = useState("");
  const [mnumberErr, setmnumberErr] = useState("");
  const [ccodeErr, setCodeErr] = useState("");
  const [cemailErr, setcemailErr] = useState("");
  const [desgntErr, setdesgntErr] = useState("");
  const [ctypeErr, setctypeErr] = useState("");

  let isValid = true;

  // function for client side validation.
  const formValidation = () => {
    const cfnameErr = {};
    const clnameErr = {};
    const cemailErr = {};
    const ccodeErr = {};
    const mnumberErr = {};
    const desgntErr = {};
    const ctypeErr = {};

    if (formData.cfname.trim().length < 1) {
      cfnameErr.cfnameShort = "Please Enter First name";
      isValid = false;
    }
    setcfnameErr(cfnameErr);

    if (formData.clname.trim().length < 1) {
      clnameErr.clnameShort = "Please Enter Last name";
      isValid = false;
    }
    setclnameErr(clnameErr);

    if (formData.cemail.trim().length < 1) {
      cemailErr.cfnameShort = "Please Enter Email";
      isValid = false;
    }
    setcemailErr(cemailErr);

    if (formData.ccode.trim().length !== 3) {
      ccodeErr.cfnameShort = "Please Choose Country Code";
      isValid = false;
    }
    setCodeErr(ccodeErr);

    if (formData.mnumber.trim().length !== 10) {
      mnumberErr.cfnameShort = "Please Enter Valid Mobile number";
      isValid = false;
    }
    setmnumberErr(mnumberErr);

    if (formData.desgnt.trim().length < 1) {
      desgntErr.cfnameShort = "Please Enter Designation";
      isValid = false;
    }
    setdesgntErr(desgntErr);

    if (formData.ctype.trim().length < 1) {
      ctypeErr.cfnameShort = "Choose Type";
      isValid = false;
    }
    setctypeErr(ctypeErr);

    return isValid;
  };

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  const submitContactdetails = (e) => {
    e.preventDefault();
    let newObj = {
      site_contacts: {
        first_name: formData.cfname,
        middle_name: formData.cmname,
        last_name: formData.clname,
        email_id: formData.cemail,
        country_code: formData.ccode,
        mobile_number: formData.mnumber,
        designation: formData.desgnt,
        linkedin_profile: formData.lprofile,
        type: formData.ctype,
        created_by: userid,
      },
    };
    validation();
    // console.log(cfnameErr, clnameErr, cemailErr, ccodeErr, mnumberErr, desgntErr, ctypeErr);
    // console.log(isValid, "isvalid");
    // console.log(newObj, "newObj");
    if (isValid) {
      dispatch({
        type: SITE_CONTACT_DETAILS,
        payload: { newObj, id },
      });
      props.onHide();
      navigate(`/sitecontact/${id}`);
    }
  };

  function nameChars(str) {
    const placeChars = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    return placeChars.test(str);
  }

  function emailChars(str) {
    const emailspecialChars = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    return emailspecialChars.test(str);
  }

  function mobileChars(str) {
    const mobilespecialChars = /^\+?[1-9][0-9]{9}$/;
    return mobilespecialChars.test(str);
  }

  function designationChars(str) {
    const designationspecialChars = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    return designationspecialChars.test(str);
  }

  //useEffects
  useEffect(() => { },
    [formData]);

  return (
    <div>
      <Modal
        open={isOpen}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Site Contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 offset-md-12 mt-3">
            <div className="CustomerUpload">
              <div className="row">
                <div className="mb-2 col-md-3">
                  <label>
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="cfname"
                    className="form-control"
                    value={formData.cfname}
                    onChange={(e) => handleFirstname(e.target.value)}
                  />
                  {Object.keys(cfnameErr).map((key) => {
                    if (!formData.cfname) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {cfnameErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {nameChars(formData.cfname) || formData.cfname === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid First name
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-3">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    name="cmname"
                    className="form-control"
                    onChange={(e) => handleMiddletname(e.target.value)}
                  />
                  {nameChars(formData.cmname) || formData.cmname === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Middle name
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-3">
                  <label>
                    Last Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="clname"
                    className="form-control"
                    onChange={(e) => handleLastname(e.target.value)}
                  />
                  {Object.keys(clnameErr).map((key) => {
                    if (!formData.clname) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {clnameErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {nameChars(formData.clname) || formData.clname === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Last name
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-3">
                  <label>
                    Email ID
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="cemail"
                    className="form-control"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,}$"
                    onChange={(e) => handleemail(e.target.value)}
                  />
                  {Object.keys(cemailErr).map((key) => {
                    if (!formData.cemail) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {cemailErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {emailChars(formData.cemail) || formData.cemail === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Email
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-3">
                  <label>
                    Mobile Number
                    <span className="text-danger">*</span>
                  </label>

                  <div className="input-group">
                    <select
                      className="form-select"
                      value={formData.ccode}
                      placeholder=""
                      onChange={(e) => handleCode(e.target.value)}
                      required
                    >
                      <option value="" disabled>
                        tel
                      </option>
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                    </select>
                    <input
                      style={{ width: "85px" }}
                      name="Mnumber"
                      className="form-control"
                      pattern="[0-9]{10,}"
                      onChange={(e) => handleMobileNumber(e.target.value)}
                      maxLength={10}
                      minLength={0}
                      required
                    />
                  </div>
                  {Object.keys(ccodeErr).map((key) => {
                    if (!formData.ccode) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {ccodeErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {Object.keys(mnumberErr).map((key) => {
                    if (!formData.mnumber) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {mnumberErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {mobileChars(formData.mnumber) || formData.mnumber === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Mobile number
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-3">
                  <label>
                    Designation
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="designation"
                    className="form-control"
                    onChange={(e) => handleDesgnt(e.target.value)}
                  />
                  {Object.keys(desgntErr).map((key) => {
                    if (!formData.desgnt) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {desgntErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                  {designationChars(formData.desgnt) || formData.desgnt === ""
                    ? " "
                    : ((isValid = false),
                      (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Designation
                        </div>
                      ))}
                </div>
                <div className="mb-2 col-md-3">
                  <label>LinkedIn Profile</label>
                  <input
                    type="text"
                    name="lprofile"
                    className="form-control"
                    onChange={(e) => handleProfile(e.target.value)}
                  />
                </div>
                <div className="mb-5 col-md-3">
                  <label htmlFor="datalist">
                    Type
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    name="ccriticality"
                    className="form-select"
                    list="datalistOptions"
                    id="datalist"
                    onChange={(e) => handleType(e.target.value)}
                  >
                    <option value="select">Select</option>
                    <option value="Primary">Primary</option>
                    <option value="Secondary">Secondary</option>
                    <option value="Operational">Operational</option>
                  </select>
                  {Object.keys(ctypeErr).map((key) => {
                    if (!formData.ctype) {
                      return (
                        <div
                          key={key}
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingTop: "2px",
                          }}
                        >
                          {ctypeErr[key]}
                        </div>
                      );
                    } else {
                      <p></p>;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* modal footer */}
          <div className="flex">
            <button
              className="saveButton float-end"
              onClick={(e) => {
                submitContactdetails(e);
              }}
            >
              Save
            </button>
            {/* <button
              className="backButton mt-4"
              onClick={() => navigate("/sitelist")}
            >
              Back
            </button> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ContactUpload;
