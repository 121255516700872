//Feature - Form to update/modify Facility details

//Created by - Janaki J
//Updated by - Janaki J

//importing dependencies and packages
import { useEffect, useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { FACILITY_UPDATE } from "../../redux/actions/facilityAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";

//Function to update facility
function FacilityUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const options = ["1", "2", "3"];

  //to fetch the loading data
  const dispatch = useDispatch();
  const facilityGetSingle = useSelector(
    (state) => state.facility.facilityGetSingle
  );
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.facility.isLoading);
  const access = useSelector((state) => state.access.facility);

  const [data, setData] = useState({
    id: "",
    facility_id: "",
    categry: "",
    name: "",
    qr: "",
    criticality: "",
    area: "",
    location: "",
    building: "",
    block: "",
    floor: "",
    wing: "",
    dept: "",
    accessibility: "",
    photo: "",
    remark: "",
  });

  //Functions to handle form inputs
  const handleFacilityID = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        facility_id: val,
        isValidFacilityID: true,
      });
    } else {
      setData({
        ...data,
        facility_id: val,
        isValidFacilityID: false,
      });
    }
  };
  const handleCategory = (val) => {
    setData({
      ...data,
      categry: val,
    });
  };
  const handleName = (val) => {
    setData({
      ...data,
      name: val,
    });
  };
  const handleQr = (val) => {
    setData({
      ...data,
      qr: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      criticality: val,
    });
  };
  const handleArea = (val) => {
    setData({
      ...data,
      area: val,
    });
  };
  const handleLocation = (val) => {
    setData({
      ...data,
      location: val,
    });
  };
  const handleBuilding = (val) => {
    setData({
      ...data,
      building: val,
    });
  };
  const handleBlock = (val) => {
    setData({
      ...data,
      block: val,
    });
  };
  const handleFloor = (val) => {
    setData({
      ...data,
      floor: val,
    });
  };
  const handleWing = (val) => {
    setData({
      ...data,
      wing: val,
    });
  };
  const handleDept = (val) => {
    setData({
      ...data,
      dept: val,
    });
  };
  const handleAccesbility = (val) => {
    setData({
      ...data,
      accessibility: val,
    });
  };
  const handleAssetimg = (val) => {
    setData({
      ...data,
      photo: val,
    });
  };
  const handleRemark = (val) => {
    setData({
      ...data,
      remark: val,
    });
  };

  //Function to update facility details
  const updateFacilityDetails = () => {
    let facilityData = {
      facility_id: data.facility_id,
      category: data.categry,
      facility_name: data.name,
      code_used: data.qr,
      criticality: data.criticality,
      area: data.area,
      location: data.location,
      building: data.building,
      block: data.block,
      floor: data.floor,
      wing: data.wing,
      department: data.dept,
      accessibility: data.accessibility,
      facility_image: data.photo,
      remarks: data.remark,
      modified_by: userid,
    };
    dispatch({
      type: FACILITY_UPDATE,
      payload: [id, facilityData],
    });
    navigate("/facilitylist");
  };

  //fetch facility ID
  const sendID = () => {
    if (id !== null) {
      dispatch({
        type: "FACILITY_GET_SINGLE",
        payload: id,
      });
    }
  };

  //functions for validation
  function idChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/])*[a-zA-Z0-9-/]+$/;
    return idspecialChars.test(str);
  }
  function fcategoryChars(str) {
    const categoryChars = /^([a-zA-Z0-9-/@]+\s)*[a-zA-Z0-9-@/]+$/;
    return categoryChars.test(str);
  }

  //useEffects
  useEffect(() => {
    // console.log("facilityGetSingle", facilityGetSingle);
    sendID();
  }, [id]);

  useEffect(() => {
    if (facilityGetSingle != undefined && facilityGetSingle != 0) {
      setData({
        facility_id: facilityGetSingle.facility_id,
        categry: facilityGetSingle.category,
        name: facilityGetSingle.facility_name,
        qr: facilityGetSingle.code_used,
        criticality: facilityGetSingle.criticality,
        area: facilityGetSingle.area,
        location: facilityGetSingle.location,
        building: facilityGetSingle.building,
        block: facilityGetSingle.block,
        floor: facilityGetSingle.floor,
        wing: facilityGetSingle.wing,
        dept: facilityGetSingle.department,
        accessibility: facilityGetSingle.accessibility,
        photo: facilityGetSingle.facility_image,
        remark: facilityGetSingle.remarks,
      });
    }
  }, [facilityGetSingle]);

  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to show the loading data */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 offset-md-12">
            <div className="FacilityUpdate" style={{ paddingTop: "25px" }}>
              {access !== null && access.u ? (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                >
                  <div className="formHeadings mb-2">Edit Facility</div>
                  <div className="row">
                    <div className="mb-2 col-md-3">
                      <label>
                        Facility ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="facility_id"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          handleFacilityID(e.target.value);
                        }}
                        defaultValue={data.facility_id}
                      />
                      {data.isValidFacilityID ? null : (
                        <p style={{ color: "red", fontSize: 12 }}></p>
                      )}
                      {idChars(data.facility_id) || data.facility_id === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Facility ID
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Category<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="category"
                        maxLength="150"
                        className="form-control"
                        onChange={(e) => {
                          handleCategory(e.target.value);
                        }}
                        defaultValue={data.categry}
                      />
                      {fcategoryChars(data.categry) || data.categry === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Category
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Facility Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleName(e.target.value);
                        }}
                        defaultValue={data.name}
                      />
                      {fcategoryChars(data.facility_name) ||
                        data.facility_name === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Facility name
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Criticality<span className="text-danger">*</span>
                      </label>
                      <select
                        name="criticality"
                        className="form-select"
                        onChange={(e) => {
                          handleCriticality(e.target.value);
                        }}
                        value={data.criticality}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Area</label>
                      <input
                        type="text"
                        name="area"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleArea(e.target.value);
                        }}
                        defaultValue={data.area}
                      />
                      {fcategoryChars(data.area) || data.area === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Area
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Location</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="location"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleLocation(e.target.value);
                        }}
                        defaultValue={data.location}
                      />
                      {fcategoryChars(data.location) || data.location === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Location
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Building</label>
                      <input
                        type="text"
                        name="building"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleBuilding(e.target.value);
                        }}
                        defaultValue={data.building}
                      />
                      {fcategoryChars(data.building) || data.building === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Building data
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Block/Tower</label>
                      <input
                        type="text"
                        name="block"
                        className="form-control"
                        onChange={(e) => {
                          handleBlock(e.target.value);
                        }}
                        defaultValue={data.block}
                      />
                      {fcategoryChars(data.blocktower) ||
                        data.blocktower === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Data
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Floor</label>
                      <input
                        type="text"
                        name="floor"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleFloor(e.target.value);
                        }}
                        defaultValue={data.floor}
                      />
                      {fcategoryChars(data.floor) || data.floor === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Floor data
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Wing</label>
                      <input
                        type="text"
                        name="wing"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleWing(e.target.value);
                        }}
                        defaultValue={data.wing}
                      />
                      {fcategoryChars(data.wing) || data.wing === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Wing data
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Department<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="dept"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleDept(e.target.value);
                        }}
                        defaultValue={data.dept}
                      />
                      {fcategoryChars(data.dept) || data.dept === "" ? (
                        " "
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "2px",
                          }}
                        >
                          Invalid Department
                        </div>
                      )}
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Network Accessibility<span className="text-danger">*</span>
                      </label>
                      <select
                        name="accessibility"
                        className="form-select"
                        onChange={(e) => {
                          handleAccesbility(e.target.value);
                        }}
                      >
                        <option value="select">{data.accessibility}</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Facility Image</label>
                      <input
                        type="file"
                        name="photo"
                        className="form-control"
                        onChange={(e) => {
                          handleAssetimg(e.target.value);
                        }}
                        defaultValue={data.photo}
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>Remarks</label>
                      <input
                        type="text"
                        name="remark"
                        maxLength="200"
                        className="form-control"
                        onChange={(e) => {
                          handleRemark(e.target.value);
                        }}
                        defaultValue={data.remark}
                      />
                    </div>
                    <div className="mb-2 col-md-12">
                      <button
                        type="submit"
                        onClick={() => updateFacilityDetails()}
                        className="float-end saveButton"
                      >
                        Update
                      </button>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={() => navigate("/facilitylist")}
                        className="backButton"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//exporting component
export default FacilityUpdate;
