import {
    FACILITY_GET_LIST,
    FACILITY_GET_SUCCESS,
    FACILITY_GET_FAILED,

    FACILITY_DELETE_LIST,
    FACILITY_DELETE_SUCCESS,
    FACILITY_DELETE_FAILED,

    FACILITY_ADD,
    FACILITY_ADD_SUCCESS,
    FACILITY_ADD_FAILED,

    FACILITY_UPDATE,
    FACILITY_UPDATE_SUCCESS,
    FACILITY_UPDATE_FAILED,

    FACILITY_GET_SINGLE,
    FACILITY_GET_SINGLE_SUCCESS,
    FACILITY_GET_SINGLE_FAILED,
} from '../actions/facilityAction';

const inistialFacilityState = {
    facilityData: {result:[]},
    facilityAddData: [],
    isSubmitted: false,
    isLoading: false,
};

export default function FacilityReducer(state = inistialFacilityState, action) {
    switch (action.type) {
        case FACILITY_GET_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case FACILITY_GET_SUCCESS:
            return {
                ...state,
                facilityData: action.payload.data,
                isSubmitted: false,
                isLoading: false,
            };
        case FACILITY_GET_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FACILITY_DELETE_LIST:
            return {
                ...state,
            };
        case FACILITY_DELETE_SUCCESS:

            return {
                ...state,
            };
        case FACILITY_DELETE_FAILED:
            return {
                ...state,
            };
        case FACILITY_ADD:
            return {
                ...state,
                isLoading: true,
            };
        case FACILITY_ADD_SUCCESS:
            return {
                ...state,
                facilityAddData: action.payload,
                isSubmitted: true,
                isLoading: false,
            }
        case FACILITY_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case FACILITY_GET_SINGLE:
            return {
                ...state,
                isLoading: true,
            };
        case FACILITY_GET_SINGLE_SUCCESS:
            return {
                ...state,
                facilityGetSingle: action.payload,
                isLoading: false,
            };
        case FACILITY_GET_SINGLE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case FACILITY_UPDATE:
            return {
                ...state,
                isLoading: true,
            };
        case FACILITY_UPDATE_SUCCESS:
            return {
                ...state,
                facilityUpdateData: action.payload,
                isLoading: false,
            };
        case FACILITY_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case 'CLEAR_FACILITY_REDUCER':
            return {
                ...state,
                facilityData: {},
                facilityAddData: [],
            };
        default:
            return state;
    }
};