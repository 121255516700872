import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import InspectionReport from "./inspectionReport";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Commonheader from "../../../layouts/common/commonHeader";

const Genratepdf = () => {
  const componentRef = useRef();
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("Asset");
  const [data, setData] = useState({
    currentPage: "",
    itemsPerPage: "",
    startDate: "",
    endDate: "",
    searchInput: "",
    name: "",
  });
  const [fileName, setFileName] = useState(""); // To set Custom file name

  const insReportData = useSelector((state) => state.inspection.insReportData);

  // console.log(insReportData);

  const handlePrint = () => {
    componentRef.current.handlePrint();
  };

  useEffect(() => {
    // console.log(location);
    // console.log(location.state);
    // console.log(location.state[0]);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state?.[0]);
      setSelectedItem(location.state?.[1]);
      setData({
        currentPage: location.state?.[2],
        itemsPerPage: location.state?.[3],
        startDate: location.state?.[4],
        endDate: location.state?.[5],
        searchInput: location.state?.[6],
        name: location.state?.[7],
      });
      const dynamicFileName = generateFileName();
      setFileName(dynamicFileName);
    }
  }, [insReportData]);
  // Function to generate the custom file name dynamically
  const generateFileName = () => {
    // Implement your logic here to generate the file name
    // Example: return `inspection_${key}_${selectedItem}.pdf`;
    return `Report_${insReportData?.parent?.parent_id}_${moment(
      insReportData?.inspection?.end_date
    ).format("LLLL")}_V1.09.pdf`;
  };

  return (
    <div>
      <Commonheader />
      {/* {console.log('afterImageSrc', afterImageSrc)}
      {console.log('b4', imageSrc)} */}

      <h4 className="listHeadings">Inspection Report</h4>
      {/* Back Btn */}
      <button
        type="button"
        onClick={() => {
          // navigate("/inspectionstatus", { state: [key, selectedItem, data?.currentPage, data?.itemsPerPage, data?.startDate, data?.endDate, data?.searchInput] })
          navigate(
            data.name === "Inspection" ? "/inspectionstatus" : "/ondemandtatus",
            {
              state: [
                key,
                selectedItem?.toLowerCase(),
                data?.currentPage,
                data?.itemsPerPage,
                data?.startDate,
                data?.endDate,
                data?.searchInput,
              ],
            }
          );
        }}
        className="backButton my-0 mx-4"
      >
        Back
      </button>
      <ReactToPrint
        trigger={() => (
          // <button className="btn btn-primary" onClick={handlePrint}>
          //     Print to PDF!
          // </button>
          <button
            type="button"
            style={{ float: "right" }}
            onClick={handlePrint}
            className="saveButton my-0 mx-4"
          >
            Download
          </button>
        )}
        content={() => componentRef.current}
        documentTitle={fileName} // Set the custom file name
        pageStyle={`
                @page {
                    size: 'A4 portrait';
                    margin-top: 60px;
                }
                @media print {
                    #page-header {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 50px;
                        background-color: lightgray;
                        padding: 10px;
                    }
                #content {
                    page-break-before: always;
                }   
            }`}
      />
      <div ref={componentRef}>
        <div style={{ marginTop: "60px" }}>
          {/* Add the necessary content here */}
          <InspectionReport />
        </div>
      </div>
    </div>
  );
  // }
};

export default Genratepdf;
