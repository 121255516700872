import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import InspectionReport from "./inspectionReport";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Commonheader from "../../../layouts/common/commonHeader";


import ticketReportData  from "./Untitled-1";



const GenrateTicketpdf = () => {

    const componentRef = useRef();
    const [key, setKey] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState("Asset");
    const [fileName, setFileName] = useState(""); // To set Custom file name

    const ticketReportData = ticketReportData

    // console.log(ticketReportData);


    const handlePrint = () => {
        componentRef.current.handlePrint();
    };


    useEffect(() => {
        // console.log(location);
        // console.log(location.state);
        // console.log(location.state[0]);
        if (location.state !== undefined && location.state !== null) {
            setKey(location.state?.[0])
            setSelectedItem(location.state?.[1])
            const dynamicFileName = generateFileName();
            setFileName(dynamicFileName);
        }
    }, [ticketReportData])
    // Function to generate the custom file name dynamically
    const generateFileName = () => {
        // Implement your logic here to generate the file name
        // Example: return `inspection_${key}_${selectedItem}.pdf`;
            return `Report_${ticketReportData?.parent?.parent_id}_${moment(ticketReportData?.inspection?.end_date).format("LLLL")}_V1.09.pdf`;
    };


    return (
        <div>
            <Commonheader />
            {/* {console.log('afterImageSrc', afterImageSrc)}
      {console.log('b4', imageSrc)} */}

            <h4 className="listHeadings">Inspection Report</h4>
            {/* Back Btn */}
            <button
                type="button"
                onClick={() => {
                    navigate("/inspectionstatus", { state: [key, selectedItem] })
                }}
                className="backButton my-0 mx-4"
            >
                Back
            </button>
            <ReactToPrint
                trigger={() => (
                    // <button className="btn btn-primary" onClick={handlePrint}>
                    //     Print to PDF!
                    // </button>
                    <button
                        type="button"
                        style={{ float: "right" }}
                        onClick={handlePrint}
                        className="saveButton my-0 mx-4"
                    >
                        Download
                    </button>
                )}
                content={() => componentRef.current}
                documentTitle={fileName} // Set the custom file name
                pageStyle={`@page {margin-top: 60px;}@media print {#page-header {position: fixed;top: 0;left: 0;right: 0;height: 50px;background-color: lightgray;padding: 10px;}}`}
            />
            <div ref={componentRef} >
                <div style={{ marginTop: '60px' }}>
                    {/* Add the necessary content here */}
                    <InspectionReport />
                </div>
            </div>
        </div>
    );
    // }
}

export default GenrateTicketpdf;
