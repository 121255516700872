import React from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import moment from "moment";

function PMC6({
  responseData,
  filterData,
  logo,
  docNumber,
  chklname,
  heading2,
}) {
  const data1 = {
    data: "MAINTENANCE",
    colSpan: "6",
  };


  const data2 = {
    data: `${docNumber}`,
  };

  const data5 = [
    { data: "S. No", colSpan: "1", rowSpan: "1", textAlign: "center" },
    { data: "Description", colSpan: "4", rowSpan: "1", textAlign: "center" },
    { data: "Observation", textAlign: "center", colSpan: "3", rowSpan: "1" },
    { data: "Remarks", textAlign: "center", colSpan: "3", rowSpan: "1" },
  ];

  const data3 = {
    data: `${chklname} FOR THE MONTH OF`,
    colSpan: "7",
  };

  const dataRow1 = [
    {
        data: `Done by:`,
        colSpan: '2'
    },
    {
        data: `-`,
        colSpan: '3'
    },
    {
        data: `Calibrated on:`,
        colSpan: '2'
    },
    {
        data: "-",
        colSpan: '3'
    }
]

const dataRow2 = [
    {
        data: `Checked by:`,
        colSpan: '2'
    },
    {
        data: `-`,
        colSpan: '3'
    },
    {
        data: `Next Calibrated Due On:`,
        colSpan: '2'
    },
    {
        data: `-`,
        colSpan: '3'
    }
]

  const data4 = [{ data: `${heading2}`, textAlign: "center" }];
  const data14 = [];
  let dataR1 = [];
  let dataR2 = [];
  const resData = [];

  // Utility function to format date
  const formatDate = (date) =>
    date ? moment(date).local().format("DD/MM/YY") : "-";

  // Utility function to get performer information
  const getPerformerInfo = (activity) =>
    activity?.performer_info?.[0]?.performed_by || "-";

  // Utility function to get maintenance approval ID
  const getMaintApprovalId = (checklist) => checklist?.maint_approval_id || "-";

  // Iterate over maint_checklist to build table data
  responseData?.maint_checklist?.forEach((el) => {
    const activity = el?.maintenance_activities[0];

    const row1 = [
      { data: "Done by:", colSpan: "2" },
      { data: getPerformerInfo(activity), colSpan: "3" },
      { data: "Calibrated on:", colSpan: "2" },
      {
        data: formatDate(activity?.performer_info?.[0]?.performed_date),
        colSpan: "3",
      },
    ];

    const row2 = [
      { data: "Checked by:", colSpan: "2" },
      { data: getMaintApprovalId(el), colSpan: "3" },
      { data: "Next Calibrated Due On:", colSpan: "2" },
      { data: formatDate(el?.next_run), colSpan: "3" },
    ];

    // Store rows for the checklist
    dataR1.push(row1);
    dataR2.push(row2);

    // Store header information for the report
    data14.push({
      data: `${chklname} FOR THE MONTH OF ${moment(el?.maint_sch_date)
        .local()
        .format("MMMM")
        .toUpperCase()}`,
      colSpan: "7",
    });

    // Process checkpoint data
    const checkpointData =
      activity?.checkpoints?.map((item, index) => ({
        data: {
          value: index + 1,
          style: { textAlign: "center" ,colSpan: "1",rowSpan: "1",},
        },
        data1: {
          value: item?.checkpoint || "-",
          style: { textAlign: "center" ,colSpan: "4",rowSpan: "1",},
        },
        data2: {
          value: item?.cp_answer || "-",
          style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
        },
        data3: {
          value: item?.cp_comments || "-",
          style: { textAlign: "center", colSpan: "3", rowSpan: "1" },
        },
      })) || [];

    resData.push(checkpointData);
  });

  return (
    <div>
      <div>
        {resData.length > 0 ? (
          resData.map((el, index) => (
            <div
              key={index}
              style={{
                pageBreakBefore: index !== 0 ? "always" : "auto",
                paddingTop: index !== 0 ? "20px" : "0px",
              }}
            >
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={data14[index]}
                custLogo={logo}
              />
              <ReportTable headerData={data4} />
              <ReportTable headerData={data5} />
              <ReportTable verticalBodyData={el} />
              <ReportTable bodyData={dataR1[index]} />
              <ReportTable bodyData={dataR2[index]} />
            </div>
          ))
        ) : (
          <div>
            <ReportHearder
              data1={data1}
              data2={data2}
              data3={data3}
              custLogo={logo}
            />
            <ReportTable headerData={data4} />
            <ReportTable headerData={data5} />
            <ReportTable verticalBodyData={[]} />
            <ReportTable bodyData={dataRow1} />
            <ReportTable bodyData={dataRow2} />
          </div>
        )}
      </div>
    </div>
  );
}

export default PMC6;
