import React from 'react'
import { IndividualData, IndividualDataDep } from './IndividualData'


export const DataExcel = ({ excelData }) => {
    // console.log('excelData', excelData);
    return excelData.map((individualExcelData) => (
        <tr key={individualExcelData.Id} style={{ fontSize: "14px", fontWeight: "lighter" }}>
            <IndividualData individualExcelData={individualExcelData} />
        </tr>
    ))
}

export const DataDep = ({ excelData }) => {
    // console.log("sdsdd", excelData)
    return excelData.map((individualExcelData, index) => (
        <tr key={individualExcelData.Id} style={{ fontSize: "14px", fontWeight: "lighter", }}>
            <IndividualDataDep individualExcelData={individualExcelData} index={index}/>
        </tr>
    ))
}