//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'

function CalibrationRecord({ responseData, tableHeaders=[],filterData, chklname, docNumber, depname, checkedby, instrumentCode, instCodeData, objheading, malur, medopharm, check1, check2, checked }) {

    const [resData, setResData] = useState([])

    //666acd540849568dce4373f7
    let data = [
        {
            header: [
                { data: `Calibration SOP No.`, rowSpan: "2", textAlign: 'center' },
                { data: `Calibrated On`, rowSpan: "2", textAlign: 'center' },
                { data: `Calibration Due`, rowSpan: "2", textAlign: 'center' },
                { data: `Calibration Report No`, rowSpan: "2", textAlign: 'center' },
            ],
            response: [
                {
                    data: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[0]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[0]?.cp_answer : '-',
                        style: { textAlign: 'center' },
                    },
                    data1: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[1]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[1]?.cp_answer : '-',
                        style: { textAlign: 'center' },
                    },
                    data2: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[2]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[2]?.cp_answer : '-',
                        style: { textAlign: 'center', },
                    },
                    data3: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', },
                    },
                },
            ]
        },
        {
            response: [
                {
                    data: {
                        value: "INSTRUMENT NAME :",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data1: {
                        value: "FRIABILITY TEST APPARATUS",
                        style: { textAlign: 'left', colSpan: '12' },
                    },
                },
                {
                    data: {
                        value: "Location :",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data1: {
                        value: responseData?.parent !== undefined ? responseData?.parent?.location : '-',
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data2: {
                        value: "Frequency :",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data3: {
                        value: "2 Months once",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                },
                {
                    data: {
                        value: "Model No. :",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data1: {
                        value: responseData?.parent !== undefined ? responseData?.parent?.model : '-',
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data2: {
                        value: "Instrument Code No. :",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data3: {
                        value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                },
                {
                    data: {
                        value: "Details :",
                        style: { textAlign: 'left', colSpan: '4' },
                    },
                    data1: {
                        value: "Digital stop watch",
                        style: { textAlign: 'left', colSpan: '12' },
                    },
                },
                {
                    data: {
                        value: "Test : The instrument is set for 25 RPM and checked the rotations for 4 minutes repeatedly for 3 times",
                        style: { textAlign: 'left', colSpan: '16' },
                    },
                },

            ]
        },
        {
            header: [
                { data: `S.No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                { data: `Set RPM`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                { data: `Time checked`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                { data: `Observed rotations`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                { data: `Limit`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                { data: `Remarks`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
            ],
            response: [
                {
                    data: {
                        value: "01",
                        style: { textAlign: 'center', colSpan: '1', },
                    },
                    data1: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data2: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data3: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data4: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data5: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                },
                {
                    data: {
                        value: "02",
                        style: { textAlign: 'center', colSpan: '1', },
                    },
                    data1: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data2: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data3: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data4: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data5: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                },
                {
                    data: {
                        value: "03",
                        style: { textAlign: 'center', colSpan: '1', },
                    },
                    data1: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data2: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data3: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                    data4: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4' },
                    },
                    data5: {
                        value: responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-',
                        style: { textAlign: 'center', colSpan: '4', },
                    },
                },
            ]
        }
        , {
            response: [
                {
                    data: {
                        value: `Remarks ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[3]?.cp_answer : '-'}`
                    }
                }
            ]
        }
    ]
    let footer = [
        [
            { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
            { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
            { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
            { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
            { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
            { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.ins_approval_id ? responseData?.maint_checklist[0]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },

        ], [
            { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
            { data: `${responseData?.checklist !== undefined && responseData?.checklist[0]?.end_date ? moment(responseData?.checklist[0]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
            { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
            { data: `${responseData?.checklist !== undefined && responseData?.checklist[0]?.end_date ? moment(responseData?.checklist[0]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
            { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
            { data: `${responseData?.checklist !== undefined && responseData?.checklist[0]?.ins_completed_date ? moment(responseData?.checklist[0]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        ]

    ]

    //666acd540849568dce4373f5
    const getDataValue = (responseData, checkpointIndex, defaultValue = '-') => {
        return responseData?.maint_checklist?.[0]?.maintenance_activities?.[0]?.checkpoints?.[checkpointIndex]?.cp_answer || defaultValue;
    };
    const getResponseData = (value, textAlign = 'center', colSpan = '1', rowSpan = '1') => ({
        value,
        style: { textAlign, colSpan, rowSpan },
    });

    if(docNumber=="QCCB/030:F1-03"){
        data = [
            {
                header: [
                    { data: `Calibration SOP No.`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    { data: `conductivity Standard Solution`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    { data: `Calibrated On`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    { data: `Calibration Due`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    { data: `Calibration Report No`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                ],
                response: [
                    {
                        data: getResponseData(getDataValue(responseData, 0), 'center', '1', '4'),
                        data1: getResponseData("1.3 µs/cm"),
                        data2: getResponseData(getDataValue(responseData, 1)),
                        data3: getResponseData(getDataValue(responseData, 2)),
                        data4: getResponseData(getDataValue(responseData, 9), 'center', '1', '4'),
                    },
                    {
                        data1: getResponseData("5 µs/cm"),
                        data2: getResponseData(getDataValue(responseData, 3)),
                        data3: getResponseData(getDataValue(responseData, 4)),
                    },
                    {
                        data1: getResponseData("84 µs/cm"),
                        data2: getResponseData(getDataValue(responseData, 5)),
                        data3: getResponseData(getDataValue(responseData, 6)),
                    },
                    {
                        data1: getResponseData("1413 µs/cm"),
                        data2: getResponseData(getDataValue(responseData, 7)),
                        data3: getResponseData(getDataValue(responseData, 8)),
                    },
                ]
            },
            {
                response: [
                    {
                        data: getResponseData("INSTRUMENT NAME :", 'left', '2'),
                        data1: getResponseData('CONDUCTIVITY METER', 'left', '10'),
                    },
                    {
                        data: getResponseData("INSTRUMENT CODE NO :", 'left', '2'),
                        data1: getResponseData(responseData?.parent?.parent_id || '-', 'left', '10'),
                    },
                    {
                        data: getResponseData("Location :", 'left', '2'),
                        data1: getResponseData(responseData?.parent?.location || '-', 'left', '10'),
                    },
                    {
                        data: getResponseData("Model No. :", 'left', '2'),
                        data1: getResponseData(responseData?.parent?.model || '-', 'left', '10'),
                    },
                ]
            },
            {
                header: [
                    { data: `1. Preparation of 1.3 µs/cm conductivity standard solution.`, rowSpan: "2", textAlign: 'left' },
                ],
            },
            {
                header: [
                    { data: `The 1.3 µs/cm conductivity standard solution can be procured from outside with certificate of analysis`, rowSpan: "2", textAlign: 'left' },
                ],
                response: [
                    {
                        data: getResponseData("Batch No :", 'left', '3'),
                        data1: getResponseData(getDataValue(responseData, 10), 'left', '4'),
                        data2: getResponseData('Expiry Date', 'left', '4'),
                        data3: getResponseData(getDataValue(responseData, 11), 'left', '3'),
                    },
                ]
            },
            {
                header: [
                    { data: `2. Preparation of 5 µs/cm conductivity standard solution.`, rowSpan: "2", textAlign: 'left' },
                ],
            },
            {
                header: [
                    { data: `The 5 µs/cm  conductivity standard solution can be procured from outside with certificate of analysis`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                ],
                response: [
                    {
                        data: getResponseData("Batch No :", 'left', '3'),
                        data1: getResponseData(getDataValue(responseData, 12), 'left', '4'),
                        data2: getResponseData('Expiry Date', 'left', '4'),
                        data3: getResponseData(getDataValue(responseData, 13), 'left', '3'),
                    },
                ]
            },
            {
                header: [
                    { data: `3. Preparation of 84 µs/cm conductivity standard solution.`, rowSpan: "2", textAlign: 'left' },
                ],
            },
            {
                header: [
                    { data: `The 84 µs/cm  conductivity standard solution can be procured from outside with certificate of analysis`, colSpan: '1', rowSpan: "2", textAlign: 'left' },
                ],
                response: [
                    {
                        data: getResponseData("Batch No :", 'left', '3'),
                        data1: getResponseData(getDataValue(responseData, 14), 'left', '4'),
                        data2: getResponseData('Expiry Date', 'left', '4'),
                        data3: getResponseData(getDataValue(responseData, 15), 'left', '3'),
                    },
                ]
            },
            {
                header: [
                    { data: `4. Preparation of 1413 µs/cm conductivity standard solution.(0.01N Potassium chloride)`, rowSpan: "2", textAlign: 'left' },
                ],
            },
            {
                response: [
                    {
                        data: getResponseData("Instrument Code :", 'left', '2'),
                        data1: getResponseData(responseData?.parent?.parent_id || '-', 'left', '10'),
                    },
                    {
                        data: getResponseData("Batch No :", 'left', '2'),
                        data1: getResponseData(getDataValue(responseData, 17), 'left', '10'),
                    },
                    {
                        data: getResponseData("Weight of Potassium chloride", 'left', '2'),
                        data1: getResponseData(getDataValue(responseData, 18), 'left', '10'),
                    },
                    {
                        data: getResponseData("Lost weight", 'left', '2'),
                        data1: getResponseData(getDataValue(responseData, 19), 'left', '10'),
                    },
                    {
                        data: getResponseData("Final weight of Potassium chloride", 'left', '2'),
                        data1: getResponseData(getDataValue(responseData, 20), 'left', '10'),
                    },
                    {
                        data: getResponseData(`Dissolve ${getDataValue(responseData, 21)} (750 mg) of the potassium chloride in 1000ml v and dilute with water`, 'left', '12'),
                    },
                ]
            },
            {
                header: [
                    { data: `Sr.no`, colSpan: '1', rowSpan: "2", textAlign: 'center' },
                    { data: `Standard conductivity solution`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                    { data: `Temp(in °C)`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                    { data: `Observed conductivity value`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                    { data: `Limit`, colSpan: '4', rowSpan: "2", textAlign: 'center' },
                ],
                response: [
                    {
                        data: getResponseData("1"),
                        data1: getResponseData("1.3 µs/cm", 'center', '4'),
                        data2: getResponseData(getDataValue(responseData, 22), 'center', '4'),
                        data3: getResponseData(getDataValue(responseData, 23), 'center', '4'),
                        data4: getResponseData("1.04 µs/cm to 1.56 µs/cm", 'center', '4'),
                    },
                    {
                        data: getResponseData("2"),
                        data1: getResponseData("5 µs/cm", 'center', '4'),
                        data2: getResponseData(getDataValue(responseData, 24), 'center', '4'),
                        data3: getResponseData(getDataValue(responseData, 25), 'center', '4'),
                        data4: getResponseData("4 µs/cm to 6 µs/cm", 'center', '4'),
                    },
                    {
                        data: getResponseData("3"),
                        data1: getResponseData("84 µs/cm", 'center', '4'),
                        data2: getResponseData(getDataValue(responseData, 26), 'center', '4'),
                        data3: getResponseData(getDataValue(responseData, 27), 'center', '4'),
                        data4: getResponseData("67.2 µs/cm to 100.8 µs/cm", 'center', '4'),
                    },
                    {
                        data: getResponseData("4"),
                        data1: getResponseData("1413 µs/cm", 'center', '4'),
                        data2: getResponseData(getDataValue(responseData, 28), 'center', '4'),
                        data3: getResponseData(getDataValue(responseData, 29), 'center', '4'),
                        data4: getResponseData("1130.4 µs/cm to 1695.6 µs/cm", 'center', '4'),
                    },
                ]
            },
            {
                response: [
                    {
                        data: {
                            value: `INFERENCE :  ${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints && responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[30]?.cp_answer !== '' ? `Instrument found to be ${responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints[30]?.cp_answer}` : '-'}`,
                            style: { textAlign: 'left', colSpan: '18' },
                        },
                    },
                    {
                        data1: {
                            value: `*Note : Standard conductivity solution 1.3 µs/cm calibration shall be performed once in three months`,
                            style: { textAlign: 'left', colSpan: '18' },
                        },
                    }
                ]
            }
        ]
    }

    // header details
    const data1 = {
        data: depname,
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }

    const data3 = {
        data: `${chklname} FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
        colSpan: '7'

    }

    // const distinctCheckpoints3 = [...new Set(resData?.checklist?.map(item => item?.ins_approval_id))];
    const distinctCheckpoints3 = [...new Set(resData?.checklist
        ?.map(item => item?.ins_approval_id)
        .filter(answer => answer !== '' && answer !== undefined && answer !== null)
    )];
    const distinctCheckpoints4 = [...new Set(resData?.checklist
        ?.map(item => item?.performed_by)
        .filter(answer =>
            answer !== '' &&
            answer !== undefined &&
            answer !== null &&
            !Array.isArray(answer)
        )
    )];

    const dataRow2 = {
        data: '22/9/2023',
        colSpan: '6'
    }

    useEffect(() => {
        setResData(responseData)
    }, [responseData])

    return (
        <div>
            {/* <div id="page-header"> */}
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
                malur={malur}
                medopharm={medopharm}
            />
            {/* {instrumentCode == true ? '' : <ReportTable
                headerData2={instCodeData}
            />} */}

            {tableHeaders.length > 0 && tableHeaders?.map((el, i) => {
                return <ReportTable
                    headerData2={el}
                />
            })
            }

            {data?.length > 0 && data?.map((el, i) => {
                return <> <ReportTable
                    headerData={el?.header}
                    headerData2={el?.header2}
                /><ReportTable verticalBodyData={el?.response} />
                </>
            })}

            {footer?.length > 0 && footer?.map((el, i) => {
                return <ReportTable
                    headerData2={el}
                />
            })
            }
        </div>
    )
}

export default CalibrationRecord
