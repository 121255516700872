import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from '../../constants/URLs/urlConstants';


function getFacilityData(data) {
    return serviceBase.get(
      CONSTANTS.FACILITY_GET_ALL + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`
    );    
};

function deleteFacilityData (id) {
    // console.log("id", id);
    return serviceBase.delete(CONSTANTS.FACILITY_DELETE + id[3]);    
};

function addFacilityData(data) {
    return serviceBase.post(CONSTANTS.FACILITY_ADD, data);
};

function updateFacility(data) {
    return serviceBase.patch(CONSTANTS.FACILITY_UPDATE + data[0], data[1]);
}

function getSingleFacility(id) {
    return serviceBase.get(CONSTANTS.FACILITY_GET_SINGLE + id);
}


export const facilityDetails = {    
    getFacilityData,   
    deleteFacilityData, 
    addFacilityData,
    updateFacility,
    getSingleFacility,
}