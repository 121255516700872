// get status of inspection & maintenance
const INS_MAIN_GET_STATUS = 'INS_MAIN_GET_STATUS';
const INS_MAIN_GET_STATUS_SUCCESS = 'INS_MAIN_GET_STATUS_SUCCESS';
const INS_MAIN_GET_STATUS_FAILED = 'INS_MAIN_GET_STATUS_FAILED';

const FILTERED_INS_DATA = 'FILTERED_INS_DATA';
const FILTERED_INS_DATA_SUCCESS = 'FILTERED_INS_DATA_SUCCESS';
const FILTERED_INS_DATA_FAILED = 'FILTERED_INS_DATA_FAILED';

const FILTERED_MAIN_DATA = 'FILTERED_MAIN_DATA';
const FILTERED_MAIN_DATA_SUCCESS = 'FILTERED_MAIN_DATA_SUCCESS';
const FILTERED_MAIN_DATA_FAILED = 'FILTERED_MAIN_DATA_FAILED';


const INS_MAIN_GET_STATUS_OFFLINE = 'INS_MAIN_GET_STATUS_OFFLINE';
const INS_MAIN_GET_STATUS_OFFLINE_SUCCESS = 'INS_MAIN_GET_STATUS_OFFLINE_SUCCESS';
const INS_MAIN_GET_STATUS_OFFLINE_FAILED = 'INS_MAIN_GET_STATUS_OFFLINE_FAILED';


export {
    INS_MAIN_GET_STATUS,
    INS_MAIN_GET_STATUS_SUCCESS,
    INS_MAIN_GET_STATUS_FAILED,

    FILTERED_INS_DATA,
    FILTERED_INS_DATA_SUCCESS,
    FILTERED_INS_DATA_FAILED,

    FILTERED_MAIN_DATA,
    FILTERED_MAIN_DATA_SUCCESS,
    FILTERED_MAIN_DATA_FAILED,

    INS_MAIN_GET_STATUS_OFFLINE,
    INS_MAIN_GET_STATUS_OFFLINE_SUCCESS,
    INS_MAIN_GET_STATUS_OFFLINE_FAILED,
}
    