import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  INSPECTION_ADD,
  INSPECTION_ADD_SUCCESS,
  INSPECTION_ADD_FAILED,

  INSPECTION_GET_LIST,
  INSPECTION_GET_SUCCESS,
  INSPECTION_GET_FAILED,

  INSPECTION_GET_SINGLE,
  INSPECTION_GET_SINGLE_SUCCESS,
  INSPECTION_GET_SINGLE_FAILED,

  INSPECTION_UPDATE,
  INSPECTION_UPDATE_SUCCESS,
  INSPECTION_UPDATE_FAILED,

  INSPECTION_ASSIGN_LIST,
  INSPECTION_ASSIGN_SUCCESS,
  INSPECTION_ASSIGN_FAILED,

  INSPECTION_DELETE_LIST,
  INSPECTION_DELETE_FAILED,

  INSPECTION_BULK_ADD,
  INSPECTION_BULK_ADD_FAILED,

  INSPECTION_REPORT,
  INSPECTION_REPORT_SUCCESS,
  INSPECTION_REPORT_FAILED,

  INSPECTION_STATUS,
  INSPECTION_GET_STATUS_SUCCESS,
  INSPECTION_GET_STATUS_FAILED,

  INSPECTION_STOP,
  INSPECTION_STOP_SUCCESS,
  INSPECTION_STOP_FAILED,
} from "../actions/inspectionAction";
import { inspectionService } from "../../services/service.index";
import { responsiveArray } from "antd/lib/_util/responsiveObserve";

export function* watchAddInspection() {
  yield takeLatest(INSPECTION_ADD, addInspectionSaga);
}

export function* watchGetInspection() {
  yield takeLatest(INSPECTION_GET_LIST, getInspectionSaga);
}

export function* watchGetSingleInspection() {
  yield takeLatest(INSPECTION_GET_SINGLE, getSingleInspectionSaga);
}

export function* watchInspectionUpdate() {
  yield takeLatest(INSPECTION_UPDATE, updateInspectionSaga);
}

export function* watchAssignInspection() {
  yield takeLatest(INSPECTION_ASSIGN_LIST, assignInspectionSaga);
}

export function* watchDeleteInspection() {
  yield takeLatest(INSPECTION_DELETE_LIST, deleteInspectionSaga);
}

export function* watchBulkAddInspection() {
  yield takeLatest(INSPECTION_BULK_ADD, bulkAddInspectionSaga);
}
export function* watchInspectionReportGet() {
  yield takeLatest(INSPECTION_REPORT, getInspectionReportSaga);
}
export function* watchGetInspectionStatus() {
  yield takeLatest(INSPECTION_STATUS, getInspectionStatus);
}
export function* watchStopInspectionStatus() {
  yield takeLatest(INSPECTION_STOP, stopInspectionSaga);
}
//upload inspection details
export function* addInspectionSaga(action) {
  // console.log(action);
  try {
    const response = yield call(
      inspectionService.addInspectionData,
      action.payload
    );
    // console.log(response);
    if (response.status === 201) {
      yield put({
        type: INSPECTION_ADD_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: INSPECTION_GET_LIST,
      });
      alert("Inspection Uploaded Successfully");
    } else {
      yield put({
        type: INSPECTION_ADD_FAILED,
      });
    }
  } catch (e) {
      // console.log("error" ,e);
    yield put({
      type: INSPECTION_ADD_FAILED,
    });
  }
}
//fetch single inspection details to update
export function* getSingleInspectionSaga(action) {
  try {
    const response = yield call(
      inspectionService.getSingleInspData,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: INSPECTION_GET_SINGLE_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: INSPECTION_GET_SINGLE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: INSPECTION_GET_SINGLE_FAILED,
    });
  }
}
//update inspection details
export function* updateInspectionSaga(action) {
  try {
    const response = yield call(
      inspectionService.updateInspectionData,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: INSPECTION_UPDATE_SUCCESS,
        payload: action.payload,
      });
      alert("Inspection Updated Successfully");
      yield put({
        type: INSPECTION_GET_LIST,
      });
    } else {
      yield put({
        type: INSPECTION_UPDATE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: INSPECTION_UPDATE_FAILED,
    });
  }
}
export function* getInspectionSaga(action) {
  try {
    const response = yield call(
      inspectionService.getInspectionData,
      action.payload
    );
    // console.log(response.data);
    if (response.status === 200) {
      yield put({
        type: INSPECTION_GET_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getInspectionSaga Error response", response)
      yield put({
        type: INSPECTION_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log("getInspectionSaga Error response", e)
    yield put({
      type: INSPECTION_GET_FAILED,
    });
  }
}
export function* assignInspectionSaga(action) {
  try {
    const response = yield call( inspectionService.assignInspectionData, action.payload);
    // console.log(action.payload);
    // console.log(response);
    if (response.status === 200) {
      const data = action.payload;
      // console.log(data);
      yield put({
        type: INSPECTION_ASSIGN_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: INSPECTION_GET_LIST,
        payload: data[2]
      });
      alert("Inspection Assigned Successfully")
    } else {
      yield put({
        type: INSPECTION_ASSIGN_FAILED,
      });
    }
  } catch (e) {
    // console.log(e);
    yield put({
      type: INSPECTION_ASSIGN_FAILED,
    });
  }
}
export function* deleteInspectionSaga(action) {
  try {
    // console.log("viewUserSaga action", action)
    const response = yield call(
      inspectionService.deleteInspectionData,
      action.payload
    );
    if (response.status === 200) {
      // console.log("delete Inspection Data response", response)
      const data = action.payload;   
      yield put({
        type: INSPECTION_GET_LIST,
        payload: [ data[0], data[1], data[2], data[3] ]                
      });
      alert("Inspection Deleted Successfully");
    } else {
      // console.log("INSPECTION_DELETE_FAILED Error response", response)
      yield put({
        type: INSPECTION_DELETE_FAILED,
      });
    }
  } catch (e) {
    // console.log("getInspectionSaga Error response", e)
    yield put({
      type: INSPECTION_DELETE_FAILED,
    });
  }
}
export function* bulkAddInspectionSaga(action) {
  try {
    const response = yield call(
      inspectionService.addBulkInspection,
      action.payload
    );
    // console.log("addBulkInspection action.payload", action.payload);
    if (response.status === 201) {
      // console.log("addBulkInspection response", response);
      const data = action.payload.response
      alert("Data uploaded Successfully");
      yield put({
        type: INSPECTION_GET_LIST,
        payload: [data[2], 1 , 10, '']
      });
    } else {
      // console.log("INSPECTION_BULK_ADD_FAILED Error response", response);
      yield put({
        type: INSPECTION_BULK_ADD_FAILED,
      });
    }
  } catch (e) {
    // console.log("addInspectionSaga Error response", e);
    yield put({
      type: INSPECTION_BULK_ADD_FAILED,
    });
    alert("Error!", e.response.data.message, [{ text: "Okay" }]);
  }
}

// Report
export function* getInspectionReportSaga(action) {
  try {
    const response = yield call(
      inspectionService.inspectionReport,
      action.payload
    );
    // console.log("getInspectionReportSaga response", response);
    if (response.status === 200) {
      yield put({
        type: INSPECTION_REPORT_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getInspectionSaga Error response", response)
      yield put({
        type: INSPECTION_REPORT_FAILED,
      });
    }
  } catch (e) {
    // console.log("getInspectionSaga Error response", e)
    yield put({
      type: INSPECTION_REPORT_FAILED,
    });
  }
}
export function* getInspectionStatus(action) {
  // console.log("action", action);
  try {
    const response = yield call(
      inspectionService.inspectionGetallStatus,
      action.payload
    );
    // console.log("response", response);
    if (response.status === 200) {
      yield put({
        type: INSPECTION_GET_STATUS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: INSPECTION_GET_STATUS_FAILED,
      });
    }
  } catch (e) {
    // console.log("error", e);
    yield put({
      type: INSPECTION_GET_STATUS_FAILED,
    });
  }
}
export function* stopInspectionSaga(action) {
  try {
    const response = yield call(inspectionService.stopInspectionData,action.payload);
    // console.log(action.payload);
    // console.log(response)
    if (response.status === 200) {
      const data = action.payload
      yield put({
        type: INSPECTION_STOP_SUCCESS,
      });
      yield put({
        type: INSPECTION_GET_LIST,
        payload: data[2]
      });      
      alert("Inspection stopped successfully")
    } else {
      yield put({
        type: INSPECTION_STOP_FAILED,
      });
    }
  } catch (e) {
    // console.log(e);
    yield put({
      type: INSPECTION_STOP_FAILED,
    });
  }
}