import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import Commonheader from "../../../layouts/common/commonHeader";
import { INSPECTION_UPDATE } from "../../../redux/actions/inspectionAction";
import "./inspectStyle.css";

const UpdateInspection = ({ action }) => {
  const location = useLocation();
  const isLoading = useSelector((state) => state.inspection.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const inspGetSingle = useSelector((state) => state.inspection.inspGetSingle);
  const accessIn = useSelector((state) => state.access.assetInspection);
  const accessOnDemand = useSelector((state) => state.access.onDemandInspection);

  // console.log(inspGetSingle);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [radioValue, setRadiovalue] = useState("");
  const [endValue, setEndValue] = useState("");
  const [key, setKey] = useState('')
  const [minOfHrs, setMinOfHrs] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [hoursOfDay, setHoursOfDay] = useState([]);
  const [viewAction, setViewAction] = useState(false);

  //Array of Hours - its used for repeat hourly frequency check box
  const numOfHours = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  //Array of Days
  const numOfDays = [{ key: 0, value: "Sun" }, { key: 1, value: "Mon" }, { key: 2, value: "Tue" }, { key: 3, value: "Wed" }, { key: 4, value: "Thu" }, { key: 5, value: "Fri" }, { key: 6, value: "Sat" }];

  const addInspChekPonts = () => {
    let newCpObj = {
      checkpoint: "",
      uom: "",
      standard_requirement: "",
      dropdown_array: [],
      answer_type: "",
    };
    let cpList = data.checkpoints;
    let updatedList = cpList.push(newCpObj);
    setData({ ...data, updatedList });
  };
  //delete Task
  const deleteInspChekPonts = (index) => {
    alert("Are you sure you wish to delete ?");
    let afterDelCp = data.checkpoints;
    afterDelCp.splice(index, 1);
    setData({ ...data, afterDelCp });
  };
  const [data, setData] = useState({
    parent_mid: "",
    inspection_name: "",
    priority: "",
    based_on: "",
    managed_by: "",
    assignee_type: "",
    assignee: [],
    inspection_duration: "",
    man_power_required: "",
    man_hour_required: "",
    sla: "",
    sla_interval: "",
    type: "",
    frequency: {
      frequency_type: "", //"months",
      frequency_type_value: "",
      frequency_end_type: "",
      frequency_end_occurence_value: "",
      frequency_end_date_value: "",
      frequency_days_of_week: [],
      frequency_hours_of_day: [],
      frequency_mits_of_hrs: [],
    },
    checkpoints: [
      {
        checkpoint: "",
        uom: "",
        standard_requirement: "",
        answer_type: "",
      },
    ],
  });

  const handleDropdownOptionChange = (e, i, optionIndex) => {
    const value = e.target.value;
    // console.log(value);
    setData((prevState) => {
      prevState.checkpoints[i].dropdown_array[optionIndex] = value;
      return { ...prevState };
    });
  };

  const addDropdownOption = (i) => {
    setData((prevState) => {
      prevState.checkpoints[i].dropdown_array.push("");
      return { ...prevState };
    });
  };

  const removeDropdownOption = (i, optionIndex) => {
    setData((prevState) => {
      prevState.checkpoints[i].dropdown_array.splice(optionIndex, 1);
      return { ...prevState };
    });
  };

  //Client-side validation
  const [form, setForm] = useState({
    name: "",
    priority: "",
    basedOn: "",
    managedBy: "",
    type: "",
    frequency: "",
    checkpoint: "",
    stdReq: "",
    ansTyp: "",
  });
  // console.log("name",form.name);
  const [nameErr, setNameErr] = useState("");
  const [priorityErr, setPriorityErr] = useState("");
  const [basedonErr, setBasedonErr] = useState("");
  const [managedByErr, setManagedbyErr] = useState("");
  const [freqErr, setFreqErr] = useState("");
  const [freqValErr, setFreqValErr] = useState("");
  const [endTypeErr, setEndTypeErr] = useState("");
  const [cpErr, setCpErr] = useState("");
  const [stdReqErr, setStdReqErr] = useState("");
  const [ansTypeErr, setAnsTypeErr] = useState("");

  let isValid = true;

  const handleName = (val) => {
    setData({
      ...data,
      inspection_name: val,
    });
    setForm({
      ...form,
      name: val
    })
  };

  const handleProity = (val) => {
    setRadiovalue(val);
    setData({
      ...data,
      priority: val,
    });
  };
  const handleBased = (val) => {
    setData({
      ...data,
      based_on: val,
    });
  };
  const handleManagedby = (val) => {
    setData({
      ...data,
      managed_by: val,
    });
  };
  const handleInspDuratn = (val) => {
    setData({
      ...data,
      inspection_duration: val,
    });
  };
  const handleManPower = (val) => {
    setData({
      ...data,
      man_power_required: val,
    });
  };
  const handleManHour = (val) => {
    setData({
      ...data,
      man_hour_required: val,
    });
  };
  const handleSla = (val) => {
    setData({
      ...data,
      sla: val,
    });
  };
  const handleSlaInval = (val) => {
    setData({
      ...data,
      sla_interval: val,
    });
  };

  const handleType = (val) => {
    setData({
      ...data,
      type: val,
    });
  };

  const handleFreqType = (val) => {
    // console.log(val);
    // setMinOfHrs([])
    // setDaysOfWeek([])
    // setHoursOfDay([])
    setData((prevState) => {
      prevState.frequency.frequency_type = val;
      // prevState.frequency.frequency_mits_of_hrs = [];
      // prevState.frequency.frequency_hours_of_day = [];
      // prevState.frequency.frequency_days_of_week = [];
      return { ...prevState };
    });
    val === ("repeat_minutes" || 'repeat_days' || 'repeat_hours') &&
      setData((prevState) => {
        prevState.frequency.type_value = '1';
        return { ...prevState };
      });
  };
  const handleFreqTypeval = (val) => {
    setData((prevState) => {
      prevState.frequency.frequency_type_value = val;
      return { ...prevState };
    });
    setForm({
      ...form,
      frequency: val
    })
  };

  const convertToUTC = (time) => {
    const localTime = new Date('1970-01-01T' + time + ':00');
    const utcTime = localTime.toISOString().substr(11, 5);
    return utcTime;
  };

  const handlehr = (localTime) => {
    // console.log(e);
    const utcTime = convertToUTC(localTime);
    const newHrs = [...data.frequency.frequency_hours_of_day];
    const hours = newHrs.indexOf(utcTime);
    if (hours === -1) {
      newHrs.push(utcTime);
    } else {
      newHrs.splice(hours, 1);
    }
    setMinOfHrs(newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b)));
    setData((prevState) => {
      prevState.frequency.frequency_hours_of_day = newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b));
      prevState.frequency.frequency_type_value = ""
      return { ...prevState };
    });
  };
  const handleDay = (e) => {
    // console.log(e);
    const newDays = [...data.frequency.frequency_days_of_week];
    const index = newDays.indexOf(e);
    if (index === -1) {
      newDays.push(e);
      // newDays.push(e === "Sun" ? 0 : e === "Mon" ? 1 : e === "Tue" ? 2 : e === "Wed" ? 3 : e === "Thu" ? 4 : e === "Fri" ? 5 : e === "Sat" ? 6 : "");
    } else {
      newDays.splice(index, 1);
    }
    // console.log('newDays', newDays);
    // const resultArray = newDays.map(day => ({ 'Sun': 0, 'Mon': 1, 'Tue': 2, 'Wed': 3, 'Thu': 4, 'Fri': 5, 'Sat': 6 })[day]);
    // const resultArray1 = resultArray.filter(value => typeof value === 'number' && !isNaN(value))
    // console.log('resultArray1', resultArray1);
    setDaysOfWeek(newDays.sort((a, b) => a - b));
    setData((prevState) => {
      prevState.frequency.frequency_days_of_week = newDays.sort((a, b) => a - b);
      prevState.frequency.frequency_type_value = ""
      return { ...prevState };
    });
  };
  const handleFreqEnd = (val) => {
    setEndValue(val);
    setData((prevState) => {
      prevState.frequency.frequency_end_type = val;
      return { ...prevState };
    });
  };
  const handleCheckpont = (val, index) => {
    setData((prevState) => {
      prevState.checkpoints[index].checkpoint = val;
      return { ...prevState };
    });
    // setForm({
    //   ...form,
    //   checkpoint:val
    // })
  };
  const handleUom = (val, index) => {
    setData((prevState) => {
      prevState.checkpoints[index].uom = val;
      return { ...prevState };
    });
  };
  const handleStdReq = (val, index) => {
    setData((prevState) => {
      prevState.checkpoints[index].standard_requirement = val;
      return { ...prevState };
    });
  };
  const handleAnsType = (val, index) => {
    setData((prevState) => {
      prevState.checkpoints[index].answer_type = val;
      return { ...prevState };
    });
  };

  const formValidation = () => {

    const nameErr = {};
    const priorityErr = {};
    const basedonErr = {};
    const managedByErr = {};
    const freqErr = {};
    const freqValErr = {};
    const endTypeErr = {};
    const cpErr = {};
    const stdReqErr = {};
    const ansTypeErr = {};

    if (form.name.trim().length === 0) {
      nameErr.inspShort = "Please Enter Inspection Name";
      isValid = false;
    }
    setNameErr(nameErr);

    if (form.priority === "") {
      priorityErr.inspShort = "Please Choose Priority";
      isValid = false;
    }
    setPriorityErr(priorityErr);

    if (form.basedOn === "") {
      basedonErr.inspShort = "Please Choose Based on";
      isValid = false;
    }
    setBasedonErr(basedonErr);

    if (form.managedBy === "") {
      managedByErr.inspShort = "Please Choose Managed by";
      isValid = false;
    }
    setManagedbyErr(managedByErr);

    // if (form.frequency.trim().length < 1) {
    //   freqErr.inspShort = "Please Enter Frequency Value";
    //   isValid = false;
    // }
    // setFreqErr(freqErr);
    if (data.frequency.frequency_type === "") {
      freqErr.inspShort = "Please Choose Frequency";
      isValid = false;
    }
    setFreqErr(freqErr);

    if (form.checkpoint.trim().length < 1) {
      cpErr.inspShort = "Please Enter Checkpoint";
      isValid = false;
    }
    setCpErr(cpErr);

    if (form.stdReq.trim().length < 1) {
      stdReqErr.inspShort = "Please Enter Standard Requirement";
      isValid = false;
    }
    setStdReqErr(stdReqErr);

    if (form.ansTyp === "") {
      ansTypeErr.inspShort = "Please Choose Answer Type";
      isValid = false;
    }
    setAnsTypeErr(ansTypeErr);
    if (data.frequency.frequency_type_value === "" &&
      (data?.frequency?.frequency_type !== "repeat_minutes") &&
      (data.frequency.frequency_type !== "repeat_hours") &&
      (data.frequency.frequency_type !== "repeat_days")) {
      freqValErr.inspShort = "Please Enter Frequency Value";
      isValid = false;
    }
    setFreqValErr(freqValErr)
    if (data.frequency.frequency_end_type === "") {
      endTypeErr.inspShort = "Please Choose Inspection End Type";
      isValid = false;
    }
    setEndTypeErr(endTypeErr);
    // console.log('isValid', isValid);
    return isValid;
  }

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  //fetch customer ID
  const sendID = () => {
    if (id !== null) {
      dispatch({
        type: "INSPECTION_GET_SINGLE",
        payload: id,
      });
    }
  };
  //useEffects
  useEffect(() => {
    sendID();
    // console.log('accessIn', accessIn);
  }, [id]);

  useEffect(() => {

  }, [isValid]);

  useEffect(() => {
    if (inspGetSingle !== null) {
      const getRadioValues = inspGetSingle.priority;
      setRadiovalue(getRadioValues);
    }
  }, [inspGetSingle]);

  useEffect(() => {
    if (inspGetSingle !== null) {
      const getValues = inspGetSingle.frequency.frequency_end_type;
      setEndValue(getValues);
    }
  }, [inspGetSingle]);

  const updateInspChekPonts = (e) => {
    // console.log(data.frequency.frequency_days_of_week, "data");
    e.preventDefault();
    // validation()
    // let validate_Cp = true
    // if (data.checkpoints.length === 0) {
    //   alert(`Please add atleast one checkpoint`)
    //   validate_Cp = false
    // }
    // else if ((data.frequency.type === 'repeat_days' || data.frequency.type === 'repeat_hours') && data.frequency.frequency_days_of_week.length === 0) {
    //   alert("Please select atleast one day of week");
    // } else if (data.frequency.type === 'repeat_hours' && data.frequency.frequency_hours_of_day.length === 0) {
    //   alert("Please select atleast one hour of day");
    // }
    // data.checkpoints.forEach((obj) => {
    //   if (data.inspection_name === "" || data.frequency.frequency_type_value === "" || obj.standard_requirement === "" ||
    //     obj.checkpoint === "") {
    //     validate_Cp = false
    //   }
    // })
    if (validation()) {
      alert("Please add atleast one Checkpoint", 2.5);
    }
    else if (data.based_on === 'calendar' && (validation() ||
      // data.frequency.frequency_type_value === "" ||
      data.checkpoints.length === 0)
    ) {
      alert("Please fill Mandatory field's", 2.5);
    } else if ((data.frequency.frequency_type === 'repeat_days' || data.frequency.frequency_type === 'repeat_hours') && data.frequency.frequency_days_of_week.length === 0) {
      alert("Please select atleast one day of week");
    }
    else if (data.frequency.frequency_type === 'repeat_hours' && data.frequency.frequency_hours_of_day.length === 0) {
      alert("Please select atleast one hour of day");
    }
    else {
      let validate_Cp = true
      data.checkpoints.forEach((obj) => {
        if (obj.standard_requirement === "" ||
          obj.answer_type === "") {
          validate_Cp = false
        }
      })
      if (validate_Cp) {
        let updateInspData = {
          parent_mid: inspGetSingle.parent_mid,
          inspection_name: data.inspection_name,
          priority: data.priority,
          based_on: data.based_on,
          managed_by: data.managed_by,
          assignee: data.assignee,
          inspection_duration: data.inspection_duration,
          man_power_required: data.man_power_required,
          man_hour_required: data.man_hour_required,
          sla: data.sla,
          sla_interval: data.sla_interval,
          type: data.type,
          frequency: {
            frequency_type: data.frequency.frequency_type, //"months",
            frequency_type_value: data.frequency.frequency_type_value,
            frequency_end_type: data.frequency.frequency_end_type,
            frequency_end_occurence_value:
              data.frequency.frequency_end_occurence_value,
            frequency_end_date_value: data.frequency.frequency_end_date_value,
            frequency_days_of_week: (daysOfWeek.length > 0) ? daysOfWeek : inspGetSingle?.frequency?.frequency_days_of_week,
            frequency_hours_of_day: data.frequency.frequency_hours_of_day,
            frequency_minutes_of_hours: data.frequency.frequency_mits_of_hrs,
          },
          checkpoints: data.checkpoints,
          created_by: userid,
          modified_by: userid,
        };
        // console.log('updateInspData', updateInspData);
        dispatch({
          type: INSPECTION_UPDATE,
          payload: [updateInspData, id],
        });
        navigate("/inspectionlist", { state: { key } });
      }
    };
  }
  // useEffect(() => {
  //   const getFreqType = data.frequency.frequency_type;
  //   setFrequcyType(getFreqType);
  // }, [inspGetSingle]);

  useEffect(() => {
    if (inspGetSingle !== null) {
      // console.log("inspGetSingle", inspGetSingle);
      setData({
        parent_mid: inspGetSingle.parent_mid,
        inspection_name: inspGetSingle.inspection_name,
        priority: inspGetSingle.priority,
        based_on: inspGetSingle.based_on,
        managed_by: inspGetSingle.managed_by,
        assignee_type: inspGetSingle.assignee_type,
        assignee: inspGetSingle.assignee,
        inspection_duration: inspGetSingle.inspection_duration,
        man_power_required: inspGetSingle.man_power_required,
        man_hour_required: inspGetSingle.man_hour_required,
        sla: inspGetSingle.sla,
        sla_interval: inspGetSingle.sla_interval,
        type: inspGetSingle?.type,
        // frequency: inspGetSingle.frequency,
        frequency: {
          frequency_type: inspGetSingle.frequency.frequency_type, //"months",
          frequency_type_value: inspGetSingle.frequency.frequency_type_value,
          frequency_end_type: inspGetSingle.frequency.frequency_end_type,
          frequency_end_occurence_value:
            inspGetSingle.frequency.frequency_end_occurence_value,
          frequency_end_date_value:
            inspGetSingle.frequency.frequency_end_date_value,
          frequency_days_of_week: inspGetSingle?.frequency?.frequency_days_of_week,
          frequency_hours_of_day: inspGetSingle.frequency.frequency_hours_of_day,
          frequency_mits_of_hrs: inspGetSingle.frequency.frequency_minutes_of_hours,
        },
        checkpoints: inspGetSingle.checkpoints,
      });
      // console.log(inspGetSingle?.frequency?.frequency_days_of_week, "data");

    }
  }, [inspGetSingle]);


  //functions for validation
  function nameChars(str) {
    const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialChars.test(str);
  }
  // console.log("inspGetSingle", inspGetSingle);

  useEffect(() => {
    // console.log(location.state);
    if (location.state !== undefined && location.state !== null) {
      setKey(location.state.key)
    }
  }, [])
  // console.log('key', key);

  useEffect(() => {
    // console.log('action', action);
    if (action !== undefined && action === 'ins_view') {
      setViewAction(true)
    }
  }, [action])

  return (
    <div>
      <Commonheader />
      {isLoading && <Loader />}
      <div className="container">
        <div className="row">
          <div className="d-flex">
            <div className="col-md-12">
              <>
                <div className="d-flex">
                  <div className="col-md-6">
                    <form className="mt-5 border p-4 bg-light shadow rounded">
                      {/* Add Schedule form starts */}
                      <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>{key} - {(action === 'ins_view' && action !== undefined) ? 'View Inspection' : 'Edit Inspection'}</h3>
                      <div>
                        <div className="mb-2 col-md-12">
                          <label>
                            Inspection Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="inspection_name"
                            className="form-control"
                            placeholder="Inspection Name"
                            value={data.inspection_name}
                            onChange={(e) => handleName(e.target.value)}
                            disabled={viewAction}
                          />
                          {Object.keys(nameErr).map((key) => {
                            if (!data.inspection_name) {
                              return (
                                <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                  {nameErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                          {nameChars(data.inspection_name) ||
                            data.inspection_name === ""
                            ? " "
                            : ((isValid = false),
                              (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    paddingTop: "2px",
                                  }}
                                >
                                  Invalid Inspection Name
                                </div>
                              ))}
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>
                            Priority
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px" }}
                            type="radio"
                            name="priority"
                            id="priority"
                            value={radioValue}
                            onChange={(e) => handleProity(e.target.value)}
                            checked={radioValue === "low"}
                            disabled
                          />
                          &nbsp;Low
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px" }}
                            type="radio"
                            name="priority"
                            id="priority"
                            value={radioValue}
                            onChange={(e) => handleProity(e.target.value)}
                            checked={radioValue === "medium"}
                            disabled
                          />
                          &nbsp; Medium
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px" }}
                            type="radio"
                            name="priority"
                            id="priority"
                            value={radioValue}
                            onChange={(e) => handleProity(e.target.value)}
                            checked={radioValue === "high"}
                            disabled
                          />
                          &nbsp; High
                          {Object.keys(priorityErr).map((key) => {
                            if (!data.priority) {
                              return (
                                <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                  {priorityErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>
                            Based on
                            <span className="text-danger">*</span>
                          </label>
                          {/* <select
                            name="based_on"
                            className="form-select"
                            placeholder="Select"
                            value={data.based_on || ""}
                            onChange={(e) => {
                              handleBased(e.target.value);
                            }}
                            disabled={viewAction}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="calendar">Calendar</option>
                            <option value="on_demand">On Demand</option>
                          </select> */}
                          {(accessOnDemand?.c === true) ? (
                            <select
                              name="based_on"
                              className="form-select"
                              value={data.based_on || ""}
                              onChange={(e) => {
                                handleBased(e.target.value);
                              }}
                              disabled={viewAction}
                            >
                              <option value="" disabled>Select</option>
                              <option value="calendar">Calendar</option>
                              <option value="on_demand">On Demand</option>
                            </select>) :
                            <select
                              name="based_on"
                              className="form-select"
                              value={data.based_on || ""}
                              onChange={(e) => {
                                handleBased(e.target.value);
                              }}
                              disabled={viewAction}
                            >
                              <option value="" disabled>Select</option>
                              <option value="calendar">Calendar</option>
                              {/* <option value="on_demand">On Demand</option> */}
                            </select>

                          }

                          {Object.keys(basedonErr).map((key) => {
                            if (!data.based_on) {
                              return (
                                <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                  {basedonErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>
                            Managed by
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            name="managed_by"
                            className="form-select"
                            placeholder="Select"
                            value={data.managed_by || ""}
                            onChange={(e) => {
                              handleManagedby(e.target.value);
                            }}
                            disabled={viewAction}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="inhouse">In house</option>
                            <option value="external">External</option>
                          </select>
                          {Object.keys(managedByErr).map((key) => {
                            if (!data.managed_by) {
                              return (
                                <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                  {managedByErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>Inspection Duration(Mins)</label>
                          <input
                            min="0"
                            name="duration"
                            className="form-control"
                            placeholder="Minutes"
                            defaultValue={data.inspection_duration || ""}
                            onChange={(e) => handleInspDuratn(e.target.value)}
                            disabled={viewAction}
                          />
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>Total man power required</label>
                          <input
                            min="0"
                            name="man_power"
                            className="form-control"
                            placeholder="Total man power"
                            defaultValue={data.man_power_required || ""}
                            onChange={(e) => handleManPower(e.target.value)}
                            disabled={viewAction}
                          />
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>Total man hour required</label>
                          <input
                            min="0"
                            name="man_hour"
                            className="form-control"
                            placeholder="Total man hour"
                            defaultValue={data.man_hour_required || ""}
                            onChange={(e) => handleManHour(e.target.value)}
                            disabled={viewAction}
                          />
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>SLA</label>
                          <input
                            min="0"
                            name="man_hour"
                            className="form-control"
                            placeholder="Total man hour"
                            defaultValue={data.sla || ""}
                            onChange={(e) => handleSla(e.target.value)}
                            disabled={viewAction}
                          />
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>SLA Interval</label>
                          <select
                            name="sla_interval"
                            className="form-select"
                            placeholder="Select"
                            value={data.sla_interval || ""}
                            onChange={(e) => handleSlaInval(e.target.value)}
                            disabled={viewAction}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="hours">Hour</option>
                            <option value="minutes">Minute</option>
                          </select>
                        </div>
                        <div className="mb-2 col-md-12">
                          <label>Type</label>
                          <select
                            name="type"
                            className="form-select"
                            placeholder="Select"
                            value={data.type || ""}
                            onChange={(e) => handleType(e.target.value)}
                            disabled={viewAction}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="water">Water Consumption</option>
                            <option value="lpg">LPG Consumption</option>
                            <option value="electric_energy_grid">Electric Energy - Grid</option>
                            <option value="electric_energy_dg">Electric Energy - DG</option>
                            <option value="diesel">Diesel Consumption</option>
                            <option value="steam">Steam Consumption</option>
                            <option value="compressed_air">Compressed Air Consumption</option>
                            <option value="chilled_water">Chilled Water Consumption</option>
                            <option value="other">Other Consumption</option>
                          </select>
                        </div>
                        <button
                          className="backButton m-2"
                          type="button"
                          onClick={() => {
                            navigate("/inspectionlist", { state: { key } });
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </form>
                  </div>
                  {data.based_on === 'calendar' &&
                    <div className="mx-2 col-md-12">
                      <form className="mt-5 border p-4 bg-light shadow rounded col-md-6">
                        <div className="mb-2 col-md-12">
                          <h4>Calendar based on </h4>
                          <label>
                            Frequency
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            name="type"
                            className="form-select"
                            placeholder="Select"
                            value={data.frequency.frequency_type || ""}
                            onChange={(e) => {
                              handleFreqType(e.target.value);
                            }}
                            disabled={viewAction}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="daily">Daily</option>
                            <option value="hourly">Hourly</option>
                            <option value="minutes">Minutes</option>
                            {/* <option value="repeat_minutes">Repeat Minutes</option> */}
                            <option value="repeat_hours">Repeat Hours</option>
                            <option value="repeat_days">Repeat Days</option>
                          </select>
                          {Object.keys(freqErr).map((key) => {
                            if (!data.frequency.frequency_type) {
                              return (
                                <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                  {freqErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                          {/* Frequency type === Daily */}
                          {((data.frequency.frequency_type === "daily") ||
                            (data.frequency.frequency_type === "hourly") ||
                            (data.frequency.frequency_type === "minutes")) &&
                            <div className="mt-3">
                              <label>
                                Frequency value
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                style={{
                                  marginTop: 5,
                                  height: 35,
                                  paddingLeft: "10px",
                                  borderRadius: 5,
                                  border: "1px solid #ccc",
                                }}
                                min="1"
                                className="col-md-12"
                                placeholder="Number of days"
                                value={data.frequency.frequency_type_value || ""}
                                onChange={(e) => {
                                  handleFreqTypeval(e.target.value);
                                }}

                              />
                              {nameChars(data.frequency.frequency_type_value) ||
                                data.frequency.frequency_type_value === ""
                                ? " "
                                : ((isValid = false),
                                  (
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        color: "red",
                                        paddingTop: "2px",
                                      }}
                                    >
                                      Invalid Frequency Value
                                    </div>
                                  ))}
                              {Object.keys(freqValErr).map((key) => {
                                if (!data.frequency.frequency_type_value) {
                                  return (
                                    <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                      {freqValErr[key]}
                                    </div>
                                  );
                                } else {
                                  <p></p>;
                                }
                              })}
                            </div>
                          }
                          {/* Frequency type === Repeat Minutes */}
                          {data?.frequency?.frequency_type === "repeat_minutes" && (
                            <div className="mt-3">
                              <br></br>
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {numOfDays.map((dayVal, index) => (
                                  <div key={dayVal.key}>
                                    <input
                                      className="daysAlign"
                                      value={dayVal.value}
                                      type="checkbox"
                                      onChange={(e) => handleDay(dayVal.key)}
                                      disabled={viewAction}
                                      checked={data.frequency.frequency_days_of_week.includes(dayVal.key)}
                                    />
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        verticalAlign: 'middle',
                                        marginLeft: '3px',
                                        // marginTop: '22px',
                                      }}
                                    >
                                      {dayVal.value}
                                    </label>
                                  </div>
                                ))}
                                <br></br>
                                <br></br>
                                <br></br>
                                <table >
                                  <tbody>
                                    {numOfHours.reduce((rows, time, index) => {
                                      if (index % 5 === 0) rows.push([]);
                                      rows[rows.length - 1].push(time);
                                      return rows;
                                    }, []).map((row, rowIndex) => (
                                      <tr key={rowIndex}>
                                        {row.map((time, cellIndex) => (
                                          <>
                                            <td key={cellIndex} style={{ verticalAlign: 'middle' }}>
                                              <div>
                                                <input
                                                  className="hoursAlign"
                                                  value={time}
                                                  type="checkbox"
                                                  onChange={(e) => handlehr(e.target.value)}
                                                  // checked={data.frequency.frequency_mits_of_hrs.includes(time)}
                                                  checked={data.frequency.frequency_mits_of_hrs.map(utcTime => {
                                                    const utcDate = new Date(`1970-01-01T${utcTime}:00Z`);
                                                    const localHours = utcDate.getHours().toString().padStart(2, '0');
                                                    const localMinutes = utcDate.getMinutes().toString().padStart(2, '0');
                                                    return `${localHours}:${localMinutes}`;
                                                  }).includes(time)}
                                                  disabled={viewAction}
                                                />
                                                <label
                                                  style={{
                                                    fontSize: "13px",
                                                    verticalAlign: 'middle',
                                                    marginLeft: '3px',
                                                    // marginTop: '22px',
                                                  }}
                                                >{time}</label>
                                              </div>
                                            </td>
                                          </>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {/* Frequency type === Repeat Hours */}
                          {data.frequency.frequency_type === "repeat_hours" && (
                            <div className="mt-3">
                              <br></br>
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {numOfDays.map((dayVal, index) => (
                                  <div key={dayVal.key}>
                                    <input
                                      className="daysAlign"
                                      value={dayVal.value}
                                      type="checkbox"
                                      onChange={(e) => handleDay(dayVal.key)}
                                      disabled={viewAction}
                                      checked={data.frequency.frequency_days_of_week.includes(dayVal.key)}
                                    />
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        verticalAlign: 'middle',
                                        marginLeft: '3px',
                                        // marginTop: '22px',
                                      }}
                                    >
                                      {dayVal.value}
                                    </label>
                                  </div>
                                ))}
                                <br></br>
                                <br></br>
                                <br></br>
                                <table >
                                  <tbody>
                                    {numOfHours.reduce((rows, time, index) => {
                                      if (index % 5 === 0) rows.push([]);
                                      rows[rows.length - 1].push(time);
                                      return rows;
                                    }, []).map((row, rowIndex) => (
                                      <tr key={rowIndex}>
                                        {row.map((time, cellIndex) => (
                                          <>
                                            <td key={cellIndex} style={{ verticalAlign: 'middle' }}>
                                              <div>
                                                <input
                                                  className="hoursAlign"
                                                  value={time}
                                                  type="checkbox"
                                                  onChange={(e) => handlehr(e.target.value)}
                                                  // checked={data.frequency.frequency_hours_of_day.includes(time)}
                                                  checked={data.frequency.frequency_hours_of_day.map(utcTime => {
                                                    const utcDate = new Date(`1970-01-01T${utcTime}:00Z`);
                                                    const localHours = utcDate.getHours().toString().padStart(2, '0');
                                                    const localMinutes = utcDate.getMinutes().toString().padStart(2, '0');
                                                    return `${localHours}:${localMinutes}`;
                                                  }).includes(time)}
                                                  disabled={viewAction}
                                                />
                                                <label
                                                  style={{
                                                    fontSize: "13px",
                                                    verticalAlign: 'middle',
                                                    marginLeft: '3px',
                                                    // marginTop: '22px',
                                                  }}
                                                >{time}</label>
                                              </div>
                                            </td>
                                          </>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {/* Frequency type === Repeat Days */}
                          {data.frequency.frequency_type === "repeat_days" && (
                            <div className="mt-3">
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {numOfDays.map((dayVal, index) => (
                                  <div key={dayVal.key}>
                                    <input
                                      className="daysAlign"
                                      value={dayVal.value}
                                      type="checkbox"
                                      onChange={(e) => handleDay(dayVal.key)}
                                      disabled={viewAction}
                                      checked={data.frequency.frequency_days_of_week.includes(dayVal.key)}
                                    />
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        verticalAlign: 'middle',
                                        marginLeft: '3px',
                                        // marginTop: '22px',
                                      }}
                                    >
                                      {dayVal.value}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          {/* Schedule End types */}
                          <div className="mt-4 md-6">
                            <label>Inspection End Type</label>
                            <span className="text-danger">*</span>
                            <div>
                              <input
                                className="radioButtons"
                                style={{ marginLeft: "25px", margin: "8px" }}
                                type="radio"
                                name="endType"
                                id="endType"
                                value={endValue}
                                onChange={(e) => handleFreqEnd(e.target.value)}
                                checked={endValue === "no_end"}
                                disabled
                              />
                              No End<br></br>
                              <input
                                className="radioButtons"
                                style={{ marginLeft: "25px", margin: "8px" }}
                                type="radio"
                                name="endType"
                                id="endType"
                                value={endValue}
                                onChange={(e) => handleFreqEnd(e.target.value)}
                                checked={endValue === "end_after_occurence"}
                                disabled
                              />
                              End After Occurences
                              <br></br>
                              <input
                                className="radioButtons"
                                style={{ marginLeft: "25px", margin: "8px" }}
                                type="radio"
                                name="endType"
                                id="endType"
                                value={endValue}
                                onChange={(e) => handleFreqEnd(e.target.value)}
                                checked={endValue === "end_date"}
                                disabled
                              />
                              End Date
                            </div>
                            {Object.keys(endTypeErr).map((key) => {
                              if (!data.frequency.frequency_end_type) {
                                return (
                                  <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                    {endTypeErr[key]}
                                  </div>
                                );
                              } else {
                                <p></p>;
                              }
                            })}
                          </div>
                        </div>
                      </form>
                    </div>
                  }
                </div>
                {data.checkpoints &&
                  data.checkpoints?.map((item, i) => (
                    <div key={i} className="mt-4 p-4 bg-light shadow rounded">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h5>Task number : {i + 1}</h5>
                        <button className="delIcons" type="button">
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            onClick={() => deleteInspChekPonts(i)}
                            disabled={viewAction}
                          />
                        </button>
                      </div>

                      <div className="card-body py-0">
                        <div className="col-md-12 p-2">
                          <label>Checkpoint
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="checkpoint"
                            className="form-control"
                            placeholder="Enter Checkpoint"
                            value={item.checkpoint}
                            onChange={(e) => {
                              handleCheckpont(e.target.value, i);
                            }}
                            disabled={viewAction}
                          />
                          {nameChars(item.checkpoint) ||
                            item.checkpoint === ""
                            ? " "
                            : ((isValid = false),
                              (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    paddingTop: "2px",
                                  }}
                                >
                                  Invalid Checkpoint
                                </div>
                              ))}
                          {Object.keys(cpErr).map((key) => {
                            if (!item.checkpoint) {
                              return (
                                <div
                                  key={key}
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {cpErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                        <div className="col-md-12 p-2">
                          <label>UOM of Rating capacity</label>
                          <input
                            type="text"
                            name="uom"
                            className="form-control"
                            placeholder="Enter UOM"
                            value={item.uom || ""}
                            onChange={(e) => {
                              handleUom(e.target.value, i);
                            }}
                            disabled={viewAction}
                          />
                        </div>
                        <div className="col-md-12 p-2">
                          <label>Standard Requirement
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="std_desc"
                            className="form-control"
                            placeholder="Standard Requirement"
                            value={item.standard_requirement || ""}
                            onChange={(e) => {
                              handleStdReq(e.target.value, i);
                            }}
                            disabled={viewAction}
                          />
                          {Object.keys(stdReqErr).map((key) => {
                            // !data.checkpoints[i].standard_requirement
                            if (!item.standard_requirement) {
                              return (
                                <div
                                  key={key}
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {stdReqErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                        <div className="col-md-12 p-2 mb-4">
                          <label>Answer Type
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            name="ans_type"
                            className="form-select"
                            placeholder="Select"
                            value={item.answer_type || ""}
                            onChange={(e) => {
                              handleAnsType(e.target.value, i);
                            }}
                            disabled={viewAction}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="yes_no_na">Yes / No / Not Applicable</option>
                            <option value="value">Value</option>
                            <option value="dropdown">Dropdown</option>
                          </select>
                          {item?.answer_type === "dropdown" && (
                            <div>
                              <br />
                              <label>Dropdown Options:</label> &nbsp;&nbsp;&nbsp;&nbsp;
                              <button className="delIcons"
                                onClick={() => addDropdownOption(i)}
                                disabled={viewAction}
                              >
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={faPlus}
                                />
                              </button>
                              {item?.dropdown_array?.map((option, optionIndex) => (
                                <div key={optionIndex} className="option-container" style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: '10px',
                                  marginTop: '10px',
                                }}>
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}>{optionIndex + 1}. </div>
                                  <input
                                    type="text"
                                    name="checkpoint"
                                    className="form-control"
                                    maxLength="200"
                                    placeholder="Enter Option"
                                    value={option}
                                    onChange={(e) =>
                                      handleDropdownOptionChange(e, i, optionIndex)
                                    }
                                    disabled={viewAction}
                                  />
                                  <button className="delIcons float-end" onClick={() => removeDropdownOption(i, optionIndex)} disabled={viewAction}>
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faTrash}
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                          {Object.keys(ansTypeErr).map((key) => {
                            if (!item.answer_type) {
                              return (
                                <div
                                  key={key}
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {ansTypeErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            </div>
          </div>

          <div className="mt-10 col-md-12 p-2">
            <button
              className="addBtn float-end"
              onClick={addInspChekPonts}
              style={{
                borderRadius: "8px",
                padding: "8px",
                fontWeight: "bold",
              }}
              disabled={viewAction}
            >
              Add Checkpoints
            </button>
          </div>
          <div className="mt-10 col-md-12 p-2">
            <button
              className="saveButton float-end"
              onClick={(e) => updateInspChekPonts(e)}
              disabled={viewAction}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateInspection;
