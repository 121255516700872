import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'

function BOD({ responseData, filterData, chklname, docNumber, frequency, depname ,data6,data4, logo ,instrumentCode,malur,medopharm,header2}) {

    const [resData, setResData] = useState([])

    const data1 = {
        data: depname,
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }
    const data3 = {
        data: `${chklname} FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
        colSpan: '7'
    }


    useEffect(() => {
        setResData(responseData)
    }, [responseData])


    return (
        <div>
           <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={logo}
                malur={malur}
                medopharm={medopharm}
            />
            {instrumentCode!==true && <ReportTable
                headerData={data4}
            
            />}
            <ReportTable
                headerData={data6}
                headerData2={header2}
            
            />
            <ReportTable
                // headerData2={data6}
                verticalBodyData={resData}
            />
        </div>
    )
}


export default BOD
