import React from 'react'
import moment from "moment";


export const IndividualData = ({ individualExcelData }) => {
    return (
        <>
            <th>{individualExcelData.asset_id}</th>
            <th>{individualExcelData.asset_name}</th>
            <th>{individualExcelData.nature_of_asset}</th>
            <th>{individualExcelData.cost_center}</th>
            <th>{individualExcelData.supplier_name}</th>
            <th>{individualExcelData.location}</th>
            <th>{individualExcelData.invoice_no}</th>
            <th style={{ width: "5rem" }}>{moment(individualExcelData.acquisition_date).local().format("MMMM Do YYYY")}</th>
            <th>{moment(individualExcelData.installation_date).local().format("MMMM Do YYYY")}</th>
            <th>{individualExcelData?.invoice_value}</th>
            <th>{individualExcelData?.other_value}</th>
            <th>{individualExcelData?.acquisition_price}</th>
            <th>{individualExcelData.salvage_value}</th>
            <th>{individualExcelData.useful_life}</th>
            <th>{individualExcelData.quantity}</th>
            <th>{individualExcelData.user}</th>
            <th>{individualExcelData.remarks}</th>
        </>
    )
}

export const IndividualDataDep = ({ individualExcelData, index }) => {
    // console.log("individualExcelData", individualExcelData);
    return (
        <>
            <td style={{ padding: "0px 10px" }}>{index + 1}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData?.bu_code}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData?.gl_code}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.asset_id}</td>
            <td style={{ padding: "0px 10px", textAlign: "left" }}>{individualExcelData.asset_name}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.nature_of_asset}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.asset_class}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.asset_class_name}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.cost_center}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.cost_center_name}</td>
            <td style={{ padding: "5px 10px", }}>{individualExcelData.location}</td>
            <td style={{ padding: "5px 10px", }}>{individualExcelData.location_name}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.supplier_name || "-"}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.invoice_no || "-"}</td>
            <td style={{ width: "10rem", padding: "0px 10px" }}>{individualExcelData.acquisition_date?.slice(0, 10)}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.installation_date?.slice(0, 10)}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.invoice_value}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.other_value}</td>
            {/* <td style={{ padding: "0px 10px" }}>{individualExcelData.cost}</td> */}
            <td style={{ padding: "0px 10px" }}>{Number(individualExcelData.salvage_value)?.toFixed(2)}</td>
            {individualExcelData.rate && <td style={{ padding: "0px 10px" }}>{Number(individualExcelData.rate)}</td>}
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.useful_life}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.quantity}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.user || "-"}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.remark || "-"}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.total_cost}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.addition}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.deletion}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.gross_block}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{individualExcelData.open_dep}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{Number(individualExcelData.total_dep) < 0 ? 0 : individualExcelData.total_dep}</td>
            <td style={{ padding: "0px 10px", textAlign: "center" }}>{Number(individualExcelData.total_dep) < 0 ? 0 : individualExcelData.total_dep}</td>
            <td style={{ padding: "0px 10px" }}>{individualExcelData.net_block?.toFixed(2)}</td>

        </>
    )
}