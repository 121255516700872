import axios from "axios";
import { Button } from 'react-bootstrap';
import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as XLSX from 'xlsx';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCalendarDays,
    faAngleRight,
    faAngleLeft,
    faClose,
    faFilter,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faEye,
    faBook,
    faCheck,
    faX
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import Commonheader from "../../layouts/common/commonHeader";
import BackButton from "../../components/BackButton/BackButton";
import { faServices } from "../../services/service.index";
// import { FaCheck, FaQrcode } from "react-icons/fa";
// import { ImCross } from "react-icons/im";


const Capitalised = () => {
    const [list, setList] = useState();
    const [qrData, setQrData] = useState();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const dispatch = useDispatch()
    const list1 = useSelector((state) => state.FA.CapitalData)
    const isLoading = useSelector((state) => state.FA.isLoading);
    const qrRef = useRef()
    const [rearrangedData, setRearrangedData] = useState([]);
    const [getAllExcelData, setGetAllExcelData] = useState([]);
    const [isLoadingAllData, setIsLoading] = useState([false])
    // console.log(rearrangedData, 'rearrangedData');
    // const getData = async () => {
    //     dispatch({
    //         type: 'CAPITAL_GET_LIST'
    //     })
    //     // const { data } = await axios.get(`http://localhost:4010/capitalise`);
    //     // setList(data)

    // };
    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={qrData?._id}
            size={200}
            bgColor={"#fff"}
            level={"H"}
            style={{ marginLeft: "130px" }}
        />
    );

    const downloadQRCode = (e) => {
        e.preventDefault();
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = `qr-code.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

    };
    // console.log(isFilterOpen)
    const openFilterPopup = () => {
        setIsFilterOpen(!isFilterOpen);
        // console.log("Open filter popup")
    };
    const closeFilterPopup = () => {
        setIsFilterOpen(false);
    };

    const applyFilters = () => {
        // Add your filtering logic here
    };

    function generateExcelFile1() {
        // const worksheet = XLSX.utils.json_to_sheet(data);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // const downloadLink = document.createElement('a');
        // const url = URL.createObjectURL(blob);
        // downloadLink.href = url;
        // downloadLink.download = 'ZV_Capitalised_Report.xlsx';
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);

        // rearrangedData.map((date) => { date.installation_date = moment(date?.installation_date).format('DD-MM-YYYY') })

        // rearrangedData.map((date) => { date.acquisition_date = moment(date?.acquisition_date).format('DD-MM-YYYY') })

        // rearrangedData.map((date) => { date.depreciation_start_date = moment(date?.depreciation_start_date).format('DD-MM-YYYY') })

        // rearrangedData.map((date) => { date.last_depreciation_date = moment(date?.last_depreciation_date).format('DD-MM-YYYY') })

        const excelData = rearrangedData.map(date => ({
            ...date,
            installation_date: date.installation_date ? moment(date.installation_date).format('DD-MM-YYYY') : null,
            acquisition_date: date.acquisition_date ? moment(date.acquisition_date).format('DD-MM-YYYY') : null,
            depreciation_start_date: date.depreciation_start_date ? moment(date.depreciation_start_date).format('DD-MM-YYYY') : null,
            last_depreciation_date: date.last_depreciation_date ? moment(date.last_depreciation_date).format('DD-MM-YYYY') : null
        }));

        // rearrangedData.map((date) => { date.invoice_date = moment(date?.invoice_date).format('DD-MM-YYYY') })

        let customHeader = [[
            "BU Code",
            "Asset Group",
            "Asset ID",
            "Asset Name",
            "Make",
            "Model",
            "Serial Number",
            "Asset Type",
            "Book",
            "Asset Class Name",
            "Cost Center Code",
            "Cost Center Name",
            "Location Code",
            "Description",
            "Supplier Name",
            "Quantity",
            "Date of Purchase (dd-mm-yyyy)",
            "Purchase Value (INR)",
            "Date of Installation (dd-mm-yyyy)",
            "Cost (INR)",
            "UoM",
            "Salvage Value (INR)",
            "Department",
            "Depreciation Periods",
            "Depreciation Periods Remaining",
            "Depreciation Start Date (dd-mm-yyyy)",
            "Last Depreciation Date (dd-mm-yyyy)",
            "Method",
            "Property Type",
            "Useful Life",
            "Status",
            // ...data?.map(date => [`${moment(date?.doi).format('YYYY-MM-DD')}`]).flat()
        ]]

        excelData.map((obj, i) => (
            delete obj._id
        ))

        // const modifiedData = [customHeader, ...data];

        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();
        // console.log("worksheet", modifiedData);
        XLSX.utils.sheet_add_aoa(worksheet, customHeader);
        XLSX.utils.sheet_add_json(worksheet, excelData, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `ZV_Capitalised_${new Date().toDateString()}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }



    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    // Calculate the total number of pages
    let totalPages = 0;
    var data1List = [];
    if (list1?.result !== undefined) {
        data1List = list1?.result;
    }
    if (list1?.total_count !== undefined) {
        totalPages = Math.ceil(list1?.total_count / itemsPerPage);
    }
    // console.log('data1List', data1List);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    useEffect(() => {
        // console.log('call for API');
        if ((currentPage && currentPage > 0)) {
            dispatch({
                type: 'CAPITAL_GET_LIST',
                payload: [currentPage, itemsPerPage],
            })
        }
        dispatch({
            type: "GET_LOCATION_LIST"
        })
    }, [currentPage, itemsPerPage])
    // useEffect(() => {
    //     // getData()
    // }, [])

    const generateExcelFile = async () => {
        setIsLoading(true)
        // console.log('CURRENTPAGE', currentPage)
        await faServices.getCapitalData([1, list1?.total_count]).then(function (res) {
            // console.log("2135468", res.data.result);
            setGetAllExcelData(res.data.result)
            setIsLoading(false)
        })
            .catch(function (error) {
                // console.log(error.response);
            });
    };

    const rearrangeAttributes = (data) => {
        const desiredSequence = [
            "_id",
            "bu_code",
            "asset_group",
            'asset_id',
            'asset_name',
            'make',
            'model',
            'serial_number',
            'asset_type',
            'book_id',
            'asset_class_name',
            'cost_center',
            'cost_center_name',
            'location',
            'description',
            'supplier_name',
            'quantity',
            'acquisition_date',
            'acquisition_price',
            'installation_date',
            'unit_cost',
            'uom',
            'salvage_value',
            'department',
            'depreciation_periods',
            'depreciation_periods_remaining',
            'depreciation_start_date',
            'last_depreciation_date',
            'method',
            'property_type',
            'useful_life',
            'status'
        ];
        return data?.map(obj => {
            const reorderedObj = {};
            desiredSequence.forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    reorderedObj[key] = obj[key];
                } else {
                    reorderedObj[key] = null; // Set to null if property is missing
                }
            });
            return reorderedObj;
        });
    };

    useEffect(() => {
        // console.log(getAllExcelData, rearrangedData);
        if (getAllExcelData?.length > 0) {
            const rearranged = rearrangeAttributes(getAllExcelData);
            setRearrangedData(rearranged);
        }

    }, [getAllExcelData])

    useEffect(() => {
        if (rearrangedData?.length > 0) {
            // console.log(rearrangedData, 'rearrangedDatarearrangedData');
            generateExcelFile1()
        }
    }, [rearrangedData])

    useEffect(() => {
        if (isLoading) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [isLoading])

    return (
        <>
            {isLoadingAllData && <Loader />}
            <Commonheader />
            <div className="col-md-12">
                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        justifyContent: "center",
                    }}
                >
                    Capitalised Asset List
                    <div style={{
                        float: "right", display: "flex", alignItems: "center", gap: "1rem", position: "relative",
                    }}>

                        {/* <FontAwesomeIcon icon={faFilter} size="2xl" onClick={openFilterPopup} /> */}

                        {/* ................. SEARCH ................. */}
                        {/* <form>
                            <input
                                type="text"
                                placeholder="Search"

                                style={{
                                    border: "1px solid steelblue",
                                    fontSize: "15px",
                                    padding: "5px",
                                }}
                            />
                            <span
                                type="reset"

                                style={{ paddingLeft: "8px", marginRight: "25px" }}
                            >
                                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                            </span>
                        </form> */}

                        {/* {isFilterOpen && ( */}
                        {/* <div style={{
                            display: isFilterOpen ? "block" : "none",
                            position: "absolute",
                            top: "80%",
                            left: "-400px",
                            // transform: "translate(-50%, -10%)",
                            backgroundColor: "white",
                            padding: "20px",
                            boxShadow: "0 0 10px rgba(0,0,0,0.3)"
                        }}>
                            <form>
                              
                        <input type="text" placeholder="Filter 1" />
                        <input type="text" placeholder="Filter 2" />
                       
                        <button type="button" onClick={applyFilters}>
                            Apply
                        </button>
                    </form>
                    <button type="button" onClick={closeFilterPopup}>
                        Close
                    </button>
                </div> */}
                        {/* )} */}
                    </div>
                    <div>Capitalised Asset View </div>
                </div >
                <BackButton />
                <div style={{ overflow: 'auto', maxHeight: '575px' }}>
                    <table className="table-bordered" style={{ minWidth: '100%' }}>
                        <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#4682b4', color: 'white' }}>
                            {/* {data1List?.map((el, index) => ( */}
                            <tr style={{
                                textAlign: "center",
                                backgroundColor: "steelblue",
                                padding: "8px",
                                color: "#fff",
                            }}>
                                <th style={{ padding: "8px" }}>S. No</th>
                                <th scope="col"
                                    style={{ width: "auto" }}>BU Code</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Group</th>
                                <th scope="col"
                                    style={{ wordBreak: "break-word" }}>Asset ID</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "left" }}>Asset Name</th>
                                {/* <th scope="col"
                                    style={{ padding: "8px", textAlign: "left" }}>Make</th>
                                <th scope="col" style={{ padding: "8px", textAlign: "left" }}>Model</th> */}
                                <th scope="col" style={{ padding: "8px", textAlign: "left" }}>Serial Number</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Type</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Book</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Asset Class Name</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost Center Code</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost Center Name</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Location Code</th>
                                {/* <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Location Name</th> */}
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Description</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Supplier Name</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Quantity</th>
                                {/* <th scope="col"
                                style={{ padding: "8px", textAlign: "left", width: "auto" }}>Invoice No.</th> */}
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }} >Acquisition Date</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }} >Acquisition Price (INR)</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Date of Installation</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Cost (INR)</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>UoM</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Salvage Value (INR)</th>
                                {/* <th scope="col"
                                style={{ padding: "8px", textAlign: "left", width: "auto" }} >Field Verification</th> */}
                                {/* <th scope="col"
                                style={{ padding: "8px", textAlign: "left", width: "auto" }}>Field Verification</th> */}
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Department</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Depreciation Periods</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Depreciation Periods Remaining</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Depreciation Start Date</th>
                                <th scope="col"
                                    style={{ padding: "8px", textAlign: "center", width: "auto" }}>Last Depreciation Date</th>
                                <th scope="col" style={{ padding: "8px", textAlign: "center", width: "auto" }}>Method</th>
                                <th scope="col" style={{ padding: "8px", textAlign: "center", width: "auto" }}>Property Type</th>
                                <th scope="col" style={{ padding: "8px", textAlign: "center", width: "auto" }}>Useful Life</th>
                                <th scope="col" style={{ padding: "8px", textAlign: "center", width: "auto" }}>Status</th>
                            </tr>
                            {/* ))} */}
                        </thead>
                        <tbody>
                            {data1List?.map((el, index) =>
                            (<tr style={{ textAlign: "center", padding: "20px" }}>
                                <td style={{ padding: "8px" }} >{(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.bu_code}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.asset_class}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.asset_id}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.asset_name}</td>
                                {/* <td style={{ textAlign: "left", padding: "5px" }}>{el.make}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.model}</td> */}
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.serial_number}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.asset_type}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.book_id}</td>
                                <td style={{ textAlign: "left", padding: "5px" }} >{el.asset_class_name}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.cost_center}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.cost_center_name}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.location}</td>
                                {/* <td style={{ textAlign: "left", padding: "5px" }}>{el.location_name}</td> */}
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.remarks}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.supplier_name}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.quantity}</td>
                                {/* <td >{el.invoice_no}</td> */}
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.acquisition_date ? moment(el.acquisition_date).local().format('Do MMMM YYYY') : ""}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.invoice_value}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.installation_date ? moment(el.installation_date).local().format('Do MMMM YYYY') : ""}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.cost}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.uom}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.salvage_value}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.department}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.depreciation_periods}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.depreciation_periods_remaining}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.depreciation_start_date ? moment(el.depreciation_start_date).local().format('Do MMMM YYYY') : ""}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.last_depreciation_date ? moment(el.last_depreciation_date).local().format('Do MMMM YYYY') : ""}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.method}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.property_type}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.useful_life}</td>
                                <td style={{ textAlign: "left", padding: "5px" }}>{el.status}</td>
                                {/* <td >
                                <FaQrcode data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setQrData(el)} />
                            </td> */}
                                {/* < td >
                                {el.verified ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faX} />}
                            </td> */}
                            </tr>)
                            )}
                        </tbody>
                    </table >
                </div>
                <div>
                    {/* pagination */}
                    {data1List !== null && data1List.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                padding: "10px",
                            }}
                        >
                            <div className="mb-6">
                                <label>Items per page:</label> &nbsp;
                                <select
                                    onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                    style={{ borderRadius: "8px", cursor: "pointer" }}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div style={{ paddingRight: "25px" }} className="pagination">
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handleFirstPage}
                                >
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "#fff",
                                    }}
                                    disabled={currentPage === 1}
                                    onClick={handlePrev}
                                >
                                    {/* Prev */}
                                    {currentPage === 1 ? (
                                        <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                </button>
                                <span style={{ padding: "10px" }}>
                                    Showing {currentPage} of {totalPages} pages
                                </span>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        border: "none",
                                    }}
                                    disabled={currentPage === totalPages}
                                    onClick={handleNext}
                                >
                                    {currentPage === totalPages ? (
                                        <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                                </button>
                                <button
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        border: "none",
                                    }}
                                    disabled={currentPage === totalPages}
                                    onClick={handleLastpage}
                                >
                                    {currentPage === totalPages ? (
                                        <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                    ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">{qrData?.asset_name}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="qrcode__container" >
                                    <div ref={qrRef} >{qrcode}</div>
                                    <div style={{ textAlign: "center" }} >
                                        <form onSubmit={downloadQRCode} style={{ margin: "auto" }} >
                                            <button type="submit" class="btn btn-primary" disabled={!qrData?._id}>
                                                Download QR code
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    type="submit"

                    style={{
                        marginLeft: "2rem",
                        backgroundColor: "#69fae1",
                        color: "#000",
                        fontWeight: "bold",
                        marginBottom: '2rem'
                    }}
                    onClick={() => generateExcelFile()
                    }>
                    Download
                </Button>
            </div >

        </>
    );
};

export default Capitalised;
