import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'

function Fortnightly({ data, responseData, filterData, data10, data12,headingName, tableHeaders, department1, department2, make, medopharm, OperatingRange, Tolerance, docNumber, department, Date_Time_Zero,LeastCount ,rows,std,calibration}) {
    const [resData, setResData] = useState([])

    const data1 = {
        data: department,
        colSpan: '6'
    }
    const data2 = {
        data: `${docNumber}`
    }
    const data3 = {
        data: `FORTNIGHTLY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}  `,
        colSpan: '7'
    }

    const data4 = [
        { data: `Department`, colSpan: '2' },
        { data: `:`, colSpan: '1', textAlign: 'center' },
        { data: `${resData?.parent?.department !== undefined ? resData?.parent?.department : ""}`, colSpan: '2' },
        { data: `Area`, colSpan: '2', textAlign: 'left' },
        { data: `:`, colSpan: '1', textAlign: 'center' },
        { data: `${resData?.parent?.area !== undefined ? resData?.parent?.area : ''}`, colSpan: '6', textAlign: 'left' },
    ]
    const data5 = [
        { data: `Weighing capacity`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: `:`, colSpan: '1', rowSpan: "5", textAlign: 'center' },
        { data: `${resData?.parent?.rating_capacity !== undefined ? resData?.parent?.rating_capacity : ''} ${resData?.parent?.uom !== undefined ? resData?.parent?.uom : ''}`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: 'Least count', colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: ':', colSpan: '1', rowSpan: "5", textAlign: 'center' },
        { data: `${LeastCount}`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: `Balance code No.`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
        { data: ` ${resData?.parent?.parent_id !== undefined ? resData?.parent?.parent_id : ''}`, colSpan: '2', rowSpan: "5", textAlign: 'left' },
    ]
    const data6 = [
        { data: `Tolerance: ${Tolerance}`, colSpan: '8' },
        { data: `Operating Range: ${OperatingRange}`, colSpan: '7', textAlign: 'left' },
    ]
    

    const data14 = [
        { data: `Std Weight certificate No. ${std} & Calibration due:  ${calibration}`, colSpan: '15', textAlign: 'left' },
    ]


    useEffect(() => {
        setResData(responseData)
    }, [responseData])


    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
                medopharm={medopharm}
            />
            {department1?.length > 0 && <ReportTable
                headerData={department1}
            />}
            {department2?.length > 0 ? <ReportTable
                headerData={department2}
            /> : <ReportTable
                headerData={data4}
                headerData2={data5}
            />}

            <ReportTable
                headerData={data6}
            />
            <ReportTable
                headerData2={data14}
            />
            <ReportTable
            headerData={tableHeaders}
            />

            <ReportTable
                headerData2={data12}
            />
            <ReportTable
                verticalBodyData={rows}
            />
        </div>
    )
}

export default Fortnightly
