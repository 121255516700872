/* Feature - action list for access

Created by - Mona R
Updated by - Mona R */

// action to get all access details from API
const ACCESS_GET_LIST = 'ACCESS_GET_LIST';
const ACCESS_GET_SUCCESS = 'ACCESS_GET_SUCCESS';
const ACCESS_GET_FAILED = 'ACCESS_GET_FAILED';

// action to get all role list from API
const ROLE_GET_LIST = 'ROLE_GET_LIST';
const ROLE_GET_SUCCESS = 'ROLE_GET_SUCCESS';
const ROLE_GET_FAILED = 'ROLE_GET_FAILED';


export {
    ACCESS_GET_LIST,
    ACCESS_GET_SUCCESS,
    ACCESS_GET_FAILED,

    ROLE_GET_LIST,
    ROLE_GET_SUCCESS,
    ROLE_GET_FAILED
}