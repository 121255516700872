/* Feature - Form to upload/onboard Asset

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useEffect, useState, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ASSET_ADD } from "../../redux/actions/assetAction";
import Loader from "../../components/loader/loader";

function AssetUpload() {
  const { id } = useParams();
  const navigate = useNavigate();

  // To fetch details from redux
  const dispatch = useDispatch();
  const isSubmitted = useSelector((state) => state.asset.isSubmitted);
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.asset.isLoading);

  // state to update the form data
  const [data, setData] = useState({
    site_id: id,
    assetID: "",
    assetname: "",
    make: "",
    model: "",
    serial: "",
    rating: "",
    unitofmeasurement: "",
    codeused: "",
    manufactureYear: "",
    installationYear: "",
    life: "",
    criticality: "",
    area: "",
    location: "",
    building: "",
    blocktower: "",
    floor: "",
    wing: "",
    dept: "",
    accessbilty: "",
    assetimg: "",
    remark: "",
  });

  //Functions to handle form inputs
  const handleAssetID = (val) => {
    setData({
      ...data,
      assetID: val,
    });
  };
  const handleAssetname = (val) => {
    setData({
      ...data,
      assetname: val,
    });
  };
  const handleMake = (val) => {
    setData({
      ...data,
      make: val,
    });
  };
  const handleModel = (val) => {
    setData({
      ...data,
      model: val,
    });
  };
  const handleSerialnumber = (val) => {
    setData({
      ...data,
      serial: val,
    });
  };
  const handlerating = (val) => {
    setData({
      ...data,
      rating: val,
    });
  };
  const handleUom = (val) => {
    setData({
      ...data,
      unitofmeasurement: val,
    });
  };
  const handleCodeused = (val) => {
    setData({
      ...data,
      codeused: val,
    });
  };
  const handleYom = (val) => {
    setData({
      ...data,
      manufactureYear: val,
    });
  };
  const handleYoi = (val) => {
    setData({
      ...data,
      installationYear: val,
    });
  };
  const handleExpLife = (val) => {
    setData({
      ...data,
      life: val,
    });
  };
  const handleCriticality = (val) => {
    setData({
      ...data,
      criticality: val,
    });
  };
  const handleArea = (val) => {
    setData({
      ...data,
      area: val,
    });
  };
  const handleLocation = (val) => {
    setData({
      ...data,
      location: val,
    });
  };
  const handleBuilding = (val) => {
    setData({
      ...data,
      building: val,
    });
  };
  const handleBlocktower = (val) => {
    setData({
      ...data,
      blocktower: val,
    });
  };
  const handleFloor = (val) => {
    setData({
      ...data,
      floor: val,
    });
  };
  const handleWing = (val) => {
    setData({
      ...data,
      wing: val,
    });
  };
  const handleDept = (val) => {
    setData({
      ...data,
      dept: val,
    });
  };
  const handleAcesibility = (val) => {
    setData({
      ...data,
      accessbilty: val,
    });
  };
  const handleAssetImg = (input) => {
    // console.log(input);
    let file = input.target.files[0];
    // console.log(file);
    // console.log('fileread', file instanceof Blob);
    if (file && !file.type.match(/(jpeg|png|jpg)/)) {
      alert('Only JPEG, PNG, JPG files are allowed');
      input.target.value = '';
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64String = reader.result;
      // console.log('Base64 String - ', base64String);
      // Simply Print the Base64 Encoded String,
      // without additional data: Attributes.
      // console.log('Base64 String without Tags- ',
      //   base64String.substr(base64String.indexOf(', ') + 1));
      setData({
        ...data,
        assetimg: base64String,
      });
    };
  };
  const handleRemark = (val) => {
    setData({
      ...data,
      remark: val,
    });
  };

  //client-side validation
  const [assetidErr, setAssetidErr] = useState("");
  const [assetnameErr, setAssetnameErr] = useState("");
  const [makeErr, setMakeErr] = useState("");
  const [modelErr, setModelErr] = useState("");
  // const [srlErr, setSrlErr] = useState("")
  const [rcErr, setRcErr] = useState("");
  const [uomErr, setUomErr] = useState("");
  // const [codeusedErr, setCodeusedErr] = useState("")
  // const [yomErr, setYomErr] = useState("")
  // const [yoiErr, setYoiErr] = useState("")
  // const [lifeErr, setlifeErr] = useState("")
  const [criticErr, setCriticErr] = useState("");
  const [areaErr, setAreaErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [deptErr, setDeptErr] = useState("");

  let isValid = true;

  // function for client side validation.
  const formValidation = () => {
    const assetidErr = {};
    const assetnameErr = {};
    const makeErr = {};
    const modelErr = {};
    // const srlErr = {};
    const rcErr = {};
    const uomErr = {};
    // const codeusedErr = {};
    // const yomErr = {};
    // const yoiErr = {};
    // const lifeErr = {};
    const criticErr = {};
    const areaErr = {};
    const locationErr = {};
    const deptErr = {};

    if (data.assetID.trim().length < 1) {
      assetidErr.assetidShort = "Please Enter Asset ID";
      isValid = false;
    }
    setAssetidErr(assetidErr);
    if (data.assetname.trim().length < 1) {
      assetnameErr.assetidShort = "Please Enter Asset name";
      isValid = false;
    }
    setAssetnameErr(assetnameErr);
    if (data.make.trim().length < 1) {
      makeErr.assetidShort = "Please Enter Make";
      isValid = false;
    }
    setMakeErr(makeErr);
    if (data.model.trim().length < 1) {
      modelErr.assetidShort = "Please Enter Model";
      isValid = false;
    }
    setModelErr(modelErr);

    if (data.rating.trim().length < 1) {
      rcErr.assetidShort = "Please Enter Rating";
      isValid = false;
    }
    setRcErr(rcErr);
    if (data.unitofmeasurement.trim().length < 1) {
      uomErr.assetidShort = "Please Enter UOM";
      isValid = false;
    }
    setUomErr(uomErr);

    if (data.criticality.trim().length < 1) {
      criticErr.critiShort = " Choose Criticality";
      isValid = false;
    }
    setCriticErr(criticErr);
    if (data.area.trim().length < 1) {
      areaErr.assetidShort = "Please Enter Area";
      isValid = false;
    }
    setAreaErr(areaErr);
    if (data.location.trim().length < 1) {
      locationErr.locationShort = "Please Enter Location";
      isValid = false;
    }
    setLocationErr(locationErr);
    if (data.dept.trim().length < 1) {
      deptErr.assetidShort = "Please Enter Department";
      isValid = false;
    }
    setDeptErr(deptErr);

    return isValid;
  };

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  // Function to upload asset details
  const submitAssetdetails = (e) => {
    e.preventDefault();
    // console.log(data.assetimg);
    const formData = new FormData();
    formData.append("data.assetimg", data.assetimg);
    var options = { content: formData };
    // console.log(options);
    let assetData = {
      site_id: data.site_id,
      asset_id: data.assetID,
      asset_name: data.assetname,
      make: data.make,
      model: data.model,
      serial_number: data.serial,
      rating_capacity: data.rating,
      uom: data.unitofmeasurement,
      // code_used: data.codeused,
      year_of_manufacture: data.manufactureYear,
      year_of_installation: data.installationYear,
      expected_life_in_month: data.life,
      criticality: data.criticality,
      area: data.area,
      location: data.location,
      building: data.building,
      block_tower: data.blocktower,
      floor: data.floor,
      wing: data.wing,
      department: data.dept,
      accessibility: data.accessbilty,
      asset_image: data.assetimg,
      remarks: data.remark,
      created_by: userid,
    };
    validation();
    // console.log(isValid);
    if (isValid) {
      dispatch({
        type: ASSET_ADD,
        payload: assetData,
      });
    }
  };

  // useEffect
  useEffect(() => {
    if (isSubmitted) {
      navigate("/assetList");
    }
  }, [isSubmitted]);

  //functions for validation
  function idChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/@]+\s)*[a-zA-Z0-9-/@]+$/;
    return idspecialChars.test(str);
  }
  function snumChars(str) {
    const categoryChars = /^([0-9]+\s)*[0-9]+$/;
    return categoryChars.test(str);
  }
  function rncChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/&]+\s)*[a-zA-Z0-9-/&]+$/;
    return idspecialChars.test(str);
  }
  function areaChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/@|#]+\s)*[a-zA-Z0-9-/@|#]+$/;
    return idspecialChars.test(str);
  }
  function deptChars(str) {
    const idspecialChars = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    return idspecialChars.test(str);
  }
  function uomChars(str) {
    const idspecialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return idspecialChars.test(str);
  }

  function yearChars(str) {
    const categoryChars = /^[1-9][0-9]{0,4}$/;
    return categoryChars.test(str);
  }

  return (
    <div>
      {isLoading && <Loader />} {/* to show Loading effect */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3" style={{ paddingTop: "45px" }}>
            <div className="m-2">
              <form
                action=""
                className="mt-5 border p-4 bg-light shadow rounded"
              >
                <div className="formHeadings mb-2">Create Asset</div>
                <div className="row">
                  <div className="mb-2 col-md-4">
                    <label>
                      Asset ID
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="assetID"
                      maxLength="200"
                      className="form-control"
                      value={data.assetID}
                      onChange={(e) => handleAssetID(e.target.value)}
                      required
                    />
                    {Object.keys(assetidErr).map((key) => {
                      if (!data.assetID) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {assetidErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.assetID) || data.assetID === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Asset ID
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Asset Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="assetname"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleAssetname(e.target.value)}
                      required
                    />
                    {Object.keys(assetnameErr).map((key) => {
                      if (!data.assetname) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {assetnameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.assetname) || data.assetname === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Asset name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Make
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="make"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => handleMake(e.target.value)}
                      required
                    />
                    {Object.keys(makeErr).map((key) => {
                      if (!data.make) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {makeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.make) || data.make === "" ? (
                      " "
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          paddingTop: "2px",
                        }}
                      >
                        Invalid Make data
                      </div>
                    )}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Model
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="model"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleModel(e.target.value)}
                      required
                    />
                    {Object.keys(modelErr).map((key) => {
                      if (!data.model) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {modelErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.model) || data.model === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid model
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Serial Number
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <input
                      type="text"
                      name="serial"
                      maxLength="100"
                      className="form-control"
                      onChange={(e) => handleSerialnumber(e.target.value)}
                      required
                    />
                    {/* {Object.keys(srlErr).map((key) => {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px", paddingTop:"2px" }}
                          >
                            {srlErr[key]}
                          </div>
                        );
                      })} */}
                    {snumChars(data.serial) || data.serial === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Serial number
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Rating/Capacity
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="rating"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handlerating(e.target.value)}
                      required
                    />
                    {Object.keys(rcErr).map((key) => {
                      if (!data.rating) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {rcErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {rncChars(data.rating) || data.rating === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Data
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      UoM of Rating/Capacity
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="unitofmeasurement"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => handleUom(e.target.value)}
                      required
                    />
                    {Object.keys(uomErr).map((key) => {
                      if (!data.unitofmeasurement) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {uomErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {uomChars(data.unitofmeasurement) ||
                    data.unitofmeasurement === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid UOM
                          </div>
                        ))}
                  </div>

                  <div className="mb-2 col-md-4">
                    <label>Year of Manufacture</label>
                    <input
                      type="text"
                      maxLength="4"
                      name="manufactureYear"
                      className="form-control"
                      onChange={(e) => handleYom(e.target.value)}
                      required
                    />

                    {yearChars(data.manufactureYear) ||
                    data.manufactureYear === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Manufacture Year
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Year of Installation</label>
                    <input
                      type="text"
                      maxLength="4"
                      name="installationYear"
                      className="form-control"
                      onChange={(e) => handleYoi(e.target.value)}
                    />

                    {yearChars(data.installationYear) ||
                    data.installationYear === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Installation Year
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Expected Life in Years</label>
                    <input
                      type="text"
                      name="life"
                      maxLength="4"
                      className="form-control"
                      onChange={(e) => handleExpLife(e.target.value)}
                      required
                    />

                    {yearChars(data.life) || data.life === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Manufacture Year
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Criticality<span className="text-danger">*</span>
                    </label>
                    <select
                      name="criticality"
                      id="criticality"
                      placeholder="Select"
                      value={data.criticality}
                      className="form-select"
                      onChange={(e) => {
                        handleCriticality(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    {Object.keys(criticErr).map((key) => {
                      if (!data.criticality) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {criticErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Area
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="area"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleArea(e.target.value)}
                      required
                    />
                    {Object.keys(areaErr).map((key) => {
                      if (!data.area) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {areaErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {areaChars(data.area) || data.area === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Area
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="location"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleLocation(e.target.value)}
                    />
                    {Object.keys(locationErr).map((key) => {
                      if (!data.location) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {locationErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {idChars(data.location) || data.location === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Location
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Building</label>
                    <input
                      type="text"
                      name="building"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleBuilding(e.target.value)}
                    />
                    {idChars(data.building) || data.building === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Building
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Block/Tower</label>
                    <input
                      type="text"
                      name="blocktower"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleBlocktower(e.target.value)}
                    />
                    {idChars(data.blocktower) || data.blocktower === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Tower
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Floor</label>
                    <input
                      type="text"
                      name="floor"
                      maxLength="200"
                      className="form-control"
                      onChange={(e) => handleFloor(e.target.value)}
                    />
                    {idChars(data.floor) || data.floor === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Floor
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Wing</label>
                    <input
                      type="text"
                      name="wing"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => handleWing(e.target.value)}
                    />
                    {idChars(data.wing) || data.wing === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Wing
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>
                      Department
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="dept"
                      className="form-control"
                      maxLength="200"
                      onChange={(e) => handleDept(e.target.value)}
                      required
                    />
                    {Object.keys(deptErr).map((key) => {
                      if (!data.dept) {
                        return (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingTop: "2px",
                            }}
                          >
                            {deptErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {deptChars(data.dept) || data.dept === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Department
                          </div>
                        ))}
                  </div>

                  <div className="mb-2 col-md-4">
                    <label>Netwok Accessibility</label>
                    <select
                      name="accessbilty"
                      className="form-select"
                      value={data.accessbilty}
                      onChange={(e) => {
                        handleAcesibility(e.target.value);
                      }}
                    >
                      <option value="" disabled>Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div className="mb-2 col-md-8">
                    <label>Asset Image</label>
                    <input
                      className="form-control"
                      name="assetimg"
                      type="file"
                      id="formFile"
                      onChange={handleAssetImg}
                      accept=".jpeg,.jpg,.png"
                    ></input>
                  </div>
                  <div className="mb-2 col-md-4">
                    <label>Remarks</label>
                    <input
                      type="text"
                      name="remark"
                      className="form-control"
                      onChange={(e) => handleRemark(e.target.value)}
                    />
                  </div>

                  <div className="mt-4 col-md-12">
                    <button
                      type="submit"
                      className="float-end saveButton"
                      onClick={(e) => submitAssetdetails(e)}
                    >
                      Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      onClick={() => navigate("/select/asset")}
                      className="backButton"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssetUpload;
