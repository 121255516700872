/* Feature - Attachments upload

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Selectasset from "../asset/selectAsset";
import { CONSTANTS } from "../../constants/URLs/urlConstants";
import { custService } from "../../services/service.index";
import { siteService } from "../../services/service.index";
import { Link } from "react-router-dom";
import { faC } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faClose, faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

function AttachmentsDoc(props) {
    // To fetch details from redux
    // const siteData = useSelector(state => state.site.siteData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userid = useSelector((state) => state.auth.userid);


    const [selectedOption, setSelectedOption] = useState('SOP');
    const [customOption, setCustomOption] = useState('');
    const [options, setOptions] = useState(['SOP']);
    const [data, setData] = useState({
        name: '',
        type: '',
        // purpose: '',
        is_latest_version: true,
        // remarks: "",
        created_by: userid,
        sop: '',
        oem_manual: '',
    })
    const [disabled, setDisabled] = useState(true)

   const handledocName = (input) => {
    setData({
        ...data,
        name: input
    })
   }

    const handleType = (input) => {
        setData({
            ...data,
            type: input
        })

    }

    const handleActivity = (input) => {
        setData({
            ...data,
            type: input
        })
    }

    const handleDoc = (input) => {
        // console.log(input);
        let file = input.target.files[0];
        // console.log(file);
        // console.log('fileread', file instanceof Blob);
        if (file && !file.type.match(/(jpeg|png|jpg|pdf)/)) {
            alert('Only JPEG, PNG, JPG, PDF files are allowed');
            input.target.value = '';
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64String = reader.result;
            // console.log('Base64 String - ', base64String);
            // Simply Print the Base64 Encoded String,
            // without additional data: Attributes.
            // console.log('Base64 String without Tags- ',
            //   base64String.substr(base64String.indexOf(', ') + 1));
            setData({
                ...data,
                sop: base64String,
            });
        };
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        if (value === 'Others') {
            setCustomOption('');
        }
    };

    const handleCustomOptionChange = (event) => {
        setCustomOption(event.target.value);
    };

    const handleAddCustomOption = () => {
        if (customOption && !options.includes(customOption)) {
            setOptions([...options, customOption]);
            setSelectedOption(customOption);
        }
    };

    const handleSubmit = () => {
        // console.log(data);
        // console.log(props.id);
        let sop ={
            sop: [data]
        }    
        dispatch({
            type: 'SOP_UPLOAD',
            payload: [props.id, props.type, sop],
          });
    }

    useEffect(() => {
        if (data?.name?.length > 0 && data?.type?.length > 0 && data?.sop?.length > 0) {
            setDisabled(false)
        }
    },[data])

    return (
        <div>
            {/* {isLoading && <Loader />} */}
            {/* Modal for showing to upload documents */}
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Upload Documents
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2 col-md-10">
                        <label>
                            Document Name<span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            name="area"
                            maxLength="200"
                            className="form-control"
                            onChange={(e) => handledocName(e.target.value)}
                            required
                        />
                    </div>
                    <br />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '2px'
                    }}>
                        <div className="mb-2 col-md-5">
                            <label>
                                Select Document Type<span className="text-danger">*</span>
                            </label>
                            <select
                                name="docType"
                                id="docType"
                                placeholder="Select"
                                // value={data.type}
                                value={selectedOption}
                                className="form-select"
                                onChange={handleOptionChange}
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                {options.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                                {/* <option value="sop">SOP</option> */}
                                {/* <option value="oem_manual">OEM Manual</option> */}
                                {/* <option value="user_manual">User Manual</option> */}
                                <option value="Others">Others</option>
                            </select>
                            <br />
                            {selectedOption === 'Others' && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter custom option"
                                        value={customOption}
                                        onChange={handleCustomOptionChange}
                                    /> &nbsp;
                                    <button type="button" className="delIcons" onClick={handleAddCustomOption}>
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faPlus}
                                        /></button>
                                </div>
                            )}
                        </div>
                        <div className="mb-2 col-md-5">
                            <label>
                                Applicable for activity?<span className="text-danger">*</span>
                            </label>
                            <select
                                name="docType"
                                id="docType"
                                placeholder="Select"
                                value={data.type}
                                className="form-select"
                                onChange={(e) => {
                                    handleActivity(e.target.value);
                                }}
                            >
                                <option value='' disabled>
                                    Select
                                </option>
                                <option value="inspection">Inspection</option>
                                <option value="maintenance">Maintenance</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div>
                        <input
                            type="file"
                            onChange={handleDoc}
                            accept=".jpeg,.jpg,.png,.pdf"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='button' onClick={handleSubmit} disabled={disabled}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AttachmentsDoc;
