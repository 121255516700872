import { ResponsiveBar } from '@nivo/bar'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountingStat from '../../components/FA-Components/AccountingStat';

const StatusChartFA = () => {
    const dispatch = useDispatch()
    const capitalAssetlist = useSelector((state) => state.FA.CapitalData)
    // console.log("capitalAssetlist", capitalAssetlist?.result)
    let data1 = useSelector((state) => state.FA.CWIPData)
    const [filterName, setFilterName] = useState("Cost Center")

    // let FY = 2023
    const getCapitalAssetAge = (capitalAssetlist, FY, type) => {
        // console.log(FY)
        // console.log(new Date(capitalAssetlist[0].doi).getFullYear())
        if (type == "age") {
            setFilterName("Age")
            var newResult = capitalAssetlist?.map((el) => (new Date(el.doi).getFullYear() - FY) == 0 ? 1 : Math.abs(new Date(el.doi).getFullYear() - FY))
        } else if (type == "asset_class_name") {
            setFilterName("Class")
            var newResult = capitalAssetlist?.map((el) => el.asset_class_name)
        } else {
            var newResult = capitalAssetlist?.map((el) => el.cost_center_name)
        }
        // console.log(newResult)
        // Create an empty object to store the counts
        const countObject = {};

        // Count the occurrences of each number in the input array
        newResult?.forEach((num) => {
            countObject[num] = (countObject[num] || 0) + 1;
        });

        // Convert the count object into an array of objects
        const resultArray = Object.keys(countObject)?.map((key) => ({
            key: parseInt(key) || key,  //.length > 15 ? key.slice(0, 15) : key,
            value: countObject[key],
        })).sort((a, b) => b.key - a.key)

        // console.log(resultArray, "resultArray")
        setCapitalData(resultArray)
    }
    const getCwipAssetAge = (capitalAssetlist, FY, type) => {
        // console.log(FY)
        // console.log(new Date(capitalAssetlist[0].doi).getFullYear())
        // if (type == "age") {
        var newResult = capitalAssetlist?.map((el) => (new Date(el.doi).getFullYear() - FY) == 0 ? 1 : Math.abs(new Date(el.doi).getFullYear() - FY))
        // } else if (type == "asset_class_name") {
        //     var newResult = capitalAssetlist?.map((el) => el.asset_class_name)
        // } else {
        //     var newResult = capitalAssetlist?.map((el) => el.cost_center_name)

        // }
        // console.log(newResult)
        // Create an empty object to store the counts
        const countObject = {};

        // Count the occurrences of each number in the input array
        newResult?.forEach((num) => {
            countObject[num] = (countObject[num] || 0) + 1;
        });

        // Convert the count object into an array of objects
        const resultArray = Object.keys(countObject)?.map((key) => ({
            key: parseInt(key) || key,  //.length > 15 ? key.slice(0, 15) : key,
            value: countObject[key],
        })).sort((a, b) => b.key - a.key)

        // console.log(resultArray, "qqresultArray")
        setCwipData(resultArray)
    }
    const yearsArray = Array.from({ length: 90 }, (_, index) => 2010 + index);
    const [capitalData, setCapitalData] = useState()
    const [cwipData, setCwipData] = useState()
    // console.log("capitalData", capitalData)
    // 
    let resultArray
    useEffect(() => {
        dispatch({
            type: 'CAPITAL_GET_LIST',
            payload: [1, 1000],
        })
        dispatch({
            type: 'CWIP_GET_LIST',
            payload: [1, 1000],
        })
    }, [])

    // useEffect(() => {
    //     getCapitalAssetAge(capitalAssetlist?.result, new Date().getFullYear())
    //     // setCapitalData(capitalAssetlist?.result)
    // }, [capitalAssetlist?.result])
    useEffect(() => {
        getCapitalAssetAge(capitalAssetlist?.result, new Date().getFullYear())
        // setCapitalData(capitalAssetlist?.result)
        getCwipAssetAge(data1?.result, new Date().getFullYear())
    }, [capitalAssetlist?.result, data1?.result])
    // console.log("CWIP", data1)
    // if (!resultArray) {
    //     resultArray = getCapitalAssetAge(capitalAssetlist, 2023)
    // }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: '4rem' }}>
                {/* ..................... CHART 1 ................ */}
                <div style={{ paddingLeft: "3rem", height: "500px", width: "40%", paddingTop: "5rem", marginTop: '1rem', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', }}>

                    <div>
                        <p className='graphname'
                            style={{
                                padding: '0 0.5rem'
                            }}
                        >Count of Asset by {filterName} </p>
                        {/* Count of asset by  */}
                    </div>
                    <div

                    >
                        {/* <select

                    onChange={(e) => getCapitalAssetAge(capitalAssetlist?.result, e.target.value, "age")}
                >
                    <option value="">{new Date().getFullYear()}</option>
                    {
                        yearsArray?.map((el) => <option value={el}>{el}</option>)
                    }
                </select> */}
                        <select
                            style={{ border: "none" }}
                            onChange={(e) => getCapitalAssetAge(capitalAssetlist?.result, 2023, e.target.value)}>
                            <option value="cost_center_name">Cost Center</option>
                            <option value="asset_class_name">Asset class</option>
                            <option value="age">Asset Age</option>
                        </select>
                    </div>
                    {capitalData &&

                        <ResponsiveBar
                            data={capitalData}
                            keys={["value"]}
                            indexBy="key"
                            margin={{ top: 50, right: 130, bottom: 120, left: 60 }}
                            padding={0.6}
                            valueScale={{ type: "linear" }}
                            colors="#3182CE"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            maxValue={capitalData?.reduce((max, item) => (item.value > max ? item.value : max), 0) + capitalData?.reduce((max, item) => (item.value > max ? item.value : max), 0) * 20 / 100}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 15,
                                legend: `${filterName}`,
                                legendPosition: 'middle',
                                legendOffset: 50
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Number of Assets",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                        />
                    }
                </div>
                {/* ..................... CHART 2 ................ */}

                <div style={{ paddingRight: "3rem", height: "500px", width: "40%", paddingTop: "5rem", marginTop: '1rem', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', }}>
                    <div>
                        <p className='graphname'
                            style={{
                                padding: '0 0.5rem'
                            }}
                        >Count of CWIP Asset by Age</p>
                    </div>
                    <div >
                        {/* <select

                    onChange={(e) => getCapitalAssetAge(capitalAssetlist?.result, e.target.value, "age")}
                >
                    <option value="">{new Date().getFullYear()}</option>
                    {
                        yearsArray?.map((el) => <option value={el}>{el}</option>)
                    }
                </select> */}
                        <select
                            style={{ border: "none" }}
                            onChange={(e) => getCwipAssetAge(data1?.result, 2023, e.target.value)}>
                            <option value="asset_class_name">CWIP Assets Age</option>
                            {/* <option value="age">Asset Age</option>
                            <option value="cost_center_name">Cost Center</option> */}
                        </select>
                    </div>
                    {cwipData?.length > 0 ?
                        <ResponsiveBar
                            data={cwipData}
                            keys={["value"]}
                            indexBy="key"
                            margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
                            padding={0.6}
                            valueScale={{ type: "linear" }}
                            colors="rgba(100, 255, 226, 1)"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            maxValue={cwipData?.reduce((max, item) => (item.value > max ? item.value : max), 0) + cwipData?.reduce((max, item) => (item.value > max ? item.value : max), 0) * 20 / 100}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 15,
                                legend: 'Age of Assets',
                                legendPosition: 'middle',
                                legendOffset: 50
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Number of Assets",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                        /> : <div style={{ marginLeft: "50%" }}>No data</div>}
                </div>
            </div>
            {/* <div style={{
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                marginLeft: '5px',
                marginRight: '5px',
                borderRadius: '5px',
                backgroundColor: 'rgba(105, 250, 225, 1)'
            }}>
              
        </div> * /}
            {/* <AccountingStat capitalData={capitalAssetlist?.result} /> */ }
        </div >
    )
}

export default StatusChartFA