import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import AWS from "aws-sdk";
// Import your local PDF file
// Configure pdfjs worker path (needed for worker)
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function PdfDocView(props) {
    console.log(props);
    const [name, setName] = useState(null)
    const [link, setLink] = useState(null)
    const [pdfUrl, setPdfUrl] = useState('');
    const [loading, setLoading] = useState(true);
    // console.log('pdfUrl', pdfUrl);

    const handleSubmit = () => {
        // console.log('clicked');
        props?.assigned(props.selectedactivity)
    }

    useEffect(() => {
        if (props?.viewlink) {
            const arrayFromString = props?.viewlink.split(',');
            // console.log(arrayFromString);
            setName(arrayFromString[1])
            setLink(arrayFromString[0])
        }
    }, [props])

    useEffect(() => {
        // Configure AWS SDK with your credentials and S3 bucket region
        if (link !== null) {
            AWS.config.update({
                accessKeyId: process.env.REACT_APP_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_SECRET_KEY,
            });

            const s3 = new AWS.S3();

            let bucketURL = process.env.REACT_APP_BUCKET_URL;
            // console.log('bucketURL', bucketURL);
            // console.log('link', link);
            let DocKey = link?.replace(bucketURL, "")
            // console.log('DocKey', DocKey);

            // Replace 'YOUR_BUCKET_NAME' with your S3 bucket name
            const params = {
                Bucket: process.env.REACT_APP_BUCKET_NAME,
                Key: DocKey, // The key of the PDF file in your bucket
            };

            s3.getObject(params, (err, data) => {
                if (err) {
                    // console.error('Error fetching PDF from S3:', err);
                    setLoading(false);
                } else {
                    // console.log('data.Body', data.Body);
                    const pdfData = data.Body;
                    const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    setPdfUrl(pdfUrl);
                    setLoading(false);
                }
            });
        }
    }, [link]);

    return (
        <div>
            {pdfUrl &&
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            SOP - {name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: '70vh', overflowY: 'auto' }}>
                        {/* {console.log(pdfUrl)} */}
                        <Document
                            file={pdfUrl} // Pass the local PDF file
                            // onLoadSuccess={console.log}
                        >
                            <Page pageNumber={1} /> {/* Display the first page */}
                        </Document>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="backButton m-2"
                            onClick={handleSubmit}
                        >
                            Assign
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )
}

export default PdfDocView
