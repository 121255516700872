import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { DataDep } from '../../components/FA-Components/DataExcel';
import Commonheader from '../../layouts/common/commonHeader';
import { useDispatch, useSelector } from 'react-redux';
import './switch.css'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Loader from '../../components/loader/loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCalendarDays,
    faAngleRight,
    faAngleLeft,
    faClose,
    faFilter,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faEye,
    faBook,
    faCheck,
    faX
} from "@fortawesome/free-solid-svg-icons";
import BackButton from '../../components/BackButton/BackButton';

const Depreciation = () => {


    const [excelData, setExcelData] = useState(null);
    const [list, setList] = useState();
    const [showTables, setShowTables] = useState(false)
    const [fy, setFy] = useState()
    const [method, setMethod] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const list1 = useSelector((state) => state.FA.calculation)
    const [modalShow1, setModalShow1] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [locationselect, setLocationSelect] = useState("all")
    const isLoading = useSelector((state) => state.FA.isLoading);
    const [costCenter, setCostCenter] = useState("all")
    const [buCode, setBuCode] = useState("all")
    const assetclass = useSelector((state) => state.FA.assetClass)
    const location = useSelector((state) => state.FA.location)
    const center = useSelector((state) => state.FA.costCenter)
    const buCode1 = useSelector((state) => state.FA.buCode)

    // console.log('buCode1', buCode1);

    const [isNew, setIsNew] = useState("all")
    const yearOptions = [];
    for (let year = 2010; year <= 2050; year++) {
        yearOptions.push({ value: year - 1, lable: `${year - 1}-${year.toString().slice(2, 4)}` });
    }

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    const handleToggle1 = () => {
        setIsChecked1(!isChecked1);
    };
    // console.log("list1", list1)


    const handleYear = (e) => {
        // console.log(e.target.value)
        setIsNew(e.target.value)
        if (e.target.value == "prev") {
            setFy(new Date().getFullYear() - 1)
        } else {
            setFy(new Date().getFullYear())
        }
    }
    const getData = async () => {

        if (window.confirm(`Depreciation Method: ${method} 
Financial year: ${Number(fy) + 1}
                `)) {

            if (!fy || !method) {
                alert("Please select year and Method")
            } else {
                if (method == 'SLM') {
                    // var { data } = await axios.get(`http://localhost:8000/calculation?fy=${fy}`);

                    dispatch({
                        type: "GET_CALCULATION",
                        payload: [fy, "SLM", locationselect, buCode, costCenter]
                    })

                } else {
                    // var { data } = await axios.get(`http://localhost:8000/calculation-wdv?fy=${fy}`);
                    dispatch({
                        type: "GET_CALCULATION",
                        payload: [fy, "WDV", locationselect, buCode, costCenter]
                    })
                }
                // setList(data)
                // setExcelData(data)
                // console.log(data)
                setShowTables(true)
            }
        }

    };
    const handleClose = () => {
        alert("Balance has been successfully updated")
        navigate("/home")
    }


    const handleMethod = (e) => {
        // console.log("mathod", e.target.value)
        setMethod(e.target.value)
    }

    let numberOfAssets = list1?.length || 0
    let total_cost = 0;
    let totalNetBlock = 0
    for (let i = 0; i < list1?.length; i++) {
        total_cost += list1[i]?.total_cost
        totalNetBlock += list1[i]?.net_block
    }
    // console.log(total_cost)




    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    // Calculate the total number of pages
    let totalPages = 0;
    var data1List = [];
    if (list1?.result !== undefined) {
        data1List = list1?.result;
    }
    if (list1?.total_count !== undefined) {
        totalPages = Math.ceil(list1?.total_count / itemsPerPage);
    }
    // console.log('data1List', data1List);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };
    useEffect(() => {
        dispatch({
            type: "GET_CLASS_LIST",
            payload: [1, 50, ''],
        })
        dispatch({
            type: "GET_LOCATION_LIST",
            payload: [1, 50, ''],
        })
        dispatch({
            type: "GET_COST_CENTER_LIST",
            payload: [1, 50, '']
        })
        dispatch({
            type: "GET_BU_CODE_LIST",
        })
    }, [currentPage, itemsPerPage])
    return (
        <>

            {/* <div className="home" style={{ marginTop: "5rem" }}> */}
            <Commonheader />
            <div >
                {/* class="container bg-white" */}
                <div class="container bg-white" style={{ display: "flex", alignItems: "center", paddingTop: "5rem" }}>
                    <p class="fs-4 fw-semibold">Depreciation Run</p>
                </div>
                <BackButton />

                <div class="container bg-white" >
                    {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", marginBottom: "0.5rem" }}>
                        <label for="assetId" class="form-label bg-white">Depreciation Book Code :</label>
                        <Form.Select style={{ width: "50%", border: "0.5px solid", borderRadius: "0" }} size="sm">
                            <option>Small select</option>
                        </Form.Select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", paddingRight: "2rem", marginBottom: "0.5rem" }}>
                        <label for="assetId" class="form-label bg-white">FA Posting Date :</label>
                        <input style={{ width: "50%", border: "0.5px solid", paddingLeft: "0.5rem" }} type='date' />
                    </div> */}
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Depreciation Method :</label>
                        <Form.Select onChange={handleMethod} style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm">
                            <option>Select method</option>
                            <option>SLM</option>
                            {/* <option>WDV</option> */}
                        </Form.Select>
                    </div>
                    {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", marginBottom: "0.5rem" }}>
                        <label for="assetId" class="form-label bg-white">Enter Financial Year :</label>
                        <input style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} type='number' placeholder='Ex: 2023' onChange={(e) => setFy(e.target.value)} />
                    </div> */}
                    <div class="col-md-6 bg-white" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "2rem" }}>
                        <div>
                            <label for="assetId" class="form-label bg-white">Select Financial Year :</label>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ display: "flex" }}>
                                <div class="form-check" >
                                    <input onChange={handleYear} class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="prev" />
                                    <label class="form-check-label" for="inlineRadio1">Prev Year({new Date().getFullYear() - 1}-{(new Date().getFullYear()).toString().slice(2, 4)})</label>
                                </div>

                                <div class="form-check">
                                    <input onChange={handleYear} class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="current" />
                                    <label class="form-check-label" for="inlineRadio2">Cur Year({new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(2, 4)})</label>
                                </div>
                            </div>


                            <div >
                                {isNew == "all" && <select style={{ width: "100%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} type='number' placeholder='Ex: 2023' onChange={(e) => setFy(e.target.value)} >
                                    <option value="-">Select Year</option>
                                    {yearOptions?.map((el) => <option value={el.value}>{el.lable}</option>)}
                                </select>}

                            </div>
                        </div>
                    </div>
                    {/* <p>Selected year:                        {fy}</p> */}
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select Location :</label>
                        <select style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} onChange={(e) => setLocationSelect(e.target.value)} >
                            <option value="all"> All</option>
                            {location?.result && location?.result.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                        </select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select BU Code :</label>
                        <select style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} onChange={(e) => setBuCode(e.target.value)} >
                            <option value="all"> All</option>
                            {buCode1 && buCode1.map((el) => <option value={el}>{el}</option>)}
                        </select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select Cost Center :</label>
                        <select style={{ width: "50%", border: "0.5px solid grey", paddingLeft: "0.5rem", height: "36px" }} onChange={(e) => setCostCenter(e.target.value)} >
                            <option value="all"> All</option>
                            {center?.result && center?.result.map((el) => <option value={el.code}>{el.code} {el.name}</option>)}
                        </select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", paddingRight: "2rem", marginBottom: "0.5rem", justifyContent: "right", }}>
                        <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => getData()}>Run Depreciation</Button>
                    </div>
                </div>

                {showTables && <div style={{ backgroundColor: "white", marginTop: "1rem", borderRadius: "0.5rem", padding: "1rem" }}>
                    {/* <div class="container" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div><p class="fs-4 fw-semibold">Report</p></div>

                            <div class="input-group-2" >
                                <input type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="basic-addon2" />
                            </div>
                        </div> */}
                    <p style={{ fontSize: "16px", fontWeight: "bolder" }}>FA Depreciation</p>

                    {isLoading && <Loader />}
                    <div className='table-responsive'
                        style={{
                            overflow: "scroll",
                            minHeight: "400px"
                        }}>
                        <table className="table-bordered" >
                            {/* style={{ textAlign: "center", fontSize: "13px", border: "1px solid", }} */}
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>S. No</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>BU Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>GL Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset ID</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Asset Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'> Nature of Asset</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Class Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Class Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost Center Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost Center Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Location Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Location Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Supplier Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Invoice No</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Date of Purchase</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Date of Installation</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Invoice Value (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Other Cost (INR)</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost (INR)</th> */}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Salvage Value (INR)</th>
                                    {method && method !== "SLM" && < th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Rate</th>}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Life in Months</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Quantity</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>User</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Description</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Opening Gross Block (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Addition During Year (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Deletion During Year (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Gross Block (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Opening Depreciation (INR)</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Apr {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>May {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Jun {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Jul {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Aug {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Sept {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Oct {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Nov {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Dec {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Jan {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Feb {fy}</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Mar {fy}</th> */}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Current Depreciation (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Accumulated Depreciation (INR)</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Net Block (INR)</th>
                                </tr>
                            </thead>
                            <tbody >
                                {list1 && <DataDep excelData={list1} />}
                            </tbody>
                        </table>

                    </div>



                    {/* <p style={{ fontSize: "16px", marginTop: "2rem" }}>WDV Depreciation (IT act)</p>
                    <div className='table-responsive' style={{ border: "1px solid", overflow: "scroll", minHeight: "400px" }}>
                        <table className='table table-bordered' style={{ textAlign: "center", fontSize: "13px", border: "1px solid", }}>
                            <thead>
                                <tr>
                                    <th scope='col'>Asset ID</th>
                                    <th scope='col'>Asset Name</th>
                                    <th scope='col'> Nature of asset</th>
                                    <th scope='col'>Cost Center</th>
                                    <th scope='col'>Supplier Name</th>
                                    <th scope='col'>location</th>
                                    <th scope='col'>Invoice No</th>
                                    <th scope='col'>Date Of Purchase</th>
                                    <th scope='col'>Date Of Installation</th>
                                    <th scope='col'>Cost</th>
                                    <th scope='col'>Salvage Value</th>
                                    <th scope='col'>Life</th>
                                    <th scope='col'>Quantity</th>
                                    <th scope='col'>User</th>
                                    <th scope='col'>Description</th>
                                    <th scope='col'>Total Cost</th>
                                    <th scope='col'>Deletion During Year</th>
                                    <th scope='col'>Gross Block</th>
                                    <th scope='col'>Opening Depreciation</th>
                                    <th scope='col'>Total Depreciation</th>
                                    <th scope='col'>Accumulated Depreciation</th>
                                    <th scope='col'>Net Block</th>
                                </tr>
                            </thead>
                            <tbody >
                                {excelData && <DataDep excelData={excelData} />}
                            </tbody>
                        </table>
                    </div> */}
                </div>}
            </div >

            {showTables &&
                <>
                    <Button
                        type="submit"
                        onClick={() => setModalShow1(true)}
                        style={{
                            margin: "2rem",
                            backgroundColor: "#69fae1",
                            color: "#000",
                            fontWeight: "bold"
                        }}
                    >
                        Post Balances
                    </Button>

                    <div>
                        {/* pagination */}
                        {data1List !== null && data1List.length > 0 ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                    padding: "10px",
                                }}
                            >
                                <div className="mb-6">
                                    <label>Items per page:</label> &nbsp;
                                    <select
                                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                        style={{ borderRadius: "8px", cursor: "pointer" }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div style={{ paddingRight: "25px" }} className="pagination">
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            border: "none",
                                            backgroundColor: "#fff",
                                        }}
                                        disabled={currentPage === 1}
                                        onClick={handleFirstPage}
                                    >
                                        {currentPage === 1 ? (
                                            <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                                    </button>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            border: "none",
                                            backgroundColor: "#fff",
                                        }}
                                        disabled={currentPage === 1}
                                        onClick={handlePrev}
                                    >
                                        {/* Prev */}
                                        {currentPage === 1 ? (
                                            <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                    </button>
                                    <span style={{ padding: "10px" }}>
                                        Showing {currentPage} of {totalPages} pages
                                    </span>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            border: "none",
                                        }}
                                        disabled={currentPage === totalPages}
                                        onClick={handleNext}
                                    >
                                        {currentPage === totalPages ? (
                                            <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                                    </button>
                                    <button
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            border: "none",
                                        }}
                                        disabled={currentPage === totalPages}
                                        onClick={handleLastpage}
                                    >
                                        {currentPage === totalPages ? (
                                            <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                        ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>

                </>

            }

            <Modal
                show={modalShow1}
                onHide={() => setModalShow1(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {/* ....................................Summary....................................... */}
                    <div>
                        {/* <table className="table-bordered">
                            <thead >
                                <tr style={{
                                    textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                }}>
                                    <th scope="col"
                                        style={{ padding: "8px", textAlign: "center", width: "auto" }}>S.No</th>
                                    <th scope="col"
                                        style={{ padding: "8px", textAlign: "center", width: "auto" }}>Description</th>
                                   

                                </tr>
                            </thead>
                            <tbody>
                                {list1?.map((el, index) =>
                                (<tr key={el.id} style={{ textAlign: "center" }}>
                                    <td >{index + 1}</td>
                                    <td >{el.description}</td>

                                </tr>)
                                )}
                            </tbody>
                        </table> */}
                        <div >
                            <h6>Total Number of Assets:  {numberOfAssets}</h6>
                            <h6>Total Depreciation (INR):  {total_cost.toFixed(2)}</h6>
                            <h6>Total Net Block (INR):  {totalNetBlock.toFixed(2)}</h6>
                            {/* totalNetBlock */}
                            {/* total_cost */}
                        </div>

                    </div>

                    {/* .............................................................................. */}

                    <div style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}>
                        <p>Do you want to post balances to GL?</p>
                        <label className={`switch ${isChecked ? 'checked' : ''}`}>
                            <input type="checkbox" checked={isChecked1} onChange={handleToggle1} />
                            <span className="slider round"> </span>
                        </label>
                    </div>
                    <div style={{ display: "flex", gap: "2rem" }}>
                        {/* <p>Do you want to close the period?</p>
                        <label className={`switch ${isChecked ? 'checked' : ''}`}>
                            <input type="checkbox" checked={isChecked} onChange={handleToggle} />
                            <span className="slider round"> </span>
                        </label> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} disabled={isChecked1 ? false : true} >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* </div > */}
        </>
    )
}

export default Depreciation