import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx'
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";

import axios from "axios";
import { DataExcel } from "../../components/FA-Components/DataExcel";
import SingleCreation from "../../components/FA-Components/SingleCreation";
import Commonheader from "../../layouts/common/commonHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BackButton from "../../components/BackButton/BackButton";
export const Register = () => {
    const [showUpload, setShowUpload] = useState(true)
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [isNew, setIsNew] = useState("new")
    const [showPreviewButton, setShowPreviewButton] = useState(true)
    const isSubmitted = useSelector((state) => state.FA.isSubmitted);


    // submit
    const [excelData, setExcelData] = useState(null);
    // console.log(excelData, 'excelData');

    // it will contain array of objects
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // handle File
    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                    setShowPreviewButton(false)
                }
            }
            else {
                setExcelFileError('Please select only excel file types');
                setExcelFile(null);
            }
        }
        else {
            // console.log('plz select your file');
        }
    }
    const handleAsset = (e) => {
        // console.log(e.target.value)
        setIsNew(e.target.value)
    }
    const handleDownload = () => {
        // Create an anchor element
        const downloadLink = document.createElement('a');
        // Set the file URL (assuming the file is in the public/downloads folder)
        // https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Fixed_Asset_Bulk_Upload_V1_0.xlsx
        const fileUrl = process.env.PUBLIC_URL + 'Sample_Upload.xlsx';
        // Set the anchor's href and download attributes
        downloadLink.href = fileUrl;
        downloadLink.download = 'Sample_Upload.xlsx';
        // Append the anchor to the document and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        // Clean up the anchor element
        document.body.removeChild(downloadLink);
    }
    // submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        if (excelFile !== null) {
            let customHeader = [[
                'SrNo',
                "asset_id",
                "asset_name",
                "nature_of_asset",
                "bu_code",
                "gl_code",
                "serial_number",//"asset_tag"
                "asset_class",
                "cost_center",
                "location",
                "supplier_code",
                "supplier_name",
                "supplier_location",
                "acquisition_date",//"dop",
                "installation_date",// "doi",
                "invoice_date",
                "invoice_no",
                "invoice_value",
                "salvage_value",
                "other_value",
                "acquisition_price",// "cost",
                "useful_life",// "life",
                "quantity",
                "remarks",//"description",
                "user",
            ]]

            const workbook = XLSX.read(excelFile, { type: 'buffer', });
            const worksheetName = workbook.SheetNames[1];
            const worksheet = workbook.Sheets[worksheetName];

            let data = XLSX.utils.sheet_to_json(worksheet, { defval: null });

            const CreateWStoMeargeAsset = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(CreateWStoMeargeAsset, customHeader);

            const MeargehederDataAsset = XLSX.utils.sheet_add_json(
                CreateWStoMeargeAsset,
                data,
                { skipHeader: true, origin: "A2" },
                { defval: null }
            );
            const MeagedData1 = XLSX.utils.sheet_to_json(MeargehederDataAsset, {
                defval: null,
            });
            const MeagedData = MeagedData1.splice(1);
            data = MeagedData;
            MeagedData.forEach(function (obj) {
                delete obj.SrNo;
            });
            // console.log("Excel Data", MeagedData)
            MeagedData.forEach(function (obj) {
                const parts = obj.installation_date.split('-');
                const day = parts[0];
                const month = parts[1];
                const year = parts[2];

                // Create a JavaScript Date object in "yyyy-mm-dd" format
                const jsDate = new Date(`${year}-${month}-${day}`);
                // console.log('jsDate', jsDate);
                // console.log(moment(jsDate).utc().format());
                obj.installation_date = moment(jsDate).utc().format()
            });

            MeagedData.forEach(function (obj) {
                const parts1 = obj.acquisition_date.split('-');
                const day1 = parts1[0];
                const month1 = parts1[1];
                const year1 = parts1[2];

                // Create a JavaScript Date object in "yyyy-mm-dd" format
                const jsDate1 = new Date(`${year1}-${month1}-${day1}`);
                // console.log('jsDate1', jsDate1);
                obj.acquisition_date = moment(jsDate1).utc().format()
            });

            MeagedData.forEach(function (obj) {
                const parts2 = obj.invoice_date.split('-');
                const day2 = parts2[0];
                const month2 = parts2[1];
                const year2 = parts2[2];

                // Create a JavaScript Date object in "yyyy-mm-dd" format
                const jsDate2 = new Date(`${year2}-${month2}-${day2}`);
                // console.log('jsDate2', jsDate2);
                obj.invoice_date = moment(jsDate2).utc().format()
            });


            // console.log(isNew)
            if (isNew == "existing") {
                let filtered = MeagedData.filter((el) =>
                    // el.hasOwnProperty("cost") && el.cost !== null &&
                    el.hasOwnProperty("acquisition_date") && el.acquisition_date !== null &&
                    el.hasOwnProperty("installation_date") && el.installation_date !== null &&
                    el.hasOwnProperty("asset_name") && el.asset_name !== null &&
                    el.hasOwnProperty("nature_of_asset") && el.nature_of_asset !== null &&
                    el.hasOwnProperty("bu_code") && el.bu_code !== null &&
                    el.hasOwnProperty("asset_class") && el.asset_class !== null &&
                    el.hasOwnProperty("cost_center") && el.cost_center !== null &&
                    el.hasOwnProperty("location") && el.location !== null &&
                    el.hasOwnProperty("invoice_date") && el.invoice_date !== null &&
                    el.hasOwnProperty("invoice_no") && el.invoice_no !== null &&
                    el.hasOwnProperty("salvage_value") && el.salvage_value !== null &&
                    el.hasOwnProperty("useful_life") && el.useful_life !== null &&
                    el.hasOwnProperty("quantity") && el.quantity !== null
                );
                // console.log("asdfgh", filtered)
                if (filtered.length != data.length) {
                    alert("Please fill all mandatory fields")
                    setExcelData(null)
                    setShow(false)
                    return
                }
            } else if (isNew == "new") {
                // console.log("MeagedData", MeagedData)
                let filtered = MeagedData.filter((el) =>
                    // el.hasOwnProperty("cost") && el.cost !== null &&
                    el.hasOwnProperty("acquisition_date") && el.acquisition_date !== null &&
                    el.hasOwnProperty("installation_date") && el.installation_date !== null &&
                    el.hasOwnProperty("asset_name") && el.asset_name !== null &&
                    el.hasOwnProperty("nature_of_asset") && el.nature_of_asset !== null &&
                    el.hasOwnProperty("bu_code") && el.bu_code !== null &&
                    el.hasOwnProperty("asset_class") && el.asset_class !== null &&
                    el.hasOwnProperty("cost_center") && el.cost_center !== null &&
                    el.hasOwnProperty("location") && el.location !== null &&
                    el.hasOwnProperty("invoice_date") && el.invoice_date !== null &&
                    el.hasOwnProperty("invoice_no") && el.invoice_no !== null &&
                    el.hasOwnProperty("salvage_value") && el.salvage_value !== null &&
                    el.hasOwnProperty("useful_life") && el.useful_life !== null &&
                    el.hasOwnProperty("quantity") && el.quantity !== null
                );
                // console.log("asdfgh", filtered)
                // console.log("data", data)
                if (filtered.length !== data.length) {
                    alert("Please fill all mandatory fields")
                    setExcelData(null)
                    setShow(false)
                    return
                }
            }
            // console.log("MeagedData", MeagedData);
            setExcelData(MeagedData);
            setShow(true)
        }
        else {
            setExcelData(null);
        }
    }
    const handleSubmitBulk = async () => {
        // console.log(excelData)
        try {
            dispatch({
                type: "ADD_BULK_FAASSET",
                payload: [excelData, isNew]
            })

            // console.log(isNew)
            // if (isNew == "new") {
            //     navigate("/cwip")
            // } else {
            //     navigate("/capitalised")
            // }
            // await axios.post("http://localhost:4010/create", excelData)
            // alert("Asset Uploaded successfully")
        } catch (error) {
            // console.log(error)
        }
    }
    // console.log(isSubmitted, 'isSubmitted');
    useEffect(() => {
        if (isSubmitted) {
            if (isNew == "new") {
                navigate("/cwip")
            } else {
                navigate("/capitalised")
            }
        }

    }, [isSubmitted])

    return (
        <>
            {/* <Sidebar /> */}
            <div>
                <Commonheader />
            </div>
            {/* <div className="services" style={{ marginTop: "5rem" }}> */}
            {/* <div class="container fs-4 my-3" > */}
            <div style={{ padding: "5rem" }}>
                {!showUpload &&
                    <button type="button" className="float-end saveButton" style={{ width: "10rem", marginLeft: "1rem" }} onClick={() => setShowUpload(true)}>UI Upload</button>}
                {showUpload &&
                    <button type="button" className="float-end saveButton" style={{ width: "10rem" }} onClick={() => setShowUpload(false)}>Bulk Upload</button>
                }
            </div>
            <BackButton />
            {
                showUpload ?
                    // <div >
                    <SingleCreation />
                    // </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 offset-md-3">
                                <div className="border bg-light shadow rounded" style={{ width: "50%", height: "auto", marginTop: "1rem", borderRadius: "0.5rem", padding: "1rem", border: '10px red' }}>
                                    <div className="formHeadings mb-3">Bulk Upload - Asset Registration</div>
                                    <hr></hr>
                                    <div className="container" style={{ width: "100%", marginTop: "2rem" }}>
                                        <div className="form-check form-check-inline">
                                            <input onChange={handleAsset} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="new" defaultChecked />
                                            <label className="form-check-label" for="inlineRadio1">New Asset</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={handleAsset} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="existing" />
                                            <label className="form-check-label" for="inlineRadio2">Existing Asset</label>
                                        </div>
                                        <div style={{ marginTop: "2rem", width: "30rem" }}>
                                            <input type="file" className="form-control" onChange={handleFile} style={{ border: "none" }} required />
                                            {excelFileError && <div className='text-danger fs-6'
                                                style={{ marginTop: 5 + 'px' }}>{excelFileError}</div>}

                                        </div>
                                        {/* <p type="button" onClick={handleDownload} style={{ fontSize: "16px", marginTop: "1rem", color: "blue" }} >Download Format</p> */}
                                    </div>
                                    <br />
                                    <br />
                                    <div>
                                        <button type="button" onClick={handleSubmit}
                                            class="btn btn-primary"
                                            style={{ width: "30%", float: "right", marginTop: "1rem" }}
                                        // disabled={showPreviewButton}
                                        >
                                            Preview
                                        </button>
                                    </div>
                                    <div>

                                        <div style={{ paddingLeft: '10px', marginTop: '6rem' }}>
                                            Download Asset Registration Bulk Upload Template - &nbsp;
                                            <a
                                                href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Fixed_Asset_Bulk_Upload_V1.2.xlsx`}
                                            >
                                                Click here
                                            </a>
                                        </div>
                                    </div>

                                    <Modal
                                        show={show}
                                        // size="lg"
                                        onHide={() => setShow(false)}
                                        fullscreen={fullscreen}
                                        dialogClassName="modal-90w"
                                        aria-labelledby="example-custom-modal-styling-title"

                                    >
                                        <Modal.Header closeButton style={{ paddingTop: "4rem", }}>
                                            <Modal.Title id="example-custom-modal-styling-title">
                                                Preview
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {excelData !== null && (
                                                <div className='table-responsive'>
                                                    <table className='table' style={{ textAlign: "center", fontSize: "14px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Asset ID</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Asset Name</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'> Nature of asset</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Cost Center</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Supplier Name</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>location</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Invoice No</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Date of Purchase</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Date of Installation</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Invoice Value (INR)</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Other Cost (INR)</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Cost (INR)</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Salvage Value (INR)</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Life</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Quantity</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>User</th>
                                                                <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {excelData && <DataExcel excelData={excelData} />}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button onClick={handleSubmitBulk}>Submit</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div></div></div>
            }
            {/* </div> */}
            {/* </div> */}
        </>
    );
};

