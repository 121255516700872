import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

function insMainStatusData(data) {
    // console.log(data);
    // console.log(CONSTANTS.INS_MAIN_STATUS + data[6] + `?ins_start=${data[0]}&ins_end=${data[1]}&maint_start=${data[2]}&maint_end=${data[3]}&on_start=${data[4]}&on_end=${data[5]}`);
    return serviceBase.get(CONSTANTS.INS_MAIN_STATUS + data[8] + `?ins_start=${data[0]}&ins_end=${data[1]}&maint_start=${data[2]}&maint_end=${data[3]}&on_start=${data[4]}&on_end=${data[5]}&tkt_start=${data[6]}&tkt_end=${data[7]}`);
};

function criticalityData() {
    return serviceBase.get(CONSTANTS.ASSET_CRITICALITY);
};

function insMainStatusDataOffline (data) {
    return serviceBase.get(CONSTANTS.INS_MAIN_STATUS_OFFLINE + data[8] + `?ins_start=${data[0]}&ins_end=${data[1]}&maint_start=${data[2]}&maint_end=${data[3]}&on_start=${data[4]}&on_end=${data[5]}&tkt_start=${data[6]}&tkt_end=${data[7]}`);
}
export const dashboardDetails = {
    insMainStatusData,
    criticalityData,
    insMainStatusDataOffline
};