// responsible for handling all of the actions that are dispatched
import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import UserReducer from "./userReducer";
import CustReducer from "./custReducer";
import SiteReducer from "./siteReducer";
import AssetReducer from "./assetReducer";
import DeviceReducer from "./deviceReducer";
import FacilityReducer from "./facilityReducer";
import InspectionReducer from './inspectionReducer';
import MaintenanceReducer from "./maintReducer";
import AccessReducer from "./accessReducer";
import DashboardReducer from './dashReducer'
import HistoryReducer from "./historyReducer";
import ReportReducer from "./reportReducer";
import FAReducer from "./faReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  customer: CustReducer,
  site: SiteReducer,
  asset: AssetReducer,
  device: DeviceReducer,
  facility: FacilityReducer,
  inspection: InspectionReducer,
  maintenance: MaintenanceReducer,
  access: AccessReducer,
  dashboard: DashboardReducer,
  history: HistoryReducer,
  report: ReportReducer,
  FA: FAReducer
});

//exporting reducers for global usage
export default rootReducer;