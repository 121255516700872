/* Feature - to maintain header for user page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Bulkupload from "../../modules/user/bulkUpload";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import Logo from "../../components/logo";
import { useSelector, useDispatch } from "react-redux";

//Function for site header
function LocationHeader() {
  const dispatch = useDispatch();
  // modal for bulk upload button
  const [modalShow, setModalShow] = useState(false);

  const access = useSelector((state) => state.access.user);
  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    const roleCode = role[0];
    // console.log(roleCode);
    if (access === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role]);

  useEffect(() => {
    // console.log(access);
  }, [access]);

  return (
    <div>
      <div>
        <Navbar bg="light" variant={"light"} expand="lg">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="headershadow">
            <Nav
              className="mr-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
            >
              <Sidebar />
              <Logo />

              <div >
                <div>
                  <Nav.Link
                    activeclassname="active"
                    as={Link}
                    to="/addlocation"
                    className="position-absolute end-0 header"
                    style={{ marginRight: "300px", }}
                  >
                    Add location
                  </Nav.Link>
                </div>
              </div>
              <LogoutButton />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
    </div>
  );
}

export default LocationHeader;
