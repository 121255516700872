/* Feature - Logout component

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React from "react";
import { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faSignOut,
  faUser,
  faUserCircle,
  faUserPen,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { Persistor } from "../redux/store.index";
// import Cookies from 'js-cookie';

// function for Logout
const LogoutButton = () => {
  const navigate = useNavigate();

  // from redux
  const dispatch = useDispatch();
  const reducerToken = useSelector((state) => state.auth.userToken);
  const email = useSelector((state) => state.auth.email);
  const userProfileData = useSelector((state) => state.user.userSingleData);
  // dispatch to redux for logout functionality
  const handleLogout = (e) => {
    const confirmed = window.confirm("Are you sure you want to Logout ?");
    if (confirmed) {
      Persistor.purge();
      localStorage.clear();
      sessionStorage.clear("persist:root");
      // Cookies.remove('refreshToken', { path: '/' });
      dispatch({
        type: "LOGOUT",
      });
      // storage.removeItem("persist:root");
    }
  };

  // useEffects
  useEffect(() => {
    if (reducerToken === null) navigate("/");
  }, [reducerToken]);

  useEffect(() => {
    if (email === null);
    let viewUserObj = {
      email: email,
    };
    dispatch({
      type: "USER_GET_SINGLE",
      payload: viewUserObj,
    });
  }, [email]);

  return (
    <div>
      <div className="position-absolute end-0" style={{ color: "#000" }}>
        <FontAwesomeIcon
          size="lg"
          icon={faUserCircle}
          className="pt-1 mt-2 sideNavIcons"
          style={{ maxHeight: "100px", cursor: "pointer" }}
        ></FontAwesomeIcon>
        <span style={{ fontSize: "15px", fontWeight: "bolder" }}>
          {userProfileData.first_name}&nbsp;{userProfileData.middle_name}&nbsp;
          {userProfileData.last_name}
        </span>
        <button
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            border: "none",
            color: "#2D7DC1",
            background: "none",
          }}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faCaretDown}
            className="pt-1 mt-2 mx-4 sideNavIcons"
            style={{ maxHeight: "100px", cursor: "pointer" }}
          ></FontAwesomeIcon>
        </button>

        <ul className="dropdown-menu mt-2">
          <li className="pt-1">
            <button
              className="dropdwn "
              style={{
                textDecoration: "none",
                color: "black",
                textAlign: "center",
                border: "none",
                background: "none",
              }}
              onClick={() => {
                navigate("/userdetail");
              }}
            >
              Profile
              <span data-tip data-for="toolTip">
                <FontAwesomeIcon
                  size="1x"
                  id="tooltip-anchor"
                  icon={faUserPen}
                  className="mx-4"
                ></FontAwesomeIcon>
              </span>
            </button>
          </li>
          <li className="pb-2">
            <button
              className="dropdwn"
              style={{
                border: "none",
                background: "none",
                textDecoration: "none",
                color: "black",
                textAlign: "center",
              }}
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
              <span data-tip data-for="toolTip">
                <FontAwesomeIcon
                  size="lg"
                  id="tooltip-anchor"
                  icon={faSignOut}
                  className="mx-3"
                ></FontAwesomeIcon>
              </span>
              {/* <ReactTooltip id="toolTip" place="bottom" effect="solid">
                Logout
              </ReactTooltip> */}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LogoutButton;
