import React, { useEffect, useState } from 'react'
import Speedometer, {
    Background,
    Arc,
    Needle,
    Progress,
    Marks,
    Indicator,
} from 'react-speedometer';



function RadialInsChart(props) {

    const [status, setStatus] = useState({
        inTotalPlanned: 0,
        inProgressvalue: 0,
        inCompletedvalue: 0,
        inPendingvalue: 0,
    })
    // console.log(status);


    // console.log(status.inTotalPlanned);

    if (status.inTotalPlanned === 0) {
        var stepSize = 1;
        status.inTotalPlanned = 1;
    }
    if (status.inTotalPlanned <= 10 && status.inTotalPlanned >= 2) {
        var stepSize = status.inTotalPlanned / status.inTotalPlanned;
        // console.log(stepSize);

    } if (status.inTotalPlanned >= 11) {
        var stepSize = status.inTotalPlanned / 10;
        // console.log(stepSize);
    }

    useEffect(() => {
        // console.log(props.data);
        if (props.data !== undefined && props.data.totalPlanned !== '') {
            // setStatus(props.data)
            setStatus({
                inTotalPlanned: props.data.totalPlanned,
                inProgressvalue: props.data.inProgress,
                inCompletedvalue: props.data.completed,
                inPendingvalue: props.data.pending,
            })
        }
    }, [props.data])

    return (
        <div
            style={{
                width: "100%",
                // border: '2px solid',
                height: 'auto',
                display: "flex",
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                marginBottom: '2rem'
            }}
        >
            <div style={{ border: '1px solid rgba(105, 250, 225, 1)', borderRadius: '10px', padding: '0.5rem 2rem', height: '200px' }}>
                <div style={{
                    // marginLeft: '140px', 
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    margin: 'auto',
                    // border: '1px solid',
                    textAlign: 'center'
                }}>
                    <p>In Progress - {status.inProgressvalue}</p>
                </div>

                <div style={{
                    // marginLeft: '70px' 
                }}>
                    <Speedometer
                        value={status.inProgressvalue}
                        max={status.inTotalPlanned}
                        angle={160}
                        fontFamily='squada-one'
                    >
                        <Background angle={180} color='#021D37' opacity={1} />
                        <Arc />
                        <Needle color={'#64FFE2'} />
                        <Progress color={'#64FFE2'} />
                        <Marks step={stepSize} fontSize={'auto'} color={'#000'} />
                    </Speedometer>
                </div>
            </div>


            <div style={{ border: '1px solid rgba(105, 250, 225, 1)', borderRadius: '10px', padding: '0.5rem 2rem', height: '200px' }}>

                <div style={{
                    // marginLeft: '140px', 
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    margin: 'auto',
                    // border: '1px solid',
                    textAlign: 'center'
                }}>
                    <p>Completed - {status.inCompletedvalue}</p>
                </div>
                <div style={{
                    // marginLeft: '70px'
                }}>
                    <Speedometer
                        value={status.inCompletedvalue}
                        max={status.inTotalPlanned}
                        angle={160}
                        fontFamily='squada-one'
                    >
                        <Background angle={180} color='#021D37' opacity={1} />
                        <Arc />
                        <Needle color={'#64FFE2'} />
                        <Progress color={'#64FFE2'} />
                        <Marks step={stepSize} fontSize={'auto'} color={'#000'} />
                    </Speedometer>
                </div>
            </div>

            <div style={{ border: '1px solid rgba(105, 250, 225, 1)', borderRadius: '10px', padding: '0.5rem 2rem', height: '200px' }}>

                <div style={{
                    // marginLeft: '140px', 
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    margin: 'auto',
                    // border: '1px solid',
                    textAlign: 'center'
                }}>
                    <p>Pending - {status.inPendingvalue}</p>
                </div>
                <div style={{
                    // marginLeft: '70px' 
                }}>
                    <Speedometer
                        value={status.inPendingvalue}
                        max={status.inTotalPlanned}
                        angle={160}
                        fontFamily='squada-one'
                    >
                        <Background angle={180} color='#021D37' opacity={1} />
                        <Arc />
                        <Needle color={'#64FFE2'} />
                        <Progress color={'#64FFE2'} />
                        <Marks step={stepSize} fontSize={'auto'} color={'#000'} />
                    </Speedometer>
                </div>
            </div>
        </div>
    )
}

export default RadialInsChart

