import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportTable from './reportTable'
import moment from 'moment'

function PCAUBI2({ responseData, filterData, chklname, docNumber, depname, checkedby, instrumentCode, instCodeData, objheading, malur, medopharm, check1, check2, checked }) {

    const formatValue = (condition, trueValue, falseValue = '-') => {
        console.log("truevalue",condition,trueValue)
       return condition ? trueValue : falseValue
    };
    const formatCheckpoint = (objectIndex,index) => formatValue(
        responseData?.checklist && responseData?.checklist?.length > 0 && responseData?.checklist[objectIndex]?.checkpoints[index]?.answer !== undefined,
        responseData?.checklist && responseData?.checklist?.length > 0 && responseData.checklist[objectIndex]?.checkpoints[index]?.answer!==''? responseData.checklist[objectIndex]?.checkpoints[index]?.answer : '-'
    );
    const formatCheckpointMaint = (objectIndex,index) => formatValue(
        responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[objectIndex]?.maintenance_activities[0]?.checkpoints[index]?.cp_answer !== undefined,
        responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData.maint_checklist[objectIndex]?.maintenance_activities[0]?.checkpoints[index]?.cp_answer!==''? responseData.maint_checklist[objectIndex]?.maintenance_activities[0]?.checkpoints[index]?.cp_answer : '-'
    );

    const formatParent = (field) => formatValue(
        responseData?.parent && responseData?.parent[field] !== undefined,
        responseData?.parent ? responseData.parent[field] : ''
    );

    let resData = [];
    const data3 = {
        data: `${chklname}`,
        colSpan: '7'
    }
    if (docNumber=='QCCB/028:F1-04'){
        responseData?.maint_checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    header: [
                        { data: `Calibration Sop No.`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibrated On`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration Due`, rowSpan: "2", textAlign: 'center' },
                        { data: `Calibration Report No`, rowSpan: "2", textAlign: 'center' },
                    ],
                    response: [
                        {
                            data: { value: formatCheckpointMaint(index,0), style: { textAlign: 'center' } },
                            data1: { value: formatCheckpointMaint(index,1), style: { textAlign: 'center' } },
                            data2: { value: formatCheckpointMaint(index,2), style: { textAlign: 'center' } },
                            data3: { value: formatCheckpointMaint(index,3), style: { textAlign: 'center' } },
                        },
                    ]
                },
                {
                    response: [
                        {
                            data: {value: "INSTRUMENT NAME :",style: { textAlign: 'left', colSpan: '4' },},
                            data1: {value: responseData?.parent !== undefined ? responseData?.parent?.parent_name : '-',style: { textAlign: 'left', colSpan: '4' }},
                            data2: {value: "Instrument code No. :",style: { textAlign: 'left', colSpan: '4' }},
                            data3: {value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-',style: { textAlign: 'left', colSpan: '4' }},
                        },
                        {
                            data: {value: "FREQUENCY :",style: { textAlign: 'left', colSpan: '4' }},
                            data1: {value: 'Monthly once',style: { textAlign: 'left', colSpan: '12' }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `A)`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Preparation of pH 4.01 Buffer solution (D) :`, colSpan: '5', rowSpan: "2", textAlign: 'left' },
                        { data: `Potassium hydrogen phthalate`, colSpan: '10', rowSpan: "1", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'left', colSpan: '1', }},
                            data2: { value: 'Instrument code', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,4), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'left', colSpan: '1', }},
                            data2: { value: 'Batch No.', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,5), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'left', colSpan: '1', }},
                            data2: { value: 'Weight of potassium hydrogen phthalate', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,6), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'left', colSpan: '1', }},
                            data2: { value: 'Loss wight', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,7), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'left', colSpan: '1', }},
                            data2: { value: 'Final weight of potassium hydrogen phthalate', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,8), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: `Dissolve ${formatCheckpointMaint(index,9)} (1.021 g) of potassium hydrogen phthalate A.R. in sufficient carbon dioxide free water to produce 100 ml.`, style: { textAlign: 'left', colSpan: '16', }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `B)`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Preparation of pH Buffer solution 6.86 (E) :`, colSpan: '15', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Instrument code', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,10), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: ``, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Potassium dihydrogen phosphate`, colSpan: '15', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Batch No.', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,11), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Weight of potassium dihydrogen phosphate taken', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,12), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Loss weight', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,13), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Final weight of potassium dihydrogen phosphate taken', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value:formatCheckpointMaint(index,14), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: ``, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Anhydrous disodium hydrogen phosphate`, colSpan: '15', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Batch No.', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,15), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Weight of Anhydrous disodium hydrogen phosphate taken', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,16), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Loss weight', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,17), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Final weight of Anhydrous disodium hydrogen phosphate', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,18), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: `Dissolve ${formatCheckpointMaint(index,19)} (0.340 g) of potassium dihydrogen phosphate A.R. and ${formatCheckpointMaint(index,20)} (0.355 g)  of anhydrous disodium hydrogen phosphate A.R previously dried at 110°C to 130°C  for 2 hours. In sufficient carbon dioxide free water to produce 100 ml`, style: { textAlign: 'left', colSpan: '16', }},
                        }
                    ]
                },
                {
                    header: [
                        { data: `C)`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Buffer solution pH 9.18(G) :`, colSpan: '5', rowSpan: "2", textAlign: 'left' },
                        { data: `Disodium tetra borate`, colSpan: '10', rowSpan: "1", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Batch No.', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,21), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Instrument code', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,22), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Weight of disodium tetra borate taken', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,23), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Loss wight', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,24), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Final weight of disodium tetra borate taken', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,25), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                        {
                            data: { value: `Dissolve ${formatCheckpointMaint(index,26)} (0.381 g) of dried disodium tetra borate in sufficient carbon dioxide free water to produce 100 ml.`, style: { textAlign: 'left', colSpan: '16', }},
                        },
                    ]
                },
                {
                    header: [
                        { data: `D)`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Buffer solution pH 1.68(C) & pH 12.45 (H) :`, colSpan: '15', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Standard buffer solution of pH 1.68 and 12.45 shall procured from outside with certificate of Analysis', style: { textAlign: 'left' ,colSpan: '15',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: ``, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Buffer solution of pH 1.68`, colSpan: '15', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Batch No.', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,27), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: ``, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Buffer solution of pH 12.45`, colSpan: '15', rowSpan: "2", textAlign: 'left' },
                    ]
                },
                {
                    response: [
                        {
                            data: { value: "", style: { textAlign: 'center', colSpan: '1', }},
                            data2: { value: 'Batch No.', style: { textAlign: 'left' ,colSpan: '5',} },
                            data3: { value: formatCheckpointMaint(index,28), style: { textAlign: 'left' ,colSpan: '10',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: `Measure the pH of referece buffers C,D,E,G & H at below mentioned temperature.`, colSpan: '13', rowSpan: "1", textAlign: 'left' },
        
                    ]
                },
                {
                    header: [
                        { data: `Temperature`, colSpan: '2', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed pH at Buffer C`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Observed pH at Buffer D`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Observed pH at Buffer E`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        // { data: `Remarks`, colSpan: '2', rowSpan: "2", textAlign: 'center' },
                    ],
                    header2: [
                        { data: `Obsvd`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Std.`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Obsvd`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Std.`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Obsvd`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Std.`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "15°C", style: { textAlign: 'center', colSpan: '2', }},
                            data2: { value: formatCheckpointMaint(index,29), style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "1.67", style: { textAlign: 'center' ,colSpan: '1',} },
                            data4: { value: "1.62 To 1.72", style: { textAlign: 'center', colSpan: '1', }},
                            data5: { value: formatCheckpointMaint(index,30), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: formatCheckpointMaint(index,39), style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "4.00", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: "3.95 To 4.05", style: { textAlign: 'center', colSpan: '1', }},
                            data9: { value: formatCheckpointMaint(index,40), style: { textAlign: 'center' ,colSpan: '1',} },
                            data10: { value: formatCheckpointMaint(index,49), style: { textAlign: 'center' ,colSpan: '1',} },
                            data11: { value: "6.90", style: { textAlign: 'center' ,colSpan: '1',} },
                            data12: { value: "6.85 To 6.95", style: { textAlign: 'center' ,colSpan: '1',} },
                            data13: { value: formatCheckpointMaint(index,50), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "20°C", style: { textAlign: 'center', colSpan: '2', }},
                            data2: { value: formatCheckpointMaint(index,31), style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "1.68", style: { textAlign: 'center' ,colSpan: '1',} },
                            data4: { value: "1.63 To 1.73", style: { textAlign: 'center', colSpan: '1', }},
                            data5: { value: formatCheckpointMaint(index,32), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: formatCheckpointMaint(index,41), style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "4.00", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: "3.95 To 4.05", style: { textAlign: 'center', colSpan: '1', }},
                            data9: { value: formatCheckpointMaint(index,42), style: { textAlign: 'center' ,colSpan: '1',} },
                            data10: { value: formatCheckpointMaint(index,51), style: { textAlign: 'center' ,colSpan: '1',} },
                            data11: { value: "6.88", style: { textAlign: 'center' ,colSpan: '1',} },
                            data12: { value: "6.83 To 6.93", style: { textAlign: 'center' ,colSpan: '1',} },
                            data13: { value: formatCheckpointMaint(index,52), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "25°C", style: { textAlign: 'center', colSpan: '2', }},
                            data2: { value: formatCheckpointMaint(index,33), style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "1.68", style: { textAlign: 'center' ,colSpan: '1',} },
                            data4: { value: "1.63 To 1.73", style: { textAlign: 'center', colSpan: '1', }},
                            data5: { value: formatCheckpointMaint(index,34), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: formatCheckpointMaint(index,43), style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "4.01", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: "3.96 To 4.06", style: { textAlign: 'center', colSpan: '1', }},
                            data9: { value: formatCheckpointMaint(index,44), style: { textAlign: 'center' ,colSpan: '1',} },
                            data10: { value: formatCheckpointMaint(index,53), style: { textAlign: 'center' ,colSpan: '1',} },
                            data11: { value: "6.86", style: { textAlign: 'center' ,colSpan: '1',} },
                            data12: { value: "6.81 To 6.91", style: { textAlign: 'center' ,colSpan: '1',} },
                            data13: { value: formatCheckpointMaint(index,54), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "30°C", style: { textAlign: 'center', colSpan: '2', }},
                            data2: { value: formatCheckpointMaint(index,35), style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "1.68", style: { textAlign: 'center' ,colSpan: '1',} },
                            data4: { value: "1.63 To 1.73", style: { textAlign: 'center', colSpan: '1', }},
                            data5: { value: formatCheckpointMaint(index,36), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: formatCheckpointMaint(index,45), style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "4.02", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: "3.97 To 4.07", style: { textAlign: 'center', colSpan: '1', }},
                            data9: { value: formatCheckpointMaint(index,46), style: { textAlign: 'center' ,colSpan: '1',} },
                            data10: { value: formatCheckpointMaint(index,55), style: { textAlign: 'center' ,colSpan: '1',} },
                            data11: { value: "6.85", style: { textAlign: 'center' ,colSpan: '1',} },
                            data12: { value: "6.80 To 6.90", style: { textAlign: 'center' ,colSpan: '1',} },
                            data13: { value: formatCheckpointMaint(index,56), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "35°C", style: { textAlign: 'center', colSpan: '2', }},
                            data2: { value: formatCheckpointMaint(index,37), style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "1.69", style: { textAlign: 'center' ,colSpan: '1',} },
                            data4: { value: "1.64 To 1.74", style: { textAlign: 'center', colSpan: '1', }},
                            data5: { value: formatCheckpointMaint(index,38), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: formatCheckpointMaint(index,47), style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "4.02", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: "3.97 To 4.07", style: { textAlign: 'center', colSpan: '1', }},
                            data9: { value: formatCheckpointMaint(index,48), style: { textAlign: 'center' ,colSpan: '1',} },
                            data10: { value: formatCheckpointMaint(index,57), style: { textAlign: 'center' ,colSpan: '1',} },
                            data11: { value: "6.84", style: { textAlign: 'center' ,colSpan: '1',} },
                            data12: { value: "6.79 To 6.89", style: { textAlign: 'center' ,colSpan: '1',} },
                            data13: { value: formatCheckpointMaint(index,58), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        
                    ]
                },
                {
                    header: [
                        { data: `Temperature`, colSpan: '2', rowSpan: "2", textAlign: 'center' },
                        { data: `Observed pH at Buffer G`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Observed pH at Buffer H`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                    header2: [
                        { data: `Obsvd`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Std.`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Obsvd`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Std.`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "15°C", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,59), style: { textAlign: 'center' ,colSpan: '1',} },
                            data2: { value: "9.28", style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "9.23 To 9.33", style: { textAlign: 'center', colSpan: '1', }},
                            data4: { value: formatCheckpointMaint(index,60), style: { textAlign: 'center' ,colSpan: '1',} },
                            data5: { value: formatCheckpointMaint(index,69), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: "12.81", style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "12.76 To 12.86", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: formatCheckpointMaint(index,70), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "20°C", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,61), style: { textAlign: 'center' ,colSpan: '1',} },
                            data2: { value: "9.23", style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "9.18 To 9.28", style: { textAlign: 'center', colSpan: '1', }},
                            data4: { value: formatCheckpointMaint(index,62), style: { textAlign: 'center' ,colSpan: '1',} },
                            data5: { value: formatCheckpointMaint(index,71), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: "12.63", style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "12.58 To 12.68", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: formatCheckpointMaint(index,72), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "25°C", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,63), style: { textAlign: 'center' ,colSpan: '1',} },
                            data2: { value: "9.18", style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "9.13 To 9.23", style: { textAlign: 'center', colSpan: '1', }},
                            data4: { value: formatCheckpointMaint(index,64), style: { textAlign: 'center' ,colSpan: '1',} },
                            data5: { value: formatCheckpointMaint(index,73), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: "12.45", style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "12.40 To 12.50", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: formatCheckpointMaint(index,74), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "30°C", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,65), style: { textAlign: 'center' ,colSpan: '1',} },
                            data2: { value: "9.14", style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "9.09 To 9.19", style: { textAlign: 'center', colSpan: '1', }},
                            data4: { value: formatCheckpointMaint(index,66), style: { textAlign: 'center' ,colSpan: '1',} },
                            data5: { value: formatCheckpointMaint(index,75), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: "12.29", style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "12.24 To 12.34", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: formatCheckpointMaint(index,76), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        {
                            data: { value: "35°C", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,67), style: { textAlign: 'center' ,colSpan: '1',} },
                            data2: { value: "9.10", style: { textAlign: 'center' ,colSpan: '1',} },
                            data3: { value: "9.05 To 9.15", style: { textAlign: 'center', colSpan: '1', }},
                            data4: { value: formatCheckpointMaint(index,68), style: { textAlign: 'center' ,colSpan: '1',} },
                            data5: { value: formatCheckpointMaint(index,77), style: { textAlign: 'center' ,colSpan: '1',} },
                            data6: { value: "12.13", style: { textAlign: 'center' ,colSpan: '1',} },
                            data7: { value: "12.08 To 12.18", style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: formatCheckpointMaint(index,78), style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                        
                    ]
                },
                {
                    header: [
                        { data: `Verfication :`, colSpan: '4', rowSpan: "1", textAlign: 'left' },
                    ],
                    header2: [
                        { data: `Temperature`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Observed`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Standard Buffer Used`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                        { data: `Limits`, colSpan: '1', rowSpan: "1", textAlign: 'center' },
                    ]
                },
                {
                    response: [
                        {
                            data5: { value: formatCheckpointMaint(index,79), style: { textAlign: 'center' ,colSpan: '1',} },
                            data8: { value: formatCheckpointMaint(index,80), style: { textAlign: 'center' ,colSpan: '1',} },
                            data9: { value: "6.86", style: { textAlign: 'center' ,colSpan: '1',} },
                            data10: { value: "6.81 to 6.91", style: { textAlign: 'center' ,colSpan: '1',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: `Temperature Calibration :`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                        { data: `Temperature calibration of temperature probe found to be ${formatCheckpointMaint(index,81)}`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    ]
                },
                {
                    header: [
                        { data: `INFERENCE :`, colSpan: '1', rowSpan: "1", textAlign: 'left' },
                        { data: `Instrument found to be ${formatCheckpointMaint(index,82)}`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    ]
                }
            ]
            valueArray['footer'] =[
                [
                    { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[index]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_approval_id ? responseData?.maint_checklist[index]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        
                ], [
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.end_date ? moment(responseData?.maint_checklist[index]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                    { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
                    { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[index]?.ins_completed_date ? moment(responseData?.maint_checklist[index]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
                ]
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });
    }else if (docNumber=="QCCB/031:F1-00"){
        responseData?.maint_checklist?.forEach((element,index) => {
            let valueArray = {};
            valueArray['data'] =  [
                {
                    response: [
                        {
                            data1: { value: 'Instrument Code :', style: { textAlign: 'left' ,colSpan: '4',} },
                            data2: { value: responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: 'Make :', style: { textAlign: 'left' ,colSpan: '2',} },
                            data4: { value: responseData?.parent !== undefined ? responseData?.parent?.make : '-', style: { textAlign: 'left' ,colSpan: '2',} },
                            data5: { value: 'Cal On :', style: { textAlign: 'left' ,colSpan: '2',} },
                            data6: { value: formatCheckpointMaint(index,0), style: { textAlign: 'left' ,colSpan: '2',} },
                        },
                        {
                            data1: { value: 'Model :', style: { textAlign: 'left' ,colSpan: '3',} },
                            data2: { value: responseData?.parent !== undefined ? responseData?.parent?.model : '-', style: { textAlign: 'left' ,colSpan: '3',} },
                            data3: { value: 'Calibration Reference No. :', style: { textAlign: 'left' ,colSpan: '3',} },
                            data4: { value: formatCheckpointMaint(index,2), style: { textAlign: 'left' ,colSpan: '3',} },
                            data5: { value: 'Due. On', style: { textAlign: 'left' ,colSpan: '2',} },
                            data6: { value: formatCheckpointMaint(index,1), style: { textAlign: 'left' ,colSpan: '2',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: `CALIBRATION FOR UATR`, colSpan: '16', rowSpan: "2", textAlign: 'left' },
                    ],
                },
                {
                    header: [
                        { data: `1.0 CONTROL OF RESOLUTION PERFORMANCE :`, colSpan: '16', rowSpan: "2", textAlign: 'left' },
                    ],
                },
                {
                    response: [
                        {
                            data2: { value: 'Polystyrene Film Lot No :', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,3), style: { textAlign: 'left' ,colSpan: '12',} },
                        },
                        {
                            data2: { value: "Absorbance at 2870 cm⁻¹", style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,4), style: { textAlign: 'left' ,colSpan: '12',} },
                        },
                        {
                            data2: { value: 'Absorbance at 2849.5 cm⁻¹', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,5), style: { textAlign: 'left' ,colSpan: '12',} },
                        },
                        {
                            data2: { value: 'Difference = ', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,6), style: { textAlign: 'left' ,colSpan: '4',} },
                            data4: { value: 'Limit :', style: { textAlign: 'left' ,colSpan: '4',} },
                            data5: { value: 'NLT 0.33', style: { textAlign: 'left' ,colSpan: '4',} },
                        },
                        {
                            data2: { value: 'Absorbance at 1589 cm⁻¹', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,7), style: { textAlign: 'left' ,colSpan: '12',} },
                        },
                        {
                            data2: { value: 'Absorbance at 1583 cm⁻¹', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,8), style: { textAlign: 'left' ,colSpan: '12',} },
                        },
                        {
                            data2: { value: 'Difference = ', style: { textAlign: 'left' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,9), style: { textAlign: 'left' ,colSpan: '4',} },
                            data4: { value: 'Limit :', style: { textAlign: 'left' ,colSpan: '4',} },
                            data5: { value: 'NLT 0.08.', style: { textAlign: 'left' ,colSpan: '4',} },
                        }
                    ]
                },
                {
                    header: [
                        { data: `2.0 VERIFICATION OF THE WAVE-NUMBER SCALE :`, colSpan: '16', rowSpan: "2", textAlign: 'left' },
                    ],
                },
                {
                    response: [
                        {
                            data2: { value: `The wave-number scale is verified using polystyrene film, which has absorption maxima at the wave numbers (in cm⁻¹) shown in the following table. Ploystyrene Film Lot No: ${formatCheckpointMaint(index,10)}`, style: { textAlign: 'left' ,colSpan: '2',} },
                        },
                    ]
                },
                {
                    header: [
                        { data: `S.No.`, colSpan: '2', rowSpan: "1", textAlign: 'center' },
                        { data: `Absorption maxima`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Acceptance criteria`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                        { data: `Remarks`, colSpan: '4', rowSpan: "1", textAlign: 'center' },
                    ],
                },
                {
                    response: [
                        {
                            data: { value: "1", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,11), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "3060.0 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,12), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                        {
                            data: { value: "2", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,13), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "2849.5 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,14), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                        {
                            data: { value: "3", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,15), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "1942.9 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,16), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                        {
                            data: { value: "4", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,17), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "1601.2 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,18), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                        {
                            data: { value: "5", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,19), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "1583.0 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,20), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                        {
                            data: { value: "6", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,21), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "1154.5 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,22), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                        {
                            data: { value: "7", style: { textAlign: 'center', colSpan: '2', }},
                            data1: { value: formatCheckpointMaint(index,23), style: { textAlign: 'center' ,colSpan: '4',} },
                            data2: { value: "1028.3 cm⁻¹ ± 1.0", style: { textAlign: 'center' ,colSpan: '4',} },
                            data3: { value: formatCheckpointMaint(index,24), style: { textAlign: 'center' ,colSpan: '4',} },
                        },
                    ]
                }
            ]
            valueArray['data3'] ={
                data: `${chklname} FOR THE MONTH OF ${responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 ? formatValue(
                    responseData?.maint_checklist && responseData?.maint_checklist?.length > 0 && responseData?.maint_checklist[index] && responseData?.maint_checklist[index]?.maint_sch_date !== '',
                    moment(responseData?.maint_checklist[index]?.maint_sch_date).local().format("MMMM").toUpperCase()
                ) : '-'}`,
                colSpan: '7'
            }
            resData.push(valueArray);
        });
    }

    // header details
    const data1 = {
        data: depname,
        colSpan: '6'
    }
    const data2 = {
        data: docNumber
    }

    return (
        <div>
      {resData?.length > 0 ?
        resData?.map((el, i) => {
          return (
            <div style={{
                pageBreakBefore: i !== 0 ? "always" : "auto",
                paddingTop: i !== 0 ? "20px" : "0px",
              }}>
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={el?.data3}
                custLogo={responseData?.customer?.cust_logo}
                malur={malur}
                medopharm={medopharm}
              />
              {instrumentCode == true && (
                <ReportTable headerData2={instCodeData} />
              )}
              {el?.tableHeaders?.length > 0 && el?.tableHeaders?.map((ele, i) => {
                 return <ReportTable
                     headerData2={ele}
                 />
             })
             }
              {el?.data?.length > 0 &&
                el?.data?.map((ele, i) => {
                  return (
                    <>
                      <ReportTable
                        headerData={ele?.header}
                        headerData2={ele?.header2}
                      />
                      <ReportTable verticalBodyData={ele?.response} />
                    </>
                  );
                })}
              {el.footer?.length > 0 &&
                el.footer?.map((ele, i) => {
                  return <ReportTable headerData2={ele} />;
                })}
            </div>
          );
        }):<ReportHearder
        data1={data1}
        data2={data2}
        data3={data3}
        custLogo={responseData?.customer?.cust_logo}
        malur={malur}
        medopharm={medopharm}
      />}      
        </div>
    )
}

export default PCAUBI2
