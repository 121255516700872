//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from 'react'
import ReportHearder from './reportHearder'
import ReportFooter from './reportFooter'
import ReportTable from './reportTable'
import moment from 'moment'

function GenexDailyReport({ responseData, filterData, chklname, docNumber, depname, malur, medopharm }) {

    const [resData, setResData] = useState([])

    // header details
    const data1 = {
        data: responseData?.parent?.site_name, 
        colSpan: '5'
    }
    const data2 = {
        data: `Date ${docNumber}`,
        colSpan: '1'
    }

    const data3 = {
        data: `${chklname} FOR THE MONTH OF ${filterData?.selectedStartDate !== '' ? filterData?.selectedStartDate : ''} ${filterData?.selectedStartDate == filterData?.selectedEndDate ? '' : (filterData?.selectedEndDate !== '' ? ' - ' + filterData?.selectedEndDate : '')}`,
        colSpan: '5'

    }

    const uniqueAssets = new Map();
    const uniqueAssets2 = new Map();
    const uniqueTime = new Map();

    let uniqueNames = []

    uniqueAssets.set("Time", { data: "Time", textAlign: 'left', rowSpan: '2', colSpan: '2'});

    responseData?.checklist?.forEach(el => {
        if (el?.asset_id && !uniqueAssets.has(el.asset_id)) {
            uniqueNames.push(el.asset_id);
            uniqueAssets.set(el.asset_id, { data: `${el?.asset_name} (${(el?.asset_id).trim().split('/').pop()})`, textAlign: 'center', data1: `${el.asset_id}`, colSpan: '2' });
            uniqueAssets2.set(el.asset_id, { data: "ON/OFF", textAlign: 'center', colSpan: '2' });
        }

        let formattedTime = moment(el?.ins_sch_date)
            .add(15, 'minutes')  // Add 30 minutes to round to the nearest hour
            .startOf('hour')     // Set to the start of the next hour
            .format("hh:mm A");

        if (formattedTime && !uniqueTime.has(formattedTime)) {
            uniqueTime.set(formattedTime, formattedTime);
        }

    });

    const tableHeaders = Array.from(uniqueAssets.values());
    const tableHeaders2 = Array.from(uniqueAssets2.values());
    let time = Array.from(uniqueTime.values());

    time = time.sort((a, b) => {
        return moment(a, "hh:mm A").diff(moment(b, "hh:mm A"));
    });

    // let startIndex = time.indexOf("07:00 AM");

    // let part1 = time.slice(startIndex, time.length); // From 7:00 AM to end
    // let part2 = time.slice(0, startIndex); // From beginning to 6:00 AM



    // // Concatenating part1 and part2 to get the desired array starting from 7:00 AM to 6:00 AM
    // time = part1.concat(part2);





    const WeighingBalance = [];

    time.forEach(currentTime => {
        const rowData = {
            time: {
                value: currentTime,
                style: { textAlign: 'left', colSpan: '2' },
            }
        }
        tableHeaders.forEach(header => {
            if (header.data !== 'Time') { // Skip the 'Time' header
                const assetName = header.data1;


                const matchingChecklistItem = responseData?.checklist.find(
                    (item) => {
                        let formattedTime = moment(item?.ins_sch_date)
                            .add(30, 'minutes')  // Add 30 minutes to round to the nearest hour
                            .startOf('hour')     // Set to the start of the next hour
                            .format("hh:mm A");

                        return formattedTime === currentTime && item.asset_id === assetName
                    }
                );

                if (matchingChecklistItem) {
                    rowData[assetName] = {
                        value: matchingChecklistItem?.checkpoints[0]?.answer !== '' ? matchingChecklistItem?.checkpoints[0]?.answer : '-',
                        style: { textAlign: 'center', colSpan: '2' },
                    };
                } else {
                    rowData[assetName] = {
                        value: '-',
                        style: { textAlign: 'center', colSpan: '2' },
                    };
                }
            }
        });

        WeighingBalance.push(rowData);
    });


    useEffect(() => {
        setResData(responseData)
    }, [responseData])


    return (
        <div>
            <ReportHearder
                data1={data1}
                data2={data2}
                data3={data3}
                custLogo={resData?.customer?.cust_logo}
                Genex={true}
            />
            <ReportTable
                headerData={tableHeaders}
                headerData2={tableHeaders2}
                verticalBodyData={WeighingBalance}
            />
        </div>
    )
}

export default GenexDailyReport
