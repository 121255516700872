import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../components/loader/loader";

import LocationHeader from "../../layouts/FA-layouts/LocationHeader";
import axios from "axios";

const CreateLocation = () => {
    const [selected, setSelected] = useState("");

    // To fetch details from redux
    const isLoading = useSelector((state) => state.user.isLoading);
    const roleData = useSelector((state) => state.user.roleData);
    // console.log(roleData);
    const subscriber_id = useSelector((state) => state.auth.subscriber_id);
    const navigate = useNavigate();
    const userid = useSelector((state) => state.auth.userid);
    // Bulk upload button with modal feature
    const inputRef = useRef();

    // To fetch details from redux
    const dispatch = useDispatch();
    const isSubmitted = useSelector((state) => state.user.isSubmitted);
    const [code, setCode] = useState()
    const [name, setName] = useState()
    const [address, setAddress] = useState()
    const submitLocationdetails = (e) => {
        let payload = {
            code,
            name,
            address,
            created_by: userid
        }


        if (!code || !name || !address) {
            alert("Please enter mandatory fields")
        } else {
            dispatch({
                type: "ADD_LOCATION",
                payload: payload,
            })
            navigate("/location")
        }
        // try {
        //     axios.post("http://localhost:8000/fa/location", payload)
        // } catch (error) {

        // }
    }

    useEffect(() => {

    }, []);

    return (
        <div>
            {isLoading && <Loader />} {/* to show loading effect */}
            <LocationHeader />
            <div className="container">
                <div className="row h-100">
                    <div className="col-md-6 offset-md-3">
                        <br />
                        <div className="mt-5 border p-4 bg-light shadow rounded">
                            {/* <form
                            action=""
                            method="post"
                           
                            target="#"
                        > */}
                            {" "}
                            <div className="formHeadings mb-2"> Add Location</div>
                            <div className="row">
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Location Code<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="lcode"
                                        className="form-control"
                                        onChange={(e) => setCode(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>Location Name</label><span className="text-danger">*</span>
                                    <input
                                        type="text"
                                        name="lname"
                                        className="form-control"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label>
                                        Address<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="laddress"
                                        className="form-control"
                                        onChange={(e) => setAddress(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mt-4 col-md-12">
                                    <button
                                        type="button"
                                        className="backButton"
                                        onClick={() => {
                                            navigate("/location");
                                        }}
                                    >
                                        Back
                                    </button>
                                    <button
                                        type="submit"
                                        className="float-end saveButton"
                                        onClick={(e) => submitLocationdetails(e)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateLocation;
