import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Commonheader from '../../layouts/common/commonHeader'
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import './switch.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Loader from '../../components/loader/loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faAngleLeft,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select";
import moment from 'moment';
import { faServices } from '../../services/service.index';
import BackButton from '../../components/BackButton/BackButton';



function Sampling() {
    const [data, setData] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const list1 = useSelector((state) => state.FA.calculation)
    const isLoading = useSelector((state) => state.FA.isLoading);
    const location = useSelector((state) => state.FA.location)
    const buCode1 = useSelector((state) => state.FA.buCode)
    const [dataLocation, setDataLocation] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [buCode, setBuCode] = useState([]);
    const [selectedBUOptions, setSelectedBUOptions] = useState([]);
    const [percentage, setPercentage] = useState(null)
    const [period, setPeriod] = useState(null)
    const [days, setDays] = useState(null)
    const [cycle, setCycle] = useState(null)
    const [criteria, setCriteria] = useState(null)
    const [removeAll, setRemoveAll] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [sampleRunData, setSampleRunData] = useState([])
    const [lastSample, setLastSample] = useState([])
    const [verfied, setVerified] = useState(false)

    const [method, setMethod] = useState(null)
    //get list of user (As of now only technicians)
    // const userData = useSelector((state) => state.user.userTechData);
    const userData = useSelector((state) => state.user.userData);
    // console.log("userData", userData)
    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [verifier, setVerifier] = useState([])

    const optionsTech = userData.map((eachData) => ({
        value: eachData._id,
        label: eachData.email,
    }));
    // console.log("optionsTech", optionsTech, userData)
    // Calculate the total number of pages
    let totalPages = 0;
    var data1List = [];
    if (data?.result !== undefined) {
        data1List = data.result;
    }
    // console.log("data1List", data1List)
    if (data?.result?.total_count !== undefined) {
        totalPages = Math.ceil(data?.result?.total_count / itemsPerPage);
    }
    const getUserDetails = () => {
        dispatch({
            type: "USER_GET_LIST",
        });
    };
    // console.log('data1List', data1List);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };
    useEffect(() => {
        getUserDetails()
        dispatch({
            type: "GET_CLASS_LIST",
            payload: [1, 50, ''],
        })
        dispatch({
            type: "GET_LOCATION_LIST",
            payload: [1, 50, ''],
        })
        dispatch({
            type: "GET_COST_CENTER_LIST",
            payload: [1, 50, '']
        })
        dispatch({
            type: "GET_BU_CODE_LIST",
        })
    }, [currentPage, itemsPerPage])

    /////// dropdown selection for BU code ////////////

    if (buCode && !removeAll) {
        var options1 = [
            {
                value: 'all',
                label: 'All',
            },
            ...buCode
        ];
    }
    if (buCode && removeAll) {
        var options1 = buCode
    }
    const handleChange1 = (selectedOptions) => {
        // console.log(selectedOptions);
        setRemoveAll(false)
        if (selectedOptions && selectedOptions.length > 0) {
            if (selectedOptions.some((option) => option.value === 'all')) {
                // console.log(options[0]);
                // "All" option is selected
                setSelectedBUOptions([options[0]]); // Clear selected options
                setBuCode([]);
            } else {
                // Remove "All" option from selected options, if present
                setSelectedBUOptions(
                    selectedOptions.filter((option) => option.value !== 'all')
                );
            }
        }
        if (selectedOptions.length > 0 && selectedOptions.some((option) => option.value !== 'all')) {
            setRemoveAll(true)
        }
        if (selectedOptions.length === 0) {
            setBuCode(buCode1?.map((eachData) => ({
                value: eachData,
                label: eachData,
            })))
        }
    };


    useEffect(() => {
        setBuCode(buCode1?.map((eachData) => ({
            value: eachData,
            label: `${eachData}`,
        })))
    }, [buCode1])

    /////// dropdown selection for location //////////////
    if (dataLocation && !removeAll) {
        var options = [
            {
                value: 'all',
                label: 'All',
            },
            ...dataLocation
        ];
    }
    if (dataLocation && removeAll) {
        var options = dataLocation
    }
    const handleChange = (selectedOptions) => {
        setRemoveAll(false)
        if (selectedOptions && selectedOptions.length > 0) {
            if (selectedOptions.some((option) => option.value === 'all')) {
                // console.log(options[0]);
                // "All" option is selected
                setSelectedOptions([options[0]]); // Clear selected options
                setDataLocation([]);
            } else {
                // Remove "All" option from selected options, if present
                setSelectedOptions(
                    selectedOptions.filter((option) => option.value !== 'all')
                );
            }

        }
        if (selectedOptions.length > 0 && selectedOptions.some((option) => option.value !== 'all')) {
            setRemoveAll(true)
        }
        if (selectedOptions.length === 0) {
            setDataLocation(location?.result?.map((eachData) => ({
                value: eachData.code,
                label: `${eachData.code} - ${eachData.name}`,
            })))
        }
    };
    // Last Sample list generated
    const getLastSampleData = async (id) => {
        // console.log("ID", id)
        await faServices.getSampleListData(id)
            .then(function (res) {
                // console.log("LAST SAMPLE", res.data.result);
                setLastSample(res.data.result);
                // data1List = res.data.result
                setLoading(false)
            })
            .catch(function (error) {
            });
    }
    useEffect(() => {

        setDataLocation(location?.result?.map((eachData) => ({
            value: eachData.code,
            label: `${eachData.code} - ${eachData.name}`,
        })))
    }, [location])


    const handleSubmit = () => {

        if (verifier.length > 0) {
            let payload = {
                assignee: verifier,
                samples: data1List,
                run_date: new Date(),
            }
            dispatch({
                type: "ADD_SAMPLE_LIST",
                payload: payload
            })
            setModalShow(false)
        } else {
            alert("Please select user for verification")
        }
    }
    const handlePercentage = (evt) => {
        const inputValue = evt.target.value;
        // console.log(inputValue, "inputValue")
        if (isNaN(inputValue) || inputValue > 100 || inputValue < 1) {
            alert('Please enter a number between 1 and 100.');
            evt.target.value = '';
            return;
        }
        setPercentage(inputValue)
    }
    const handlePeriod = (e) => {
        setPeriod(e.target.value)
    }
    const handleDays = (e) => {
        setDays(e.target.value)
    }
    // const handleCycle = (e) => {
    //     setCycle("3 Year")
    // }

    // console.log(verfied, "VERIFIED")
    const getData = () => {
        let bodyData = {
            // bu_code: selectedBUOptions?.map(item => item.value),
            // location: selectedOptions?.map(item => item.value),
            bu_code: ['all'],
            location: ['all'],
            period: parseInt(2023, 10),
            days: days,
            percent: parseInt(percentage, 10),
            // percent: 33.33,
            isRandom: true, // required by backend
            verified: verfied
        }
        // console.log(bodyData, "BODYDATA")
        if (isNaN(bodyData.period) || days === null) {
            alert(`Please fill the all mandatory details to run sample report`)
        } else {
            if (data1List.length > 0) {
                return alert("Sample already generated for this criteria")
            }
            // console.log(bodyData);
            getSample(bodyData)
        }
    }

    const getSample = async (bodyData, currentPage, itemsPerPage) => {
        setLoading(true)
        await faServices
            .getSampleData(bodyData, currentPage, itemsPerPage)
            .then(function (res) {
                // console.log(res);
                setData(res.data);
                setLoading(false)
            })
            .catch(function (error) {
            });
    };


    //  new API call - ajit add code for tis in UI
    const getSampleRunData = async () => {
        setModalShow(true)

        await faServices
            .getSampleRunData({
                run_date: moment().utc().format()
            })
            .then(function (res) {
                // console.log("11111", res);
                setSampleRunData(res.data)
                //     [
                //         {
                //             "_id": "651facc20ff3a13833406270",
                //             "assignee": [
                //                 "645cd1294a70c914079842d5"
                //             ],
                //             "samples": [
                //                 "6516561476d708ee8963fd21",
                //                 "6516561476d708ee8963fd27",
                //                 "6516561476d708ee8963fd1f",
                //                 "6516561476d708ee8963fd0f",
                //                 "6516561476d708ee8963fd17",
                //                 "6516561476d708ee8963fcfb",
                //                 "6516561476d708ee8963fcf9",
                //                 "6516561476d708ee8963fcf7",
                //                 "6516561476d708ee8963fce9",
                //                 "6516561476d708ee8963fcff",
                //                 "6516561676d708ee8963fd87",
                //                 "6516561676d708ee8963fd89",
                //                 "6516561676d708ee8963fd83",
                //                 "6516561676d708ee8963fd85",
                //                 "6516561676d708ee8963fd79",
                //                 "6516561376d708ee8963fcc8",
                //                 "6516561376d708ee8963fcdc",
                //                 "6516561576d708ee8963fd6b",
                //                 "6516561476d708ee8963fd29",
                //                 "6516561576d708ee8963fd3d",
                //                 "6516561576d708ee8963fd49",
                //                 "6516561576d708ee8963fd53",
                //                 "6516561476d708ee8963fd07",
                //                 "6516561476d708ee8963fd0b",
                //                 "6516561576d708ee8963fd59"
                //             ],
                //             "__v": 0,
                //             "createdAt": "2023-10-06T06:44:18.852Z",
                //             "updatedAt": "2023-10-06T06:44:18.852Z",
                //             "run_date": "2023-10-06T06:44:18.852Z"
                //         },
                //         {
                //             "_id": "651facc20ff3a13833406270",
                //             "assignee": [
                //                 "645cd1294a70c914079842d5"
                //             ],
                //             "samples": [
                //                 "6516561476d708ee8963fd21",
                //                 "6516561476d708ee8963fd27",
                //                 "6516561476d708ee8963fd1f",
                //                 "6516561476d708ee8963fd0f",
                //                 "6516561476d708ee8963fd17",
                //                 "6516561476d708ee8963fcfb",
                //                 "6516561476d708ee8963fcf9",
                //                 "6516561476d708ee8963fcf7",
                //                 "6516561476d708ee8963fce9",
                //                 "6516561476d708ee8963fcff",
                //                 "6516561676d708ee8963fd87",
                //                 "6516561676d708ee8963fd89",
                //                 "6516561676d708ee8963fd83",
                //                 "6516561676d708ee8963fd85",
                //                 "6516561676d708ee8963fd79",
                //                 "6516561376d708ee8963fcc8",
                //                 "6516561376d708ee8963fcdc",
                //                 "6516561576d708ee8963fd6b",
                //                 "6516561476d708ee8963fd29",
                //                 "6516561576d708ee8963fd3d",
                //                 "6516561576d708ee8963fd49",
                //                 "6516561576d708ee8963fd53",
                //                 "6516561476d708ee8963fd07",
                //                 "6516561476d708ee8963fd0b",
                //                 "6516561576d708ee8963fd59"
                //             ],
                //             "__v": 0,
                //             "createdAt": "2023-10-06T06:44:18.852Z",
                //             "updatedAt": "2023-10-06T06:44:18.852Z",
                //             "run_date": "2023-10-06T06:44:18.852Z"
                //         }
                //     ]
                // )
            })
            .catch(function (error) {
            });
    };

    const handleverifierID = (val) => {
        // if (val !== null) {
        const key = 'value';
        const data = val.map((obj) => obj[key]);
        // console.log("VERIFIER", data);
        setVerifier(data)
        //     // console.log(data);
        //     setItems({
        //         ...items,
        //         approverID: data,
        //     });
        // }
    };

    //// to download all the data in excel //////
    function generateExcelFile(data) {
        // Define the keys you want to exclude
        const keysToExclude = ['_id'];

        // console.log("data", data)
        // data.map((date) => { date.installation_date = moment(date?.installation_date).format('DD-MM-YYYY') })

        // data.map((date) => { date.dop = moment(date?.dop).format('DD-MM-YYYY') })


        // data.map((date) => { date.last_verified = moment(date?.last_verified).format('DD-MM-YYYY') })



        // Create a modified data array without the specified keys
        // const modifiedData = data.map((item) => {
        //     const newItem = { ...item };
        //     keysToExclude.forEach((key) => {
        //         delete newItem[key];
        //     });
        //     return newItem;
        // });

        const modifiedData = data.map(({
            // bu_code, gl_code, asset_id, asset_name, nature_of_asset, asset_class, asset_class_name, cost_center, cost_center_name, location, location_name,
            // supplier_name, dop, doi, life, quantity, last_verified, description
            asset_id, asset_name, nature_of_asset, class_code, class_name, cost_center, cost_center_name, location_code, location_name,
            installation_date, life, quantity, last_verified
        }) => ({
            // bu_code, gl_code, asset_id, asset_name, nature_of_asset, asset_class, asset_class_name, cost_center, cost_center_name, location, location_name,
            // supplier_name, dop, doi, life, quantity, last_verified, description
            asset_id, asset_name, nature_of_asset, class_code, class_name, cost_center, cost_center_name, location_code, location_name,
            installation_date, life, quantity, last_verified
        }));

        const excelData = modifiedData.map(date => ({
            ...date,
            installation_date: date.installation_date ? moment(date.installation_date).format('DD-MM-YYYY') : null,
            last_verified: date.last_verified ? moment(date.last_verified).format('DD-MM-YYYY') : 'Not Verified',
        }));

        let customHeader = [[
            // "BU Code",
            // "GL Code",
            "Asset ID",
            "Asset Name",
            "Nature of Asset",
            "Asset Class Code",
            "Asset Class Name",
            "Cost Center Code",
            "Cost Center Name",
            "Location Code",
            "Location Name",
            // "Supplier Name",
            // "Date of Purchase",
            "Date of Installation",
            "Useful Life in Months",
            "Quantity",
            "Last Verification Date",
            // "Origin",
            // "Description",
        ]]
        // const modifiedData = [customHeader, ...data];

        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();
        // console.log("worksheet", modifiedData);
        // data = data.map((el) => el.monthWiseDep.map(row => Object.fromEntries(Object.entries(row).map(([k, v]) => ([k, Array.isArray(v) ? v.join(",") : v])))))
        XLSX.utils.sheet_add_aoa(worksheet, customHeader);
        XLSX.utils.sheet_add_json(worksheet, excelData, { origin: 'A2', skipHeader: true });
        //const fixedData = usersData.map(row => Object.fromEntries(Object.entries(row).map(([k,v]) => ([k, Array.isArray(v) ? v.join(",") : v]))))
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `ZV_Sampling_${new Date().toDateString()}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    //// to download List already published data //////
    function generateExcelFileList(data) {
        // Define the keys you want to exclude
        // const keysToExclude = ['_id'];

        // console.log("data", data)

        // data.map((date) => { date.installation_date = moment(date?.installation_date).format('DD-MM-YYYY') })

        // data.map((date) => { date.dop = moment(date?.dop).format('DD-MM-YYYY') })

        // data.map((date) => { date.last_verified = moment(date?.last_verified).format('DD-MM-YYYY') })



        // Create a modified data array without the specified keys
        const modifiedData = data.map(({
            // bu_code, gl_code, asset_id, asset_name, nature_of_asset, asset_class, asset_class_name, cost_center, cost_center_name, location, location_name,
            // supplier_name, dop, doi, life, quantity, last_verified, description
            asset_id, asset_name, nature_of_asset, asset_class, asset_class_name, cost_center, cost_center_name, location, location_name,
            installation_date, life, quantity, last_verified
        }) => ({
            // bu_code, gl_code, asset_id, asset_name, nature_of_asset, asset_class, asset_class_name, cost_center, cost_center_name, location, location_name,
            // supplier_name, dop, doi, life, quantity, last_verified, description
            asset_id, asset_name, nature_of_asset, asset_class, asset_class_name, cost_center, cost_center_name, location, location_name,
            installation_date, life, quantity, last_verified
        }));
        let customHeader = [[
            // "BU Code",
            // "GL Code",
            "Asset ID",
            "Asset Name",
            "Nature of Asset",
            "Asset Class Code",
            "Asset Class Name",
            "Cost Center Code",
            "Cost Center Name",
            "Location Code",
            "Location Name",
            // "Supplier Name",
            // "Date of Purchase",
            "Date of Installation",
            "Useful Life in Months",
            "Quantity",
            "Last Verification Date",
            // "Origin",
            // "Description",
        ]]
        // const modifiedData = [customHeader, ...data];

        const excelData = modifiedData.map(date => ({
            ...date,
            installation_date: date.installation_date ? moment(date.installation_date).format('DD-MM-YYYY') : null,
            last_verified: date.last_verified ? moment(date.last_verified).format('DD-MM-YYYY') : "Not Verified",
        }));

        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();
        // console.log("worksheet", modifiedData);
        // data = data.map((el) => el.monthWiseDep.map(row => Object.fromEntries(Object.entries(row).map(([k, v]) => ([k, Array.isArray(v) ? v.join(",") : v])))))
        XLSX.utils.sheet_add_aoa(worksheet, customHeader);
        XLSX.utils.sheet_add_json(worksheet, excelData, { origin: 'A2', skipHeader: true });
        //const fixedData = usersData.map(row => Object.fromEntries(Object.entries(row).map(([k,v]) => ([k, Array.isArray(v) ? v.join(",") : v]))))
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `ZV_Sampling_${new Date().toDateString()}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    // const getDataToDownload = async (bodyData, itemsPerPage, totalPages) => {
    //     var currentPage = 1
    //     var requiredData = itemsPerPage * totalPages
    //     await faServices
    //         .getSampleData(bodyData, currentPage, requiredData)
    //         .then(function (res) {
    //             console.log(res);
    //             generateExcelFile(res.data.result);
    //         })
    //         .catch(function (error) {
    //         });
    // };
    // console.log("lastSample", lastSample)
    // console.log("data1List", data1List)

    const handleYearSelection = (e) => {
        // if(e.target.value === '1'){}
        // console.log((100 / e.target.value).toFixed(2), "QQQ")
        setPercentage((100 / e.target.value).toFixed(2));
    }

    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])

    return (
        <div>
            {loading && <Loader />}
            <Commonheader />
            <div >
                {/* class="container bg-white" */}
                <div class="container bg-white" style={{ display: "flex", alignItems: "center", paddingTop: "5rem" }}>
                    <p class="fs-4 fw-semibold">Sample Based Physical Asset Verification</p>
                </div>
                <BackButton />
                <div class="container bg-white" >
                    {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select Period <span className="text-danger">*</span> : </label>
                        <Form.Select onChange={handlePeriod} style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm">
                            <option value=" "> Select</option>
                            <option value='2023'>2023</option>
                        </Form.Select>
                    </div> */}
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Choose Sampling Type<span className="text-danger">*</span> : </label>
                        <Form.Select
                            onChange={(e) => setMethod(e.target.value)}
                            style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm">
                            <option value=""> Select Sampling Type</option>
                            <option value='year'>Year Wise</option>
                            <option value='percentage'>Percentage Wise</option>
                            {/* <option value='2023'>3 Year</option> */}
                        </Form.Select>
                    </div>
                    {method === 'year' &&
                        <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                            <label for="assetId" class="form-label bg-white">Select Audit Cycle <span className="text-danger">*</span> : </label>
                            <Form.Select
                                onChange={(e) => handleYearSelection(e)}
                                style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm">
                                <option value=""> Select</option>
                                <option value='1'>1 Year</option>
                                <option value='2'>2 Year</option>
                                <option value='3'>3 Year</option>
                            </Form.Select>
                        </div>
                    }
                    <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white" style={{ width: '100%' }}>Percentage (%) <span className="text-danger">*</span> : </label>
                        <input
                            className="form-control"
                            name="quantity"
                            value={percentage}
                            type="text"
                            onChange={(e) => handlePercentage(e)}
                            min={0}
                            maxLength={3}
                            max={100}
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            disabled={method === 'year'}
                        ></input>
                    </div>

                    {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select BU Code <span className="text-danger">*</span> : </label>
                        <CreatableSelect
                            isMulti
                            isClearable
                            formatCreateLabel={() => undefined}
                            options={options1}
                            onChange={handleChange1}
                            placeholder="Select BU Code"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '310px !important',
                                    border: '0.5px solid grey',
                                    paddingLeft: '0.5rem',
                                }),
                            }}
                        />
                    </div> */}
                    {/* <div class="col-md-6 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Select Location <span className="text-danger">*</span> : </label>
                        <CreatableSelect
                            isMulti
                            isClearable
                            formatCreateLabel={() => undefined}
                            options={options}
                            onChange={handleChange}
                            placeholder="Select Location"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '310px !important',
                                    border: '0.5px solid grey',
                                    paddingLeft: '0.5rem',
                                }),
                            }}
                        />
                    </div> */}


                    <div class="col-md-12 bg-white" style={{ display: "flex", alignItems: "center", paddingRight: "4rem" }}>
                        <div style={{ width: '50%', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                            <label for="assetId" class="form-label bg-white">Criteria <span className="text-danger">*</span> : </label>
                            <Form.Select style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm" disabled>
                                {/* <option value=" "> Select Criteria</option>*/}
                                <option value="qantity"> Percentage By Quantity</option>
                                {/* <option value="percentage"> Percentage By Value</option> */}
                            </Form.Select>
                        </div>
                        {/* {
                            <div style={{ width: '30%', marginLeft: '5rem', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                <label for="assetId" class="form-label bg-white" style={{ width: '100%' }}>Percentage (%) <span className="text-danger">*</span> : </label>
                                <input
                                    className="form-control"
                                    name="quantity"
                                    // value={percentage}
                                    type="text"
                                    onChange={() => handlePercentage}
                                    min={0}
                                    maxLength={3}
                                    max={100}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                // disabled
                                ></input>
                            </div>
                        } */}
                    </div>
                    {/* <div class="col-md-12 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", width: '50%' }}>
                        <label class="form-label bg-white">Report Run Date :</label>
                        <input type="text" className="form-control" style={{ width: "50%", border: "none", paddingLeft: "0.5rem", height: "36px" }} value={moment().local().format("Do MMM YYYY")} />
                    </div> */}
                    <div class="col-md-12 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", width: '50%' }}>
                        <label for="assetId" class="form-label bg-white">Audit to be Completed (Days) <span className="text-danger">*</span> : </label>
                        <Form.Select onChange={handleDays} style={{ width: "50%", border: "0.5px solid grey", borderRadius: "5px", height: "36px" }} size="sm">
                            <option value=" "> Select</option>
                            {Array.from({ length: 100 }, (_, index) => (
                                <option key={index + 1} value={index + 1}>
                                    {index + 1}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", alignItems: "center", paddingRight: "2rem", }}>
                        <label for="assetId" class="form-label bg-white">Include Verified Assets For Sampling<span className="text-danger">*</span> : </label>
                        <input type="checkbox"
                            onChange={(e) => setVerified(e.target.checked)}
                            style={{ border: "0.5px solid grey", borderRadius: "5px", height: "16px", width: "16px", marginLeft: '4rem' }} />
                    </div>
                    <div class="col-md-6 bg-white" style={{ display: "flex", paddingRight: "2rem", marginBottom: "0.5rem", justifyContent: "space-between" }}>
                        <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => getData()}>Generate Sample List</Button>
                        <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => getLastSampleData(userData[0]?._id)}>Last Published List</Button>

                        {data &&
                            <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => generateExcelFile(data?.result)}>Download Sample List</Button>
                        }

                        {/* {data &&
                            <Button variant="outline-primary" style={{ margin: "1rem", color: 'red' }} onClick={() => getData()}>Publish Sample List</Button>
                        } */}
                    </div>
                    {/* <div>

                        <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => getLastSampleData(userData[0]?._id)}>Last Published List</Button>
                    </div> */}
                    {data &&
                        <div class="col-md-9 bg-white" style={{ paddingRight: "2rem", marginBottom: "0.5rem" }}>
                            <table className="table-bordered"  >
                                <tr>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "left", paddingLeft: "20px" }}>
                                        Details
                                    </th>
                                    <td style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }}>
                                        Number
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ paddingLeft: "20px" }}>
                                        Total Number of Assets
                                    </th>
                                    <td style={{ textAlign: 'center' }}>
                                        {data?.allAssets_count}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ paddingLeft: "20px" }}>
                                        Total Number of Assets Already Verified
                                    </th>
                                    <td style={{ textAlign: 'center' }}>
                                        {data?.allVerifiedAssets_count}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ paddingLeft: "20px" }}>
                                        Total Number of Assets to be Verified
                                    </th>
                                    <td style={{ textAlign: 'center' }}>
                                        {data?.allNonVerifiedAssets_count}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ paddingLeft: "20px" }}>
                                        Total Number of Assets for Sampling Based on Selected Criteria
                                    </th>
                                    <td style={{ textAlign: 'center' }}>
                                        {data?.result?.length}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    }
                </div>

                {data && <div style={{ backgroundColor: "white", marginTop: "1rem", borderRadius: "0.5rem", padding: "1rem" }}>
                    <p style={{ fontSize: "16px", fontWeight: "bolder" }}>Sample Based Physical Asset Verification</p>
                    <div className='table-responsive'
                        style={{
                            overflow: "scroll",
                            minHeight: "400px"
                        }}>
                        <table className="table-bordered" >
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>S. No</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>BU Code</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>GL Code</th> */}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset ID</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Asset Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'> Nature of Asset</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset Class Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset Class Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost Center Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost Center Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Location Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Location Name</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Supplier Name</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Date of Purchase</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Date of Installation</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Useful Life in Months</th> */}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Quantity</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Last Verification Date</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Origin</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Description</th> */}
                                </tr>
                            </thead>


                            {data1List !== null && data1List.length > 0 ? (
                                data1List.map((eachData, index) => {
                                    return (
                                        <tbody key={eachData._id} style={{ padding: "15px" }}>
                                            <tr
                                                style={{ padding: "20px" }}
                                            >
                                                {/* function Double click on row to view all asset atribute other than availbe on table.  */}
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {(currentPage - 1) * itemsPerPage + (index + 1)}
                                                </td>
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.bu_code}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.gl_code}
                                                </td> */}
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.asset_id}
                                                </td>
                                                <td style={{ textAlign: "left", padding: "8px" }}>
                                                    {eachData.asset_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.nature_of_asset}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.class_code}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.class_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.cost_center}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.cost_center_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.location_code}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.location_name}
                                                </td>
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.supplier_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.dop ? moment(eachData.acquisition_date).utc().format("Do MMM YYYY") : null}
                                                </td> */}
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.installation_date ? moment(eachData.installation_date).utc().format("Do MMM YYYY") : null}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.useful_life}
                                                </td> */}
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.quantity}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData?.last_verified ? moment(eachData.last_verified).utc().format("Do MMM YYYY") : "Not Verified"}
                                                </td>
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.description}
                                                </td> */}
                                            </tr>
                                        </tbody>
                                    );
                                })
                            ) : (
                                <tbody>
                                    <tr style={{ textAlign: "center", height: "75px" }}>
                                        <td colSpan="9">No Data Available</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <div>

                            {/* pagination */}
                            {/* {data1List !== null && data1List.length > 0 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                        padding: "10px",
                                    }}
                                >
                                    <div className="mb-6">
                                        <label>Items per page:</label> &nbsp;
                                        <select
                                            onChange={(e) => handleItemsPerPageChange(e.target.value)}
                                            style={{ borderRadius: "8px", cursor: "pointer" }}
                                        >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>
                                    <div style={{ paddingRight: "25px" }} className="pagination">
                                        <button
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                border: "none",
                                                backgroundColor: "#fff",
                                            }}
                                            disabled={currentPage === 1}
                                            onClick={handleFirstPage}
                                        >
                                            {currentPage === 1 ? (
                                                <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                                            ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                                        </button>
                                        <button
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                border: "none",
                                                backgroundColor: "#fff",
                                            }}
                                            disabled={currentPage === 1}
                                            onClick={handlePrev}
                                        >
                                            {/* Prev */}
                            {/* {currentPage === 1 ? (
                                                <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                                            ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                                        </button>
                                        <span style={{ padding: "10px" }}>
                                            Showing {currentPage} of {totalPages} pages
                                        </span>
                                        <button
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                border: "none",
                                            }}
                                            disabled={currentPage === totalPages}
                                            onClick={handleNext}
                                        >
                                            {currentPage === totalPages ? (
                                                <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                                            ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                                        </button>
                                        <button
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                border: "none",
                                            }}
                                            disabled={currentPage === totalPages}
                                            onClick={handleLastpage}
                                        >
                                            {currentPage === totalPages ? (
                                                <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                                            ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                                        </button>
                                    </div>
                                </div>
                            ) : null} */}
                        </div>


                        {data &&
                            // <Button variant="outline-primary" style={{ margin: "1rem", color: 'red', float: "right" }} onClick={() => getData()}>Publish Sample List</Button>
                            <Button variant="outline-primary" style={{ margin: "1rem", float: "right" }} onClick={() => getSampleRunData()}>Publish Sample List</Button>
                        }


                        <Modal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Asset Verification Assign
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <h4>Centered Modal</h4> */}
                                <>
                                    <div class="col-md-9 bg-white" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "2rem", }}>
                                        <label for="assetId" class="form-label bg-white">Assign user - Verify Asset<span className="text-danger">*</span> : </label>
                                        <CreatableSelect
                                            isMulti
                                            isClearable
                                            formatCreateLabel={() => undefined}
                                            options={optionsTech}
                                            onChange={(option) => {
                                                handleverifierID(option);
                                            }}
                                            placeholder="Select User"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '310px !important',
                                                    border: '0.5px solid grey',
                                                    paddingLeft: '0.5rem',
                                                }),
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <br />

                                    <div>
                                        <div class="col-md-12 bg-white" style={{ paddingRight: "2rem", marginBottom: "0.5rem" }}>
                                            <h4 style={{ textAlign: 'center' }}>Hisory of Published Data</h4>
                                            <table className="table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ paddingLeft: "20px", textAlign: 'center' }}>
                                                            Last Sample Run Date
                                                        </th>
                                                        <th style={{ paddingLeft: "20px", textAlign: 'center' }}>
                                                            Number of Sample Considered
                                                        </th>
                                                        <th style={{ paddingLeft: "20px", textAlign: 'center' }}>
                                                            Assignee
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {sampleRunData.map((el) =>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: 'center', padding: "8px" }}>
                                                                {el?.run_date ? moment(el?.run_date).local().format('Do MMMM YYYY') : null}
                                                            </td>
                                                            <td style={{ textAlign: 'center', padding: "8px" }}>
                                                                {el?.samples?.length}
                                                            </td>
                                                            <td style={{ textAlign: 'center', padding: "8px" }}>
                                                                {el?.assignee}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>


                                    </div>
                                </>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button >Close</Button> */}
                                <button
                                    onClick={() => {
                                        window.confirm(
                                            "Are you sure you wish to initiate this verification?"
                                        ) && handleSubmit();
                                    }}
                                    className="float-end saveButton mt-5"
                                >
                                    Save
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                </div>}
                {/* Last verification List */}
                {lastSample.length > 0 &&
                    <div style={{ display: "block" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginLeft: "2rem", marginTop: '0.7rem' }}>Last Published List</p>
                            <span onClick={() => setLastSample([])} style={{ float: "right", margin: "0.7rem 2rem", border: "1px solid", padding: "0.2rem 0.5rem", borderRadius: "0.5rem", cursor: "pointer" }}>Close</span>
                        </div>
                        <table className="table-bordered" >
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>S. No</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>BU Code</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>GL Code</th> */}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset ID</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white" }} scope='col'>Asset Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'> Nature of Asset</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset Class Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Asset Class Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost Center Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Cost Center Name</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Location Code</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Location Name</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Supplier Name</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Date of Purchase</th> */}
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Date of Installation</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Useful Life in Months</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Quantity</th>
                                    <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Last Verification Date</th>
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Origin</th> */}
                                    {/* <th style={{ backgroundColor: "steelblue", color: "white", textAlign: "center" }} scope='col'>Description</th> */}
                                </tr>
                            </thead>


                            {lastSample !== null && lastSample.length > 0 ? (
                                lastSample.map((eachData, index) => {
                                    return (
                                        <tbody key={eachData._id} style={{ padding: "15px" }}>
                                            <tr
                                                style={{ padding: "20px" }}
                                            >
                                                {/* function Double click on row to view all asset atribute other than availbe on table.  */}
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {(currentPage - 1) * itemsPerPage + (index + 1)}
                                                </td>
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.bu_code}
                                                </td> */}
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.gl_code}
                                                </td> */}
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.asset_id}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.asset_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.nature_of_asset}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.asset_class}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.asset_class_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.cost_center}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.cost_center_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.location}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.location_name}
                                                </td>
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.supplier_name}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.acquisition_date ? moment(eachData.acquisition_date).utc().format("Do MMM YYYY") : null}
                                                </td> */}
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.installation_date ? moment(eachData.installation_date).utc().format("Do MMM YYYY") : null}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.useful_life}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.quantity}
                                                </td>
                                                <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {
                                                        eachData?.last_verified ? moment(eachData?.last_verified).utc().format("Do MMM YYYY") : "Not Verified"
                                                        // {moment(eachData?.last_verified).utc().format("Do MMM YYYY")}
                                                    }
                                                </td>
                                                {/* <td style={{ textAlign: "center", padding: "8px" }}>
                                                    {eachData.description}
                                                </td> */}
                                            </tr>
                                        </tbody>
                                    );
                                })
                            ) : (
                                <tbody>
                                    <tr style={{ textAlign: "center", height: "75px" }}>
                                        <td colSpan="9">No Data Available</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <Button variant="outline-primary" style={{ margin: "1rem", }} onClick={() => generateExcelFileList(lastSample)}>Download List</Button>
                    </div>
                }
            </div >
        </div >
    )
}

export default Sampling