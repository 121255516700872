/* Feature - Bulkupload of site

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";

function Bulkupload(props) {
  const navigate = useNavigate();
  // To fetch details from redux
  const custData = useSelector((state) => state.customer.custData);
  const isLoading = useSelector((state) => state.site.isLoading);
  const userid = useSelector((state) => state.auth.userid);

  const dispatch = useDispatch();

  const [items, setItems] = useState({
    cust_id: "",
    site: [],
    contact: [],
  });

  // to disable button baes on file input //
  const [isDisabled, setIsDisabled] = useState(true);

  // to disable button based on selection of customer input //
  const [disabled, setDisabled] = useState(true);

  // to disable button based on selection of wrong worksheet name //
  const [wsdisabled, setWSDisabled] = useState(false);

  // to disable button based on empty file input //
  const [disEmptyArray, setDisEmptyArray] = useState(false);

  // Read the excel sheet data and convert it to JSON file using following code

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" }); // wb - workbook

        const wsname = wb.SheetNames; // wsname - worksheet name
        // console.log('wsname', wsname);

        const ws_site_upload = wb.Sheets["Site"];
        // console.log('ws', ws_site_upload);

        const ws_contacts = wb.Sheets["Contacts"];
        // console.log('ws1', ws_contacts);

        const site_header = [
          [
            "SrNo",
            "site_name",
            "website",
            "address",
            "region",
            "city",
            "country",
            "state",
            "zip_code",
            "criticality_rating",
            "created_by",
          ],
        ];

        const site_upload_data = XLSX.utils.sheet_to_json(ws_site_upload, {
          defval: null,
        });

        // console.log('data', site_upload_data);

        const CreateWStoMearge = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMearge, site_header);

        const MeargehederDataSite = XLSX.utils.sheet_add_json(
          CreateWStoMearge,
          site_upload_data,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        // console.log('hederData', MeargehederDataSite);

        const MeagedDataSite = XLSX.utils.sheet_to_json(MeargehederDataSite, {
          defval: null,
        });
        MeagedDataSite.forEach(function (obj) {
          obj.created_by = null
        }, MeagedDataSite);

        MeagedDataSite.map((eachdata) => {

          if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
          }

          const createdby = MeagedDataSite

          const index = createdby.findIndex(object => {
            return object.created_by === null;
          });

          // console.log(index);

          if (index !== -1) {
            createdby[index].created_by = enteredCreatedBy
          }

        })

        // console.log(MeagedDatacontact);
        // console.log('MeagedData', MeagedDataSite);

        const contact_header = [
          [
            "SrNo",
            "site_name",
            "first_name",
            "middle_name",
            "last_name",
            "email_id",
            'country_code',
            "mobile_number",
            "designation",
            "linkedin_profile",
            "type",
            "created_by",
          ],
        ];

        const contact_upload_data = XLSX.utils.sheet_to_json(ws_contacts, {
          defval: null,
        });

        // console.log('data', contact_upload_data);

        const CreateWStoMeargecontact = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMeargecontact, contact_header);

        const MeargehederDatacontact = XLSX.utils.sheet_add_json(
          CreateWStoMeargecontact,
          contact_upload_data,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        // console.log('hederData contact', MeargehederDatacontact);

        const MeagedDatacontact = XLSX.utils.sheet_to_json(
          MeargehederDatacontact,
          { defval: null }
        );

        MeagedDatacontact.forEach(function (obj) {
          obj.created_by = null
        }, MeagedDatacontact);

        MeagedDatacontact.map((eachdata) => {

          if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
          }

          const createdby = MeagedDatacontact

          const index = createdby.findIndex(object => {
            return object.created_by === null;
          });

          // console.log(index);

          if (index !== -1) {
            createdby[index].created_by = enteredCreatedBy
          }

        })
        // console.log('MeagedDatacontact', MeagedDatacontact);

        //////////////////////////// client side validation - sheet name ///////////////////////////////////////

        if (wsname.includes("Site") && wsname.includes("Contacts")) {
        } else {
          setWSDisabled(true);
          result = "error";
          alert(
            `Please verify the uploaded sheet. \n It doesn't includes following workbook: \n 1. Site - work book with the name - "Site" \n 2. Contacts - work book with the name of - "Contacts"  \n \n\n Note: Either you may have changed the work book name or you are not uploading correct format`
          );
        }

        //////////////////////////// client side validation -  ///////////////////////////////////////

        MeagedDataSite.forEach(function (obj) {
          delete obj.SrNo;
        });

        MeagedDataSite.forEach(function (obj) {
          // console.log(obj.inspection_name);
          obj.criticality_rating = null;
          obj.criticality_rating = "";
        }, MeagedDataSite);

        MeagedDatacontact.forEach(function (obj) {
          delete obj.SrNo;
        });

        MeagedDatacontact.forEach(function (obj) {
          // console.log(obj.inspection_name);
          obj.type = null;
          obj.type = "";
        }, MeagedDatacontact);

        var result = "";

        MeagedDataSite.map((eachdata) => {
          if (eachdata.site_name == null) {
            // console.log(eachdata);
            // console.log(MeagedDataSite.indexOf(eachdata));
            var site_name_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.website == null) {
            var website_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.address == null) {
            var address_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.region == null) {
            var region_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.city == null) {
            var city_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.country == null) {
            var country_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.state == null) {
            var state_index = MeagedDataSite.indexOf(eachdata);
          }
          if (eachdata.zip_code === null) {
            var zip_code_index = MeagedDataSite.indexOf(eachdata);
          }
          if (
            eachdata.zip_code !== null &&
            eachdata.zip_code.toString().length !== 6
          ) {
            var zip_code_valid_index = MeagedDataSite.indexOf(eachdata);
          }

          result += validatelegalName();
          result += validateWebsite();
          result += validateAddress();
          result += validateRegion();
          result += validateCity();
          result += validateCountry();
          result += validateState();
          result += validateZipCode();
          result += validatelengthZipCode();

          function validatelegalName() {
            if (eachdata.site_name == null)
              return `Sl.No: ${site_name_index + 1
                } - Site name is mandetory field (sheet name - Site).\n`;
            return "";
          }

          function validateWebsite() {
            if (eachdata.website == null)
              return `Sl.No: ${website_index + 1
                } - Website name is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validateAddress() {
            if (eachdata.address == null)
              return `Sl.No: ${address_index + 1
                } - Address is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validateRegion() {
            if (eachdata.region == null)
              return `Sl.No: ${region_index + 1
                } - Region is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validateCity() {
            if (eachdata.city == null)
              return `Sl.No: ${city_index + 1
                } - City is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validateCountry() {
            if (eachdata.country == null)
              return `Sl.No: ${country_index + 1
                } - Country is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validateState() {
            if (eachdata.state == null)
              return `Sl.No: ${state_index + 1
                } - State is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validateZipCode() {
            if (eachdata.zip_code === null)
              return `Sl.No: ${zip_code_index + 1
                } - Zipcode is mandetory field (sheet name - Site).\n`;
            return "";
          }
          function validatelengthZipCode() {
            if (
              eachdata.zip_code !== null &&
              eachdata.zip_code.toString().length !== 6
            )
              return `Sl.No: ${zip_code_valid_index + 1
                } - Please enter valid Zipcode (sheet name - Site).\n`;
            return "";
          }
        });

        const siteData = MeagedDataSite.map((x) =>
          x.criticality_rating === null ? { ...x, criticality_rating: "" } : x
        );

        resolve(MeagedDataSite);

        MeagedDatacontact.map((eachdata) => {
          if (eachdata.site_name == null) {
            // console.log(eachdata);
            // console.log(MeagedDataSite.indexOf(eachdata));
            var site_name_index = MeagedDataSite.indexOf(eachdata);
          }

          if (eachdata.first_name == null) {
            // console.log(eachdata);
            // console.log(MeagedDatacontact.indexOf(eachdata));
            var first_name_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.last_name == null) {
            var last_name_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.email_id == null) {
            var email_id_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.country_code === null) {
            var country_code_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.mobile_number === null) {
            var mobile_number_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (
            eachdata.mobile_number !== null &&
            eachdata.mobile_number.toString().length !== 10
          ) {
            var mobile_number_lenght_index =
              MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.designation == null) {
            var designation_index = MeagedDatacontact.indexOf(eachdata);
          }

          result += validatelegalName();
          result += validatefirstName();
          result += validateLastName();
          result += validateEmailID();
          result += validateCountryCode();
          result += validateMobileNumber();
          result += validatelenghtMobileNumber();
          result += validateDesignation();

          function validatelegalName() {
            if (eachdata.site_name == null)
              return `Sl.No: ${site_name_index + 1
                } - Site name is mandetory field (sheet name - Contacts).\n`;
            return "";
          }
          function validatefirstName() {
            if (eachdata.first_name == null)
              return `Sl.No: ${first_name_index + 1
                } - First name is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateLastName() {
            if (eachdata.last_name == null)
              return `Sl.No: ${last_name_index + 1
                } - Last name is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateEmailID() {
            if (eachdata.email_id == null)
              return `Sl.No: ${email_id_index + 1
                } - Email ID is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateCountryCode() {
            if (eachdata.country_code === null)
              return `Sl.No: ${country_code_index + 1
                } - Country Code is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateMobileNumber() {
            if (eachdata.mobile_number === null)
              return `Sl.No: ${mobile_number_index + 1
                } - Mobile Number is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validatelenghtMobileNumber() {
            if (
              eachdata.mobile_number !== null &&
              eachdata.mobile_number.toString().length !== 10
            )
              return `Sl.No: ${mobile_number_lenght_index + 1
                } - Please enter valid mobile number (sheet name - Contacts). \n`;
            return "";
          }
          function validateDesignation() {
            if (eachdata.designation == null)
              return `Sl.No: ${designation_index + 1
                } - Designation is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
        });

        const contactData = MeagedDatacontact.map((x) =>
          x.type === null ? { ...x, type: "" } : x
        );

        resolve(MeagedDatacontact);

        items.site = siteData;
        items.contact = contactData;

        // console.log('items.site', items.site);
        // console.log('items.contact', items.contact);

        setItems({
          ...items,
          site: siteData,
          contact: contactData,
        });

        if (MeagedDataSite.length === 0 && MeagedDatacontact.length === 0) {
          alert("Please enter atleast one site");
          setDisEmptyArray(true);
        }

        if (result == "") return setIsDisabled(false);
        alert(
          "Please resolve the following error in the attachment:\n\n" + result
        );
        return false;
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      // console.log('data', data)
    });
  };

  const getCustDetails = () => {
    dispatch({
      type: "CUST_GET_LIST",
    });
  };

  useEffect(() => {
    // console.log('siteData', siteData);
    if (custData.length === 0) {
      getCustDetails();
    }
  }, [custData]);

  const handleCustData = (string) => {
    if (string.length > 1) {
      setDisabled(false);
    }
    var array = string.split(",");
    // console.log("handleSiteData", array[0], array[1]);
    // console.log(array[0]);
    items.cust_id = array[0];
    // console.log('data.id', items.cust_id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitSiteData = [items.site, items.contact];
    // console.log('submitSiteData',submitSiteData);
    dispatch({
      type: "SITE_BULK_ADD",
      payload: [items.cust_id, submitSiteData],
    });
    props.onHide();
    navigate("/sitelist");
  };

  useEffect(() => {
    // console.log('from data usestate', items);
  }, []);

  return (
    <div>
      {isLoading && <Loader />} {/* to show the Loading data */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Upload of Site
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group w-50">
            <select
              id="customername"
              className="form-select"
              label="Disabled Dropdown"
              onChange={(e) => {
                handleCustData(e.target.value);
              }}
            >
              <option>Select Customer Name</option>
              {custData?.map((eachCust) => {
                return (
                  <option
                    value={[eachCust._id, eachCust.legal_name]}
                    key={eachCust._id}
                  >
                    {eachCust.legal_name}
                  </option>
                );
              })}
            </select>
          </div>
          <br />
          <div>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                // console.log("here", file);
                readExcel(file);
              }}
            />
          </div>
          <br />
          <br />
          <span>
            Download Site Bulk Upload Template -
            <a
              href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Site_Bulk_Upload_V1_0.xlsx`}
            >
              Click here
            </a>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={isDisabled || disabled || wsdisabled || disEmptyArray}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Bulkupload;
