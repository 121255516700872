import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
    REP_BASEDON_USER_STATUS,
    REP_BASEDON_USER_STATUS_SUCCESS,
    REP_BASEDON_USER_STATUS_FAILED,
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAILED,
    GET_QUERY,
    GET_QUERY_SUCCESS,
    GET_QUERY_FAILED,
    GET_ATTRIBUTES,
    GET_ATTRIBUTES_SUCCESS,
    GET_ATTRIBUTES_FAILED,
    GET_FIELD_NAME,
    GET_FIELD_NAME_SUCCESS,
    GET_FIELD_NAME_FAILED,
    SAVE_QUERY,
    SAVE_QUERY_SUCCESS,
    SAVE_QUERY_FAILED,
    GET_QUERY_LIST,
    GET_QUERY_LIST_SUCCESS,
    GET_QUERY_LIST_FAILED,
    UPDATE_QUERY,
    UPDATE_QUERY_FAILED,
    UPDATE_QUERY_SUCCESS,
    DELETE_QUERY,
    DELETE_QUERY_SUCCESS,
    DELETE_QUERY_FAILED,
    SAVE_REPORT,
    SAVE_REPORT_FAILED,
    SAVE_REPORT_SUCCESS,
} from '../actions/reportAction'

import { reportService } from "../../services/service.index";

export function* watchUserBasedReportStatus() {
    yield takeLatest(REP_BASEDON_USER_STATUS, getUserBasedReportSaga);
}

export function* getUserBasedReportSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(reportService.reportBasedOnUser, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: REP_BASEDON_USER_STATUS_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: REP_BASEDON_USER_STATUS_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: REP_BASEDON_USER_STATUS_FAILED,
        });
    }
}


// Custom report builder Saga

export function* watchGetReportBuilderData() {
    yield takeLatest(GET_COLLECTIONS, getReportBuilderDataSaga);
}

export function* watchGetReportBuilderQuery() {
    // console.log("Saga")
    yield takeLatest(GET_QUERY, getReportBuilderQuerySaga);
}

export function* watchGetReportBuilderAttributes() {
    yield takeLatest(GET_ATTRIBUTES, getReportBuilderAttributesSaga);
}

export function* watchGetReportBuilderFieldValues() {
    yield takeLatest(GET_FIELD_NAME, getReportBuilderFieldValuesSaga);
}

export function* watchSaveReportBuilderQuery() {
    yield takeLatest(SAVE_QUERY, saveReportBuilderQuerySaga);
}

export function* watchGetReportBuilderQueryList() {
    yield takeLatest(GET_QUERY_LIST, getReportBuilderQueryListSaga);
}


export function* watchUpdateReportBuilderQuery() {
    yield takeLatest(UPDATE_QUERY, updateReportBuilderQuerySaga);
}

export function* watchDeleteReportBuilderQuery() {
    yield takeLatest(DELETE_QUERY, deleteReportBuilderQuerySaga);
}

export function* watchSaveReport(){
    yield takeLatest(SAVE_REPORT,saveReport)
}

export function* deleteReportBuilderQuerySaga(action) {
    try {
        const response = yield call(reportService.deleteReportBuilderQuery, action.payload);
        if (response.status === 200) {
            yield put({
                type: DELETE_QUERY_SUCCESS,
                payload: response.data,
            });
            alert("Query Deleted Successfully")
            yield put({
                type: GET_QUERY_LIST,
                payload: { page: 1, limit: 10, search: '' }
            });
        } else {
            yield put({
                type: DELETE_QUERY_FAILED,
            });
        }
    }
    catch (e) {
        yield put({
            type: DELETE_QUERY_FAILED,
        });
    }
}

export function* updateReportBuilderQuerySaga(action) {
    try {
        const response = yield call(reportService.updateReportBuilderQuery, action.payload);
        if (response.status === 200) {
            yield put({
                type: UPDATE_QUERY_SUCCESS,
                payload: response.data,
            });
            alert("Query Updated Successfully")
        } else {
            yield put({
                type: UPDATE_QUERY_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: UPDATE_QUERY_FAILED,
        });
    }
}

export function* getReportBuilderQueryListSaga(action) {
    try {
        const response = yield call(reportService.getReportBuilderQueryList, action.payload);
        // console.log("response", response.data)
        if (response.status === 200) {
            yield put({
                type: GET_QUERY_LIST_SUCCESS,
                payload: response.data,
            });
            
        } else {
            yield put({
                type: GET_QUERY_LIST_FAILED,
            });
        }
    }
    catch (e) {
        yield put({
            type: GET_QUERY_LIST_FAILED,
        });
    }
}

export function* saveReport(action){
    try {
        // console.log(action.payload);
        const response = yield call(reportService.saveReport, action.payload);
        if (response.status === 200) {
            yield put({
                type: SAVE_REPORT_SUCCESS,
                payload: response.data,
            });
            alert("Query Saved Successfully")
        } else {
            yield put({
                type: SAVE_REPORT_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SAVE_REPORT_FAILED,
        });
    }
}


export function* saveReportBuilderQuerySaga(action) {
    try {
        const response = yield call(reportService.saveReportBuilderQuery, action.payload);
        if (response.status === 200) {
            yield put({
                type: SAVE_QUERY_SUCCESS,
                payload: response.data,
            });
            alert("Query Saved Successfully")
        } else {
            yield put({
                type: SAVE_QUERY_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SAVE_QUERY_FAILED,
        });
    }
}

export function* getReportBuilderFieldValuesSaga(action) {
    try {
        const response = yield call(reportService.getReportBulderFieldValues, action.payload);
        if (response.status === 200) {
            yield put({
                type: GET_FIELD_NAME_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: GET_FIELD_NAME_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_FIELD_NAME_FAILED,
        });
    }
}

//OLD
/* export function* getReportBuilderAttributesSaga(action) {
    try {
        console.log('ACTION', action.payload.length)
        yield action.payload.forEach((element, i) => {
            console.log('element', element)
            const response = call(reportService.getReportBuilderAttributes, element);
            console.log(`RESPONSE ${i}`, response.status)
            if (response.status === 200) {
                put({
                    type: GET_ATTRIBUTES_SUCCESS,
                    payload: response.data,
                });
            } else {
                put({
                    type: GET_ATTRIBUTES_FAILED,
                });
            }
        });


    } catch (e) {
        yield put({
            type: GET_ATTRIBUTES_FAILED,
        });
    }
} */
//NEW

export function* getReportBuilderAttributesSaga(action) {
    try {
        // console.log('ACTION', action.payload.length)
        for (const element of action.payload) {
            // console.log('element', element)
            const response = yield call(reportService.getReportBuilderAttributes, element);
            // console.log(`RESPONSE`, response.status)
            if (response.status === 200) {
                yield put({
                    type: GET_ATTRIBUTES_SUCCESS,
                    payload: response.data,
                });
            } else {
                yield put({
                    type: GET_ATTRIBUTES_FAILED,
                });
            }
        }
    } catch (e) {
        yield put({
            type: GET_ATTRIBUTES_FAILED,
        });
    }
}


export function* getReportBuilderQuerySaga(action) {
    try {
        const response = yield call(reportService.getReportBuilderQuery, action.payload);
        // console.log("response", response.data)
        if (response.status === 200) {
            yield put({
                type: GET_QUERY_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: GET_QUERY_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_QUERY_FAILED,
        });
    }
}

export function* getReportBuilderDataSaga() {
    try {
        const response = yield call(reportService.getReportBuilderData);
        // console.log("response", response.data)
        if (response.status === 200) {
            yield put({
                type: GET_COLLECTIONS_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: GET_COLLECTIONS_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: GET_COLLECTIONS_FAILED,
        });
    }
}