/* Feature - to maintain header for customer page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Bulkupload from "../../modules/customer/bulkUpload";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo";

//Function for customer header
function Commonheader() {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar bg="light" variant={"light"} expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="headershadow">
          <Nav className="mr-auto my-2 my-lg-0" style={{ maxHeight: "100px" }}>
            <Sidebar />
            <Logo />
            <LogoutButton />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </div>
  );
}

export default Commonheader;
