import React, { useEffect, useState } from 'react'
import './Dashboard.css';
import { ResponsiveBar } from '@nivo/bar'
import { faServices } from '../../services/service.index';

// Rest of the code...

const Verification = () => {

    const [data, setData] = useState([]);
    const [count, setCount] = useState();
    // console.log(count, 'Count')
    const getVerficationCount = async () => {
        await faServices.getVerificationCount().then(function (res) {
            // console.log(res.data, 'FACount Asset');
            // setData(res.data);
            // console.log("aaaa", res.data)
            setCount(res.data)
        }).catch(function (error) {
        });
    };

    const getVerificationData = async () => {
        await faServices.getVerificationData().then(function (res) {
            // console.log(res.data, 'FA VerficationData Asset');
            setData(res.data);
        }).catch(function (error) {
        });
    };


    // console.log(data?.assetClass.filter((item) => item.count).reduce((max, item) => (item.count > max ? item.count : max), 0), 'Max Value')
    useEffect(() => {
        getVerficationCount();
        getVerificationData();
    }, []);

    return (
        <div>
            {/*........................  Count ..........................*/}
            <div className='countcardcontainer' >
                <h3>Dashboard</h3>
            </div>
            <div className='countcardcontainer' >
                <div
                    className='countcard'
                    style={{
                        borderBottom: '3px solid #0063B9',
                        display: 'block',
                    }}
                >
                    <div>
                        <p className='titletext'>Total Assets</p>
                    </div>
                    <div>
                        <p className='counttext' >{count?.total}</p>
                    </div>
                </div>
                <div className='countcard'
                    style={{
                        borderBottom: '3px solid green',
                        display: 'block',
                    }}
                >
                    <div>
                        <p className='titletext'>Verified</p>
                    </div>
                    <div >
                        <p className='counttext' >{count?.verified}</p>
                    </div>

                </div>
                <div className='countcard'
                    style={{
                        borderBottom: '3px solid #F8DE7E',
                        display: 'block',
                    }}
                >
                    <div>
                        <p className='titletext'>Not Verified</p>
                    </div>
                    <div >
                        <p className='counttext' >{count?.unverified}</p>
                    </div>

                </div>
                {/* <div className='countcard'
                    style={{
                        borderBottom: '3px solid red',
                        display: 'block',
                    }}
                >
                    <div>
                        <p className='titletext'>Disposed</p>
                    </div>
                    <div >
                        <p className='counttext' >{count?.disposed}</p>
                    </div>

                </div> */}
            </div>

            <div style={{
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                marginLeft: '5px',
                marginRight: '5px',
                borderRadius: '5px',
                backgroundColor: 'rgba(105, 250, 225, 1)',
                height: '25px'
            }}>
                {/* Account Stats */}
            </div>
            {/*........................  Graphs ..........................*/}
            <div className='countcardcontainer'
                style={{
                    width: '100%',
                    paddingTop: '4rem',
                    // border: '1px solid black',
                }}
            >
                <div
                    style={{
                        // border: '1px solid black',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        // minHeight: '400px',
                        width: '49%',
                        height: '450px',

                    }}
                >
                    <div>
                        <p className='graphname'
                            style={{
                                padding: '0 0.5rem'
                            }}
                        >Location Wise Verified Assets Count</p>
                    </div>
                    {data?.location?.length > 0 &&
                        <ResponsiveBar
                            data={data?.location?.sort((a, b) => b.count - a.count)}
                            keys={["count"]}
                            indexBy="key"
                            margin={{ top: 50, right: 60, bottom: 120, left: 60 }}
                            padding={0.4}
                            valueScale={{ type: "linear" }}
                            colors="#3182CE"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            // width={100}
                            maxValue={(data?.location.filter((item) => item.count).reduce((max, item) => (item.count > max ? item.count : max), 0)) + (data?.location.filter((item) => item.count).reduce((max, item) => (item.count > max ? item.count : max), 0) * 20 / 100)}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 15,
                                legend: 'Location',
                                legendPosition: 'middle',
                                legendOffset: 70
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Number of Assets",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                        />}
                </div>
                <div
                    style={{
                        // border: '2px solid black',
                        // minHeight: '400px',
                        // maxWidth: '',
                        // maxWidth: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        height: '450px',
                        width: '48%',

                    }}
                >
                    <div>
                        <p className='graphname'
                            style={{
                                padding: '0 0.5rem'
                            }}
                        >Cost Center Wise Verified Assets Count</p>
                    </div>
                    {data?.assetClass?.length > 0 &&
                        <ResponsiveBar
                            data={data?.assetClass}
                            keys={["count"]}
                            indexBy="key"
                            margin={{ top: 50, right: 60, bottom: 120, left: 60 }}
                            padding={0.4}
                            valueScale={{ type: "linear" }}
                            colors="#3182CE"
                            animate={true}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            // rounded={true}
                            maxValue={(data?.assetClass.filter((item) => item.count).reduce((max, item) => (item.count > max ? item.count : max), 0)) + (data?.assetClass.filter((item) => item.count).reduce((max, item) => (item.count > max ? item.count : max), 0) * 20 / 100)}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 15,
                                legend: 'Cost Center',
                                legendPosition: 'middle',
                                legendOffset: 70
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Number of Assets",
                                legendPosition: "middle",
                                legendOffset: -40
                            }}
                        />
                    }
                </div>
            </div>
            <div style={{
                marginTop: '1rem',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                marginLeft: '5px',
                marginRight: '5px',
                borderRadius: '5px',
                backgroundColor: 'rgba(105, 250, 225, 1)',
                height: '25px'
            }}>
                {/* Account Stats */}
            </div>
        </div>
    )
}

export default Verification