//Feature - Form to update/modify Site details

//Created by - Janaki J
//Updated by - Janaki J

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SITE_UPDATE } from "../../redux/actions/siteAction";
import Loader from "../../components/loader/loader";
import { Link } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";

//Function to update site
function SiteUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();

  // to fetch the redux data
  const dispatch = useDispatch();
  const siteGetSingle = useSelector((state) => state.site.siteGetSingle);
  const custData = useSelector((state) => state.customer.custData);
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.site.isLoading);
  const access = useSelector((state) => state.access.site);

  let isValid = true;

  const [data, setData] = useState({
    sitename: "",
    swebsite: "",
    saddress: "",
    sregion: "",
    scity: "",
    scountry: "",
    sstate: "",
    szcode: "",
    sgeo: "",
    scriticality: "",
    site_id: "",
    cust_name: "",
  });
  //Functions to handle form inputs
  const handleCriticality = (val) => {
    setData({
      ...data,
      scriticality: val,
    });
  };
  const handleCustomerData = (string) => {
    let array = string.split(",");
    data.id = array[0];
    if (string.length < 1) {
      setData({
        ...data,
        isValidCustomername: true,
      });
    } else {
      setData({
        ...data,
        isValidCustomername: false,
      });
    }
  };
  const handleSitename = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        sitename: val,
        isValidSitename: true,
      });
    } else {
      setData({
        ...data,
        sitename: val,
        isValidSitename: false,
      });
    }
  };
  const handleCustname = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        cust_name: val,
        isValidCustomername: true,
      });
    } else {
      setData({
        ...data,
        cust_name: val,
        isValidCustomername: false,
      });
    }
  };
  const handleWebsite = (val) => {
    setData({
      ...data,
      swebsite: val,
    });
  };
  const handleAddress = (val) => {
    setData({
      ...data,
      saddress: val,
    });
  };
  const handleRegion = (val) => {
    setData({
      ...data,
      sregion: val,
    });
  };
  const handleCity = (val) => {
    setData({
      ...data,
      scity: val,
    });
  };
  const handleCountry = (val) => {
    setData({
      ...data,
      scountry: val,
    });
  };
  const handleState = (val) => {
    setData({
      ...data,
      sstate: val,
    });
  };
  const handleZipCode = (val) => {
    setData({
      ...data,
      szcode: val,
    });
  };
  //Function to update site details
  const updateSiteDetails = () => {
    let siteDetails = {
      site_name: data.sitename,
      website: data.swebsite,
      address: data.saddress,
      region: data.sregion,
      city: data.scity,
      country: data.scountry,
      state: data.sstate,
      zip_code: data.szcode,
      geo_address: data.sgeo,
      site_criticality_rating: data.scriticality,
      legal_name: siteGetSingle.legal_name,
      site_id: siteGetSingle._id,
      modified_by: userid,
    };
    if (isValid) {
      dispatch({
        type: SITE_UPDATE,
        payload: siteDetails,
      });
      navigate("/sitelist");
    }
  };
  //fetch site ID
  const sendID = () => {
    if (id != null) {
      dispatch({
        type: "SITE_GET_SINGLE",
        payload: id,
      });
    }
  };
  //useEffects
  useEffect(() => {
    // console.log("siteGetSingle", siteGetSingle);
    sendID();
  }, [id]);
  useEffect(() => {
    setData({
      sitename: siteGetSingle.site_name,
      swebsite: siteGetSingle.website,
      saddress: siteGetSingle.address,
      sregion: siteGetSingle.region,
      scity: siteGetSingle.city,
      scountry: siteGetSingle.country,
      sstate: siteGetSingle.state,
      szcode: siteGetSingle.zip_code,
      sgeo: siteGetSingle.geo_address,
      scriticality: siteGetSingle.site_criticality_rating,
      site_id: siteGetSingle._id,
      cust_name: siteGetSingle.legal_name,
    });
  }, [siteGetSingle]);
  //functions for validation
  function containSpeclChars(str) {
    const specialChars = /^([a-zA-Z0-9-#@']+\s)*[a-zA-Z0-9-#@']+$/;
    return specialChars.test(str);
  }

  function websiteChars(str) {
    const webChars =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-]+\.[a-z.]+(\/[a-zA-Z0-9-#:=?]+\/?)*$/;
    return webChars.test(str);
  }
  function regionChars(str) {
    const placeChars = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    return placeChars.test(str);
  }

  function zcodeChars(str) {
    const zipChars = /^[1-9][0-9]{5}$/;
    return zipChars.test(str);
  }
  //---------------------------------------HTML-------------------------------------------//
  return (
    <div>
      {isLoading && <Loader />} {/* to show the Loading data */}
      <BackButton />
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div style={{ paddingTop: "55px" }}>
              {access !== null && access.u ? (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                >
                  <div className="formHeadings mb-2">Edit Site</div>
                  <div className="row">
                    {/* Site update form starts */}
                    <div className="mb-2 col-md-6">
                      <label>
                        Site Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sitename"
                        className="form-control"
                        onChange={(e) => handleSitename(e.target.value)}
                        defaultValue={data.sitename}
                      />
                      {containSpeclChars(data.sitename) || data.sitename === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Site name
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Customer Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sitename"
                        className="form-control"
                        onChange={(e) => handleCustname(e.target.value)}
                        defaultValue={data.cust_name}
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Website
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="swebsite"
                        className="form-control"
                        onChange={(e) => handleWebsite(e.target.value)}
                        defaultValue={data.swebsite}
                      />
                      {websiteChars(data.swebsite) || data.swebsite === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Website
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Address
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="saddress"
                        className="form-control"
                        onChange={(e) => handleAddress(e.target.value)}
                        defaultValue={data.saddress}
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Region
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sregion"
                        className="form-control"
                        onChange={(e) => handleRegion(e.target.value)}
                        defaultValue={data.sregion}
                      />
                      {regionChars(data.sregion) || data.sregion === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Region name
                            </div>
                          ))}
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        City
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="scity"
                        className="form-control"
                        onChange={(e) => handleCity(e.target.value)}
                        defaultValue={data.scity}
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Country
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="scountry"
                        className="form-control"
                        onChange={(e) => handleCountry(e.target.value)}
                        defaultValue={data.scountry}
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        State
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sstate"
                        className="form-control"
                        onChange={(e) => handleState(e.target.value)}
                        defaultValue={data.sstate}
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Zipcode
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        min="0"
                        name="szcode"
                        className="form-control"
                        maxLength={6}
                        minLength={6}
                        pattern="[0-9]{6,}"
                        placeholder="Eg. 5600XX"
                        onChange={(e) => handleZipCode(e.target.value)}
                        defaultValue={data.szcode}
                      />
                      {zcodeChars(data.szcode) || data.szcode === ""
                        ? " "
                        : ((isValid = false),
                          (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                                paddingTop: "2px",
                              }}
                            >
                              Invalid Zipcode
                            </div>
                          ))}
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="scriticality">Criticality Rating</label>
                      <select
                        name="scriticality"
                        id="scriticality"
                        className="form-select"
                        onChange={(e) => handleCriticality(e.target.value)}
                        placeholder="Choose any one"
                        value={data.scriticality}
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-12">
                      <button
                        onClick={() => navigate("/sitelist")}
                        className="backButton"
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="float-end saveButton"
                        onClick={() => {
                          updateSiteDetails();
                        }}
                      >
                        Update
                      </button>

                      {/* Site update form ends */}
                    </div>
                  </div>
                </form>
              ) : (
                <form
                  action=""
                  className="mt-5 border p-4 bg-light shadow rounded"
                >
                  <div className="formHeadings mb-2">Edit Site</div>
                  <div className="row">
                    {/* Site update form starts */}
                    <div className="mb-2 col-md-6">
                      <label>
                        Site Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sitename"
                        className="form-control"
                        onChange={(e) => handleSitename(e.target.value)}
                        defaultValue={data.sitename}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Customer Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sitename"
                        className="form-control"
                        onChange={(e) => handleCustname(e.target.value)}
                        defaultValue={data.cust_name}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Website
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="swebsite"
                        className="form-control"
                        onChange={(e) => handleWebsite(e.target.value)}
                        defaultValue={data.swebsite}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Address
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="saddress"
                        className="form-control"
                        onChange={(e) => handleAddress(e.target.value)}
                        defaultValue={data.saddress}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Region
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sregion"
                        className="form-control"
                        onChange={(e) => handleRegion(e.target.value)}
                        defaultValue={data.sregion}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        City
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="scity"
                        className="form-control"
                        onChange={(e) => handleCity(e.target.value)}
                        defaultValue={data.scity}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Country
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="scountry"
                        className="form-control"
                        onChange={(e) => handleCountry(e.target.value)}
                        defaultValue={data.scountry}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        State
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="sstate"
                        className="form-control"
                        onChange={(e) => handleState(e.target.value)}
                        defaultValue={data.sstate}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-6">
                      <label>
                        Zipcode
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        min="0"
                        name="szcode"
                        className="form-control"
                        maxLength={6}
                        minLength={6}
                        pattern="[0-9]{6,}"
                        placeholder="Eg. 5600XX"
                        onChange={(e) => handleZipCode(e.target.value)}
                        defaultValue={data.szcode}
                        disabled
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="scriticality">Criticality Rating</label>
                      <select
                        name="scriticality"
                        id="scriticality"
                        className="form-select"
                        onChange={(e) => handleCriticality(e.target.value)}
                        placeholder="Choose any one"
                        value={data.scriticality}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-12">
                      <button
                        onClick={() => navigate("/sitelist")}
                        className="backButton"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//exporting component
export default SiteUpdate;
