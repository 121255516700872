import React from 'react';
import './MenuItem.css';
import { Link } from 'react-router-dom';

function MenuItem({ title, logo, to, onClick }) {
  return (
    <Link to={to} style={{ color: 'black', textDecoration: 'none' }}>

      <div className="menu-item" onClick={onClick}>
        {logo ?
          <img src={logo} alt={`${title} logo`} className="menu-logo" />
          :
          <div className="menu-withoout-logo" >

            <p>Icon</p>
          </div>
        }
        <div className='menu-text'>
          <h2 style={{ fontStyle: "Arial" }} >{title}</h2>
        </div>
      </div>
    </Link>
  );
}

export default MenuItem;
