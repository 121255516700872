import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from '../../constants/URLs/urlConstants';


function getSiteData() {
    return serviceBase.get(CONSTANTS.SITE_GET_ALL);    
};

function getSiteById(id) {
    return serviceBase.get(CONSTANTS.SITE_BY_CUSTID + id)
}

function deleteSiteData (id) {
    return serviceBase.delete(CONSTANTS.SITE_DELETE + id);    
};

function addSiteData(data){
    // console.log(data);
    return serviceBase.post( CONSTANTS.SITE_ADD, data);
}

function addBulkSite(data) {
    // console.log('bulk upload client ID service data', data);
    // console.log('bulk upload client ID service data', data[0]);
    // console.log('bulk upload client ID service data', data[1]);
    return serviceBase.post(CONSTANTS.SITE_BULK_ADD + data[0], data[1]);   
};

function getSingleSite(id) {
    // console.log("id", id);
    return serviceBase.get(CONSTANTS.SITE_GET_SINGLE + id);
}

function updateSite(data) {
    // console.log("data", data);
    return serviceBase.patch(CONSTANTS.SITE_UPDATE + data.site_id, data);
}
function getSiteContact(id) {
    return serviceBase.get(CONSTANTS.SITE_CONTACT_GET + id);
}
function addSiteContact(data) {
    // console.log(data)
    let requestObj = JSON.stringify(data.newObj);
    return serviceBase.post(CONSTANTS.SITE_CONTACT_ADD + data.id, requestObj);
}
function deleteSiteContactData(data) {
    return serviceBase.delete(CONSTANTS.SITE_CONTACT_DELETE + data._id);
};

export const siteDetails = {
    getSiteData,
    getSiteById,
    deleteSiteData,
    addSiteData,
    updateSite,
    getSingleSite,
    addBulkSite,
    getSiteContact,
    addSiteContact,
    deleteSiteContactData

}