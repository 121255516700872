import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faClose, faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { INSPECTION_ADD } from "../../../redux/actions/inspectionAction";
import "./inspectStyle.css";

const UploadInspection = () => {
  const [queryParams] = useSearchParams();
  // const [imageName, setImageName] = useState('');

  const isLoading = useSelector((state) => state.inspection.isLoading);
  const userid = useSelector((state) => state.auth.userid);
  const isSubmitted = useSelector((state) => state.inspection.isSubmitted);
  const accessOnDemand = useSelector((state) => state.access.onDemandInspection);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showhide, setShowhide] = useState("");
  const [minOfHrs, setMinOfHrs] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [hoursOfDay, setHoursOfDay] = useState([]);
  const today = new Date().toISOString().slice(0, 10);

  // console.log('imageName', imageName);
  // console.log('imageArray', imageArray);


  //Array of Hours - its used for repeat hourly frequency check box
  const numOfHours = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  //Array of Days
  // const numOfDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // const numOfDays = [0, 1, 2, 3, 4, 5, 6];
  const numOfDays = [{ key: 0, value: "Sun" }, { key: 1, value: "Mon" }, { key: 2, value: "Tue" }, { key: 3, value: "Wed" }, { key: 4, value: "Thu" }, { key: 5, value: "Fri" }, { key: 6, value: "Sat" }];

  //Radio button function
  const handleShow = (e) => {
    const getshow = e.target.value;
    setShowhide(getshow);
    setSchedule({ ...schedule, priority: e.target.value });
  };
  const handleFreqcy = (e) => {
    // console.log(e);
    setMinOfHrs([])
    setDaysOfWeek([])
    setHoursOfDay([])
    setSchedule((prevState) => {
      prevState.frequency.type = e;
      prevState.frequency.mits_of_hrs = [];
      prevState.frequency.hrs_of_day = [];
      prevState.frequency.days_of_week = [];
      return { ...prevState };
    });
    e === ("repeat_minutes" || 'repeat_days' || 'repeat_hours') &&
      setSchedule((prevState) => {
        prevState.frequency.type_value = '1';
        return { ...prevState };
      });
  };

  //Handle Inspection Frequency
  const handleInsptnFreqcy = (e) => {
    // console.log(typeof(e));
    setSchedule((prevState) => {
      prevState.frequency.type_value = e;
      return { ...prevState };
    });
  };

  const convertToUTC = (time) => {
    const localTime = new Date('1970-01-01T' + time + ':00');
    const utcTime = localTime.toISOString().substr(11, 5);
    return utcTime;
  };

  const handlehr = (localTime) => {
    const utcTime = convertToUTC(localTime);
    const newHrs = [...minOfHrs];
    const hours = newHrs.indexOf(utcTime);
    if (hours === -1) {
      newHrs.push(utcTime);
    } else {
      newHrs.splice(hours, 1);
    }
    // console.log(newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b)));
    setMinOfHrs(newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b)));
    setSchedule((prevState) => {
      prevState.frequency.hrs_of_day = newHrs.sort((a, b) => new Date('1970-01-01T' + a) - new Date('1970-01-01T' + b));
      prevState.frequency.frequency_type_value = ""
      return { ...prevState };
    });
  };
  const handleDay = (e) => {
    // console.log(e);
    const newDays = [...daysOfWeek];
    const index = newDays.indexOf(e);
    if (index === -1) {
      // newDays.push(e === "Sun" ? 0 : e === "Mon" ? 1 : e === "Tue" ? 2 : e === "Wed" ? 3 : e === "Thu" ? 4 : e === "Fri" ? 5 : e === "Sat" ? 6 : "");
      newDays.push(e)
    } else {
      newDays.splice(index, 1);
    }
    setDaysOfWeek(newDays.sort((a, b) => a - b));
    setSchedule((prevState) => {
      prevState.frequency.days_of_week = newDays.sort((a, b) => a - b);
      prevState.frequency.frequency_type_value = ""
      return { ...prevState };
    });
  };
  const handleFreqEnd = (e) => {
    const getshow = e.target.value;
    setShowhide(getshow);
    setSchedule((prevState) => {
      prevState.frequency.end_type = getshow;
      return { ...prevState };
    });
  };
  const handleDropdownOptionChange = (e, i, optionIndex) => {
    const value = e.target.value;
    // console.log(value);
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array[optionIndex] = value;
      return { ...prevState };
    });
  };

  const addDropdownOption = (i) => {
    // console.log(schedule);
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array.push("");
      return { ...prevState };
    });
  };

  const removeDropdownOption = (i, optionIndex) => {
    setSchedule((prevState) => {
      prevState.checkpoints[i].dropdown_array.splice(optionIndex, 1);
      return { ...prevState };
    });
  };

  const addInspChekPonts = () => {
    let newCpObj = {
      checkpoint: "",
      uom: "",
      standard_requirement: "",
      answer_type: "",
      dropdown_array: [],
      attachment: "",
    };
    // console.log(newCpObj);
    let cpList = schedule.checkpoints;
    let updatedList = cpList.push(newCpObj);
    setSchedule({ ...schedule, updatedList });
  };


  //delete Task
  const deleteInspChekPonts = (index) => {
    alert("Are you sure you wish to delete ?");
    let afterDelCp = schedule.checkpoints;
    afterDelCp.splice(index, 1);
    setSchedule({ ...schedule, afterDelCp });
  };
  const initialCalenderFrequency = {
    type: "", //"months",
    type_value: "",
    mits_of_hrs: "",
    hrs_of_day: [],
    days_of_week: [], //["Sun", "Mon", "Tue", ....]
    end_type: "",
    occur_value: "",
    date_value: "",
  };
  const [calendarFreqncy, setCalendarFreqncy] = useState(
    initialCalenderFrequency
  );
  const initialInspectionState = {
    parent_mid: id,
    inspection_name: "",
    priority: "",
    based_on: "",
    managed_by: "",
    assignee_type: "",
    assignee: [],
    inspection_duration: "",
    man_power_required: "",
    man_hour_required: "",
    sla: "",
    sla_interval: "",
    frequency: calendarFreqncy,
    checkpoints: [],
  };
  const [schedule, setSchedule] = useState(initialInspectionState);

  // console.log(schedule);


  const [form, setForm] = useState({
    name: "",
    priority: "",
    basedOn: "",
    managedBy: "",
    frequency: "",
    checkpoint: "",
    stdReq: "",
    ansTyp: "",
  });

  const [nameErr, setNameErr] = useState("");
  const [priorityErr, setPriorityErr] = useState("");
  const [basedonErr, setBasedonErr] = useState("");
  const [managedByErr, setManagedbyErr] = useState("");
  const [freqErr, setFreqErr] = useState("");
  const [freqValErr, setFreqValErr] = useState("");
  const [endTypeErr, setEndTypeErr] = useState("");
  const [cpErr, setCpErr] = useState("");
  const [stdReqErr, setStdReqErr] = useState("");
  const [ansTypeErr, setAnsTypeErr] = useState("");

  let isValid = true;
  const handleName = (val) => {
    setForm({
      ...form,
      name: val,
    });
  };
  const handleProity = (val) => {
    setForm({
      ...form,
      priority: val,
    });
  };
  const handleBased = (val) => {
    setForm({
      ...form,
      basedOn: val,
    });
  };
  const handleManagedby = (val) => {
    setForm({
      ...form,
      managedBy: val,
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const formValidation = () => {
    const nameErr = {};
    const priorityErr = {};
    const basedonErr = {};
    const managedByErr = {};
    const freqErr = {};
    const freqValErr = {};
    const endTypeErr = {};
    const cpErr = {};
    const stdReqErr = {};
    const ansTypeErr = {};

    if (form.name.trim().length < 1) {
      nameErr.inspShort = "Please Enter Inspection Name";
      isValid = false;
    }
    setNameErr(nameErr);

    if (form.priority === "") {
      priorityErr.inspShort = "Please Choose Priority";
      isValid = false;
    }
    setPriorityErr(priorityErr);

    if (form.basedOn === "") {
      basedonErr.inspShort = "Please Choose Based on";
      isValid = false;
    }
    setBasedonErr(basedonErr);

    if (form.managedBy === "") {
      managedByErr.inspShort = "Please Choose Managed by";
      isValid = false;
    }
    setManagedbyErr(managedByErr);

    if (schedule.frequency.type === "") {
      freqErr.inspShort = "Please Choose Frequency";
      isValid = false;
    }
    setFreqErr(freqErr);

    if (form.checkpoint.trim().length < 1) {
      cpErr.inspShort = "Please Enter Checkpoint";
      isValid = false;
    }
    setCpErr(cpErr);

    if (form.stdReq.trim().length < 1) {
      stdReqErr.inspShort = "Please Enter Standard Requirement";
      isValid = false;
    }
    setStdReqErr(stdReqErr);

    if (form.ansTyp === "") {
      ansTypeErr.inspShort = "Please Choose Answer Type";
      isValid = false;
    }
    setAnsTypeErr(ansTypeErr);

    if (schedule.frequency.type_value === "" &&
      (schedule?.frequency?.type !== "repeat_minutes") &&
      (schedule.frequency.type !== "repeat_hours") &&
      (schedule.frequency.type !== "repeat_days")) {
      freqValErr.inspShort = "Please Enter Frequency Value";
      isValid = false;
    }
    setFreqValErr(freqValErr)
    if (schedule.frequency.end_type === "") {
      endTypeErr.inspShort = "Please Choose Inspection End Type";
      isValid = false;
    }
    setEndTypeErr(endTypeErr);

    return isValid;
  };

  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  // console.log(schedule.checkpoints);

  const submitInspChekPonts = (e) => {
    e.preventDefault();
    if (
      validation()
    ) {
      alert("Please add atleast one Checkpoint", 2.5);
    }
    else if (schedule.based_on === 'calendar' && (validation() ||
      // schedule.frequency.type === "" ||
      schedule.checkpoints.length === 0)
    ) {
      alert("Please fill Mandatory field's", 2.5);
    } else if ((schedule.frequency.type === 'repeat_days' || schedule.frequency.type === 'repeat_hours') && schedule.frequency.days_of_week.length === 0) {
      alert("Please select atleast one day of week");
    }
    else if (schedule.frequency.type === 'repeat_hours' && schedule.frequency.hrs_of_day.length === 0) {
      alert("Please select atleast one hour of day");
    }
    else {
      let validate_Cp = true
      schedule.checkpoints.forEach((obj) => {
        if (obj.standard_requirement === "" ||
          obj.answer_type === "") {
          validate_Cp = false
        }
      })
      // console.log('validate_Cp', validate_Cp);
      if (validate_Cp) {
        let scheduleData = {
          parent_mid: schedule.parent_mid,
          inspection_name: schedule.inspection_name,
          priority: schedule.priority,
          based_on: schedule.based_on,
          managed_by: schedule.managed_by,
          // assignee_type: schedule.assignee_type,
          assignee: schedule.assignee,
          inspection_duration: schedule.inspection_duration,
          man_power_required: schedule.man_power_required,
          man_hour_required: schedule.man_hour_required,
          sla: schedule.sla,
          sla_interval: schedule.sla_interval,
          type: schedule.type,
          frequency: {
            frequency_type: schedule.frequency.type,
            frequency_type_value: schedule.frequency.type_value,
            frequency_minutes_of_hours: schedule.frequency.mits_of_hrs,
            frequency_hours_of_day: schedule.frequency.hrs_of_day,
            frequency_days_of_week: schedule.frequency.days_of_week,
            frequency_end_type: schedule.frequency.end_type,
            frequency_end_occurence_value: schedule.frequency.occur_value,
            frequency_end_date_value: schedule.frequency.date_value,
          },
          checkpoints: schedule.checkpoints,
          created_by: userid,
        };
        // console.log(scheduleData);
        dispatch({
          type: INSPECTION_ADD,
          payload: [queryParams.get("type"), scheduleData],
        });
      }
    }
  };

  // useEffect
  useEffect(() => {
    if (isSubmitted === true) {
      navigate("/inspectionlist");
    }
  }, [isSubmitted]);

  useEffect(() => {
    // console.log(queryParams.get("type"));
  }, [queryParams]);

  useEffect(() => {
    // console.log(accessOnDemand)
  }, [accessOnDemand])


  //functions for validation
  function nameChars(str) {
    const specialChars = /^([a-zA-Z0-9-/]+\s)*[a-zA-Z0-9-/]+$/;
    return specialChars.test(str);
  }
  function timeChars(str) {
    const integers = /^[1-9][0-9]{0,9}$/;
    return integers.test(str);
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="container">
        <div className="row">
          <div className="d-flex">
            <div className="col-md-6">
              <form className="mt-5 border p-4 bg-light shadow rounded">
                {/* Add Schedule form starts */}
                <h3 style={{ textAlign: "center" }}>Create Inspection</h3>
                <div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Inspection Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="inspectionname"
                      className="form-control"
                      placeholder="Inspection Name"
                      value={schedule.inspection_name}
                      onChange={(e) => {
                        setSchedule({
                          ...schedule,
                          inspection_name: e.target.value,
                        });
                        handleName(e.target.value);
                      }}
                    />

                    {Object.keys(nameErr).map((key) => {
                      if (!schedule.inspection_name) {
                        return (
                          <div key={key} style={{ color: "red", fontSize: "12px" }}>
                            {nameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {nameChars(schedule.inspection_name) ||
                      schedule.inspection_name === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Inspection Name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Priority
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="assets"
                      value="low"
                      defaultChecked={showhide === "low"}
                      onClick={handleShow}
                      onChange={(e) => handleProity(e)}
                    />
                    &nbsp;Low
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="assets"
                      value="medium"
                      onClick={handleShow}
                      onChange={(e) => handleProity(e)}
                    />
                    &nbsp; Medium
                    <input
                      className="radioButtons"
                      style={{ marginLeft: "25px" }}
                      type="radio"
                      name="assets"
                      value="high"
                      onClick={handleShow}
                      onChange={(e) => handleProity(e)}
                    />
                    &nbsp; High
                    {Object.keys(priorityErr).map((key) => {
                      if (!schedule.priority) {
                        return (
                          <div key={key} style={{ color: "red", fontSize: "12px" }}>
                            {priorityErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Based on
                      <span className="text-danger">*</span>
                    </label>
                    {(accessOnDemand?.c === true) ? (
                      <select
                        name="based_on"
                        className="form-select"
                        value={schedule.based_on}
                        onChange={(e) => {
                          setSchedule({ ...schedule, based_on: e.target.value });
                          handleBased(e.target.value);
                        }}
                      >
                        <option value="" disabled>Select</option>
                        <option value="calendar">Calendar</option>
                        <option value="on_demand">On Demand</option>
                      </select>) :
                      <select
                        name="based_on"
                        className="form-select"
                        value={schedule.based_on}
                        onChange={(e) => {
                          setSchedule({ ...schedule, based_on: e.target.value });
                          handleBased(e.target.value);
                        }}
                      >
                        <option value="" disabled>Select</option>
                        <option value="calendar">Calendar</option>
                        {/* <option value="on_demand">On Demand</option> */}
                      </select>

                    }

                    {Object.keys(basedonErr).map((key) => {
                      if (!schedule.based_on) {
                        return (
                          <div key={key} style={{ color: "red", fontSize: "12px" }}>
                            {basedonErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>
                      Managed by
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="managed_by"
                      className="form-select"
                      value={schedule.managed_by}
                      onChange={(e) => {
                        setSchedule({
                          ...schedule,
                          managed_by: e.target.value,
                        });
                        handleManagedby(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="inhouse">In house</option>
                      <option value="external">External</option>
                    </select>
                    {Object.keys(managedByErr).map((key) => {
                      if (!schedule.managed_by) {
                        return (
                          <div key={key} style={{ color: "red", fontSize: "12px" }}>
                            {managedByErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Inspection Duration(Mins)</label>
                    <input
                      min="0"
                      name="duration"
                      className="form-control"
                      maxLength="100"
                      placeholder="Minutes"
                      value={schedule.inspection_duration}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          inspection_duration: e.target.value,
                        })
                      }
                    />
                    {timeChars(schedule.inspection_duration) ||
                      schedule.inspection_duration === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Time
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man power required</label>
                    <input
                      min="0"
                      name="man_power"
                      className="form-control"
                      placeholder="Total man power"
                      value={schedule.man_power_required}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          man_power_required: e.target.value,
                        })
                      }
                    />
                    {timeChars(schedule.man_power_required) ||
                      schedule.man_power_required === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Required man power
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Total man hour required</label>
                    <input
                      min="0"
                      name="man_hour"
                      maxLength="1000"
                      className="form-control"
                      placeholder="Total man hour"
                      value={schedule.man_hour_required}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          man_hour_required: e.target.value,
                        })
                      }
                    />
                    {timeChars(schedule.man_hour_required) ||
                      schedule.man_hour_required === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Required time
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>SLA</label>
                    <input
                      min="0"
                      name="man_hour"
                      className="form-control"
                      placeholder="Total man hour"
                      value={schedule.sla}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          sla: e.target.value,
                        })
                      }
                    />
                    {timeChars(schedule.sla) || schedule.sla === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid SLA
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>SLA Interval</label>
                    <select
                      name="sla_interval"
                      className="form-select"
                      value={schedule.sla_interval}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          sla_interval: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="hours">Hour</option>
                      <option value="minutes">Minute</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-12">
                    <label>Type</label>
                    <select
                      name="type"
                      className="form-select"
                      value={schedule.type}
                      onChange={(e) =>
                        setSchedule({
                          ...schedule,
                          type: e.target.value,
                        })
                      }
                    >
                      <option value="" >
                        Select
                      </option>
                      <option value="water">Water Consumption</option>
                      <option value="lpg">LPG Consumption</option>
                      <option value="electric_energy_grid">Electric Energy - Grid</option>
                      <option value="electric_energy_dg">Electric Energy - DG</option>
                      <option value="diesel">Diesel Consumption</option>
                      <option value="steam">Steam Consumption</option>
                      <option value="compressed_air">Compressed Air Consumption</option>
                      <option value="chilled_water">Chilled Water Consumption</option>
                      <option value="other">Other Consumption</option>
                    </select>
                  </div>
                  {/* <div className="mb-2 col-md-12">
                    <label>Attachments</label>
                    <input
                      type="file"
                    />
                  </div> */}
                  <button
                    className="backButton m-2"
                    type="button"
                    onClick={() => {
                      navigate("/assetlist");
                    }}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>

            {/* Calender Based && Inspection*/}
            {(schedule.based_on === 'calendar') && (
              <div className="mx-2 col-md-12">
                <div className="col-md-12">
                  <form className="mt-5 border p-4 bg-light shadow rounded col-md-6">
                    <div className="mb-2 col-md-12">
                      <h5>Calendar based on </h5>
                      {/* <button className="float-end delIcons" type="button">
                        <FontAwesomeIcon
                          size="xl"
                          icon={faClose}
                          onClick={handleForm}
                        />
                      </button> */}
                      <label>
                        Frequency
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="frequency_type"
                        className="form-select"
                        value={schedule.frequency.type}
                        onChange={(e) => {
                          handleFreqcy(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="daily">Daily</option>
                        <option value="hourly">Hourly</option>
                        <option value="minutes">Minutes</option>
                        {/* <option value="repeat_minutes">Repeat Minutes</option> */}
                        <option value="repeat_hours">Repeat Hours</option>
                        <option value="repeat_days">Repeat Days</option>
                      </select>
                      {Object.keys(freqErr).map((key) => {
                        if (!schedule.frequency.type) {
                          return (
                            <div key={key} style={{ color: "red", fontSize: "12px" }}>
                              {freqErr[key]}
                            </div>
                          );
                        } else {
                          <p></p>;
                        }
                      })}
                      {/* Frequency type === Daily */}
                      {(schedule.frequency.type === "daily" ||
                        schedule.frequency.type === "hourly" ||
                        schedule.frequency.type === "minutes"
                      ) && (
                          <div className="mt-3">
                            <label>Frequency value</label>
                            <span className="text-danger">*</span>
                            <input
                              style={{
                                marginTop: 5,
                                height: 35,
                                paddingLeft: "10px",
                                borderRadius: 5,
                                border: "1px solid #ccc",
                              }}
                              min="1"
                              className="col-md-12"
                              placeholder="Number of days"
                              value={schedule.frequency.type_value}
                              onChange={(e) => handleInsptnFreqcy(e.target.value)}
                            />
                            {timeChars(schedule.frequency.type_value) ||
                              schedule.frequency.type_value === ""
                              ? " "
                              : ((isValid = false),
                                (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      paddingTop: "2px",
                                    }}
                                  >
                                    Invalid Frequency type
                                  </div>
                                ))}
                            {Object.keys(freqValErr).map((key) => {
                              if (!schedule.frequency.type_value) {
                                return (
                                  <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                    {freqValErr[key]}
                                  </div>
                                );
                              } else {
                                <p></p>;
                              }
                            })}
                          </div>
                        )}
                      {/* Frequency type === Repeat Minutes */}
                      {schedule?.frequency?.type === "repeat_minutes" && (
                        <div className="mt-3">
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: 'middle',
                                    marginLeft: '3px',
                                    // marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                            <br></br>
                            <br></br>
                            <br></br>
                            <table >
                              <tbody>
                                {numOfHours.reduce((rows, time, index) => {
                                  if (index % 5 === 0) rows.push([]);
                                  rows[rows.length - 1].push(time);
                                  return rows;
                                }, []).map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {row.map((time, cellIndex) => (
                                      <>
                                        <td key={cellIndex} style={{ verticalAlign: 'middle' }}>
                                          <div>
                                            <input
                                              className="hoursAlign"
                                              value={time}
                                              type="checkbox"
                                              onChange={(e) => handlehr(e.target.value)}
                                            />
                                            <label
                                              style={{
                                                fontSize: "13px",
                                                verticalAlign: 'middle',
                                                marginLeft: '3px',
                                                // marginTop: '22px',
                                              }}
                                            >{time}</label>
                                          </div>
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {/* Frequency type === Repeat Hours */}
                      {schedule.frequency.type === "repeat_hours" && (
                        <div className="mt-3">
                          {/* <label>Frequency value</label>
                          <input
                            style={{
                              marginTop: 5,
                              height: 35,
                              paddingLeft: "10px",
                              borderRadius: 5,
                              border: "1px solid #ccc",
                            }}
                            min="1"
                            className="col-md-12"
                            placeholder="Number of hours"
                            value={schedule.frequency.value}
                            onChange={(e) => handleInsptnFreqcy(e.target.value)}
                          /> */}
                          <br></br>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays.map((dayVal) => (
                              <div key={dayVal.key} >
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: 'middle',
                                    marginLeft: '3px',
                                    // marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                            <br></br>
                            <br></br>
                            <br></br>
                            <table >
                              <tbody>
                                {numOfHours.reduce((rows, time, index) => {
                                  if (index % 5 === 0) rows.push([]);
                                  rows[rows.length - 1].push(time);
                                  return rows;
                                }, []).map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {row.map((time, cellIndex) => (
                                      <>
                                        <td key={cellIndex} style={{ verticalAlign: 'middle' }}>
                                          <div>
                                            <input
                                              className="hoursAlign"
                                              value={time}
                                              type="checkbox"
                                              onChange={(e) => handlehr(e.target.value)}
                                            />
                                            <label
                                              style={{
                                                fontSize: "13px",
                                                verticalAlign: 'middle',
                                                marginLeft: '3px',
                                                // marginTop: '22px',
                                              }}
                                            >{time}</label>
                                          </div>
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {/* Frequency type === Repeat Days */}
                      {schedule.frequency.type === "repeat_days" && (
                        <div className="mt-3">
                          {/* <label>Frequency value</label>
                          <input
                            style={{
                              marginTop: 5,
                              height: 35,
                              paddingLeft: "10px",
                              borderRadius: 5,
                              border: "1px solid #ccc",
                            }}
                            min="1"
                            className="col-md-12"
                            placeholder="Number of repeat days"
                            value={schedule.frequency.value}
                            onChange={(e) => handleInsptnFreqcy(e.target.value)}
                          /> */}
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {numOfDays.map((dayVal, index) => (
                              <div key={dayVal.key}>
                                <input
                                  className="daysAlign"
                                  value={dayVal.value}
                                  type="checkbox"
                                  onChange={(e) => handleDay(dayVal.key)}
                                />
                                <label
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: 'middle',
                                    marginLeft: '3px',
                                    // marginTop: '22px',
                                  }}
                                >
                                  {dayVal.value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* Schedule End types */}
                      <div className="mt-4 md-6">
                        <label>Inspection End Type</label>
                        <span className="text-danger">*</span>
                        <div>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="no_end"
                            name="endType"
                            defaultChecked={showhide === "no_end"}
                            onClick={handleFreqEnd}
                          />
                          No End<br></br>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="end_after_occurence"
                            name="endType"
                            onClick={handleFreqEnd}
                          />
                          End After Occurences
                          {schedule.frequency.end_type ===
                            "end_after_occurence" && (
                              <input
                                style={{
                                  marginLeft: 10,
                                  border: "1px solid",
                                  borderRadius: 5,
                                  margin: "8px",
                                }}
                                min="0"
                                placeholder="Occurences"
                                onChange={(e) => {
                                  setSchedule((prevState) => {
                                    prevState.frequency.occur_value =
                                      e.target.value;
                                    return { ...prevState };
                                  });
                                  setSchedule((prevState) => {
                                    prevState.frequency.date_value = "";
                                    return { ...prevState };
                                  });
                                }}
                              />
                            )}
                          <br></br>
                          <input
                            className="radioButtons"
                            style={{ marginLeft: "25px", margin: "8px" }}
                            type="radio"
                            value="end_date"
                            name="endType"
                            onClick={handleFreqEnd}
                          />
                          End Date
                          {schedule.frequency.end_type === "end_date" && (
                            <input
                              style={{
                                marginLeft: 10,
                                width: 190,
                                border: "1px solid",
                                borderRadius: 5,
                                height: 30,
                              }}
                              type="date"
                              placeholdertext={"Please select a date"}
                              onChange={(e) => {
                                setSchedule((prevState) => {
                                  prevState.frequency.date_value =
                                    e.target.value;
                                  return { ...prevState };
                                });
                                setSchedule((prevState) => {
                                  prevState.frequency.occur_value = "";
                                  return { ...prevState };
                                });
                              }}
                              min={today}
                            />
                          )}
                          {Object.keys(endTypeErr).map((key) => {
                            if (!schedule.frequency.end_type) {
                              return (
                                <div key={key} style={{ color: "red", fontSize: "12px" }}>
                                  {endTypeErr[key]}
                                </div>
                              );
                            } else {
                              <p></p>;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* ) : null} */}
                </div>
              </div>
            )}
          </div>

          {/* Tasks */}
          {schedule?.checkpoints?.map((cards, i) => (
            <div key={i} className="mt-4 col-md-8 mb-2">
              <div className="mt-4 p-4 bg-light shadow rounded">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Inspection Checkpoints */}
                  <h5>Task number : {i + 1}</h5>
                  <button className="delIcons float-end" type="button">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faTrash}
                      onClick={() => deleteInspChekPonts(i)}
                    />
                  </button>
                </div>
                <div className="card-body">
                  <div className="col-md-12 p-2">
                    <label>Checkpoint
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="checkpoint"
                      className="form-control"
                      maxLength="200"
                      placeholder="Enter Checkpoint"
                      value={cards.checkpoint}
                      onChange={(e) =>
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].checkpoint =
                            e.target.value;
                          return { ...prevState };
                        })
                      }
                    />
                    {Object.keys(cpErr).map((key) => {
                      if (!cards.checkpoint) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {cpErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="col-md-12 p-2">
                    <label>UoM</label>
                    <input
                      type="text"
                      name="uom"
                      className="form-control"
                      maxLength="30"
                      placeholder="Enter UoM"
                      value={cards.uom}
                      onChange={(e) =>
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].uom = e.target.value;
                          return { ...prevState };
                        })
                      }
                    />
                  </div>
                  <div className="col-md-12 p-2">
                    <label>Standard Requirement
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="std_desc"
                      className="form-control"
                      maxLength="100"
                      required
                      placeholder="Enter Standard Requirement"
                      value={cards.standard_requirement}
                      onChange={(e) =>
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].standard_requirement =
                            e.target.value;
                          return { ...prevState };
                        })
                      }
                    />
                    {Object.keys(stdReqErr).map((key) => {
                      if (!cards.standard_requirement) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {stdReqErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="col-md-12 p-2">
                    <label>Answer Type
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      required
                      name="ans_type"
                      className="form-select"
                      value={cards.answer_type}
                      onChange={(e) =>
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].answer_type =
                            e.target.value;
                          return { ...prevState };
                        })
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="yes_no_na">Yes / No / Not Applicable</option>
                      <option value="value">Value</option>
                      <option value="dropdown">Dropdown</option>
                    </select>
                    {/* {console.log(cards?.dropdown_array)} */}
                    {cards?.answer_type === "dropdown" && (
                      <div>
                        <br />
                        <label>Dropdown Options:</label> &nbsp;&nbsp;&nbsp;&nbsp;
                        <button className="delIcons"
                          onClick={() => addDropdownOption(i)}
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={faPlus}
                          />
                        </button>
                        {cards?.dropdown_array?.map((option, optionIndex) => (
                          <div key={optionIndex} className="option-container" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px',
                            marginTop: '10px',
                          }}>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>{optionIndex + 1}. </div>
                            <input
                              type="text"
                              name="checkpoint"
                              className="form-control"
                              maxLength="200"
                              placeholder="Enter Option"
                              value={option}
                              onChange={(e) =>
                                handleDropdownOptionChange(e, i, optionIndex)
                              }
                            />
                            <button className="delIcons float-end" onClick={() => removeDropdownOption(i, optionIndex)}>
                              <FontAwesomeIcon
                                size="lg"
                                icon={faTrash}
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    {Object.keys(ansTypeErr).map((key) => {
                      if (!cards.answer_type) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {ansTypeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="col-md-12 p-2 mb-4">
                    <label>Attachments</label>
                    <input
                      className="form-control"
                      label="attachments"
                      type="file"
                      // value={cards.attachments}
                      multiple
                      onChange={async (e) => {
                        const files = e.target.files;
                        const convertedImages = [];
                        // Iterate through the selected files
                        for (let i = 0; i < files.length; i++) {
                          const base64String = await convertToBase64(files[i]);
                          convertedImages.push(base64String);
                        }
                        // console.log(convertedImages);                   
                        setSchedule((prevState) => {
                          prevState.checkpoints[i].attachment =
                            convertedImages
                          return { ...prevState };
                        })
                      }
                      }
                      accept=".jpeg,.jpg,.png"
                    ></input>
                    {/* <ul> */}
                    {/* {imageName.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))} */}
                    {/* </ul> */}
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="mt-10 col-md-12 p-2">
            <button
              type="button"
              className="addBtn float-end"
              onClick={addInspChekPonts}
              style={{
                borderRadius: "8px",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Add Checkpoints
            </button>
          </div>

          <div className="mt-10 col-md-12 p-2">
            <button
              type="button"
              className="saveButton float-end"
              onClick={(e) => submitInspChekPonts(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div >
    </div >
  );
};

export default UploadInspection;
