/* Feature - forget email page - when user click on forget password link of dashboard.

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import Loader from '../../components/loader/loader'

// forget email API
const url = process.env.REACT_APP_BASE_URL + "reset/sendResetLink";

// function to handle forget password
function ForgetPass() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  // function to handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const resp = await Axios.post(url, { user_email: email });

      if (resp.data.includes ("Email sent")) {
        alert("Email sent successfully @ " + inputRef.current.value);
        navigate("/");

      } else  if (resp.status === 204){
        alert("Invalid Email ID.");
        navigate("/");
      }
      // console.log(resp);
    }

    catch (error) {
      // console.log(error.response);
    }finally {
      setIsLoading(false);
    }
  };


  return (
    <div>
      {isLoading ? <Loader /> : null}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="ForgetEmail">
              <form
                action=""
                className="mt-5 border p-4 bg-light shadow rounded"
                onSubmit={handleSubmit}
              >
                <div className="formHeadings">Forgot Password</div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="Email1" className="form-label">
                      Email/User ID
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="Email1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Enter Your Registered Email ID"
                      ref={inputRef}
                    />
                  </div>
                  <div className="mt-4 col-md-12">
                    <button type="submit" className="saveButton float-end">
                      Submit
                    </button>
                    <button
                      onClick={() => {
                        navigate("/");
                      }}
                      type="button"
                      className="backButton"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPass
