//Audit trail Services
//Feature - Functions to define Customer's API

//importing dependencies
import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from '../../constants/URLs/urlConstants';

function getAuditData(data) {
    // console.log(CONSTANTS.AUDIT_TRAIL_AMMS + data);
    // console.log(CONSTANTS.AUDIT_TRAIL_AMMS`?page=${data[0]}&limit=${data[1]}`);
    return serviceBase.get(CONSTANTS.AUDIT_TRAIL_AMMS + data); 
};

//exporting functions for the global usage
export const auditDetails = {
    getAuditData,  
}