/* Feature - Get all TAT-Ticket list provided with pagination, search, filter functionalities
Created by - Janaki
Updated by - Janaki
*/
//importing dependencies and packages
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCalendarDays,
    faAngleRight,
    faAngleLeft,
    faClose,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faEye,
    faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";
import ReactTooltip from "react-tooltip";
import Commonheader from "../../../layouts/common/commonHeader";

//Function to fetch all asset
function TatList() {
    //For Search and filter by murthi new
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //to fetch data from redux
    const assetData = useSelector((state) => state.asset.assetData);
    const isLoading = useSelector((state) => state.asset.isLoading);
    const role = useSelector((state) => state.auth.role);
    const access = useSelector((state) => state.access.asset);
    const userProfileData = useSelector((state) => state.user.userSingleData);


    // Add state variables for current page and items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //add toggle Button

    // Bulk upload feature to show in modal (Bulk upload feature of schedule).
    const [modalShow, setModalShow] = useState(false);
    const [ID, setID] = useState("");
    const [assetName, setAssetName] = useState("");

    //function to delete asset from list
    const handleDelete = (_id) => {
        dispatch({
            type: "ASSET_DELETE_LIST",
            // payload: _id,
            payload: [currentPage, itemsPerPage, searchInput, _id],
        });
    };

    // Calculate the total number of pages
    let totalPages = 0;
    var assetList = [];
    if (assetData.result !== undefined) {
        assetList = assetData.result;
    }
    if (assetData?.total_count !== undefined) {
        totalPages = Math.ceil(assetData.total_count / itemsPerPage);
    }
    // console.log('assetList', assetList);
    // Function to handle next button
    const handleNext = () => {
        setCurrentPage((currentPage) => currentPage + 1);
    };

    // Function to handle previous button
    const handlePrev = () => {
        setCurrentPage((currentPage) => currentPage - 1);
    };

    //Function to handle FirstPage Button
    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    //Function to handle LastPage Button
    const handleLastpage = () => {
        setCurrentPage(totalPages);
    };

    // Function to handle items per page change
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    // Function to handle search input change
    // let id ;
    const handleSearchInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        // id = setTimeout(function () {
        //   console.log(searchInput);
        // }, 5000); 

        // console.log(id);
        // if (id !== 0) {
        //   console.log(id);
        //   clearTimeout(id);
        //   console.log(id);      
        // }     
    };


    //Changes for Pagination and Search
    const handleClearButton = () => {
        setSearchInput("");
    };

    //useEffect
    useEffect(() => {
        const roleCode = role[0];
        if (access === null && roleCode !== null) {
            dispatch({
                type: "ACCESS_GET_LIST",
                payload: roleCode,
            });
        }
    }, [role, access]);

    useEffect(() => {
        if ((currentPage && currentPage > 0 || searchInput.length > 0)) {
            dispatch({
                type: "ASSET_GET_LIST",
                payload: [currentPage, itemsPerPage, searchInput],
            });
        }
    }, [currentPage, itemsPerPage, searchInput]);

    useEffect(() => {
        if (userProfileData !== null) {
            // userProfileData.sites
            // console.log('userProfileData', userProfileData.sites);
        }
    }, [userProfileData]);

    ////////////////////////////////////////HTML////////////////////////////////////////////////
    return (
        <div>
            <Commonheader />
            {isLoading && <Loader />} {/* to show Loading effect */}
            <div className="col-md-12">
                <div
                    className="listHeadings"
                    style={{
                        fontSize: "20px",
                        justifyContent: "center",
                    }}
                >
                    Ticket-TAT List
                    <div style={{ float: "right" }}>
                        <form
                            className="mb-4"
                        >
                            <button className="buttons mx-2"
                                onClick={() => {
                                    navigate(
                                        `/tat-ticket/upload/${userProfileData.sites[0]}`
                                    );
                                }}
                            >
                                <FontAwesomeIcon icon={faAdd} className="icons mx-2" size="lg" />
                                Create TAT
                            </button>
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                                style={{
                                    border: "1px solid steelblue",
                                    fontSize: "15px",
                                    padding: "5px",
                                }}
                            />
                            {/* {console.log(searchInput)} */}
                            <span
                                type="reset"
                                onClick={handleClearButton}
                                style={{ paddingLeft: "8px", marginRight: "25px" }}
                            >
                                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
                            </span>
                        </form>
                    </div>
                    <div>Turn Around Time - Ticket (View, Edit & Delete) List </div>
                </div>
                {/* Table to view asset list */}
                <div className="mb-6">
                    <table className="table-bordered" width="100%">
                        <thead>
                            {/* Table heading to display list of Site */}
                            <tr
                                style={{
                                    textAlign: "center",
                                    backgroundColor: "steelblue",
                                    padding: "8px",
                                    color: "#fff",
                                }}
                            >
                                <th style={{ padding: "8px", width: "5%" }}>S. No</th>
                                <th
                                    scope="col"
                                    style={{ padding: "8px", textAlign: "left", width: "15%" }}
                                >
                                    Criticality
                                </th>
                                <th scope="col" style={{ width: "15%" }}>
                                    Description
                                </th>
                                <th
                                    scope="col"
                                    colSpan="4"
                                    className="actionColumn"
                                    style={{ textAlign: "center" }}
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        {assetList !== null && assetList.length > 0 ? (
                            assetList.map((eachData, index) => {
                                return (
                                    <tbody key={eachData._id} style={{ padding: "15px" }}>
                                        <tr
                                            style={{ padding: "20px" }}
                                        // onDoubleClick={() =>
                                        //   navigate(`/asset/view/${eachData._id}`)
                                        // }
                                        >
                                            {/* function Double click on row to view all asset atribute other than availbe on table.  */}
                                            <td style={{ textAlign: "center", padding: "8px" }}>
                                                {(currentPage - 1) * itemsPerPage + (index + 1)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>{eachData.make}</td>
                                            <td style={{ textAlign: "center" }}>{eachData.model}</td>
                                            {access !== null && access.r ? (
                                                <td style={{ textAlign: "center" }}>
                                                    <div>
                                                        <button
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            style={{
                                                                border: "none",
                                                                color: "#2D7DC1",
                                                                background: "none",
                                                            }}
                                                            data-tip
                                                            data-for="viewTip"
                                                        >
                                                            <FontAwesomeIcon icon={faEye} size="lg" />
                                                            <ReactTooltip
                                                                id="viewTip"
                                                                place="bottom"
                                                                effect="solid"
                                                            >
                                                                View/History
                                                            </ReactTooltip>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li className="pt-1 mx-2">
                                                                <Link
                                                                    className="dropdwn"
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        color: "black",
                                                                        textAlign: "center",
                                                                    }}
                                                                    //
                                                                    to={`/assets/update/${eachData._id}`}
                                                                >
                                                                    Asset View
                                                                </Link>
                                                            </li>
                                                            <li className="pb-2">
                                                                <button
                                                                    className="dropdwn"
                                                                    style={{
                                                                        border: "none",
                                                                        background: "none",
                                                                        position: "relative",
                                                                        textAlign: "center",
                                                                    }}
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/inspection/maintenance-history/${eachData._id}?type=asset`
                                                                        );
                                                                    }}
                                                                >
                                                                    Asset History
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            ) : null}
                                            {access !== null && access.u ? (
                                                <td style={{ textAlign: "center" }}>
                                                    {
                                                        <Link to={`/assets/update/${eachData._id}`}>
                                                            {/* Edit button */}
                                                            <button
                                                                style={{
                                                                    border: "none",
                                                                    color: "#2D7DC1",
                                                                    background: "none",
                                                                }}
                                                                data-tip
                                                                data-for="updateTip"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    size="lg"
                                                                />
                                                                <ReactTooltip
                                                                    id="updateTip"
                                                                    place="bottom"
                                                                    effect="solid"
                                                                >
                                                                    Edit
                                                                </ReactTooltip>
                                                            </button>
                                                        </Link>
                                                    }
                                                </td>
                                            ) : null}

                                            {access !== null && access.d ? (
                                                <td style={{ textAlign: "center" }}>
                                                    {/* Delete button */}
                                                    <button
                                                        onClick={() => {
                                                            window.confirm(
                                                                "Are you sure you want to delete this item?"
                                                            ) && handleDelete(eachData._id);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            color: "#2D7DC1",
                                                            background: "none",
                                                        }}
                                                        data-tip
                                                        data-for="deleteTip"
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} size="lg" />
                                                        <ReactTooltip
                                                            id="deleteTip"
                                                            place="bottom"
                                                            effect="solid"
                                                        >
                                                            Delete
                                                        </ReactTooltip>
                                                    </button>
                                                    {/* )} */}
                                                </td>
                                            ) : null}
                                        </tr>
                                    </tbody>
                                );
                            })
                        ) : (
                            <tbody>
                                <tr style={{ textAlign: "center", height: "75px" }}>
                                    <td colSpan="6">No Data Available</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>

                {/* pagination */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        padding: "10px",
                    }}
                >
                    <div className="mb-6">
                        <label>Items per page:</label> &nbsp;
                        <select
                            onChange={(e) => handleItemsPerPageChange(e.target.value)}
                            style={{ borderRadius: "8px", cursor: "pointer" }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            {/* <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option> */}
                        </select>
                    </div>
                    <div style={{ paddingRight: "25px" }} className="pagination">
                        <button
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                border: "none",
                                backgroundColor: "#fff",
                            }}
                            disabled={currentPage === 1}
                            onClick={handleFirstPage}
                        >
                            {currentPage === 1 ? (
                                <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                            ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}

                        </button>
                        <button
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                border: "none",
                                backgroundColor: "#fff",
                            }}
                            disabled={currentPage === 1}
                            onClick={handlePrev}
                        >
                            {/* Prev */}
                            {currentPage === 1 ? (
                                <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                            ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
                        </button>
                        <span style={{ padding: "10px" }}>
                            Showing {currentPage} of {totalPages} pages
                        </span>
                        <button
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                border: "none",
                            }}
                            disabled={currentPage === totalPages}
                            onClick={handleNext}
                        >
                            {currentPage === totalPages ? (
                                <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                            ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
                        </button>
                        <button
                            style={{
                                padding: "5px",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                                border: "none",
                            }}
                            disabled={currentPage === totalPages}
                            onClick={handleLastpage}
                        >
                            {currentPage === totalPages ? (
                                <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                            ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TatList;
