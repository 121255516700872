/* Feature - to maintain header for site page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Bulkupload from "../../modules/site/bulkUpload";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import Logo from "../../components/logo";
import { useSelector, useDispatch } from 'react-redux';


//Function for site header
function Siteheader() {
  const dispatch = useDispatch();
  // modal for bulk upload button
  const [modalShow, setModalShow] = useState(false);

  // To fetch user role code & access based on role
  const role = useSelector(state => state.auth.role)
  const access = useSelector((state) => state.access.site)

  useEffect(() => {
    const roleCode = role[0]
    // console.log(access);
    // console.log(roleCode);
    if (access === null && roleCode !== null) {      
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode
      });
    }   
  }, [access])

  return (
    <div>
      <div>
        <Navbar bg="light" variant={"light"} expand="lg">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="headershadow">
            <Nav
              className="mr-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
            >
              <Sidebar />
              <Logo />
              {/* <Nav.Link as={Link} to="/home">Home</Nav.Link> */}
              {((access !== null && access.c))  ? (
                <div>
                  <Nav.Link
                    as={Link}
                    to="/siteupload"
                    className="position-absolute top-25 end-0 header"
                    style={{ color: "#000", paddingRight: "380px" }}
                  >
                    Create Site
                  </Nav.Link>
                </div>
              ) : (
                <div></div>
              )}

              {((access !== null && access.c))  ? (
                  
                <div>
                  <Nav.Link
                    onClick={() => setModalShow(true)}
                    className="position-absolute top-25 end-0 header"
                    style={{ color: "#000", paddingRight: "250px" }}
                  >
                    Bulk Upload
                  </Nav.Link>
                </div>
              ) : (
                <div></div>
              )}
              {modalShow && (
                <Bulkupload
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                ></Bulkupload>
              )}
              <LogoutButton />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
    </div>
  );
}

export default Siteheader;
