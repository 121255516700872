import {
  DEVICE_GET_LIST,
  DEVICE_GET_SUCCESS,
  DEVICE_GET_FAILED,
  DEVICE_DELETE_LIST,
  DEVICE_DELETE_SUCCESS,
  DEVICE_DELETE_FAILED,
  DEVICE_ADD,
  DEVICE_ADD_SUCCESS,
  DEVICE_ADD_FAILED,
  DEVICE_UPDATE,
  DEVICE_UPDATE_SUCCESS,
  DEVICE_UPDATE_FAILED,
  DEVICE_GET_SINGLE,
  DEVICE_GET_SINGLE_SUCCESS,
  DEVICE_GET_SINGLE_FAILED,
} from "../actions/deviceAction";

const inistialDeviceState = {
  deviceData: { result: [] },
  deviceAddData: [],
  isSubmitted: false,
  isLoading: false,
};

export default function DeviceReducer(state = inistialDeviceState, action) {
  switch (action.type) {
    case DEVICE_GET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case DEVICE_GET_SUCCESS:
      return {
        ...state,
        deviceData: action.payload.data,
        isSubmitted: false,
        isLoading: false,
      };
    case DEVICE_GET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DEVICE_DELETE_LIST:
      return {
        ...state,
      };
    case DEVICE_DELETE_SUCCESS:
      return {
        ...state,
      };
    case DEVICE_DELETE_FAILED:
      return {
        ...state,
      };
    case DEVICE_ADD:
      return {
        ...state,
        isLoading: true,
      };
    case DEVICE_ADD_SUCCESS:
      return {
        ...state,
        deviceAddData: action.payload,
        isSubmitted: true,
        isLoading: false,
      };
    case DEVICE_ADD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DEVICE_GET_SINGLE:
      return {
        ...state,
        isLoading: true,
      };
    case DEVICE_GET_SINGLE_SUCCESS:
      return {
        ...state,
        deviceGetSingle: action.payload,
        isLoading: false,
      };
    case DEVICE_GET_SINGLE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DEVICE_UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case DEVICE_UPDATE_SUCCESS:
      return {
        ...state,
        deviceUpdateData: action.payload,
        isLoading: false,
      };
    case DEVICE_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case "CLEAR_DEVICE_REDUCER":
      return {
        ...state,
        deviceData: {},
        deviceAddData: [],
      };
    default:
      return state;
  }
}
