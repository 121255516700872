import { call, put, takeLatest } from "@redux-saga/core/effects";
import { userService } from "../../services/service.index";

import {
  USER_ADD,
  USER_ADD_SUCCESS,
  USER_ADD_FAILED,
  USER_GET_LIST,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_SET_PASSWORD,
  USER_SET_PASSWORD_SUCCESS,
  USER_SET_PASSWORD_FAILED,
  ROLE_GET_LIST,
  ROLE_GET_SUCCESS,
  ROLE_GET_FAILED,
  USER_BULK_ADD,
  USER_BULK_ADD_SUCCESS,
  USER_BULK_ADD_FAILED,
  USER_DELETE_LIST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_GET_SINGLE,
  USER_GET_SINGLE_SUCCESS,
  USER_GET_SINGLE_FAILED,
  GET_APPROV_USERS,
  GET_APPROV_USERS_SUCCESS,
  GET_APPROV_USERS_FAILED,
  USER_GET_TECH,
  USER_GET_TECH_SUCESS,
  USER_GET_TECH_FAILED,
  USER_GET_APPROVER,
  USER_GET_APPROVER_SUCESS,
  USER_GET_APPROVER_FAILED
} from "../actions/userAction";

export function* watchAddUser() {
  yield takeLatest(USER_ADD, addUserSaga);
}

export function* watchGetUser() {
  yield takeLatest(USER_GET_LIST, getUserSaga);
}

export function* watchGetSingleUser() {
  yield takeLatest(USER_GET_SINGLE, getUserSingleSaga);
}

export function* watchApprovUsers() {
  yield takeLatest(GET_APPROV_USERS, getApprovUserSaga);
}

export function* watchCreatePass() {
  yield takeLatest(USER_SET_PASSWORD, setPassSaga);
}

export function* watchGetRole() {
  yield takeLatest(ROLE_GET_LIST, getRoleSaga);
}

export function* watchAddBulkUser() {
  yield takeLatest(USER_BULK_ADD, addBulkUserSaga);
}

export function* watchDeletekUser() {
  yield takeLatest(USER_DELETE_LIST, deleteUserSaga);
}

export function* watchGetUserTech() {
  yield takeLatest(USER_GET_TECH, getUserTechSaga);
}
export function* watchGetUserApprover() {
  yield takeLatest(USER_GET_APPROVER, getUserApproverSaga);
}

export function* addUserSaga(action) {
  try {
    const response = yield call(userService.addUserData, action.payload);
    // console.log("response saga", response);
    // console.log("action.payload saga", action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_ADD_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: USER_GET_LIST,
      });
      alert(
        "Email Sent Successfully to uploaded user. Please set password using email"
      );
    } else {
      yield put({
        type: USER_ADD_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e);
    // console.log("e", e.response.data);
    yield put({
      type: USER_ADD_FAILED,
    });
    alert(e.response.data);
  }
}

export function* getUserSaga(action) {
  try {
    // console.log("getUserData action", action)
    const response = yield call(userService.getUserData, action.payload);
    if (response.status === 200) {
      // console.log("getUserData response", response)
      yield put({
        type: USER_GET_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getUserData Error response", response)
      yield put({
        type: USER_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log("getUserData Error response", e)
    yield put({
      type: USER_GET_FAILED,
    });
  }
}

export function* getUserSingleSaga(action) {
  try {
    const response = yield call(userService.getSingleUser, action.payload);
    if (response.status === 200 || response.status === 204) {
      yield put({
        type: USER_GET_SINGLE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: USER_GET_SINGLE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: USER_GET_SINGLE_FAILED,
    });
  }
}
export function* getApprovUserSaga(action) {
  try {
    const response = yield call(userService.getApprovUsers, action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_APPROV_USERS_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getUserData Error response", response)
      yield put({
        type: GET_APPROV_USERS_FAILED,
      });
    }
  } catch (e) {
    // console.log("getUserData Error response", e)
    yield put({
      type: GET_APPROV_USERS_FAILED,
    });
  }
}
export function* setPassSaga(action) {
  try {
    const response = yield call(userService.setPassword, action.payload);
    // console.log("response saga", response);
    // console.log("action.payload saga", action.payload);
    if (response.status === 200) {
      yield put({
        type: USER_SET_PASSWORD_SUCCESS,
      });
      alert("Password created Successfully");
    } else {
      yield put({
        type: USER_SET_PASSWORD_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e);
    yield put({
      type: USER_SET_PASSWORD_FAILED,
    });
  }
}

export function* getRoleSaga(action) {
  try {
    // console.log("getRoleCodeData action", action)
    const response = yield call(userService.getRoleCodeData, action.payload);
    if (response.status === 200) {
      // console.log("getRoleCodeData response", response)
      yield put({
        type: ROLE_GET_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getRoleCodeData Error response", response)
      yield put({
        type: ROLE_GET_FAILED,
      });
    }
  } catch (e) {
    // console.log("getRoleCodeData Error response", e)
    yield put({
      type: ROLE_GET_FAILED,
    });
  }
}

export function* addBulkUserSaga(action) {
  try {
    const response = yield call(userService.addBulkUserData, action.payload);
    // console.log("response saga", response);
    // console.log("action.payload saga", action.payload);
    if (response.status === 201 || response.status === 200) {
      yield put({
        type: USER_ADD_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: USER_GET_LIST,
      });
      alert(
        "Email Sent Successfully to each uploaded user. Please set password using email"
      );
    } else {
      yield put({
        type: USER_BULK_ADD_FAILED,
      });
      // alert("Error during user ceration");
    }
  } catch (e) {
    // console.log("e", e);
    yield put({
      type: USER_BULK_ADD_FAILED,
    });
    alert(e.response.data);
  }
}

export function* deleteUserSaga(action) {
  try {
    // console.log("viewUserSaga action", action)
    const response = yield call(userService.deleteUserData, action.payload);
    if (response.status === 200) {
      // console.log("delete User Data response", response)
      yield put({
        type: USER_GET_LIST,
      });
    } else {
      // console.log("USER_DELETE_FAILED Error response", response)
      yield put({
        type: USER_DELETE_FAILED,
      });
    }
  } catch (e) {
    // console.log("e", e)
    yield put({
      type: USER_DELETE_FAILED,
    });
  }
}
export function* getUserTechSaga(action) {
  try {
    // console.log("getUserData action", action)
    const response = yield call(userService.getTechData, action.payload);
    if (response.status === 200) {
      // console.log("getUserData response", response)
      yield put({
        type: USER_GET_TECH_SUCESS,
        payload: response,
      });
    } else {
      // console.log("getUserData Error response", response)
      yield put({
        type: USER_GET_TECH_FAILED,
      });
    }
  } catch (e) {
    // console.log("getUserData Error response", e)
    yield put({
      type: USER_GET_FAILED,
    });
  }
}
export function* getUserApproverSaga(action) {
  try {
    // console.log("getUserData action", action)
    const response = yield call(userService.getApproverData, action.payload);
    if (response.status === 200) {
      // console.log("getUserData response", response)
      yield put({
        type: USER_GET_APPROVER_SUCESS,
        payload: response,
      });
    } else {
      // console.log("getUserData Error response", response)
      yield put({
        type: USER_GET_APPROVER_FAILED,
      });
    }
  } catch (e) {
    // console.log("getUserData Error response", e)
    yield put({
      type: USER_GET_APPROVER_FAILED,
    });
  }
}
