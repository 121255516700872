import { React, useEffect, useState } from 'react'
import { Fragment } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import Commonheader from '../../layouts/common/commonHeader';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator, faEye, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { accessService } from "../../services/service.index";
import CreatableSelect from 'react-select'
import Button from 'react-bootstrap/Button';


function UserroleModel(props) {

  // all props from custome role component
  const { featureaccess, loading, name, rolelist, sendrole, getupdateddata } = props;

  const [selectedOption, setSelectedOption] = useState([]); // dropdown slection option
  const [showData, setShowData] = useState(null); // to handle the show data based on cancle of feature from selected input


  // when this form is selected for custom role then only edit access will be enabled, which is handled by using folloiwng state change  
  const [data, setData] = useState([]) // set feature access data
  const [enableInput, setEnableInput] = useState(true) // to enable each filed
  const [roleCode, setRoleCode] = useState('') // to set role code
  const [newRoleName, SetNewRoleName] = useState(null) // new role name
  const [updatedData, setUpdatedData] = useState([]) // set new updated data


  // handle function to manage dropdown
  const options = rolelist?.map(eachData => ({
    value: eachData.role_code,
    label: eachData.role_name.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase())
  }));

  const handleOption = (option) => {
    // console.log(option);
    if (option !== null) {
      setSelectedOption(option);
      setRoleCode(option?.value)
      sendrole(option?.value)
      setShowData(option?.label)
    } else {
      setShowData(null)
      setData(null)
      setUpdatedData([])
    }
  }
  // console.log('loading', loading);

  // function to get each updated input
  const handleCheck = (event, code, access) => {
    const index = data.find(obj => obj.feature_code === code);
    if (index) {
      if (access === 'create') {
        index.is_create = event;
      }
      if (access === 'read') {
        index.is_read = event;
      }
      if (access === 'update') {
        index.is_update = event;
      }
      if (access === 'delete') {
        index.is_delete = event;
      }
      // console.log(index);
      setUpdatedData(data)
    }
  }
  // console.log(updatedData);
  const handleSubmit = (e) => {
    // console.log('updatedData send to the API');
    // console.log('updatedData send to the API', updatedData);
    if (selectedOption.length === 0) {
      alert('Please select default role')
    }
    if (newRoleName === null || newRoleName === '') {
      alert('Please enter "custom role name"')
    }
    if (updatedData.length === 0) {
      alert('The created role has the same access as the default role. Please review the access of each features and make any necessary changes')
    }
    if (newRoleName !== null && newRoleName !== '' && updatedData.length > 0) {
      getupdateddata(updatedData, newRoleName, roleCode)
    }
  }
  useEffect(() => {
    setData(null)
  }, [selectedOption])

  useEffect(() => {
    // console.log('useEffect FeatureAccess', featureaccess);
  }, [props])

  useEffect(() => {
    // console.log('data', data);
    // console.log('updatedData', updatedData);
  }, [data, updatedData])


  useEffect(() => {
    if (name === 'Custom Feature Access') {
      setEnableInput(false)
    }
    setData(featureaccess)
  }, [name, featureaccess])


  return (
    <div>
      <div>
        {/* model to handle access componet*/}
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {name}
            </Modal.Title>
          </Modal.Header>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Modal.Body>
              {name === 'Custom Feature Access' &&
                <div style={{ width: '100%', display: 'flex' }}>
                  <Container >
                    <input
                      type="text"
                      name="life"
                      className="form-control"
                      placeholder='*Enter custom role name'
                      required
                      style={{
                        height: '38px'
                      }}
                      onChange={(e) => SetNewRoleName(e.target.value)}
                    />
                  </Container>
                  <Container>
                    <CreatableSelect
                      isClearable
                      formatCreateLabel={() => undefined}
                      options={options}
                      onChange={(option) => handleOption(option)}
                      placeholder={'*Please select default role'}
                    />
                  </Container>
                  <br />
                  <br />
                  <br />
                </div>
              }
              <Container>
                <div className="mb-6" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  overflowY: 'scroll',
                  height: '400px'
                }}>
                  <table className="table-bordered" width="100%">
                    <thead className="table">
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "steelblue",
                          padding: "8px",
                          color: "#fff",
                        }}>
                        <th
                          style={{ padding: "18px" }}
                        >
                          Feature
                        </th>
                        <th
                          style={{ padding: "18px" }}
                        >
                          Add
                        </th>
                        <th
                          style={{ padding: "18px" }}
                        >
                          View
                        </th>
                        <th
                          style={{ padding: "18px" }}
                        >
                          Edit
                        </th>
                        <th
                          style={{ padding: "18px" }}
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    {/* html to show view feature access */}
                    {((name === 'View Feature Access' && featureaccess?.length > 0)) &&
                      (featureaccess?.map((eachData, index) => {
                        // console.log(Object.keys(eachData));
                        // console.log(Object.values(eachData));
                        return (
                          <tbody key={index} style={{ padding: "15px" }}>
                            <tr style={{ padding: "20px" }}>
                              <td style={{ textAlign: "left", padding: "8px" }}>
                                {(eachData.feature_name).replace(/_/g, " ")}
                              </td>
                              {/* {console.log(eachData)}
                                {console.log(index)} */}
                              {/* {Object.values(eachData).map((eachValue) => {
                                return ( */}
                              <Fragment>
                                <td
                                  style={{ textAlign: "center" }}
                                >
                                  {eachData.is_create ? (
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={eachData.is_create}
                                      disabled={enableInput}
                                      style={{
                                        display: "block",
                                        margin: "auto",
                                        alignItems: "center",
                                      }}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faSquareXmark}
                                      style={{
                                        border: "none",
                                        color: "#6c757d",
                                        background: "none",
                                      }}
                                    />
                                  )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {eachData.is_read ? (
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={eachData.is_read}
                                      disabled={enableInput}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faSquareXmark}
                                      style={{
                                        border: "none",
                                        color: "#6c757d",
                                        background: "none",
                                      }}
                                    />
                                  )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {eachData.is_update ? (
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={eachData.is_update}
                                      disabled={enableInput}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faSquareXmark}
                                      style={{
                                        border: "none",
                                        color: "#6c757d",
                                        background: "none",
                                      }}
                                    />
                                  )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {eachData.is_delete ? (
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      defaultChecked={eachData.is_delete}
                                      disabled={enableInput}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      size="lg"
                                      icon={faSquareXmark}
                                      style={{
                                        border: "none",
                                        color: "#6c757d",
                                        background: "none",
                                      }}
                                    />
                                  )}
                                </td>
                              </Fragment>
                              {/* );
                              })} */}
                            </tr>
                          </tbody>
                        )
                      })
                      )
                      // : (
                      //   <tbody>
                      //     <tr style={{ textAlign: "center", height: "75px" }}>
                      //       <td colSpan="11">No Data Available</td>
                      //     </tr>
                      //   </tbody>
                      // )
                    }
                    {((name === 'Custom Feature Access') && showData !== null && data !== null) &&
                      (data?.map((eachData, index) => {
                        // console.log(Object.keys(eachData));
                        // console.log(Object.values(eachData));
                        return (
                          <tbody key={index} style={{ padding: "15px" }}>
                            <tr style={{ padding: "20px" }}>
                              <td style={{ textAlign: "left", padding: "8px" }}>
                                {(eachData.feature_name).replace(/_/g, " ")}
                              </td>
                              {/* {console.log(eachData)} */}
                              {/* {console.log(index)} */}
                              {/* {Object.values(eachData).map((eachValue) => { */}
                              {/* const [key, value] = Object.entries(eachData)[0]; */}
                              {/* // console.log(key); */}
                              {/* // console.log(value); */}
                              {/* return ( */}
                              <Fragment>
                                <td
                                  key={index}
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={eachData.is_create}
                                    disabled={enableInput}
                                    style={{
                                      display: "block",
                                      margin: "auto",
                                      alignItems: "center",
                                    }}
                                    onChange={(e) => handleCheck(e.target.checked, eachData.feature_code, 'create')}
                                  />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={eachData.is_read}
                                    disabled={enableInput}
                                    onChange={(e) => handleCheck(e.target.checked, eachData.feature_code, 'read')}
                                  />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={eachData.is_update}
                                    disabled={enableInput}
                                    onChange={(e) => handleCheck(e.target.checked, eachData.feature_code, 'update')}
                                  />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={eachData.is_delete}
                                    disabled={enableInput}
                                    onChange={(e) => handleCheck(e.target.checked, eachData.feature_code, 'delete')}
                                  />
                                </td>
                              </Fragment>
                              {/* );
                              })} */}
                            </tr>
                          </tbody>
                        )
                      })
                      )
                      //  : (
                      //   <tbody>
                      //     <tr style={{ textAlign: "center", height: "75px" }}>
                      //       <td colSpan="11">No Data Available</td>
                      //     </tr>
                      //   </tbody>
                      // )
                    }
                    {/* {console.log(data.length)} */}
                    {/* {(data.length < 1 ) &&
                      <tbody>
                        <tr style={{ textAlign: "center", height: "75px" }}>
                          <td colSpan="11">No Data Available</td>
                        </tr>
                      </tbody>} */}
                  </table>
                </div>
              </Container>
            </Modal.Body>
          )}
          {name === 'Custom Feature Access' &&
            <Modal.Footer>
              <button
                className="float-end saveButton mt-5"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
            </Modal.Footer>
          }
        </Modal>
      </div >
    </div >
  );
}

export default UserroleModel
