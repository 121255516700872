/* Feature - Bulkupload of customer

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalFooter } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";

function Bulkupload(props) {
  const navigate = useNavigate();
  // To fetch details from redux
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.customer.isLoading);
  const [items, setItems] = useState({
    customer: [],
    contact: [],
  });

  // to disable button based on file input //
  const [disabled, setDisabled] = useState(true);

  // to disable button based on selection of wrong worksheet name //
  const [wsdisabled, setWSDisabled] = useState(false);

  // to disable button based on empty file input //
  const [disEmptyArray, setDisEmptyArray] = useState(false);

  const subscriber_id = useSelector((state) => state.auth.subscriber_id);
  const userid = useSelector((state) => state.auth.userid);

  //    console.log(props.show);

  // Read the excel sheet data and convert it to JSON file using following code

  const readExcel = (file) => {
    // console.log(file);

    // to handle button
    if (file.length === 0) {
      setDisabled(true);
    }

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" }); // wb - workbook

        const wsname = wb.SheetNames; // wsname - worksheet name

        const ws_cust_upload = wb.Sheets["Customer"]; // "Customer" name is the name directly taken from excel workbook sheet name.

        const ws_contacts = wb.Sheets["Contacts"]; // "Contacts" name is the name directly taken from excel workbook sheet name.

        const cust_header = [
          [
            "SrNo",
            "legal_name",
            "website",
            "address",
            "region",
            "city",
            "country",
            "state",
            "zip_code",
            "criticality_rating",
            "created_by",
          ],
        ]; // map header from backend

        const cust_upload_data = XLSX.utils.sheet_to_json(ws_cust_upload, {
          defval: null,
        });

        const CreateWStoMearge = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMearge, cust_header);

        const MeargehederDataCust = XLSX.utils.sheet_add_json(
          CreateWStoMearge,
          cust_upload_data,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        const MeagedDataCust = XLSX.utils.sheet_to_json(MeargehederDataCust, {
          defval: null,
        });

        MeagedDataCust.forEach(function (obj) {
          obj.created_by = null
      }, MeagedDataCust);

      MeagedDataCust.map((eachdata) => {

        if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
        }        

        const createdby = MeagedDataCust

        const index = createdby.findIndex(object => {
            return object.created_by === null;
        });

        // console.log(index);

        if (index !== -1) {
          createdby[index].created_by = enteredCreatedBy
        }

    })

    // console.log(MeagedDataCust);

        const contact_header = [
          [
            "SrNo",
            "legal_name",
            "first_name",
            "middle_name",
            "last_name",
            "email_id",
            'country_code',
            "mobile_number",
            "designation",
            "linkedin_profile",
            "type",
            "created_by",
          ],
        ];

        const contact_upload_data = XLSX.utils.sheet_to_json(ws_contacts, {
          defval: null,
        });

        const CreateWStoMeargecontact = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(CreateWStoMeargecontact, contact_header);

        const MeargehederDatacontact = XLSX.utils.sheet_add_json(
          CreateWStoMeargecontact,
          contact_upload_data,
          { skipHeader: true, origin: "A2" },
          { defval: null }
        );

        const MeagedDatacontact = XLSX.utils.sheet_to_json(
          MeargehederDatacontact,
          { defval: null }
        );

        MeagedDatacontact.forEach(function (obj) {
          obj.created_by = null
      }, MeagedDatacontact);

      MeagedDatacontact.map((eachdata) => {

        if (eachdata.created_by === null) {

            var enteredCreatedBy = userid;
        }        

        const createdby = MeagedDatacontact

        const index = createdby.findIndex(object => {
            return object.created_by === null;
        });

        // console.log(index);

        if (index !== -1) {
          createdby[index].created_by = enteredCreatedBy
        }

    })

    // console.log(MeagedDatacontact);


        //////////////////////////// client side validation - sheet name ///////////////////////////////////////

        if (wsname.includes("Customer") && wsname.includes("Contacts")) {
        } else {
          setWSDisabled(true);
          result = "error";
          alert(
            `Please verify the uploaded sheet. \n It doesn't includes following workbook: \n 1. Customer - work book with the name - "Customer" \n 2. Contacts - work book with the name of - "Contacts"  \n \n\n Note: Either you may have changed the work book name or you are not uploading correct format`
          );
        }

        //////////////////////////// client side validation -  ///////////////////////////////////////

        MeagedDataCust.forEach(function (obj) {
          delete obj.SrNo;
        });

        MeagedDatacontact.forEach(function (obj) {
          delete obj.SrNo;
        });

        var result = "";

        MeagedDataCust.map((eachdata) => {
          if (eachdata.legal_name == null) {
            var legal_name_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.website == null) {
            var website_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.address == null) {
            var address_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.region == null) {
            var region_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.city == null) {
            var city_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.country == null) {
            var country_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.state == null) {
            var state_index = MeagedDataCust.indexOf(eachdata);
          }
          if (eachdata.zip_code === null) {
            var zip_code_index = MeagedDataCust.indexOf(eachdata);
          }
          if (
            eachdata.zip_code !== null &&
            eachdata.zip_code.toString().length !== 6
          ) {
            var zip_code_valid_index = MeagedDataCust.indexOf(eachdata);
          }

          result += validatelegalName();
          result += validateWebsite();
          result += validateAddress();
          result += validateRegion();
          result += validateCity();
          result += validateCountry();
          result += validateState();
          result += validateZipCode();
          result += validatelengthZipCode();

          function validatelegalName() {
            if (eachdata.legal_name == null)
              return `Sl.No: ${
                legal_name_index + 1
              } - Legal name is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateWebsite() {
            if (eachdata.website == null)
              return `Sl.No: ${
                website_index + 1
              } - Website name is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateAddress() {
            if (eachdata.address == null)
              return `Sl.No: ${
                address_index + 1
              } - Address is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateRegion() {
            if (eachdata.region == null)
              return `Sl.No: ${
                region_index + 1
              } - Region is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateCity() {
            if (eachdata.city == null)
              return `Sl.No: ${
                city_index + 1
              } - City is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateCountry() {
            if (eachdata.country == null)
              return `Sl.No: ${
                country_index + 1
              } - Country is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateState() {
            if (eachdata.state == null)
              return `Sl.No: ${
                state_index + 1
              } - State is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validateZipCode() {
            if (eachdata.zip_code === null)
              return `Sl.No: ${
                zip_code_index + 1
              } - Zipcode is mandetory field (sheet name - Customer).\n`;
            return "";
          }
          function validatelengthZipCode() {
            // console.log((eachdata.zip_code).toString().length);
            if (
              eachdata.zip_code !== null &&
              eachdata.zip_code.toString().length !== 6
            )
              return `Sl.No: ${
                zip_code_valid_index + 1
              } - Please enter valid Zipcode (sheet name - Customer).\n`;
            return "";
          }
        });
        // console.log(MeagedDataCust);

        const customerData = MeagedDataCust.map((x) =>
          x.criticality_rating === null ? { ...x, criticality_rating: "" } : x
        );

        // console.log(customerData);
        resolve(MeagedDataCust);

        MeagedDatacontact.map((eachdata) => {
          if (eachdata.legal_name == null) {
            var legal_name_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.first_name == null) {
            var first_name_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.last_name == null) {
            var last_name_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.email_id == null) {
            var email_id_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.country_code === null) {
            var country_code_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.mobile_number === null) {
            var mobile_number_index = MeagedDatacontact.indexOf(eachdata);
          }
          if (
            eachdata.mobile_number !== null &&
            eachdata.mobile_number.toString().length !== 10
          ) {
            var mobile_number_lenght_index =
              MeagedDatacontact.indexOf(eachdata);
          }
          if (eachdata.designation == null) {
            var designation_index = MeagedDatacontact.indexOf(eachdata);
          }

          result += validateLegalName();
          result += validatefirstName();
          result += validateLastName();
          result += validateEmailID();
          result += validateCountryCode();
          result += validateMobileNumber();
          result += validatelenghtMobileNumber();
          result += validateDesignation();

          function validateLegalName() {
            if (eachdata.legal_name == null)
              return `Sl.No: ${
                legal_name_index + 1
              } - Legal name is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validatefirstName() {
            if (eachdata.first_name == null)
              return `Sl.No: ${
                first_name_index + 1
              } - First name is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateLastName() {
            if (eachdata.last_name == null)
              return `Sl.No: ${
                last_name_index + 1
              } - Last name is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateEmailID() {
            if (eachdata.email_id == null)
              return `Sl.No: ${
                email_id_index + 1
              } - Email ID is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateCountryCode() {
            if (eachdata.country_code === null)
              return `Sl.No: ${
                country_code_index + 1
              } - Country Code is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validateMobileNumber() {
            if (eachdata.mobile_number === null)
              return `Sl.No: ${
                mobile_number_index + 1
              } - Mobile Number is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
          function validatelenghtMobileNumber() {
            if (
              eachdata.mobile_number !== null &&
              eachdata.mobile_number.toString().length !== 10
            )
              return `Sl.No: ${
                mobile_number_lenght_index + 1
              } - Please enter valid mobile number (sheet name - Contacts). \n`;
            return "";
          }
          function validateDesignation() {
            if (eachdata.designation == null)
              return `Sl.No: ${
                designation_index + 1
              } - Designation is mandetory field (sheet name - Contacts). \n`;
            return "";
          }
        });

        const contactData = MeagedDatacontact.map((x) =>
          x.type === null ? { ...x, type: "" } : x
        );

        resolve(MeagedDatacontact);

        items.customer = customerData;
        items.contact = contactData;

        setItems({
          ...items,
          customer: customerData,
          contact: contactData,
        });

        if (MeagedDatacontact.length === 0 && MeagedDataCust.length === 0) {
          alert("Please enter atleast one Customer");
          setDisEmptyArray(true);
        }

        if (result == "") return setDisabled(false);
        // if (result == "") return true;
        alert(
          "Please resolve the following error in the attachment:\n\n" + result
        );
        return false;
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitCustomerData = [items.customer, items.contact];
    dispatch({
      type: "CUST_BULK_ADD",
      payload: [subscriber_id, submitCustomerData],
    });
    props.onHide();
    navigate("/customerlist");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bulk Upload of Customer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            />
          </div>
          <br />
          <br />
          <span>
            Download Customer Bulk Upload Template -
            <a
              href={`https://zongoweb.s3.ap-northeast-1.amazonaws.com/templates/ZV_Customer_Bulk_Upload_V1_0.xlsx`}
            >
              Click here
            </a>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={disabled || wsdisabled || disEmptyArray}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Bulkupload;
