import React, { useEffect } from "react";
import moment from "moment";
import S3BucketImage from "./s3BucketImages";

function ApprovalActivity(props) {
  const { MaintenanceActivity, activityName } = props;

  useEffect(() => {
    // console.log(props);
  }, [props]);

  return (
    <div style={{ margin: "auto" }}>
      <table
        className="table"
        style={{
          width: 1000,
          margin: "auto",
          border: "1px solid black",
          borderBottom: "none",
          tableLayout: "fixed",
        }}
      >
        <thead style={{ verticalAlign: "middle" }}>
          <tr>
            <th
              colSpan="9"
              style={{
                fontWeight: "bold",
                backgroundColor: "#0862B4",
                color: "white",
                textAlign: "center",
                borderBottom: "none",
                padding: "7px",
                borderTop: "1px black",
                margin: "0px",
                alignItems: "center",
              }}
            >
              {activityName}
            </th>
          </tr>
          <tr>
            <th colSpan="9" style={{ border: "1px solid" }}>
              Approval Type -&nbsp;
              {MaintenanceActivity?.approvals?.sequence_approval_type
                ? MaintenanceActivity?.approvals?.sequence_approval_type
                    ?.charAt(0)
                    .toUpperCase() +
                  MaintenanceActivity?.approvals?.sequence_approval_type?.slice(
                    1
                  )
                : MaintenanceActivity?.approvals?.parallel_approval_type
                    ?.charAt(0)
                    .toUpperCase() +
                  MaintenanceActivity?.approvals?.parallel_approval_type?.slice(
                    1
                  )}
            </th>
          </tr>
          <tr>
            <th
              scope="col"
              style={{
                wordWrap: "break-word",
                width: "70px",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              Sequence Number
            </th>
            <th
              scope="col"
              colSpan="2"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Approver Name
            </th>
            <th
              scope="col"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Designation
            </th>
            <th
              scope="col"
              colSpan="2"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Approved By - ID
            </th>
            <th
              scope="col"
              colSpan="2"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Approved Date & Time
            </th>
            <th
              scope="col"
              colSpan="1"
              style={{ wordWrap: "break-word", border: "1px solid black" }}
            >
              Signature
            </th>
          </tr>
        </thead>
        {MaintenanceActivity?.length !== 0
          ? MaintenanceActivity?.approvals?.approver_info.map(
              (eachData, index) => {
                return (
                  <tbody style={{ width: "100%" }} key={index}>
                    <tr>
                      <td
                        style={{
                          wordWrap: "break-word",
                          border: "1px solid black",
                          borderBottom: "1px solid black",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        colSpan="2"
                        style={{
                          wordWrap: "break-word",
                          border: "1px solid black",
                          borderBottom: "1px solid black",
                          verticalAlign: "middle",
                        }}
                      >
                        {eachData?.approved_by?.first_name}&nbsp;
                        {eachData?.approved_by?.middle_name}&nbsp;
                        {eachData?.approved_by?.last_name}
                      </td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          border: "1px solid black",
                          borderBottom: "1px solid black",
                          verticalAlign: "middle",
                        }}
                      >
                        {eachData?.approved_by?.designation}
                      </td>
                      <td
                        colSpan="2"
                        style={{
                          wordWrap: "break-word",
                          border: "1px solid black",
                          borderBottom: "1px solid black",
                          verticalAlign: "middle",
                        }}
                      >
                        {eachData?.approved_by?.email}
                      </td>

                      <td
                        colSpan="2"
                        style={{
                          wordWrap: "break-word",
                          border: "1px solid black",
                          borderBottom: "1px solid black",
                          verticalAlign: "middle",
                        }}
                      >
                        {moment(eachData?.approved_date).format(
                          "MMMM Do YYYY | hh:mm:ss A"
                        )}
                      </td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          overflow: "hidden",
                          border: "1px solid black",
                          borderBottom: "1px solid black",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {eachData.approver_signature ? (
                          <S3BucketImage
                            imageUrl={eachData?.approver_signature}
                            widthSize={"100px"}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  </tbody>
                );
              }
            )
          : null}
      </table>
    </div>
  );
}

export default ApprovalActivity;
