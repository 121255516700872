import axios from 'axios';
import React, { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import IMAGENAME from '../../medias/media.index';


const Plant = () => {
    // const { id } = useParams();
    // console.log(id);

    // useEffect(() => {

    //     axios.get(`http://api.zongovita.com/plant/${id}`)
    //         .then(res => {
    //             console.log(res);
    //             console.log(res.data);
    //         })

    // }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div style={{ paddingTop: "25px" }}>
                        <form
                            action=""
                            className="mt-5 border p-4 bg-light shadow rounded"
                            style={{
                                maxWidth: "400px",
                                margin: "0 auto",
                                padding: "20px",
                                // border: "1px solid #000",
                            }}
                        >


                            <div
                                style={{
                                    // border: "1px solid #000",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                <img
                                    style={{
                                        maxWidth: "10rem",
                                        margin: "auto",
                                        padding: "20px",

                                    }}

                                    src={IMAGENAME.qrAirportLogo} />

                            </div>
                            {/* <div
                                style={{
                                    marginTop: "2rem",
                                    marginBottom: "2rem"
                                }}
                            >
                                <h3>
                                    Welcome to Bengaluru Airport - Terminal 2
                                </h3>
                            </div> */}
                            <div
                            // style={{
                            //     border: "1px solid"
                            // }}
                            >

                                <div
                                    style={{
                                        // border: "1px solid",
                                        display: "flex",
                                        // justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            // border: "1px solid",
                                            width: "40%"
                                        }}
                                    >
                                        <h5
                                            style={{
                                                display: "inline",
                                                fontWeight: "bolder"
                                            }}
                                        >Plant Name</h5>
                                    </div>
                                    <h5
                                        style={{
                                            display: "inline"
                                        }}
                                    >:</h5>
                                    <h5
                                        style={{
                                            display: "inline",
                                            marginLeft: "10px"
                                        }}
                                    >Dracaena Draco</h5>
                                </div>

                                <div
                                    style={{
                                        // border: "1px solid",
                                        display: "flex",
                                        // justifyContent: "space-between"
                                    }}
                                >
                                    <div
                                        style={{
                                            // border: "1px solid",
                                            width: "40%"
                                        }}
                                    >
                                        <h5
                                            style={{
                                                display: "inline",
                                                fontWeight: "bolder"
                                            }}
                                        >Plant Location</h5>
                                    </div>
                                    <h5
                                        style={{
                                            display: "inline"
                                        }}
                                    >:</h5>
                                    <h5
                                        style={{
                                            display: "inline",
                                            marginLeft: "10px"
                                        }}
                                    >Terminal 2</h5>
                                </div>
                            </div>
                        </form>
                        <p
                            style={{
                                marginTop: "1rem",
                                marginLeft: "20px"
                            }}
                        >Thank you for scanning, have a good time !</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Plant

{/* <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div style={{ paddingTop: "25px" }}>
                            <form
                                action=""
                                className="mt-5 border p-4 bg-light shadow rounded"
                                style={{
                                    maxWidth: "400px",
                                    margin: "0 auto",
                                    padding: "20px",
                                }}
                            >
                                <h3 style={{ textAlign: "center" }}>
                                    Plant Name: Dracaena Draco
                                </h3>
                                <h3 >Location: T2</h3>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}