/* Feature - Logout component

Created by - Janaki J
*/

//importing dependencies and packages
import React from "react";
import { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import Commonheader from "../../layouts/common/commonHeader";
import Loader from "../loader/loader";
import { Navigate } from 'react-router-dom';

// function for Logout
const UserDetail = () => {
  const navigate = useNavigate();

  // from redux
  const dispatch = useDispatch();
  // const reducerToken = useSelector((state) => state.auth.userToken);
  const userProfileData = useSelector((state) => state.user.userSingleData);
  const email = useSelector((state) => state.auth.email);

  // useEffects
  // useEffect(() => {
  //   if (reducerToken === null) navigate("/");
  // }, [reducerToken]);

  useEffect(() => {
    // if (email === null);
    let viewUserObj = {
      email: email,
    };
    dispatch({
      type: "USER_GET_SINGLE",
      payload: viewUserObj,
    });
  }, [email]);

  let designation = userProfileData.designation;

  if (designation !== null || designation !== undefined) {
    var ignoredCharas = designation?.replace(/_/g, " ");
  }
  const handleBack = () => {
    // window.history.back();
    navigate('/home')
  };

  return (
    <div>
      <Commonheader />
      {/* <Loader /> */}
      {/*  to show loading effect */}
      <div className="container">
        <div className="col-md-12 offset-md-0">
          <div style={{ paddingTop: "10px", fontSize: "18px" }}>
            <form
              className="mt-5 border p-4 bg-light shadow rounded col-md-12"
            >
              <div className="formHeadings mb-2">User Profile</div>
              <div style={{ marginBottom: '10px' }}>
                <Card
                  style={{ width: 'auto' }}
                >
                  <Card.Header>
                    Name :  {userProfileData?.first_name}{userProfileData?.middle_name ? " " + userProfileData?.middle_name : ""}{" " + userProfileData?.last_name}
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      Email : {userProfileData?.email}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Mobile number : {userProfileData?.country_code} {userProfileData?.mobile_number}
                    </ListGroup.Item>
                    <ListGroup.Item style={{ textTransform: "capitalize" }}>
                      Designation : {ignoredCharas}
                    </ListGroup.Item>
                    <ListGroup.Item style={{ textTransform: "capitalize" }}>
                      Department : {userProfileData?.department?.map((el, index) => <span>{el}{userProfileData?.department?.length !== index + 1 ? ", " : "."}</span>)}
                    </ListGroup.Item>{" "}
                    <ListGroup.Item>
                      Status :{" "}
                      {userProfileData?.is_active ? "Active" : "Inactive"}
                    </ListGroup.Item>{" "}
                  </ListGroup>
                </Card>
              </div>
              <div>
                <button className="backButton"
                  // onClick={() => { navigate(-1) }}
                  onClick={handleBack}
                >Back</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
