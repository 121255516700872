import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
    INS_MAIN_GET_STATUS,
    INS_MAIN_GET_STATUS_SUCCESS,
    INS_MAIN_GET_STATUS_FAILED,

    INS_MAIN_GET_STATUS_OFFLINE,
    INS_MAIN_GET_STATUS_OFFLINE_SUCCESS,
    INS_MAIN_GET_STATUS_OFFLINE_FAILED,

    // FILTERED_INS_DATA,
    // FILTERED_INS_DATA_SUCCESS,
    // FILTERED_INS_DATA_FAILED,

    // FILTERED_MAIN_DATA,
    // FILTERED_MAIN_DATA_SUCCESS,
    // FILTERED_MAIN_DATA_FAILED,

} from '../actions/dashAction'

import { dashboardService } from "../../services/service.index";

export function* watchGetInsMainStatus() {
    yield takeLatest(INS_MAIN_GET_STATUS, getInsMainStatusSaga);
}

export function* watchGetInsMainOfflineStatus() {
    yield takeLatest(INS_MAIN_GET_STATUS_OFFLINE, getInsMainOfflineStatusSaga);
}
// export function* watchFilteredInsData() {
//     yield takeLatest(FILTERED_INS_DATA, InsDataSaga);
// }

// export function* watchFilteredMainData() {
//     yield takeLatest(FILTERED_MAIN_DATA, MainDataSaga);
// }

export function* getInsMainStatusSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(dashboardService.insMainStatusData, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: INS_MAIN_GET_STATUS_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: INS_MAIN_GET_STATUS_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: INS_MAIN_GET_STATUS_FAILED,
        });
    }
}

export function* getInsMainOfflineStatusSaga(action) {
    try {
        // console.log(action.payload);
        const response = yield call(dashboardService.insMainStatusDataOffline, action.payload);
        // console.log(response);
        if (response.status === 200) {
            yield put({
                type: INS_MAIN_GET_STATUS_OFFLINE_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: INS_MAIN_GET_STATUS_OFFLINE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: INS_MAIN_GET_STATUS_OFFLINE_FAILED,
        });
    }
}

// export function* InsDataSaga(action) {
//     try {
//         const response = yield call(dashboardService.filteredInsData, action.payload);
//         // console.log(response);
//         if (response.status === 200) {
//             yield put({
//                 type: FILTERED_INS_DATA_SUCCESS,
//                 payload: response.data,
//             });
//         } else {
//             yield put({
//                 type: FILTERED_INS_DATA_FAILED,
//             });
//         }
//     } catch (e) {
//         yield put({
//             type: FILTERED_INS_DATA_FAILED,
//         });
//     }
// }
// export function* MainDataSaga(action) {
//     try {
//         const response = yield call(dashboardService.filteredMainData, action.payload);
//         // console.log(response);
//         if (response.status === 200) {
//             yield put({
//                 type: FILTERED_MAIN_DATA_SUCCESS,
//                 payload: response.data,
//             });
//         } else {
//             yield put({
//                 type: FILTERED_MAIN_DATA_FAILED,
//             });
//         }
//     } catch (e) {
//         yield put({
//             type: FILTERED_MAIN_DATA_FAILED,
//         });
//     }
// }