/* Feature - to create Auth

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { useContext, useDebugValue } from "react";
import { AuthContext } from "../context/authContext";

const useAuth = () => {
    const { auth, setAuth } = useContext(AuthContext);   

    useDebugValue(auth, auth => auth ? "Logged In" : "Logged Out")
    return useContext(AuthContext);
}

export default useAuth;