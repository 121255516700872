import React, { useState, useEffect } from "react";
import Commonheader from "../../../layouts/common/commonHeader";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/loader/loader";
import "../../workOrder/schStatusStyle.css";
import WorkStatus from "../workStatus";


function TicketStatus() {

  const isLoading = useSelector((state) => state.maintenance.isLoading);
  const [key, setKey] = useState("");
  const [assetType, setAssetType] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);


  // console.log(selectedItem);

  const dispatch = useDispatch();

  const maintenanceStatus = useSelector((state) => state.maintenance.maintenanceStatus);
  const userid = useSelector(state => state.auth.userid);


  // console.log(maintenanceStatus);
  // console.log(key);

  // Function to handle next button
  let totalPages = 0;
  var maintList = [];
  if (maintenanceStatus.result !== undefined) {
    maintList = maintenanceStatus.result;
  }
  if (maintenanceStatus?.total_count !== undefined) {
    totalPages = Math.ceil(maintenanceStatus.total_count / itemsPerPage);
  }

  const handleNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };
  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  //Function to handel clear
  const handleClearButton = () => {
    setSearchInput("");
  };

  const handleTabKey = (val) => {
    // console.log('Tab key val', val);
    setKey(val)
    setCurrentPage(1);
  }
  const handleType = (val) => {
    // console.log('Type val', val);
    setAssetType(val.toLowerCase())
    setCurrentPage(1);
  }

  
  const handleStartDate = (e) => {
    // console.log(e.target.value);
    e.preventDefault();
    setStartDate(e.target.value)
  }
  const handleEndDate = (e) => {
    // console.log(e.target.value);
    e.preventDefault();
    setEndDate(e.target.value)
  }
 

  useEffect(() => {
    if (currentPage && currentPage > 0) {
      dispatch({
        type: "MAINTENANCE_STATUS",
        payload: [currentPage, itemsPerPage, key, assetType, userid, startDate, endDate, searchInput],
      });
    }
  }, [currentPage, itemsPerPage, key, assetType, userid, startDate, endDate, searchInput]);
 

  return (
    <div>
      <Commonheader />
      {isLoading && (
        <div className="">
          <Loader />
        </div>
      )}
      <WorkStatus
        sendTabKeyData={handleTabKey}
        sendType={handleType}
        data={maintList}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleFirstPage={handleFirstPage}
        handleLastpage={handleLastpage}
        handleItemsPerPageChange={handleItemsPerPageChange}
        handleSearchInputChange={handleSearchInputChange}
        handleClearButton={handleClearButton}
        searchInput={searchInput}
        totalPages={totalPages}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        name={"Ticket"}
        startDate={startDate}
        handleStartDate={handleStartDate}
        endDate={endDate}
        handleEndDate={handleEndDate}
      />

    </div >
  );
}

export default TicketStatus;
