/* Feature - to create AuthProvider

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AuthContext } from './authContext';



// function to create Authprovider
export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const reducerToken = useSelector(state => state.auth.userToken)
    useEffect(() => {
        if (reducerToken) {
            // console.log("AuthProvider reducerToken", reducerToken);
            // console.log("AuthProvider auth", auth);
            if (auth === null) {
                setAuth(reducerToken);
            }
        }
    }, [reducerToken])
    
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider >
    )
}

