//Feature - Get all Onboarded site list

//Created by - Mona R
//Updated by - Janaki J

//importing dependencies and packages
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faUserPlus,
  faAngleLeft,
  faAngleRight,
  faClose,
  faContactBook,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import ContactUpload from "./contactUpload";
import ReactTooltip from "react-tooltip";
import Bulkupload from "./bulkUpload";
import BackButton from "../../components/BackButton/BackButton";
//Function to fetch all sites
function SiteList() {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState("");
  //  to fetch the data from redux
  const siteData = useSelector((state) => state.site.siteData);
  const isLoading = useSelector((state) => state.site.isLoading);
  // //useState

  // Add state variables for current page and items per page -pai
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //add toggle Button
  const [button3, setButton3] = useState(true);

  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.role);
  const access = useSelector((state) => state.access.site);

  //Get all site details
  const getSiteDetails = () => {
    dispatch({
      type: "SITE_GET_LIST",
      payload: siteData,
    });
  };

  //useEffects
  useEffect(() => {
    if (siteData.length == 0) {
      // console.log("siteData", siteData);
      getSiteDetails();
    }
  }, []);

  //Delete site from list
  const handleDelete = (_id) => {
    // console.log(_id);
    dispatch({
      type: "SITE_DELETE_LIST",
      payload: _id,
    });
  };
  const addContact = (_id) => {
    setModalShow(true);
    setId(_id);
  };
  // Function to handle next button
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(currentPage - currentPage + 1);
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  //Function to handel clear
  const handleClearButton = () => {
    setSearchInput("");
  };

  // Function to handle search submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let keyWord = e.target.value;
    // Check if siteData is defined
    if (siteData) {
      // Filter siteData based on search input
      const filteredData = siteData.filter((site) => {
        return (
          (site &&
            site.site_name &&
            site.site_name.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.website &&
            site.website.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.address &&
            site.address.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.region &&
            site.region.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.city &&
            site.city.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.country &&
            site.country.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.state &&
            site.state.toLowerCase().includes(keyWord.toLowerCase())) ||
          (site &&
            site.zip_code &&
            site.zip_code.toString().includes(keyWord)) ||
          (site &&
            site.site_criticality_rating &&
            site.site_criticality_rating.toString().includes(keyWord)) ||
          (site &&
            site.legal_name &&
            site.legal_name.toLowerCase().includes(keyWord.toLowerCase()))
        );
      });
      setFilteredData(filteredData);
      setCurrentPage(1);
    }
  };

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Slice the filteredData array to only show the appropriate items
  let currentItems = [];
  if (searchInput === "") {
    currentItems = siteData.slice(indexOfFirstItem, indexOfLastItem);
  } else {
    currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  }

  // Calculate the total number of pages
  let totalPages = 0;
  if (searchInput === "") {
    totalPages = Math.ceil(siteData.length / itemsPerPage);
  } else {
    totalPages = Math.ceil(filteredData.length / itemsPerPage);
  }

  useEffect(() => {
    const roleCode = role[0];
    // console.log(roleCode);
    if (access === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role]);

  useEffect(() => {
    // console.log('accessSite', access);
  }, [access]);

  return (
    <div>
      {isLoading && <Loader />} {/* to show the Loading effect */}
      <BackButton />
      <div className="col-md-12">
        <div
          className="listHeadings"
          style={{
            fontSize: "20px",
            paddingLeft: "100px",
          }}
        >
          Site List
          <div style={{ float: "right" }}>
            <form
              className="mb-4"
              onChange={handleSearchSubmit}
              onSubmit={handleSearchInputChange}
            >
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "5px",
                }}
              />
              <span
                type="reset"
                onClick={handleClearButton}
                style={{
                  paddingLeft: "8px",
                  marginRight: "25px",
                }}
              >
                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
              </span>
            </form>
          </div>
          <div style={{ paddingLeft: "8px" }}>
            Site (List & Edit) & Site Contact (Create & List)
          </div>
        </div>
        {/* Table to view Site list */}
        <div className="mb-6">
          <table className="table-bordered" width="100%">
            <thead>
              {/* Table heading to display list of Site */}

              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}
              >
                <th
                  scope="col"
                  style={{
                    paddingLeft: "5px",
                    textAlign: "center",
                    width: "5%",
                  }}
                >
                  S. No
                </th>
                <th scope="col" style={{ textAlign: "left", padding: "5px" }}>
                  Site Name
                </th>
                <th
                  scope="col"
                  style={{ width: "18%", textAlign: "left", padding: "5px" }}
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    width: "10%",
                    wordWrap: "break-word",
                  }}
                >
                  Website
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "left", padding: "5px", width: "20%" }}
                >
                  Address
                </th>
                <th scope="col" style={{ textAlign: "left", padding: "15px" }}>
                  Region
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "center", padding: "15px" }}
                >
                  City
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "center", padding: "15px" }}
                >
                  Country
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "center", padding: "15px" }}
                >
                  State
                </th>
                <th scope="col" style={{ padding: "10px" }}>
                  Zipcode
                </th>
                <th scope="col" style={{ width: "8%" }}>
                  Criticality Rating
                </th>

                <th
                  scope="col"
                  colSpan="3"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            {currentItems !== null && currentItems.length > 0 ? (
              currentItems.map((eachData, index) => {
                return (
                  <tbody key={eachData._id} style={{ padding: "15px" }}>
                    <tr style={{ padding: "20px" }}>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {(currentPage - 1) * itemsPerPage + (index + 1)}
                      </td>
                      <td
                        style={{
                          padding: "15px",
                          textAlign: "left",
                          width: "12%",
                        }}
                      >
                        {eachData.site_name}
                      </td>
                      <td
                        style={{
                          paddingLeft: "8px",
                          textAlign: "left",
                          wordBreak: "break-word",
                        }}
                      >
                        {eachData.legal_name}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          wordBreak: "break-word",
                          padding: "8px",
                          width: '25%'
                        }}
                      >
                        {eachData.website}
                      </td>
                      <td style={{ paddingLeft: "8px" }}>{eachData.address}</td>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {eachData.region}
                      </td>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {eachData.city}
                      </td>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {eachData.country}
                      </td>
                      <td style={{ textAlign: "center", padding: "15px" }}>
                        {eachData.state}
                      </td>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {eachData.zip_code}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.site_criticality_rating}
                      </td>

                      {access !== null && access.u ? (
                        <td style={{ textAlign: "center" }}>
                          {/* <Link to={`/site/update/${eachData._id}`}> */}
                          <button
                            onClick={() =>
                              navigate(`/site/update/${eachData._id}`)
                            }
                            style={{
                              border: "none",
                              color: "#2D7DC1",
                              background: "none",
                            }}
                            data-tip
                            data-for="updateTip"
                          >
                            <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                            <ReactTooltip
                              id="updateTip"
                              place="bottom"
                              effect="solid"
                            >
                              Edit
                            </ReactTooltip>
                          </button>
                          {/* </Link> */}
                        </td>
                      ) : (
                        <td style={{ textAlign: "center" }}>
                          <button
                            onClick={() =>
                              navigate(`/site/update/${eachData._id}`)
                            }
                            style={{
                              border: "none",
                              color: "#2D7DC1",
                              background: "none",
                            }}
                            data-tip
                            data-for="viewTip"
                          >
                            <FontAwesomeIcon icon={faEye} size="lg" />
                            <ReactTooltip
                              id="viewTip"
                              place="bottom"
                              effect="solid"
                            >
                              Edit
                            </ReactTooltip>
                          </button>
                        </td>
                      )}
                      {access !== null && access.d ? (
                        <td style={{ textAlign: "center" }}>
                          <Link>
                            <button
                              onClick={() => {
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && handleDelete(eachData._id);
                              }}
                              style={{
                                border: "none",
                                color: "#2D7DC1",
                                background: "none",
                              }}
                              data-tip
                              data-for="deleteTip"
                            >
                              <FontAwesomeIcon icon={faTrash} size="lg" />
                              <ReactTooltip
                                id="deleteTip"
                                place="bottom"
                                effect="solid"
                              >
                                Delete
                              </ReactTooltip>
                            </button>
                          </Link>
                          {/* Delete button */}
                        </td>
                      ) : null}
                      {access !== null && access.c ? (
                        <td style={{ textAlign: "center" }}>
                          <div>
                            <button
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                border: "none",
                                color: "#2D7DC1",
                                background: "none",
                              }}
                              data-tip
                              data-for="uploadTip"
                            >
                              <FontAwesomeIcon icon={faUserPlus} size="lg" />
                              <ReactTooltip
                                id="uploadTip"
                                place="bottom"
                                effect="solid"
                              >
                                Customer Contact
                              </ReactTooltip>
                            </button>
                            <ul className="dropdown-menu">
                              <li className="pt-1">
                                <button
                                  className="dropdwn"
                                  style={{
                                    border: "none",
                                    background: "none",
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                  onClick={() => addContact(eachData._id)}
                                >
                                  Add Contact
                                </button>
                              </li>
                              <li className="pb-2">
                                <button
                                  className="dropdwn"
                                  style={{
                                    border: "none",
                                    background: "none",
                                    position: "relative",
                                    textAlign: "center",
                                  }}
                                  onClick={() =>
                                    navigate("/sitecontact/" + eachData._id)
                                  }
                                >
                                  View Contact
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      ) : (
                        <td style={{ textAlign: "center" }}>
                          <button
                            onClick={() =>
                              navigate("/sitecontact/" + eachData._id)
                            }
                            style={{
                              border: "none",
                              color: "#2D7DC1",
                              background: "none",
                            }}
                            data-tip
                            data-for="contactTip"
                          >
                            <FontAwesomeIcon icon={faContactBook} size="lg" />
                            <ReactTooltip
                              id="contactTip"
                              place="bottom"
                              effect="solid"
                            >
                              View Contact
                            </ReactTooltip>
                          </button>
                        </td>
                      )}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr style={{ textAlign: "center", height: "75px" }}>
                  <td colSpan="12">No Data Available</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "30px",
            padding: "10px",
          }}
        >
          <div className="mb-6">
            <label>Items per page:</label>&nbsp;
            <select
              onChange={(e) => handleItemsPerPageChange(e.target.value)}
              style={{ borderRadius: "8px", cursor: "pointer" }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              {/* <option value={siteData.length}>All</option> */}
            </select>
          </div>

          <div style={{ paddingRight: "25px" }} className="pagination">
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={currentPage === 1}
              onClick={handleFirstPage}
            >
              {currentPage === 1 ? (
                <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}
            </button>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "#fff",
              }}
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              {/* Prev */}
              {currentPage === 1 ? (
                <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
            </button>
            <span style={{ padding: "10px" }}>
              Showing {currentPage} of {totalPages} pages
            </span>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "none",
              }}
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              {currentPage === totalPages ? (
                <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleRight} color="steelblue" />)}
            </button>
            <button
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                border: "none",
              }}
              disabled={currentPage === totalPages}
              onClick={handleLastpage}
            >
              {currentPage === totalPages ? (
                <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
              ) : (<FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />)}
            </button>
          </div>
        </div>
        {modalShow && (
          <ContactUpload
            show={modalShow}
            onHide={() => setModalShow(false)}
            id={id}
          />
        )}
      </div>
    </div>
  );
}

export default SiteList;
