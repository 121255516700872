import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";


function addPlantData(data) {
  // console.log(data);
  return serviceBase.post(CONSTANTS.PLANT_ADD, data);
}

function getPlantData(data) {
  // console.log(data);
  return serviceBase.get(
    CONSTANTS.PLANT_GET_ALL + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}`
  );
}

function getSinglePlantData(data) {
  // console.log(data[2]);
  return serviceBase.get(
    CONSTANTS.PLANT_GET_SINGLE + `${data}`
  );
}

function updatePlantData(data) {
  // console.log(data);
  return serviceBase.patch(
    CONSTANTS.PLANT_UPDATE + `${data[1]}`, data[0]
  );
}

function getPlantSummary(data) {
  // console.log(data);
  return serviceBase.get(
    CONSTANTS.PLANT_GET_SUMMARY + `?page=${data[0]}&limit=${data[1]}&key=${data[2]}&verifyStatus=${data[3]}`
  );
}

function getPlantHistory(data) {
  // console.log(data);
  return serviceBase.get(
    CONSTANTS.PLANT_GET_HISTORY + `${data}`
  );
}

function getPlantRFID(data) {
  // console.log(data);
  return serviceBase.get(
    CONSTANTS.PLANT_RFID + `?page=${data[0]}&limit=${data[1]}`
  );
}

function getPlantRFIDAreaWise(data) {
  // console.log(CONSTANTS.PLANT_RFID_LOCATION_WISE + data);
  return serviceBase.get(
    CONSTANTS.PLANT_RFID_LOCATION_WISE + data
  );
}

function getPlantDashboard() {
  // console.log('plant dashboard log');
  return serviceBase.get(CONSTANTS.PLANT_DASH_BOARD);
}

export const plantDetails = {
  addPlantData,
  getPlantData,
  getSinglePlantData,
  updatePlantData,
  getPlantSummary,
  getPlantHistory,
  getPlantRFID,
  getPlantRFIDAreaWise,
  getPlantDashboard
};