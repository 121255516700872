/* Feature - Reducer for Login

Created by - Mona R
Updated by - Mona R */

import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
} from '../actions/authActions';

const inistialAuthState = {
    isLoading: false,
    userToken: null,
    subscriber_id: null,
    userid: null,
    role: [],
    email:null,
    sites_id:[],
};

export default function AuthReducer(state = inistialAuthState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                userToken: action.payload.data.accessToken,
                subscriber_id: action.payload.data.subscriber_id,
                userid: action.payload.data.userid,
                email:action.payload.data.email,
                role: action.payload.data.role,
                sites_id:action.payload.data.sites,
                isLoading: false,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                userToken: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoading: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userToken: null,
                subscriber_id: null,
                userid: null,
                role: [],
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                userToken: null,
                isLoading: false,
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                isLoading: true,
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                userToken: action.payload.accessToken,
                isLoading: false,
            };
        case "RE_LOGIN":
            return {
                ...state,
                userToken: action.payload.data.accessToken,
                subscriber_id: action.payload.data.subscriber_id,
                userid: action.payload.data.userid,
                role: action.payload.data.role,
                sites_id:action.payload.data.sites,
                isLoading: false,
            };
        default:
            return state;
    }
};