import React, { useEffect, useState } from 'react';
import './AssetDetails.css'; // Import the CSS file
import axios from 'axios';
import S3BucketImage from '../report/pdfPreview/s3BucketImages';
import { useParams, useLocation } from 'react-router-dom';
import IMAGENAME from '../../medias/media.index';
import ImageViewer from '../../components/ImageViewer/ImageViewer';
import styles from './verification.module.css'
import moment from 'moment';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const AssetDetails = () => {
    const query = useQuery();
    const id = query.get('id');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [zoom, setZoom] = useState(1);

    const handleZoomIn = () => setZoom(zoom + 0.1);
    const handleZoomOut = () => setZoom(zoom - 0.1);

    const [asset, setAsset] = useState({

        // asset_id: "ZV/A/01",
        // asset_name: "Wall Fan -1",
        // make: "Havells",
        // model: "V3",
        // serial_number: null,
        // rating_capacity: "Not available",
        // uom: "Not available",
        // year_of_manufacture: null,
        // year_of_installation: null,
        // expected_life_in_month: null,
        // criticality: "2",
        // area: "Office room",
        // location: "Second Floor",
        // building: null,
        // block_tower: null,
        // floor: null,
        // wing: null,
        // department: "HVAC",
        // accessibility: "",
        // remarks: null
    })

    const getAssetDetails = async () => {
        try {
            let getData = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/public/asset-details?id=${id}`)
            if (getData?.status != 200) {
                setAsset({})
            } else {
                setAsset(getData.data)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (id) {
            getAssetDetails()
        }
    }, [id])
    const renderField = (label, value) => (
        <div className="asset-field">
            <div style={{ minWidth: '12rem' }} >
                <span className="asset-label">{label}:</span>
            </div>
            <div>
                <span className="asset-value">{value || 'Not available'}</span>
            </div>
        </div>
    );

    return (
        <div className="tab-content">
            <h2>Asset Details</h2>
            {asset?.asset_id ? <>
                <div className="asset-details">
                    <div className="asset-image" onClick={() => setIsModalOpen(true)} >
                        <ImageViewer
                            imageUrl={asset.asset_image}
                            widthSize={"100%"}
                            maxHeight={"auto"}
                            maxWidth={"30%"}
                        />
                    </div>
                    <div className="group">
                        <h3>Data </h3>
                        {renderField('Asset ID', asset.asset_id)}
                        {renderField('Asset Name', asset.asset_name)}
                        {renderField('Location', asset.location)}
                        {renderField('Asset Type', asset.asset_type)}
                    </div>
                    <div className="group">
                        <h3>Other Details</h3>
                        {renderField('BU Code', asset.bu_code)}
                        {renderField('GL Code', asset.gl_code)}
                        {renderField('Asset Class', asset.asset_class)}
                        {renderField('Asset Tag', asset.asset_tag)}
                        {renderField('Cost Center', asset.cost_center)}
                        {renderField('Installation Date', new Date(asset.installation_date).toLocaleDateString())}
                        {renderField('Acquisition Date', new Date(asset.acquisition_date).toLocaleDateString())}
                        {/* {renderField('Other Value', asset.other_value)} */}
                        {/* {renderField('Unit Cost', asset.unit_cost)} */}
                        {renderField('Description', asset.description)}
                        {renderField('Invoice No', asset.invoice_no)}
                        {renderField('Invoice Date', new Date(asset.invoice_date).toLocaleDateString())}
                        {renderField('Useful Life (Months)', asset.useful_life)}
                        {/* {renderField('Salvage Value', asset.salvage_value)} */}
                        {renderField('Supplier Name', asset.supplier_name)}
                        {renderField('Supplier Location', asset.supplier_location)}
                        {renderField('Supplier Code', asset.supplier_code)}
                        {/* {renderField('Cost', asset.cost)} */}
                        {renderField('Quantity', asset.quantity)}
                        {renderField('User', asset.user)}
                        {renderField('Verified', asset.verified ? 'Yes' : 'No')}
                    </div>
                </div>
                {isModalOpen && (
                    <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            {/* <button className="modal-close" onClick={() => setIsModalOpen(false)}>X</button>
                        <button className="modal-zoom" onClick={handleZoomIn}>+</button>
                        <button className="modal-zoom" onClick={handleZoomOut}>-</button> */}

                            <div
                            // style={{ transform: `scale(${zoom})` }}
                            >
                                <S3BucketImage
                                    imageUrl={asset.asset_image}
                                    widthSize={"100%"}
                                />
                            </div>
                            {/* <img
                            src={asset.asset_image}
                            alt={asset.asset_name}
                            style={{ transform: `scale(${zoom})` }}
                        /> */}
                        </div>
                    </div>
                )}
            </> : <p>Asset details will be displayed here.</p>
            }
        </div>
    );
};

const AssetHistory = () => {

    const query = useQuery();
    const id = query.get('id');
    const [verificationDetails, setVerificationDetails] = useState([])

    const getVerificationDetails = async () => {
        try {
            let getVerificationData = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/public/verification-record?id=${id}`)
            // console.log("getVerificationData", getVerificationData)
            if (getVerificationData?.status != 200) {
                setVerificationDetails([])
            } else {
                setVerificationDetails(getVerificationData.data)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (id) {
            getVerificationDetails()
        }
    }, [id])
    return (
        <div className="tab-content">
            <h2>Verification Details</h2>
            {verificationDetails?.length > 0 ? <>
                <div className={styles.timelineContainer}>
                    {verificationDetails.map((item, index) => (
                        <div key={index} className={styles.timelineItem}>
                            <div className={styles.timelineDate} style={{ fontSize: '18px' }}>
                                {item.verification_date ? `${moment(item.verification_date).format('lll')}` : ""}
                            </div>
                            <div className={styles.timelineContent}>
                                <div className={styles.verifiedBy}>Verified by : {item.verified_by}</div>
                                <div className={styles.comments}>
                                    <div className={styles.verifiedBy}>Comments :</div>
                                    {item.comments.map((cmt, cIndex) => (
                                        <div key={cIndex} className={styles.comment}>
                                            - {cmt}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </> : <p>Asset verification details will be displayed here.</p>
            }
        </div>
    );
};

const AssetPage = () => {
    const [activeTab, setActiveTab] = useState('details');

    return (
        <div className="container">

            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <img
                    style={{ cursor: "pointer", margin: 'auto' }}
                    className="mt-2 mx-2"
                    src={IMAGENAME.logo}
                    width="auto"
                    alt="logo"
                    height="25"
                />
            </div>
            <div className="tabs">
                <button
                    className={activeTab === 'details' ? 'tab active' : 'tab'}
                    onClick={() => setActiveTab('details')}
                >
                    Asset Details
                </button>
                <button
                    className={activeTab === 'history' ? 'tab active' : 'tab'}
                    onClick={() => setActiveTab('history')}
                >
                    History
                </button>
            </div>
            <div className="content">
                {activeTab === 'details' && <AssetDetails />}
                {activeTab === 'history' && <AssetHistory />}
            </div>
        </div>
    );
};

export default AssetPage;
