import React, { useState } from 'react'
import IMAGENAME from '../../medias/media.index';
import { Document, Page, pdfjs } from "react-pdf";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import BaslFooter from './baslFooter';
import BaslHeader from './baslHeader';

// Set workerSrc to the URL of the PDF worker file
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const HouseRule = () => {

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const Imagename1 = {
        HR: require("./Images/HouseRules.jpg"),
        QILA2: require("./Images/third_img.png")
    }
    return (


        < div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

            }
            }
        >
            <BaslHeader />
            <div style={{ width: '100%', display: 'flex', margin: '8rem 0' }}>
                <img style={{ margin: 'auto', width: '80%', }} src={Imagename1.HR} alt='gbtt' />
            </div>
            {/* <Document
                file="/HouseRule.pdf"
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document> */}
            {/* <footer>
                <h6>
                    <span>  © 2024 Powered by </span>
                    <a style={{ textDecoration: "none", color: 'black', cursor: 'pointer' }} href="https://www.zongovita.com" target="_blank" rel="noopener noreferrer">
                        ZongoVita Tech Pvt. Ltd.
                    </a>
                    <span> v0.10.35</span>
                </h6>
            </footer> */}
            <BaslFooter />
        </div >

    )
}

export default HouseRule