//DAILY BALANCE CALIBRATION LOG SHEET FOR THE MONTH OF
import React, { useEffect, useState } from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import moment from "moment";

function CalibrationAutoTitrator({
  responseData,
  tableHeaders,
  tableHeaders2,
  data,
  filterData,
  chklname,
  docNumber,
  depname,
  checkedby,
  instrumentCode,
  instCodeData,
  objheading,
  malur,
  medopharm,
  check1,
  check2,
  checked,
}) {
  const [resData, setResData] = useState([]);

  // header details
  const data1 = {
    data: depname,
    colSpan: "6",
  };
  const data2 = {
    data: docNumber,
  };

  const data3 = {
    data: `${chklname} FOR THE MONTH OF ${
      filterData?.selectedStartDate !== "" ? filterData?.selectedStartDate : ""
    } ${
      filterData?.selectedStartDate == filterData?.selectedEndDate
        ? ""
        : filterData?.selectedEndDate !== ""
        ? " - " + filterData?.selectedEndDate
        : ""
    }`,
    colSpan: "7",
  };

  const header1 = [
    { data: "Calibration SOP No.", textAlign: "center", rowSpan: "1" },
    { data: "Calibrated on", textAlign: "center", colSpan: "1" },
    { data: "Calibration due", textAlign: "center", rowSpan: "1" },
    { data: "Calibration Report No", textAlign: "center", rowSpan: "1" },
  ];

  const header1Ans = [
    { data: `${"-"}`, textAlign: "center", rowSpan: "1" },
    { data: `${"-"}`, textAlign: "center", colSpan: "1" },
    { data: `${"-"}`, textAlign: "center", rowSpan: "1" },
    { data: `${"-"}`, textAlign: "center", rowSpan: "1" },
  ];

  // const distinctCheckpoints3 = [...new Set(resData?.checklist?.map(item => item?.ins_approval_id))];
  const distinctCheckpoints3 = [
    ...new Set(
      resData?.checklist
        ?.map((item) => item?.ins_approval_id)
        .filter(
          (answer) => answer !== "" && answer !== undefined && answer !== null
        )
    ),
  ];
  const distinctCheckpoints4 = [
    ...new Set(
      resData?.checklist
        ?.map((item) => item?.performed_by)
        .filter(
          (answer) =>
            answer !== "" &&
            answer !== undefined &&
            answer !== null &&
            !Array.isArray(answer)
        )
    ),
  ];

  const data5 = [
    { data: `INSTRUMENT NAME`, colSpan: "3", rowSpan: "1", textAlign: "left" },
    {
      data: `${"AUTO TITRATOR"}`,
      colSpan: "12",
      rowSpan: "9",
      textAlign: "left",
    },
  ];

  const data6 = [
    { data: `Location`, colSpan: "3", rowSpan: "1", textAlign: "left" },
    { data: `${responseData?.parent !== undefined ? responseData?.parent?.location : '-'}`, colSpan: "12", rowSpan: "1", textAlign: "left" },
  ];

  const data7 = [
    { data: `Model No`, colSpan: "3", rowSpan: "1", textAlign: "left" },
    { data: `${responseData?.parent !== undefined ? responseData?.parent?.model : '-'}`, colSpan: "4", rowSpan: "1", textAlign: "left" },
    {
      data: `Instrument Code No`,
      colSpan: "4",
      rowSpan: "1",
      textAlign: "left",
    },
    { data: `${responseData?.parent !== undefined ? responseData?.parent?.parent_id : '-'}`, colSpan: "4", rowSpan: "1", textAlign: "left" },
  ];
  const data8 = [
    { data: `Details`, colSpan: "3", rowSpan: "1", textAlign: "left" },
    { data: `${"-"}`, colSpan: "12", rowSpan: "5", textAlign: "left" },
  ];

  // Dummy data array with answers for different volumes
  const groupAndMapData = () => {
    // Headings array
    const headings = [
      "Weight of flask + sample at 25 °C",
      "Weight of flask + stopper",
      "Weight of sample",
      "Weight per ml of the sample",
      "Actual volume of sample delivered",
      "Set volume displayed",
      "Difference of set and actual volume",
      "(Difference/Actual)*100",
    ];

    const volumes = ["1 mL", "5 mL", "10 mL", "20 mL"];
    const result = [];

    // Create a new array for each group of 32 responses
    let mainData =
      responseData?.maint_checklist &&
      responseData?.maint_checklist[0]?.maintenance_activities[0]?.checkpoints;

    if (mainData) {
      // console.log("mainData",mainData)
      for (let i = 0; i < mainData.length; i += 32) {
        const group = mainData.slice(i, i + 32);
        // console.log("group",group);

        // Create an object for each heading
        let mappedGroup = headings.map((heading, index) => {
          let obj = {
            data0: {
              value: heading,
              style: { textAlign: "left", colSpan: "3" },
            },
          };

          // Check for each volume and add corresponding answers
          volumes.forEach((volume, i) => {
            // console.log("volume",volume)
            const answerObj = group.find((d) => d.checkpoint.includes(volume));

            if (answerObj) {
              obj[`data${i + 1}`] = {
                value: answerObj?.cp_answer !== "" ? answerObj?.cp_answer : "-",
                style: { textAlign: "center", colSpan: "1" },
              };
            }
          });
          // console.log("obj",obj)
          return obj;
        });
        let obj1 = {
          data0: {
            value: "Acceptance criteria",
            style: {
              textAlign: "left",
              colSpan: "3",
            },
          },
          data1: {
            value: "± 0.6%",
            style: {
              textAlign: "center",
              colSpan: "1",
            },
          },
          data2: {
            value: "± 0.3%",
            style: {
              textAlign: "center",
              colSpan: "1",
            },
          },
          data3: {
            value: "± 0.3%",
            style: {
              textAlign: "center",
              colSpan: "1",
            },
          },
          data4: {
            value: "± 0.2%",
            style: {
              textAlign: "center",
              colSpan: "1",
            },
          },
        };
        mappedGroup.push(obj1);

        console.log("mappedGroup", mappedGroup);

        result.push(mappedGroup);
      }

      return result;
    }
    return [];
  };

  // Group and map the data
  const groupedData = groupAndMapData();

  const header1body = [
    { data: `1.0`, colSpan: "1", rowSpan: "1", textAlign: "left" },
    {
      data: `BURETTE - 1: (KF TITRATOR)`,
      colSpan: "11",
      rowSpan: "1",
      textAlign: "left",
    },
  ];
  const header5body = [
    {
      data: `Collect the solution from the burette to an empty dry flask and calculate weight per ml of the solution at 25 °C`,
      colSpan: "1",
      rowSpan: "1",
      textAlign: "left",
    },
  ];
  const header6body = [
    { data: `Parameters`, colSpan: "3", rowSpan: "1", textAlign: "left" },
    { data: `1 ml`, colSpan: "1", rowSpan: "1", textAlign: "center" },
    { data: `5 ml`, colSpan: "1", rowSpan: "1", textAlign: "center" },
    { data: `10 ml`, colSpan: "1", rowSpan: "1", textAlign: "center" },
    { data: `20 ml`, colSpan: "1", rowSpan: "1", textAlign: "center" },
  ];
  const header2body = [
    { data: `2.0`, colSpan: "1", rowSpan: "1", textAlign: "left" },
    {
      data: `BURETTE - 2: (AQUEOUS TITRATOR)`,
      colSpan: "11",
      rowSpan: "1",
      textAlign: "left",
    },
  ];
  const header3body = [
    { data: `3.0`, colSpan: "1", rowSpan: "1", textAlign: "left" },
    {
      data: `BURETTE - 3: (NON - AQUEOUS TITRATOR)`,
      colSpan: "11",
      rowSpan: "1",
      textAlign: "left",
    },
  ];
  const header4body = [
    { data: `4.0`, colSpan: "1", rowSpan: "1", textAlign: "left" },
    {
      data: `BURETTE - 4: (REDOX TITRATOR)`,
      colSpan: "11",
      rowSpan: "1",
      textAlign: "left",
    },
  ];

  const footer = [
    [
        { data: `CALIBRATED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
        { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        { data: `CHECKED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
        { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info && responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by !== '' ? responseData?.maint_checklist[0]?.maintenance_activities[0]?.performer_info[0]?.performed_by : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        { data: `APPROVED BY :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
        { data: `${responseData?.maint_checklist !== undefined && responseData?.maint_checklist[0]?.ins_approval_id ? responseData?.maint_checklist[0]?.ins_approval_id : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },

    ], [
        { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
        { data: `${responseData?.checklist !== undefined && responseData?.checklist[0]?.end_date ? moment(responseData?.checklist[0]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
        { data: `${responseData?.checklist !== undefined && responseData?.checklist[0]?.end_date ? moment(responseData?.checklist[0]?.end_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
        { data: `DATE :`, colSpan: '3', rowSpan: "1", textAlign: 'left' },
        { data: `${responseData?.checklist !== undefined && responseData?.checklist[0]?.ins_completed_date ? moment(responseData?.checklist[0]?.ins_completed_date).local().format("DD/MM/YY") : '-'}`, colSpan: '4', rowSpan: "9", textAlign: 'left' },
    ]

   ]

  useEffect(() => {
    setResData(responseData);
  }, [responseData]);

  return (
    <div>
      <ReportHearder
        data1={data1}
        data2={data2}
        data3={data3}
        custLogo={resData?.customer?.cust_logo}
        malur={malur}
        medopharm={medopharm}
      />
      <ReportTable headerData={header1} />
      <ReportTable headerData={header1Ans} />
      <ReportTable headerData2={data5} />
      <ReportTable headerData2={data6} />
      <ReportTable headerData2={data7} />
      <ReportTable headerData2={data8} />
      <ReportTable headerData2={header1body} />
      <ReportTable headerData2={header5body} />
      <ReportTable
        headerData2={header6body}
        verticalBodyData={groupedData[0]}
      />
      <ReportTable headerData2={header2body} />
      <ReportTable headerData2={header5body} />
      <ReportTable
        headerData2={header6body}
        verticalBodyData={groupedData[1]}
      />
      <ReportTable headerData2={header3body} />
      <ReportTable headerData2={header5body} />
      <ReportTable
        headerData2={header6body}
        verticalBodyData={groupedData[2]}
      />
      <ReportTable headerData2={header4body} />
      <ReportTable headerData2={header5body} />
      <ReportTable
        headerData2={header6body}
        verticalBodyData={groupedData[3]}
      />

      {footer?.length > 0 &&
        footer?.map((el, i) => {
          return <ReportTable headerData2={el} />;
        })}
    </div>
  );
}

export default CalibrationAutoTitrator;
