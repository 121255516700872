import React, { useEffect, useState } from 'react'
import IMAGENAME from "../../medias/media.index";
import { Link } from 'react-router-dom';


const BaslHeader = () => {
    return (
        <div>
            {/* <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, display: 'flex', justifyContent: 'center', background: 'linear-gradient(359deg, rgb(255, 239, 190) 0.59%, rgb(255, 255, 255) 99.58%)', color: 'white' }} > */}
            <div style={{ padding: "0px", boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)', display: 'flex', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000, background: '#ffff', color: 'white' }} >
                <Link to="/basl">
                    <img
                        // className={styles.logoImage}
                        style={{ padding: "0px", margin: "0.5rem 0 0.5rem 1.5rem", width: '100px' }}
                        // style={imgStyle}
                        src={IMAGENAME.BASLLogo}
                        width="auto"
                        alt="logo"
                    // height="80px"
                    />
                </Link>
            </div>
        </div >
    )
}

export default BaslHeader