import { takeEvery, call, put, all, takeLatest } from "@redux-saga/core/effects";
import {
    FACILITY_GET_LIST,
    FACILITY_GET_SUCCESS,
    FACILITY_GET_FAILED,
    
    FACILITY_DELETE_LIST,
    FACILITY_DELETE_SUCCESS,
    FACILITY_DELETE_FAILED,

    FACILITY_ADD,
    FACILITY_ADD_SUCCESS,
    FACILITY_ADD_FAILED,

    FACILITY_UPDATE,
    FACILITY_UPDATE_SUCCESS,
    FACILITY_UPDATE_FAILED,


    FACILITY_GET_SINGLE,
    FACILITY_GET_SINGLE_SUCCESS,
    FACILITY_GET_SINGLE_FAILED,


} from "../actions/facilityAction";

import { facilityService } from "../../services/service.index"





export function* watchGetFacility() {
    yield takeLatest(FACILITY_GET_LIST, getFacilitySaga);
}

export function* watchDeleteFacility() {
    yield takeLatest(FACILITY_DELETE_LIST, deleteFacilitySaga);
}

export function* watchAddFacility() {
    yield takeLatest(FACILITY_ADD, addFacilitySaga);
}

export function* watchFacilityUpdate() {
    yield takeLatest(FACILITY_UPDATE, facilityUpdatesaga);
}

export function* watchSingleFacilityget() {
    yield takeLatest(FACILITY_GET_SINGLE, facilityGetSinglesaga);
}

export function* getFacilitySaga(action) {
    try {
        const response = yield call(facilityService.getFacilityData, action.payload);
        if (response.status === 200) {
            // console.log("getfacilitySaga response", response)
            yield put({
                type: FACILITY_GET_SUCCESS,
                payload: response,
            });

        }
        else {
            // console.log("getfacilitySaga Error response", response)
            yield put({
                type: FACILITY_GET_FAILED,
            });
        }
    } catch (e) {
        // console.log("getfacilitySaga Error response", e)
        yield put({
            type: FACILITY_GET_FAILED,
        });
        
    }
}

export function* deleteFacilitySaga(action) {
    try {
        const response = yield call(facilityService.deleteFacilityData, action.payload);
        if (response.status === 200) {
            // console.log("delete facility Data response", response)  
            const data = action.payload;   
            yield put({
                type: FACILITY_GET_LIST,
                payload: [ data[0], data[1], data[2] ]                
            });
            alert('Facility Deleted Successfully')
        }
        else {
            // console.log("FACILITY_DELETE_FAILED Error response", response)
            yield put({
                type: FACILITY_DELETE_FAILED,
            });
        }
    } catch (e) {
        // console.log("getFacilitySaga Error response", e)
        yield put({
            type: FACILITY_DELETE_FAILED,
        });        
    }
}

export function* addFacilitySaga(action) {
    try {
        const response = yield call(facilityService.addFacilityData, action.payload);
        if (response.status === 201) {
            // console.log("respose", response.data);
            // console.log("action.payload", action.payload);
            yield put({
                type: FACILITY_ADD_SUCCESS,
                payload: action.payload,
            });
            yield put({
                type: FACILITY_GET_LIST,
            });
            alert("Facility Uploaded Successfully");
        }
        else {
            yield put({
                type: FACILITY_ADD_FAILED,
            });
        }
    } catch (e) {
        if (e.response.data.message === 'Duplicate facility_id entered') {
            alert('Entered Facility ID already exists')
          }
        yield put({
            type: FACILITY_ADD_FAILED,
        });
    }
}


export function* facilityUpdatesaga(action) {
    try {
        const response = yield call(facilityService.updateFacility, action.payload);
        if (response.status === 200) {
            yield put({
                type: FACILITY_UPDATE_SUCCESS,
                payload: action.payload,
            });
            yield put({
                type: FACILITY_GET_LIST,
            })
             alert("Facility updated successfully");
        }
        else {
            yield put({
                type: FACILITY_UPDATE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: FACILITY_UPDATE_FAILED,
        });
    }
}

export function* facilityGetSinglesaga(action) {
    try {
        const response = yield call(facilityService.getSingleFacility, action.payload);
        if (response.status === 200) {
            yield put({
                type: FACILITY_GET_SINGLE_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: FACILITY_GET_SINGLE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: FACILITY_GET_SINGLE_FAILED,
        });
    }
}
