import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

function addInspectionData(data) {
  return serviceBase.post(CONSTANTS.INSP_CREATE + `?type=${data[0]}`, data[1]);
}

function getInspectionData(data) {
  
  return serviceBase.get(CONSTANTS.INSPECTION_GET_ALL + `?type=${data[0]}&page=${data[1]}&limit=${data[2]}&key=${data[3]}`);
}

function getSingleInspData(id) {
  return serviceBase.get(CONSTANTS.INSPECTION_GET_SINGLE + id);
}

function updateInspectionData(data) {
  // console.log(data);
  return serviceBase.patch(CONSTANTS.INSPECTION_UPDATE + data[1], data[0]);
}
function assignInspectionData(data) {
  // console.log(data);
  return serviceBase.patch(CONSTANTS.INSPECTION_ASSIGN + data[1], data[0]);
}

function deleteInspectionData(id) {
  // console.log("id", id);
  return serviceBase.delete(CONSTANTS.INSPECTION_DELETE + id[4]);
}

function addBulkInspection(data) {
  // console.log('bulk upload service data', data[0]);
  // console.log('bulk upload service data', data[1]);
  // console.log('bulk upload service data', CONSTANTS.INSPECTION_BULK + data[0] + `?type=${data[2]}`);
  return serviceBase.post(CONSTANTS.INSPECTION_BULK + data[0] + `?type=${data[2]}`, data[1]);
}

function inspectionReport(id) {
  return serviceBase.get(CONSTANTS.INSPECTION_REPORT + id);
}

function inspectionGetallStatus(data) {
  // console.log(data);
  // console.log(CONSTANTS.INSPECTION_STATUS + data[4] + `?type=${data[3]}&based_on=all&page=${data[0]}&limit=${data[1]}&status=${data[2]}&key=${data[7]}&start_date=${data[5]}&end_date=${data[6]}`);
  return serviceBase.get(
    // CONSTANTS.INSPECTION_STATUS + data[4] + `?type=${data[3]}&based_on=${data[8]}&page=${data[0]}&limit=${data[1]}&status=${data[2]}&key=${data[7]}&start_date=${data[5]}&end_date=${data[6]}`
    CONSTANTS.INSPECTION_STATUS + data[4] + `?type=${data[3]}&based_on=${data[8]}&page=${data[0]}&limit=${data[1]}&status=${data[2]}&key=${data[7]}&start_date=${data[5]}&end_date=${data[6]}`

  );
}

function stopInspectionData(data) {
  // console.log("data", data);
  return serviceBase.patch(CONSTANTS.INSPECTION_STOP + data[1], data[0]);
}

export const inspectionDetails = {
  addInspectionData,
  getInspectionData,
  getSingleInspData,
  updateInspectionData,
  assignInspectionData,
  deleteInspectionData,
  addBulkInspection,
  inspectionReport,
  inspectionGetallStatus,
  stopInspectionData,
};
