//1 WeighingBalence 6604f856e448a6f58df81130
export const WeighingBalenceHeader1 = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Time', textAlign: 'center', colSpan: '2' },
    { data: 'Name of the product', textAlign: 'center', rowSpan: '2' },
    { data: 'Batch No/A.R. No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Test', textAlign: 'center', rowSpan: '2' },
    { data: 'Done by', textAlign: 'center', rowSpan: '2' },
]
export const Weighing1BalenceHeader = [
    { data: 'From', textAlign: 'center' },
    { data: 'To', textAlign: 'center' },
]

//
export const Thcheaderins6 = [
    { data: '1.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '2.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '3.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '4.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '5.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '1.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '1.', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '1', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '1', rowSpan: "1", textAlign: 'left' },
]
export const Thcheaderins7 = [
    { data: 'Daily', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: 'Daily', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: 'Daily', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: 'Daily', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: 'Daily', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: 'Weekly', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: '', colSpan: '2', rowSpan: "1", textAlign: 'left' },
    { data: 'Monthly', colSpan: '2', rowSpan: "3", textAlign: 'left' },
]
export const ThcheaderData2 = [
    { data: 'Clean the unit with cloth.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Remove the bottom tray and clean the tray.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Dispose off dead insects.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Clean the tray thoroughly with a wet cloth.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Re-fix the tray back to its position.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Operation done by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Operation checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Check the UV lamp and electrode working condition.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Operation done by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Operation checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Check the electric circuit.', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Operation done by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Operation checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
]
export const ThcdataIns = {
    data: "MAINTENANCE",
    colSpan: '6'
}

export const ThcdataIns2 = {
    data: "MAINT/030:F1-01"
}

export let ThcdataIns5 = [
    { data: 'No', colSpan: '1', rowSpan: "2" },
    { data: 'Activity', colSpan: '4', rowSpan: "2" },
    { data: 'Period', colSpan: '2', rowSpan: "2" },
    { data: 'Date', colSpan: '15', rowSpan: "1",textAlign:'center' },
];



// 4 66178f41d188a4a4f949a9ba
export const ThcheaderData = [
    { data: 'Temperature °C, Relative Humidity %', colSpan: '4', rowSpan: "7", textAlign: 'left' },
    { data: 'Time', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Temperature °C (Min)', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Relative Humidity % (Min)', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Temperature °C (Max)', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Relative Humidity % (Max)', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
]

export const Thcdata1 = {
    data: "QUALITY ASSURANCE",
    colSpan: '6'
}

export const Thcdata2 = {
    data: "QAD/023:F4-06"
}

export const Thcdata4 = [
    { data: "RAW MATERIAL STORES A/C", colSpan: '4' },
    { data: 'Limit: Temperature: NMT 25°C & RH: 40% to 60%', colSpan: '5' },
    { data: 'THC NO-42', colSpan: '3' },
]
export const Thcdata5 = [
    { data: 'Activity:', colSpan: '8', rowSpan: "2" },
    { data: 'Date', colSpan: '30', rowSpan: "1", textAlign: 'center' },
]

//5 6618cb11abf43927cdb992b6

export const DBC5 = {
    data1:{
        data: "PRODUCTION",
        colSpan: '6'
    },
    data2:{
        data: "PDN/074:F26-03"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 0.50 to 120.00 kg', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "0.50 kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.01 kg', colSpan: '5', textAlign: 'center' },
        { data: '0.49 to 0.51 kg', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "75.00 kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.08 kg', colSpan: '5', textAlign: 'center' },
        { data: '74.92 to 75.08 kg', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "120.00 kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.12 kg', colSpan: '5', textAlign: 'center' },
        { data: '119.88 to 120.12 kg', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '6' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: '0.50 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '75.00 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '120.00 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//7 661a2ada069914b5adaa78a6
export const data7PMC = [
    { data: "25 KVA GENERATOR", textAlign: 'center' },
]

//8 661a2b72069914b5adaa78a7
export const data8Pmc = [{ data: "180 KVA GENERATOR", textAlign: 'center', colSpan: '9' },
{ data: "FREQUENCY - DAILY", textAlign: 'center', colSpan: '2' },]


//13 661cf171181d6a4ba128bc15
export const Weighing13BalenceHeader = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Time', textAlign: 'center', colSpan: '2' },
    { data: 'Name of the product / Material', textAlign: 'center', rowSpan: '2' },
    { data: 'Batch No/A.R. No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Test', textAlign: 'center', rowSpan: '2' },
    { data: 'Done by', textAlign: 'center', rowSpan: '2' },
]

//14 661cfa0e181d6a4ba128bc16
export const Weighing14BalenceHeader = [
    { data: 'DATE', textAlign: 'center', rowSpan: '2' },
    { data: 'TIME', textAlign: 'center', colSpan: '2' },
    { data: 'NAME OF THE PRODUCT / Material', textAlign: 'center', rowSpan: '2' },
    { data: 'BATCH NO/A.R. NO.', textAlign: 'center', rowSpan: '2' },
    { data: 'TEST', textAlign: 'center', rowSpan: '2' },
    { data: 'DONE BY', textAlign: 'center', rowSpan: '2' },
]

//16 661d0a1a181d6a4ba128bc27
export const tmr16 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Temperature', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Verified By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]


//BOD INCUBATOR USAGE LOG SHEET
//17 661e70f69d007bb0980fc7d2
export const BOD1 = [
    { data: 'Date of Incubation', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Product/Material Name', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Batch Number / AR No', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of plates Incubated', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Incubated by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Date of removal', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of plates removed', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Removed by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Remarks', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//18 661e71579d007bb0980fc7d3
export const BOD2 = [
    { data: 'Date of Incubation ', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Product/Material Name', textAlign: 'center', colSpan: '3', rowSpan: '1', },
    { data: 'Batch Number / AR No', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of Plates Incubated', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Incubated By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Date of Removal', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of Plates Removed', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Removed By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Remarks', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//19 661e71ab9d007bb0980fc7d4
export const BOD3 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Name of the Product / Material', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Batch No/A.R. No.', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Test', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Titrant', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'No. of Samples', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Cleaning (Yes/No)', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done By', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

export const BOD31 = [
    { data: 'From', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'To', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]


// 6629de5f07f6f770dea8c5b3
export const SRS1 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Softener Regeneration', textAlign: 'center', colSpan: '3', rowSpan: '1', },
    { data: 'Softener output hardness (NMT 5 ppm)', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'TDS (NMT 500ppm)', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '2', rowSpan: '1', },
]

export const SRS11 = [
    { data: 'Date of softener Regeneration', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Softener Regeneration done by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Regeneration checked by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Maintenance', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'QA', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//66b1be596231c54b2fd49f37
export const SRS3 = [
    { data: 'S.No.', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Date', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Cleaning/Sterilization of Vent Filter', textAlign: 'center', colSpan: '4', rowSpan: '1', },
    { data: 'Done BY', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]
export const SRS33 = [
    { data: `Frequency -  once in a month`, textAlign: 'left', rowSpan: '1', colSpan: '8' }
]


//66b1b6206231c54b2fd49f2a
export const SRS4 = [
    { data: 'DATE', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'SANITATION TEMPERATURE (°C)', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'VENT TEMPERATURE (°C)', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'SANITATION START TIME', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'SANITATION END TIME', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'DONE BY', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'CHECKED BY', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]
export const SRS41 = [
    { data: 'Start of the activity', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'End of the activity', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Start of the activity', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'End of the activity', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//66b1c0d36231c54b2fd49f39
export const SRS5 = [
    { data: 'DATE', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Starting time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Loading pressure (7kg/cm²)', textAlign: 'center', colSpan: '3', rowSpan: '1', },
    { data: 'Stopping time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]
export const SRS51 = [
    { data: '07:00± 30min', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: '15:00± 30min', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: '23:00± 30min', colSpan: '1', rowSpan: '1', textAlign: 'center' },
]



//20 6620ae4e9d007bb0980fc7d7
export const WeighingBalenceHeader2 = [
    { data: 'DATE', textAlign: 'center', rowSpan: '2' },
    { data: 'TIME', textAlign: 'center', colSpan: '2' },
    { data: 'PRODUCT/MATERIAL NAME', textAlign: 'center', rowSpan: '2', colSpan: '3' },
    { data: 'BATCH NO/A.R. No', textAlign: 'center', rowSpan: '2' },
    { data: 'TEST', textAlign: 'center', rowSpan: '2' },
    { data: 'DONE BY', textAlign: 'center', rowSpan: '2' },
    { data: 'CHECKED BY', textAlign: 'center', rowSpan: '2' },
]


//661a2d6b069914b5adaa78a9
export const tableHeaderDaily = [
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Check For Oil / Fuel Level (Ok / Not Ok)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Check and rectify the Unusual Sound (Ok / Not Ok)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Check and rectify / replace the leaks (Ok / Not Ok)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Clean Air Filter and Air Duct (Cleaned / Not Cleaned)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Clean oil Sludge and dust from the engine and accessories (Cleaned / Not Cleaned)', textAlign: 'center', rowSpan: '1', colSpan: '3' },
    { data: 'Coolant level', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Coolant sensor status', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Engine running hours', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'KWH reading', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Done By', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked By', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]

//6629de5f07f6f770dea8c5d0
export const tableHeaderDaily2 = [
    { data: 'SI. No.', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Check For Oil / Fuel Level (Ok / Not Ok)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Check and rectify the Unusual Sound (Ok / Not Ok)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Check and rectify / replace the leaks (Ok / Not Ok)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Clean Air Filter and Air Duct (Cleaned / Not Cleaned)', textAlign: 'center', rowSpan: '1', colSpan: '2' },
    { data: 'Clean oil Sludge and dust from the engine and accessories (Cleaned / Not Cleaned)', textAlign: 'center', rowSpan: '1', colSpan: '3' },
    { data: 'Engine running hours', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'KWH reading', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Done By', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked By', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]



// 21 6620b0779d007bb0980fc7d8


export const DBC21 = {
    data1:{
        data: "STORES",
        colSpan: '6'
    },
    data2:{
        data: "STR/010:F26-00"
    },
    data3:[
        { data: "Tolerance: ± 0.1% of standard weight used or least count whichever is maximum", colSpan: '8' },
        { data: 'Operating Range: 0.500 Gm to 160.00 Gm', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "0.500Gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.001 Gm', colSpan: '5', textAlign: 'center' },
        { data: '0.499 to 0.501 Gm', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "100.000Gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.100 Gm', colSpan: '5', textAlign: 'center' },
        { data: '99.900 to 100.100 Gm', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "160.000 Gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.160 Gm', colSpan: '5', textAlign: 'center' },
        { data: '159.840 to 160.160 Gm', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '7' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data8:[
        { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '0.500 Gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '100.000 Gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '160.000Gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]


}


//22 6620b38a9d007bb0980fc7d9

export const DBC22 = {
    data1:{
        data: "PRODUCTION",
        colSpan: '6'
    },
    data2:{
        data: "PDN/183:F1-00"
    },
    data3:[
        { data: "Tolerance: ± 0.1 % of standard weight used", colSpan: '8', textAlign: 'left' },
        { data: 'Operating Range: 0.100 to 40.000 Kgs', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5.33', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5.33', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5.33', textAlign: 'center' },
    ],
    data5: [
        { data: "0.100 Kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.005 Kg', colSpan: '5', textAlign: 'center' },
        { data: '0.095 to 0.105 Kg', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "25.000 Kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.025 Kg', colSpan: '5', textAlign: 'center' },
        { data: '24.975 to 25.025 Kg', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "40.000 Kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.040 Kg', colSpan: '5', textAlign: 'center' },
        { data: '39.960 to 40.040 Kg', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: '0.100 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '25.000 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '40.000 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]

    
}




//23 6621f4b77ba5a161365b69ab

export const WeighingBalenceHeader3 = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Destruction Cycle Load No', textAlign: 'center', rowSpan: '2' },
    { data: 'Materials Destructed', textAlign: 'center', rowSpan: '2' },
    { data: 'Set Temperature (121°C)', textAlign: 'center', rowSpan: '2' },
    { data: 'Pressure (psi)', textAlign: 'center', rowSpan: '2' },
    { data: 'Temperature Attained (121°C)', textAlign: 'center', colSpan: '3', rowSpan: 1 },
    { data: 'Sterilization Indicator', textAlign: 'center', rowSpan: '2' },
    { data: 'Remarks', textAlign: 'center', rowSpan: '2' },
]
export const Weighing23BalenceHeader23 = [
    { data: '5 Minute', textAlign: 'center' },
    { data: '15 Minutes', textAlign: 'center' },
    { data: '30 Minutes', textAlign: 'center' },
]


//24 
export const Thc24headerData = [
    { data: 'Temperature °C', colSpan: '4', rowSpan: "10", textAlign: 'left' },
    { data: 'Time', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Temperature °C', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Time', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Temperature °C', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Time', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Temperature °C', colSpan: '4', rowSpan: "1", textAlign: 'left' },
    { data: 'Checked by', colSpan: '4', rowSpan: "1", textAlign: 'left' },

]

export const Thc24data1 = {
    data: "QUALITY ASSURANCE",
    colSpan: '6'
}

export const Thc24data2 = {
    data: "QAD/023:F3-03"
}

export const Thc24data4 = [
    { data: "ORGANIC SOLVENT STORAGE AREA", colSpan: '4' },
    { data: 'Limit Temperature : NMT 30°C', colSpan: '5' },
    { data: 'THC NO - 073', colSpan: '3' },
]
export const Thc24data5 = [
    { data: 'Activity:', colSpan: '8', rowSpan: "2" },
    { data: 'Date', colSpan: '30', rowSpan: "1", textAlign: 'center' },
]

//25 662381a1e54f9b1753251d1f

export const Weighing25BalenceHeader = [
    { data: 'SL. NO.', textAlign: 'center', rowSpan: '2' },
    { data: 'DATE', textAlign: 'center', rowSpan: '2' },
    { data: 'PRODUCT NAME/MATERIAL NAME', textAlign: 'center', rowSpan: '2', colSpan: '3' },
    { data: 'STAGE', textAlign: 'center', rowSpan: '2', },
    { data: 'BATCH NO / A R NO.', textAlign: 'center', rowSpan: '2' },
    { data: 'NAME OF THE TEST', textAlign: 'center', rowSpan: '2' },
    { data: 'ANALYST', textAlign: 'center', rowSpan: '2' },
    { data: 'REMARKS', textAlign: 'center', rowSpan: '2' },
]

//26 66238234e54f9b1753251d20

export const Weighing26BalenceHeader = [
    { data: 'DATE', textAlign: 'center', rowSpan: '2' },
    { data: 'TIME', textAlign: 'center', rowSpan: '2' },
    { data: 'EYE WASH', textAlign: 'center', rowSpan: '2', },
    { data: 'SAFETY SHOWER', textAlign: 'center', rowSpan: '2' },
    { data: 'SIGN', textAlign: 'center', rowSpan: '2' },
]


//27 662382e6e54f9b1753251d21

export const BOD4 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Start Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Product Name', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'B.No', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Apparatus', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Speed', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Media', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Temperature', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Run Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'End Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Signature', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//28 66238357e54f9b1753251d22
export const Weighing28BalenceHeader = [
    { data: 'DATE', textAlign: 'center', rowSpan: '2' },
    { data: 'TIME', textAlign: 'center', colSpan: '2' },
    { data: 'NAME OF THE PRODUCT / MATERIAL', textAlign: 'center', rowSpan: '2' },
    { data: 'BATCH NO./A.R.NO', textAlign: 'center', rowSpan: '2' },
    { data: 'TEST', textAlign: 'center', rowSpan: '2' },
    { data: 'DONE BY', textAlign: 'center', rowSpan: '2' },
    { data: 'CHECKED BY', textAlign: 'center', rowSpan: '2' },
]
export const Weighing28BalenceHeader2 = [
    { data: 'FROM', textAlign: 'center' },
    { data: 'TO', textAlign: 'center' },
]

// 29 662383eae54f9b1753251d24
export const BOD5 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Name of the Product/Material', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Batch No/A.R. NO', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

// 30 6626340579c2608c2533e9b6
export const BOD6 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Product / Material Name', textAlign: 'center', colSpan: '3', rowSpan: '1', },
    { data: 'Batch No/A.R. No.', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Done By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]


// 31 6626349579c2608c2533e9b7
export const BOD7 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Product/Material Name', textAlign: 'center', colSpan: '3', rowSpan: '1', },
    { data: 'Batch No./A.R.No.', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Wavelength', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Number of Readings', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Done By', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

// 29 662635a679c2608c2533e9b8
export const BOD9 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Name of the Product', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Batch No/A.R. No.', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Temperature', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Pressure mm Hg / In Hg', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]
export const BOD91=[
    { data: 'From', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'To', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//33 6626364c79c2608c2533e9b9

export const Weighing32BalenceHeader = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Sterilization Cycle Load No', textAlign: 'center', rowSpan: '2' },
    { data: 'Materials Sterilized', textAlign: 'center', rowSpan: '2' },
    { data: 'Set Temperature (121°C)', textAlign: 'center', rowSpan: '2' },
    { data: 'Pressure (psi)', textAlign: 'center', rowSpan: '2' },
    { data: 'Temperature Attained (121°C)', textAlign: 'center', colSpan: '3' },
    { data: 'Sterilization Indicator', textAlign: 'center', rowSpan: '2' },
    { data: 'Remarks', textAlign: 'center', rowSpan: '2' },
]

export const Weighing33BalenceHeader33 = [
    { data: '1 Minute', textAlign: 'center' },
    { data: '5 Minutes', textAlign: 'center' },
    { data: '15 Minutes', textAlign: 'center' },
]


//34 6626388f79c2608c2533e9ba
export const BOD8 = [
    { data: 'Incubation starting date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Incubation starting Time', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Product/Material Name', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Batch No. / AR No.', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of plates Incubated', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Incubated by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Date of removal', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of plates removed', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Removed by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Remarks', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//35 6629de5f07f6f770dea8c59f
export const BOD10 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Product / Material Name', textAlign: 'center', colSpan: '3', rowSpan: '1', },
    { data: 'A.R. No / B.No.', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'No. of Plates', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Cleaning', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Sign', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

// 36 6629de5f07f6f770dea8c5a0
export const BOD36 = [
    { data: 'DATE', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'TIME', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'NAME OF THE PRODUCT', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'BATCH NUMBER', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'DONE BY', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'CHECKED BY', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//37 6629de5f07f6f770dea8c5a1
export const BOD37 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Name of the Product', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Batch No', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Medium', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//41 6629de5f07f6f770dea8c5a5
export const Weighing41BalenceHeader = [
    { data: 'Starting Date & Time', textAlign: 'center', colSpan: '2', rowSpan: '2' },
    { data: 'Product/Material name', textAlign: 'center', rowSpan: '2', colSpan: '3' },
    { data: 'A.R. No/B.No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Column used', textAlign: 'center', rowSpan: '2' },
    { data: 'Column no.', textAlign: 'center', rowSpan: '2' },
    { data: 'Test', textAlign: 'center', rowSpan: '2' },
    { data: 'Ending date & time', textAlign: 'center', colSpan: '2', rowSpan: '2' },
    { data: 'Lamp hours', textAlign: 'center', rowSpan: '2' },
    { data: 'Sign', textAlign: 'center', rowSpan: '2' },
]

//42 6629de5f07f6f770dea8c5a6
export const Weighing42BalenceHeader = [
    { data: 'Date', textAlign: 'center', colSpan: '1', rowSpan: '2' },
    { data: 'Operation Time', textAlign: 'center', rowSpan: '1', colSpan: '2' },
    { data: 'Pressure (10-15 mm)', textAlign: 'center', rowSpan: '2' },
    { data: 'UV Light burning hours (Limit: NMT 2000 Hours)', textAlign: 'center', rowSpan: '1', colSpan: '4' },
    { data: 'Done By', textAlign: 'center', rowSpan: '2' },
    { data: 'Checked By', textAlign: 'center', rowSpan: '2' },
    { data: 'Remarks', textAlign: 'center', rowSpan: '2' },
]
export const Weighing42BalenceHeader42 = [
    { data: 'From', textAlign: 'center' },
    { data: 'To', textAlign: 'center' },
    { data: 'From', textAlign: 'center' },
    { data: 'To', textAlign: 'center' },
    { data: 'Duration', textAlign: 'center' },
    { data: 'Cumulative', textAlign: 'center' },
]

//43 6629de5f07f6f770dea8c5a7
export const BOD43 = [
    { data: 'DATE', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'NAME OF THE PRODUCT', textAlign: 'center', colSpan: '2', rowSpan: '2', },
    { data: 'BATCH NO', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'MODE OF PACK', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'FREQUENCY', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'CONDITION', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'A.R. NO.', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'REMARKS', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//44 6629de5f07f6f770dea8c5a8
export const tmr44 = [
    { data: 'Date', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Temperature', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Temperature', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: 'Temperature', textAlign: 'center', colSpan: '1', rowSpan: '1', },
]

//47 6629de5f07f6f770dea8c5ab
export const Weighing47BalenceHeader = [
    { data: 'Sr.No.', textAlign: 'center', colSpan: '1', rowSpan: '2' },
    { data: 'Date', textAlign: 'center', colSpan: '1', rowSpan: '2' },
    { data: 'Verification Std buffer used', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    { data: 'Buffer Limit', textAlign: 'center', rowSpan: '2' },
    { data: 'Temperature', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    { data: 'Observed pH', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    { data: 'Done By', textAlign: 'center', rowSpan: '2' },
    { data: 'Checked By', textAlign: 'center', rowSpan: '2' },
]

//48 6629de5f07f6f770dea8c5ac
export const Weighing48BalenceHeader = [
    { data: 'Date', textAlign: 'center', colSpan: '1', rowSpan: '2' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '2' },
    { data: 'Name of the product / Material', textAlign: 'center', rowSpan: '2', colSpan: '2' },
    { data: 'Batch No/A.R. No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Number of Samples', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    { data: 'Done By', textAlign: 'center', rowSpan: '2' },
]

//49 6629de5f07f6f770dea8c5ad
export const Weighing49BalenceHeader = [
    { data: 'Date', textAlign: 'center', colSpan: '1', rowSpan: '2' },
    { data: 'Name of the product', textAlign: 'center', rowSpan: '2', colSpan: '2' },
    { data: 'Batch No./A.R. No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Start Time', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    { data: 'End Time', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    { data: 'Done By', textAlign: 'center', rowSpan: '2' },
    { data: 'Checked By', textAlign: 'center', rowSpan: '2' },
]


//50 6629de5f07f6f770dea8c5ae
export const Weighing50BalenceHeader = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Time', textAlign: 'center', colSpan: '2' },
    { data: 'Name of the product', textAlign: 'center', rowSpan: '2' },
    { data: 'Batch No./A.R. No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Test', textAlign: 'center', rowSpan: '2' },
    { data: 'Done by', textAlign: 'center', rowSpan: '2' },
]

//52 6629de5f07f6f770dea8c5b0
export const Weighing52BalenceHeader = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Product Name', textAlign: 'center', rowSpan: '2', colSpan: '2' },
    { data: 'B.No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Mfg Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Exp Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Mode of pack', textAlign: 'center', rowSpan: '2' },
    { data: 'Reason for study', textAlign: 'center', rowSpan: '2' },
    { data: 'Sample Issued By QA', textAlign: 'center', rowSpan: '2' },
    { data: 'Sample Received By QC', textAlign: 'center', rowSpan: '2' },
]

//56 6629de5f07f6f770dea8c5b4
export const BOD56 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Name of the product', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Batch No.', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//57 6629de5f07f6f770dea8c5b5
export const BOD57 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Start Time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Name of the Material', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Batch No/A.R.NO', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'End Time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//58 6629de5f07f6f770dea8c5b6
export const Weighing58BalenceHeader = [
    { data: 'S.No.', textAlign: 'center', rowSpan: '2' },
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'pH Buffer used', textAlign: 'center', rowSpan: '2' },
    { data: 'Buffer Limit', textAlign: 'center', rowSpan: '2' },
    { data: 'Temperature', textAlign: 'center', rowSpan: '2' },
    { data: 'Observed pH', textAlign: 'center', rowSpan: '1', colSpan: '2' },
    { data: 'Done by', textAlign: 'center', rowSpan: '2' },
    { data: 'Checked by', textAlign: 'center', rowSpan: '2' },
]

export const Weighing58BalenceHeader58 = [
    { data: 'Before calibration', textAlign: 'center' },
    { data: 'After calibration', textAlign: 'center' },
]

//61 6629de5f07f6f770dea8c5b9

export const Weighing61BalenceHeader = [
    { data: 'Date', textAlign: 'center', rowSpan: '2' },
    { data: 'Sterilization Cycle Load No', textAlign: 'center', rowSpan: '2' },
    { data: 'Materials sterilized', textAlign: 'center', rowSpan: '2' },
    { data: 'Set Temperature (115°C)', textAlign: 'center', rowSpan: '2' },
    { data: 'Pressure (psi)', textAlign: 'center', rowSpan: '2' },
    { data: 'Temperature Attained (115°C)', textAlign: 'center', colSpan: '3' },
    { data: 'Done By', textAlign: 'center', rowSpan: '2' },
    { data: 'Checked By', textAlign: 'center', rowSpan: '2' },
    { data: 'Remarks', textAlign: 'center', rowSpan: '2' },
]


//63 6629de5f07f6f770dea8c5bb
export const BOD63 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Start Time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Name of the Material', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Batch No/A.R.NO', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'End Time', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//102 666acd540849568dce4373df
export const DBC102 = {
    data1:{
        data: "STORES",
        colSpan: '6'
    },
    data2:{
        data: "STR/010:F14-03"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 0.50 to 48.00 kg', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "0.50 kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.01 kg', colSpan: '5', textAlign: 'center' },
        { data: '0.49 to 0.51 kg', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "30.00 kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.03 kg', colSpan: '5', textAlign: 'center' },
        { data: '29.97 to 30.03 kg', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "48.00 kg", colSpan: '5', textAlign: 'center' },
        { data: '± 0.05 kg', colSpan: '5', textAlign: 'center' },
        { data: '47.95 to 48.05 kg', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '7' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '0.50 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '30.00 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '48.00 kg', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//103 666acd540849568dce4373e0
export const DBC103 = {
    data1:{
        data: "PRODUCTION",
        colSpan: '6'
    },
    data2:{
        data: "PDN/074:F50-01"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 0.050 to 496.000 g', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "0.050 g", colSpan: '5', textAlign: 'center' },
       { data: '± 0.001 g', colSpan: '5', textAlign: 'center' },
        { data: '0.049 to 0.051 g', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "310.000 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.310 g', colSpan: '5', textAlign: 'center' },
        { data: '309.69 to 310.310 g', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "496.000 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.496 g', colSpan: '5', textAlign: 'center' },
        { data: '495.504 to 496.496 g', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '6' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: '0.050 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '310.000 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '496.000 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//104 666acd540849568dce4373e1
export const DBC104 = {
    data1:{
        data: "STORES",
        colSpan: '6'
    },
    data2:{
        data: "STR/010:F23-00"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 50 gm to 2400.0 gm', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "50.0 gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.1 gm', colSpan: '5', textAlign: 'center' },
        { data: '49.9 to 50.1 gm', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "1500.0 gm", colSpan: '5', textAlign: 'center' },
        { data: '± 1.5 gm', colSpan: '5', textAlign: 'center' },
        { data: '1498.5 to 1501.5 gm', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "2400.0 gm", colSpan: '5', textAlign: 'center' },
        { data: '± 2.4 gm', colSpan: '5', textAlign: 'center' },
        { data: '2397.6 to 2402.4 gm', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '7' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '50.0gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '1500.0gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '2400.0gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//105 666acd540849568dce4373e2
export const DBC105 = {
    data1:{
        data: "STORES",
        colSpan: '6'
    },
    data2:{
        data: "STR/010:F12-03"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 20.0 Gm to 4800.0 Gm', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "20.0 gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.2 gm', colSpan: '5', textAlign: 'center' },
        { data: '19.8 to 20.2 gm', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "3000.0 gm", colSpan: '5', textAlign: 'center' },
        { data: '± 3.0 gm', colSpan: '5', textAlign: 'center' },
        { data: '2997 to 3003 gm', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "4800.0 gm", colSpan: '5', textAlign: 'center' },
        { data: '± 4.0 gm', colSpan: '5', textAlign: 'center' },
        { data: '4796.0 to 4804.0 gm', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '7' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '20.0 gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '3000.0 gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '4800.0 gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//106 666acd540849568dce4373e3
export const DBC106 = {
    data1:{
        data: "PRODUCTION",
        colSpan: '6'
    },
    data2:{
        data: "PDN/074:F67-01"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 0.050 to 176.000 g', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "0.050 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.001 g', colSpan: '5', textAlign: 'center' },
        { data: '0.049 to 0.051 g', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "110.000 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.110 g', colSpan: '5', textAlign: 'center' },
        { data: '109.890 to 110.110 g', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "176.000 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.176 g', colSpan: '5', textAlign: 'center' },
        { data: '175.824 to 176.176 g', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '6' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: '0.050 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '110.000 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '176.000 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//107 666acd540849568dce4373e4
export const BOD107 = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Name of the product', textAlign: 'center', colSpan: '3', rowSpan: '2', },
    { data: 'Stage', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'B.No./R.R.No.', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Destroyed by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]

//110 666acd540849568dce4373e7
export const DBC110 = {
    data1:{
        data: "PRODUCTION",
        colSpan: '6'
    },
    data2:{
        data: "PDN/074:F12-03"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 50.0 to 1200.0 g', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "50.0 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.2 g', colSpan: '5', textAlign: 'center' },
        { data: '49.8 to 50.2 g', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "750.0 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.8 g', colSpan: '5', textAlign: 'center' },
        { data: '749.2 to 750.8 g', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "1200.0 g", colSpan: '5', textAlign: 'center' },
        { data: '± 1.2 g', colSpan: '5', textAlign: 'center' },
        { data: '1198.8 to 1201.2 g', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '6' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: '50.0 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '750.0 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '1200.0 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//114 666acd540849568dce4373eb
export const Weighing114BalenceHeader = [
    { data: 'Date', colSpan: '1', rowSpan: '2', textAlign: 'center' },
    { data: 'Observed Weights', textAlign: 'center', colSpan: '4', rowSpan: '1', },
    { data: 'Remarks', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Done by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
    { data: 'Checked by', textAlign: 'center', colSpan: '1', rowSpan: '2', },
]
export const Weighing114BalenceHeaderB = [
    { data: '0.500 g', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: '1.000 g', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: '10.000 g', textAlign: 'center', colSpan: '1', rowSpan: '1', },
    { data: '100.000 g', textAlign: 'center', colSpan: '1', rowSpan: '1', },

]

//115 666acd540849568dce4373ec
export const DBC115 = {
    data1:{
        data: "STORES DEPARTMENT",
        colSpan: '6'
    },

    data2:{
        data: "STR/010:F10-03"
    },

    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 1.00 Gm to 240.0 Gm', colSpan: '7', textAlign: 'left' },
    ],

    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],

    data5:[
        { data: "1.00 Gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.01 Gm', colSpan: '5', textAlign: 'center' },
        { data: '0.99 to 1.01 Gm', colSpan: '5', textAlign: 'center' },
    ],

    data6:[
        { data: "150.00 Gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.15 Gm', colSpan: '5', textAlign: 'center' },
        { data: '149.85 to 150.15 Gm', colSpan: '5', textAlign: 'center' },
    ],

    data7:[
        { data: "240.00 Gm", colSpan: '5', textAlign: 'center' },
        { data: '± 0.25 Gm', colSpan: '5', textAlign: 'center' },
        { data: '239.76 to 240.24 Gm', colSpan: '5', textAlign: 'center' },
    ],

    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '7' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],

    data9:[
        { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '1.00 Gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '150.00 Gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '240.00 Gm', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}

//116 666acd540849568dce4373ed
export const DBC116 = {
    data1:{
        data: "PRODUCTION",
        colSpan: '6'
    },
    data2:{
        data: "PDN/074:F62-01"
    },
    data3: [
        { data: "Tolerance: ± 0.1 % of standard weight used or least count whichever is maximum.", colSpan: '8' },
        { data: 'Operating Range: 60.0 to 2400.0 g', colSpan: '7', textAlign: 'left' },
    ],
    data4:[
        { data: "Standard weights used", colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance', colSpan: '5', textAlign: 'center' },
        { data: 'Tolerance limit', colSpan: '5', textAlign: 'center' },
    ],
    data5:[
        { data: "60.0 g", colSpan: '5', textAlign: 'center' },
        { data: '± 0.2 g', colSpan: '5', textAlign: 'center' },
        { data: '59.8 to 60.2 g', colSpan: '5', textAlign: 'center' },
    ],
    data6:[
        { data: "1500.0 g", colSpan: '5', textAlign: 'center' },
        { data: '± 1.6 g', colSpan: '5', textAlign: 'center' },
        { data: '1498.4 to 1501.6 g', colSpan: '5', textAlign: 'center' },
    ],
    data7:[
        { data: "2400.0 g", colSpan: '5', textAlign: 'center' },
        { data: '± 2.4 g', colSpan: '5', textAlign: 'center' },
        { data: '2397.6 to 2402.4 g', colSpan: '5', textAlign: 'center' },
    ],
    data8:[
        { data: 'Date', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Time (start time)', textAlign: 'center', rowSpan: '2', colSpan: '1' },
        { data: 'Reading shown by weighing balance against the standard weight ', textAlign: 'center', rowSpan: '1', colSpan: '6' },
        { data: 'Checked by', textAlign: 'center', rowSpan: '2', colSpan: '1' },
    ],
    data9:[
        { data: '60.0 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '1500.0 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: '2400.0 g', textAlign: 'center', rowSpan: '1', colSpan: '1' },
        { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    ]
}


//6629de5f07f6f770dea8c5df
export const DBCdata12140 = [
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Time', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Std. Wt. (in Kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance Limit (in Kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Observed Wt. (in Kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Done by', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked by', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]


//141

export const DBCtableHeaders141 = [
    { data: 'WEIGHING BALANCE CALIBRATION', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]
export const DBCdata12141 = [
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Time', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Std. Wt. (in g)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance Limit (in g)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Observed Wt. (in g)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked by', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]


//142 666acd540849568dce437404
export const DBCdata12142 = [
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Time', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Std. Wt. (in gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance (in gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance Limit (in gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Observed Wt. (in gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked by', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]


//144 666acd540849568dce437406
export const DBCdata12144 = [
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Time', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Std. Wt. (in Kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance (in kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance Limit (in kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Observed Wt. (in Kg)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked by', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]


//148 666acd540849568dce43740a
export const DBCdata12148 = [
    { data: 'Date', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Time', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Zero Error', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Std. Wt. (in Gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance (in Gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Tolerance Limit (in Gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Observed Wt. (in Gm)', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Deviation', textAlign: 'center', rowSpan: '1', colSpan: '1' },
    { data: 'Checked by', textAlign: 'center', rowSpan: '1', colSpan: '1' },
]

//6629de5f07f6f770dea8c5bb

export const Filter1 = [
    { data: 'Sr. No', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'AHU No', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Filter Details', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
]

//6629de5f07f6f770dea8c5d9
export const Filter2 = [
    { data: 'Sr. No', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'EAU No.', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Filter Details', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
]

//666acd540849568dce4373fc
export const Filter3 = [
    { data: 'Sr. No', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'Dust Collector', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Filter Details', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
]

//666acd540849568dce4373fd
export const Filter4 = [
    { data: 'Sr. No', colSpan: '1', rowSpan: '1', textAlign: 'center' },
    { data: 'PMC No', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Filter Details', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Cleaned on Sign & Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
    { data: 'Next Due Date', textAlign: 'center', colSpan: '2', rowSpan: '1', },
]