/* Feature - to maintain header for user page

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { React, useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../../components/sideNavBar/sideBarButton";
import LogoutButton from "../../components/logOutButton";
import Logo from "../../components/logo";
import { useSelector, useDispatch } from "react-redux";
import Transfer from "../../modules/FA/Transfer";
import Dispose from "../../modules/FA/Dispose";

//Function for site header
function CapitalHeader() {
    const dispatch = useDispatch();
    // modal for bulk upload button
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);

    const access = useSelector((state) => state.access.user);
    const role = useSelector((state) => state.auth.role);

    useEffect(() => {
        const roleCode = role[0];
        // console.log(roleCode);
        if (access === null && roleCode !== null) {
            dispatch({
                type: "ACCESS_GET_LIST",
                payload: roleCode,
            });
        }
    }, [role]);

    useEffect(() => {
        // console.log(access);
    }, [access]);

    return (
        <div>
            <div>
                <Navbar bg="light" variant={"light"} expand="lg">
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" className="headershadow">
                        <Nav
                            className="mr-auto my-2 my-lg-0"
                            style={{ maxHeight: "100px" }}
                        >
                            <Sidebar />
                            <Logo />

                            <div >
                                <div>
                                    <Nav.Link
                                        onClick={() => setModalShow(true)}
                                        className="position-absolute end-0 header"
                                        style={{ marginRight: "300px", }}
                                    >
                                        Transfer
                                    </Nav.Link>

                                </div>
                                <div>
                                    <Nav.Link
                                        onClick={() => setModalShow1(true)}
                                        className="position-absolute end-0 header"
                                        style={{ marginRight: "400px", }}
                                    >
                                        Expense
                                    </Nav.Link>
                                </div>
                            </div>
                            {modalShow && (
                                <Transfer
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                ></Transfer>
                            )}
                            {modalShow1 && (
                                <Dispose
                                    show={modalShow1}
                                    onHide={() => setModalShow1(false)}
                                ></Dispose>
                            )}
                            <LogoutButton />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Outlet />
            </div>
        </div>
    );
}

export default CapitalHeader;
