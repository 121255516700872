import React from 'react'
import Commonheader from '../../layouts/common/commonHeader'
import { Button } from 'react-bootstrap'
import Logo from '../../components/logo';

function Company() {

  const onButtonClick = () => {
    const pdfUrl = "https://app.zongovita.com/ZongoVita_CP_V5.1-08122023_small.pdf";
    window.open(pdfUrl, '_blank');
  };
  const HomePage = () => {
    const homeLink = 'https://zongovita.com/'
    window.open(homeLink, '_blank');
  }
  return (
    <div style={{ backgroundColor: 'black', height: '100vh' }}>
      <center>
        <Logo style={{ textAlign: 'center' }} />
        <hr style={{ color: '#64ffe2' }} />
        <h3 style={{ color: '#64ffe2' }}>Welcome to ZongoVita</h3>
        <div className='companyButtonDiv'>
          <button
            type="click"
            onClick={() => onButtonClick()}
            style={{ width: '170px', marginTop: '20px',marginLeft:'50px',marginRight:'50px' }}
            className="float-center saveButton"
          >
            Download Brochure
          </button>
          <button
            type="click"
            onClick={() => HomePage()}
            style={{ width: '170px', marginTop: '20px', marginLeft:'50px',marginRight:'50px'}}
            className="float-center saveButton"
          >
            Visit Website
          </button>
        </div>
      </center>

    </div>
  );
}

export default Company
