import { takeEvery, call, put, all, takeLatest } from "@redux-saga/core/effects";
import {
    DEVICE_GET_LIST,
    DEVICE_GET_SUCCESS,
    DEVICE_GET_FAILED,
    
    DEVICE_DELETE_LIST,
    DEVICE_DELETE_SUCCESS,
    DEVICE_DELETE_FAILED,

    DEVICE_ADD,
    DEVICE_ADD_SUCCESS,
    DEVICE_ADD_FAILED,

    DEVICE_UPDATE,
    DEVICE_UPDATE_SUCCESS,
    DEVICE_UPDATE_FAILED,

    DEVICE_GET_SINGLE,
    DEVICE_GET_SINGLE_SUCCESS,
    DEVICE_GET_SINGLE_FAILED,
} from "../actions/deviceAction";

import { deviceService } from "../../services/service.index"





export function* watchGetDevice() {
    yield takeLatest(DEVICE_GET_LIST, getDeviceSaga);
}

export function* watchDeleteDevice() {
    yield takeLatest(DEVICE_DELETE_LIST, deleteDeviceSaga);
}

export function* watchAddDevice() {
    yield takeLatest(DEVICE_ADD, addDeviceSaga);
}

export function* watchDeviceUpdate() {
    yield takeLatest(DEVICE_UPDATE, deviceUpdatesaga);
}

export function* watchSingleDeviceget() {
    yield takeLatest(DEVICE_GET_SINGLE, deviceGetSinglesaga);
}



export function* getDeviceSaga(action) {
    try {
        const response = yield call(deviceService.getDeviceData, action.payload);
        if (response.status === 200) {
            // console.log("getdeviceSaga response", response)
            yield put({
                type: DEVICE_GET_SUCCESS,
                payload: response,
            });

        }
        else {
            // console.log("getdeviceSaga Error response", response)
            yield put({
                type: DEVICE_GET_FAILED,
            });
        }
    } catch (e) {
        // console.log("getdeviceSaga Error response", e)
        yield put({
            type: DEVICE_GET_FAILED,
        });
        
    }
}

export function* deleteDeviceSaga(action) {
    try {
        const response = yield call(deviceService.deleteDeviceData, action.payload);
        if (response.status === 200) {
            // console.log("delete device Data response", response)    
            const data = action.payload;   
            yield put({
                type: DEVICE_GET_LIST,
                payload: [ data[0], data[1], data[2] ]                
            });
            alert('Device Deleted Successfully')
        }
        else {
            // console.log("DEVICE_DELETE_FAILED Error response", response)
            yield put({
                type: DEVICE_DELETE_FAILED,
            });
        }
    } catch (e) {
        // console.log("getDeviceSaga Error response", e)
        yield put({
            type: DEVICE_DELETE_FAILED,
        });        
    }


}

export function* addDeviceSaga(action) {
    try {
        const response = yield call(deviceService.addDeviceData, action.payload);
        if (response.status === 201) {
            // console.log("addCustData response", response)
            // console.log("Customer ID response", response.data._id)
            // console.log("action.payload", action.payload)
            // let obj = { subscriberData: action.payload, customerId: response.data._id }
            yield put({
                type: DEVICE_ADD_SUCCESS
                // payload: action.payload,
                // payload: obj
            });
            yield put({
                type: DEVICE_GET_LIST
                // payload: action.payload,
                // payload: obj
            });
            alert("Device Uploaded Successfully");
        }
        else {
            // console.log("CUST_ADD_FAILED Error response", response)
            yield put({
                type: DEVICE_ADD_FAILED,
            });
        }
    } catch (e) {
        // console.log("addCustSaga Error response", e)
        if (e.response.data.message === 'Duplicate device_id entered') {
            alert('Entered Device ID already exists')
          }
        yield put({
            type: DEVICE_ADD_FAILED,
        });
        // Alert.alert('Error!', e.response.data.message, [
        //     { text: 'Okay' }
        // ]);
    }
}

export function* deviceUpdatesaga(action) {
    try {
        const response = yield call(deviceService.updateDevice, action.payload);
        if (response.status === 200) {
            yield put({
                type: DEVICE_UPDATE_SUCCESS,
                payload: action.payload,
            });
            yield put({
                type: DEVICE_GET_LIST,
            })
             alert("Device updated successfully")
        }
        else {
            yield put({
                type: DEVICE_UPDATE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: DEVICE_UPDATE_FAILED,
        });
    }
}

export function* deviceGetSinglesaga(action) {
    try {
        const response = yield call(deviceService.getSingleDevice, action.payload);
        if (response.status === 200) {
            yield put({
                type: DEVICE_GET_SINGLE_SUCCESS,
                payload: response.data,
            });
        }
        else {
            yield put({
                type: DEVICE_GET_SINGLE_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: DEVICE_GET_SINGLE_FAILED,
        });
    }
}
