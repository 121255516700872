//Feature - Form to upload/onboard Customer

//Created by - Mona R
//Updated by - Janaki

//importing dependencies and packages
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CUST_ADD } from "../../redux/actions/custAction";
import Loader from "../../components/loader/loader";
import ContactUpload from "./contactUpload";
import ToastNotifications, {
  notifySuccess,
} from "../../components/toaster/toaster";
import BackButton from "../../components/BackButton/BackButton";

//Function to upload single customer
function CustomerUpload() {
  // To fetch details from redux
  const id = useSelector((state) => state.customer.contactId);
  const subscriber_id = useSelector((state) => state.auth.subscriber_id);
  const userid = useSelector((state) => state.auth.userid);
  const isLoading = useSelector((state) => state.customer.isLoading);
  const [modalShow, setModalShow] = useState(false);

  // const showToastMessage = () => {
  //   toast.success("Customer Onboarded Successfully!", {
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // };
  // const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    clegalname: "",
    cwebsite: "",
    caddress: "",
    cregion: "",
    ccity: "",
    ccountry: "",
    cstate: "",
    czcode: "",
    cgeo: "",
    ccriticality: "",
    cust_logo: "",
    created_by:""
  });

  //Functions to handle form inputs
  const handleLegalName = (val) => {
    if (val.length < 1) {
      setData({
        ...data,
        clegalname: val,
      });
    } else {
      setData({
        ...data,
        clegalname: val,
      });
    }
  };
  const handleWebsite = (val) => {
    setData({
      ...data,
      cwebsite: val,
    });
  };
  const handleAddress = (val) => {
    setData({
      ...data,
      caddress: val,
    });
  };
  const handleRegion = (val) => {
    setData({
      ...data,
      cregion: val,
    });
  };
  const handleCity = (val) => {
    setData({
      ...data,
      ccity: val,
    });
  };
  const handleCountry = (val) => {
    setData({
      ...data,
      ccountry: val,
    });
  };
  const handleState = (val) => {
    setData({
      ...data,
      cstate: val,
    });
  };
  const handleZipCode = (val) => {
    setData({
      ...data,
      czcode: val,
    });
  };
  const handleLogo = (input) => {
    let file = input.target.files[0];
    if (file && !file.type.match(/(jpeg|png|jpg)/)) {
      alert('Only JPEG, PNG, JPG files are allowed');
      input.target.value = '';
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64String = reader.result;
      setData({
        ...data,
        cust_logo: base64String,
      });
    };
  };
  // const handleGeo = (val) => {
  //   setData({
  //     ...data,
  //     cgeo: val,
  //   });
  // };
  const handleCriticality = (val) => {
    setData({
      ...data,
      ccriticality: val,
    });
  };

  //client-side validation
  const [clegalnameErr, setClegalnameErr] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [regionErr, setRegionErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [zcodeErr, setZcodeErr] = useState("");

  let isValid = true;
  const formValidation = () => {
    const clegalnameErr = {};
    const websiteErr = {};
    const addressErr = {};
    const regionErr = {};
    const cityErr = {};
    const countryErr = {};
    const stateErr = {};
    const zcodeErr = {};

    if (data.clegalname.trim().length < 1) {
      clegalnameErr.clegalnameShort = "Please Enter Legal Name";
      isValid = false;
    }
    setClegalnameErr(clegalnameErr);

    if (data.cwebsite.trim().length < 1) {
      websiteErr.cwebsiteShort = "Please Enter website";
      isValid = false;
    }
    setWebsiteErr(websiteErr);

    if (data.caddress.trim().length < 1) {
      addressErr.caddressShort = "Please Enter Address";
      isValid = false;
    }
    setAddressErr(addressErr);

    if (data.cregion.trim().length < 1) {
      regionErr.cregionShort = "Please Enter Region";
      isValid = false;
    }
    setRegionErr(regionErr);

    if (data.ccity.trim().length < 1) {
      cityErr.ccityShort = "Please Enter City";
      isValid = false;
    }
    setCityErr(cityErr);

    if (data.ccountry.trim().length < 1) {
      countryErr.countryShort = "Please Enter Country";
      isValid = false;
    }
    setCountryErr(countryErr);

    if (data.cstate.trim().length < 1) {
      stateErr.stateShort = "Please Enter State";
      isValid = false;
    }
    setStateErr(stateErr);

    if (data.czcode.trim().length !== 6) {
      zcodeErr.zcodeShort = "Please Enter Valid Zipcode";
      isValid = false;
    }
    setZcodeErr(zcodeErr);

    return isValid;
  };
  const validation = () => {
    const isValid = formValidation();
    return isValid;
  };

  //Function to submit customer details
  const submitCustomer = (e) => {
    e.preventDefault();
    let customerData = {
      subscriber_id: subscriber_id,
      legal_name: data.clegalname,
      website: data.cwebsite,
      address: data.caddress,
      region: data.cregion,
      city: data.ccity,
      country: data.ccountry,
      state: data.cstate,
      zip_code: data.czcode,
      geo_address: data.cgeo,
      criticality_rating: data.ccriticality,
      cust_logo: data.cust_logo,
      created_by: userid,
    };
    validation();
    if (isValid) {
      dispatch({
        type: CUST_ADD,
        payload: customerData,
      });
      notifySuccess();
      navigate('/customerlist');
    }
  };

  // navigation tab
  const navigate = useNavigate();

  //functions for validation
  function nameChars(str) {
    const specialChars = /^([a-zA-Z0-9-/@]+\s)*[a-zA-Z0-9-@/]+$/;
    return specialChars.test(str);
  }
  function websiteChars(str) {
    const webChars =
      // /^(https?:\/\/)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-]+\.[a-z.]+(\/[a-zA-Z0-9-#:=?]+\/?)*$/;
    return webChars.test(str);
  }
  function regionChars(str) {
    const placeChars = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    return placeChars.test(str);
  }
  function zcodeChars(str) {
    const zipChars = /^[1-9][0-9]{5}$/;
    return zipChars.test(str);
  }

  //useEffects
  useEffect(() => {}, [data]);

  useEffect(() => {
    if (data.clegalname !== "" && id != null && id != undefined) {
      setModalShow(true);
    }
  }, [id]);
  //---------------------------------------HTML-------------------------------------------//

  return (
    <div>
      {isLoading && <Loader />} {/* {isLoading && <Loader />} */}
      <BackButton />
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div style={{ paddingTop: "25px" }}>
              <form
                action=""
                className="mt-5 border p-4 bg-light shadow rounded"
              >
                <div className="formHeadings mb-3">Create Customer</div>
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <label>
                      Customer Legal Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="clegalname"
                      className="form-control"
                      maxLength="200"
                      value={data.clegalname}
                      onChange={(e) => handleLegalName(e.target.value)}
                    />
                    {Object.keys(clegalnameErr).map((key) => {
                      if (!data.clegalname) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {clegalnameErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {nameChars(data.clegalname) || data.clegalname === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Customer name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Website<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="cwebsite"
                      className="form-control"
                      onChange={(e) => handleWebsite(e.target.value)}
                      placeholder="www.abc.com"
                    />
                    {Object.keys(websiteErr).map((key) => {
                      if (!data.cwebsite) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {websiteErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {websiteChars(data.cwebsite) || data.cwebsite === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Website
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="caddress"
                      className="form-control"
                      onChange={(e) => handleAddress(e.target.value)}
                    />
                    {Object.keys(addressErr).map((key) => {
                      if (!data.caddress) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {addressErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Region<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="cregion"
                      className="form-control"
                      onChange={(e) => handleRegion(e.target.value)}
                    />
                    {Object.keys(regionErr).map((key) => {
                      if (!data.cregion) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {regionErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.cregion) || data.cregion === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Region name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      City<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="ccity"
                      className="form-control"
                      onChange={(e) => handleCity(e.target.value)}
                    />
                    {Object.keys(cityErr).map((key) => {
                      if (!data.ccity) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {cityErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.ccity) || data.ccity === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid City name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Country<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="ccountry"
                      className="form-control"
                      onChange={(e) => handleCountry(e.target.value)}
                    />
                    {Object.keys(countryErr).map((key) => {
                      if (!data.ccountry) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {countryErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.ccountry) || data.ccountry === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Country name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      State<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="cstate"
                      className="form-control"
                      onChange={(e) => handleState(e.target.value)}
                    />
                    {Object.keys(stateErr).map((key) => {
                      if (!data.cstate) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {stateErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {regionChars(data.cstate) || data.cstate === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid State name
                          </div>
                        ))}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label>
                      Zipcode<span className="text-danger">*</span>
                    </label>
                    <input
                      // min="0"
                      // type="number"
                      name="czcode"
                      className="form-control"
                      maxLength={6}
                      minLength={6}
                      pattern="[0-9]{6,}"
                      placeholder="Eg. 5600XX"
                      onChange={(e) => handleZipCode(e.target.value)}
                    />
                    {Object.keys(zcodeErr).map((key) => {
                      if (!data.czcode) {
                        return (
                          <div
                            key={key}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {zcodeErr[key]}
                          </div>
                        );
                      } else {
                        <p></p>;
                      }
                    })}
                    {zcodeChars(data.czcode) || data.czcode === ""
                      ? " "
                      : ((isValid = false),
                        (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              paddingTop: "2px",
                            }}
                          >
                            Invalid Zipcode
                          </div>
                        ))}
                    {/* <div className="input-group">
                      <button type="button" onClick={()=>setModalShow(true)}
                      className="postion-absolute top-25 end-0 header"
                      >
                        Button
                      </button>
                      </div> */}
                  </div>
                  {/* <div className="mb-3 col-md-6">
                    <label>Geo Address</label>
                    <input
                      type="text"
                      name="cgeo"
                      className="form-control"
                      onChange={(e) => handleGeo(e.target.value)}
                    />
                  </div> */}
                  <div className="mb-3 col-md-6">
                    <label htmlFor="ccriticality">Criticality Rating</label>
                    <select
                      name="ccriticality"
                      id="ccriticality"
                      value={data.ccriticality}
                      className="form-select"
                      onChange={(e) => handleCriticality(e.target.value)}
                    >
                      <option value="" disabled>Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>Customer Logo</label>
                    <input
                      className="form-control"
                      name="custlogo"
                      type="file"
                      id="formFile"
                      onChange={handleLogo}
                      accept=".jpeg,.jpg,.png"
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <button
                      onClick={() => navigate("/customerlist")}
                      className="backButton"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="float-end saveButton"
                      onClick={(e) => submitCustomer(e)}
                    >
                      Save
                    </button>
                    <ToastNotifications
                      toasts={{
                        success: true,
                        error: false,
                        warning: false,
                        info: false,
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <ContactUpload
          show={modalShow}
          onHide={() => setModalShow(false)}
          id={id}
        />
      )}
    </div>
  );
}

//exporting component
export default CustomerUpload;
