/* Feature - service / api call for asset/device/facility history data for both inspection & maintenance type

Created by - Janaki J
Updated by - Janaki J */
import serviceBase from "../../utilities/interceptor/apiInterceptor";
import { CONSTANTS } from "../../constants/URLs/urlConstants";

function getHistoryData(data) {
  // console.log(CONSTANTS.HISTRY_DATA +
  //   data[0] +
  //   `?type=${data[1]}&sch_type=${data[2]}&freq_type=${data[3]}&page=${data[4]}&limit=${data[5]}&based_on=${data[6]}`);
  return serviceBase.get(
    CONSTANTS.HISTRY_DATA +
    data[0] +
    `?type=${data[1]}&sch_type=${data[2]}&freq_type=${data[3]}&page=${data[4]}&limit=${data[5]}&based_on=${data[6]}`
  );

}
export const historyDetails = {
  getHistoryData,
};
