/* Feature - Form to view single Asset with all attributes

Created by - Mona R
Updated by - Mona R */

//importing dependencies and packages
import { useEffect, useState, React } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import { Link } from "react-router-dom";



//Function to fetch single asset
const AssetSingleView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    // To fetch details from redux
    const dispatch = useDispatch();
    const assetGetSingle = useSelector(state => state.asset.assetGetSingle);
    const userid = useSelector(state => state.auth.userid);
    const isLoading = useSelector(state => state.asset.isLoading)


    const [data, setData] = useState({
        id: '',
        assetId: '',
        name: '',
        make: '',
        model: '',
        serial: '',
        rating: '',
        uom: '',
        qr: '',
        manufactureYear: '',
        installationYear: '',
        life: '',
        criticality: '',
        area: '',
        location: '',
        building: '',
        block: '',
        floor: '',
        wing: '',
        dept: '',
        accessibility: '',
        photo: '',
        remark: '',
    })

    // Take asset ID from params and send to redux to fetch asset details
    const sendID = () => {
        if (id !== null) {
            dispatch({
                type: "ASSET_GET_SINGLE",
                payload: id,
            })
        }
    }

    // useEffects
    useEffect(() => {
        sendID()
    }, [id])

    useEffect(() => {
        if(assetGetSingle != undefined && assetGetSingle != 0) {
            setData({
                assetId: assetGetSingle.asset_id,
                name: assetGetSingle.asset_name,
                make: assetGetSingle.make,
                model: assetGetSingle.model,
                serial: assetGetSingle.serial_number,
                rating: assetGetSingle.rating_capacity,
                uom: assetGetSingle.uom,
                qr: assetGetSingle.code_used,
                manufactureYear: assetGetSingle.year_of_manufacture,
                installationYear: assetGetSingle.year_of_installation,
                life: assetGetSingle.expected_life_in_month,
                criticality: assetGetSingle.criticality,
                area: assetGetSingle.area,
                location: assetGetSingle.location,
                building: assetGetSingle.building,
                block: assetGetSingle.block_tower,
                floor: assetGetSingle.floor,
                wing: assetGetSingle.wing,
                dept: assetGetSingle.department,
                accessibility: assetGetSingle.accessibility,
                photo: assetGetSingle.asset_image,
                remark: assetGetSingle.remarks
            })
        }
        
    }, [assetGetSingle])

    return (
      <div>
        {isLoading && <Loader />} {/* to show Loading effect */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 offset-md-12">
              <div className="AssetUpdate">
                <form action="" className="mt-5 border p-4 bg-light shadow">
                  <h3 style={{ textAlign: "center", color: "grey" }}>
                    Asset Update
                  </h3>
                  <div className="row">
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="assetId"
                        className="form-control form-rounded"
                        defaultValue={data.assetId}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        defaultValue={data.name}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Make<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="make"
                        className="form-control"
                        defaultValue={data.make}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Model<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="model"
                        className="form-control"
                        defaultValue={data.model}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Serial number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="serial"
                        className="form-control"
                        defaultValue={data.serial}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Rating/Capacity<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="rating"
                        className="form-control"
                        defaultValue={data.rating}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        UOM of Rating/Capacity<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="uom"
                        className="form-control"
                        defaultValue={data.uom}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Code used (QR/NFC)<span className="text-danger">*</span>
                      </label>
                      <select name="qr" className="form-select" disabled>
                        <option value="select">{data.qr}</option>
                        <option value="qr">QR</option>
                        <option value="nfc">NFC</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Year of Manufacture
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="installationYear"
                        className="form-control"
                        defaultValue={data.manufactureYear}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Installation year<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="installationYear"
                        className="form-control"
                        defaultValue={data.installationYear}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Expected Life in year
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="life"
                        className="form-control"
                        defaultValue={data.life}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Criticality<span className="text-danger">*</span>
                      </label>
                      <select
                        name="criticality"
                        className="form-select"
                        disabled
                      >
                        <option value="select">{data.criticality}</option>
                        <option value="high">1</option>
                        <option value="medium">2</option>
                        <option value="low">3</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Area<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="area"
                        className="form-control"
                        defaultValue={data.area}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Location<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="location"
                        className="form-control"
                        defaultValue={data.location}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Building<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="building"
                        className="form-control"
                        defaultValue={data.building}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Block/Tower<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="block"
                        className="form-control"
                        defaultValue={data.block}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Floor<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="floor"
                        className="form-control"
                        defaultValue={data.floor}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Wing<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="wing"
                        className="form-control"
                        defaultValue={data.wing}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Department<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="dept"
                        className="form-control"
                        defaultValue={data.dept}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Accessibility<span className="text-danger">*</span>
                      </label>
                      <select
                        name="accessibility"
                        className="form-select"
                        disabled
                      >
                        <option value="select">{data.accessibility}</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Asset Image<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="photo"
                        className="form-control"
                        defaultValue={data.photo}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-3">
                      <label>
                        Remark<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="remark"
                        className="form-control"
                        defaultValue={data.remark}
                        disabled
                      />
                    </div>
                    <div className="mb-2 col-md-12">
                      <Link to="/assetlist" className="backButton">
                        Back
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AssetSingleView